import { getUserUnit, recalculateParameterValueUnit } from 'helpers';

const makeUnit = (min: number, max: number) => {
	const unit = getUserUnit('pef');
	const minRecalculated = recalculateParameterValueUnit('pef', min);
	const maxRecalculated = recalculateParameterValueUnit('pef', max);

	return `PEF: ${minRecalculated}-${maxRecalculated} ${unit}`;
};

export const CHECK_PARAMETERS = {
	slow: {
		label: 'report.calibration_check.slow',
		getUserUnit: () => makeUnit(0, 2)
	},
	medium: {
		label: 'report.calibration_check.medium',
		getUserUnit: () => makeUnit(2, 5)
	},
	fast: {
		label: 'report.calibration_check.fast',
		getUserUnit: () => makeUnit(5, 12)
	}
} as const;
