import { ExaminationTypeSchema } from 'models/Examinations/common';
import { z } from 'zod';

export const REPORT_NOTE_MAX_LENGTH = 1000;
export const ReportNoteSchema = z
	.string()
	.max(
		REPORT_NOTE_MAX_LENGTH,
		'report.examination_note.report_interpretation.validation.max_length'
	);

export const GeneratePDFReportRequestSchema = z.object({
	patientId: z.union([z.number(), z.string()]).optional(),
	examinationId: z.string(),
	examinationType: ExaminationTypeSchema,
	reportNote: ReportNoteSchema,
	reportDateTime: z.string()
});

export type DownloadReportRequestModel = z.infer<
	typeof GeneratePDFReportRequestSchema
>;
