import { makeStyles } from '@material-ui/styles';
import theme from 'theme';
import palette from 'theme/palette';

import { isValueInvalid } from './helpers';
import { Props } from './StatusCell';

export const getStatusColor = ({
	value,
	defaultColor = palette.black
}: {
	value: Props['value'];
	defaultColor?: string;
}) => {
	if (isValueInvalid(value)) {
		return defaultColor;
	}
	return value ? palette.report.green.main : palette.error.alert;
};

export const useStyles = makeStyles<typeof theme, Props>(() => ({
	statusValue: {
		textTransform: 'uppercase',
		color: getStatusColor
	}
}));
