import { TOKEN_QUERY_PARAM_KEY } from '../../../helpers/api/queryParamKeys';

export const APP_PATHS = {
	SIGN_IN: '/sign-in',
	SIGN_IN_2FA: '/sign-in/2fa',
	SIGN_UP: '/sign-up',
	RESET_PASSWORD: '/password/reset/:id',
	RESET_PASSWORD_LINK: '/password/reset',
	NEW_DOCTOR: '/workers/account/create',
	DOCTOR_ACCOUNT_VERIFICATION: `/doctor/account/verification/${TOKEN_QUERY_PARAM_KEY}`,
	PATIENT_INVITATION_ACCOUNT: `/invitation/${TOKEN_QUERY_PARAM_KEY}`,
	PATIENT_LINK_INVITATION_ACCEPT: `/invitation/accept/${TOKEN_QUERY_PARAM_KEY}`,
	ACCOUNT_DELETE: `/account/delete/${TOKEN_QUERY_PARAM_KEY}`
} as const;

type AppPathsKeys = keyof typeof APP_PATHS;
export type AppPaths = (typeof APP_PATHS)[AppPathsKeys];
