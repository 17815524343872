import { MY_PROFILE_USER_RESOURCES_QUERY_KEY } from 'helpers';
import { DoctorUserModel } from 'models/User/Doctor';
import { useGetCachedData } from 'queries/utilities/useGetCachedData';

export const useGetCachedDoctorUserData = () => {
	const doctorUserData = useGetCachedData<{ data: DoctorUserModel }>([
		MY_PROFILE_USER_RESOURCES_QUERY_KEY
	]);

	return doctorUserData;
};
