import { DateFormatConversion } from 'helpers';

export const ConditionsServices = {
	/**
	 * convert fetched data to data accepted by used form
	 * @param {object} data form data fetched from DB
	 * @returns {object} form initial values
	 */
	createFormInitialValues: (data) => ({
		name: data.name || '',
		description: data.description || '',
		date: (!!data.date && new Date(data.date)) || new Date(),
		source: data.source || null,
		external_id: data.external_id || null,
		reaction_type: data.reaction_type || null,
		visible_on_patients_card: data.visible_on_patients_card
			? 'show'
			: 'hide',
		...DateFormatConversion.PHPToJS(data)
	}),
	/**
	 * converts form values to request payload accepted by backend
	 * @param {object} values form values
	 * @returns request payload
	 */
	convertFormValues: (values) => {
		const convertedValues = DateFormatConversion.dayMonthYearToPHP(values);

		return {
			...convertedValues,
			name: values.name,
			description: values.description,
			visible_on_patients_card:
				values?.visible_on_patients_card === 'show' ? true : false
		};
	}
};
