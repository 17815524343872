import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	provider: {
		height: '100%'
	},
	root: {
		paddingTop: 56,
		position: 'relative',
		height: '100%',
		[theme.breakpoints.up('sm')]: {
			paddingTop: 80
		}
	},
	signOutBox: {
		position: 'fixed',
		top: 0,
		left: 0,
		height: '100%',
		width: '100%',
		zIndex: 99999,
		background: 'rgba(0, 0, 0, 0.41)',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	lngLoader: {
		background: theme.palette.white
	},
	bg: {
		position: 'absolute',
		top: 0,
		left: 0,
		height: ({ layoutBgHeight }) => layoutBgHeight,
		width: '100%',
		background: '#0C0C30',
		zIndex: -1
	}
}));
