import { SMOKER_OPTIONS_VALUES } from 'helpers/variables';

import { SmokerLabelFactory } from './classes';

export const getSmokerLabel = (data) => {
	const smokerState = mapBooleanSmokerFieldsToSmokerState(data);
	const smokerLabelFactory = new SmokerLabelFactory(data);
	return smokerLabelFactory.createSmokerLabel(smokerState);
};

export function mapBooleanSmokerFieldsToSmokerState({ patientSmoker }) {
	let smokerState = SMOKER_OPTIONS_VALUES.NO;
	if (
		patientSmoker &&
		Object.values(SMOKER_OPTIONS_VALUES).includes(patientSmoker)
	) {
		smokerState = patientSmoker;
	}

	return smokerState;
}
