import { HelmetTags } from 'components/elements';
import { LoginTemplate } from 'components/templates';
import React from 'react';

import ResetUserPasswordForm from './form/ResetUserPasswordForm';

const ResetUserPassword = () => (
	<LoginTemplate>
		<HelmetTags title="pages.reset_password.title" />
		<ResetUserPasswordForm />
	</LoginTemplate>
);

export default ResetUserPassword;
