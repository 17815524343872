import { Alert, AlertTitle } from '@material-ui/lab';
import { useTranslations } from 'components/utilities';
import React from 'react';

import { useStyles } from './styles';

const AlertSuccess = () => {
	const classes = useStyles();
	const { t } = useTranslations();

	return (
		<Alert className={classes.alert} severity="success">
			<AlertTitle>{t('reset_password.success')}</AlertTitle>
			{t('reset_password.please_check')}{' '}
			<strong>{t('reset_password.your_email')}</strong>
		</Alert>
	);
};

export default AlertSuccess;
