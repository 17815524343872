import * as types from './types';

export const toggleFormOpen = (payload) => (dispatch) => {
	dispatch({ type: types.TOGGLE_FORM_OPEN, payload });
};

export const setEmailAvailable = (payload) => (dispatch) => {
	dispatch({ type: types.SET_EMAIL_AVAILABLE, payload });
};

export const setEmailDuplicated = (payload) => (dispatch) => {
	dispatch({ type: types.SET_EMAIL_DUPLICATED, payload });
};
export const nextStep = (payload) => (dispatch) => {
	dispatch({ type: types.NEXT_ACTIVE_STEP, payload });
};

export const prevStep = (payload) => (dispatch) => {
	dispatch({ type: types.PREV_ACTIVE_STEP, payload });
};

export const resetActiveStep = (payload) => (dispatch) => {
	dispatch({ type: types.RESET_ACTIVE_STEP, payload });
};

export const setNewPatientId = (payload) => (dispatch) => {
	dispatch({ type: types.SET_NEW_PATIENT_ID, payload });
};

export const setMarkPatientAsInvited = (payload) => (dispatch) => {
	dispatch({ type: types.SET_MARK_PATIENT_AS_INVITED, payload });
};
