import { API_URL_PREFIX } from './helpers';

export const LANGUAGE_API_URL = `${API_URL_PREFIX}/translations/find-by-groups`;
export const AVAILABLE_LOCALES_API_URL = `${API_URL_PREFIX}/dictionary/getLanguages`;

const LANGUAGES_GROUPS_PARAMS = [
	'frontend_pages',
	'frontend_global',
	'frontend_buttons',
	'frontend_patients',
	'frontend_patientssmoker',
	'frontend_login',
	'frontend_signup',
	'frontend_review',
	'frontend_resetpassword',
	'frontend_settings',
	'frontend_navigation',
	'frontend_buttons',
	'frontend_table',
	'frontend_chat',
	'frontend_schedule',
	'frontend_apicode',
	'frontend_errorcodes',
	'frontend_landingpages',
	'frontend_report',
	'frontend_changelog'
];

export const LANGUAGES_GROUPS_PARAMS_API = LANGUAGES_GROUPS_PARAMS.map(
	(group) => `groups[]=${group}`
).join('&');
