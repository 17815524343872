import { HelmetTags } from 'components/elements';
import React from 'react';
import BasicDataView from 'views/PatientsDetails/views/BasicData';
import ConditionsView from 'views/PatientsDetails/views/Conditions';
import DrugsView from 'views/PatientsDetails/views/Drugs/DrugsTable';
import ExaminationsV2View from 'views/PatientsDetails/views/ExaminationsV2';

import { PATIENTS_DETAILS_PATHS } from '../paths';

export const PATIENTS_DETAILS_ROUTES = [
	{
		path: PATIENTS_DETAILS_PATHS.BASIC_DATA,
		component: BasicDataView,
		helmet: <HelmetTags title="pages.profile.title" />
	},
	{
		path: PATIENTS_DETAILS_PATHS.CONDITIONS.ROOT,
		component: ConditionsView,
		helmet: <HelmetTags title="pages.health.title" />
	},
	{
		path: [
			PATIENTS_DETAILS_PATHS.EXAMINATIONS.ROOT,
			PATIENTS_DETAILS_PATHS.EXAMINATIONS.PREVIEW,
			PATIENTS_DETAILS_PATHS.EXAMINATIONS.TREND_CHART
		],
		component: ExaminationsV2View,
		helmet: <HelmetTags title="pages.examinations.title" />
	},
	{
		path: PATIENTS_DETAILS_PATHS.DRUGS.ROOT,
		component: DrugsView,
		helmet: <HelmetTags title="pages.medications.title" />
	}
];
