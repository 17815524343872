export const INITIAL_STATE = {
	auth: null,
	conversationList: [],
	conversationHistory: {
		messages: [],
		nextPage: true,
		status: false
	},
	conversationId: null,
	isParamConversationExist: false
};

export const SOCKET_EVENTS = {
	AUTHORIZE: 'authorize',
	UPDATE_CONVERSATION_LIST: 'conversationListUpdated',
	LOAD_CONVERSATIONS: 'loadConversations',
	LOAD_CONVERSATION_HISTORY: 'loadConversationHistory',
	SEND_MESSAGE: 'sendMessage',
	RECEIVED_MESSAGE: 'messageReceived',
	SHARE_EXAMINATION: 'shareExamination',
	MARK_CONVERSATION_AS_READ: 'markConversationAsRead'
};

export const sortMessagesByDate = (data) =>
	data.sort((a, b) => (a.id < b.id ? -1 : 1));

export const getSetConversationResources = ({
	conversationList,
	patientId,
	conversationId,
	isParamConversation
}) => {
	const newConversationId = conversationList[0]?.id;
	const isCurrentConversationId = conversationId === newConversationId;
	const isConversationListAvailable = conversationList.length;

	const patientParamConversation = conversationList.find(
		({ doctor_patient_id }) => doctor_patient_id === Number(patientId)
	);

	const conversationCondition =
		!isCurrentConversationId &&
		isConversationListAvailable > 0 &&
		newConversationId &&
		isParamConversation;

	const conversationParamCondition =
		patientParamConversation && !isParamConversation;

	return {
		conversationCondition,
		conversationParamCondition,
		patientParamConversation,
		newConversationId
	};
};
