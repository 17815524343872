import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		marginTop: 20,
		padding: '0 10px',
		gap: 10,
		flexWrap: 'wrap',

		'& .MuiCheckbox-colorSecondary.Mui-checked': {
			color: theme.palette.primary.main
		},

		[theme.breakpoints.down(890)]: {
			marginTop: 10
		},
		[theme.breakpoints.down(610)]: {
			justifyContent: 'center'
		}
	}
}));
