import React from 'react';

import { AppointmentFormContext } from './AppointmentFormProvider';

const useAppointmentFormContext = () => {
	const context = React.useContext(AppointmentFormContext);

	return { ...context };
};

export default useAppointmentFormContext;
