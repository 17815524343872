import { useQuery } from 'components/utilities';
import { ACCOUNT_DELETION_DELETE_QUERY_KEY } from 'helpers';
import { useParams } from 'utilities';

import { AccountRepository } from './repository';

const useFinalDeleteAccount = () => {
	const { token } = useParams();

	const query = useQuery(ACCOUNT_DELETION_DELETE_QUERY_KEY, () =>
		AccountRepository.finalDeleteAccount(token)
	);
	return query;
};

export default useFinalDeleteAccount;
