import { ClassNameMap } from '@material-ui/styles';
import { CreateDateTimeCell } from 'components/elements/atoms/ExaminationsV2';
import { IColumn } from 'components/elements/organisms/ExaminationsV2/ParametersTable/ParametersTable';
import { PDFHistoryModel } from 'models/Report/common';
import React from 'react';

import { PDFReportLinkCell, ShareReportCell } from './Cells';

type Accessor = Leaves<PDFHistoryModel & { createdBy: string }> | '';

export const createColumns = (
	classes: ClassNameMap<'cell'>
): IColumn<Accessor>[] => [
	{
		Header: 'patients.examinations_v2.details.pdf_history.created_date_time',
		accessor: 'created_at',
		component: (props) => (
			<CreateDateTimeCell {...props} className={classes.cell} />
		)
	},
	{
		Header: 'patients.examinations_v2.details.pdf_history.created_by',
		accessor: 'createdBy'
	},
	// {
	// 	Header: 'patients.examinations_v2.details.pdf_history.note',
	// 	accessor: 'note',
	// 	component: NoteCell
	// },
	{
		Header: 'patients.examinations_v2.details.pdf_history.download_link',
		accessor: 'key',
		component: PDFReportLinkCell
	},
	{
		Header: '',
		accessor: 'revision',
		component: ShareReportCell
	}
];
