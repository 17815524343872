import { EMPTY_VALUE } from 'helpers/variables';

export const convertPhoneNumber = (
	phoneNumber: string | undefined,
	prefix = ''
): string | typeof EMPTY_VALUE => {
	if (!phoneNumber) {
		return EMPTY_VALUE;
	}

	const formattedPhoneNumber =
		phoneNumber &&
		`${phoneNumber}`
			.replace(/\s/g, '')
			.replace(/-/g, '')
			.replace(/(\d{3})/g, '$1 ')
			.replace(/(^\s+|\s+$)/, '');

	return prefix ? `${prefix} ${formattedPhoneNumber}` : formattedPhoneNumber;
};
