import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		zIndex: 90,
		height: 25,
		minWidth: 40,
		backgroundColor: 'rgb(230, 235, 245)'
	},
	icon: {
		color: theme.palette.customBlack.main
	}
}));
