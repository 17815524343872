import { ParametersTable } from 'components/elements/organisms/ExaminationsV2';
import { ForcedSpirometryReportModel } from 'models/Report/Reports';
import React from 'react';

import { useGetCachedBronchodilatorReportData } from '../../useGetCachedBronchodilatorReportData';
import { getColumns } from './columns';
import { convertBestResultsToColumnsData } from './helpers';
import { useStyles } from './styles';

type Props = {
	examination: ForcedSpirometryReportModel | undefined;
	isChangePercentage: boolean;
};

export const BestResults = ({ examination, isChangePercentage }: Props) => {
	const classes = useStyles();
	const { examination: copdExaminationReportData } =
		useGetCachedBronchodilatorReportData();

	return (
		<ParametersTable
			headerCellClassName={classes.headerCell}
			rowClassName={classes.row}
			columns={getColumns(isChangePercentage)}
			data={convertBestResultsToColumnsData(
				examination?.bestParameters,
				copdExaminationReportData?.changePercentage,
				isChangePercentage
			)}
		/>
	);
};
