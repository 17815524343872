import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { func, number, oneOfType, string } from 'prop-types';
import React from 'react';

import { useStyles } from './styles';

const Circle = ({
	size = 25,
	color = 'black',
	className,
	getAnchorProps,
	...rest
}) => {
	const classes = useStyles({ size, color });

	const anchorProps = getAnchorProps && getAnchorProps();
	return (
		<Box
			className={clsx([classes.root, className])}
			{...rest}
			{...anchorProps}
		/>
	);
};

Circle.propTypes = {
	size: oneOfType([string, number]),
	className: string,
	color: string,
	getAnchorProps: func
};

export default Circle;
