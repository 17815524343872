import { Checkbox, FormControl, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { useTranslations } from 'components/utilities';
import { func, object, string } from 'prop-types';
import React from 'react';

import { InputError } from '../../atoms';

const useStyles = makeStyles(() => ({
	controlLabel: {
		display: 'flex',
		alignItems: 'flex-start'
	},
	controlLabel__checkbox: {
		marginTop: '-10px'
	}
}));
const CheckboxFieldAdapter = ({
	input,
	meta,
	label,
	subLabel,
	classNameLabel,
	classNameCheckbox,
	linkComponent: LinkComponent = null,
	...rest
}) => {
	const classes = useStyles();
	const { t } = useTranslations();
	const error =
		meta.touched && (meta.error || meta.submitError) ? true : false;
	return (
		<FormControl component="fieldset" error={error}>
			<FormControlLabel
				control={
					<Checkbox
						{...input}
						{...rest}
						onChange={(e) => {
							input?.onChange?.(e);
							rest?.onChange?.(e);
						}}
						className={clsx([
							classNameCheckbox,
							classes.controlLabel__checkbox
						])}
					/>
				}
				label={
					<span>
						{t(label)}
						{LinkComponent && <LinkComponent />}
						{subLabel && subLabel}
						{rest.required && '*'}
					</span>
				}
				className={clsx([classNameLabel, classes.controlLabel])}
			/>
			<InputError meta={meta} />
		</FormControl>
	);
};

CheckboxFieldAdapter.propTypes = {
	input: object.isRequired,
	label: string,
	subLabel: string,
	meta: object.isRequired,
	classNameLabel: string,
	classNameCheckbox: string,
	linkComponent: func
};

export default CheckboxFieldAdapter;
