import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { isSameDay } from 'date-fns';
import { arrayOf, node, object, oneOfType } from 'prop-types';
import { Fragment } from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import { LoaderSpinner } from '../../../elements/molecules';
import { DateTitle } from './DateTitle';
import { RESULT_TYPES } from './helpers';
import Message from './Message';
import Result from './Result';
import useMessages from './useMessages';

const useStyles = makeStyles(() => ({
	root: {
		padding: '26px 21px 5px 21px',
		height: '100%',
		overflowY: 'scroll'
	},
	messages: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%'
	},
	loader: {
		height: '20px !important'
	}
}));

const DateTitleHOC = ({ date, children }) => {
	return (
		<Fragment>
			<DateTitle date={date} />
			{children}
		</Fragment>
	);
};

DateTitleHOC.propTypes = {
	children: oneOfType([arrayOf(node), node]).isRequired,
	date: object.isRequired
};

const Messages = () => {
	const classes = useStyles();
	const { loadMore, hasMoreMessages, messages, rootRef, userId } =
		useMessages();

	const loader = (
		<LoaderSpinner key={0} size={20} classNameRoot={classes.loader} />
	);

	// eslint-disable-next-line
	const renderMessageContent = ({ id, type, ...rest }, index) => {
		const next = messages?.[index + 1] ?? {};
		const prev = messages?.[index - 1] ?? {};
		const date = new Date(rest.created_at);
		const isTheSameDayAsPrevious = prev.created_at
			? isSameDay(date, new Date(prev.created_at))
			: false;

		const ContentWrapper = isTheSameDayAsPrevious
			? ({ children }) => children
			: DateTitleHOC;
		switch (type) {
			case RESULT_TYPES.TEXT:
				return (
					<ContentWrapper key={id} date={date}>
						<Message
							id={id}
							userId={userId}
							next={next}
							{...rest}
						/>
					</ContentWrapper>
				);

			default:
				return (
					<ContentWrapper key={id} date={date}>
						<Result
							id={id}
							userId={userId}
							next={next}
							type={type}
							{...rest}
						/>
					</ContentWrapper>
				);
		}
	};

	return (
		<Box ref={rootRef} className={classes.root}>
			<InfiniteScroll
				loadMore={loadMore}
				hasMore={hasMoreMessages}
				loader={loader}
				useWindow={false}
				isReverse={true}
			>
				<Box className={classes.messages}>
					{messages.map(renderMessageContent)}
				</Box>
			</InfiniteScroll>
		</Box>
	);
};

export default Messages;
