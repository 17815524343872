export const PATIENT_SMOKER_FIELD_NAMES = {
	EX_SMOKER: 'patientExSmoker',
	PASSIVE_SMOKER: 'patientPassiveSmoker',
	SMOKER: 'patientSmoker'
};

export const ADDITIONAL_FIELD_NAMES = {
	BMI: 'patientBmi',
	GENDER: 'patientGender',
	RACE: 'patientRace',
	WEIGHT: 'patientWeight',
	HEIGHT: 'patientHeight',
	WEIGHT_UNIT: 'patientWeightUnit',
	HEIGHT_UNIT: 'patientHeightUnit'
};
