import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	wrapper: {
		position: 'relative',
		minHeight: 300
	},
	buttonSave: {
		marginLeft: theme.spacing(3)
	},
	cardActions: {
		justifyContent: 'flex-end'
	},
	loader: {
		position: 'absolute',
		top: 0,
		left: 0,
		height: '100%',
		width: '100%',
		zIndex: 9999,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	}
}));
