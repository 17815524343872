import { useEffect, useRef } from 'react';
import io from 'socket.io-client';

const useSocket = (socketUrl) => {
	const { current: socket } = useRef(
		io(socketUrl, {
			transports: ['websocket', 'polling'],
			extraHeaders: {
				'Access-Control-Allow-Origin': '*',
				'Content-Type': 'application/json',
				Accept: 'application/json',
				'User-Agent': `com.aiocare.panel - v${process.env.REACT_APP_VERSION}`
			},
			withCredentials: false
		})
	);
	useEffect(() => {
		return () => {
			socket && socket.close();
			socket && socket.disconnect();
			socket && socket.removeAllListeners();
		};
	}, [socket]);
	return [socket];
};

export default useSocket;
