import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	additionalParameters: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	divider: {
		width: 1,
		margin: '0 20px'
	}
}));
