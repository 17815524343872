import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		width: '21cm',
		height: '29.7cm',
		padding: 30,
		paddingBottom: 10,
		background: '#FFFFFF'
	}
}));
