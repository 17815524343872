import * as types from './types';

export const toggleMedicationForm = (payload) => (dispatch) => {
	dispatch({ type: types.TOGGLE_MEDICATION_FORM, payload });
};

export const setMedicationName = (payload) => (dispatch) => {
	dispatch({ type: types.SET_MEDICATION_NAME, payload });
};

export const resetDosageState = (payload) => (dispatch) => {
	dispatch({ type: types.RESET_DOSAGE_STATE, payload });
};

export const setDosage = (payload) => (dispatch) => {
	dispatch({ type: types.SET_DOSAGE, payload });
};

export const setDosageType = (payload) => (dispatch) => {
	dispatch({ type: types.SET_DOSAGE_TYPE, payload });
};

export const setDosageDays = (payload) => (dispatch) => {
	dispatch({ type: types.SET_DOSAGE_DAYS, payload });
};

export const setDosageForm = (payload) => (dispatch) => {
	dispatch({ type: types.SET_DOSAGE_FORM, payload });
};
export const setInhalerPurpose = (payload) => (dispatch) => {
	dispatch({ type: types.SET_INHALER_PURPOSE, payload });
};

export const setTaking = (payload) => (dispatch) => {
	dispatch({ type: types.SET_TAKING, payload });
};

export const setUntil = (payload) => (dispatch) => {
	dispatch({ type: types.SET_UNTIL, payload });
};

export const setMedicationIndex = (payload) => (dispatch) => {
	dispatch({ type: types.SET_MEDICATION_INDEX, payload });
};

export const setNote = (payload) => (dispatch) => {
	dispatch({ type: types.SET_NOTE, payload });
};

export const setInitialFormData = (payload) => (dispatch) => {
	dispatch({ type: types.SET_INITIAL_FORM_DATA, payload });
};
