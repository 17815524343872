import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAvailableLocales, fetchLanguages } from 'store/actions';
import { AppLanguage, Cookies } from 'utilities';

const useInitialLanguageTranslations = () => {
	const dispatch = useDispatch();

	const {
		authorization: { isLoaded }
	} = useSelector<{ language }, { authorization: { isLoaded: boolean } }>(
		(state) => state.language
	);

	const handleFetchLanguages = async () => {
		// Dispatch actions in languages
		await fetchAvailableLocales()(dispatch);
		const appLanguage = new AppLanguage();
		const initialLng = appLanguage.getInitialLanguage(
			Cookies.countryCode.get()
		);

		fetchLanguages({ lng: initialLng, isAuthForm: true })(dispatch);
	};

	React.useEffect(() => {
		!isLoaded && handleFetchLanguages();
		// eslint-disable-next-line
	}, []);

	return { isLoaded };
};

export default useInitialLanguageTranslations;
