import { HelmetTags } from 'components/elements';
import RoleAuthorization from 'components/hoc/RoleAuthorization';
import { lazyLoadDelay } from 'helpers';
import { GLOBAL_PAGE_ROLES, PATIENTS_PAGE_ROLES } from 'helpers';
import { Blank as BlankLayout, Main as MainLayout } from 'layouts';
import React from 'react';
import {
	Patients as PatientsView,
	PatientsDetails as PatientsDetailsView,
	Schedule as ScheduleView,
	Settings as SettingsView
} from 'views';

import { DASH_PATHS } from '../paths';

// const ReviewsView = React.lazy(() =>
// 	lazyLoadDelay(import('views/Reviews'), 1000)
// );
const ChatView = React.lazy(() => lazyLoadDelay(import('views/Chat'), 1000));
const NotFoundView = React.lazy(() =>
	lazyLoadDelay(import('views/NotFound'), 1000)
);

// Define roles
const GlobalRoles = RoleAuthorization(GLOBAL_PAGE_ROLES) as unknown as (
	props: RouteComponent
) => JSX.Element;
const PatientRoles = RoleAuthorization(PATIENTS_PAGE_ROLES) as unknown as (
	props: RouteComponent
) => JSX.Element;

type RouteComponent =
	| (() => JSX.Element)
	| JSX.Element
	| React.LazyExoticComponent<React.ComponentType>;
type DashRoute = {
	path: ValuesOf<typeof DASH_PATHS> | ValuesOf<typeof DASH_PATHS>[];
	component: RouteComponent;
	layout: typeof MainLayout | typeof BlankLayout;
	helmet: JSX.Element;
};

export const DASH_ROUTES: DashRoute[] = [
	// {
	// 	path: DASH_PATHS.REVIEWS,
	// 	component: GlobalRoles(ReviewsView),
	// 	layout: MainLayout,
	// 	helmet: <HelmetTags title="pages.review.title" />
	// },
	{
		path: DASH_PATHS.PATIENTS_DETAILS,
		component: GlobalRoles(PatientsDetailsView),
		layout: MainLayout,
		helmet: <HelmetTags title="pages.patients.title" />
	},
	// {
	// 	path: DASH_PATHS.REPORT,
	// 	component: GlobalRoles(Report),
	// 	layout: BlankLayout,
	// 	helmet: <HelmetTags title="pages.schedule.title" />
	// },
	{
		path: DASH_PATHS.PATIENTS,
		component: PatientRoles(PatientsView),
		layout: MainLayout,
		helmet: <HelmetTags title="pages.patients.title" />
	},
	{
		path: DASH_PATHS.SETTINGS,
		component: GlobalRoles(SettingsView),
		layout: MainLayout,
		helmet: <HelmetTags title="pages.settings.title" />
	},
	{
		path: [DASH_PATHS.CHAT_PATIENT_CONVERSATION, DASH_PATHS.CHAT],
		component: GlobalRoles(ChatView),
		layout: MainLayout,
		helmet: <HelmetTags title="pages.chat.title" />
	},
	{
		path: DASH_PATHS.SCHEDULE,
		component: GlobalRoles(ScheduleView),
		layout: MainLayout,
		helmet: <HelmetTags title="pages.schedule.title" />
	},
	{
		path: DASH_PATHS.NOT_FOUND,
		component: NotFoundView,
		layout: MainLayout,
		helmet: <HelmetTags title="pages.not_found.title" />
	}
];
