import { makeStyles } from '@material-ui/styles';
import palette from 'theme/palette';

export const useStyles = makeStyles(() => ({
	wrapper: {
		display: 'flex',
		flexDirection: 'column',
		gap: '10px',
		alignItems: 'center',
		flex: 1
	},
	titleContainer: {
		width: '100%',
		height: 50,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: palette.background.default
	},
	borderBox: {
		width: '100%',
		borderRight: `1px solid ${palette.border.light}`
	},
	title: {
		marginBottom: 0
	}
}));
