import { Box } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/styles';
import clsx from 'clsx';
import React, { Key } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Title } from '../../../atoms/ExaminationsV2';
import { LabeledValue } from '../LabeledValue';
import { Data, InformationSectionField } from '.';
import { useStyles } from './styles';

export type LocalTheme = () => void | ClassNameMap<
	'title' | 'font' | 'fieldsWrapper'
>;

type Props<TData> = {
	orientation: 'row' | 'column';
	title: string;
	fields: InformationSectionField<TData>[];
	data: Data<TData>;
	fieldsContainerClassName?: string;
	classNameRoot?: string;
	useLocalTheme?: LocalTheme;
};

export const InformationSection = <TData,>({
	orientation,
	title,
	fields,
	data,
	classNameRoot = '',
	fieldsContainerClassName = '',
	useLocalTheme = () => {}
}: Props<TData>) => {
	const classes = useStyles({ orientation });
	const localTheme = useLocalTheme();

	return (
		<Box className={clsx([classes.root, classNameRoot])}>
			<Title className={localTheme?.title} title={title} />
			<Box
				className={clsx([
					localTheme?.fieldsWrapper,
					classes.fieldsWrapper,
					fieldsContainerClassName
				])}
			>
				{fields.map((field) =>
					field ? (
						<LabeledValue
							key={field.fieldName as Key}
							labelProps={{
								className: clsx([
									localTheme?.font,
									classes.label
								]),
								label: field.label
							}}
							valueProps={{
								className: localTheme?.font,
								valueWidth: field?.valueWidth ?? 'auto',
								value: field.valueConverter(
									data?.[field.fieldName],
									data
								)
							}}
						/>
					) : (
						<Box key={uuidv4()} />
					)
				)}
			</Box>
		</Box>
	);
};

export default InformationSection;
