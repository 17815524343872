import { func } from 'prop-types';
import React from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import { Cookies } from 'utilities';

import { APP_PATHS } from '../routes/paths';

type Props = {
	component: (
		props: RouteComponentProps<{
			[x: string]: string | undefined;
		}>
	) => JSX.Element;
};
export const ProtectedReport = ({ component: Component }: Props) => {
	return () => (
		<Route
			render={(props) =>
				Cookies.jwt.get() ? (
					<Component {...props} />
				) : (
					<Redirect to={APP_PATHS.SIGN_IN} />
				)
			}
		/>
	);
};

ProtectedReport.propTypes = {
	component: func.isRequired
};
