import { useLocationQueryParams } from 'components/utilities';
import React from 'react';
import { useForm } from 'react-final-form';

import { EMAIL_FIELD_NAME } from './helpers';

const useFields = () => {
	const { getQueryParam } = useLocationQueryParams();
	const { change } = useForm();

	React.useEffect(() => {
		const emailValue = getQueryParam(EMAIL_FIELD_NAME);
		change(EMAIL_FIELD_NAME, emailValue);
		//eslint-disable-next-line
	}, []);
};

export default useFields;
