import { ReactComponent as AioCareLogoIcon } from 'assets/icons/AioCareLogo.svg';
import React from 'react';

type Props = {
	className?: string;
} & React.SVGProps<SVGSVGElement>;
const AioCareLogo = ({ className }: Props) => {
	return <AioCareLogoIcon className={className} />;
};

export default AioCareLogo;
