import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative'
	},
	divider: {
		position: 'absolute',
		left: 10,
		bottom: 4,
		opacity: 0.2,
		background: '#707070',
		width: '100%',
		[theme.breakpoints.down(890)]: {
			display: 'none'
		}
	},
	list: {
		marginTop: 12,
		position: 'relative',
		display: 'flex',
		justifyContent: 'flex-start',
		zIndex: 100,

		[theme.breakpoints.down(890)]: {
			flexWrap: 'wrap',
			justifyContent: 'space-around'
		}
	}
}));
