export const COLUMNS_ACCESSORS = {
	NAME: 'name',
	TYPE: 'dosage_form',
	BRONCHIAL_TEST: 'bronchialTest',
	UNTIL: 'take_until',
	SETTINGS: 'settings',
	DOSAGE: 'dosage'
};

export const columns = [
	{
		Header: 'patients.drugs.table.header.name',
		accessor: COLUMNS_ACCESSORS.NAME,
		disableSortBy: false
	},
	{
		Header: 'patients.drugs.table.header.dosage',
		accessor: COLUMNS_ACCESSORS.DOSAGE,
		disableSortBy: true
	},
	{
		Header: 'patients.drugs.table.header.type',
		accessor: COLUMNS_ACCESSORS.TYPE,
		disableSortBy: true
	},
	{
		Header: 'patients.drugs.table.header.until',
		accessor: COLUMNS_ACCESSORS.UNTIL,
		disableSortBy: true
	},
	{
		Header: '',
		accessor: COLUMNS_ACCESSORS.SETTINGS,
		disableSortBy: true
	}
];
