import { PointOptionsObject } from 'highcharts';
import { ForcedSpirometryMeasurementModel } from 'models/Report/ForcedSpirometry';
import { SlowVitalCapacitySVCMeasurementModel } from 'models/Report/SlowVitalCapacitySVC';

export const prepareAllMeasurementsDataToRenderInGraph = (
	data:
		| ForcedSpirometryMeasurementModel[]
		| SlowVitalCapacitySVCMeasurementModel[],
	dataType: 'flowVolumeChart' | 'volumeTimeChart'
): {
	data: PointOptionsObject[];
	isBest: boolean;
	lineWidth: number;
}[] =>
	data.map((d) => ({
		isBest: d.isBest,
		data: d[dataType],
		lineWidth: 2
	}));
