import { TIME_FORMAT } from 'helpers';
import { ForcedSpirometryMeasurementModel } from 'models/Report/ForcedSpirometry';
import React from 'react';
import { LocaleDate } from 'utilities';

import { usePrePostContextActions } from '../../context/usePrePostContextActions';

export type UseArrowSelectorsProps = {
	prePostIndexSetter: React.Dispatch<React.SetStateAction<number>>;
	activeMeasurementIndex: number;
	measurements: ForcedSpirometryMeasurementModel[];
	type: 'pre' | 'post';
};
export const useArrowSelectors = ({
	prePostIndexSetter,
	activeMeasurementIndex,
	measurements,
	type
}: UseArrowSelectorsProps) => {
	const { setActiveGraphCurve } = usePrePostContextActions({
		type,
		activeMeasurementIndex
	});

	const handleClickLeft = () => {
		prePostIndexSetter((prevIndex) => {
			const newIndex = prevIndex - 1;
			setActiveGraphCurve(newIndex, prevIndex);
			return newIndex;
		});
	};

	const handleClickRight = () => {
		prePostIndexSetter((prevIndex) => {
			const newIndex = prevIndex + 1;
			setActiveGraphCurve(newIndex, prevIndex);
			return newIndex;
		});
	};

	const leftArrowDisabled = React.useMemo(
		() => activeMeasurementIndex === 0,
		[activeMeasurementIndex]
	);
	const rightArrowDisabled = React.useMemo(
		() => activeMeasurementIndex + 1 === measurements?.length,
		[activeMeasurementIndex]
	);

	const attemptDateTime = React.useMemo(
		() =>
			new LocaleDate(
				measurements?.[activeMeasurementIndex]?.createDateTime
			).getLocaleTime(TIME_FORMAT.DEFAULT_WITH_SECONDS),
		[activeMeasurementIndex]
	);

	const attemptsArray = React.useMemo(
		() =>
			new Array(measurements?.length).fill('').map((_, i) => ({
				isBest: measurements?.[i].isBest || false
			})),
		[]
	);

	const isBestIndex = React.useMemo(
		() => attemptsArray.findIndex((attempt) => attempt),
		[]
	);

	const handleMouseEnterRight = React.useCallback(() => {}, [
		activeMeasurementIndex
	]);
	const handleMouseLeaveRight = React.useCallback(() => {}, [
		activeMeasurementIndex
	]);

	const handleMouseEnterLeft = React.useCallback(() => {}, [
		activeMeasurementIndex
	]);
	const handleMouseLeaveLeft = React.useCallback(() => {}, [
		activeMeasurementIndex
	]);

	const getSelectorActions = () => ({
		onClickLeft: handleClickLeft,
		onClickRight: handleClickRight,
		onMouseEnterRight: handleMouseEnterRight,
		onMouseLeaveRight: handleMouseLeaveRight,
		onMouseEnterLeft: handleMouseEnterLeft,
		onMouseLeaveLeft: handleMouseLeaveLeft,
		leftArrowDisabled,
		rightArrowDisabled,
		attemptDateTime,
		activeMeasurementIndex,
		attemptsArray,
		isBestIndex
	});

	return { getSelectorActions, activeMeasurementIndex };
};
