import { z } from 'zod';

import {
	ChartPointSchema,
	CommonReportFieldsSchema,
	ReportParameterSchema,
	StatusSchema
} from './common';

export const SlowVitalCapacitySVCMeasurementSchema = z.object({
	id: z.string().uuid(),
	atsError: z.string().array().nullable(),
	createDateTime: z.string().nullable().optional(),
	ic: z.union([ReportParameterSchema, z.number()]).nullable().optional(),
	vc: z.union([ReportParameterSchema, z.number()]).nullable().optional(),
	erv: z.union([ReportParameterSchema, z.number()]).nullable().optional(),
	irv: z.union([ReportParameterSchema, z.number()]).nullable().optional(),
	vt: z.union([ReportParameterSchema, z.number()]).nullable().optional(),
	icStatus: StatusSchema.nullable().optional(),
	isBest: z.boolean(),
	num: z.number(),
	vcStatus: StatusSchema.nullable().optional(),
	volumeTimeChart: z.array(ChartPointSchema).nullable()
});

export type SlowVitalCapacitySVCMeasurementModel = z.infer<
	typeof SlowVitalCapacitySVCMeasurementSchema
>;

export const SlowVitalCapacitySVCBestParametersSchema = z.object({
	erv: ReportParameterSchema.nullable().optional(),
	fev1Svc: ReportParameterSchema.pick({ value: true }).nullable().optional(),
	ic: ReportParameterSchema.nullable().optional(),
	irv: ReportParameterSchema.nullable().optional(),
	vc: ReportParameterSchema.nullable().optional(),
	vt: ReportParameterSchema.nullable().optional()
});

export type SlowVitalCapacitySVCBestParametersModel = z.infer<
	typeof SlowVitalCapacitySVCBestParametersSchema
>;

export const SlowVitalCapacitySVCReportSchema = CommonReportFieldsSchema.merge(
	z.object({
		protocol: z.string().nullable().optional(),
		bestMeasurementChart: z
			.object({
				volumeTimeChart: z.array(ChartPointSchema)
			})
			.nullable()
			.optional(),
		bestParameters: SlowVitalCapacitySVCBestParametersSchema,
		measurements: z.array(SlowVitalCapacitySVCMeasurementSchema).nullable()
	})
);
