import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		paddingBottom: '25px !important',
		paddingTop: '26px !important',
		borderBottom: `1px solid ${theme.palette.border.light}`,
		marginBottom: 10,
		[theme.breakpoints.up('sm')]: {
			padding: '38px 0px 0px 66px'
		}
	},
	wrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: '20px',
		width: '100%'
	},
	title: {
		textTransform: 'inherit',
		fontSize: '20px'
	}
}));
