import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	flex: {
		display: 'flex',
		justifyContent: 'center'
	},
	gap: {
		gap: 15
	},
	itemContainer: {
		padding: '8px 25px'
	}
}));
