import { APP_NODE_ENV_TYPES } from 'helpers';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { isFormActive } from './helpers';

const useConditions = ({ CONDITIONS, SURGERIES, ALLERGIES }) => {
	const { pathname } = useLocation();
	const [manualRefetch, setManualRefetch] = React.useState({
		[CONDITIONS]: false,
		[SURGERIES]: false,
		[ALLERGIES]: false
	});

	const handleSetManualRefetch = (tableName) => {
		//eslint-disable-next-line
		if (process.env.REACT_APP_NODE_ENV === APP_NODE_ENV_TYPES.TESTING) {
			setTimeout(() => setManualRefetchAction(tableName), 350);
		} else {
			setManualRefetchAction(tableName);
		}
	};

	function setManualRefetchAction(tableName) {
		setManualRefetch((state) => ({
			...state,
			[tableName]: !state[tableName]
		}));
	}

	const isFormShown = isFormActive(pathname);

	const gridTemplateColumns = {
		md: isFormShown ? 8 : 12,
		lg: isFormShown ? 8 : 12,
		xl: isFormShown ? 9 : 12
	};

	return {
		setManualRefetch: handleSetManualRefetch,
		manualRefetch,
		gridTemplateColumns,
		isFormShown
	};
};

export default useConditions;
