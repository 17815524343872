import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		cursor: 'pointer',
		position: 'relative'
	},
	hoverEffect: {
		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0, 0.04)'
		}
	}
}));
