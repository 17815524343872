import { IColumn } from 'components/elements/organisms/ExaminationsV2/ParametersTable/ParametersTable';
import { ReportParameterModel } from 'models/Report/common';

import { ColumnValues } from '../common/createColumnData';

type Accessor = Leaves<ColumnValues<ReportParameterModel>>;

export const columns: IColumn<Accessor>[] = [
	{ Header: '', accessor: 'parameterTypeLabel' },
	{
		Header: 'report.best_results.value',
		accessor: 'value',
		isInteger: { rows: ['pef', 'pif'] }
	},
	{
		Header: 'report.best_results.lln',
		accessor: 'lln',
		isInteger: { rows: ['pef', 'pif'] }
	},
	{
		Header: 'report.best_results.predicted',
		accessor: 'predicted',
		isInteger: { rows: ['pef', 'pif'] }
	},
	{ Header: 'report.best_results.z_score', accessor: 'zScore' },
	{
		Header: 'report.best_results.percentage_predicted',
		accessor: 'predictedPercent',
		isInteger: true
	},
	{
		Header: 'report.best_results.percentile',
		accessor: 'percentile',
		isInteger: true
	}
];
