import services from 'services/services';

import * as types from './types';

const testData = {
	data: {
		draw: 0,
		recordsTotal: 48,
		recordsFiltered: 48,
		data: new Array(3).fill().map(() => ({
			id: '024e3dc',
			name: 'John Black',
			avatar: 'https://source.unsplash.com/user/erondu/40x40',
			email: 'oliviasmith@gmail.com',
			phone: '+44 4567342246',
			since: '17.10.2020',
			account: 'linked',
			subscription: true
		})),
		input: {
			start: 0,
			length: 5,
			columns: [
				{
					name: 'uuid',
					searchable: false,
					orderable: false,
					search: { value: null }
				},
				{
					name: 'name',
					searchable: true,
					orderable: true,
					search: { value: null }
				},
				{
					name: 'city',
					searchable: true,
					orderable: true,
					search: { value: null }
				},
				{
					name: 'cars',
					searchable: false,
					orderable: false,
					search: { value: null }
				},
				{
					name: 'cars_type',
					searchable: false,
					orderable: false,
					search: { value: null }
				},
				{
					name: 'no_of_bookings',
					searchable: false,
					orderable: false,
					search: { value: null }
				},
				{
					name: 'acceptance_rate',
					searchable: false,
					orderable: false,
					search: { value: null }
				},
				{
					name: 'avg_response_time',
					searchable: false,
					orderable: false,
					search: { value: null }
				},
				{
					name: 'actual_revenue',
					searchable: false,
					orderable: false,
					search: { value: null }
				},
				{
					name: 'lost_revenue',
					searchable: false,
					orderable: false,
					search: { value: null }
				}
			]
		}
	}
};

export const fetchControlledTableData = async ({
	options,
	queryParams,
	url,
	customFetchData,
	isInfiniteScroll,
	dispatch
}) => {
	const { pageIndex, pageSize } = options;
	const body = !isInfiniteScroll && {
		start: pageIndex * pageSize,
		length: pageSize
	};

	// Get response
	const apiUrl = `${url}${queryParams}`;
	const tableData = customFetchData
		? await customFetchData()
		: url
		? await services.post(apiUrl, body)
		: testData;
	const {
		data: { data, input, recordsFiltered },
		rolesData
	} = tableData;

	setInputColumns(input.columns)(dispatch);

	const tableDataPayload = {
		data,
		pageCount: Math.ceil(recordsFiltered / pageSize),
		rolesData
	};

	return tableDataPayload;
};

export const setOptions = (payload) => (dispatch) => {
	dispatch({ type: types.SET_OPTIONS, payload });
};

export const setInputColumns = (payload) => (dispatch) => {
	dispatch({ type: types.SET_INPUT_COLUMNS, payload });
};

export const updateInputColumns = (payload) => (dispatch) => {
	dispatch({ type: types.SET_TABLE_COLUMNS, payload });
};

export const setFilters = (payload) => (dispatch) => {
	dispatch({ type: types.SET_FILTERS, payload });
};
