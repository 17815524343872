import { Box, TableCell } from '@material-ui/core';
import { DosagePreview } from 'components/elements';
import { object } from 'prop-types';
import React from 'react';

import { useCommonStyles } from '../../styles';

const DosageTableCell = ({ cell }) => {
	const classes = useCommonStyles();

	const { dosage_schedule, taking, days } = cell.row.original;

	const isDosagePreview = dosage_schedule && taking && days;

	const renderContent = React.useCallback(
		() =>
			isDosagePreview ? (
				<DosagePreview
					dosageSchedule={dosage_schedule}
					taking={taking}
					days={days}
					isTakeLabel={false}
					isDefaultStyle
					component={Box}
				/>
			) : (
				cell.render('Cell')
			),
		[isDosagePreview]
	);

	return (
		<TableCell
			key={cell.column.id}
			{...cell.getCellProps()}
			className={classes.cell}
		>
			{renderContent()}
		</TableCell>
	);
};

DosageTableCell.propTypes = {
	cell: object.isRequired
};

export default DosageTableCell;
