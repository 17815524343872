import { MutationOptions, useMutation } from 'components/utilities';
import {
	PatientUnlinkFromDoctorModel,
	PatientUnlinkFromDoctorRequestModel
} from 'models/Patient/PatientLink';

import { PatientsRepository } from './repository';

export const useUnlinkPatientAccountFromDoctorMutation = (
	options: MutationOptions<
		PatientUnlinkFromDoctorModel,
		TMyError,
		PatientUnlinkFromDoctorRequestModel
	> = {}
) => {
	const mutation = useMutation(
		(payload: PatientUnlinkFromDoctorRequestModel) =>
			PatientsRepository.unlinkPatientAccountFromDoctor(payload),
		options
	);
	return mutation;
};
