import { CancelToken } from 'axios';
import {
	DOWNLOAD_REPORT,
	DOWNLOAD_REPORT_PDF_BY_LINK,
	GET_CALIBRATION_CHECK_REPORT_DATA_API,
	GET_EXAMINATION_REPORT_DATA_API,
	GET_EXAMINATION_REPORT_NOTE_API,
	SHARE_REPORT_BY_RECIPIENT_EMAIL_API
} from 'helpers';
import { ExaminationNoteSchema } from 'models/Examinations/ExaminationNote';
import { BronchodilatorReportSchema } from 'models/Report/Bronchodilator';
import { CalibrationCheckReportSchema } from 'models/Report/CalibrationCheck';
import {
	DownloadPDFReportByLinkRequestModel,
	DownloadPDFReportByLinkRequestSchema,
	DownloadPDFReportByLinkResponseSchema
} from 'models/Report/DownloadPDFReportByLink';
import {
	DownloadReportRequestModel,
	GeneratePDFReportRequestSchema
} from 'models/Report/DownloadReport';
import { ForcedSpirometryReportSchema } from 'models/Report/ForcedSpirometry';
import { PeakFlowReportSchema } from 'models/Report/PeakFlow';
import { PulseOximetryReportSchema } from 'models/Report/PulseOximetry';
import {
	ShareReportByRecipientEmailRequestModel,
	ShareReportByRecipientEmailRequestSchema,
	ShareReportByRecipientEmailResponseSchema
} from 'models/Report/ShareReport';
import { SlowVitalCapacitySVCReportSchema } from 'models/Report/SlowVitalCapacitySVC';
import {
	replaceCommonIdQueryParam,
	replaceExaminationTypeQueryParam,
	replacePatientIdQueryParam
} from 'queries/common';
import { servicesV2 } from 'services/servicesV2';
import { z } from 'zod';

import { UNCOMMON_EXAMINATION_TYPE } from './helpers';

type GetReportDataArgs = {
	cancelToken: CancelToken;
	examinationId: IDParam;
	patientId: PatientIdParam;
	examinationType: ExaminationTypeParam;
};

export const ExaminationReportV2Repository = {
	shareReportByRecipientEmail(data: ShareReportByRecipientEmailRequestModel) {
		return servicesV2.post(SHARE_REPORT_BY_RECIPIENT_EMAIL_API, data, {
			requestSchema: ShareReportByRecipientEmailRequestSchema,
			responseSchema: ShareReportByRecipientEmailResponseSchema
		});
	},
	downloadPDFReportByLink(data: DownloadPDFReportByLinkRequestModel) {
		return servicesV2.post(DOWNLOAD_REPORT_PDF_BY_LINK, data, {
			requestSchema: DownloadPDFReportByLinkRequestSchema,
			responseSchema: DownloadPDFReportByLinkResponseSchema
		});
	},

	generatePDFReport(data: DownloadReportRequestModel) {
		return servicesV2.post(DOWNLOAD_REPORT, data, {
			requestSchema: GeneratePDFReportRequestSchema,
			responseSchema: z.unknown(),
			responseType: 'blob',
			headers: {
				Accept: 'application/pdf'
			}
		});
	},
	getReportData({
		cancelToken,
		examinationId,
		examinationType,
		patientId
	}: GetReportDataArgs) {
		let baseUrl: string = GET_EXAMINATION_REPORT_DATA_API;
		if (!patientId) {
			baseUrl = GET_CALIBRATION_CHECK_REPORT_DATA_API;
		}
		const apiUrl = replacePatientIdQueryParam(
			replaceExaminationTypeQueryParam(
				replaceCommonIdQueryParam(baseUrl, examinationId),
				examinationType
			),
			patientId
		);
		return servicesV2.get(apiUrl, {
			cancelToken,
			requestSchema: z.undefined(),
			responseSchema: z.union([
				ForcedSpirometryReportSchema,
				SlowVitalCapacitySVCReportSchema,
				BronchodilatorReportSchema,
				PeakFlowReportSchema,
				PulseOximetryReportSchema,
				CalibrationCheckReportSchema
			])
		});
	},
	getNote({
		examinationId,
		examinationType
	}: {
		examinationId: IDParam;
		examinationType: ExaminationTypeParam;
	}) {
		const apiUrl = replaceExaminationTypeQueryParam(
			replaceCommonIdQueryParam(
				GET_EXAMINATION_REPORT_NOTE_API,
				examinationId
			),
			UNCOMMON_EXAMINATION_TYPE[examinationType]
		);

		return servicesV2.get(apiUrl, {
			requestSchema: z.undefined(),
			responseSchema: ExaminationNoteSchema
		});
	}
};
