import { convertDecimalNumber } from 'helpers/numbers';

import { HEIGHT_RANGE, UNITS_TYPES, WEIGHT_RANGE } from '../variables';

const [kg, lbs] = Object.keys(WEIGHT_RANGE);
const [m, cm, inch, ft] = Object.keys(HEIGHT_RANGE);

const SI_UNITS_CONVERSION_VALUES = {
	//height
	[m]: 1,
	[cm]: 100,
	[inch]: 39.3701,
	[ft]: 3.28084,
	//weight
	[kg]: 1,
	[lbs]: 2.20462,
	//flow
	[UNITS_TYPES.L_S.unit]: 1,
	[UNITS_TYPES.L_M.unit]: 60
} as const;

export type Value = string | number | null | undefined;
export type Unit = string;
/**
 * convert value to new given unit
 * @param {Value} value value to be converted
 * @param {Unit} currentUnit given value current unit
 * @param {Unit} newUnit new value unit to be converted in
 * @returns new value converted to given new unit
 */
export const convertValueUnit = (
	value: number,
	currentUnit: Unit,
	newUnit: Unit
) => {
	if (value === undefined) return value;
	if (Number(value) === 0) return value;
	validateArgs(value, newUnit);
	const valueSIUnit = convertValueToSIUnit(Number(value), currentUnit);

	const convertedValue = valueSIUnit * SI_UNITS_CONVERSION_VALUES[newUnit];
	switch (newUnit) {
		case inch:
		case ft:
		case m:
		case UNITS_TYPES.L_S.unit:
			return convertDecimalNumber(convertedValue);
	}
	return convertDecimalNumber(convertedValue, 0);
};

/**
 * converts given value with unit to SI system unit representation
 * @param {number} value value to be converted
 * @param {string} unit value unit
 * @returns value converted to SI system unit e.g. m or kg
 */
const convertValueToSIUnit = (value: number, unit: Unit) => {
	validateArgs(value, unit);
	return Number(value / SI_UNITS_CONVERSION_VALUES[unit]);
};

const validateArgs = (value: Value, unit: Unit): void => {
	if (
		(!value && value != 0) ||
		!unit ||
		!SI_UNITS_CONVERSION_VALUES?.[unit]
	) {
		throw new Error(
			`Given value: ${value || null} or Unit: ${
				unit || null
			} are not defined, or their values are illegal!`
		);
	}
};
