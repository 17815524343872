const paramConnect = (prevParam, nextParam) => {
	if (prevParam !== '') {
		return nextParam !== '' ? '&' : '';
	} else if (prevParam === '' && nextParam !== '') {
		return '?';
	}
	return '';
};

export const setQueryParams = (options, columns) => {
	const { sortBy, globalFilter } = options;

	let sortParams = '';

	let globalFilterParam = globalFilter ? `search[value]=${globalFilter}` : '';

	if (sortBy.length > 0) {
		const findColumns = columns.findIndex(
			(columns) => columns.name === sortBy[0].id
		);
		const sortIndex = `order[0][column]=${findColumns}`;
		const sortDir = `order[0][dir]=${sortBy[0].desc ? 'asc' : 'desc'}`;
		sortParams = `${sortIndex}&${sortDir}`;
	}

	const isSortParams = sortParams === '' ? '' : `?${sortParams}`;
	const isGlobalFilterParams =
		globalFilterParam === '' ? '' : globalFilterParam;

	return `${isSortParams}${paramConnect(
		isSortParams,
		globalFilterParam
	)}${isGlobalFilterParams}`;
};

export const setFilterParams = (filters, columns, queryParams) => {
	if (filters) {
		const filtersParams = Object.entries(filters)
			.reduce((acc, next) => {
				const index = columns.findIndex(({ name }) => name === next[0]);
				next[1] &&
					acc.push(`columns[${index}][search][value]=${next[1]}`);
				return acc;
			}, [])
			.join('&');

		const isQueryParams = queryParams.includes('?');
		return isQueryParams ? `&${filtersParams}` : `?${filtersParams}`;
	}

	return '';
};
