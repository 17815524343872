import { PulseOximetryPreviewModel } from 'models/Examinations/allExaminationsTypes';

import { PulseOximetry } from '../common/PulseOximetry';
import { IParameterTooltip } from './common';

interface IPulseOximetryTooltip {
	getHrTooltip: () => IParameterTooltip;
	getSpO2Tooltip: () => IParameterTooltip;
}

export class PulseOximetryTooltip
	extends PulseOximetry
	implements IPulseOximetryTooltip
{
	constructor(data: PulseOximetryPreviewModel) {
		super(data);
	}

	public getHrTooltip = (): IParameterTooltip => ({
		bestValue: this.getHrValues()
	});

	public getSpO2Tooltip = (): IParameterTooltip => ({
		bestValue: this.getSpO2Values()
	});
}
