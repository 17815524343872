import { getPulseOximetryExaminationParameterColors } from 'helpers/examinations/colors';

import {
	IParameterValuesData,
	ParameterValuesData
} from '../../ParameterValuesData';
import { ICommonParameterBuilder } from './CommonParametersDirector';

export class SpO2ValueBuilder implements ICommonParameterBuilder {
	private commonParameterData: IParameterValuesData;

	constructor() {
		this.commonParameterData = new ParameterValuesData();
	}

	reset = () => {
		this.commonParameterData = new ParameterValuesData();
	};

	setValidator = () => {
		this.commonParameterData.setValidator((v) => v >= 0);
	};

	setValue = (v) => {
		this.commonParameterData.setValue(v?.toFixed(0) ?? null);
	};

	setColor = (c) => {
		const color = getPulseOximetryExaminationParameterColors({
			status: { spO2: c },
			examinationValues: { spO2: this.commonParameterData.value },
			EXAMINATION_VALUES_KEYS: { spO2: { key: 'spO2' } },
			valueAdditionalValidation: (v) => v >= 0
		});
		this.commonParameterData.setColor(color.spO2);
	};

	setUnit = (u) => {
		this.commonParameterData.setUnit(u);
	};

	getValue = () => {
		return this.commonParameterData;
	};
}
