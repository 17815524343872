import { RESULTS_COLORS } from './colors';

export const getColor = (value, greenLimit, yellowLimit) => {
	if (value > greenLimit) return RESULTS_COLORS[0];
	if (value <= greenLimit && value > yellowLimit) return RESULTS_COLORS[1];

	return RESULTS_COLORS[2];
};

export function validateColor(color) {
	return color ?? RESULTS_COLORS[3];
}
