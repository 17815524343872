import { FIELD_TYPES } from 'helpers';

export const ALLERGY_INITIAL_VALUES = {
	name: '',
	description: '',
	date: new Date(),
	source: null,
	external_id: null,
	reaction_type: null,
	visible_on_patients_card: 'show'
};

export const ALLERGY_FIELDS = [
	{
		label: 'patients.allergies.form.field.name',
		type: 'text',
		name: 'name'
	},
	{
		label: 'patients.allergies.form.field.description',
		type: FIELD_TYPES.DESCRIPTION,
		name: 'description'
	},
	{
		label: 'patients.allergies.form.field.date',
		type: FIELD_TYPES.DATE,
		name: 'date'
	},
	{
		label: 'patients.allergies.form.field.visible_on_patients_card',
		type: FIELD_TYPES.RADIO,
		name: 'visible_on_patients_card',
		options: [
			{
				value: 'show',
				label: 'patients.allergies.form.show'
			},
			{
				value: 'hide',
				label: 'patients.allergies.form.hide'
			}
		]
	}
];
