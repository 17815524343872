import { ReportParameterModel } from 'models/Report/common';
import { ExaminationsListServicesV2 } from 'queries';

import { ColumnDataImpl, ColumnValues } from './createColumnData';

type ParameterKey = Leaves<ColumnValues<ReportParameterModel>>;

export const PARAMETERS_TO_UNITS_RECALCULATIONS: ParameterKey[] = [
	'lln',
	'value',
	'predicted'
];

export const OBSTRUCTION_PERCENTILE_FEV1_FVC = 5;
export const OBTURATION_LEVELS_LIST = [
	{
		label: 'report.obstruction.mild',
		percentage: '>=70%'
	},
	{
		label: 'report.obstruction.moderate',
		percentage: '60-69%'
	},
	{
		label: 'report.obstruction.moderately_severe',
		percentage: '50-59%'
	},
	{
		label: 'report.obstruction.severe',
		percentage: '35-49%'
	},
	{
		label: 'report.obstruction.very_severe',
		percentage: '&#60;35%'
	}
];

export const convertToColumnsData = <TData>(
	data: TData,
	parameters: Record<string, { label: string; getUserUnit: () => string }>
): ColumnValues<ReportParameterModel>[] => {
	if (!data) {
		return [];
	}

	const convertedData =
		ExaminationsListServicesV2.convertPlainValuesToParameter<TData>(data);

	const columnData = new ColumnDataImpl<
		{ [k in keyof TData]: ReportParameterModel },
		ReportParameterModel
	>(parameters, convertedData);
	columnData.createColumnData();

	return columnData.columnData;
};
