import { Typography as MuiTypography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { addDays } from 'date-fns';
import { bool, number, object, string } from 'prop-types';
import React from 'react';

import { ItemWrapper } from './components';

const useStyles = makeStyles(({ palette }) => ({
	message: {
		overflowWrap: 'break-word',
		color: ({ isUser }) => (isUser ? palette.white : palette.black)
	}
}));

const Message = ({
	message,
	author_id: authorId,
	userId,
	created_at,
	next,
	...rest
}) => {
	const isUser = authorId === userId;
	const isNextUser = next.author_id === userId;
	const classes = useStyles({ isUser });

	if (!message) return null;

	return (
		<ItemWrapper
			isUser={isUser}
			isNextUser={isNextUser}
			nextMessageTimestamp={(next?.created_at
				? new Date(next?.created_at)
				: addDays(new Date(), 1)
			).getTime()}
			messageTimestamp={new Date(created_at).getTime()}
			{...rest}
		>
			<MuiTypography
				className={classes.message}
				variant="body2"
				component="p"
			>
				{message}
			</MuiTypography>
		</ItemWrapper>
	);
};

Message.propTypes = {
	message: string,
	author_id: number.isRequired,
	userId: number.isRequired,
	viewed: bool.isRequired,
	created_at: string.isRequired,
	next: object.isRequired
};

export default Message;
