import { Box, Typography } from '@material-ui/core';
import { ReactComponent as RedMinusIcon } from 'assets/icons/minus_red.svg';
import { ReactComponent as GreenPlusIcon } from 'assets/icons/plus_green.svg';
import clsx from 'clsx';
import { useTranslations } from 'components/utilities';
import { number } from 'prop-types';
import React from 'react';

import { useGlobalStyles } from '../styles';
import { useStyles } from './styles';

const TestResult = ({ positiveResponseFlag }) => {
	const globalClasses = useGlobalStyles();
	const classes = useStyles();

	const { t } = useTranslations();

	const icon = React.useMemo(() => {
		if (Number(positiveResponseFlag) === 1) {
			return <GreenPlusIcon className={classes.icon} />;
		}

		return <RedMinusIcon className={classes.icon} />;
		//eslint-disable-next-line
	}, []);

	return (
		<Box className={globalClasses.box} justifyContent="space-between">
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				flex={1}
			>
				<Typography
					variant="h6"
					component="p"
					className={clsx([globalClasses.result, classes.result])}
				>
					{t('patients.examinations.details.bronchodilator.title')}
				</Typography>
			</Box>
			<Box className={classes.iconWrapper}>{icon}</Box>
		</Box>
	);
};

TestResult.propTypes = {
	positiveResponseFlag: number.isRequired
};

export default TestResult;
