import { SvcPreviewModel } from 'models/Examinations/allExaminationsTypes';

import {
	CommonParametersDirector,
	ICValueBuilder,
	VCValueBuilder
} from '../Parameters';

export class Svc {
	private ic: CommonExaminationParameter;
	private vc: CommonExaminationParameter;

	constructor(data: SvcPreviewModel) {
		this.ic = data.ic;
		this.vc = data.vc;
	}

	protected getIcValues = () => {
		const director = new CommonParametersDirector(this.ic);
		const icValueBuilder = new ICValueBuilder();
		director.createIcValue(icValueBuilder);

		return icValueBuilder.getValue();
	};

	protected getVcValues = () => {
		const director = new CommonParametersDirector(this.vc);
		const vcValueBuilder = new VCValueBuilder();
		director.createVcValue(vcValueBuilder);

		return vcValueBuilder.getValue();
	};
}
