import { makeStyles } from '@material-ui/styles';

import { FONT } from '../styles';

export const useStyles = makeStyles(() => ({
	root: { width: '100%', alignSelf: 'flex-start' }
}));

export const useTechnicalNoteStyles = makeStyles(() => ({
	font: {
		...FONT,
		fontSize: '12px',
		lineHeight: '13.4px',
		fontWeight: 400
	},
	title: {
		...FONT
	}
}));
