import { Box } from '@material-ui/core';
import React from 'react';
import ReactCodeInput, {
	InputModeTypes,
	ReactCodeInputProps
} from 'react-code-input';

import { InputErrorLabel } from '../../../atoms';
import { INPUT_STYLES_INVALID, useStyles } from './styles';
type Props = Omit<ReactCodeInputProps, 'inputMode'> & {
	inputMode?: InputModeTypes;
	errorMessage?: string;
	isErrorMessage?: boolean;
};

const CodeInput = React.forwardRef(
	(
		{
			errorMessage = '',
			inputMode = 'numeric',
			isValid,
			isErrorMessage,
			...rest
		}: Props,
		ref
	) => {
		const classes = useStyles();
		return (
			<Box data-testid={'code-input'}>
				<ReactCodeInput
					//eslint-disable-next-line
					// @ts-ignore
					ref={ref}
					className={classes.root}
					type="password"
					fields={6}
					inputMode={inputMode}
					inputStyleInvalid={INPUT_STYLES_INVALID}
					isValid={isValid}
					{...rest}
				/>
				{(!isValid || isErrorMessage) && errorMessage && (
					<InputErrorLabel message={errorMessage} />
				)}
			</Box>
		);
	}
);

export default CodeInput;
