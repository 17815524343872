// Import actions

import { INITIAL_STATE } from './helpers';
import {
	resetDosageState,
	setDosage,
	setDosageDays,
	setDosageForm,
	setDosageType,
	setInhalerPurpose,
	setInitialFormData,
	setMedicationIndex,
	setMedicationName,
	setNote,
	setTaking,
	setUntil,
	toggleMedicationForm
} from './store/actions';

const useActions = ({ dispatch }) => {
	const handleToggleMedicationForm = () => toggleMedicationForm()(dispatch);

	const handleSetMedicationName = (name) => setMedicationName(name)(dispatch);

	const handleSetDosage = (dosage) => setDosage(dosage)(dispatch);

	const handleResetDosageState = () => {
		resetDosageState()(dispatch);
		handleSetDosageForm(INITIAL_STATE.dosage_form);
		handleSetInhalerPurpose(INITIAL_STATE.type);
		handleSetNote(INITIAL_STATE.note);
	};

	const handleSetDosageDays = (days) => setDosageDays(days)(dispatch);

	const handleSetDosageType = (type) => setDosageType(type)(dispatch);

	const handleSetTaking = (taking) => setTaking(taking)(dispatch);

	const handleSetUntil = (until) => setUntil(until)(dispatch);

	const handleMedicationIndex = (index) =>
		setMedicationIndex(index)(dispatch);

	const handleSetDosageForm = (value) => setDosageForm(value)(dispatch);

	const handleSetInhalerPurpose = (purpose) =>
		setInhalerPurpose(purpose)(dispatch);

	const handleSetNote = (note) => setNote(note)(dispatch);
	const handleSetInitialFormData = (note) =>
		setInitialFormData(note)(dispatch);

	return {
		toggleMedicationForm: handleToggleMedicationForm,
		setMedicationName: handleSetMedicationName,
		resetDosageState: handleResetDosageState,
		setDosage: handleSetDosage,
		setDosageDays: handleSetDosageDays,
		setDosageType: handleSetDosageType,
		setTaking: handleSetTaking,
		setUntil: handleSetUntil,
		setMedicationIndex: handleMedicationIndex,
		setDosageForm: handleSetDosageForm,
		setInhalerPurpose: handleSetInhalerPurpose,
		setNote: handleSetNote,
		setInitialFormData: handleSetInitialFormData
	};
};

export default useActions;
