import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	row: {
		backgroundColor: 'inherit',
		'& > :first-child': {
			paddingLeft: 10
		},
		'& > :last-child': {
			paddingRight: 10
		}
	}
}));
