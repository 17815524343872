import { LoaderSpinner, RouterSwitchWithTransition } from 'components/elements';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { PATIENTS_ROUTES } from './helpers';
import { DASH_PATHS } from './paths';

const PatientsRoutes = () => {
	return (
		<React.Suspense fallback={<LoaderSpinner />}>
			<RouterSwitchWithTransition routes={PATIENTS_ROUTES}>
				<Redirect
					exact
					from={DASH_PATHS.PATIENTS}
					to={PATIENTS_ROUTES[0].path}
				/>
				{PATIENTS_ROUTES.map((route) => (
					<Route
						key={route.path}
						path={route.path}
						render={(matchProps) => (
							<>
								{route.helmet}
								<route.component {...matchProps} />
							</>
						)}
					/>
				))}

				<Redirect to="/not-found" />
			</RouterSwitchWithTransition>
		</React.Suspense>
	);
};

export default PatientsRoutes;
