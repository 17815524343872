import { ForcedSpirometryReportModel } from 'models/Report/Reports';
import React from 'react';

import { useGetCachedExaminationDetailsData } from '../../hooks/useGetCachedExaminationDetailsData';

export const useSpirometryFVCData = () => {
	const data =
		useGetCachedExaminationDetailsData<ForcedSpirometryReportModel>();

	const memoizedData = React.useMemo(() => data, []);
	return memoizedData;
};
