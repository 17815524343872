import { TextField } from '@material-ui/core';
import { useTranslations } from 'components/utilities';
import { bool, string } from 'prop-types';
import React from 'react';
import { Field, useFormState } from 'react-final-form';

import { TextFieldAdapter } from '../../Inputs';

const DrugsField = ({
	isForm = false,
	className,
	name,
	label = 'patients.drugs.form.search_drugs',
	...rest
}) => {
	const { t } = useTranslations();
	const { submitting } = useFormState();

	const fieldProps = {
		className,
		fullWidth: true,
		type: 'text',
		margin: 'dense',
		variant: 'outlined',
		autoComplete: 'false',
		name,
		label: t(label),
		disabled: submitting,
		required: true,
		...rest
	};
	return isForm ? (
		<TextField {...fieldProps} />
	) : (
		<Field component={TextFieldAdapter} {...fieldProps} />
	);
};

DrugsField.propTypes = {
	isForm: bool,
	name: string.isRequired,
	label: string,
	className: string
};

export default DrugsField;
