import { produce } from 'immer';
import * as types from 'store/types';

const INITIAL_STATE = {
	name: '',
	country: ''
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.SET_GEO_LOCATION:
				draft.country = action.payload.country;
				draft.name = action.payload.name ?? '';
				return;

			default:
				return state;
		}
	});
