import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { usePasswordChecker } from 'components/utilities';
import { string } from 'prop-types';
import React from 'react';

import CheckerInfo from './CheckerInfo';
import { useStyles } from './styles';

const PasswordChecker = ({ password }) => {
	const classes = useStyles();
	const { validateInfo, isPasswordValid, isPasswordCheckerNotEmpty } =
		usePasswordChecker({ password });

	if (!isPasswordCheckerNotEmpty || isPasswordValid) return null;
	return (
		<Box className={classes.box}>
			<Box className={clsx([classes.content])}>
				{validateInfo.map(({ text, ...rest }) => (
					<CheckerInfo key={text} text={text} {...rest} />
				))}
			</Box>
		</Box>
	);
};

PasswordChecker.propTypes = {
	password: string.isRequired
};

export default PasswordChecker;
