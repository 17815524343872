import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { number, object, string } from 'prop-types';
import React from 'react';

import { ContainerWithBorder } from '../../ContainerWithBorder';
import { Title } from '../../Typography';
import Question from '../Question/Question';
import { getAnswer } from './helpers';
import { useStyles } from './styles';

export const Questions = ({
	questions,
	className = '',
	spacing = 6,
	classNameAnswer = '',
	answers
}) => {
	const classes = useStyles();

	return (
		<ContainerWithBorder className={clsx([classes.root, className])}>
			<Title
				title={
					'patients.examinations.details.spirometry.additional_questions'
				}
			/>
			<Box className={classes.questions}>
				{Object.entries(questions).map(([key, question]) => (
					<Question
						key={key}
						question={question}
						answer={getAnswer(answers?.[key])}
						spacing={spacing}
						classNameAnswer={classNameAnswer}
					/>
				))}
			</Box>
		</ContainerWithBorder>
	);
};

Questions.propTypes = {
	questions: object.isRequired,
	classNameAnswer: string,
	spacing: number,
	className: string,
	answers: object
};
