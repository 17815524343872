import { FormControl, Input, InputLabel, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { node, object } from 'prop-types';
import React from 'react';

import { InputError } from '../../atoms';

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 180,
		maxWidth: 400
	},
	chips: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	chip: {
		margin: 2
	}
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250
		}
	}
};

const MultiselectFiledAdapter = ({ input, meta, children, ...rest }) => {
	const classes = useStyles();
	const error =
		meta.touched && (meta.error || meta.submitError) ? true : false;

	return (
		<FormControl error={error} className={classes.formControl}>
			<InputLabel id="mutiple-chip-label">Payment methods</InputLabel>
			<Select
				{...input}
				{...rest}
				labelId="mutiple-chip-label"
				multiple
				input={<Input />}
				MenuProps={MenuProps}
			>
				{children}
			</Select>
			<InputError meta={meta} />
		</FormControl>
	);
};

MultiselectFiledAdapter.propTypes = {
	input: object.isRequired,
	meta: object.isRequired,
	children: node.isRequired
};

export default MultiselectFiledAdapter;
