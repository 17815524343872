import {
	CardActions,
	CardContent,
	Table as MaterilaTable,
	TableBody,
	TableContainer,
	TableHead
} from '@material-ui/core';
import clsx from 'clsx';
import { CardTopBar } from 'components/elements/molecules';
import { TABLE_OPTIONS } from 'helpers/variables';
import { array, bool, func, node, object, oneOfType, string } from 'prop-types';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
	useGlobalFilter,
	usePagination,
	useSortBy,
	useTable as useRTTable
} from 'react-table';

import {
	EmptyTableContent,
	TableBodyRow,
	TableHeadRow,
	TableIdle,
	TablePagination,
	Toolbar
} from '../components';
import { setDefaultLabelDisplayRow } from '../helpers';
import { useTable, useTableProps } from '../hooks';
import { useStyles } from '../styles';

const { ROWS_PER_PAGE, PAGE_INDEX, PAGE_SIZE } = TABLE_OPTIONS;

const ControlledTable = ({
	className,
	classNames,
	isDefaultRow = true,
	rowsPerPageOptions = ROWS_PER_PAGE,
	labelDisplayedRows = setDefaultLabelDisplayRow,
	editLinkPath = '/',
	isToolbarHidden = false,
	manualRefetch = false,
	isCardTopBar = false,
	isBox = false,
	setManualRefetch,
	isInfiniteScroll = false,
	...props
}) => {
	const { toolbarComponent, children, ...rest } = props;

	// ------ Hooks ------
	const classes = useStyles({ isCardTopBar, isInfiniteScroll });

	const {
		getEmptyDataContentProps,
		getTableIdleProps,
		data,
		controlledPageCount,
		columns,
		userRolesData
	} = useTableProps();

	// Use the state and functions returned from useTable to build your UI
	const tableProps = useRTTable(
		{
			columns,
			data: data || [],
			initialState: {
				pageIndex: PAGE_INDEX,
				pageSize: PAGE_SIZE,
				globalFilter: ''
			}, // Pass our hoisted table state
			manualPagination: true,
			manualGlobalFilter: true,
			manualSortBy: true, // Tell the usePagination
			// hook that we'll handle our own data fetching
			// This means we'll also have to provide our own
			// pageCount.
			pageCount: controlledPageCount
		},
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	const {
		getPaginationProps,
		getToolbarProps,
		getTableProps,
		getTableBodyProps,
		prepareRow,
		headerGroups,
		page,
		pageIndex,
		pageSize
	} = useTable({
		tableProps,
		toolbarComponent,
		setManualRefetch,
		manualRefetch
	});

	return (
		<>
			<CardTopBar
				{...rest}
				isCardTopBar={isCardTopBar}
				isBox={isBox}
				className={clsx(classes.root, className)}
				cardContentClassName={classes.rootCardContent}
				classNameBox={classes.cardBox}
			>
				<TableIdle {...getTableIdleProps()} />
				{!isToolbarHidden && <Toolbar {...getToolbarProps()} />}
				<CardContent className={classes.content}>
					<EmptyTableContent {...getEmptyDataContentProps()} />
					<PerfectScrollbar>
						<TableContainer className={classes.inner}>
							<MaterilaTable
								{...getTableProps()}
								stickyHeader
								aria-label="table"
							>
								<TableHead
									className={classNames?.tableHeadClassName}
								>
									{headerGroups.map((headerGroup, index) => (
										<TableHeadRow
											key={headerGroup.headers[index].id}
											headerGroup={headerGroup}
										/>
									))}
								</TableHead>
								<TableBody
									{...getTableBodyProps()}
									className={classes.tableBody}
								>
									{page.map((row) => {
										prepareRow(row);
										return isDefaultRow ? (
											<TableBodyRow
												editLinkPath={editLinkPath}
												key={row.id}
												row={row}
												userRolesData={userRolesData}
											/>
										) : (
											children({
												key: row.id,
												row,
												editLinkPath,
												userRolesData
											})
										);
									})}
								</TableBody>
							</MaterilaTable>
						</TableContainer>
					</PerfectScrollbar>
				</CardContent>

				<CardActions className={classes.actions}>
					<TablePagination
						labelDisplayedRows={labelDisplayedRows}
						rowsPerPageOptions={rowsPerPageOptions}
						pageIndex={pageIndex}
						pageSize={pageSize}
						direction={'ltr'}
						{...getPaginationProps()}
					/>
				</CardActions>
			</CardTopBar>
		</>
	);
};

ControlledTable.propTypes = {
	className: string,
	tableHeadClassName: string,
	rowsPerPageOptions: array,
	children: func,
	labelDisplayedRows: func,
	isDefaultRow: bool,
	isToolbarHidden: bool,
	toolbarComponent: oneOfType([node, func]),
	editLinkPath: string,
	manualRefetch: bool,
	isCardTopBar: bool,
	isBox: bool,
	setManualRefetch: func,
	isInfiniteScroll: bool,
	classNames: object
};

export default ControlledTable;
