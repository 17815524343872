import { SnackbarCloseReason, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { NotistackSnackbar } from 'components/elements';
import { SETTINGS_PATHS } from 'components/routes/paths';
import { useTranslations } from 'components/utilities';
import { CustomContentProps } from 'notistack';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Cookies } from 'utilities';

import { MessageProps } from '../../../elements/molecules/Snackbar/Snackbar';
import { useStyles } from './styles';

export type IReleaseNoteSnackbarProps = Omit<
	CustomContentProps,
	'variant' | 'message'
>;

export const ReleaseNoteSnackbar = React.forwardRef<
	HTMLDivElement,
	IReleaseNoteSnackbarProps
>(({ id, ...rest }, ref) => {
	const { tNoParser, t } = useTranslations();
	const classes = useStyles();

	const handleClose = (
		event: React.SyntheticEvent<AnyType>,
		reason: SnackbarCloseReason | 'close'
	) => {
		if (reason === 'clickaway') {
			return;
		}
		Cookies.releaseNote.accept();
	};

	const renderMessage = ({ getMessageProps }: MessageProps) => (
		<Typography {...getMessageProps()} component="span">
			{t('changelog.notification.description_1')}&nbsp;
			<NavLink
				onClick={(e) => {
					handleClose(e, 'close');
				}}
				{...getMessageProps()}
				className={clsx([classes.link, getMessageProps().className])}
				to={SETTINGS_PATHS.RELEASE_NOTES}
			>
				{t('changelog.title')}
			</NavLink>
			{t('changelog.notification.description_2')}
		</Typography>
	);

	return (
		<NotistackSnackbar
			ref={ref}
			id={id}
			key="some-key"
			contentVariant="primary"
			titleProps={{
				title: `🎉 ${tNoParser('changelog.notification.title')}`
			}}
			onClose={handleClose}
			{...rest}
			message={renderMessage}
		/>
	);
});
