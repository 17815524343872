import { BrowserTracing } from '@sentry/browser';
import {
	CaptureConsole as CaptureConsoleIntegration,
	ExtraErrorData as ExtraErrorDataIntegration
} from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { Integration } from '@sentry/types';

export class MySentry {
	constructor() {}

	static init() {
		Sentry.init({
			dsn: process.env.REACT_APP_SENTRY_DSN,
			environment: process.env.REACT_APP_NODE_ENV,
			integrations: [
				new BrowserTracing({
					tracePropagationTargets: [
						process.env.REACT_APP_API_URL as string
					]
				}),
				new ExtraErrorDataIntegration() as Integration,
				new CaptureConsoleIntegration({
					levels: ['warn', 'error']
				}) as Integration
			],
			beforeSend(event) {
				if (event.message?.includes('Highcharts warning #15')) {
					return null;
				}
				return event;
			},
			ignoreErrors: [/Highcharts warning #15/gm],
			release:
				process.env.REACT_APP_NODE_ENV === 'frp1'
					? process.env.REACT_APP_VERSION
					: `${process.env.REACT_APP_VERSION}-DEV`,
			attachStacktrace: true,
			tracesSampleRate:
				process.env.REACT_APP_NODE_ENV === 'frp1' ? 0.1 : 1.0
		});
	}
}
