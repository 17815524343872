import { z } from 'zod';

export const PatientLinkWithDoctorRequestSchema = z.object({
	id: z.union([z.string(), z.number()]),
	email: z.string().email(),
	message: z.string()
});
export const PatientLinkWithDoctorResponseSchema = z.object({
	invitationSent: z.boolean()
});

export const PatientUnlinkFromDoctorRequestSchema = z.object({
	id: z.union([z.string(), z.number()])
});
export const PatientUnlinkFromDoctorResponseSchema = z.object({
	status: z.literal('OK')
});

export const PatientLinkInvitationAcceptanceRequestSchema = z.undefined();
export const PatientLinkInvitationAcceptanceResponseSchema = z.object({
	patient_user_id: z.union([z.number(), z.string()]),
	status: z.boolean(),
	user_id: z.union([z.number(), z.string()])
});

export type PatientUnlinkFromDoctorModel = z.infer<
	typeof PatientUnlinkFromDoctorResponseSchema
>;
export type PatientUnlinkFromDoctorRequestModel = z.infer<
	typeof PatientUnlinkFromDoctorRequestSchema
>;
export type PatientLinkWithDoctorModel = z.infer<
	typeof PatientLinkWithDoctorResponseSchema
>;
export type PatientLinkWithDoctorRequestModel = z.infer<
	typeof PatientLinkWithDoctorRequestSchema
>;
export type PatientLinkInvitationAcceptanceRequestModel = z.infer<
	typeof PatientLinkInvitationAcceptanceRequestSchema
>;
export type PatientLinkInvitationAcceptanceModel = z.infer<
	typeof PatientLinkInvitationAcceptanceResponseSchema
>;
