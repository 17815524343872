import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		textAlign: 'center',
		marginTop: 20
	},
	typography: {
		fontWeight: 400,
		lineHeight: '12px',
		color: theme.palette.gray.medium
	},
	version: {
		marginBottom: 12
	}
}));
