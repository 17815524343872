import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		width: '100%',
		position: 'relative',
		overflow: 'hidden'
	},
	input: {
		fontWeight: 400
	},
	placeholderText: {
		fontSize: '20px',
		lineHeight: '20px',
		textTransform: 'uppercase'
	},
	placeholderRoot: {
		cursor: 'pointer',
		height: 'auto'
	}
}));
