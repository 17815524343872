import { Tooltip } from 'components/elements/molecules';
import React from 'react';

import { LegendLabel } from './LegendLabel';
import { useStyles } from './styles';
import { TooltipContent } from './TooltipContent';

const ChartLegend = () => {
	const classes = useStyles();
	return (
		<Tooltip
			tooltipContent={TooltipContent}
			classNamePopoverWrapper={classes.popover}
		>
			<LegendLabel />
		</Tooltip>
	);
};

export default ChartLegend;
