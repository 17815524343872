import { Grid } from '@material-ui/core';
import { Password } from 'components/elements';
import { bool } from 'prop-types';
import React from 'react';

import useFields from './useFields';

const Fields = ({ submitting }) => {
	useFields();
	return (
		<Grid container spacing={2}>
			<Password submitting={submitting} />
		</Grid>
	);
};

Fields.propTypes = {
	submitting: bool.isRequired
};

export default Fields;
