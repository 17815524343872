import { createTheme } from '@material-ui/core/styles';

import calendar from './calendar';
import overrides from './overrides';
import palette from './palette';
import typography from './typography';

const theme = createTheme({
	palette,

	typography: {
		fontFamily: ['Geomanist', 'Open Sans'].join(','),
		...typography
	},
	overrides,
	zIndex: {
		appBar: 1200,
		drawer: 1100
	},
	calendar
});

export default theme;
