import { Box, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { useOpacityTransitionStyles } from 'theme/styles/transitions';

import { useStyles } from './styles';

type Props = {
	size?: number;
	maxWidth?: number;
	enableBackground?: boolean;
	className?: string;
	isLoading: boolean;
	children: CmpChildren;
};

const TRANSITION_TIMEOUT = 250;

const ContainerLoaderSpinner = ({
	isLoading,
	children,
	className = '',
	enableBackground = false,
	size = 30,
	maxWidth = 338
}: Props) => {
	const classes = useStyles({ maxWidth, enableBackground });
	const transitionClasses = useOpacityTransitionStyles({
		timeout: TRANSITION_TIMEOUT
	});

	return (
		<Box className={clsx([className, classes.root])}>
			{children}
			<CSSTransition
				mountOnEnter
				unmountOnExit
				in={isLoading}
				timeout={TRANSITION_TIMEOUT}
				classNames={{ ...transitionClasses }}
			>
				<Box className={classes.loaderContainer}>
					<CircularProgress
						size={size}
						color="primary"
						data-testid="LoaderSpinner-CircularProgress"
					/>
				</Box>
			</CSSTransition>
		</Box>
	);
};

export default ContainerLoaderSpinner;
