import {
	Button as MuiButton,
	Divider,
	Grid,
	Hidden,
	ListItem,
	Typography
} from '@material-ui/core';
import clsx from 'clsx';
import { RouterNavLink } from 'components/elements';
import { useTranslations } from 'components/utilities';
import { bool, object, string } from 'prop-types';
import { generatePath } from 'react-router-dom';
import { useParams } from 'utilities';

import { useGlobalStyles } from '../styles';
import { useStyles } from './style';

const NavItem = ({ label, icon, path, pathBase, isInfo, isLastItem }) => {
	const classes = useStyles();
	const classesGlobal = useGlobalStyles();

	const { t } = useTranslations();
	const params = useParams();

	const pathname = generatePath(path, params);

	const isVisibleMd = isLastItem ? [] : ['xs', 'sm'];
	const isVisibleSm = isLastItem ? [] : ['xs', 'sm'];

	return (
		<Grid
			key={label}
			className={clsx(classesGlobal.gridItem)}
			item
			lg={true}
			md={true}
			sm={true}
			xs={6}
		>
			<ListItem
				className={clsx(classesGlobal.item, classes.item)}
				disableGutters
			>
				<MuiButton
					activeClassName={classes.active}
					className={classes.button}
					component={RouterNavLink}
					to={pathname}
					pathBase={pathBase}
					startIcon={icon}
				>
					<Typography component="p">{t(label)}</Typography>
				</MuiButton>
			</ListItem>
			{!isLastItem && (
				<>
					{!isInfo && (
						<Hidden only={isVisibleMd}>
							<Divider
								className={classes.divider}
								orientation="vertical"
							/>
						</Hidden>
					)}
					{isInfo && (
						<Hidden only={isVisibleSm}>
							<Divider
								className={classes.divider}
								orientation="vertical"
							/>
						</Hidden>
					)}
				</>
			)}
		</Grid>
	);
};

NavItem.propTypes = {
	label: string,
	icon: object,
	path: string,
	isInfo: bool,
	isLastItem: bool,
	pathBase: string
};

export default NavItem;
