import { KeyboardDatePickerField } from 'components/elements';
import React from 'react';

import { useStyles } from '../styles';
import useDateField from './useDateField';

const DateField = () => {
	const classes = useStyles();

	const { isAppointmentReview, submitting, onChange } = useDateField();

	return (
		<KeyboardDatePickerField
			name="start_date"
			label={'schedule.form.labels.start'}
			margin="dense"
			inputVariant="outlined"
			required={!isAppointmentReview}
			disabled={submitting || isAppointmentReview}
			onChange={onChange}
			classNameInput={classes.buttonAdornment}
			disablePast={true}
			disableFuture={false}
			autoOk
		/>
	);
};

export default DateField;
