import React from 'react';

import { AttemptsArrowSelector } from '../../../components';
import { AttemptNumber } from './AttemptNumber';
import { useArrowSelectors, UseArrowSelectorsProps } from './useArrowSelectors';

type Props = UseArrowSelectorsProps & {
	type: 'pre' | 'post';
	color?: string;
};
export const ArrowSelectors = ({ type, color, ...rest }: Props) => {
	const { getSelectorActions, activeMeasurementIndex } = useArrowSelectors({
		...rest,
		type
	});

	return (
		<AttemptsArrowSelector
			{...getSelectorActions()}
			attemptComponent={({ attemptNumber, isBestIndex, index }) => (
				<AttemptNumber
					{...rest}
					color={color}
					type={type}
					attemptNumber={attemptNumber}
					activeMeasurementIndex={activeMeasurementIndex}
					isBest={isBestIndex === index}
				/>
			)}
		/>
	);
};
