import {
	Box,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	CircularProgress,
	Grid,
	Link
} from '@material-ui/core';
import clsx from 'clsx';
import { ButtonProgress } from 'components/elements/atoms';
import { Alert } from 'components/elements/molecules';
import { useForm, useTranslations } from 'components/utilities';
import arrayMutators from 'final-form-arrays';
import {
	array,
	bool,
	func,
	object,
	oneOfType,
	shape,
	string
} from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';
import { Link as RouterLink } from 'react-router-dom';

import { useStyles } from './styles';

const FormCardLayoutRPC = ({
	validation,
	QUERY_KEY = '',
	fetchFormResAction = () => {},
	submitFormAction,
	goBackPath = '',
	isGoBackAction = true,
	isCardLayout = true,
	initialValues = {},
	title,
	actionButtons = true,
	classNameContent = '',
	classNameRoot = '',
	queryOptions,
	style,
	children
}) => {
	const classes = useStyles();
	const { t } = useTranslations();

	const {
		data,
		patientId,
		isEdit,
		isResourcesError,
		isSubmitError,
		isSubmitLoading,
		isResourcesLoading,
		apiError,
		submitForm
	} = useForm({
		fetchFormResAction,
		submitFormAction,
		QUERY_KEY,
		goBackPath,
		isGoBackAction,
		queryOptions
	});

	const handleOnSubmit = async (values) => {
		try {
			return await submitForm(values);
		} catch (error) {
			return error?.response?.data?.validator?.errors || error;
		}
	};

	const renderActionButtons = (submitting) => (
		<CardActions classes={{ root: classes.cardActions }}>
			{!submitting && (
				<Link component={RouterLink} to={goBackPath} variant="h6">
					{t('btn.cancel')}
				</Link>
			)}
			<ButtonProgress
				className={classes.buttonSave}
				type="submit"
				color="primary"
				variant="contained"
				isLoading={submitting}
				disabled={submitting}
			>
				{t('btn.save')}
			</ButtonProgress>
		</CardActions>
	);

	const setInitialValues =
		data && Object.keys(data).length > 0 ? data : initialValues;

	const renderContent = () => (
		<Box className={clsx([classes.wrapper, classNameRoot])}>
			<Alert message={apiError} />
			{isCardLayout && title && <CardHeader title={title} />}

			{!data && isResourcesLoading && (
				<Box className={classes.loader}>
					<CircularProgress size={70} color="primary" />
				</Box>
			)}
			{(data || !isResourcesLoading) && !isResourcesError && (
				<Form
					initialValues={setInitialValues}
					keepDirtyOnReinitialize={true}
					onSubmit={handleOnSubmit}
					validate={validation}
					mutators={{ ...arrayMutators }}
					render={({ handleSubmit, submitting, values }) => (
						<form
							onSubmit={handleSubmit}
							className={classNameRoot}
							noValidate
						>
							{isCardLayout ? (
								<CardContent className={classNameContent}>
									{children({
										isEdit,
										patientId,
										data,
										values,
										apiError,
										submitting,
										isSubmitError,
										isSubmitLoading
									})}
								</CardContent>
							) : (
								children({
									isEdit,
									patientId,
									data,
									values,
									apiError,
									submitting,
									isSubmitError,
									isSubmitLoading
								})
							)}

							{isCardLayout
								? actionButtons && (
										<Card>
											{renderActionButtons(submitting)}
										</Card>
										//eslint-disable-next-line
								  )
								: actionButtons && (
										<Grid container spacing={4}>
											<Grid item lg={9} xs={12}>
												<Card>
													{renderActionButtons(
														submitting
													)}
												</Card>
											</Grid>
										</Grid>
										//eslint-disable-next-line
								  )}
						</form>
					)}
				/>
			)}
		</Box>
	);

	return isCardLayout ? (
		<Card style={style}>{renderContent()}</Card>
	) : (
		<Box style={style}>{renderContent()}</Box>
	);
};

FormCardLayoutRPC.propTypes = {
	submitFormAction: func.isRequired,
	children: func.isRequired,
	validation: func,
	QUERY_KEY: oneOfType([array, string]),
	fetchFormResAction: func,
	goBackPath: string,
	title: string,
	classNameContent: string,
	classNameRoot: string,
	isGoBackAction: bool,
	isCardLayout: bool,
	actionButtons: bool,
	initialValues: object,
	style: object,
	queryOptions: shape({
		onSuccess: func
	})
};

export default FormCardLayoutRPC;
