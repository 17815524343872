import { Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { DATE_FORMAT } from 'helpers';

import { useStyles } from './styles';

export interface DateTitleProps {
	date: Date;
}

export const DateTitle = ({ date }: DateTitleProps) => {
	const classes = useStyles();

	return (
		<Typography className={classes.title}>
			{format(date, DATE_FORMAT.DEFAULT)}
		</Typography>
	);
};
