import React from 'react';

import { TooltipHeader } from '../../../../../../../Tooltips';
import { ObturationLevelItem } from '../ObturationLevelItem';
import { OBTURATION_LEVELS_LIST } from './helpers';

const TooltipContent = () => {
	return (
		<>
			<TooltipHeader title="patients.examinations.details.bronchodilator.level_of_obstruction" />
			{OBTURATION_LEVELS_LIST.map(({ label, percentage }) => (
				<ObturationLevelItem
					key={percentage}
					value={percentage}
					labelKey={label}
				/>
			))}
		</>
	);
};

export default TooltipContent;
