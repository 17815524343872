import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { CALENDAR_VIEWS_VARIANTS } from 'helpers';

// Calendar plugins
export const CALENDAR_PLUGINS = [
	dayGridPlugin,
	timeGridPlugin,
	interactionPlugin
];

// Calendar settings
export const CALENDAR_SETTINGS = {
	dayHeaderFormat: { weekday: 'long', omitCommas: true },
	initialView: CALENDAR_VIEWS_VARIANTS.MONTH,
	eventBorderColor: 'transparent',
	slotDuration: '00:15:00',
	scrollTime: getStartTimeString(),
	slotLabelInterval: '1:00',
	slotLabelFormat: {
		hour: 'numeric',
		minute: '2-digit',
		omitZeroMinute: false,
		meridiem: 'short'
	},
	firstDay: 1,
	contentHeight: '100%',
	eventMinHeight: 20,
	selectable: true,
	selectMirror: true,
	dayMaxEventRows: true,
	footerToolbar: false,
	headerToolbar: false,
	showNonCurrentDates: false,
	fixedWeekCount: false,
	allDaySlot: false,
	handleWindowResize: true,
	slotEventOverlap: true,
	nowIndicator: true,
	unselectAuto: false
};

function getStartTimeString() {
	const today = new Date();
	const todayDate = today.toDateString();
	const todayHour = today.getHours();
	const calendarStartTime = new Date(
		`${todayDate} ${todayHour - 1}:00:00`
	).toLocaleTimeString();
	return calendarStartTime;
}

export const getCalendarSettings = (t) => ({
	...CALENDAR_SETTINGS,
	views: {
		dayGridMonth: {
			dayMaxEventRows: 4,
			moreLinkText: t('schedule.calendar.more_link_text')
		}
	}
});
