import { AxiosRequestConfig, CancelToken } from 'axios';
import { z } from 'zod';

import { api, ApiPayload } from './myAxiosFactory';

export type Options<Request, Response> = AxiosRequestConfig<Response> & {
	requestSchema: z.ZodType<Request>;
	responseSchema: z.ZodType<Response>;
	cancelToken?: CancelToken;
};

type RequestMethod<Response = AnyType> = (
	url: string,
	options: Options<undefined, Response>
) => Promise<Response>;

type RequestMethodWithPayload<Request = AnyType, Response = AnyType> = (
	url: string,
	payload: ApiPayload<Request>,
	options: Options<Request, Response>
) => Promise<Response>;

interface IApiServiceFactory {
	post: RequestMethodWithPayload;
	put: RequestMethodWithPayload;
	patch: RequestMethodWithPayload;
	delete: RequestMethodWithPayload;
	get: RequestMethod;
}

class ApiServicesFactory implements IApiServiceFactory {
	public post = <Request = AnyType, Response = AnyType>(
		url: string,
		payload: ApiPayload<Request>,
		options: Options<Request, Response>
	) =>
		api<Request, Response>('post')({
			url,
			payload,
			options
		});

	public put = <Request = AnyType, Response = AnyType>(
		url: string,
		options: Options<Request, Response>,
		payload?: ApiPayload<Request>
	) =>
		api<Request, Response>('put')({
			url,
			payload,
			options
		});

	public patch = <Request = AnyType, Response = AnyType>(
		url: string,
		options: Options<Request, Response>,
		payload?: ApiPayload<Request>
	) =>
		api<Request, Response>('patch')({
			url,
			payload,
			options
		});

	public delete = <Request = AnyType, Response = AnyType>(
		url: string,
		options: Options<Request, Response>,
		payload?: ApiPayload<Request>
	) =>
		api<Request, Response>('delete')({
			url,
			payload,
			options
		});

	public get = <Response = AnyType>(
		url: string,
		options: Options<undefined, Response>
	) =>
		api<undefined, Response>('get')({
			url,
			options
		});
}

export const servicesV2 = new ApiServicesFactory();
