import { getTimeOfDay } from './helpers';

const useTimeOfDay = ({ dosage, setTaking }) => {
	const { taking } = dosage;

	const handleTaking = (value) => {
		isTimeOfDayActive(value) ? removeTaking(value) : addTaking(value);
	};

	const addTaking = (value) => {
		const newTaking = [...taking];
		newTaking.push({ time_of_day: value, amount: '', applying_way: '' });
		setTaking(newTaking);
	};

	const removeTaking = (value) => {
		const newTaking = taking.filter((item) => item.time_of_day !== value);
		setTaking(newTaking);
	};

	const handleChangeAmount = ({ amountValue, value }) => {
		const newTaking = getTimeOfDay(taking, value);
		newTaking.amount = amountValue;

		setTaking(getNewTakingArray({ newTaking, value }));
	};

	const handleChangeApplyingWay = (applyingWayValue, value) => {
		const newTaking = getTimeOfDay(taking, value);
		newTaking.applying_way = applyingWayValue;

		setTaking(getNewTakingArray({ newTaking, value }));
	};

	const getNewTakingArray = ({ newTaking, value }) =>
		taking.map((item) => (item.time_of_day === value ? newTaking : item));

	const setVariant = (value) =>
		isTimeOfDayActive(value) ? 'contained' : 'outlined';

	const isTimeOfDayActive = (value) =>
		taking.find((item) => item?.time_of_day === value);

	return {
		setTakingArray: handleTaking,
		setVariant,
		setAmount: handleChangeAmount,
		setApplyingWay: handleChangeApplyingWay,
		isTimeOfDayActive
	};
};

export default useTimeOfDay;
