import { Box } from '@material-ui/core';
import { FormControl, MenuItem, Select } from '@material-ui/core';
// Import context
import { useScheduleCalendarContext } from 'components/context';
import { useSelectProps, useTranslations } from 'components/utilities';
import React from 'react';

import { viewVariants } from '../helpers';
import { useStyles } from './styles';

const ViewSelector = () => {
	const { t } = useTranslations();
	const classes = useStyles();
	const selectProps = useSelectProps();

	const { calendarView, setCalendarView } = useScheduleCalendarContext();

	const handleSetViewVariant = ({ target: { value } }) => {
		setCalendarView(value);
	};

	return (
		<Box className={classes.formControlWrapper}>
			<FormControl
				variant="outlined"
				className={classes.formControl}
				size="small"
				margin="dense"
			>
				<Select
					value={calendarView}
					id="view-variants"
					onChange={handleSetViewVariant}
					margin="dense"
					MenuProps={{ ...selectProps }}
				>
					{viewVariants.map(({ id, value, name }) => (
						<MenuItem key={id} value={value}>
							{t(`schedule.view_variant.${name}`)}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</Box>
	);
};

export default ViewSelector;
