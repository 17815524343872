import { arrayOf, node, oneOfType } from 'prop-types';
import React from 'react';

import { INITIAL_STATE } from './helpers';
import { useCalendar } from './hooks';
import reducer from './store/reducer';

export const ScheduleCalendarContext = React.createContext();

const ScheduleCalendarProvider = ({ children }) => {
	const [state, dispatch] = React.useReducer(reducer, INITIAL_STATE);
	const calendarRef = React.useRef();

	const calendarActions = useCalendar({ dispatch, calendarRef, ...state });
	const values = { ...state, ...calendarActions, calendarRef };

	return (
		<ScheduleCalendarContext.Provider value={{ ...values }}>
			{children}
		</ScheduleCalendarContext.Provider>
	);
};

ScheduleCalendarProvider.propTypes = {
	children: oneOfType([arrayOf(node), node]).isRequired
};

export default ScheduleCalendarProvider;
