import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	row: {
		'& > :first-child': {
			width: '150px',
			marginRight: 10
		}
	}
}));
