import { Grid } from '@material-ui/core';
// Import context
import {
	ControlledTableProvider,
	DrugsProvider,
	MedicationsProvider
} from 'components/context';
import { CardSpinner } from 'components/elements';
import { DRUGS_TABLE_QUERY_KEY, DRUGS_TABLE_RESOURCES_API_URL } from 'helpers';
import React from 'react';
import { Route, Switch, useParams } from 'react-router-dom';

import DrugForm from '../DrugForm';
// Import columns
import { columns } from './columns';
import { DrugsTable } from './components';
import { DRUGS_PATHS } from './helpers';
import { useStyles } from './styles';
import useDrugs from './useDrugs';

const Drugs = () => {
	const classes = useStyles();

	const { patientId } = useParams();

	const { isFormActive } = useDrugs();

	const drugsTableResourcesPath = DRUGS_TABLE_RESOURCES_API_URL.replace(
		':patientId',
		patientId
	);

	const renderForm = () => (
		<MedicationsProvider>
			<DrugForm />
		</MedicationsProvider>
	);

	return (
		<Grid container spacing={isFormActive ? 2 : 0}>
			<DrugsProvider>
				<Grid
					item
					xs={12}
					md={isFormActive ? 8 : 12}
					lg={isFormActive ? 8 : 12}
					xl={isFormActive ? 9 : 12}
				>
					<CardSpinner
						isLoading={false}
						className={{
							classContent: classes.cardContent,
							classCard: classes.card
						}}
					>
						<ControlledTableProvider
							url={drugsTableResourcesPath}
							columns={columns}
							queryKey={`${DRUGS_TABLE_QUERY_KEY}-${patientId}`}
						>
							<DrugsTable.Prescribed />
							<DrugsTable.Archived />
						</ControlledTableProvider>
					</CardSpinner>
				</Grid>
				<Grid item xs={12} md={4} lg={4} xl={3}>
					<Switch>
						<Route path={DRUGS_PATHS} component={renderForm} />
					</Switch>
				</Grid>
			</DrugsProvider>
		</Grid>
	);
};

export default Drugs;
