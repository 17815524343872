import { Box } from '@material-ui/core';
import { ControlledTableProvider } from 'components/context';
import { ControlledTable } from 'components/elements';
import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';

import { useStyles } from './styles';
import TableBodyRow from './TableBodyRow';
import Toolbar from './Toolbar';

const ConditionsTable = ({
	title,
	pathname,
	editPathname,
	url,
	removeApiUrl,
	columns,
	manualRefetch,
	queryKey,
	setManualRefetch,
	...rest
}) => {
	const classes = useStyles();

	const { patientId } = useParams();

	return (
		<Box className={classes.item}>
			<Toolbar
				title={title}
				pathname={pathname.replace(':patientId', patientId)}
			/>

			<ControlledTableProvider
				url={url.replace(':patientId', patientId)}
				columns={columns}
				queryKey={`${queryKey}-${patientId}`}
			>
				<ControlledTable
					isToolbarHidden
					isDefaultRow={false}
					manualRefetch={manualRefetch}
					setManualRefetch={setManualRefetch}
				>
					{({ row }) => (
						<TableBodyRow
							key={row.id}
							row={row}
							editPathname={editPathname}
							removeApiUrl={removeApiUrl}
							{...rest}
						/>
					)}
				</ControlledTable>
			</ControlledTableProvider>
		</Box>
	);
};

ConditionsTable.propTypes = {
	title: PropTypes.string.isRequired,
	pathname: PropTypes.string.isRequired,
	editPathname: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired,
	removeApiUrl: PropTypes.string.isRequired,
	queryKey: PropTypes.string.isRequired,
	columns: PropTypes.array.isRequired,
	manualRefetch: PropTypes.bool.isRequired,
	setManualRefetch: PropTypes.func.isRequired
};

export default ConditionsTable;
