export const TOGGLE_MEDICATION_FORM = 'TOGGLE_MEDICATION_FORM';
export const SET_MEDICATION_NAME = 'SET_MEDICATION_NAME';

// DOSAGE
export const SET_DOSAGE = 'SET_DOSAGE';
export const RESET_DOSAGE_STATE = 'RESET_DOSAGE_STATE';
export const SET_DOSAGE_TYPE = 'SET_DOSAGE_TYPE';
export const SET_DOSAGE_DAYS = 'SET_DOSAGE_DAYS';
export const SET_DOSAGE_FORM = 'SET_DOSAGE_FORM';
export const SET_INHALER_PURPOSE = 'SET_INHALER_PURPOSE';

// TAKING
export const SET_TAKING = 'SET_TAKING';

export const SET_UNTIL = 'SET_UNTIL';

// EDIT FORM
export const SET_MEDICATION_INDEX = 'SET_MEDICATION_INDEX';

// NOTE
export const SET_NOTE = 'SET_NOTE';

// INITIAL DATA
export const SET_INITIAL_FORM_DATA = 'SET_INITIAL_FORM_DATA';
