import { RequestModalMenuItem } from 'components/elements/organisms/MenuItem/RequestModalMenuItem';
import { PatientsRepository } from 'queries/Patients/repository';

import { Ctx } from '../../types';
import { usePatientFullName } from '../../usePatientFullName';

export interface IDeletionModalItemProps {
	ctx: Ctx['ctx'];
	close: () => void;
}

export const DeletionModalItem = ({ ctx, close }: IDeletionModalItemProps) => {
	const patientFullName = usePatientFullName({ ctx });
	return (
		<RequestModalMenuItem
			onSuccess={ctx.refreshData}
			menuItemLabel={'table.delete'}
			closeMenu={() => {
				close();
			}}
			promiseFn={() =>
				PatientsRepository.deletePatient({
					patientId: ctx.row.original.id?.toString()
				})
			}
			modalTitle="patients.basic_data.delete_patient.title"
			modalTextContent={{
				key: 'patients.basic_data.delete_patient.confirmation',
				replaceKeys: {
					['%1']: `<strong>${patientFullName}</strong>`
				}
			}}
			actionButtonsProps={{
				confirm: {
					label: 'btn.delete',
					color: 'error'
				}
			}}
			successProps={{
				textContent: {
					key: 'patients.basic_data.patient_has_been_deleted',
					replaceKeys: {
						['%x1']: `<strong>${patientFullName}</strong>`,
						['%X1']: `<strong>${patientFullName}</strong>`
					}
				}
			}}
		/>
	);
};
