import {
	IParameterValuesData,
	ParameterValuesData
} from '../../ParameterValuesData';
import { IParameterBuilder } from './SpirometryParametersDirector';

export class PercentageOfPersonalBestBuilder implements IParameterBuilder {
	private parameterData: IParameterValuesData;

	constructor() {
		this.parameterData = new ParameterValuesData();
	}

	reset = () => {
		this.parameterData = new ParameterValuesData();
	};

	setValidator = () => {
		this.parameterData.setValidator((v) => v >= 0);
	};
	setValue = (v) => {
		const value = v?.toFixed(0) ?? null;
		this.parameterData.setValue(value);
	};

	setUnit = (unit) => {
		this.parameterData.setUnit(unit);
	};

	setColor = (color) => {
		this.parameterData.setColor(color);
	};

	getValue = () => {
		return this.parameterData;
	};
}
