import { useTranslations } from 'components/utilities';

const useSelectField = ({ options, yearFieldName, name }) => {
	const { t } = useTranslations();

	const newOptions = options.map((item) => ({ item, value: item }));

	if (name !== yearFieldName) {
		newOptions.splice(0, 0, { item: t('global.none'), value: '' });
	}

	return { newOptions };
};

export default useSelectField;
