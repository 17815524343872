import { useExaminationsV2Context } from 'components/context/ExaminationsV2';
import { GET_EXAMINATION_REPORT_DATA_QUERY_KEY } from 'helpers';
import { ExaminationsListConvertedItemModel } from 'models/Examinations/ExaminationsList';
import { useExaminationsListQueryKey } from 'queries';
import { useExaminationReportData } from 'queries/Examinations/ReportsV2';
import React from 'react';
import { useQueryClient } from 'react-query';

export const useExaminationDetails = () => {
	const { store: activeExamination, deleteActiveExamination } =
		useExaminationsV2Context((s) => s.activeExamination);

	const { store: isExaminationsListDataSuccess } = useExaminationsV2Context(
		(store) => store.isExaminationsListDataSuccess
	);

	const {
		apiError: examinationReportDataApiError,
		isLoading: isExaminationReportDataLoading,
		isError: isExaminationReportDataError,
		isPreviousData
	} = useExaminationReportData(
		{
			keepPreviousData: true,
			enabled: !!activeExamination.id
		},
		{
			examinationId: activeExamination.id,
			examinationType: activeExamination.type
		}
	);

	const examinationsListQueryKey = useExaminationsListQueryKey();

	const queryClient = useQueryClient();

	const queryData = queryClient.getQueryData(
		GET_EXAMINATION_REPORT_DATA_QUERY_KEY(
			activeExamination.id as IDParam,
			activeExamination.type as ExaminationTypeParam
		)
	);

	const examinationsListQueryData = queryClient.getQueryData<
		ExaminationsListConvertedItemModel[]
	>(examinationsListQueryKey);

	React.useEffect(() => {
		return () => {
			deleteActiveExamination();
		};
	}, []);

	const apiError = examinationReportDataApiError;

	const isLoading =
		!isExaminationsListDataSuccess ||
		isExaminationReportDataLoading ||
		(isPreviousData && queryData === undefined);

	return {
		isLoading,
		apiError,
		isError: isExaminationReportDataError,
		examinationType: activeExamination.type,
		examinationsListQueryData,
		activeExamination
	};
};
