import { createRoot } from 'react-dom/client';
import { MyConsole } from 'utilities/Console/Console';

import App from './App';
import * as serviceWorker from './serviceWorker';

MyConsole.init();

const rootNode = document.getElementById('root');
//eslint-disable-next-line
const root = createRoot(rootNode!);

root.render(<App />);

serviceWorker.unregister();
