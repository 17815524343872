import { useFirebaseMessagingHandler } from './FirebaseMessaging';
import { useReleaseNoteSnackbar } from './ReleaseNoteSnackbar';
import { useSystemNotificationsPermissionSnackbar } from './SystemNotificationsPermissionSnackbar';

export const AllNotifications = () => {
	useSystemNotificationsPermissionSnackbar();
	useFirebaseMessagingHandler();
	useReleaseNoteSnackbar();
	return <></>;
};
