import _ from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';

export function useSearchParams() {
	const location = useLocation();
	const history = useHistory();
	const setSearchParam = (
		key: string | string[],
		value: string | string[] = ''
	) => {
		const searchParams = new URLSearchParams();
		if (typeof key === 'string' && value === 'string') {
			searchParams.set(key, value);
		} else if (_.isArray(key) && _.isArray(value)) {
			key.forEach((k, index) => {
				searchParams.set(k, value?.[index]);
			});
		}

		const searchParamsString = searchParams.toString();

		if (window.location.search === '?' + searchParamsString) {
			return;
		}

		history.push({ search: searchParamsString });
	};

	const getSearchParam = (key: string) => {
		const searchParams = new URLSearchParams(location.search);

		return searchParams.get(key);
	};

	const clearSearchParams = (key?: string | string[]) => {
		const searchParams = new URLSearchParams(location.search);

		if (searchParams.toString() === '') {
			return;
		}

		if (typeof key === 'string') {
			searchParams.delete(key);
			return;
		}

		if (_.isArray(key)) {
			key.forEach((k) => {
				searchParams.delete(k);
			});
			return;
		}

		while (searchParams.keys().next().done === false) {
			searchParams.delete(searchParams.keys().next().value);
		}

		history.push({ search: '' });
	};

	const getAllSearchParams = () => {
		const searchParams = new URLSearchParams(location.search);

		const params = {};
		for (const [key, value] of searchParams) {
			params[key] = value;
		}
		return params;
	};

	return {
		setSearchParam,
		getSearchParam,
		clearSearchParams,
		getAllSearchParams
	};
}
