import { Icon as IconWrapper, TableCell } from '@material-ui/core';
import { EMPTY_VALUE } from 'helpers';
import { object } from 'prop-types';
import React from 'react';

import { useCommonStyles } from '../../styles';
import { getDrugTypeIcon } from './helpers';
import { useStyles } from './styles';

const DrugTypeTableCell = ({ cell }) => {
	const classes = useStyles({ type: cell.row.original.type });
	const commonClasses = useCommonStyles();

	const Icon = getDrugTypeIcon(cell.value);
	return (
		<TableCell {...cell.getCellProps()} className={commonClasses.cell}>
			{cell.value && Icon ? (
				<IconWrapper className={classes.iconWrapper}>
					<Icon className={classes.icon} />
				</IconWrapper>
			) : (
				EMPTY_VALUE
			)}
		</TableCell>
	);
};

DrugTypeTableCell.propTypes = {
	cell: object.isRequired
};

export default DrugTypeTableCell;
