import axios, { CancelToken, CancelTokenSource } from 'axios';
import React from 'react';

export const CANCEL_REQUEST_MESSAGE = 'Query was cancelled by React Query';

export interface RequestCancellation {
	source: CancelTokenSource;
	sourceRef: React.MutableRefObject<CancelTokenSource>;
	token: CancelToken;
	isCancelled: boolean;
}
const useRequestCancellation = (): RequestCancellation => {
	const sourceRef = React.useRef(axios.CancelToken.source());
	const isCancelled = React.useRef(false);

	React.useEffect(() => {
		const currentSourceRef = sourceRef.current;
		return () => {
			isCancelled.current = true;
			currentSourceRef.cancel(CANCEL_REQUEST_MESSAGE);
		};
	}, [sourceRef]);

	const { current: source } = sourceRef;

	return {
		source,
		sourceRef,
		token: source.token,
		isCancelled: isCancelled.current
	};
};

export default useRequestCancellation;
