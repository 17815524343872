import { IColumn } from 'components/elements/organisms/ExaminationsV2/ParametersTable/ParametersTable';
import { ReportParameterModel } from 'models/Report/common';
import { ColumnValues } from 'utilities/Examinations/ExaminationDetails/common';

type Accessor = Leaves<ColumnValues<ReportParameterModel>>;

export const getColumnsValueOnly = ({
	isInteger
}: {
	isInteger?: IColumn<Accessor>['isInteger'];
}): IColumn<Accessor>[] => [
	{ Header: '', accessor: 'parameterTypeLabel' },
	{
		Header: 'report.best_results.value',
		accessor: 'value',
		isInteger: isInteger ?? false
	}
];
