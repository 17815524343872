import { makeStyles } from '@material-ui/styles';

export const getRootContainerHeight = () => `calc(100vh -
	${/* Page Header */ '80px'} - 
	${/* Page padding bottom */ '16px'})`;

export const useStyles = makeStyles(() => ({
	examinationsListContent: {
		flexBasis: 435,
		maxWidth: 435,
		minWidth: 435,
		position: 'sticky',
		top: 80,
		padding: 0,
		height: getRootContainerHeight()
	},
	divider: {
		margin: '0 16px'
	},
	root: {
		display: 'flex',
		flexDirection: 'column'
	}
}));
