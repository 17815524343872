import { RESULTS_COLORS } from '../colors/colors';

/**
 * determine color of result value tile
 * @param {number | string} status parameter status which describe color of result tile
 * @returns color of bronchodilator result
 */
export const getColor = (status) => {
	let colorIndex;
	switch (Number(status)) {
		case 1:
			colorIndex = 2;
			break;
		case 2:
			colorIndex = 0;
			break;
		default:
			colorIndex = 3;
			break;
	}

	return RESULTS_COLORS[colorIndex];
};
