import { isAfter, isBefore } from 'date-fns';
import { formatDateToDateRange, NAVIGATION_DIRECTIONS } from 'helpers';
import { useLocation } from 'react-router-dom';

// Import actions
import {
	setAppointmentsDateRange,
	setCalendarView,
	setCreateFromGrid,
	setDayMaxEvents,
	setIsAppointmentReview,
	setSelectedDate
} from '../store/actions';

const useCalendar = ({
	dispatch,
	calendarRef,
	calendarView,
	appointmentsDateRange,
	selectedDate,
	isMonthView
}) => {
	const { pathname } = useLocation();

	const getCalendarApi = () => calendarRef?.current?.getApi();

	const handleSetAppointmentsDateRange = (date, forceGoToDate) => {
		setSelectedDate(date)(dispatch);

		const enableUpdate =
			isBefore(date, appointmentsDateRange.startDate) ||
			isAfter(date, appointmentsDateRange.endDate);

		setCalendarDate({
			date,
			forceGoToDate,
			enableUpdate,
			view: calendarView
		});
	};

	const handleSetStepDateChange = (type) => {
		const calendarApi = getCalendarApi();
		const isPrevClick = type === NAVIGATION_DIRECTIONS.PREV;

		isPrevClick ? calendarApi.prev() : calendarApi.next();
		handleSetAppointmentsDateRange(calendarApi.getDate(), false);
	};

	const handleSetCalendarView = async (view) => {
		setCalendarView(view)(dispatch);
		await setCalendarDate({ date: selectedDate, view });
		getCalendarApi().changeView(view);
	};

	const handleUnselectTimeGrid = () => {
		if (calendarRef?.current) {
			getCalendarApi().unselect();
		}
	};

	const handleSetIsAppointmentReview = (cannotEdit = false) => {
		const isReview = pathname.includes('review');
		setIsAppointmentReview(isReview || cannotEdit)(dispatch);
	};

	const handleSetCreateFromGrid = (isCreateFromGrid) =>
		setCreateFromGrid(isCreateFromGrid)(dispatch);

	function setCalendarDate({
		forceGoToDate = true,
		enableUpdate = true,
		date,
		view
	}) {
		if (enableUpdate) {
			const { first: startDate, last: endDate } = formatDateToDateRange(
				date,
				view
			);
			const daysMaxEvents = isMonthView ? 3 : false;

			setAppointmentsDateRange({ startDate, endDate })(dispatch);
			setDayMaxEvents(daysMaxEvents)(dispatch);
			forceGoToDate && getCalendarApi().gotoDate(startDate);
		}
	}

	return {
		setAppointmentsDateRange: handleSetAppointmentsDateRange,
		setStepDateChange: handleSetStepDateChange,
		setCalendarView: handleSetCalendarView,
		unselectTimeGrid: handleUnselectTimeGrid,
		setIsAppointmentReview: handleSetIsAppointmentReview,
		setCreateFromGrid: handleSetCreateFromGrid,
		getCalendarApi
	};
};

export default useCalendar;
