import { CHART_MEASUREMENT_PARAMETER_OPTION_NAMES } from 'helpers';
import { ReportParameterModel } from 'models/Report/common';
import {
	ForcedSpirometryBestParametersModel,
	ForcedSpirometryMeasurementModel
} from 'models/Report/ForcedSpirometry';
import {
	SlowVitalCapacitySVCBestParametersModel,
	SlowVitalCapacitySVCMeasurementModel
} from 'models/Report/SlowVitalCapacitySVC';
import { ColumnDataImpl } from 'utilities/Examinations/ExaminationDetails/common';
import {
	ADDITIONAL_FVC_MEASUREMENTS_PARAMETERS,
	ColumnValues,
	CREATE_DATE_TIME,
	FVC_BEST_PARAMETERS,
	SVC_PARAMETERS_WITH_CREATE_DATE
} from 'utilities/Examinations/ExaminationDetails/helpers';

const EXTENDED_MEASUREMENTS_PARAMETERS = {
	...FVC_BEST_PARAMETERS,
	...ADDITIONAL_FVC_MEASUREMENTS_PARAMETERS,
	createDateTime: CREATE_DATE_TIME
};

export const convertDataToColumns = (
	data: ForcedSpirometryMeasurementModel[],
	bestParametersData?: ForcedSpirometryBestParametersModel
): ColumnValues[] => {
	const columnData = new ColumnDataImpl<
		ForcedSpirometryMeasurementModel[],
		ColumnValues
	>(EXTENDED_MEASUREMENTS_PARAMETERS, data);
	columnData.createColumnDataFromAllMeasurements();

	const columnDataFromBests = new ColumnDataImpl<
		ForcedSpirometryBestParametersModel,
		ReportParameterModel
	>(EXTENDED_MEASUREMENTS_PARAMETERS, bestParametersData);
	columnDataFromBests.createColumnData();

	const fvcWithBestsColumnData = columnData.mergeWithSpecificFields(
		columnDataFromBests.columnData,
		['value', 'predicted']
	);

	return fvcWithBestsColumnData;
};

const SVC_PARAMETERS = {
	...SVC_PARAMETERS_WITH_CREATE_DATE,
	ic: {
		...SVC_PARAMETERS_WITH_CREATE_DATE.ic,
		label: CHART_MEASUREMENT_PARAMETER_OPTION_NAMES.ic.label
	}
};

export const convertSvcDataToColumns = (
	data: SlowVitalCapacitySVCMeasurementModel[],
	bestParametersData: SlowVitalCapacitySVCBestParametersModel | undefined
): ColumnValues[] => {
	const svcColumnData = new ColumnDataImpl<
		SlowVitalCapacitySVCMeasurementModel[],
		ColumnValues
	>(SVC_PARAMETERS, data);
	svcColumnData.createColumnDataFromAllMeasurements();

	const svcColumnDataFromBests = new ColumnDataImpl<
		SlowVitalCapacitySVCBestParametersModel,
		ReportParameterModel
	>(SVC_PARAMETERS, bestParametersData);
	svcColumnDataFromBests.createColumnData();

	const svcWithBestsColumnData = svcColumnData.mergeWithSpecificFields(
		svcColumnDataFromBests.columnData,
		['value', 'predicted']
	);

	return svcWithBestsColumnData as ColumnValues[];
};
