import {
	CHART_MEASUREMENT_PARAMETER_OPTION_NAMES,
	getUserUnit,
	UNITS_TYPES
} from 'helpers';

import { IParameterValuesData } from '../../ParameterValuesData';
import { Parameters } from '../../SpirometryParameters';

const { pef, fev1, fvc, tiffeneau } = CHART_MEASUREMENT_PARAMETER_OPTION_NAMES;

export interface IParameterBuilder {
	reset: () => void;
	setValidator: (value?: number) => void;
	setValue: <T>(value: number | null | undefined, type?: T) => void;
	setUnit: (unit: string) => void;
	setColor: (color: string) => void;
	getValue: () => IParameterValuesData;
}

export type ExaminationDataRowType =
	| typeof pef.name
	| typeof fev1.name
	| typeof fvc.name
	| typeof tiffeneau.name
	| undefined;

interface ISpirometryParametersDirector {
	createBestValue: (builder: IParameterBuilder) => void;
	createPercentageOfPersonalBestValue: (builder: IParameterBuilder) => void;
	createPredictedValue: (builder: IParameterBuilder) => void;
	createPercentileValue: (builder: IParameterBuilder) => void;
}

export class SpirometryParametersDirector
	implements ISpirometryParametersDirector
{
	private examinationValues: Parameters | Required<ISpirometryParameters>;
	private examinationDataRowType: ExaminationDataRowType;
	constructor(
		examinationValues: Parameters | Required<ISpirometryParameters>,
		examinationDataRowType: ExaminationDataRowType
	) {
		this.examinationValues = examinationValues;
		this.examinationDataRowType =
			examinationDataRowType?.toLowerCase() as ExaminationDataRowType;
	}

	createBestValue = (builder: IParameterBuilder) => {
		builder.setValidator();
		builder.setValue(this.examinationValues.best);
		builder.setUnit(
			this.examinationDataRowType
				? getUserUnit(this.examinationDataRowType) ?? ''
				: ''
		);
	};

	createPercentageOfPersonalBestValue = (builder: IParameterBuilder) => {
		builder.setValidator();
		builder.setValue(this.examinationValues.PBPercentage);
		builder.setUnit(`${UNITS_TYPES.PB.unit} ${UNITS_TYPES.PB.label}`);
	};

	createPredictedValue = (builder: IParameterBuilder) => {
		builder.setValidator();
		builder.setValue(
			this.examinationValues.predicted,
			this.examinationValues.best
		);
		builder.setUnit(`${UNITS_TYPES.PRED.unit} ${UNITS_TYPES.PRED.label}`);
	};

	createPercentileValue = (builder: IParameterBuilder) => {
		builder.setValidator();
		builder.setValue(
			this.examinationValues.bestPercentile,
			this.examinationValues.best
		);
		builder.setUnit(`${UNITS_TYPES.PERCENTILE.label}`);
	};
}
