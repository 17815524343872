import { useAppointmentsList, usePatientsList } from 'queries';

const useFullCalendar = ({ appointmentsDateRange, isMonthView }) => {
	const { apiError: patientsListApiError, isPatientsListLoading } =
		usePatientsList();

	const {
		apiError: appointmentsError,
		isLoading: appointmentsIsLoading,
		data
	} = useAppointmentsList({}, { appointmentsDateRange });

	const apiError = appointmentsError || patientsListApiError;

	const isLoading = appointmentsIsLoading || isPatientsListLoading;

	return {
		apiError,
		appList: data,
		isLoading,
		isMonthView
	};
};

export default useFullCalendar;
