import {
	BronchodilatorReportModel,
	CalibrationCheckReportModel,
	CommonReportFieldsModel,
	ForcedSpirometryReportModel,
	PeakFlowReportModel,
	PulseOximetryReportModel,
	SlowVitalCapacitySVCReportModel
} from 'models/Report/Reports';
import { useGetCachedClinicData } from 'queries/Clinics/getters';
import {
	useGetCachedExaminationNote,
	useGetCachedExaminationReportData
} from 'queries/Examinations/ReportsV2/getter';
import { useGetCachedDoctorUserData } from 'queries/Users/getters';
import { useGetCachedPatientData } from 'queries/Users/Patient/getters';

export const useGetCachedReportData = <
	T extends
		| ForcedSpirometryReportModel
		| SlowVitalCapacitySVCReportModel
		| PulseOximetryReportModel
		| PeakFlowReportModel
		| BronchodilatorReportModel
		| CalibrationCheckReportModel
		| CommonReportFieldsModel
>() => {
	const patient = useGetCachedPatientData();
	const clinic = useGetCachedClinicData();
	const examination = useGetCachedExaminationReportData<T>();
	const doctorUser = useGetCachedDoctorUserData();
	const examinationNote = useGetCachedExaminationNote();

	return {
		patient,
		clinic,
		examination,
		doctorUser: doctorUser?.data,
		examinationNote
	};
};
