export const getApiErrorMessage = (
	errorData: TMyError,
	t: TODOType
): string | QueryApiError[] => {
	const unknownErrorMessage =
		t('unknown_error_occurred') || 'Unknown error occurred';
	const errorCodeMessageTranslationKey =
		'error_codes.' + errorData.code?.toLowerCase();
	const errorCodeMessage =
		errorData?.code &&
		t({
			key: errorCodeMessageTranslationKey,
			replaceKeys: errorData?.extra_params
		});
	return errorCodeMessage || errorData?.message || unknownErrorMessage;
};
