import { arrayOf, node, oneOfType } from 'prop-types';
import React, { createContext } from 'react';

import { INITIAL_STATE } from './helpers';
// Import reducer
import reducer from './store/reducer';
import useActions from './useActions';

export const PatientFormContext = createContext();

const PatientFormProvider = ({ children }) => {
	const [state, dispatch] = React.useReducer(reducer, INITIAL_STATE);
	const formRef = React.useRef(null);
	const actions = useActions({ dispatch });

	const providerValues = {
		formRef,
		...state,
		...actions
	};

	return (
		<PatientFormContext.Provider value={providerValues}>
			{children}
		</PatientFormContext.Provider>
	);
};

PatientFormProvider.propTypes = {
	children: oneOfType([arrayOf(node), node]).isRequired
};

export default PatientFormProvider;
