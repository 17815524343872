import { Button, RouterLink } from 'components/elements/atoms';
import { useTranslations } from 'components/utilities';
import PropTypes from 'prop-types';
import React from 'react';

import { useStyles } from './styles';

// eslint-disable-next-line
const CustomRouterLink = React.forwardRef((props, ref) => (
	<div style={{ width: '100%' }}>
		<RouterLink ref={ref} {...props} />
	</div>
));

export const DoctorContextActionButtons = ({ path, buttonText }) => {
	const classes = useStyles();
	const { t } = useTranslations();
	return (
		<Button
			fullWidth
			className={classes.btn}
			component={CustomRouterLink}
			to={path}
		>
			{t(buttonText)}
		</Button>
	);
};

DoctorContextActionButtons.propTypes = {
	path: PropTypes.string,
	buttonText: PropTypes.string
};
