import { z } from 'zod';

import { CommonReportFieldsSchema } from './common';

export const PulseOximetryBestParametersSchema = z.object({
	spO2: z.number(),
	hrAvg: z.number()
});

export type PulseOximetryBestParameterModel = z.infer<
	typeof PulseOximetryBestParametersSchema
>;

export const PulseOximetryReportSchema = CommonReportFieldsSchema.merge(
	z.object({
		bestParameters: PulseOximetryBestParametersSchema
	})
);
