import { Grid } from '@material-ui/core';
import { ErrorBoundary } from 'components/elements';
import React from 'react';

import AdditionalInfo from './AdditionalInfo';
import BasicInfo from './BasicInfo';
import useBasicData from './useBasicData';

const BasicData = () => {
	const { patient } = useBasicData();

	return (
		<Grid container spacing={2}>
			<ErrorBoundary resetKeys={[patient]}>
				<Grid lg={true} md={8} sm={12} xs={12} item>
					<BasicInfo patient={patient} />
				</Grid>
				<Grid lg={true} md={8} sm={12} xs={12} item>
					<AdditionalInfo patient={patient} />
				</Grid>
			</ErrorBoundary>
		</Grid>
	);
};

export default BasicData;
