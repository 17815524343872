import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslations } from 'components/utilities';
import { string } from 'prop-types';
import React from 'react';

import { Divider } from '../../../../atoms';
import { useStyles } from './styles';

const TooltipHeader = ({ title, className = '' }) => {
	const classes = useStyles();
	const { t } = useTranslations();

	return (
		<>
			<Typography className={clsx([className, classes.typography])}>
				{t(title) || title}
			</Typography>
			<Divider className={classes.divider} />
		</>
	);
};

TooltipHeader.propTypes = {
	title: string.isRequired,
	className: string
};

export default TooltipHeader;
