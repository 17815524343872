import { z } from 'zod';

export const EmailSchema = {
	default: z.string().email({
		message: 'global.validation.email.invalid'
	}),
	required: z.string().min(1, 'global.validation.email.required').email({
		message: 'global.validation.email.invalid'
	})
};
