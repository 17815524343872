import {
	CHART_MEASUREMENT_PARAMETER_OPTION_NAMES,
	getUserUnit,
	recalculateParameterValueUnit,
	SPIROMETRY_EXAMINATION_VALUE_KEYS
} from 'helpers';
import { SpirometryPreviewModel } from 'models/Examinations/allExaminationsTypes';
import { PersonalBestModel } from 'models/Examinations/PersonalBest';
import { ExaminationsParametersType } from 'types/Examinations/parameters';

import { SpirometryParameters } from '../Parameters';
import { ExaminationDataRowType } from '../Parameters/Builder/Spirometry/SpirometryParametersDirector';
import { IParameterValuesData } from '../Parameters/ParameterValuesData';

const {
	best: { key: bestKey }
} = SPIROMETRY_EXAMINATION_VALUE_KEYS;

const { fev1, fvc, pef, tiffeneau } = CHART_MEASUREMENT_PARAMETER_OPTION_NAMES;

export type ParameterValues = {
	[key: string]: IParameterValuesData;
};

export class Spirometry {
	private fev1: ISpirometryParameters;
	private fvc: ISpirometryParameters;
	private pef: PefParameters;
	private tiffeneau: ISpirometryParameters;
	private spirometryParameters: SpirometryParameters;

	constructor(
		data: SpirometryPreviewModel,
		personalBest: PersonalBestModel,
		chartName?: ExaminationDataRowType
	) {
		this.spirometryParameters = new SpirometryParameters(
			personalBest,
			chartName
		);
		this.fev1 = data.fev1;
		this.fvc = data.fvc;
		this.pef = data.pef;
		this.tiffeneau = data.tiffeneau;
	}

	protected getFev1Values = (): ParameterValues =>
		this.getParameterData(this.fev1, getUserUnit(fev1.name));

	protected getFvcValues = (): ParameterValues =>
		this.getParameterData(this.fvc, getUserUnit(fvc.name));

	protected getPefValues = (): ParameterValues => {
		return this.getParameterData(
			this.#recalculateData(this.pef, pef.name),
			getUserUnit(pef.name)
		);
	};

	protected getTiffeneauValues = (): ParameterValues =>
		this.getParameterData(this.tiffeneau, getUserUnit(tiffeneau.name));

	private getParameterData = (
		parameter: ISpirometryParameters | PefParameters,
		bestValueUnit?: string
	): ParameterValues => {
		const parameterValues =
			this.spirometryParameters.getValuesWithColors(parameter);

		if (bestValueUnit) parameterValues[bestKey].setUnit(bestValueUnit);

		return parameterValues;
	};

	#recalculateData(
		data: ISpirometryParameters,
		key: ExaminationsParametersType
	) {
		return {
			...data,
			[bestKey]: recalculateParameterValueUnit(key, data[bestKey])
		};
	}
}
