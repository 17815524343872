import {
	ContainerWithBorder,
	Title
} from 'components/elements/atoms/ExaminationsV2';
import { ParametersTable } from 'components/elements/organisms/ExaminationsV2';
import React from 'react';

import { measurementsColumns } from '../../../../../../../utilities/Examinations/ExaminationDetails/helpers/constants';
import { useGetCachedForcedSpirometryReportData } from '../useGetCachedForcedSpirometryReportData';
import { convertDataToColumns, convertSvcDataToColumns } from './helpers';
import { useStyles } from './styles';

export const AllMeasurementsTable = () => {
	const classes = useStyles();
	const { examination } = useGetCachedForcedSpirometryReportData();

	return (
		<>
			<ContainerWithBorder>
				<Title title="report.comparison.title" />
				<ParametersTable
					tableBodyClassName={classes.tableBody}
					columns={measurementsColumns}
					data={convertDataToColumns(
						examination?.measurements ?? [],
						examination?.bestParameters
					)}
				/>
			</ContainerWithBorder>
			{examination?.svc ? (
				<ContainerWithBorder>
					<ParametersTable
						tableBodyClassName={classes.tableBody}
						columns={measurementsColumns}
						data={convertSvcDataToColumns(
							examination?.svc?.measurements ?? [],
							examination?.svc?.bestParameters
						)}
					/>
				</ContainerWithBorder>
			) : (
				<></>
			)}
		</>
	);
};
