import {
	CreateAppointment as CreateAppointmentView,
	EditAppointment as EditAppointmentView
} from 'views/Schedule/Sidebar';

import { SCHEDULE_PATHS } from '../paths';

export const SCHEDULE_ROUTES = [
	{
		path: [
			SCHEDULE_PATHS.CREATE_PATIENT_APPOINTMENT,
			SCHEDULE_PATHS.CREATE_APPOINTMENT
		],
		component: CreateAppointmentView
	},
	{
		path: [
			SCHEDULE_PATHS.EDIT_APPOINTMENT,
			SCHEDULE_PATHS.REVIEW_APPOINTMENT
		],
		component: EditAppointmentView
	}
];
