import { MEDICATION_TYPES_FIELDS } from 'helpers';

export const TIME_OF_DAY_FIELDS = [
	{
		text: 'patients.action_plan.create.medications.morning',
		value: 'morning'
	},
	{
		text: 'patients.action_plan.create.medications.noon',
		value: 'noon'
	},
	{
		text: 'patients.action_plan.create.medications.evening',
		value: 'evening'
	}
];

export const getTimeOfDay = (timing, timeOfDayValue) => {
	return { ...timing.find((item) => item.time_of_day === timeOfDayValue) };
};

export const getDosageOptions = (amount) =>
	MEDICATION_TYPES_FIELDS.reduce((acc, { value }) => {
		if (value === 'inhaler') {
			const option = amount > 1 ? 'puffs' : 'puff';
			acc[value] = option;
		} else {
			const option = amount > 1 ? `${value}s` : value;
			acc[value] = option;
		}
		return acc;
	}, {});
