export const getPacksAmountDeclinationTranslationKey = (packsAmount) => {
	switch (Number(packsAmount)) {
		case 1:
			return 'patients.basic_data.field.smoker_yes.single_pack';
		case 2:
		case 3:
		case 4:
			return 'patients.basic_data.field.smoker_yes.packs_between_2_and_4';
		default:
			return 'patients.basic_data.field.smoker_yes.packs_more_and_equal_5';
	}
};

export const getYearsAmountDeclinationTranslationKey = (yearsAmount) => {
	switch (Number(yearsAmount)) {
		case 1:
			return 'global.years.one';
		case 2:
		case 3:
		case 4:
			return 'global.years.between_2_and_4';
		default:
			return 'global.years.more_or_equal_5';
	}
};
