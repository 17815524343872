import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
		display: 'flex',
		padding: '8px 10px 8px 20px',
		flexDirection: 'column',
		'& .MuiIconButton-root:hover': { backgroundColor: 'transparent' }
	},
	avatar: {
		borderRadius: '50%',
		marginRight: 12,
		width: 64,
		height: 64
	},
	nameWrapper: {},
	name: {
		marginRight: 5,
		fontWeight: 600,
		fontSize: 22,
		letterSpacing: '0.15px',
		color: theme.palette.gray.heavy
	},
	desc: {
		fontSize: 12,
		fontWeight: 400,
		letterSpacing: '0.15px',
		color: theme.palette.gray.heavy
	},
	divider: {
		position: 'absolute',
		right: 0,
		height: '30%',
		width: 1,
		background: '#707070',
		opacity: 0.6
	},
	btnIcon: {
		padding: 0
	},
	icon: {
		position: 'relative',
		top: -3,
		marginLeft: 2,
		height: 20,
		color: '#FFC107'
	}
}));
