import { useContext } from 'react';

// Import context
import { ControlledTableContext } from './ControlledTableProvider';

const useControlledTableContextProvider = () => {
	const context = useContext(ControlledTableContext);

	if (!context) {
		throw new Error(
			'useControlledTableContextProvider must be used within a ControlledTableProvider'
		);
	}

	return context;
};
export default useControlledTableContextProvider;
