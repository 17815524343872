import { CHART_MEASUREMENT_PARAMETER_OPTION_NAMES } from 'helpers/charts/measurement/parameters';
import _ from 'lodash';
import { SpirometryPreviewModel } from 'models/Examinations/allExaminationsTypes';
import { PersonalBestModel } from 'models/Examinations/PersonalBest';
import { SpirometryPreview } from 'utilities/Examinations/Previews';

import { formatCreatedDateTime } from './helpers';

const getScore = (
	score: ExaminationScoreObject
): { [key: string]: ExaminationScore } => {
	if (_.isObject(score)) {
		return { fvc: score.fvc, fev1: score.fev1 };
	}
	return { common: score };
};

export const getSpirometryPreview = (
	data: SpirometryPreviewModel,
	personalBest: PersonalBestModel
) => {
	const spirometryPreview = new SpirometryPreview(data, personalBest);
	const fev1 = spirometryPreview.getFev1Preview();
	const fvc = spirometryPreview.getFvcPreview();
	return {
		id: data.id,
		type: data.type,
		examinationType: data?.examinationType,
		createDateTime: formatCreatedDateTime(data.createDateTime),
		tab: data.type,
		title: CHART_MEASUREMENT_PARAMETER_OPTION_NAMES.fev1.translationKey,
		parameters: [fvc, fev1],
		scores: getScore(data.score),
		pdfReport: data.pdfReport || null
	};
};
