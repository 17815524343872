import { produce } from 'immer';
import * as types from 'store/types';

const INITIAL_STATE = {
	prevLocation: ''
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.PREV_LOCATION_STATE:
				draft.prevLocation = action.payload;
				break;
			default:
				return state;
		}
	});
