import { Box, MenuItem } from '@material-ui/core';
import { ExitToAppOutlinedIcon } from 'assets/icons/commonMUIIcons';
import { useTranslations } from 'components/utilities';
import { func } from 'prop-types';
import React from 'react';

import { useStyles } from './styles';

const LogoutItem = ({ signOut }) => {
	const classes = useStyles();
	const { t } = useTranslations();
	return (
		<MenuItem onClick={signOut} className={classes.wrapper}>
			<Box className={classes.iconWrapper}>
				<ExitToAppOutlinedIcon />
			</Box>
			{t('global.logout')}
		</MenuItem>
	);
};

LogoutItem.propTypes = {
	signOut: func.isRequired
};

export default LogoutItem;
