import { func } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { APP_PATHS } from '../routes/paths';

const ProtectedDashboard = ({ component: Component }) => {
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

	return (
		<Route
			render={(props) =>
				isAuthenticated ? (
					<Component {...props} />
				) : (
					<Redirect to={APP_PATHS.SIGN_IN} />
				)
			}
		/>
	);
};

ProtectedDashboard.propTypes = {
	component: func.isRequired
};

export default ProtectedDashboard;
