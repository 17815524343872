import {
	getPacksAmountDeclinationTranslationKey,
	getYearsAmountDeclinationTranslationKey
} from 'helpers/language/languageDeclination';
import { replaceAll } from 'helpers/typography';

export class ActiveSmoker {
	constructor({ patientSmokerCigarettes, patientSmokerYears }, translations) {
		this.cigarettes = patientSmokerCigarettes;
		this.yearsAsSmoker = patientSmokerYears;
		this.translations = translations;
	}

	getLabel = () => {
		const activeSmokerLabel = this.#getActiveSmokerLabel();
		const smokerSinceLabel = this.#getSmokerSinceLabel();

		return `${activeSmokerLabel} ${smokerSinceLabel}`;
	};

	#getActiveSmokerLabel = () => this.translations['global.yes'];

	#getSmokerSinceLabel = () => {
		const label =
			this.translations[
				'patients.basic_data.field.smoker_yes.smoker_since'
			];
		if (!label) {
			return 'none';
		}

		return replaceAll(label, {
			['%v1']: this.cigarettes,
			['%s1']:
				this.translations[
					getPacksAmountDeclinationTranslationKey(this.cigarettes)
				],
			['%v2']: this.yearsAsSmoker,
			['%s2']:
				this.translations[
					getYearsAmountDeclinationTranslationKey(this.yearsAsSmoker)
				]
		});
	};
}
