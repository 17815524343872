import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexWrap: 'wrap',
		gap: 10,
		marginTop: 10,
		'& > div': {
			width: 'auto'
		}
	},
	wrapper: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		marginBottom: theme.spacing(5)
	},
	title: {
		fontSize: '24px',
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(1.625)
	},
	desc: {
		textAlign: 'center',
		fontWeight: 400,
		fontSize: '18px',
		marginBottom: ({ secondaryDescription }) =>
			!secondaryDescription ? theme.spacing(3.8) : theme.spacing(1)
	},
	descSecondary: {
		fontWeight: 400,
		fontSize: '18px',
		textAlign: 'center'
	},
	btn: {
		height: 48,
		maxWidth: 533,
		width: '100%',
		fontSize: '15px',
		marginTop: theme.spacing(3.8)
	}
}));
