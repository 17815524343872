import clsx from 'clsx';
import { LabeledValue as CmpLabeledValue } from 'components/elements/molecules/ExaminationsV2';
import { Props } from 'components/elements/molecules/ExaminationsV2/LabeledValue/LabeledValue';
import React from 'react';

import { useStyles } from './styles';

export const LabeledValue = ({ labelProps, valueProps, className }: Props) => {
	const classes = useStyles();

	return (
		<CmpLabeledValue
			className={clsx([className, classes.labeledValue])}
			labelProps={{ ...labelProps, className: classes.labeledValue }}
			valueProps={{ ...valueProps, className: classes.labeledValue }}
		/>
	);
};
