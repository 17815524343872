import { useMutation, useQuery } from 'components/utilities';
import { EMPLOYEES_INVITATION } from 'helpers/Forms/fieldNames/employeesInvitation';
import React from 'react';

import * as fieldNames from './helpers';

const useEmployeeInvitationForm = ({
	getResources,
	resourcesQueryKey,
	createAccountRepository,
	createAccountService,
	initialFormState,
	roleInitialValuesCreator = () => {}
}) => {
	const [isAccountCreated, setIsAccountCreated] = React.useState(false);

	// Form error messages
	const {
		data,
		isLoading: isResourcesLoading,
		isError: isResourcesError,
		apiError: resourcesError
	} = useQuery(resourcesQueryKey, getResources);

	const { mutateAsync, apiError: createAccountApiError } = useMutation(
		createAccountRepository
	);

	// Form handlers
	const handleOnSubmit = async (values) => {
		const [countries] = data;
		const convertedValues =
			createAccountService?.({
				values,
				countries: countries,
				countryCodeFieldName: EMPLOYEES_INVITATION.COUNTRY_CODE
			}) ?? values;

		try {
			const data = await mutateAsync(convertedValues);
			setIsAccountCreated(true);
			return data;
		} catch (error) {
			return error?.response?.data?.validator?.errors || error;
		}
	};

	const fetchDataErrorMessage = resourcesError || createAccountApiError;

	const createInitialValues = () => {
		const roleInitialize = roleInitialValuesCreator({ data, fieldNames });

		return {
			...initialFormState,
			...roleInitialize
		};
	};

	return {
		errorMessages: fetchDataErrorMessage,
		isResourcesError,
		isResourcesLoading,
		data,
		isAccountCreated,
		submit: handleOnSubmit,
		createInitialValues
	};
};

export default useEmployeeInvitationForm;
