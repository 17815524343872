import clsx from 'clsx';
import { RippleBox } from 'components/elements';
import { arrayOf, bool, func, node, oneOfType, string } from 'prop-types';
import React from 'react';

import { ItemWrapperContext, ItemWrapperProvider } from './context';
import { useStyles } from './styles';

const ItemWrapper = ({
	onClick,
	isListItem = false,
	className = '',
	isActive,
	children
}) => {
	const classes = useStyles({ isListItem });

	return (
		<ItemWrapperProvider>
			<ItemWrapperContext.Consumer>
				{({ isHoverEnable }) => (
					<RippleBox
						className={clsx([
							classes.root,
							className,
							isActive ? classes.active : classes.unactive
						])}
						isHoverEnable={isListItem ? isHoverEnable : false}
						onClick={isListItem ? onClick : () => {}}
					>
						{children}
					</RippleBox>
				)}
			</ItemWrapperContext.Consumer>
		</ItemWrapperProvider>
	);
};

ItemWrapper.propTypes = {
	className: string,
	isListItem: bool,
	isActive: bool,
	onClick: func.isRequired,
	children: oneOfType([arrayOf(node), node]).isRequired
};

export default ItemWrapper;
