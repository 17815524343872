import { ReactComponent as CircleIcon } from 'assets/icons/circle_white.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/cross_white.svg';
import { ReactComponent as TickIcon } from 'assets/icons/tick_white.svg';
import clsx from 'clsx';
import { MEASUREMENT_STATUS_DOT_TYPE } from 'helpers';
import { StatusModel } from 'models/Report/common';
import React from 'react';

import { CorrectnessMeasurementModel } from '../helpers';
import { DotsWrapper } from './DotsWrapper';
import { useStyles } from './styles';

type Props = {
	isBest: CorrectnessMeasurementModel['isBest'];
	upperStatus: StatusModel;
	bottomStatus: StatusModel;
	color?: string;
	className?: string;
	upperDotClassName?: string;
	bottomDotClassName?: string;
};
export const Dots = ({
	isBest,
	upperStatus,
	bottomStatus,
	color,
	upperDotClassName = '',
	bottomDotClassName = '',
	className = ''
}: Props) => {
	const classes = useStyles({ isBest, color });

	const getIcon = React.useCallback(
		(status) => {
			switch (status) {
				case MEASUREMENT_STATUS_DOT_TYPE.valid:
					return TickIcon;
				case MEASUREMENT_STATUS_DOT_TYPE.invalid:
					return CrossIcon;
				case MEASUREMENT_STATUS_DOT_TYPE.usable:
					return CircleIcon;
			}
		},
		[upperStatus, bottomStatus]
	);
	return (
		<DotsWrapper
			className={className}
			upperDotClassName={clsx([upperDotClassName, classes.dotBackground])}
			bottomDotClassName={clsx([
				bottomDotClassName,
				classes.dotBackground
			])}
			upperDotIcon={getIcon(upperStatus)}
			bottomDotIcon={getIcon(bottomStatus)}
		/>
	);
};
