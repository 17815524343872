import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		position: 'absolute',
		top: 0,
		left: 0,
		height: '100%',
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		zIndex: 9999,
		background: '#f4f6f8'
	},
	box: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		animation: `$rotateAnimation 2s ${theme.transitions.easing.easeInOut} infinite`
	},
	'@keyframes rotateAnimation': {
		'0%': {
			transform: 'scale(0.9)'
		},
		'50%': {
			transform: 'scale(1)'
		},
		'100%': {
			transform: 'scale(0.9)'
		}
	},
	logo: {
		height: 80,
		maxWidth: '95vw',
		width: 400
	}
}));
