import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslations } from 'components/utilities';
import React from 'react';

import { useStyles } from './styles';

export interface ITextProps {
	text: string;
	variant?: 'title' | 'text';
	suffix?: string;
	prefix?: string;
	className?: string;
}
export const Text = ({
	text,
	suffix = '',
	prefix = '',
	className = ''
}: ITextProps) => {
	const classes = useStyles();
	const { t } = useTranslations();

	return (
		<Typography
			variant={'h3'}
			className={clsx([className, classes.textVariantFont])}
		>
			{prefix}
			{t(text)}
			{suffix}
		</Typography>
	);
};
