import { API_URL_PREFIX } from '../helpers';
import { ID_QUERY_PARAM, PATIENT_ID_QUERY_PARAM_KEY } from '../queryParamKeys';

// conditions
export const CONDITIONS_API_URL = `${API_URL_PREFIX}/patients/${PATIENT_ID_QUERY_PARAM_KEY}/conditions/list`;
export const CONDITION_GET_API_URL = `${API_URL_PREFIX}/patients/conditions/${ID_QUERY_PARAM}`;
export const CONDITION_ADD_API_URL = `${API_URL_PREFIX}/patients/${PATIENT_ID_QUERY_PARAM_KEY}/conditions`;
export const CONDITION_EDIT_API_URL = `${API_URL_PREFIX}/patients/conditions/${ID_QUERY_PARAM}`;
export const CONDITION_REMOVE_API_URL = `${API_URL_PREFIX}/patients/conditions/${ID_QUERY_PARAM}`;

// query
export const CONDITIONS_TABLE_QUERY_KEY = 'CONDITIONS_TABLE_QUERY_KEY';
export const CONDITION_RESOURCES_QUERY_KEY = 'CONDITION_RESOURCES_QUERY_KEY';

// surgeries
export const SURGERIES_API_URL = `${API_URL_PREFIX}/patients/${PATIENT_ID_QUERY_PARAM_KEY}/surgeries/list`;
export const SURGERY_GET_API_URL = `${API_URL_PREFIX}/patients/surgeries/${ID_QUERY_PARAM}`;
export const SURGERY_ADD_API_URL = `${API_URL_PREFIX}/patients/${PATIENT_ID_QUERY_PARAM_KEY}/surgeries`;
export const SURGERY_EDIT_API_URL = `${API_URL_PREFIX}/patients/surgeries/${ID_QUERY_PARAM}`;
export const SURGERY_REMOVE_API_URL = `${API_URL_PREFIX}/patients/surgeries/${ID_QUERY_PARAM}`;

// query
export const SURGERIES_TABLE_QUERY_KEY = 'SURGERIES_TABLE_QUERY_KEY';
export const SURGERY_RESOURCES_QUERY_KEY = 'SURGERY_RESOURCES_QUERY_KEY';

// allergies
export const ALLERGIES_API_URL = `${API_URL_PREFIX}/patients/${PATIENT_ID_QUERY_PARAM_KEY}/allergies/list`;
export const ALLERGY_GET_API_URL = `${API_URL_PREFIX}/patients/allergies/${ID_QUERY_PARAM}`;
export const ALLERGY_ADD_API_URL = `${API_URL_PREFIX}/patients/${PATIENT_ID_QUERY_PARAM_KEY}/allergies`;
export const ALLERGY_EDIT_API_URL = `${API_URL_PREFIX}/patients/allergies/${ID_QUERY_PARAM}`;
export const ALLERGY_REMOVE_API_URL = `${API_URL_PREFIX}/patients/allergies/${ID_QUERY_PARAM}`;

// query
export const ALLERGY_TABLE_QUERY_KEY = 'ALLERGY_TABLE_QUERY_KEY';
export const ALLERGY_RESOURCES_QUERY_KEY = 'ALLERGY_RESOURCES_QUERY_KEY';
