import { Box } from '@material-ui/core';
import clsx from 'clsx';

import { useSnackBarStyles, useStyles as useCommonStyles } from '../../styles';
import { ISnackbarMessageProps } from '../SnackbarMessage';
import { useStyles } from './styles';

export interface IContainerProps {
	variant: ISnackbarMessageProps['variant'];
	children: CmpChildren;
}

export const Container = ({ variant, children }: IContainerProps) => {
	const classes = useStyles({ variant });
	const commonClasses = useCommonStyles({ variant });
	const snackbarClasses = useSnackBarStyles({ variant });

	return (
		<Box
			className={clsx([
				commonClasses.messageContainer,
				commonClasses.root,
				commonClasses.message,
				snackbarClasses.font,
				classes.messageContainer
			])}
		>
			{children}
		</Box>
	);
};
