import { Typography } from '@material-ui/core';
import React from 'react';

import { useStyles } from './styles';

type Props = {
	createDateTime: string;
};
const DateTimeLabel = ({ createDateTime }: Props) => {
	const classes = useStyles();

	return (
		<Typography
			className={classes.typography}
			component="p"
			variant="body1"
		>
			{createDateTime}
		</Typography>
	);
};

export default DateTimeLabel;
