import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	item: {
		marginBottom: theme.spacing(6),
		'&:last-child': {
			marginBottom: 0
		}
	}
}));
