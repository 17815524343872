import { Box } from '@material-ui/core';
import {
	useExaminationsV2Context,
	useTooltipContext
} from 'components/context/ExaminationsV2';
import {
	EXAMINATIONS_TYPES,
	QUERY_PATIENT_DATA_AND_PERSONAL_BESTS
} from 'helpers';
import { bool, number, object } from 'prop-types';
import React from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import Examination from './Examination';
import ExaminationCreationDate from './ExaminationCreationDate';
import { ExaminationListItemFactory } from './ExaminationListItemFactory/ExaminationListItemFactory';

const ExaminationListItem = ({ style, rowData, isActive }) => {
	const cacheQuery = useQueryClient();
	const { patientId } = useParams();

	const [{ best }] = cacheQuery.getQueryData([
		QUERY_PATIENT_DATA_AND_PERSONAL_BESTS,
		patientId
	]);

	const { store: examinationTooltip } = useTooltipContext(
		(s) => s.examinationTooltip
	);
	const { store: examinationsListData } = useExaminationsV2Context(
		(s) => s.examinationsListData
	);

	const isItem = rowData.type === EXAMINATIONS_TYPES.CREATION_DATE.type;

	const renderItem = () => {
		const data = new ExaminationListItemFactory(rowData, best).createItem();
		let examinationListItem =
			examinationsListData[examinationTooltip.listItemIndex];
		return (
			<Examination
				data={data}
				isListItem
				isActive={
					isActive &&
					data.id?.toString() ===
						examinationListItem?.id?.toString() &&
					data.type === examinationListItem?.type
				}
			/>
		);
	};

	return (
		<Box
			style={{
				padding: '0 16px',
				...style,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center'
			}}
		>
			{!isItem ? (
				renderItem()
			) : (
				<ExaminationCreationDate dateCreated={rowData.createDateTime} />
			)}
		</Box>
	);
};

ExaminationListItem.propTypes = {
	style: object.isRequired,
	index: number.isRequired,
	rowData: object.isRequired,
	isActive: bool
};

export default ExaminationListItem;
