import { useContext } from 'react';

// Import context
import { DrugsContext } from './DrugsProvider';

const useDrugsContext = () => {
	const context = useContext(DrugsContext);
	return context;
};

export default useDrugsContext;
