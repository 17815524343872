import { Box } from '@material-ui/core';
import { useExaminationsV2Context } from 'components/context/ExaminationsV2';
import { LoaderSpinnerTransitioned, Placeholder } from 'components/elements';
import { useGetExaminationsListFromCache } from 'queries';
import React from 'react';

import { ExaminationsCharts } from './ExaminationsCharts';
import MeasurementParameters from './MeasurementParameters';
import { useStyles } from './styles';

export const TrendChart = () => {
	const classes = useStyles();

	const { examinationsListData } = useGetExaminationsListFromCache();

	const { store: isExaminationsListDataSuccess } = useExaminationsV2Context(
		(s) => s.isExaminationsListDataSuccess
	);

	return (
		<Box className={classes.cardContent}>
			<LoaderSpinnerTransitioned
				isLoading={!isExaminationsListDataSuccess}
				isCover
			/>

			{isExaminationsListDataSuccess && (
				<Placeholder
					classes={{
						root: classes.placeholderRoot
					}}
					active={examinationsListData?.length === 0}
					label={'patients.examinations_v2.no_examinations_data'}
					prefixMarker=""
					suffixMarker=""
					backgroundColor="inherit"
				>
					<MeasurementParameters />
					<ExaminationsCharts />
				</Placeholder>
			)}
		</Box>
	);
};
