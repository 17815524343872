import { Button, List, ListItem } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslations } from 'components/utilities';
// Import nav links
import { NAV_LINKS } from 'helpers/navigation/mainNavigation';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { useStyles } from './styles';

// eslint-disable-next-line
const CustomRouterNavLink = React.forwardRef((props, ref) => (
	<NavLink ref={ref} {...props} />
));

const Navigation = ({ className, ...rest }) => {
	const roles = useSelector(({ auth }) => auth.authenticate.data.roles);
	const { t } = useTranslations();

	const classes = useStyles();

	return (
		<List {...rest} className={clsx(classes.root, className)}>
			{NAV_LINKS.map(
				({ role, title, href }) =>
					roles.some((userRole) => role.includes(userRole)) && (
						<ListItem
							className={classes.item}
							disableGutters
							key={title}
						>
							<Button
								activeClassName={classes.active}
								className={classes.button}
								component={CustomRouterNavLink}
								to={href}
							>
								{t(title)}
							</Button>
						</ListItem>
					)
			)}
		</List>
	);
};

Navigation.propTypes = {
	className: PropTypes.string
};

export default Navigation;
