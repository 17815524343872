import {
	useActiveExaminationActions,
	useExaminationsV2Context,
	useExaminationsV2RefsContext,
	useTooltipContext
} from 'components/context/ExaminationsV2';
import { PATIENTS_DETAILS_PATHS } from 'components/routes/paths';
import {
	ActiveExamination,
	ActiveExaminationBase
} from 'models/Examinations/common';
import {
	ExaminationsListConvertedItemModel,
	ExaminationsListItemModel
} from 'models/Examinations/ExaminationsList';
import {
	ExaminationsListServicesV2,
	useGetExaminationsListFromCache
} from 'queries';
import { useExaminationsList as useExaminationsListQuery } from 'queries';
import React from 'react';
import { generatePath, useLocation } from 'react-router-dom';
import { SessionStorage, useParams } from 'utilities';
import { useActiveExaminationSearchParams } from 'utilities/ReactRouterDomHooks/implementations';

import { validateActiveExamination } from '../helpers';
import { useInitialListActiveIndex } from './useInitialListActiveIndex';
import { useListActions } from './useListActions';

export const useList = () => {
	const { patientId } = useParams();
	const location = useLocation();
	const { getTypeSearchParam, getIdSearchParam } =
		useActiveExaminationSearchParams();

	const { store: examinationsListRef } = useExaminationsV2RefsContext(
		(s) => s.examinationsListRef
	);

	const { store: examinationsListData } = useExaminationsV2Context(
		(s) => s.examinationsListData
	);
	const { store: activeExamination } = useExaminationsV2Context(
		(store) => store.activeExamination
	);
	const { store: availableExaminationTypesInList } = useExaminationsV2Context(
		(store) => store.availableExaminationTypesInList
	);
	const { store: examinationsDateRange } = useExaminationsV2Context(
		(store) => store.examinationsDateRange
	);

	const { setExaminationsListDataSuccess, setExaminationsListData } =
		useExaminationsV2Context();

	const { store: examinationTooltip } = useTooltipContext(
		(store) => store.examinationTooltip
	);

	const { examinationsListData: examinationsListQueryData } =
		useGetExaminationsListFromCache();

	const { setInitialIndex } = useInitialListActiveIndex();
	const { updateActiveExamination, setInitialExaminationsListData } =
		useListActions({ setInitialIndex });

	const { removeActiveExaminationIndexFromList } =
		useActiveExaminationActions();

	const isExaminationPreviewView = React.useMemo(
		() =>
			location.pathname ===
			generatePath(PATIENTS_DETAILS_PATHS.EXAMINATIONS.PREVIEW, {
				patientId
			}),
		[location.pathname]
	);

	const setDefaultActiveExamination = React.useCallback(
		(data: ExaminationsListConvertedItemModel[]) => {
			// set active examination in list item only in examination preview view
			if (!isExaminationPreviewView) {
				return;
			}
			let activeExaminationIndex: number;

			/**
			 * Sets the active examination and filters it from the list.
			 * @param activeExaminationBase - The base object with the active examination data and its index.
			 * @param forceInitialIndex - A flag indicating whether to force setting the initial index or not. Default is `true`.
			 * @returns void
			 */
			function setActiveExaminationAndFilterItFromTheList(
				activeExaminationBase: ActiveExaminationBase & {
					index: number;
				},
				forceInitialIndex = true
			) {
				updateActiveExamination(
					data,
					activeExaminationBase,
					forceInitialIndex
				);
				const dataWithoutActiveExaminationItem =
					removeActiveExaminationIndexFromList(
						data,
						activeExaminationBase
					);
				setExaminationsListData(dataWithoutActiveExaminationItem);
			}

			// active examination is defined in search params
			const activeExamBySearchParam = {
				id: getIdSearchParam(),
				type: getTypeSearchParam()
			};
			activeExaminationIndex = validateActiveExamination(
				data,
				activeExamBySearchParam
			);
			if (activeExaminationIndex > -1) {
				setActiveExaminationAndFilterItFromTheList({
					...activeExamBySearchParam,
					index: activeExaminationIndex
				});
				return;
			}

			// active examination is defined in session storage
			const activeExamBySessionData =
				SessionStorage.examinations.getLastActiveExamination({
					patientId
				});
			activeExaminationIndex = validateActiveExamination(
				data,
				activeExamBySessionData
			);
			if (activeExaminationIndex > -1) {
				setActiveExaminationAndFilterItFromTheList({
					...(activeExamBySessionData as ActiveExamination),
					index: activeExaminationIndex
				});
				return;
			}

			// set first element from list if none of the above conditions are met
			if (data.length > 0) {
				activeExaminationIndex = 1;

				let examinationListItem = data?.[
					activeExaminationIndex
				] as ExaminationsListItemModel;

				if (
					ExaminationsListServicesV2.isCopdPostExamination(
						examinationListItem
					)
				) {
					examinationListItem = data?.[
						++activeExaminationIndex
					] as ExaminationsListItemModel;
				}

				const activeExam: ActiveExaminationBase = {
					id: examinationListItem.id.toString(),
					type: examinationListItem.type
				};

				setActiveExaminationAndFilterItFromTheList(
					{
						...activeExam,
						index: activeExaminationIndex
					},
					false
				);
				return;
			}
		},
		[examinationsListData.length === 0, isExaminationPreviewView]
	);

	React.useEffect(() => {
		// run when navigated from trend chart to examinations
		if (
			examinationsListQueryData &&
			examinationsListQueryData?.length > 0 &&
			examinationsListRef.current &&
			activeExamination.id === undefined
		) {
			const initialExaminationsListData = setInitialExaminationsListData(
				examinationsListQueryData,
				false
			);
			setDefaultActiveExamination(initialExaminationsListData);
		}
	}, [isExaminationPreviewView]);

	const handleSuccess = React.useCallback(
		(data: ReturnType<typeof useExaminationsListQuery>['data']) => {
			const isDataDefined = data && data.length > 0;

			// returned data is not defined or empty
			// OR
			// examinationsListData already exists in query client - context store updated on component mount
			if (!isDataDefined || examinationsListQueryData?.length) {
				setExaminationsListDataSuccess();
				return;
			}
			const initialExaminationsListData =
				setInitialExaminationsListData(data);
			setDefaultActiveExamination(initialExaminationsListData);

			setExaminationsListDataSuccess();
		},
		[examinationsListData.length === 0, examinationsListQueryData?.length]
	);

	const { apiError: examinationsListApiError, remove } =
		useExaminationsListQuery({
			onSuccess: handleSuccess
		});

	React.useEffect(() => {
		return () => {
			remove();
		};
	}, []);

	const apiError = examinationsListApiError;

	const isSomeExaminationInDateRange =
		availableExaminationTypesInList.length > 0;

	const isListItemActive =
		examinationTooltip.active && !isExaminationPreviewView;
	return {
		examinationsListRef,
		activeExamination,
		examinationsListQueryData,
		examinationsListData,
		apiError,
		isSomeExaminationInDateRange,
		examinationsDateRange,
		isListItemActive
	};
};
