import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	label: {
		color: ({ isChecked }) =>
			isChecked ? theme.palette.primary.main : theme.palette.black,
		fontWeight: 400
	},
	icon: {
		margin: '0 10px',
		'& path': {
			fill: ({ value }) => theme.palette.medication[value]
		}
	}
}));
