import {
	useExaminationsTrendChartContext,
	useExaminationsV2Context
} from 'components/context/ExaminationsV2';
import React from 'react';

const useInitialSeries = () => {
	const { selectedParameters, allChartParametersMutation } =
		useExaminationsTrendChartContext();

	const { store: examinationDateExtremes } = useExaminationsV2Context(
		(s) => s.examinationDateExtremes
	);

	const fetchAllInitialCharts = () => {
		allChartParametersMutation.mutateAsync({
			chartStartDate: examinationDateExtremes.startDate,
			chartEndDate: examinationDateExtremes.endDate,
			chartParameters: selectedParameters
		});
	};

	React.useEffect(() => {
		fetchAllInitialCharts();
	}, []);
};

export default useInitialSeries;
