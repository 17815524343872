// Import context
import {
	useAppointmentFormContext,
	useScheduleCalendarContext
} from 'components/context';
import { AppointmentsServices } from 'queries';
import { useParams } from 'react-router-dom';

import {
	getSchedulePatientParamId,
	INITIAL_SCHEDULE_FORM_VALUES
} from '../ScheduleForm/helpers';

const useCreateAppointment = () => {
	const params = useParams();
	const { selectedDate, setSelectedDate, patientsList, duration } =
		useAppointmentFormContext();

	const { isCreateFromGrid } = useScheduleCalendarContext();

	const patientId = getSchedulePatientParamId(params);

	const isPatientsLoaded = patientsList?.length;

	/*eslint-disable*/
	const initialValues = isPatientsLoaded
		? AppointmentsServices.createAppointmentFormInitialValues({
				selectedDate,
				patientsList,
				patientId,
				setSelectedDate,
				isCreateFromGrid,
				duration,
				minutesPeriod: 15,
				initialValues: INITIAL_SCHEDULE_FORM_VALUES
		  })
		: {};
	/*eslint-enable*/

	return {
		initialValues,
		isPatientsLoaded
	};
};

export default useCreateAppointment;
