import { Box, Grid } from '@material-ui/core';
import { calculateAge } from 'helpers';
import { SMOKER_OPTIONS_VALUES } from 'helpers/variables';
import { string } from 'prop-types';
import React from 'react';
import { useFormState } from 'react-final-form';

import {
	ExSmokerFields,
	PassiveSmokerFields,
	SmokerFields
} from './components';
import { useStyles } from './styles';

const { YES, PASSIVE_SMOKER, EX_SMOKER } = SMOKER_OPTIONS_VALUES;

const useRadioFields = ({ smoker }) => {
	const classes = useStyles();

	const {
		values: { patientDob }
	} = useFormState();

	const handleSmokerSinceKeyboardChange = React.useCallback((e) => {
		if (e.target.value?.toString()?.includes('+')) {
			return e;
		}
		e.target.value = e.target.value.replace(/[^0-9]/g, '');
		return e;
	}, []);

	const renderFields = React.useCallback(() => {
		const props = {
			maxYearsAsSmoker: calculateAge(patientDob),
			onSmokerSinceKeyboardChange: handleSmokerSinceKeyboardChange
		};
		switch (smoker) {
			case YES:
				return <SmokerFields {...props} />;
			case PASSIVE_SMOKER:
				return <PassiveSmokerFields {...props} />;
			case EX_SMOKER:
				return <ExSmokerFields />;
			default:
				return (
					<Grid item lg={12} xs={12}>
						<Box className={classes.root} />
					</Grid>
				);
		}
	}, [smoker]);

	return { renderFields };
};

useRadioFields.propTypes = {
	smoker: string.isRequired
};

export default useRadioFields;
