import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		gap: 10,
		display: 'flex',
		flexDirection: 'column',
		height: 'inherit',
		justifyContent: 'center',
		borderLeft: `1px solid ${theme.palette.border.light}`,
		paddingLeft: 15
	},
	score: {
		paddingRight: '0px !important',
		marginBottom: ({ isSingleScore }) => isSingleScore && 18
	},
	divider: {
		backgroundColor: theme.palette.border.light
	}
}));
