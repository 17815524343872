import { makeStyles } from '@material-ui/styles';
import palette from 'theme/palette';

export const useStyles = makeStyles(() => ({
	flexContainer: {
		display: 'flex',
		// position: 'relative',
		height: '100%',
		background: palette.white,
		borderRadius: 5,
		boxShadow: '0px 3px 6px #00000029',
		padding: 15,
		overflowX: 'clip',
		position: 'sticky',
		top: 100
	},
	loader: {
		top: 0,
		transform: 'translate(-50%, 0)',
		maxHeight: 100
	},
	content: {
		display: 'flex'
	}
}));
