import { SessionStorage, useParams } from 'utilities';

import { INITIAL_STATE } from './helpers';

export const useInitialSelectedParameters = () => {
	const params = useParams();

	const initialSelectedParameters =
		SessionStorage.examinations.getSelectedTrendChartParameters(params) ??
		INITIAL_STATE;

	return { initialSelectedParameters };
};
