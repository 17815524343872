import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		padding: '0 20px'
	},
	container: {
		paddingBottom: 20,
		width: '100%'
	},
	pictogramWrapper: {
		position: 'relative',
		paddingTop: 14
	}
}));
