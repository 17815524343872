import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	dayName: ({ isMonthView }) => ({
		backgroundColor: '#f6f6fe',
		padding: '5px 0',
		borderTop: isMonthView ? 'none' : '1px solid #ddd',
		borderLeft: isMonthView ? 'none' : '1px solid #ddd'
	}),
	dayNumber: {
		fontSize: '15px',
		paddingBottom: '10px'
	}
}));
