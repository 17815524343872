import React from 'react';
import { ReactContext } from 'utilities';

import {
	ITooltip,
	Tooltip
} from '../../../../views/PatientsDetails/views/ExaminationsV2/TrendChart/ExaminationsCharts/Chart/components/classes/Tooltip';
import { useActions } from './useActions';

interface ITooltipProvider {
	examinationTooltip: ITooltip;
}

type Props = { children: CmpChildren };

const { Provider, useStore } =
	ReactContext.createPubSubContext<ITooltipProvider>();

export const TooltipProvider = ({ children }: Props) => {
	return (
		<Provider value={{ examinationTooltip: new Tooltip() }}>
			{children}
		</Provider>
	);
};

export const useTooltipContext = <SelectorOutput,>(
	selector?: (store: ITooltipProvider) => SelectorOutput
) => {
	const { store, setStore, getStore } = useStore(selector);

	const actions = useActions(setStore);
	return {
		store,
		getTooltipContextStore: getStore,
		...actions
	};
};
