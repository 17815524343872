import { isValueValid } from 'helpers';

import {
	IParameterValuesData,
	ParameterValuesData
} from '../../ParameterValuesData';
import { IParameterBuilder } from './SpirometryParametersDirector';

export class PercentileValueBuilder implements IParameterBuilder {
	private parameterData: IParameterValuesData;

	constructor() {
		this.parameterData = new ParameterValuesData();
	}

	reset = () => {
		this.parameterData = new ParameterValuesData();
	};

	setValidator = () => {
		this.parameterData.setValidator((v) => v >= 0);
	};
	setValue = (_percentileValue, bestValue) => {
		const isBestValueValid = isValueValid(bestValue, (v) => v >= 0);
		const percentileValue = isBestValueValid
			? _percentileValue?.toFixed(0) ?? null
			: null;
		this.parameterData.setValue(percentileValue);
	};

	setUnit = (u) => {
		this.parameterData.setUnit(u);
	};

	setColor = (color) => {
		this.parameterData.setColor(color);
	};

	getValue = () => {
		return this.parameterData;
	};
}
