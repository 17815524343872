import { Box, Typography } from '@material-ui/core';
import { bool, number, shape, string } from 'prop-types';
import React from 'react';

import { useStyles } from './styles';

const EventBox = ({ startTime, patientName, stylesProps }) => {
	const classes = useStyles(stylesProps);
	return (
		<Box className={classes.eventBox}>
			<Typography component="p" className={classes.hours}>
				{startTime}
			</Typography>
			&nbsp;
			<Typography component="p" className={classes.patient}>
				{patientName}
			</Typography>
		</Box>
	);
};

EventBox.propTypes = {
	startTime: string.isRequired,
	patientName: string.isRequired,
	stylesProps: shape({
		colorNumber: number.isRequired,
		isMirror: bool.isRequired,
		isPastAppointment: bool.isRequired
	}).isRequired
};

export default EventBox;
