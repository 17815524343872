import { useTooltipContext } from 'components/context/ExaminationsV2';
import { QUERY_PATIENT_DATA_AND_PERSONAL_BESTS } from 'helpers';
import { PersonalBestModel } from 'models/Examinations/PersonalBest';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { ExaminationTooltipFactory } from './ExaminationTooltipFactory';

const TooltipRPC = ({ examinationListItemData, children }) => {
	const { patientId } = useParams<{ patientId: PatientIdParam }>();
	const cacheQuery = useQueryClient();

	const personalBest = cacheQuery.getQueryData<
		[{ best: PersonalBestModel }] | []
	>([QUERY_PATIENT_DATA_AND_PERSONAL_BESTS, patientId]);

	if (!personalBest?.[0]?.best) {
		throw new Error("Personal bests doesn't exists");
	}
	const { store: examinationTooltip } = useTooltipContext(
		(s) => s.examinationTooltip
	);

	const tooltipData = new ExaminationTooltipFactory(
		examinationListItemData,
		personalBest?.[0]?.best
	).createTooltip(examinationTooltip.chartName);

	return children({
		...tooltipData,
		chartName: examinationTooltip.chartName,
		active: examinationTooltip.active
	});
};

export default TooltipRPC;
