import { z } from 'zod';

export const DeleteClinicImageSchema = z.any();
export type DeleteClinicImageModel = z.infer<typeof DeleteClinicImageSchema>;

export const CreatedClinicDataSchema = z.object({
	city: z.string().max(50).nullable(),
	country: z.string().max(30).nullable(),
	email: z.string().max(100).email().nullable(),
	imageUrl: z.string().nullable(),
	name: z.string(),
	phoneNumber: z.string().max(20).nullable(),
	phonePrefix: z.string().max(5).nullable(),
	postcode: z.string().max(10).nullable(),
	street: z.string().max(100).nullable(),
	userId: z.number()
});
export type CreatedClinicDataModel = z.infer<typeof CreatedClinicDataSchema>;
export const EmptyClinicDataSchema = z.object({});
export const ClinicDataSchema = z.union([
	CreatedClinicDataSchema,
	EmptyClinicDataSchema
]);

export type ClinicDataModel = z.infer<typeof ClinicDataSchema>;
export type ClinicDataModelWithDoctor = CreatedClinicDataModel & {
	doctorName: string;
};

export const ClinicRequestSchema = z.object({
	city: z
		.string()
		.max(50, 'settings.clinic.validation.city.max_length')
		.nullable()
		.optional(),
	country: z
		.string()
		.max(30, 'settings.clinic.validation.country.max_length')
		.nullable()
		.optional(),
	email: z
		.string()
		.max(100, 'settings.clinic.validation.email.max_length')
		.email('settings.clinic.validation.email.invalid_email')
		.nullable()
		.optional(),
	image: z.union([z.string(), z.object({})]).nullable(),
	name: z
		.string({
			required_error: 'settings.clinic.validation.name.is_required'
		})
		.max(255, 'settings.clinic.validation.name.max_length'),
	phoneNumber: z
		.string()
		.max(20, 'settings.clinic.validation.phonenumber.max_length')
		.nullable()
		.optional(),
	phonePrefix: z
		.string()
		.max(5, 'settings.clinic.validation.phoneprefix.max_length')
		.nullable()
		.optional(),
	postcode: z
		.string()
		.max(10, 'settings.clinic.validation.postcode.max_length')
		.nullable()
		.optional(),
	street: z
		.string()
		.max(100, 'settings.clinic.validation.street.max_length')
		.nullable()
		.optional()
});

export type ClinicRequestModel = Omit<
	z.infer<typeof ClinicRequestSchema>,
	'image'
> & {
	image: File | null | string;
};
