import { CHART_MEASUREMENT_PARAMETER_OPTION_NAMES } from 'helpers';
import { PeakFlowPreviewModel } from 'models/Examinations/allExaminationsTypes';
import { PeakFlowPreview } from 'utilities/Examinations/Previews';

import { formatCreatedDateTime } from './helpers';

export const getPeakFlowPreview = (
	data: PeakFlowPreviewModel,
	personalBest
): ExaminationPreview => {
	const peakFlowPreview = new PeakFlowPreview(data, personalBest);

	const pef = peakFlowPreview.getPefPreview();
	return {
		id: data.id,
		type: data.type,
		tab: data.type,
		createDateTime: formatCreatedDateTime(data.createDateTime),
		title: CHART_MEASUREMENT_PARAMETER_OPTION_NAMES.pef.translationKey,
		parameters: [pef],
		pdfReport: data.pdfReport || null
	};
};
