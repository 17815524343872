import { DATE_FORMAT, EMPTY_VALUE, TIME_FORMAT } from 'helpers';
import React from 'react';
import { LocaleDate } from 'utilities';

import { getDateWithTimeCellValue } from './helpers';

export interface IDateProps {
	/**
	 * Date string or Date object to be rendered.
	 */
	date: string | Date | null;
	/**
	 * Date format.
	 */
	dateFormat?: DeepValuesOf<typeof DATE_FORMAT>;
	/**
	 * Time format
	 */
	timeFormat?: DeepValuesOf<Omit<typeof TIME_FORMAT, 'PM' | 'AM'>>;
	/**
	 * Determines if render time next to date.
	 */
	withTime?: boolean;
}
export const Date = ({
	date,
	dateFormat = DATE_FORMAT.DEFAULT,
	withTime = false,
	timeFormat = TIME_FORMAT.DEFAULT
}: IDateProps) => {
	if (!date) {
		return <>{EMPTY_VALUE}</>;
	}

	const dateWithTSeparator =
		typeof date === 'string' ? date.replace(' ', 'T') : date;
	if (withTime) {
		const dateLabelWithTime = getDateWithTimeCellValue(
			dateWithTSeparator,
			dateFormat,
			timeFormat
		);
		return <>{dateLabelWithTime}</>;
	}

	const dateLabelWithTime = new LocaleDate(
		dateWithTSeparator
	).getStringFormatDate(dateFormat);
	return <>{dateLabelWithTime}</>;
};
