export class MyObject {
	constructor() {}

	static entries<T>(o: { [key in keyof T]: ValuesOf<T> }) {
		const objKeys = Object.keys(o) as Array<keyof T>;
		let i = objKeys.length;
		const resArray: Array<[keyof T, ValuesOf<T>]> = [];
		while (i > 0) {
			i = i - 1;
			resArray[i] = [objKeys[i], o[objKeys[i]]];
		}

		return resArray;
	}
}
