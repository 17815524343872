import { makeStyles } from '@material-ui/styles';
import theme from 'theme';

export const INPUT_STYLES_INVALID = {
	border: `2px solid ${theme.palette.error.alert}`
};
export const useStyles = makeStyles(() => ({
	root: {
		'& :first-child': {
			marginLeft: 0
		},
		'& :last-child': {
			marginRight: 0
		},
		'& input': {
			margin: '0 5px',
			width: 48,
			height: 56,
			borderRadius: '5px',
			color: theme.palette.customBlack.main,
			fontSize: '30px',
			textAlign: 'center',
			border: `1px solid ${theme.palette.gray.disabled}`,
			'&:focus, &:focus-visible': {
				border: `2px solid ${theme.palette.primary.main}`
			}
		}
	}
}));
