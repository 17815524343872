import { EventIcon } from 'assets/icons/commonMUIIcons';
import { useTranslations } from 'components/utilities';
import { bool, func, string } from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';

import { PickersUtilsProvider } from '../../../../molecules';
import { KeyboardDatePickerFieldAdapter } from '../..';
import useKeyboardDatePickerField from './useKeyboardDatePickerField';

const KeyboardDatePickerField = ({
	label,
	name,
	submitting = false,
	disableFuture = true,
	disablePast = false,
	required = false,
	//eslint-disable-next-line
	onChange = (e) => {},
	variant = 'dialog',
	...rest
}) => {
	const { t } = useTranslations();

	const {
		validateField,
		onChangeDate,
		submitting: submittingFormData
	} = useKeyboardDatePickerField({
		onChange,
		name,
		disablePast,
		disableFuture
	});

	return (
		<PickersUtilsProvider variant={variant}>
			{({ translationsProps }) => (
				<Field
					name={name}
					label={t(label)}
					validate={validateField}
					component={KeyboardDatePickerFieldAdapter}
					inputVariant="outlined"
					required={required}
					disabled={submitting || submittingFormData}
					disableFuture={disableFuture}
					disablePast={disablePast}
					keyboardIcon={
						<EventIcon fontSize="small" color="primary" />
					}
					onChange={onChangeDate}
					variant={variant}
					autoOk
					{...translationsProps}
					{...rest}
				/>
			)}
		</PickersUtilsProvider>
	);
};

KeyboardDatePickerField.propTypes = {
	label: string.isRequired,
	name: string.isRequired,
	submitting: bool,
	disableFuture: bool,
	disablePast: bool,
	required: bool,
	onChange: func,
	variant: string
};

export default KeyboardDatePickerField;
