import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		gap: 60
	},
	button: {
		textTransform: 'none'
	}
}));
