import { useMemo } from 'react';

import { Ctx } from './types';

export interface UsePatientFullName {
	ctx: Ctx['ctx'];
}

export const usePatientFullName = ({ ctx }: UsePatientFullName) => {
	const { firstName, lastName } = ctx.row.original;
	const fullName = useMemo(() => `${firstName} ${lastName}`, []);
	return fullName;
};
