import { Typography } from '@material-ui/core';
import { useMetricSystem } from 'components/utilities';
import { number } from 'prop-types';
import React from 'react';

import { convertValue } from './helpers';
import { useStyles } from './styles';

const TooltipContent = ({ value }) => {
	const classes = useStyles();

	const { numberSeparator } = useMetricSystem();

	return (
		<Typography className={classes.typography}>
			{numberSeparator(convertValue(value))}
		</Typography>
	);
};

TooltipContent.propTypes = {
	value: number.isRequired
};

export default TooltipContent;
