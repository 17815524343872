export default ({ email, password, password_confirmation }) => {
	let errors = {};

	if (!email) {
		errors.email = 'reset_password.validation.email_required';
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
		errors.email = 'reset_password.validation.email_invalid';
	}

	if (!password) {
		errors.password = 'reset_password.validation.password_required';
	} else if (password.length < 8) {
		errors.password = 'reset_password.validation.password_invalid';
	}

	if (!password_confirmation) {
		errors.password = 'reset_password.validation.password_required';
	} else if (password_confirmation.length < 8) {
		errors.password = 'reset_password.validation.password_invalid';
	}

	if (
		password !== '' &&
		password_confirmation !== '' &&
		password !== password_confirmation
	) {
		errors.password =
			'reset_password.validation.passwords_are_not_the_same';
		errors.password_confirmation =
			'reset_password.validation.passwords_are_not_the_same';
	}

	return errors;
};
