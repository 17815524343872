import { Divider } from '@material-ui/core';
import { DOSAGE_FIELDS_BLOCKERS, MEDICATION_TYPES } from 'helpers';
import { shape, string } from 'prop-types';
import React from 'react';

import {
	DatePicker,
	DosageFields,
	DrugPurpose,
	MedicationTypes,
	Note,
	TimeOfDay
} from './components';
import { useStyles } from './styles';

const DrugFields = (props) => {
	const classes = useStyles();

	/*eslint-disable*/
	const {
		dosage_form: dosageForm,
		dosage: { dosage_schedule }
	} = props;
	/*eslint-enable*/

	const isTimeOfDayActive = !DOSAGE_FIELDS_BLOCKERS.includes(dosage_schedule);

	return (
		<>
			<MedicationTypes {...props} />
			{dosageForm === MEDICATION_TYPES.INHALER && (
				<DrugPurpose {...props} />
			)}
			<Divider className={classes.divider} />
			<DosageFields {...props} />
			{isTimeOfDayActive && (
				<>
					<TimeOfDay {...props} />
					<Divider className={classes.divider} />
					<DatePicker {...props} />
				</>
			)}
			<Divider className={classes.divider} />
			<Note {...props} />
		</>
	);
};

DrugFields.propTypes = {
	props: shape({
		dosage_form: string.isRequired
	})
};

export default DrugFields;
