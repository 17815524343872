import { PointOptionsObject } from 'highcharts';
import { ForcedSpirometryMeasurementModel } from 'models/Report/ForcedSpirometry';

export const prepareAllMeasurementsDataToRenderInGraph = (
	data: ForcedSpirometryMeasurementModel[],
	dataType: 'flowVolumeChart' | 'volumeTimeChart',
	color: string,
	name: 'pre' | 'post'
): {
	data: PointOptionsObject[];
	isBest: boolean;
	lineWidth: number;
	color: string;
	name: 'pre' | 'post';
}[] =>
	data.map((d) => ({
		isBest: false,
		data: d[dataType] ?? [],
		lineWidth: 2,
		color,
		name
	}));
