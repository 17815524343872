import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	signature: {
		borderTop: '1px solid black',
		marginTop: 15,
		padding: '0px 5px',
		paddingTop: '2.5px',
		display: 'flex',
		justifyContent: 'space-between',
		width: '250px',
		alignSelf: 'flex-end'
	}
}));
