import palette from '../palette';

export default {
	marginNormal: {
		marginTop: 8,
		'& .MuiOutlinedInput-input': {
			padding: '19.7px 14px'
		},
		'& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
			padding: 10.2
		},
		'& .MuiInputBase-root.Mui-disabled': {
			background: palette.field.disabled.bg
		}
	}
};
