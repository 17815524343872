import { TableBody as MuiTableBody, TableBodyProps } from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from './styles';

//eslint-disable-next-line
export interface ITableBodyProps extends TableBodyProps {}

export const TableBody = ({
	children,
	className,
	...props
}: ITableBodyProps) => {
	const classes = useStyles();
	return (
		<MuiTableBody
			className={clsx([className, classes.tableBody])}
			{...props}
		>
			{children}
		</MuiTableBody>
	);
};
