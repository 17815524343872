import React from 'react';

//Import context
import { MedicationsContext } from './MedicationsProvider';

const useMedicationsContextProvider = () => {
	const context = React.useContext(MedicationsContext);

	if (!context) {
		throw new Error(
			'useMedicationsContextProvider must be used within a MedicationsProvider'
		);
	}

	return context;
};

export default useMedicationsContextProvider;
