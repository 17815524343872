import clsx from 'clsx';
import React from 'react';

import { Container, ContainerProps } from './Container';
import { useStyles } from './styles';

export const ContainerWithPrimaryBackground = ({
	children,
	className = ''
}: ContainerProps) => {
	const classes = useStyles();
	return (
		<Container className={clsx([className, classes.withPrimaryBackground])}>
			{children}
		</Container>
	);
};
