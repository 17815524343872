import { DECIMAL_SEPARATORS } from 'helpers/variables';
import { produce } from 'immer';
import * as types from 'store/types';

const { COMA } = DECIMAL_SEPARATORS;

const INITIAL_STATE = {
	separator: COMA
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.SET_NUMBER_SEPARATOR:
				draft.separator = action.payload;
				break;

			default:
				return state;
		}
	});
