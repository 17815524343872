import { array, func, object, oneOfType, string } from 'prop-types';
import React from 'react';
import { Route } from 'react-router-dom';

const RouteWithLayout = (props) => {
	const {
		layout: Layout,
		component: Component,
		helmet: HelmetComponent,
		...rest
	} = props;

	return (
		<Route
			{...rest}
			render={(matchProps) => (
				<Layout>
					{HelmetComponent}
					<Component {...matchProps} />
				</Layout>
			)}
		/>
	);
};

RouteWithLayout.propTypes = {
	component: oneOfType([object, func]).isRequired,
	helmet: object.isRequired,
	layout: func.isRequired,
	path: oneOfType([string, array])
};

export default RouteWithLayout;
