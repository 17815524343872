import React from 'react';

const useRadioGroup = ({ initialValue, onChange }) => {
	const [value, setValue] = React.useState(initialValue);

	const handleRadioChange = (e) => {
		setValue(e.target.value);
		onChange?.(e);
	};

	return { value, onRadioChange: handleRadioChange };
};

export default useRadioGroup;
