import { produce } from 'immer';

import * as types from './types';

const reducer = (state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.TOGGLE_FORM_OPEN:
				draft.formOpen = !draft.formOpen;
				break;

			case types.SET_EMAIL_AVAILABLE:
				draft.isEmailAvailable = action.payload;
				break;

			case types.SET_EMAIL_DUPLICATED:
				draft.isEmailDuplicated = action.payload;
				break;

			case types.NEXT_ACTIVE_STEP:
				draft.activeStep = draft.activeStep + 1;
				break;

			case types.PREV_ACTIVE_STEP:
				draft.activeStep =
					draft.activeStep !== 0 && draft.activeStep - 1;
				break;

			case types.RESET_ACTIVE_STEP:
				draft.activeStep = 0;
				break;

			case types.SET_NEW_PATIENT_ID:
				draft.newPatientId = action.payload;
				break;

			case types.SET_MARK_PATIENT_AS_INVITED:
				draft.isPatientInvited = action.payload;
				break;

			default:
				return state;
		}
	});

export default reducer;
