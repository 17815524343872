import { Box } from '@material-ui/core';
import {
	ContainerWithBorder,
	ParameterLabel,
	Title,
	Typography
} from 'components/elements/atoms/ExaminationsV2';
import { ValueInBorder } from 'components/elements/molecules/ExaminationsV2';
import { useTranslations } from 'components/utilities';
import { BronchodilatorReportModel } from 'models/Report/Reports';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import useObstructionLevelLegend from 'utilities/Examinations/ExaminationDetails/common/useObstructionLevelLegend';

import { ObstructionPrePostValues } from './ObstructionPrePostValues';
import { useStyles } from './styles';

export type OrientationType = 'column' | 'row';

type Props = {
	examination: BronchodilatorReportModel | undefined;
	orientation?: OrientationType;
};

export const Obstruction = ({ examination, orientation = 'column' }: Props) => {
	const classes = useStyles({ orientation });
	const { t } = useTranslations();

	const { detectedObstructionLegend, obstructionLevelLegend, noObstruction } =
		useObstructionLevelLegend();

	const renderLegend = () => {
		if (orientation === 'row') {
			return (
				<Box className={classes.rowLegend}>
					<div className={classes.spacer} />

					<Typography className={classes.obstructionLegend}>
						<>
							{t('report.report_interpretation.ref_values')}
							<br />
							{ReactHtmlParser(
								`${noObstruction}<br/>${detectedObstructionLegend}`
							)}
						</>
					</Typography>

					<Typography className={classes.obstructionLegend}>
						<>{ReactHtmlParser(`${obstructionLevelLegend}`)}</>
					</Typography>
					<div className={classes.spacer} />
				</Box>
			);
		}
		return (
			<Typography className={classes.obstructionLegend}>
				<>
					{t('report.report_interpretation.ref_values')}
					<br />
					{ReactHtmlParser(
						`${noObstruction}<br/>${detectedObstructionLegend}`
					)}
					<br />
					<br />
					{ReactHtmlParser(`${obstructionLevelLegend}`)}
				</>
			</Typography>
		);
	};

	return (
		<ContainerWithBorder className={classes.root}>
			<Box className={classes.obstructionContainer}>
				<Box>
					<Title
						className={classes.title}
						textAlign="center"
						title="report.obstruction.title"
					/>
					<ParameterLabel
						className={classes.subTitle}
						textAlign="center"
						label="report.report_interpretation.automated_interpretation"
					/>
				</Box>
				<Box display="flex" justifyContent={'center'}>
					<ValueInBorder
						value={`report.bronchodilator.obstruction.${examination?.testResult.obturation}`}
					/>
				</Box>
				<ObstructionPrePostValues
					examination={examination}
					orientation={orientation}
				/>
			</Box>
			{renderLegend()}
		</ContainerWithBorder>
	);
};
