import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslations } from 'components/utilities';
import {
	arrayOf,
	bool,
	func,
	node,
	oneOfType,
	shape,
	string
} from 'prop-types';
import React from 'react';
import ReactHTMLParser from 'react-html-parser';
import theme from 'theme';

import { useStyles } from './styles';

const Placeholder = ({
	label,
	active = false,
	children,
	classes: classesFromProps,
	onClick = () => {},
	prefixMarker = '<',
	suffixMarker = '>',
	backgroundColor = `${theme.palette.primary.main}20`
}) => {
	const { t } = useTranslations();
	const classes = useStyles({ backgroundColor });
	return (
		<div
			className={clsx([
				classesFromProps?.root,
				classes.relativeContainer
			])}
			onClick={onClick}
		>
			{active && (
				<Box
					className={clsx([
						classes.root,
						classesFromProps?.labelContainer
					])}
				>
					<Typography
						variant="h2"
						className={clsx([
							classes.label,
							classesFromProps?.label
						])}
					>
						{ReactHTMLParser(
							`${prefixMarker ? `${prefixMarker} ` : ''}${
								t(label) || t('global.no_data')
							}${suffixMarker ? ` ${suffixMarker}` : ''}`
						)}
					</Typography>
				</Box>
			)}
			{children}
		</div>
	);
};

Placeholder.propTypes = {
	label: string,
	prefixMarker: string,
	suffixMarker: string,
	backgroundColor: string,
	active: bool.isRequired,
	onClick: func,
	classes: shape({
		label: string,
		root: string,
		labelContainer: string
	}),
	children: oneOfType([arrayOf(node), node]).isRequired
};

export default Placeholder;
