import { LoginTemplate } from 'components/templates';
import { PANEL_ROLES, QUERY_CREATE_DOCTOR_RESOURCES } from 'helpers';
import { array, func, string } from 'prop-types';
import { DoctorRepository } from 'queries';
import React from 'react';

import { EmployeeInvitationForm } from './form';
import useEmployeeInvitation from './useEmployeeInvitation';

const EmployeeInvitation = ({
	fieldSettings = [],
	role = PANEL_ROLES.doctor,
	resourcesQueryKey = QUERY_CREATE_DOCTOR_RESOURCES,
	createAccountRepository = DoctorRepository.createAccount,
	...rest
}) => {
	const formInvitationProps = useEmployeeInvitation({ role });
	return (
		<LoginTemplate>
			<EmployeeInvitationForm
				role={role}
				fieldSettings={fieldSettings}
				resourcesQueryKey={resourcesQueryKey}
				createAccountRepository={createAccountRepository}
				{...formInvitationProps}
				{...rest}
			/>
		</LoginTemplate>
	);
};

EmployeeInvitation.propTypes = {
	fieldSettings: array,
	role: string,
	resourcesQueryKey: string,
	createAccountRepository: func
};

export default EmployeeInvitation;
