import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	labelWrapper: { display: 'flex' },
	label: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-around',
		minWidth: 164,
		cursor: 'pointer',
		padding: 10,
		background: theme.palette.primary.main,
		color: theme.palette.white,
		borderRadius: 6,
		position: 'relative'
	},
	inputFile: {
		display: 'none'
	},
	errorText: {
		marginTop: theme.spacing(2),
		color: theme.palette.error.main
	},
	progress: {
		color: theme.palette.white,
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12
	},
	visibleButton: {
		visibility: ({ isPreviewVisible }) =>
			isPreviewVisible ? 'visible' : 'hidden'
	}
}));
