import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';

export default {
	black,
	white,
	primary: {
		contrastText: white,
		dark: '#200D84',
		main: '#4A4FE0',
		light: colors.indigo[100],
		lighter: '#F0F1FC'
	},
	secondary: {
		contrastText: white,
		dark: colors.blue[900],
		main: colors.blue['A400'],
		light: colors.blue['A400']
	},
	success: {
		contrastText: white,
		dark: colors.green[900],
		main: colors.green[600],
		light: colors.green[400]
	},
	info: {
		contrastText: white,
		dark: colors.blue[900],
		main: colors.blue[600],
		light: colors.blue[400]
	},
	warning: {
		contrastText: white,
		dark: colors.orange[900],
		main: colors.orange[600],
		light: colors.orange[400]
	},
	error: {
		contrastText: white,
		alert: '#F44336',
		darker: '#F56666',
		dark: '#ff3131',
		main: '#ff4a4a',
		medium: '#FF8080',
		light: '#ff6464'
	},
	blue: {
		contrastText: white,
		dark: colors.blue[700],
		main: colors.blue[500],
		medium: '#1A9FF9',
		light: colors.blue[300],
		custom: '#11A0F7',
		indigo: '#C8C8D7'
	},
	green: {
		dark: '#35D402',
		main: '#70E04C',
		medium: '#71E14D',
		light: '#4CD63C'
	},
	yellow: {
		dark: '#DECC35',
		main: '#EAE000',
		medium: '#EAE002',
		light: '#F0E94D'
	},
	orange: {
		main: '#F37733',
		secondary: '#FF6E3B'
	},
	gray: {
		heavy: '#2B2B2B',
		dark: '#9D9E9A',
		medium: '#707070',
		main: '#F3F3F3',
		light: '#F5F5F5',
		custom: '#343434',
		disabled: '#A3A3A3'
	},
	customBlack: {
		main: '#212121',
		medium: '#333333'
	},
	text: {
		main: colors.blueGrey[900],
		secondary: colors.blueGrey[600],
		link: colors.blue[600],
		gray: '#BDBDBD'
	},
	field: {
		disabled: {
			bg: '#ECECEC'
		}
	},
	background: {
		medicalNote: '#ECECEC',
		actionPlan: '#F0F1FC',
		tableHeader: '#F0F1FC',
		default: '#F4F6F8',
		form: '#F8F8F8',
		paper: white
	},
	icon: colors.blueGrey[600],
	divider: colors.grey[200],
	border: {
		table: '#E0E0E0',
		light: '#E2E2E2',
		medium: '#DADADA',
		main: '#C4C4C4',
		dark: '#CCCCCC',
		custom: '#ECECEC'
	},
	chip: {
		red: { light: '#F9DDDD' },
		green: { medium: '#92D48A' },
		gray: { main: '#DADADA' }
	},
	parameters: {
		chart: {
			fev1: '#11A0F7',
			fvc: '#815745',
			pef: '#4AE0B2',
			tiffeneau: '#3DC638',
			tiffeneauMax: '#9bc638',
			hrAvg: '#CF7DA9',
			spO2: '#FC9DEE',
			ic: '#E2A593',
			vc: '#898CB5',
			aqi: '#4A4FE0',
			voc: '#FC9DEE',
			pm25: '#DB5A35',
			pm10: '#D4D181',
			medicalNote: '#C3C3C3'
		},
		chartLight: {
			SPIROMETRY: '#88CFFB',
			PEAKFLOW: '#A4EFD8',
			COPDTEST: '#9EE39B',
			PULSEOXIMETRY: '#CF7DA9',
			MEDICALNOTE: '#BABABA',
			SVC_TEST: '#C8C8D7'
		},
		results: {
			success: '#4CD63C',
			warning: '#DECC35',
			error: '#F56666',
			default: '#ABABAB',
			ic: '#D06DE2',
			vc: '#D06DE2'
		},
		navigator: {
			outerZone: '#CCCCCC'
		}
	},
	zScore: {
		green: '#4CD63C',
		yellow: '#DECC35',
		yellowLight: '#E8DC72',
		red: '#F56666'
	},
	dailyChecks: {
		green: '#4CD63C',
		yellow: '#DECC35',
		red: '#F56666',
		orange: '#F37733',
		gray: '#BDBEBE',
		white
	},
	examination: {
		chart: [
			'#4A4FE0',
			'#11A0F7',
			'#4EBFF1',
			'#898CB5',
			'#88CFFB',
			'#777AE0',
			'#389FF9',
			'#4A4FE01A'
		],
		COPDChart: {
			pre: '#D8A6D6',
			post: '#11E7F7'
		}
	},
	medication: {
		reliever: '#389FF9',
		preventer: '#70E04C'
	},
	chat: {
		selected: '#4A4FE01A',
		messageReceived: '#ECEDFC'
	},
	attempt: {
		font: '#CECECE',
		gray: 'rgba(112, 112, 112, 0.262745)',
		grayLight: '#E5E5E5'
	},
	review: {
		green: '#4CD63C',
		yellow: '#DECC35',
		red: '#F56666',
		orange: '#F37733',
		gray: '#BDBEBE',
		topbar: {
			bg: '#F8F8F8',
			border: '#00000014'
		}
	},
	alerts: {
		success: '#D2ECCF',
		warning: '#FFDDB5',
		error: '#FBACAC'
	},
	switches: {
		dark: '#808080',
		main: '#A5A5A5'
	},
	report: {
		blue: {
			dark: '#200d84',
			main: '#5543FF'
		},
		green: {
			main: '#40B246'
		}
	}
};
