import clsx from 'clsx';
import { useTranslations } from 'components/utilities';
import { EMPTY_VALUE } from 'helpers/variables';
import React from 'react';

import { Typography } from '../Typography';
import { ValueLabelProps } from '.';
import { useStyles } from './styles';

export const ValueLabel = ({
	children,
	value = EMPTY_VALUE,
	className,
	valueWidth
}: ValueLabelProps) => {
	const classes = useStyles({ valueWidth });
	const { t } = useTranslations();

	const renderValue = () => {
		if (value === null || value === undefined) {
			return EMPTY_VALUE;
		}
		if (Array.isArray(value)) {
			return value;
		}
		if (t(value)) {
			return t(value);
		}
		return value;
	};
	return (
		<Typography className={clsx([classes.value, className])}>
			<>
				{renderValue()}
				{children}
			</>
		</Typography>
	);
};
