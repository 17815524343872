import { FormModalDialog } from 'components/elements';
import { TextInput } from 'components/elements/molecules/Inputs/V2';
import { useToggle } from 'components/utilities';
import React from 'react';

import { ButtonCell } from '../components';
import {
	FIELD_NAMES,
	ShareReportFormFieldValuesModel,
	ShareReportFormFieldValuesSchema
} from './helpers';
import { useShareReportCell } from './useShareReportCell';

export const ShareReportCell = () => {
	const { on, toggle } = useToggle(false);

	const { initialValues, apiError, isSuccess, onSubmit, resetMutation } =
		useShareReportCell();

	return (
		<>
			<ButtonCell
				onClick={toggle}
				label="patients.examinations_v2.details.generate_report_pdf.share_report_cell"
			/>
			<FormModalDialog<ShareReportFormFieldValuesModel>
				open={on}
				close={toggle}
				maxWidth="sm"
				title="patients.examinations_v2.details.generate_report_pdf.sharing_report"
				onSubmit={onSubmit}
				onExited={resetMutation}
				defaultValues={initialValues}
				validationSchema={ShareReportFormFieldValuesSchema}
				apiError={apiError}
				apiFieldErrorTranslationKeyPrefix="global.input"
				actionButtons={{
					showCancelButton: false,
					submitLabel: 'btn.share'
				}}
				isSuccess={isSuccess}
				successContentProps={{
					close: () => {},
					textContent:
						'patients.examinations_v2.details.generate_report_pdf.share_report.success'
				}}
			>
				<TextInput
					name={FIELD_NAMES.RECIPIENT_EMAIL}
					label={'global.email'}
					required
				/>
			</FormModalDialog>
		</>
	);
};
