import { Box } from '@material-ui/core';
import React from 'react';

import { useStyles } from './styles';

type Props = { children: CmpChildren };
export const PageSize = ({ children }: Props) => {
	const classes = useStyles();

	return <Box className={classes.root}>{children}</Box>;
};
