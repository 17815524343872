import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: 0,
		marginBottom: 0,
		minWidth: ({ matchesFields }) => (matchesFields ? 160 : 110),
		'& .MuiSelect-root': {
			paddingRight: 10
		}
	},
	typography: {
		marginBottom: 5,
		marginTop: theme.spacing(2)
	},
	container: {
		display: 'flex',
		flexDirection: 'column'
	},
	button: {
		width: 100
	},
	numberField: {
		marginTop: 0,
		marginBottom: ({ matchDoseField }) =>
			matchDoseField ? theme.spacing(1) : theme.spacing(0),
		maxWidth: 80,
		marginRight: theme.spacing(1),
		'& .MuiInputBase-input': {
			padding: '10.5px 5px 10.5px 14px'
		}
	},
	dosageFormContainer: {
		display: 'flex',
		flexDirection: ({ matchesTimeOfDay }) =>
			matchesTimeOfDay ? 'column' : 'row'
	},
	dosageForm: {
		marginBottom: theme.spacing(1),
		marginLeft: ({ matchesTimeOfDay }) =>
			matchesTimeOfDay && theme.spacing(3.75)
	}
}));
