import { convertCountryCodeToName } from 'helpers';

const useProfile = ({ patient, countries }) => {
	const { patientCity, patientCountryCode } = patient.patient;

	const patientCountry =
		patientCountryCode &&
		convertCountryCodeToName(patientCountryCode, countries.data);

	const lineDivider =
		(patientCity && patientCountryCode) ||
		(patientCity && !patientCountryCode)
			? ' | '
			: '';

	const commaDivider = patientCity && patientCountryCode ? ', ' : '';

	return { patientCountry, lineDivider, commaDivider };
};

export default useProfile;
