import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	formControlLabel: {
		'& span': { fontWeight: 600 },
		marginRight: 40,
		fontSize: 13,
		[theme.breakpoints.down(890)]: {
			marginTop: theme.spacing(1.2)
		}
	},
	label: {
		paddingTop: 5
	}
}));
