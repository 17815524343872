import { EmployeeInvitation, HelmetTags } from 'components/elements';
import { INVITED_PATIENT_DATA_API_QUERY_KEY, PANEL_ROLES } from 'helpers';
import { InvitationsRepository } from 'queries';
import React from 'react';
import { useParams } from 'utilities';

import {
	DISABLED_FIELDS,
	INITIAL_PATIENT_INVITATION_SIGNUP_STATE
} from './helpers';
import validation from './validation';

const PatientInvitation = () => {
	const { token } = useParams();
	return (
		<>
			<HelmetTags title="pages.patient_invitation.title" />
			<EmployeeInvitation
				initialFormState={INITIAL_PATIENT_INVITATION_SIGNUP_STATE}
				disabledFields={DISABLED_FIELDS}
				role={PANEL_ROLES.patient}
				resourcesQueryKey={INVITED_PATIENT_DATA_API_QUERY_KEY}
				createAccountService={(data) => {
					return {
						password: data.values.password,
						password_confirmation:
							data.values.password_confirmation,
						type: PANEL_ROLES.patient,
						invitationId: token,
						privacy_policy: data.values.privacy_policy,
						terms_conditions: data.values.terms_conditions
					};
				}}
				createAccountRepository={
					InvitationsRepository.patient.createAccount
				}
				translationKeyBase="sign_up.doctor"
				disableUnknownGender={true}
				validation={validation}
			/>
		</>
	);
};

export default PatientInvitation;
