import { validateExaminationType } from 'components/elements/atoms/ExaminationsV2/ExaminationCopdTypeLabel/ExaminationCopdTypeLabel';
import { CHART_MEASUREMENT_PARAMETER_OPTION_NAMES } from 'helpers';
import { SpirometryPreviewModel } from 'models/Examinations/allExaminationsTypes';

import { CopdSpirometryTooltipDimensions } from './CopdSpirometryTooltipDimensions';
import { PeakFlowTooltipDimensions } from './PeakFlowTooltipDimensions';
import { PulseOximetryTooltipDimensions } from './PulseOximetryTooltipDimensions';
import { SpirometryTooltipDimensions } from './SpirometryTooltipDimensions';
import { SvcTooltipDimensions } from './SvcTooltipDimensions';

const { fev1, fvc, pef, tiffeneau, ic, vc, hrAvg, spO2 } =
	CHART_MEASUREMENT_PARAMETER_OPTION_NAMES;

export interface IDimensions {
	width: number;
	height: number;
}
export interface ITooltipDimensionsFactory {
	calculateDimensions: () => IDimensions;
}

export class TooltipDimensionsFactory {
	#chartType: ExaminationChartNames;
	#examinationType: SpirometryPreviewModel['examinationType'];
	constructor(
		chartType: ExaminationChartNames,
		examinationType: SpirometryPreviewModel['examinationType']
	) {
		this.#chartType = chartType;
		this.#examinationType = examinationType;
	}

	calculateDimensions = (): IDimensions => {
		let dimensions;
		switch (this.#chartType?.toLowerCase()) {
			case fev1.name:
			case fvc.name:
			case tiffeneau.name:
				if (validateExaminationType(this.#examinationType)) {
					dimensions = new CopdSpirometryTooltipDimensions();
					break;
				}
				dimensions = new SpirometryTooltipDimensions();
				dimensions = new SpirometryTooltipDimensions();
				break;
			case pef.name:
				dimensions = new PeakFlowTooltipDimensions();
				break;
			case ic.name:
			case vc.name:
				dimensions = new SvcTooltipDimensions();
				break;
			case hrAvg.name.toLowerCase():
			case spO2.name.toLowerCase():
				dimensions = new PulseOximetryTooltipDimensions();
				break;
		}

		return dimensions;
	};
}
