import {
	useExaminationsV2Context,
	useExaminationsV2RefsContext
} from 'components/context/ExaminationsV2';
import { PATIENTS_DETAILS_PATHS } from 'components/routes/paths';
import React from 'react';
import { generatePath } from 'react-router-dom';
import { useParams } from 'utilities';

export const useInitialListActiveIndex = () => {
	const params = useParams();

	const { scrollToExaminationsListItem } = useExaminationsV2RefsContext();
	const {
		store: initialActiveExaminationIndex,
		setInitialActiveExaminationIndex
	} = useExaminationsV2Context((s) => s.initialActiveExaminationIndex);

	const isExaminationPreviewView = React.useMemo(
		() =>
			location.pathname ===
			generatePath(PATIENTS_DETAILS_PATHS.EXAMINATIONS.PREVIEW, params),
		[location.pathname]
	);

	React.useEffect(() => {
		if (
			initialActiveExaminationIndex !== null &&
			isExaminationPreviewView
		) {
			scrollToExaminationsListItem(initialActiveExaminationIndex);
		}
	}, [initialActiveExaminationIndex, isExaminationPreviewView]);

	return { setInitialIndex: setInitialActiveExaminationIndex };
};
