import { makeStyles } from '@material-ui/styles';
import { EXAMINATIONS_TYPES } from 'helpers';

export const useGlobalStyles = makeStyles((theme) => ({
	rightSide: { display: 'flex' },
	flex: { display: 'flex' },
	parameters: {
		flex: 1,
		display: 'flex',
		gap: '15px',
		justifyContent: 'flex-start',
		paddingBottom: 10
	},
	hiddenBox: {
		width: 18,
		height: 18
	},
	arrowIcon: {
		fontSize: 12,
		position: 'relative',
		top: ({ type }) =>
			type === EXAMINATIONS_TYPES.SPIROMETRY.type ? 40 : 30,
		fill: theme.palette.blue.main,
		alignSelf: 'flex-start',
		marginLeft: 4,
		opacity: ({ manual }) => (manual ? 0 : 1)
	}
}));
