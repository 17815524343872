import { Button as MuiButton, ButtonProps } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import { useStyles } from './styles';
export interface IButton
	extends Omit<ButtonProps, 'children' | 'color' | 'size'> {
	children: React.ReactNode;
	className?: string;
	variant?: ButtonVariant;
	color?: ButtonColor;
	size?: ButtonSize | 'tiny';
}

const Button = ({
	children,
	className,
	variant = 'contained',
	color = 'primary',
	size = 'medium',
	...props
}: IButton) => {
	const classes = useStyles({ variant });

	const isColorError = color === 'error';
	const tinySizeClassName = size === 'tiny' ? classes.tiny : '';
	return (
		<MuiButton
			className={clsx(
				classes.root,
				isColorError ? classes.error : '',
				tinySizeClassName,
				className
			)}
			variant={variant}
			color={isColorError ? 'default' : color}
			size={size === 'tiny' ? 'small' : size}
			{...props}
		>
			{children}
		</MuiButton>
	);
};

declare module '@material-ui/core/Button' {
	interface ButtonPropsVariantOverrides {
		tiny: true;
	}
}

export default Button;
