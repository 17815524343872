import { EMPTY_VALUE } from '../variables';

export function isValueValid(value, valueAdditionalValidation = (v) => v >= 0) {
	if (value === null || value === '-0') {
		return false;
	}
	const number = Number(value);
	const validNumberValue = valueAdditionalValidation(number);

	return validNumberValue && !Number.isNaN(number);
}

export function validateExaminationValue(value, valueAdditionalValidation) {
	if (!isValueValid(value, valueAdditionalValidation)) return EMPTY_VALUE;
	return value;
}
