import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	tableBody: {
		'& tr': {
			'&:last-of-type': {
				'& p': {
					fontSize: '8px',
					color: '#707070'
				}
			}
		}
	}
}));
