import { CLINIC_DATA_QUERY_KEY } from 'helpers';
import { useGetCachedData } from 'queries/utilities/useGetCachedData';
import { z } from 'zod';

import {
	ClinicDataModel,
	CreatedClinicDataSchema
} from '../../../models/Clinic/Clinic';
import { queryClient } from '../../../utilities/ReactQueryClient/ReactQueryClient';

export const useGetCachedClinicData = () => {
	const clinicData = useGetCachedData<ClinicDataModel>([
		CLINIC_DATA_QUERY_KEY
	]) as z.infer<typeof CreatedClinicDataSchema> | undefined;

	return clinicData;
};

export const getCachedClinicData = () =>
	queryClient.getQueryData<ClinicDataModel>([CLINIC_DATA_QUERY_KEY]) as
		| z.infer<typeof CreatedClinicDataSchema>
		| undefined;
