import { PANEL_ROLES } from 'helpers';
import { EMPLOYEES_INVITATION } from 'helpers/Forms/fieldNames';

export const FIELD_SPECIALIZATION_NAME = 'specialization';
export const FIELD_SPECIALIZATION_ID = 'specialization_id';
export const FIELD_PHONE_PREFIX_NAME = 'phone_prefix';
export const FIELD_COUNTRY_CODE = 'countryCode';
export const GEOLOCATION_COUNTRY_FIELD_NAME = 'name';
export const FIELD_COUNTRY = 'city';

export const INITIAL_PATIENT_INVITATION_SIGNUP_STATE = {
	type: PANEL_ROLES.patient,
	first_name: '',
	last_name: '',
	email: '',
	[FIELD_PHONE_PREFIX_NAME]: '+48',
	phone: '',
	[FIELD_COUNTRY_CODE]: '',
	[FIELD_COUNTRY]: '',
	password: '',
	password_confirmation: '',
	[EMPLOYEES_INVITATION.MARKETING_AGREEMENT]: false
};

export const DISABLED_FIELDS = [
	FIELD_SPECIALIZATION_ID,
	'first_name',
	'last_name',
	'email',
	FIELD_PHONE_PREFIX_NAME,
	'phone',
	'gender',
	'race',
	FIELD_COUNTRY_CODE,
	FIELD_COUNTRY,
	'birth_date'
];
