import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		border: `1px solid ${theme.palette.gray.dark}`,
		borderRadius: 5,
		padding: 10
	},
	text: {
		width: '190px',
		'& .MuiInputBase-root.Mui-disabled': {
			color: 'initial'
		},
		'& input': {
			cursor: 'pointer'
		}
	},
	textFieldWrapper: {
		position: 'relative'
	},
	disabledTextFieldEventHandler: {
		position: 'absolute !important',
		left: 0,
		bottom: 4,
		width: '100%',
		zIndex: 10
	}
}));
