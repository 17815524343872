import { CHART_MEASUREMENT_PARAMETER_OPTION_NAMES } from 'helpers';
import { SvcPreviewModel } from 'models/Examinations/allExaminationsTypes';

import { Svc } from '../common/Svc';
import { IParameterValuesData } from '../Parameters/ParameterValuesData';
import { IParameterPreviewRow } from './common';

type TSvcPreview = IParameterPreviewRow<IParameterValuesData>;
interface ISvcPreview {
	getIcPreview: () => TSvcPreview;
	getVcPreview: () => TSvcPreview;
}
export class SvcPreview extends Svc implements ISvcPreview {
	constructor(data: SvcPreviewModel) {
		super(data);
	}

	getIcPreview = (): TSvcPreview => ({
		name: CHART_MEASUREMENT_PARAMETER_OPTION_NAMES.ic.secondaryLabel,
		values: [this.getIcValues()]
	});

	getVcPreview = (): TSvcPreview => ({
		name: CHART_MEASUREMENT_PARAMETER_OPTION_NAMES.vc.label,
		values: [this.getVcValues()]
	});
}
