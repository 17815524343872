import { makeStyles } from '@material-ui/styles';

const transparent = 'transparent !important';

export const useStyles = makeStyles((theme) => ({
	card: {
		boxShadow: 'none'
	},
	root: {
		overflowX: 'auto',
		overflowY: 'hidden'
	},
	calendarMonth: {
		padding: '0 20px'
	},
	calendarWeekDay: {
		position: 'relative',

		'&::before': {
			content: '""',
			position: 'absolute',
			display: 'block',
			height: '26px',
			width: '20px',
			top: 0,
			right: 0,
			zIndex: 1,
			background: theme.palette.white
		},
		'& .fc-scrollgrid': {
			borderLeftColor: transparent,
			borderTopColor: transparent
		},
		'& .fc-scrollgrid-section > td': {
			borderColor: transparent,
			boxShadow: 'none'
		},
		'& .fc-theme-standard td:first-of-type': {
			borderRightColor: transparent
		},
		'& .fc-col-header-cell': {
			height: '2rem',
			padding: '0 !important'
		},
		'& .fc-theme-standard th': {
			borderLeft: 'none !important',
			borderRight: 'none !important'
		},
		'& .fc-highlight': {
			background: 'transparent'
		},
		'& .fc-timegrid-event-harness > a': {
			boxShadow: 'none'
		}
	}
}));
