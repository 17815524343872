import { produce } from 'immer';

import { INITIAL_STATE } from '../helpers';
import * as types from './types';

const reducer = (state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.TOGGLE_MEDICATION_FORM:
				draft.isFormOpen = !draft.isFormOpen;
				break;
			case types.SET_MEDICATION_INDEX:
				draft.medicationIndex = action.payload;
				break;
			case types.SET_MEDICATION_NAME:
				draft.medication = action.payload;
				break;
			case types.SET_DOSAGE:
				draft.dosage = action.payload;
				break;
			case types.RESET_DOSAGE_STATE:
				draft.dosage = INITIAL_STATE.dosage;
				break;
			case types.SET_DOSAGE_TYPE:
				draft.dosage.dosage_schedule = action.payload || 'when_needed';
				draft.dosage.days = action.payload?.days || [];
				break;
			case types.SET_DOSAGE_DAYS:
				draft.dosage.days = action.payload;
				break;
			case types.SET_DOSAGE_FORM:
				draft.dosage_form = action.payload || 'unknown';
				break;
			case types.SET_INHALER_PURPOSE:
				draft.type = action.payload;
				break;
			case types.SET_TAKING:
				draft.dosage.taking = action.payload;
				break;
			case types.SET_UNTIL:
				draft.dosage.until = action.payload;
				break;
			case types.SET_NOTE:
				draft.note = action.payload;
				break;

			case types.SET_INITIAL_FORM_DATA:
				if (action.payload) {
					const {
						note,
						until,
						taking,
						type,
						dosage_form,
						dosage_schedule,
						days,
						name
					} = action.payload;
					draft.dosage_form = dosage_form;
					draft.note = note;
					draft.dosage.until = until;
					draft.dosage.taking = taking;
					draft.type = type || INITIAL_STATE.type;
					draft.dosage.dosage_schedule = dosage_schedule;
					draft.dosage.days = days || [];
					draft.medication.name = name;
				} else {
					return { ...INITIAL_STATE };
				}
				break;

			default:
				return state;
		}
	});

export default reducer;
