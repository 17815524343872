import { makeStyles } from '@material-ui/styles';
import palette from 'theme/palette';

export const useStyles = makeStyles(() => ({
	container: {
		borderRadius: 10,
		border: `1px solid ${palette.border.light}`,
		padding: 10
	}
}));
