import clsx from 'clsx';
import React from 'react';

import { ActionButtons, IActionButtons } from './ActionButtons';
import { ContentContainer, IContentContainerProps } from './ContentContainer';
import { useStyles } from './styles';
import { ITitleProps, Title } from './Title';

export interface IContentLayoutProps<T> extends IContentContainerProps {
	titleProps?: ITitleProps;
	actionButtonsProps?: IActionButtons<T>;
	close?: () => void;
	children: CmpChildren;
	components?: {
		title?: (props: ITitleProps) => JSX.Element;
		actionButtons?: (props: IActionButtons<T>) => JSX.Element;
	};
}
export const ContentLayout = <T,>({
	titleProps = {},
	actionButtonsProps = {},
	components: Components = {},
	classNameContent,
	close,
	children
}: IContentLayoutProps<T>) => {
	const isTitleDefined = !!titleProps?.title;

	const classes = useStyles({ isTitleDefined });

	const title = {
		...titleProps,
		close: () => {
			titleProps?.close?.();
			close?.();
		}
	};

	const actionButtons = {
		...actionButtonsProps,
		cancel: {
			...actionButtonsProps?.cancel,
			onClick: (e) => {
				if (actionButtonsProps?.cancel?.onClick) {
					actionButtonsProps?.cancel?.onClick(e);
				}
				if (close) {
					close();
				}
			}
		}
	};

	return (
		<>
			{Components.title ? (
				<Components.title {...title} />
			) : (
				isTitleDefined && <Title {...title} />
			)}
			<ContentContainer
				classNameContent={clsx([
					classes.contentContainer,
					classNameContent
				])}
			>
				{children}
				{Components.actionButtons ? (
					<Components.actionButtons {...actionButtons} />
				) : !actionButtonsProps?.disableActionButtons && close ? (
					<ActionButtons {...actionButtons} />
				) : null}
			</ContentContainer>
		</>
	);
};
