import { API_URL_PREFIX } from '../helpers';
import { TOKEN_QUERY_PARAM_KEY } from '../queryParamKeys';

export const INVITED_PATIENT_DATA_API = `${API_URL_PREFIX}/invitation`;
export const INVITED_PATIENT_DATA_API_QUERY_KEY =
	'INVITED_PATIENT_DATA_API_QUERY_KEY';

export const PATIENT_LINK_INVITATION_API = `${API_URL_PREFIX}/invitation/accept/${TOKEN_QUERY_PARAM_KEY}`;
export const PATIENT_LINK_INVITATION_QUERY_KEY =
	'PATIENT_LINK_INVITATION_QUERY_KEY';

export const CREATE_INVITED_PATIENT_ACCOUNT_API = `${API_URL_PREFIX}/workers/doctor`;
