import './styles.css';

import { FormControl, RadioGroup as MuiRadioGroup } from '@material-ui/core';
import { useTranslations } from 'components/utilities';
import { arrayOf, bool, func, number, oneOfType, string } from 'prop-types';
import React from 'react';
import { CSSTransition } from 'react-transition-group';

import { RadioButton } from '../../atoms';
import { LoaderSpinner } from '../Loader';
import { useStyles } from './styles';
import useRadioGroup from './useRadioGroup';

const RadioGroup = ({
	initialValue,
	translationBase = '',
	minValueWidth = 100,
	onChange,
	labelConverter,
	isLoading = false,
	disabled = false,
	values,
	...rest
}) => {
	const { t } = useTranslations();
	const classes = useStyles();
	const { value, onRadioChange } = useRadioGroup({
		initialValue,
		onChange
	});

	const getLabel = (v) => {
		const label = t(`${translationBase}.${v}`) || v;
		return labelConverter ? labelConverter(label) : label;
	};

	return (
		<FormControl>
			<MuiRadioGroup
				defaultValue={initialValue}
				value={value}
				onChange={onRadioChange}
				{...rest}
			>
				{values.map((v) => (
					<RadioButton
						key={v}
						value={v}
						label={getLabel(v)}
						minValueWidth={minValueWidth}
						disabled={disabled}
					/>
				))}
				<CSSTransition
					in={isLoading}
					timeout={150}
					mountOnEnter
					unmountOnExit
					classNames="loader-spinner"
				>
					<LoaderSpinner
						classNameRoot={classes.loader}
						size={20}
						rootSize="full"
					/>
				</CSSTransition>
			</MuiRadioGroup>
		</FormControl>
	);
};

RadioGroup.propTypes = {
	translationBase: string,
	initialValue: oneOfType([string, number]),
	minValueWidth: number,
	disabled: bool,
	onChange: func,
	labelConverter: func,
	isLoading: bool,
	values: arrayOf(oneOfType([string, number])).isRequired
};

export default RadioGroup;
