import {
	disableMultipleNumberConnectors,
	lettersAndSpecialCharacters,
	phoneInputRegex
} from 'helpers';

import {
	CountryAutocompleteAdapter,
	PhoneFieldAdapter,
	RadioFieldAdapter,
	TextFieldAdapter
} from '../../Inputs';

export const HIDDEN_FIELD_MODE = 'hidden';

export const HELPERS_FIELD_NAMES = {
	PHONE_PREFIX: 'phone_prefix',
	COUNTRY_NAME: 'name'
};

export const basicInformationFields = (fieldNames) => [
	{
		component: TextFieldAdapter,
		type: 'text',
		label: `patients.form.${fieldNames.EMAIL?.toLowerCase()}`,
		name: fieldNames.EMAIL,
		grid: { lg: 12, xs: 12 },
		required: true,
		disabled: false
	},
	{
		component: TextFieldAdapter,
		type: 'text',
		label: `patients.form.${fieldNames.FIRST_NAME?.toLowerCase()}`,
		name: fieldNames.FIRST_NAME,
		regex: lettersAndSpecialCharacters,
		grid: { lg: 12, xs: 12 },
		required: true,
		disabled: false
	},
	{
		component: TextFieldAdapter,
		type: 'text',
		label: `patients.form.${fieldNames.LAST_NAME?.toLowerCase()}`,
		name: fieldNames.LAST_NAME,
		regex: lettersAndSpecialCharacters,
		grid: { lg: 12, xs: 12 },
		required: true,
		disabled: false
	},
	{
		component: RadioFieldAdapter,
		type: 'radio',
		label: `patients.form.${fieldNames.GENDER?.toLowerCase()}`,
		name: fieldNames.GENDER,
		grid: { lg: 12, xs: 12 },
		required: true,
		disabled: false
	},
	{
		type: 'date',
		label: `patients.form.${fieldNames.DATE?.toLowerCase()}`,
		name: fieldNames.DATE,
		grid: { lg: 4, md: 4, sm: 12, xs: 12 },
		required: true
	},
	{
		component: PhoneFieldAdapter,
		type: 'select',
		label: `patients.form.${fieldNames.PREFIX?.toLowerCase()}`,
		name: fieldNames.PREFIX,
		grid: { lg: 4, md: 4, sm: 6, xs: 12 },
		required: false,
		disabled: false
	},
	{
		component: TextFieldAdapter,
		type: 'text',
		label: `patients.form.${fieldNames.PHONE_NUMBER?.toLowerCase()}`,
		name: fieldNames.PHONE_NUMBER,
		regex: phoneInputRegex,
		inputOnChange: disableMultipleNumberConnectors,
		grid: { lg: 4, md: 4, sm: 6, xs: 12 },
		required: false,
		disabled: false
	},
	{
		component: CountryAutocompleteAdapter,
		type: 'select',
		label: `patients.form.${fieldNames.COUNTRY_CODE?.toLowerCase()}`,
		name: fieldNames.COUNTRY_CODE,
		grid: { lg: 6, md: 6, sm: 12, xs: 12 },
		required: false,
		disabled: false
	},
	{
		component: TextFieldAdapter,
		type: 'text',
		label: `patients.form.${fieldNames.CITY?.toLowerCase()}`,
		name: fieldNames.CITY,
		grid: { lg: 6, md: 6, sm: 12, xs: 12 },
		required: false,
		disabled: false
	},
	{
		component: TextFieldAdapter,
		type: 'text',
		label: `patients.form.${fieldNames.ID_NUMBER.replace(/([A-Z])/g, '_$1')
			.trim()
			.toLowerCase()}`,
		name: fieldNames.ID_NUMBER,
		grid: { lg: 12, xs: 12 },
		required: false,
		disabled: false
	}
];

export const isFieldQueryEnabled = (fields, fieldName) => {
	const fieldMode = fields.find((f) => f.name === fieldName)?.mode;
	if (!fieldMode) {
		return true;
	}
	return fieldMode !== HIDDEN_FIELD_MODE;
};

// hide unnecessary fields
export const isFieldHidden = (mode) =>
	mode === HIDDEN_FIELD_MODE ? { display: 'none' } : {};

export const getBasicFields = ({
	isAvailable,
	isEmailAvailable,
	isEmailEditable,
	fieldNames,
	fieldsSettings,
	isIDDisabled
}) => {
	const fields = basicInformationFields(fieldNames).map((item) => {
		const { name, type } = item;
		const fieldSetting =
			fieldsSettings?.find((field) => field?.name === name) || {};

		const newItem = {
			...item,
			...setHiddenFields({
				isIDDisabled,
				name,
				type,
				isAvailable,
				isEmailAvailable,
				isEmailEditable,
				fieldNames
			}),
			...fieldSetting
		};

		return newItem;
	});

	return fields;
};

const setHiddenFields = ({
	name,
	type,
	fieldNames,
	isAvailable,
	isEmailAvailable,
	isEmailEditable,
	isIDDisabled
}) => {
	switch (name) {
		case fieldNames.EMAIL:
			if (isEmailEditable) {
				return { mode: type, required: false, disabled: false };
			}
			return { ...getParameters(isEmailAvailable, type), disabled: true };

		case fieldNames.COUNTRY_CODE:
		case fieldNames.CITY:
			return getParameters(isAvailable, type);

		case fieldNames.ID_NUMBER:
			if (!isIDDisabled) {
				break;
			} else return getParameters(isAvailable, type);

		default:
			break;
	}
};

const getParameters = (isAvailable, type) => ({
	mode: isAvailable ? type : HIDDEN_FIELD_MODE,
	disabled: !isAvailable
});
