import { useInterval } from 'components/utilities';
import { IDLE } from 'helpers';
import { useDoctorResourcesMutation, useFetchSettings } from 'queries';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchAvailableLocales,
	fetchLanguages,
	refreshAuthToken,
	setUserProfile
} from 'store/actions';
import { Settings } from 'utilities';

import { convertExpirationDateToTimeout } from './helpers';

const useDashboardTemplate = () => {
	const dispatch = useDispatch();

	const isLngLoaded = useSelector((state) => state.language.isLoaded);

	const { mutateAsync, status } = useDoctorResourcesMutation({
		onSuccess: ({ data }) => dispatch(setUserProfile(data))
	});

	const handleFetchSettingsSuccess = (data) => {
		const settings = new Settings(data);
		Promise.all([
			mutateAsync(),
			fetchAvailableLocales()(dispatch),
			fetchLanguages({ lng: settings.getPanelLanguage() })(dispatch)
		]);
	};
	const { status: settingsStatus } = useFetchSettings({
		onSuccess: handleFetchSettingsSuccess
	});

	// Refresh auth token
	useInterval(() => {
		refreshAuthToken()(dispatch);
	}, convertExpirationDateToTimeout(dispatch));

	const isSuccess =
		isLngLoaded &&
		IDLE.SUCCESS === status &&
		IDLE.SUCCESS === settingsStatus;

	return { isSuccess, isLngLoaded };
};

export default useDashboardTemplate;
