import { combineReducers } from 'redux';
import * as types from 'store/types';

import auth from './auth';
import geoLocation from './geo_location';
import language from './language';
import layout from './layout';
import location from './location';
import metrics from './metrics';
import settings from './settings';

const appReducer = combineReducers({
	auth,
	language,
	layout,
	geoLocation,
	metrics,
	settings,
	location
});

const rootReducer = (state, action) => {
	if (action.type === types.AUTH_SIGN_OUT_SUCCESS) {
		//eslint-disable-next-line
		state = undefined;
	}

	return appReducer(state, action);
};

export default rootReducer;
