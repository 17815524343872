import { addDays } from '@fullcalendar/react';
import { format, getYear, subMonths } from 'date-fns';

import { DATE_FORMAT } from '../../variables';
import {
	CHART_MEASUREMENT_PARAMETER_OPTION_NAMES,
	SPIROMETRY_EXAMINATION_VALUE_KEYS
} from './parameters';

const {
	best: { key: bestKey },
	PBPercentage: { key: PBPercentageKey },
	predicted: { key: predictedKey },
	bestPercentile: { key: bestPercentileKey }
} = SPIROMETRY_EXAMINATION_VALUE_KEYS;

export const EXAMINATION_CRITERIA = {
	ATS2019: 'ATS2019',
	ATS2005: 'ATS2005'
} as const;

// Date format
const { CHART_RANGE: CHART_RANGE_DATE_FORMAT } = DATE_FORMAT;

export const formatExtremeDate = (value) =>
	format(new Date(value), CHART_RANGE_DATE_FORMAT);

const formatDate = ({ date, lastDate = false }) => {
	const formatRange = lastDate ? date : subMonths(addDays(date, 1), 1);
	// Get first or last date of the month
	// Format to yyyy-mm-dd
	return formatExtremeDate(formatRange);
};

export const formatRangeDate = (date) => {
	// Get the first and the last day of the month to set the chart's extremens and send request to database to get results for the date range
	const startRangeDate = formatDate({ date });
	const lastRangeDay = formatDate({ date, lastDate: true });

	const year = getYear(date);

	return {
		startRangeDate,
		lastRangeDay,
		year
	};
};

/**
 * Get chart parameter label to display
 *
 * @param {string} name chart parameter name
 * @param {string} type chart parameter type
 * @returns {string|any} chart parameter label to display
 */
type GetParameterTypeLabelArgs = {
	type?: string | null;
	chartName: ExaminationChartNames | null;
};
export const getParameterTypeLabel = ({
	type = null,
	chartName: parameterName = null
}: GetParameterTypeLabelArgs) => {
	if (type) {
		return CHART_MEASUREMENT_PARAMETER_OPTION_NAMES[type]?.label;
	}

	if (parameterName) {
		return Object.values(CHART_MEASUREMENT_PARAMETER_OPTION_NAMES).find(
			({ name }) => name?.toLowerCase() === parameterName.toLowerCase()
		)?.label;
	}
};

export const getExaminationsValues = (
	best,
	PBPercentile,
	predicted,
	percentile = null
) => ({
	[bestKey]: best,
	[PBPercentageKey]: PBPercentile,
	[predictedKey]: predicted,
	[bestPercentileKey]: percentile
});
