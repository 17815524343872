// Import context
import { useMessengerContext } from 'components/context';
import _ from 'lodash';
import React from 'react';

// Import heleprs
import { getFirstLastMessage } from './helpers';

const Messages = () => {
	const { auth, conversationId, conversationHistory, ...events } =
		useMessengerContext();

	const userId = auth?.user_id;

	const { loadConversationHistory, markConversationAsRead } = events;
	const { messages, nextPage } = conversationHistory;
	const { firstMessage, lastMessage } = getFirstLastMessage(messages);

	// ---------- Refs ----------
	const rootRef = React.useRef();
	const rootLoadMoreRef = React.useRef(0);

	const delayedLoadMore = React.useRef(
		_.debounce((props) => handleLoadMoreMessage(props), 50)
	).current;

	// ---------- Effects ----------
	const serializeLastMessage = JSON.stringify(lastMessage);

	// Scroll to bottom when is new id or new message
	React.useLayoutEffect(() => {
		rootLoadMoreRef.current = 0;
	}, [conversationId]);

	React.useLayoutEffect(() => {
		// Check if there is trigger a load more function
		// First time is initial
		// Second time is first load more effect
		if (rootLoadMoreRef.current < 2) {
			handleScrollHeight();
		}
	});

	// Check id the conversation message is equal to conversation id, then emit mark conversation as read in websocket
	const isCurrentConversation =
		conversationId === lastMessage?.conversation_id;
	const isUserLastMessage = userId === lastMessage?.author_id;

	React.useEffect(() => {
		if (!isUserLastMessage && isCurrentConversation) {
			// Emit mark conversation as read
			markConversationAsRead({ conversation_id: conversationId });
		}
		//eslint-disable-next-line
	}, [serializeLastMessage, isCurrentConversation, isUserLastMessage]);

	// ---------- Handlers ----------
	const handleScrollHeight = () => {
		if (rootRef.current && rootRef.current.scrollHeight) {
			rootRef.current.scrollTop = rootRef.current.scrollHeight;
		}
	};

	const handleLoadMoreMessage = (props) => {
		loadConversationHistory(props);
		rootLoadMoreRef.current += 1;
	};

	const handleLoadMore = () => {
		// Check if there is a conversation history
		// Load more message based on oldest message's id
		// First message is the oldest message in conversation
		// Last message is the newest message in conversation
		const startId = firstMessage?.id || null;
		delayedLoadMore({ id: conversationId, startId });
	};

	const hasMoreMessages = conversationId ? nextPage : false;

	return {
		rootRef,
		messages,
		nextPage,
		userId,
		conversationId,
		isCurrentConversation,
		hasMoreMessages,
		loadMore: handleLoadMore
	};
};

export default Messages;
