import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	paperRoot: {
		maxWidth: ({ maxWidth }) => maxWidth,
		backgroundColor: theme.palette.background.form
	},
	backdrop: {
		backdropFilter: 'blur(3px)'
	},
	button: {
		width: '100%',
		textTransform: 'none',
		marginTop: theme.spacing(2),
		height: 48
	},
	backIconWrapper: {
		position: 'absolute',
		top: 38,
		left: 66,
		transform: 'translate(-125%, -25%)'
	},
	closeIconWrapper: {
		position: 'absolute',
		top: 38,
		right: 66,
		transform: 'translate(125%, -25%)'
	},
	typography: {
		textTransform: 'none'
	}
}));
