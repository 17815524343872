import React from 'react';
import { CSSTransition } from 'react-transition-group';

import { TIMINGS, useStyles } from './styles';
import { ComponentFadeInOutProps } from './types';
import useComponentFadeInOut from './useComponentFadeInOut';

const ComponentFadeInOut = ({
	children,
	maxHeight,
	onExited
}: ComponentFadeInOutProps) => {
	const classes = useStyles({ maxHeight });

	const {
		animate,
		onEnteredAction,
		setAnimate,
		triggerAnimation,
		setOnEnteredAction
	} = useComponentFadeInOut();

	return (
		<CSSTransition
			in={animate}
			timeout={{
				enter: TIMINGS.ENTER,
				exit: TIMINGS.EXIT
			}}
			classNames={{
				enter: classes.enter,
				enterActive: classes.enterActive,
				exit: classes.exit,
				exitActive: classes.exitActive
			}}
			onEntered={() => {
				setAnimate(false);
				onEnteredAction.action(onEnteredAction?.props);
			}}
			onExited={(props) => {
				onExited && onExited(props);
			}}
		>
			<div style={{ overflowX: 'hidden' }}>
				{children({
					setOnEnteredAction,
					triggerAnimation
				})}
			</div>
		</CSSTransition>
	);
};

export default ComponentFadeInOut;
