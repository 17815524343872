import * as Sentry from '@sentry/browser';
import axios, { AxiosRequestConfig } from 'axios';
import { getHeaders } from 'services/headers';
import { fromZodError } from 'zod-validation-error';

import { checkErrorStatus } from './helpers';
import { Options } from './servicesV2';

const myAxiosFactory = () => (options) => {
	const headers = getHeaders();
	return axios.create({
		// eslint-disable-next-line
		baseURL: `${process.env.REACT_APP_API_URL}`,
		...options,
		headers: {
			...headers,
			...options?.headers
		}
	});
};

const myAxios = myAxiosFactory();

const createMyAxios = <TResponse>(options?: AxiosRequestConfig<TResponse>) => {
	const myAxios = axios.create({
		...options,
		baseURL: `${process.env.REACT_APP_API_URL}`,
		//eslint-disable-next-line
		//@ts-ignore
		headers: { ...getHeaders(), ...options?.headers }
	});

	myAxios.interceptors?.response?.use(
		function (response) {
			return response;
		},
		function (error) {
			return checkErrorStatus(error);
		}
	);

	return myAxios;
};

export type ApiPayload<Request> = Request & AxiosRequestConfig<Request>;
type Props<Request, Response> = {
	payload?: ApiPayload<Request>;
	options: Options<Request, Response>;
	url: string;
};
export const api =
	<Request, Response>(
		method: HTTPMethod
	): ((args: Props<Request, Response>) => Promise<Response>) =>
	async ({
		payload,
		options: { requestSchema, responseSchema, ...options },
		url
	}) => {
		function zodErrorNotifier(res, type: 'request' | 'response') {
			Sentry.captureException(res.error, {
				tags: {
					target: url
				}
			});
			// eslint-disable-next-line
			console.error(
				fromZodError(res.error, {
					issueSeparator: ';\n',
					prefix: `\n${type} target: ${url}\nValidation error`
				})
			);
		}

		requestSchema.safeParseAsync(payload).then((result) => {
			if (!result.success) {
				zodErrorNotifier(result, 'request');
			}
		});

		const myAxios = createMyAxios<Response>(options);
		const response = await myAxios[method](url, payload);

		responseSchema.safeParseAsync(response.data).then((result) => {
			if (!result.success) {
				zodErrorNotifier(result, 'response');
			}
		});

		return response.data as Response;
	};

export default myAxios;
