import { useTranslationsReturnObj } from 'components/utilities/hooks/translations/types';
import { CheckTypeModel } from 'models/Report/CalibrationCheck';
import { MyObject } from 'utilities';
import {
	ColumnDataImpl,
	ColumnValues
} from 'utilities/Examinations/ExaminationDetails/common';

import { TypeItemModelWithRefValue } from './columns';
import { CHECK_PARAMETERS } from './parameters';

type CheckTypeContainsRefValue = {
	slow: TypeItemModelWithRefValue;
	medium: TypeItemModelWithRefValue;
	fast: TypeItemModelWithRefValue;
};

export const convertToColumnsData = (
	checkType: CheckTypeModel,
	tNoParser: useTranslationsReturnObj['tNoParser']
): ColumnValues<TypeItemModelWithRefValue>[] => {
	if (!checkType) {
		return [];
	}

	const checkTypeWithRefValues: CheckTypeContainsRefValue =
		addRefValue(checkType);

	const checkTypeColumnData = new ColumnDataImpl<
		CheckTypeContainsRefValue,
		TypeItemModelWithRefValue
	>(CHECK_PARAMETERS, checkTypeWithRefValues, tNoParser);
	checkTypeColumnData.createColumnData();
	return checkTypeColumnData.columnData;
};

function addRefValue(checkType: CheckTypeModel): CheckTypeContainsRefValue {
	return MyObject.entries<CheckTypeModel>(checkType).reduce(
		(acc, [key, typeValues]) => {
			acc[key] = {
				...typeValues,
				refValue: `${typeValues.reference.min} - ${typeValues.reference.max}`
			};
			return acc;
		},
		{} as CheckTypeContainsRefValue
	);
}
