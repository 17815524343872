import { Box } from '@material-ui/core';
import React from 'react';

import { useParametersContext } from '../../context';
import { Parameter } from './Parameter';
import { useStyles } from './styles';

const Parameters = () => {
	const classes = useStyles();

	const { activeParameterGroup } = useParametersContext();

	return (
		<Box className={classes.root} data-testid="Parameters-root">
			{activeParameterGroup.groupParameters.map((parameter) => (
				<Parameter key={parameter.name} {...parameter} />
			))}
		</Box>
	);
};

export default Parameters;
