import { useTranslations } from 'components/utilities';
import { useFormContext } from 'react-hook-form';

import { isError } from './helpers';

interface UseCommonConfigProps {
	name: string;
	helperText?: string;
}
export const useCommonConfig = ({ helperText, name }: UseCommonConfigProps) => {
	const { t } = useTranslations();
	const { trigger } = useFormContext();

	function createHelperTextAsError(fieldState) {
		return (
			(isError(fieldState) ? t(fieldState?.error?.message) : '') ||
			helperText
		);
	}

	const handleBlur = async () => {
		await trigger(name);
	};

	return { onBlur: handleBlur, createHelperTextAsError };
};
