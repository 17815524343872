import { EXAMINATIONS_TYPES } from 'helpers';
import { ExaminationsListConvertedItemModel } from 'models/Examinations/ExaminationsList';
import { LocaleDate } from 'utilities/LocaleDate';

export const getCreationDateFromExaminationListItem = (
	examinationListItem: ExaminationsListConvertedItemModel
) => {
	const isMedicalNoteItem =
		examinationListItem.type === EXAMINATIONS_TYPES.MEDICAL_NOTE.type;
	const isCopdItem =
		examinationListItem.type === EXAMINATIONS_TYPES.COPD_TEST.type;
	if (isMedicalNoteItem || isCopdItem) {
		const date = new LocaleDate(examinationListItem.dateCreated).getDate();
		date.setHours(0, 0, 0, 0);
		return date;
	}
	const date = new LocaleDate(examinationListItem.createDateTime).getDate();
	date.setHours(0, 0, 0, 0);
	return date;
};
