import { useForm, useFormState } from 'react-final-form';

import { KeyboardDatePickerFieldAdapter } from '../../KeyboardDatePickerFieldAdapter';

const useKeyboardDatePickerField = ({
	onChange,
	name,
	disablePast,
	disableFuture
}) => {
	const { change } = useForm();
	const { submitting } = useFormState();

	const handleChangeDate = async (value) => {
		change(name, value);
		onChange?.(value);
	};

	const validateField = (value) => {
		const dateValidator = new KeyboardDatePickerFieldAdapter.Validator(
			disablePast,
			disableFuture,
			value
		);

		return dateValidator.getErrorMessage();
	};

	return { validateField, onChangeDate: handleChangeDate, submitting };
};

export default useKeyboardDatePickerField;
