import React from 'react';

import { OBSTRUCTION_PERCENTILE_FEV1_FVC } from './helpers';

export interface ObstructionLevelProps {
	fev1PredictedPercent: number | null;
	percentileFev1Fvc: number | null;
}
export const useObstructionLevel = ({
	fev1PredictedPercent,
	percentileFev1Fvc
}: ObstructionLevelProps) => {
	const getObstructionLevel = React.useCallback(() => {
		let obstruction = 'report.obstruction.no_obstruction';
		if (
			percentileFev1Fvc &&
			percentileFev1Fvc >= OBSTRUCTION_PERCENTILE_FEV1_FVC
		) {
			return { level: '', obstruction };
		}
		if (fev1PredictedPercent === null) {
			return { level: '', obstruction };
		}
		obstruction = 'report.obstruction.detected';
		if (fev1PredictedPercent >= 70)
			return { level: 'report.obstruction.mild', obstruction };
		if (fev1PredictedPercent >= 60 && fev1PredictedPercent <= 69)
			return { level: 'report.obstruction.moderate', obstruction };
		if (fev1PredictedPercent >= 50 && fev1PredictedPercent < 60)
			return {
				level: 'report.obstruction.moderately_severe',
				obstruction
			};
		if (fev1PredictedPercent >= 35 && fev1PredictedPercent < 50)
			return { level: 'report.obstruction.severe', obstruction };
		if (fev1PredictedPercent < 35)
			return { level: 'report.obstruction.very_severe', obstruction };
	}, [percentileFev1Fvc]);

	return {
		obstructionLabels: getObstructionLevel(),
		fev1Predicted: fev1PredictedPercent?.toFixed(0),
		percentileFev1Fvc: percentileFev1Fvc?.toFixed(0)
	};
};
