export const DATES_FIELDS_NAMES = {
	START_DATE: 'start_date',
	START_TIME: 'start_time'
};

export const VALUES_TO_OMIT = [
	...Object.values(DATES_FIELDS_NAMES),
	'patient_name',
	'duration'
];

export const CALENDAR_EXTENDED_PROPS = [
	'alert_before',
	'patient_id',
	'notes',
	'appointment_ends',
	'appointment_starts'
];
