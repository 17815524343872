import { Grid } from '@material-ui/core';
import { useTranslations } from 'components/utilities';
import { anyNumberWithDecimalRegex } from 'helpers/validation';
import { func, number } from 'prop-types';
import React from 'react';
import { Field, useFormState } from 'react-final-form';

import { TextFieldAdapter } from '../../../../../../Inputs';

const SmokerFields = ({ maxYearsAsSmoker }) => {
	const { t } = useTranslations();

	const { submitting } = useFormState();

	return (
		<>
			<Grid item lg={6} md={6} sm={6} xs={12}>
				<Field
					fullWidth
					component={TextFieldAdapter}
					regex={anyNumberWithDecimalRegex(Infinity)}
					type="number"
					label={t('patients.form.patient_smoker_cigarettes')}
					name={`patientSmokerCigarettes`}
					margin="normal"
					variant="outlined"
					autoComplete="false"
					inputProps={{ min: 1, max: 5, step: 0.1 }}
					disabled={submitting}
					required
				/>
			</Grid>
			<Grid item lg={6} md={6} sm={6} xs={12}>
				<Field
					fullWidth
					component={TextFieldAdapter}
					regex={anyNumberWithDecimalRegex(Infinity)}
					type="number"
					label={t('patients.form.patient_smoker_years')}
					name={`patientSmokerYears`}
					margin="normal"
					variant="outlined"
					autoComplete="false"
					inputProps={{ min: 1, max: maxYearsAsSmoker }}
					disabled={submitting}
					required
				/>
			</Grid>
		</>
	);
};

SmokerFields.propTypes = {
	maxYearsAsSmoker: number.isRequired,
	onSmokerSinceKeyboardChange: func.isRequired
};

export default SmokerFields;
