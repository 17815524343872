import { string } from 'prop-types';
import React from 'react';

const CountryAutocompleteOption = ({ name }) => {
	return <>{name}</>;
};

CountryAutocompleteOption.propTypes = {
	name: string.isRequired
};

export default CountryAutocompleteOption;
