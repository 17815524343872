import { ContainerWithBorder } from 'components/elements/atoms/ExaminationsV2';
import {
	Correctness,
	Repeatability
} from 'components/elements/organisms/ExaminationsV2';
import { CHART_MEASUREMENT_PARAMETER_OPTION_NAMES as PARAMETERS } from 'helpers/charts';
import { ForcedSpirometryReportModel } from 'models/Report/Reports';
import React from 'react';

import { ZScore } from '../../components/organisms';
import { BestResults } from './BestResults';
import { useStyles } from './styles';
import { Title } from './Title';

export interface ExaminationDetailsProps {
	isChangePercentage: boolean;
	examination: ForcedSpirometryReportModel | undefined;
	title: string;
	color: string;
}

export const ExaminationDetails = ({
	examination,
	title,
	color,
	isChangePercentage
}: ExaminationDetailsProps) => {
	const classes = useStyles();
	return (
		<ContainerWithBorder key={title}>
			<Title
				title={title}
				createDateTime={examination?.examinationCreateDateTime}
			/>
			<Correctness
				color={color}
				examination={examination}
				upperStatusFieldName={'fvcStatus'}
				bottomStatusFieldName={'fev1Status'}
				parametersLabels={[PARAMETERS.fvc.label, PARAMETERS.fev1.label]}
			/>
			<Repeatability
				className={classes.repeatabilityContainer}
				examination={examination}
			/>
			<BestResults
				examination={examination}
				isChangePercentage={isChangePercentage}
			/>
			<ZScore
				className={classes.zScore}
				examination={examination}
				disableBorderContainer
			/>
		</ContainerWithBorder>
	);
};
