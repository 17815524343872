import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		position: 'relative',
		borderBottom: `1px solid ${theme.palette.border.light}`,
		paddingTop: 25,
		paddingBottom: 25
	},
	title: {
		flexGrow: 1
	},
	iconButton: {
		height: 30,
		width: 30,
		transform: 'translate(100%, 0%)'
	}
}));
