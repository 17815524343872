import {
	useExaminationsV2Context,
	useExaminationsV2RefsContext
} from 'components/context/ExaminationsV2';
import { GET_EXAMINATION_REPORT_DATA_QUERY_KEY } from 'helpers';
import { ExaminationsListConvertedItemModel } from 'models/Examinations/ExaminationsList';
import { ExaminationsListItemModel } from 'models/Examinations/ExaminationsList';
import {
	BronchodilatorReportModel,
	ForcedSpirometryReportModel,
	PeakFlowReportModel,
	PulseOximetryReportModel,
	SlowVitalCapacitySVCReportModel
} from 'models/Report/Reports';
import {
	ExaminationsListServicesV2,
	useExaminationsListQueryKey
} from 'queries';
import { useGeneratePDFReportV2 } from 'queries/Examinations/ReportsV2';
import React from 'react';
import { useQueryClient } from 'react-query';
import { LocaleDate, useParams } from 'utilities';

import {
	useExaminationDataContext,
	useReportInterpretationNoteContext
} from '../../context';

const useGeneratePDFReportActionButton = () => {
	const { patientId } = useParams();
	const queryClient = useQueryClient();
	const {
		reportInterpretationNoteInputRef,
		focusInterpretationNoteInput,
		isInterpretationNoteActive,
		isValid
	} = useReportInterpretationNoteContext();
	const { store: activeExamination } = useExaminationsV2Context(
		(s) => s.activeExamination
	);

	const { store: examinationsListRef } = useExaminationsV2RefsContext(
		(s) => s.examinationsListRef
	);

	const { setExaminationData } = useExaminationDataContext();
	const examinationsListQueryKey = useExaminationsListQueryKey();

	const {
		mutateAsync: generatePDFReportV2,
		isLoading: isPDFReportV2Generating,
		apiError: generatePdfReportV2ApiError
	} = useGeneratePDFReportV2({
		onSuccess: async () => {
			const examinationData = await invalidateExaminationData();

			const examinationsList =
				queryClient.getQueryData<ExaminationsListConvertedItemModel[]>(
					examinationsListQueryKey
				) || [];
			const examinationIndexInList =
				ExaminationsListServicesV2.getExaminationIndexFromList({
					examinationsList,
					...activeExamination
				});
			if (examinationIndexInList !== -1) {
				updateExaminationPdfReportKeyInExaminationsList({
					examinationData,
					examinationsList,
					examinationIndexInList
				});
			}
			queryClient.invalidateQueries(examinationsListQueryKey);
		}
	});

	const handleButtonClick = React.useCallback(() => {
		if (!isInterpretationNoteActive) {
			focusInterpretationNoteInput();
			return;
		}

		generatePDFReportV2({
			patientId,
			// disable @typescript-eslint/no-non-null-assertion
			// examination id and type is 100% defined inside examination details
			/* eslint-disable */
			examinationId: activeExamination.id!.toString(),
			examinationType: activeExamination.type!,
			reportNote: reportInterpretationNoteInputRef.current?.value || '',
			reportDateTime: new LocaleDate().getISOString()
			/*eslint-enable*/
		});
	}, [isInterpretationNoteActive]);

	async function invalidateExaminationData() {
		// disable @typescript-eslint/no-non-null-assertion
		// examination id and type is 100% defined inside examination details
		const examinationDataQueryKey = GET_EXAMINATION_REPORT_DATA_QUERY_KEY(
			/* eslint-disable */
			activeExamination.id!.toString(),
			activeExamination.type!
			/*eslint-enable*/
		);
		await queryClient.invalidateQueries(examinationDataQueryKey);
		const examinationData = queryClient.getQueryData<
			| ForcedSpirometryReportModel
			| SlowVitalCapacitySVCReportModel
			| PulseOximetryReportModel
			| PeakFlowReportModel
			| BronchodilatorReportModel
			| undefined
		>(examinationDataQueryKey);
		setExaminationData(examinationData);

		return examinationData;
	}

	function updateExaminationPdfReportKeyInExaminationsList({
		examinationData,
		examinationsList,
		examinationIndexInList
	}) {
		const latestPdfReport = ExaminationsListServicesV2.findLatestPdfReport({
			reports: examinationData?.reports
		});
		const newExaminationWithPdfReport =
			ExaminationsListServicesV2.setPdfReportKey({
				examination: examinationsList[
					examinationIndexInList
				] as ExaminationsListItemModel,
				pdfReportKey: latestPdfReport?.key
			});

		examinationsList[examinationIndexInList] = newExaminationWithPdfReport;
		queryClient.setQueryData(examinationsListQueryKey, examinationsList);
		// examinationsListRef.current?.forceUpdateGrid();
		examinationsListRef.current?.forceUpdate();
	}

	const isDisabled = !isValid && isInterpretationNoteActive;

	return {
		onButtonClick: handleButtonClick,
		focusInterpretationNoteInput,
		isPDFReportV2Generating,
		generatePdfReportV2ApiError,
		isDisabled
	};
};

export default useGeneratePDFReportActionButton;
