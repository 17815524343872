import { makeStyles } from '@material-ui/styles';
import { TRANSITIONS } from 'helpers';
import theme from 'theme';

const BORDER_RADIUS = 10;
export const useStyles = makeStyles<
	typeof theme,
	{ activeExaminationHeight: number }
>(() => ({
	root: {
		padding: '10px 16px 10px 0',
		zIndex: 9999
	},
	examinationWrapper: ({ activeExaminationHeight }) => ({
		height: activeExaminationHeight ? activeExaminationHeight - 10 : 0,
		backgroundColor: 'white',
		zIndex: 1,
		boxShadow: activeExaminationHeight
				? '0px 0px 8px 1px rgba(74,79,224)'
				: 'none',
		borderTopRightRadius: BORDER_RADIUS,
		borderBottomRightRadius: BORDER_RADIUS,
		position: 'relative',
		transition: `height ${TRANSITIONS.DURATIONS.enteringScreen}ms ${TRANSITIONS.EASING.easeInOut}`,
		'&::before': {
			content: "''",
			position: 'absolute',
			top: 0,
			left: -10,
			width: 12,
			height: activeExaminationHeight - 10,
			backgroundColor: 'white'
		},
		'&::after': {
			content: "''",
			position: 'absolute',
			top: -20,
			left: -10,
			width: 9,
			height: activeExaminationHeight + 40,
			backgroundColor: 'white'
		}
	}),
	examinationRoot: {
		padding: ({ activeExaminationHeight }) =>
			activeExaminationHeight ? 15 : 0,
		border: 'none',
		height: '100%',
		background:
			'linear-gradient(90deg, rgba(255,255,255,1) 0%,  rgba(255,255,255,1) 30%, rgba(74,79,224,0.1) 100%)'
	},
	label: {
		fontSize: 18,
		lineHeight: '20px',
		color: 'black',
		paddingBottom: 10,
		paddingLeft: 16
	}
}));
