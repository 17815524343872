import { DAILY_CHECK_LIST_TYPES, formatDailyCheckListTypes } from './common';

const { DAILY_CHECK, ACTIVITY } = DAILY_CHECK_LIST_TYPES;

// Symptoms
export const showSymptomTooltip = ({ dailyCheck }) => {
	const dailyValue = dailyCheck?.value;
	if (dailyValue) {
		const count = dailyValue.count;
		return count <= 0 ? false : true;
	}

	return false;
};

export const showSymptomInfoTip = ({ dailyCheck }) => {
	const dailyValue = dailyCheck?.value;
	if (dailyValue) {
		const count = dailyValue.count;
		return count > 0 ? true : false;
	}

	return false;
};

// Daily check
const { PARTIALLY_CONTROLLED, UNCONTROLLED, EMERGENCY } = DAILY_CHECK;
export const showDailyInfoTip = ({ dailyCheck }) => {
	const dailyValue = formatDailyCheckListTypes(dailyCheck?.value?.value);
	switch (dailyValue) {
		case PARTIALLY_CONTROLLED:
		case UNCONTROLLED:
		case EMERGENCY:
			return true;

		default:
			return false;
	}
};

// Activities
const { NORMAL, INTERRUPTED, DIFFICULT } = ACTIVITY;
export const showActivitiesInfoTip = ({ dailyCheck }) => {
	const dailyValue = dailyCheck?.value?.name?.toLowerCase() ?? null;

	switch (dailyValue) {
		case NORMAL:
		case INTERRUPTED:
		case DIFFICULT:
			return true;

		default:
			return false;
	}
};
