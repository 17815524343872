import {
	getInitialXAxisRangeValues,
	getScaleLines,
	getXAxisValuesDifference,
	Z_SCORES_PICTOGRAMS
} from './helpers';

const useGenerator = ({ data }) => {
	// initial x-axis range values based on api Z-Scores data
	const initialXAxisRangeValues = getInitialXAxisRangeValues(data);
	const [minXAxisValue, maxXAxisValue] = initialXAxisRangeValues;

	// difference between min value and max value
	const scaleValuesQuantity = getXAxisValuesDifference(
		minXAxisValue,
		maxXAxisValue
	);

	// amount of non-negative values
	const xAxisNonNegativeValues = maxXAxisValue + 1;

	// amount of non-positive values
	const xAxisNonPositiveValues = scaleValuesQuantity - xAxisNonNegativeValues;

	// scale lines based on values quantity
	const scaleLines = getScaleLines(scaleValuesQuantity);

	return {
		zScorePictograms: Z_SCORES_PICTOGRAMS,
		initialXAxisRangeValues,
		minXAxisValue,
		maxXAxisValue,
		scaleValuesQuantity,
		xAxisNonNegativeValues,
		xAxisNonPositiveValues,
		scaleLines
	};
};

export default useGenerator;
