import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: 40
	},
	button: {
		textTransform: 'none',
		width: 156,
		height: 36,
		marginLeft: 20
	}
}));
