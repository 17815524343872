import { Grid } from '@material-ui/core';
import { arrayOf, shape, string } from 'prop-types';
import React from 'react';

import { useGlobalStyles } from '../../../helpers/form';
import Field from '../Field';

const Fields = ({ fieldsSet }) => {
	const classesGlobal = useGlobalStyles();

	return (
		<Grid
			container
			justifyContent="space-between"
			spacing={2}
			className={classesGlobal.wrapper}
		>
			{fieldsSet.map(({ label, name, type, options }) => (
				<Grid xs={12} item key={name}>
					<Field
						label={label}
						type={type}
						name={name}
						options={options}
					/>
				</Grid>
			))}
		</Grid>
	);
};

Fields.propTypes = {
	fieldsSet: arrayOf(
		shape({
			label: string.isRequired,
			type: string.isRequired,
			name: string.isRequired,
			options: arrayOf(
				shape({
					value: string.isRequired,
					label: string.isRequired
				})
			)
		})
	).isRequired
};

export default Fields;
