import { Box, Button, Typography as MuiTypography } from '@material-ui/core';
import { RouterLink } from 'components/elements';
import { PATIENTS_DETAILS_PATHS } from 'components/routes/paths';
import { useTranslations } from 'components/utilities';
import { replacePatientIdQueryParam } from 'queries/common';
import React from 'react';
import { useParams } from 'react-router-dom';

import { useStyles } from './styles';

const {
	DRUGS: { ADD }
} = PATIENTS_DETAILS_PATHS;

const Toolbar = () => {
	const classes = useStyles();
	const { t } = useTranslations();
	const { patientId } = useParams();

	return (
		<Box className={classes.root}>
			<MuiTypography
				variant="h4"
				component="p"
				className={classes.typography}
			>
				{t('patients.drugs.table.toolbar.prescribed_drugs')}
			</MuiTypography>
			<Button
				variant="contained"
				color="primary"
				size="small"
				className={classes.button}
				component={RouterLink}
				to={replacePatientIdQueryParam(ADD, patientId)}
			>
				{`+ ${t('patients.drugs.table.toolbar.add_new')}`}
			</Button>
		</Box>
	);
};

export default Toolbar;
