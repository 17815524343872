import { Alert, Placeholder } from 'components/elements';
import { format } from 'date-fns';
import { DATE_FORMAT, EXAMINATIONS_TYPES } from 'helpers';
import { ExaminationsListConvertedItemModel } from 'models/Examinations/ExaminationsList';
import { ExaminationsListServicesV2 } from 'queries';
import React from 'react';
import { AutoSizer } from 'react-virtualized';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import { v4 as uuidV4 } from 'uuid';

import { ExaminationListItem } from './ExaminationListItem';
import { useList } from './hooks/useList';
import { useStyles } from './styles';

export const List = () => {
	const {
		examinationsListRef,
		activeExamination,
		examinationsListQueryData,
		examinationsListData,
		apiError,
		isSomeExaminationInDateRange,
		examinationsDateRange,
		isListItemActive
	} = useList();

	const classes = useStyles();

	React.useEffect(() => {
		examinationsListRef.current?.resetAfterIndex(0);
	}, [activeExamination.data?.height === undefined]);

	const rowRenderer = ({
		index,
		...props
	}: ListChildComponentProps<ExaminationsListConvertedItemModel>) => {
		if (!examinationsListData?.length) {
			return null;
		}
		const rowData = examinationsListData[index];
		if (
			rowData.type === EXAMINATIONS_TYPES.MEDICAL_NOTE.type ||
			ExaminationsListServicesV2.isCopdPostExamination(rowData)
		) {
			return null;
		}

		return (
			<ExaminationListItem
				{...props}
				index={index}
				rowData={rowData}
				isActive={isListItemActive}
			/>
		);
	};

	const rowCount = React.useMemo(
		() => examinationsListData?.length || 0,
		[examinationsListData?.length]
	);

	return (
		<>
			{apiError ? (
				<Alert message={apiError} className={classes.alert} />
			) : (
				<Placeholder
					classes={{ root: classes.listContainer }}
					backgroundColor="transparent"
					active={
						!isSomeExaminationInDateRange &&
						!!examinationsListQueryData?.length &&
						examinationsListQueryData.length > 0
					}
					label="patients.examinations_v2.no_examinations_data_in_range"
					prefixMarker=""
					suffixMarker={`</br>${
						examinationsDateRange.startDate
							? format(
									examinationsDateRange.startDate,
									DATE_FORMAT.DEFAULT
							  )
							: ''
					}-${
						examinationsDateRange.endDate
							? format(
									examinationsDateRange.endDate,
									DATE_FORMAT.DEFAULT
							  )
							: ''
					}`}
				>
					{/* react-virtualized doesn't support react 17 type checking so disable it
							eslint-disable-next-line
							@ts-ignore */}
					<AutoSizer>
						{({ width, height }) => (
							// react-virtualized doesn't support react 17 type checking so disable it
							//eslint-disable-next-line
							//@ts-ignore
							<VariableSizeList
								overscanCount={2}
								ref={examinationsListRef}
								width={width}
								height={height}
								itemKey={() => uuidV4()}
								itemCount={rowCount}
								itemSize={(index) =>
									examinationsListData?.[index]?.height || 0
								}
							>
								{rowRenderer}
							</VariableSizeList>
						)}
					</AutoSizer>
				</Placeholder>
			)}
		</>
	);
};
