import { Box } from '@material-ui/core';
import { PictureAsPdfIcon } from 'assets/icons/commonMUIIcons';
import { Alert, ButtonProgress } from 'components/elements';
import { useTranslations } from 'components/utilities';
import React from 'react';

import { Label } from '../Typography';
import { useStyles } from './styles';
import useGeneratePDFReportActionButton from './useGeneratePDFReportActionButton';

export const GeneratePDFReportActionButton = () => {
	const { t } = useTranslations();
	const classes = useStyles();

	const {
		onButtonClick,
		focusInterpretationNoteInput,
		isPDFReportV2Generating,
		generatePdfReportV2ApiError,
		isDisabled
	} = useGeneratePDFReportActionButton();

	return (
		<Box className={classes.root}>
			<Alert
				className={classes.alert}
				message={generatePdfReportV2ApiError}
			/>
			<ButtonProgress
				isLoading={isPDFReportV2Generating}
				disabled={isDisabled}
				startIcon={<PictureAsPdfIcon />}
				className={classes.button}
				onClick={onButtonClick}
			>
				{t('patients.examinations_v2.details.generate_report_pdf')}
			</ButtonProgress>
			<Label
				onClick={focusInterpretationNoteInput}
				className={classes.label}
				label={t(
					'patients.examinations_v2.details.generate_report_pdf.description'
				)}
			/>
		</Box>
	);
};
