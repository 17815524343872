import { Box } from '@material-ui/core';
import { useExaminationsV2Context } from 'components/context/ExaminationsV2';
import { Divider } from 'components/elements';
import React from 'react';

import { DateRangeFilter } from './DateRangeFilter';
import { ExaminationTypeFilter } from './ExaminationTypeFilter';
import { useStyles } from './styles';

export const Filters = () => {
	const classes = useStyles();

	const { store: examinationsDateRange } = useExaminationsV2Context(
		(s) => s.examinationsDateRange
	);

	return (
		<Box className={classes.filtersRoot}>
			{examinationsDateRange.endDate &&
				examinationsDateRange.startDate && <DateRangeFilter />}
			<Divider />
			<ExaminationTypeFilter />
		</Box>
	);
};
