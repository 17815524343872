import { Grid } from '@material-ui/core';
// Import context
import { useScheduleCalendarContext } from 'components/context';
import { TextFieldAdapter } from 'components/elements';
import { useTranslations } from 'components/utilities';
import React from 'react';
import { Field } from 'react-final-form';
import { useFormState } from 'react-final-form';

import AlertField from './AlertField';
import DateFields from './DateFields';
import { PatientSelect } from './PatientSelect';

const Fields = () => {
	const { t } = useTranslations();
	const { submitting } = useFormState();
	const { isAppointmentReview } = useScheduleCalendarContext();

	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<PatientSelect />
			</Grid>

			<DateFields />

			<AlertField />

			<Grid item xs={12} style={{ flex: 1 }}>
				<Field
					fullWidth
					component={TextFieldAdapter}
					type="textarea"
					multiline
					minRows={5}
					maxRows={5}
					label={t('schedule.form.labels.note')}
					name="notes"
					variant="outlined"
					autoComplete="false"
					disabled={submitting || isAppointmentReview}
					margin="dense"
				/>
			</Grid>
		</Grid>
	);
};

export default Fields;
