import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	scores: {
		flex: 1,
		display: 'flex',
		gap: 6
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		gap: '10px'
	},
	grades: {
		display: 'flex',
		gap: '10px'
	},
	stackedParameters: {
		'& > :first-child': {
			paddingBottom: '15px'
		}
	}
}));
