import { useMutation } from 'components/utilities';
import { useParams } from 'react-router-dom';
import { FileDownloader, MyBlob } from 'utilities';

import { ExaminationsRepository } from './repository';

/**
 * fetches examination report
 * @param {object} options mutation options
 * @returns fetch examination report mutation
 */
const useDownloadExaminationReport = (options = {}) => {
	const { patientId } = useParams();

	function handleSuccess(data) {
		const { data: reports, headers } = data;
		const filename = headers['x-suggested-filename'];
		const url = window.URL.createObjectURL(new MyBlob(reports).blob);

		const fileDownloader = new FileDownloader(url, filename);
		fileDownloader.download();

		options?.onSuccess?.(data);
	}

	const mutation = useMutation(
		(payload) => ExaminationsRepository.getReport({ payload, patientId }),
		{ ...options, onSuccess: handleSuccess }
	);

	return mutation;
};

export default useDownloadExaminationReport;
