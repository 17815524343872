export const TRANSLATIONS = {
	PATIENT_NOT_AVAILABLE:
		'chat.patient_not_linked.chat_with_your_patient_is_not_available',
	NO_ACTIVE_CONNECTION:
		'chat.patient_not_linked.you_do_not_have_active_connection',
	PATIENT_NOT_FOUND:
		'chat.patient_not_linked.patient_details.patient_not_found'
};

export const INITIAL_STATE = { isError: false, message: '' };

export const getPatientDetailsPath = ({
	pathUrl,
	paramKey,
	paramPatientId
}) => {
	const patientId = paramPatientId;
	const patientRedirectUrl =
		patientId && paramKey
			? pathUrl.replace(paramKey, paramPatientId)
			: pathUrl;

	return { patientRedirectUrl, patientId };
};
