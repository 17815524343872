import { MutationOptions, useMutation } from 'components/utilities';
import {
	ShareReportByRecipientEmailRequestModel,
	ShareReportByRecipientEmailResponseModel
} from 'models/Report/ShareReport';

import { ExaminationReportV2Repository } from './repository';

export const useShareReportByRecipientEmail = (
	options: MutationOptions<
		ShareReportByRecipientEmailResponseModel,
		TMyError,
		ShareReportByRecipientEmailRequestModel
	> = {}
) => {
	return useMutation(
		ExaminationReportV2Repository.shareReportByRecipientEmail,
		options
	);
};
