export default (values) => {
	let errors = {};

	if (!values.email) {
		errors.email = 'reset_password.validation.email_required';
	} else if (
		!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
	) {
		errors.email = 'reset_password.validation.email_invalid';
	}

	return errors;
};
