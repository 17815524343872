import { Box, Typography as MuiTypography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { string } from 'prop-types';
import React from 'react';

import { getDateTime } from './helpers';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: '5px 8px',
		background: theme.palette.gray.main
	}
}));

const Top = ({ createdAt }) => {
	const classes = useStyles();

	const { date, time } = getDateTime(createdAt);
	return (
		<Box className={classes.root}>
			<MuiTypography
				className={classes.message}
				variant="overline"
				component="p"
			>
				{date} | {time}
			</MuiTypography>
		</Box>
	);
};

Top.propTypes = {
	createdAt: string.isRequired
};

export default Top;
