import { useMutation } from 'components/utilities';
import { setFilterParams, setQueryParams } from 'helpers';
import { array, bool, func, node, object, string } from 'prop-types';
import React from 'react';
import { useQueryClient } from 'react-query';

// Import reducer
import * as actions from '../store/actions';
import reducer from '../store/reducer';
import { INITIAL_STATE } from './helpers';

// Create context
export const ControlledTableContext = React.createContext();

const { setOptions, updateInputColumns, setFilters, fetchControlledTableData } =
	actions;

const ControlledTableProvider = ({
	columns,
	url,
	children,
	initialResources = {},
	queryKey,
	isInfiniteScroll = false,
	customFetchData = null
}) => {
	const fetchIdRef = React.useRef(0);
	const isMounted = React.useRef(null);

	React.useEffect(() => {
		isMounted.current = true;

		return () => {
			isMounted.current = false;
		};
	}, []);

	const [state, dispatch] = React.useReducer(reducer, {
		...INITIAL_STATE,
		tableColumns: columns,
		resources: initialResources,
		queryKey
	});

	// eslint-disable-next-line
	const fetchData = React.useCallback(
		// eslint-disable-next-line
		async ({
			options: { pageSize, pageIndex, sortBy, globalFilter },
			inputColumns = state.inputColumns,
			tableFilters,
			isFormRefetch = false
		}) => {
			const {
				pageSize: statePageSize,
				pageIndex: stateIndex,
				sortBy: stateSort,
				globalFilter: stateGlobal
			} = state.options;

			const tableOptions = {
				pageSize: pageSize || statePageSize,
				pageIndex: pageIndex,
				sortBy: sortBy || stateSort,
				globalFilter: globalFilter || stateGlobal
			};

			// Dispatch options
			setOptions(tableOptions)(dispatch);

			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current;

			if (fetchId === fetchIdRef.current) {
				// Get query params
				const queryParams = setQueryParams(tableOptions, inputColumns);
				const filterParams = setFilterParams(
					tableFilters,
					inputColumns,
					queryParams
				);

				const params = `${queryParams}${filterParams}`;
				const newTableOptions = {
					...tableOptions,
					pageIndex: tableOptions.pageIndex
				};

				const data = await fetchControlledTableData({
					options: newTableOptions,
					queryParams: params,
					url,
					dispatch,
					isMounted: isMounted?.current,
					isInfiniteScroll,
					customFetchData
				});
				const isPageIndexChange = stateIndex < tableOptions.pageIndex;

				return { data, tableOptions, isFormRefetch, isPageIndexChange };
			}
		},
		// eslint-disable-next-line
		[]
	);

	const refetchTableDataResources = () => {
		mutateAsync({
			options: state.options,
			inputColumns: state.inputColumns,
			filters: state.filters,
			isFormRefetch: true
		});
	};

	const queryClient = useQueryClient();

	const {
		mutateAsync,
		isError: isMutateError,
		apiError: mutateError,
		isSuccess
	} = useMutation(fetchData, {
		onSuccess: ({ tableOptions, data }) =>
			queryClient.setQueryData([queryKey, tableOptions], data)
	});

	const handleSetFilters = (values) => setFilters(values)(dispatch);

	const handleUpdateInputColumns = (columns) =>
		updateInputColumns(columns)(dispatch);

	return (
		<ControlledTableContext.Provider
			value={{
				...state,
				columns: state.tableColumns,
				setFilters: handleSetFilters,
				updateColumns: handleUpdateInputColumns,
				fetchData,
				mutateAsync,
				refetchTableDataResources,
				isMutateError,
				mutateError,
				isSuccess,
				queryKey
			}}
		>
			{children}
		</ControlledTableContext.Provider>
	);
};

ControlledTableProvider.propTypes = {
	columns: array.isRequired,
	initialResources: object,
	url: string.isRequired,
	children: node.isRequired,
	queryKey: string.isRequired,
	isInfiniteScroll: bool,
	customFetchData: func
};

export default ControlledTableProvider;
