import {
	IconButton,
	InputAdornment as MuiInputAdornment
} from '@material-ui/core';
import { VisibilityIcon,VisibilityOffIcon } from 'assets/icons/commonMUIIcons';
import { bool, func } from 'prop-types';
import React from 'react';

const InputAdornment = ({ toggle, on }) => {
	return (
		<MuiInputAdornment position="end">
			<IconButton
				onClick={toggle}
				onMouseDown={toggle}
				edge="end"
				size="small"
			>
				{on ? <VisibilityOffIcon /> : <VisibilityIcon />}
			</IconButton>
		</MuiInputAdornment>
	);
};

InputAdornment.propTypes = {
	toggle: func.isRequired,
	on: bool.isRequired
};

export default InputAdornment;
