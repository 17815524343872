import RCFullCalendar from '@fullcalendar/react';
import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { Alert, CardSpinner } from 'components/elements';
import { useTranslations } from 'components/utilities';
import { bool, number, object, oneOfType, shape } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { AutoSizer } from 'react-virtualized';

import Appointment from '../Appointment';
import DayHeader from '../DayHeader';
import { CALENDAR_PLUGINS, getCalendarSettings } from './helpers';
import { useStyles } from './styles';
import useCalendarCell from './useCalendarCell';
import useFullCalendar from './useFullCalendar';

const TOPBAR_HEIGHT = 86;

const FullCalendar = React.forwardRef(
	({ appointmentsDateRange, dayMaxEvents, ...rest }, calendarRef) => {
		const { t } = useTranslations();
		const { lng } = useSelector((store) => store.language);
		const classes = useStyles();

		const { apiError, isMonthView, appList, isLoading } = useFullCalendar({
			appointmentsDateRange,
			...rest
		});
		const { onSelect, onAllowSelect, onAppointmentClick } = useCalendarCell(
			{ ...rest }
		);

		const eventContent = (params) => <Appointment {...params} />;

		const dayHeaderContent = (params) => (
			<DayHeader {...params} {...rest} />
		);

		const wrapperClass = isMonthView
			? classes.calendarMonth
			: classes.calendarWeekDay;

		return (
			<AutoSizer disableWidth>
				{({ height }) => (
					<CardSpinner
						isLoading={isLoading}
						className={{
							classCard: classes.card,
							classContent: clsx([wrapperClass, classes.root])
						}}
						style={{
							height: height - TOPBAR_HEIGHT
						}}
					>
						<Box
							style={{
								minWidth: 1150,
								height: 'inherit'
							}}
						>
							{apiError && (
								<Alert
									title={t('schedule.error.title')}
									message={t('schedule.error.message')}
								/>
							)}
							<RCFullCalendar
								ref={calendarRef}
								plugins={CALENDAR_PLUGINS}
								events={appList}
								eventContent={eventContent}
								dayHeaderContent={dayHeaderContent}
								locale={lng}
								dayMaxEvents={dayMaxEvents}
								eventClick={onAppointmentClick}
								selectAllow={onAllowSelect}
								select={onSelect}
								{...getCalendarSettings(t)}
							/>
						</Box>
					</CardSpinner>
				)}
			</AutoSizer>
		);
	}
);

FullCalendar.propTypes = {
	appointmentsDateRange: shape({
		starDate: object,
		endDate: object
	}),
	dayMaxEvents: oneOfType([number, bool])
};

export default FullCalendar;
