import { CancelToken } from 'axios';
import { useQuery } from 'components/utilities';
import { UseQueryOptionsProps } from 'components/utilities/hooks/react-query/useQuery';
import { ExaminationsListConvertedItemModel } from 'models/Examinations/ExaminationsList';
import { QueryClient, useQueryClient } from 'react-query';
import { useParams } from 'utilities';

import { ExaminationsListRepository } from './repository';
import { ExaminationsListServices } from './services';
import useExaminationsListQueryKey from './useExaminationsListQueryKey';

const useFetchExaminationsList = (
	options: UseQueryOptionsProps<ExaminationsListConvertedItemModel[]> = {}
) => {
	const { patientId } = useParams();
	const cache: QueryClient = useQueryClient();

	const queryKey = useExaminationsListQueryKey();

	const query = useQuery<ExaminationsListConvertedItemModel[]>(
		queryKey,
		fetchResources,
		{
			...options,
			keepPreviousData: true,
			refetchOnMount: true,
			cacheTime: 8 * 60 * 60 * 1000 // 8 hours
		}
	);

	async function fetchResources(payload: {
		cancelToken: CancelToken;
	}): Promise<ExaminationsListConvertedItemModel[]> {
		const data = await ExaminationsListRepository.getExaminationsList({
			...payload,
			patientId
		});

		/*eslint-disable*/
		return data?.data?.data
			? ExaminationsListServices.convertExaminationsListData(
					data?.data?.data
			  )
			: [];
		/*eslint-enable*/
	}

	const isLoading = query.isLoading || !cache.getQueryData(queryKey);

	return { ...query, isLoading };
};

export default useFetchExaminationsList;
