import _ from 'lodash';
import React, { CSSProperties } from 'react';

import { TableHead, TableRow } from '../../../atoms';
import { TableHeaderCell } from '../TableHeaderCell';
import { useStyles } from './styles';

export type SortDirection = 'asc' | 'desc';

export type CellSizing = {
	minWidth: React.CSSProperties['minWidth'];
	width: React.CSSProperties['width'];
};

export interface HeaderGroup<THeader> {
	headers: ({
		id: string | number;
		colSpan: number;
	} & THeader)[];
	id: string | number;
}

export interface ITableHeadRowProps<THeader> {
	headerGroups: HeaderGroup<THeader>[];
	cellRenderer: (header: THeader) => JSX.Element | React.ReactNode;
	className?: string;
	columnSizeGetter?: (header: THeader) => CellSizing;
	columnCanSortGetter?: (header: THeader) => boolean;
	columnToggleSortingHandlerGetter?: (
		header: THeader
	) => ((event: unknown) => void) | undefined;
	columnIsSortedGetter?: (header: THeader) => false | SortDirection;
	columnTextAlignGetter?: (
		header: THeader
	) =>
		| undefined
		| 'start'
		| 'end'
		| 'left'
		| 'right'
		| 'center'
		| 'justify'
		| 'initial'
		| 'match-parent';
	style?: CSSProperties;
}

export const TableHeadRow = <THeader,>({
	headerGroups,
	cellRenderer,
	className = '',
	columnSizeGetter = () => ({
		minWidth: 'auto',
		maxWidth: 'auto',
		width: 'auto'
	}),
	columnCanSortGetter = () => false,
	columnToggleSortingHandlerGetter = () => () => undefined,
	columnIsSortedGetter = () => false,
	columnTextAlignGetter = () => 'initial',
	...rest
}: ITableHeadRowProps<THeader>) => {
	const classes = useStyles();

	return (
		<TableHead className={className} {...rest}>
			{headerGroups.map((headerGroup) => (
				<TableRow key={headerGroup.id}>
					{headerGroup.headers.map((header) => {
						const columnSize = columnSizeGetter(header);
						if (
							_.isNumber(columnSize.width) &&
							!_.isNumber(columnSize.minWidth)
						) {
							columnSize.minWidth = columnSize.width;
						}
						return (
							<TableHeaderCell
								key={header.id}
								colSpan={header.colSpan}
								style={columnSize}
								{...{
									className: columnCanSortGetter(header)
										? classes.cursorPointer
										: '',
									onClick:
										columnToggleSortingHandlerGetter(header)
								}}
								isSorted={columnIsSortedGetter(header)}
								canSort={columnCanSortGetter(header)}
								textAlign={columnTextAlignGetter(header)}
							>
								{cellRenderer(header)}
							</TableHeaderCell>
						);
					})}
				</TableRow>
			))}
		</TableHead>
	);
};
