import { flexRender, Header } from '@tanstack/react-table';
import { CellSizing } from 'components/elements/molecules/Table/TableHeadRow/TableHeadRow';
import { useTranslations } from 'components/utilities';
import React from 'react';

import { IVirtualTable } from './VirtualTable';

export interface IUseVirtualTable<TData> {
	refreshData: IVirtualTable<TData>['refreshData'];
}
export const useVirtualTable = <TData>({
	refreshData
}: IUseVirtualTable<TData>) => {
	const { t } = useTranslations();

	const handleRenderCell = React.useCallback(
		(header: Header<TData, unknown>) =>
			flexRender(
				typeof header.column.columnDef.header === 'string' &&
					t(header.column.columnDef.header)
					? t(header.column.columnDef.header)
					: header.column.columnDef.header,
				header.getContext()
			),
		[]
	);

	const handleCellSizingGetter = React.useCallback(
		(header: Header<TData, unknown>) => {
			const sizing: CellSizing = {
				minWidth: 'auto',
				width: 'auto'
			};

			if (header.column.columnDef.minSize) {
				sizing.minWidth = header.column.columnDef.minSize;
			}

			if (header.column.columnDef.size) {
				sizing.width = header.column.columnDef.size;
			}
			return sizing;
		},
		[]
	);

	const handleRefreshData = React.useCallback(() => {
		if (refreshData) {
			refreshData();
		}
	}, [typeof refreshData === 'function']);

	return {
		cellSizingGetter: handleCellSizingGetter,
		renderCell: handleRenderCell,
		refreshDataHandler: handleRefreshData
	};
};
