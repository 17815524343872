import { Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useMedicationsContext } from 'components/context';
import { FormCardLayoutRPC } from 'components/utilities';
import { DRUG_RESOURCES_QUERY_KEY } from 'helpers/api';
import React from 'react';

import { ActionButtons, Fields, Top } from './components';
import { getInitialValues } from './helpers';
import useDrugForm from './useDrugForm';
// Import validation
import { validation } from './validation';

const useStyles = makeStyles((theme) => ({
	content: {
		padding: `20px ${theme.spacing(2)}px`
	}
}));

const DrugForm = () => {
	const classes = useStyles();
	const { prevPath, drugId, fetchResources, submitResources, onSuccess } =
		useDrugForm();

	const context = useMedicationsContext();

	return (
		<FormCardLayoutRPC
			QUERY_KEY={[DRUG_RESOURCES_QUERY_KEY, drugId]}
			fetchFormResAction={fetchResources}
			submitFormAction={submitResources}
			goBackPath={prevPath}
			validation={validation(context)}
			initialValues={getInitialValues(context)}
			actionButtons={false}
			classNameContent={classes.content}
			queryOptions={{ onSuccess: onSuccess }}
		>
			{({ isEdit }) => (
				<Grid container spacing={2}>
					<Grid item lg={12} xs={12}>
						<Top isEdit={isEdit} prevPath={prevPath} />
					</Grid>
					<Grid item lg={12} xs={12}>
						<Fields isEdit={isEdit} />
					</Grid>
					<Grid item lg={12} xs={12}>
						<Divider />
					</Grid>
					<Grid item lg={12} xs={12}>
						<ActionButtons prevPath={prevPath} isEdit={isEdit} />
					</Grid>
				</Grid>
			)}
		</FormCardLayoutRPC>
	);
};

export default DrugForm;
