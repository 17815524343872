import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslations } from 'components/utilities';
import React from 'react';

import { useStyles } from './styles';

export interface ILabel {
	className?: string;
	labelKey: string;
}

export const Label = ({ labelKey, className = '' }: ILabel) => {
	const classes = useStyles();
	const { t } = useTranslations();

	return (
		<Typography className={clsx([className, classes.text])}>
			{t(labelKey)}
		</Typography>
	);
};
