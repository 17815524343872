import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { ValueLabel } from 'components/elements/atoms/ExaminationsV2';
import { ForcedSpirometryReportModel } from 'models/Report/Reports';
import React from 'react';

import { useStyles } from './styles';

export type Props = {
	grade:
		| ForcedSpirometryReportModel['scoreFvc']
		| ForcedSpirometryReportModel['scoreFev1'];
	isActive?: boolean;
	gradeContainerClassName?: string;
	gradeLabelClassName?: string;
};
export const Grade = ({
	grade = null,
	isActive,
	gradeContainerClassName = '',
	gradeLabelClassName = ''
}: Props) => {
	const classes = useStyles({ isActive });
	return (
		<Box
			className={clsx([gradeContainerClassName, classes.valueContainer])}
		>
			<ValueLabel value={grade ?? ''} className={gradeLabelClassName} />
		</Box>
	);
};
