import fscreen from 'fscreen';
import React from 'react';

const useFullscreen = ({ callback } = {}) => {
	const fullscreenRef = React.useRef();
	const [active, setActive] = React.useState(false);

	const handleChangeFullScreen = () => {
		setActive(fscreen.fullscreenElement === fullscreenRef.current);
		callback?.();
	};

	// change active state when fullscreen is closed by pressing ESC key
	React.useLayoutEffect(() => {
		fscreen.addEventListener('fullscreenchange', handleChangeFullScreen);
		return () =>
			fscreen.removeEventListener(
				'fullscreenchange',
				handleChangeFullScreen
			);
		//eslint-disable-next-line
	}, []);

	const enterFullscreen = React.useCallback(async () => {
		// close current fullscreen component
		if (fscreen.fullscreenElement) {
			await fscreen.exitFullscreen();
		}

		return fscreen.requestFullscreen(fullscreenRef.current);
	}, []);

	const exitFullscreen = React.useCallback(() => {
		if (fscreen.fullscreenElement === fullscreenRef.current) {
			return fscreen.exitFullscreen();
		}
	}, []);

	// tells if fullscreen is supported
	const fullscreenEnabled = fscreen.fullscreenEnabled;

	return {
		fullscreenRef,
		fullscreenEnabled,
		isFullscreenActive: active,
		enterFullscreen,
		exitFullscreen,
		active
	};
};

export default useFullscreen;
