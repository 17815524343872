import { TableCell } from '@material-ui/core';
import { object } from 'prop-types';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import { useStyles } from './styles';

const NameTableCell = ({ cell }) => {
	const classes = useStyles();
	const value = ReactHtmlParser(cell.row.original.name);

	return (
		<TableCell
			className={classes.tableCell}
			key={cell.column.id}
			{...cell.getCellProps()}
		>
			{value}
		</TableCell>
	);
};

NameTableCell.propTypes = {
	cell: object.isRequired
};

export default NameTableCell;
