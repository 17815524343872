import { differenceInCalendarDays } from 'date-fns';

export const DAILY_CHECK_LIST_TYPES = {
	DAILY_CHECK: {
		CONTROLLED: 'controlled',
		PARTIALLY_CONTROLLED: 'partially_controlled',
		UNCONTROLLED: 'uncontrolled',
		EMERGENCY: 'emergency',
		UNKNOW: 'unknown'
	},
	ACTIVITY: {
		NORMAL: 'normal',
		INTERRUPTED: 'interrupted',
		DIFFICULT: 'difficult'
	}
};

export const PARAMETERS = {
	symptomsTypes: 'symptomsTypes',
	activity: 'activity',
	actionPlanHistory: 'actionPlanHistory',
	reliever_use: 'reliever_use',
	night_wakeup: 'night_wakeup',
	createDateTime: 'createDateTime',
	triggers: 'triggers',
	lung: 'lung'
};

const CHART_STATUS_AVAILABLE = 32;

const getParameters = (params) =>
	params.map((item) => `parameters[]=${item}`).join('&');

export const getParams = ({ min, max, patientId, params }) => {
	const date = `startDate=${min}&endDate=${max}`;
	const parameters = getParameters(params);
	const patient = `patient_id=${patientId}`;

	return { date, parameters, patient };
};

export const getStatusChartOptions = ({ startRangeDate, lastRangeDay }) => {
	const getDate = (date) => new Date(date);

	const start = getDate(startRangeDate);
	const end = getDate(lastRangeDay);

	const differenceInDays = differenceInCalendarDays(end, start);
	const isStatusChartDisabled = differenceInDays > CHART_STATUS_AVAILABLE;
	return { isStatusChartDisabled, differenceInDays };
};

export const convertedDailyCheckList = (data) =>
	Object.entries(data).reduce((acc, [key, values]) => {
		acc[key] = Object.entries(values).map(([date, value]) => {
			const data =
				typeof value === 'object' && value?.length === 0 ? null : value;
			return {
				date,
				value: data
			};
		});
		return acc;
	}, {});

export const formatDailyCheckListTypes = (key) => {
	if (key) {
		return key.toLowerCase().replaceAll(/\s/g, '_');
	}
	return key;
};
