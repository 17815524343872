import { Box, Divider, Typography as MuiTypography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { object } from 'prop-types';
import React from 'react';

import Parameter from './Parameter';

const useStyles = makeStyles(() => ({
	root: {
		padding: 8,
		display: 'flex',
		alignItems: 'center'
	},
	name: { fontSize: 15, textTransform: 'uppercase', minWidth: 114 },
	divider: { margin: '0 15px' }
}));

const Bottom = ({ data }) => {
	const classes = useStyles();

	const { name, results } = data;
	return (
		<Box className={classes.root}>
			<MuiTypography className={classes.name} variant="h4" component="p">
				{name}
			</MuiTypography>
			<Divider
				className={classes.divider}
				flexItem
				orientation="vertical"
			/>
			<Box>
				{results.map((result, index) => (
					<Parameter key={index} {...result} />
				))}
			</Box>
		</Box>
	);
};

Bottom.propTypes = {
	data: object.isRequired
};

export default Bottom;
