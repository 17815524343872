import { SPIROMETRY_EXAMINATION_VALUE_KEYS } from 'helpers';
import { getSpirometryExaminationParameterColors } from 'helpers/examinations/colors';
import { PersonalBestModel } from 'models/Examinations/PersonalBest';

import {
	BestValueBuilder,
	PercentageOfPersonalBestBuilder,
	PercentileValueBuilder,
	PredictedValueBuilder,
	SpirometryParametersDirector
} from './Builder';
import { ExaminationDataRowType } from './Builder/Spirometry/SpirometryParametersDirector';
import { IParameterValuesData } from './ParameterValuesData';

const {
	best: { key: bestKey },
	PBPercentage: { key: PBPercentageKey },
	predicted: { key: predictedKey },
	bestPercentile: { key: bestPercentileKey }
} = SPIROMETRY_EXAMINATION_VALUE_KEYS;

type ParametersKeys =
	| typeof bestKey
	| typeof PBPercentageKey
	| typeof predictedKey
	| typeof bestPercentileKey;

export type Parameters =
	| Required<ISpirometryParameters>
	| ISpirometryParameters;

export class SpirometryParameters {
	private examinationDataRowType: ExaminationDataRowType;
	private personalBest: PersonalBestModel;

	constructor(
		personalBest: PersonalBestModel,
		examinationDataRowType?: ExaminationDataRowType
	) {
		this.examinationDataRowType = examinationDataRowType;
		this.personalBest = personalBest;
	}

	getValuesWithColors = (
		parameters: Parameters
	): { [key in ParametersKeys]: IParameterValuesData } => {
		const values = this.getParametersWithValidValues(parameters);
		const colors = this.getParametersColors(
			parameters.bestStatus,
			this.flatValues(values)
		);

		Object.entries(values).forEach(([key, value]) => {
			value.setColor(colors[key]);
		});

		return values;
	};

	private getParametersWithValidValues = (
		parameters: Parameters
	): { [key in ParametersKeys]: IParameterValuesData } => {
		const director = new SpirometryParametersDirector(
			parameters,
			this.examinationDataRowType
		);
		const bestValueBuilder = new BestValueBuilder(
			this.examinationDataRowType
		);
		const percentageOfPersonalBestValueBuilder =
			new PercentageOfPersonalBestBuilder();
		const predictedValueBuilder = new PredictedValueBuilder();
		const percentileValueBuilder = new PercentileValueBuilder();

		director.createBestValue(bestValueBuilder);
		director.createPercentageOfPersonalBestValue(
			percentageOfPersonalBestValueBuilder
		);
		director.createPredictedValue(predictedValueBuilder);
		director.createPercentileValue(percentileValueBuilder);

		return {
			[bestKey]: bestValueBuilder.getValue(),
			[PBPercentageKey]: percentageOfPersonalBestValueBuilder.getValue(),
			[predictedKey]: predictedValueBuilder.getValue(),
			[bestPercentileKey]: percentileValueBuilder.getValue()
		};
	};

	private getParametersColors = (
		status: ExaminationParameterStatus,
		flattenValues
	) => {
		const colors = getSpirometryExaminationParameterColors({
			status,
			personalBestExaminationValue: this.personalBest,
			examinationValues: flattenValues,
			EXAMINATION_VALUES_KEYS: SPIROMETRY_EXAMINATION_VALUE_KEYS
		});

		return colors;
	};

	private flatValues = (values: {
		[key in ParametersKeys]: IParameterValuesData;
	}): { [key in ParametersKeys]: number } => {
		const examinationValuesEntries = Object.entries(values);
		const flattenValues = examinationValuesEntries.reduce(
			(acc: { [key in ParametersKeys]: number }, [key, value]) => {
				acc[key] = value.value;
				return acc;
			},
			{}
		);

		return flattenValues;
	};
}
