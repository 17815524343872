import { MutationOptions, useMutation } from 'components/utilities';

import { AccountRepository } from './repository';

const useGetAccountDeletionAvailability = <TVariables>(
	options: MutationOptions<void, TMyError, TVariables> = {}
) => {
	const mutation = useMutation<void, TMyError, TVariables>(
		(_, cancelToken) => AccountRepository.deleteAccount({ cancelToken }),
		options
	);

	return mutation;
};

export default useGetAccountDeletionAvailability;
