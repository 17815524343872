import {
	addDays,
	addYears,
	differenceInDays,
	differenceInMonths,
	format,
	getYear,
	isAfter,
	isBefore,
	lastDayOfYear,
	subDays,
	subYears
} from 'date-fns';
import { DATE_FORMAT } from 'helpers/variables';

const { CHART_RANGE } = DATE_FORMAT;

class PreviewDates {
	constructor(lastExaminationExtreme, firstExaminationExtreme) {
		this.lastPreviewDate = lastExaminationExtreme;

		const firstPreviewDate = subYears(tomorrow(lastExaminationExtreme), 1);
		this.firstPreviewDate = isBefore(
			firstPreviewDate,
			firstExaminationExtreme
		)
			? firstExaminationExtreme
			: firstPreviewDate;
	}

	setLastPreviewDate(newLastPreview) {
		this.lastPreviewDate = newLastPreview;
	}

	setFirstPreviewDate(newFirstPreview) {
		this.firstPreviewDate = newFirstPreview;
	}

	getLastPreviewDate() {
		return format(this.lastPreviewDate, CHART_RANGE);
	}

	getFirstPreviewDate() {
		return format(this.firstPreviewDate, CHART_RANGE);
	}

	getLastPreviewYear() {
		return getYear(this.lastPreviewDate);
	}

	setIfIsBeforeFirstChosenDate(firstChosenDate) {
		if (isBefore(firstChosenDate, this.firstPreviewDate)) {
			this.lastPreviewDate = subDays(
				this.lastPreviewDate,
				differenceInDays(this.firstPreviewDate, firstChosenDate)
			);
			this.firstPreviewDate = firstChosenDate;
		}
	}

	setTwoYearsPeriod({
		startDate,
		endDate,
		firstExaminationDate,
		lastExaminationDate
	}) {
		const lastYearDayOfLastPreviewDate = lastDayOfYear(endDate);
		const firstYearDayOfFirstPreviewDate = subYears(
			tomorrow(lastYearDayOfLastPreviewDate),
			1
		);

		if (differenceInMonths(endDate, startDate) > 12) {
			this.firstPreviewDate = startDate;
			this.lastPreviewDate = endDate;
			return;
		}

		if (isBefore(firstYearDayOfFirstPreviewDate, firstExaminationDate)) {
			const yearFromFirstExaminationExtreme = addYears(
				yesterday(firstExaminationDate, 1),
				1
			);
			this.lastPreviewDate = isAfter(
				yearFromFirstExaminationExtreme,
				lastExaminationDate
			)
				? lastExaminationDate
				: yearFromFirstExaminationExtreme;
			this.firstPreviewDate = firstExaminationDate;
			return;
		}

		this.firstPreviewDate = firstYearDayOfFirstPreviewDate;
		this.lastPreviewDate = lastYearDayOfLastPreviewDate;
	}
}

export default PreviewDates;

function tomorrow(date) {
	return addDays(date, 1);
}

function yesterday(date) {
	return subDays(date, 1);
}
