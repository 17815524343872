import { isAfter, isBefore, isSameDay } from 'date-fns';
import { ExaminationsListConvertedItemModel } from 'models/Examinations/ExaminationsList';
import { getCreationDateFromExaminationListItem } from 'utilities/Examinations/common/common';

export const isLastActiveExaminationInDatesRange = (
	examinationsDateRange: Nullable<TDateRange>,
	activeExaminationCreationDate: Date
) => {
	return (
		// last active examination occurred before date to
		examinationsDateRange.endDate &&
		(isSameDay(
			activeExaminationCreationDate,
			examinationsDateRange.endDate
		) ||
			isBefore(
				activeExaminationCreationDate,
				examinationsDateRange.endDate
			)) &&
		// last active examination occurred after date start
		examinationsDateRange.startDate &&
		(isSameDay(
			activeExaminationCreationDate,
			examinationsDateRange.startDate
		) ||
			isAfter(
				activeExaminationCreationDate,
				examinationsDateRange.startDate
			))
	);
};

export const isElementBeforeInTheSameDay = (
	listData: ExaminationsListConvertedItemModel[],
	index: number,
	activeExaminationCreationDate: Date
) =>
	listData?.[index - 1] &&
	isSameDay(
		getCreationDateFromExaminationListItem(listData?.[index - 1]),
		activeExaminationCreationDate
	);

export const isNextElementInTheSameDay = (
	listData: ExaminationsListConvertedItemModel[],
	index: number,
	activeExaminationCreationDate: Date
) =>
	!listData?.[index] ||
	isSameDay(
		activeExaminationCreationDate,
		getCreationDateFromExaminationListItem(listData[index])
	);

export const addElementToExaminationsListInIndex = (
	listData: ExaminationsListConvertedItemModel[],
	index: number,
	itemData: ExaminationsListConvertedItemModel
): ExaminationsListConvertedItemModel[] => {
	const newListData = [...listData];
	newListData.splice(index, 0, itemData);

	return newListData;
};
