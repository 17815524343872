import { Box, Typography as MuiTypography } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { bool, object, string } from 'prop-types';
import React from 'react';

import { InputError } from '../../atoms';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center'
	},
	label: {
		fontWeight: 'bold',
		marginRight: theme.spacing(2),
		minWidth: '105px',
		display: 'inline-block'
	},
	disabledInput: {
		'& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline':
			{
				borderColor: 'transparent'
			},
		'& .MuiOutlinedInput-root': {
			border: '1px solid rgba(0, 0, 0, 0.23)'
		},
		'& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
			display: 'none'
		},
		'& .MuiInputBase-input.Mui-disabled': {
			color: '#263238'
		}
	}
}));

const DatePickerFieldAdapter = ({
	input,
	meta,
	formatDate = 'dd.MM.yyyy',
	isLabel = false,
	classNameRoot = '',
	...rest
}) => {
	const classes = useStyles();
	return (
		<Box className={clsx([classNameRoot, classes.root])}>
			{isLabel && (
				<MuiTypography component="span" className={classes.label}>
					{rest.label}
				</MuiTypography>
			)}
			<DatePicker {...input} {...rest} format={formatDate} />
			<InputError meta={meta} />
		</Box>
	);
};

DatePickerFieldAdapter.propTypes = {
	input: object.isRequired,
	meta: object.isRequired,
	isDisabled: bool,
	isLabel: bool,
	formatDate: string,
	classNameRoot: string
};

export default DatePickerFieldAdapter;
