import { Dialog as MuiDialog, DialogProps } from '@material-ui/core';
import React from 'react';

export interface IDialogProps extends DialogProps {
	disableBackdropClick?: boolean;
	onClose?: () => void;
	onExited?: ((node: HTMLElement) => void) | undefined;
}

export const Dialog = ({
	children,
	disableBackdropClick = false,
	onClose = undefined,
	onExited = undefined,
	...rest
}: IDialogProps) => {
	const handleDialogClose = (_, reason) => {
		if (disableBackdropClick && reason === 'backdropClick') {
			return false;
		}

		if (onClose) {
			onClose();
		}
	};

	return (
		<MuiDialog
			data-testid={'muiDialog'}
			onClose={handleDialogClose}
			BackdropProps={{
				// data-testid is injected to BackdropComponent but TS throws IntrinsicAttributes?
				//eslint-disable-next-line
				//@ts-ignore
				'data-testid': 'muiDialogBackdrop'
			}}
			{...rest}
			TransitionProps={{
				onExited
			}}
		>
			{children}
		</MuiDialog>
	);
};
