import { DeviceSchema } from 'models/Device/Device';
import { z } from 'zod';

import { PDFsSchema } from './common';

export const StatusSchema = z.boolean().nullable();
export type StatusModel = z.infer<typeof StatusSchema>;

export const CalibrationReferenceValuesSchema = z.object({
	min: z.number(),
	max: z.number()
});
export const VerificationHistoryItemSchema = z.object({
	createDateTime: z.string(),
	device: DeviceSchema,
	status: StatusSchema
});
export type VerificationHistoryItemModel = z.infer<
	typeof VerificationHistoryItemSchema
>;

export const TypeItemSchema = z.object({
	value: z.number().nullable(),
	accuracy: z.number().nullable().optional(),
	deviation: z.number().nullable().optional(),
	status: StatusSchema.optional(),
	reference: CalibrationReferenceValuesSchema
});
export type TypeItemModel = z.infer<typeof TypeItemSchema>;

export const CheckTypeSchema = z.object({
	slow: TypeItemSchema,
	medium: TypeItemSchema,
	fast: TypeItemSchema
});
export type CheckTypeModel = z.infer<typeof CheckTypeSchema>;

export const CalibrationCheckReportSchema = z.object({
	device: DeviceSchema,
	examinationCreateDateTime: z.string(),
	expiratory: CheckTypeSchema,
	inspiratory: CheckTypeSchema,
	verification: z.object({
		result: StatusSchema,
		history: z.array(VerificationHistoryItemSchema).max(5).nullable()
	}),
	reports: PDFsSchema.optional()
});
