import {
	useExaminationsV2Context,
	useExaminationsV2RefsContext
} from 'components/context/ExaminationsV2';
import { endOfDay, format, startOfDay } from 'date-fns';
import { DATE_FORMAT } from 'helpers';
import {
	DEFINED_USER_SETTINGS_UNITS_PARAMETERS,
	getUserUnit
} from 'helpers/charts/measurement/userUnitsSetting';
import { getChartDataQueryKey } from 'queries/Examinations/ChartData/helpers';
import React from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'utilities';

import {
	useInitialSeries,
	useInitialTooltipActivity,
	usePlotMarkersEvents,
	useXAxisEvents
} from './hooks';

const useHighCharts = () => {
	const { patientId } = useParams();
	const queryClient = useQueryClient();
	const { store: examinationsDateRange } = useExaminationsV2Context(
		(s) => s.examinationsDateRange
	);
	const { store: examinationDateExtremes } = useExaminationsV2Context(
		(s) => s.examinationDateExtremes
	);
	const { store: trendChartRef } = useExaminationsV2RefsContext(
		(store) => store.trendChartRef
	);

	const xAxisEvents = useXAxisEvents();
	const { pointEvents } = usePlotMarkersEvents();
	useInitialSeries();
	useInitialTooltipActivity();

	React.useEffect(() => {
		trendChartRef.current.chart.update({
			plotOptions: {
				scatter: {
					point: {
						events: pointEvents
					}
				}
			},
			xAxis: {
				min: startOfDay(examinationsDateRange.startDate).getTime(),
				max: endOfDay(examinationsDateRange.endDate).getTime(),
				events: xAxisEvents
			}
		});
	}, [
		trendChartRef?.current === null,
		startOfDay(examinationsDateRange.startDate).getTime(),
		endOfDay(examinationsDateRange.endDate).getTime()
	]);

	React.useEffect(() => {
		if (trendChartRef?.current) {
			trendChartRef?.current.chart.yAxis
				.find((yAxis) => yAxis.options.axisNames.includes('PEF'))
				?.update({
					title: {
						text: getUserUnit('pef')
					}
				});
		}
	}, [trendChartRef?.current === null]);

	React.useEffect(() => {
		return () => {
			DEFINED_USER_SETTINGS_UNITS_PARAMETERS.forEach((param) => {
				if (
					examinationDateExtremes.endDate &&
					examinationDateExtremes.startDate
				) {
					queryClient.removeQueries(
						getChartDataQueryKey({
							patientId,
							chartEndDate: format(
								examinationDateExtremes.endDate,
								DATE_FORMAT.CHART_RANGE
							),
							chartStartDate: format(
								examinationDateExtremes.startDate,
								DATE_FORMAT.CHART_RANGE
							),
							param
						})
					);
				}
			});
		};
	}, []);

	return {
		trendChartRef
	};
};

export default useHighCharts;
