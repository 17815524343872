import { Box } from '@material-ui/core';
import React from 'react';

import { ChartLegend } from './components';
import { useStyles } from './styles';

const Controllers = () => {
	const classes = useStyles();
	return (
		<Box className={classes.root}>
			<ChartLegend />
		</Box>
	);
};

export default Controllers;
