import { DoctorUserModel } from 'models/User/Doctor';
import { Dispatch } from 'redux';
import { saveSignInDataToRedux } from 'store/actions';
import { Cookies } from 'utilities';

import { AuthServices } from '../../services';
import { ISignInStrategy } from './ISignInStrategy';

export class SignInWithout2FA implements ISignInStrategy<DoctorUserModel> {
	private dispatch: Dispatch;
	constructor(dispatch: Dispatch) {
		this.dispatch = dispatch;
	}

	public success = (data: DoctorUserModel) => {
		const {
			reduxData,
			cookiesData,
			jwtToken
		} = AuthServices.convertAuthData(data);
		Cookies.jwt.save(jwtToken);
		Cookies.auth.save(cookiesData);
		saveSignInDataToRedux(reduxData)(this.dispatch);
	};
}
