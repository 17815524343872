import { Box,IconButton, Typography } from '@material-ui/core';
import { ArrowBackIcon, CloseIcon } from 'assets/icons/commonMUIIcons';
import { DASH_PATHS } from 'components/routes/paths';
import { useTranslations } from 'components/utilities';
import { bool, string } from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';

import EditControls from './EditControls';
import { useStyles } from './styles';

const TopBar = ({ formTitle, showEditControls = false }) => {
	const history = useHistory();
	const classes = useStyles();
	const { t } = useTranslations();

	const handleGoBack = () => {
		history.push(DASH_PATHS.SCHEDULE);
	};

	return (
		<Box className={classes.wrapper}>
			<IconButton color="primary" onClick={handleGoBack}>
				<ArrowBackIcon />
			</IconButton>
			<Typography variant="h5" color="textPrimary" style={{ flex: 1 }}>
				{t(formTitle)}
			</Typography>
			{showEditControls && (
				<Box className={classes.edit}>
					<EditControls />
				</Box>
			)}
			{!showEditControls && (
				<IconButton color="primary" onClick={handleGoBack}>
					<CloseIcon />
				</IconButton>
			)}
		</Box>
	);
};

TopBar.propTypes = {
	formTitle: string.isRequired,
	showEditControls: bool
};

export default TopBar;
