import { Box } from '@material-ui/core';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { AttemptNumber } from './AttemptNumber';
import { useStyles } from './styles';

export type AttemptsNumbersProps = {
	activeMeasurementIndex: number;
	attemptsArray: { isBest: boolean }[];
	isBestIndex: number;
	/* eslint-disable */
	component?:
		| (({
				attemptNumber,
				isBestIndex,
				index
		  }: {
				attemptNumber: number;
				isBestIndex: number;
				index: number;
		  }) => JSX.Element)
		| null;
	/* eslint-enable */
};
export const AttemptsNumbers = ({
	activeMeasurementIndex,
	attemptsArray,
	isBestIndex,
	component: AttemptComponent = null
}: AttemptsNumbersProps) => {
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			{attemptsArray.map((el, i) =>
				AttemptComponent ? (
					<AttemptComponent
						key={uuidv4()}
						attemptNumber={i}
						isBestIndex={isBestIndex}
						index={i}
					/>
				) : (
					<AttemptNumber
						key={uuidv4()}
						isBest={el.isBest}
						number={i}
						isBestIndex={isBestIndex}
						isActive={activeMeasurementIndex === i}
					/>
				)
			)}
		</Box>
	);
};
