import { Menu } from '@material-ui/core';
import { useSelectProps } from 'components/utilities/hooks';
import { func } from 'prop-types';
import React from 'react';

import useMenuRPC from './useMenuRPC';

const MenuRPC = ({ children, togglerComponent: TogglerComponent, ...rest }) => {
	const { anchorEl, open, close } = useMenuRPC();
	const selectProps = useSelectProps();

	return (
		<>
			<TogglerComponent open={open} />
			<Menu
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={close}
				{...selectProps}
				{...rest}
			>
				{children({ close, open })}
			</Menu>
		</>
	);
};

MenuRPC.propTypes = {
	children: func.isRequired,
	togglerComponent: func.isRequired
};

export default MenuRPC;
