import { makeStyles } from '@material-ui/core/styles';
import palette from 'theme/palette';

import { DAILY_CHECK_LIST_TYPES, formatDailyCheckListTypes } from './common';

const { ACTIVITY, DAILY_CHECK } = DAILY_CHECK_LIST_TYPES;

const popoverClassName = ({ top = -20 }) => ({
	'& .MuiPopover-paper': { marginTop: top }
});

const infoTipIcon = { width: 12, height: 12 };

// ------- ACTIVITIES -------
const { NORMAL, INTERRUPTED, DIFFICULT } = ACTIVITY;

export const activitiesStyles = ({ dailyCheck }) => {
	const key = dailyCheck?.value?.name?.toLowerCase();

	switch (key) {
		case NORMAL:
			return palette.dailyChecks.green;
		case INTERRUPTED:
			return palette.dailyChecks.yellow;
		case DIFFICULT:
			return palette.dailyChecks.red;

		default:
			return palette.dailyChecks.white;
	}
};
export const useActivityStyles = makeStyles(() => ({
	wraper: {
		background: activitiesStyles
	},
	popover: popoverClassName({ top: 3 })
}));

// ------- DAILY CHECKS -------

const { CONTROLLED, PARTIALLY_CONTROLLED, UNCONTROLLED, EMERGENCY } =
	DAILY_CHECK;

export const dailyCheckStyles = ({
	dailyCheck,
	index,
	todayDay,
	isCurrentMonth
}) => {
	const key = formatDailyCheckListTypes(dailyCheck?.value?.value);
	switch (key) {
		case CONTROLLED:
			return palette.dailyChecks.green;
		case PARTIALLY_CONTROLLED:
			return palette.dailyChecks.yellow;
		case UNCONTROLLED:
			return palette.dailyChecks.orange;
		case EMERGENCY:
			return palette.dailyChecks.red;

		default:
			return todayDay - 1 < index && isCurrentMonth
				? palette.white
				: palette.dailyChecks.gray;
	}
};

export const useDailtCheckStyles = makeStyles(() => ({
	wraper: {
		background: dailyCheckStyles
	}
}));

// ------- SYMPTOMS -------
export const symptomsStyles = ({ dailyCheck }) => {
	const key = dailyCheck?.value?.count;

	switch (true) {
		case key === 0:
			return palette.dailyChecks.green;
		case key === 1:
			return palette.dailyChecks.yellow;
		case key === 2:
			return palette.dailyChecks.yellow;
		case key >= 3:
			return palette.dailyChecks.red;

		default:
			return palette.dailyChecks.white;
	}
};

export const useSymptomsStyles = makeStyles(() => ({
	wraper: {
		background: symptomsStyles
	},
	infoTipIcon
}));

// ------- LUNG FUNCTIONS -------
export const lungFuncStyles = ({ dailyCheck }) => {
	const key = dailyCheck?.value?.value;

	switch (true) {
		case key > 80:
			return palette.dailyChecks.green;
		case key >= 60 && key <= 80:
			return palette.dailyChecks.yellow;
		case key < 60:
			return palette.dailyChecks.red;
		default:
			return palette.dailyChecks.white;
	}
};
export const lungFuncColorStyles = {
	color: palette.white,
	overflow: 'hidden',
	fontsize: 10
};

export const useLungFunctionStyles = makeStyles(() => ({
	wraper: {
		background: lungFuncStyles
	},
	content: lungFuncColorStyles
}));

// ------- RELIVIER INTAKES -------
export const relieverIntakesStyles = ({ dailyCheck }) => {
	const key = dailyCheck?.value;
	switch (true) {
		case key === 0:
			return palette.white;
		case key >= 1 && key < 3:
			return palette.dailyChecks.green;
		case key >= 3 && key < 7:
			return palette.dailyChecks.yellow;
		case key >= 7:
			return palette.dailyChecks.red;

		default:
			return palette.dailyChecks.white;
	}
};
export const relieverIntakesColorStyles = ({ dailyCheck }) => {
	const key = dailyCheck?.value;
	return key === 0 ? palette.black : palette.white;
};

export const useRelieverIntakesStyles = makeStyles(() => ({
	wraper: {
		background: relieverIntakesStyles
	},
	intakes: {
		color: relieverIntakesColorStyles
	}
}));
