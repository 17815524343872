import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	container: {
		margin: '5px 0',
		'& > :last-child': {
			marginTop: '5px'
		}
	},
	row: {
		'& > :first-child': {
			width: 100
		}
	}
}));
