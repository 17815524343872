import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	wrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderBottom: `1px solid ${theme.palette.gray.main}`,
		paddingBottom: ({ spacing }) => spacing,
		marginBottom: ({ spacing }) => spacing
	},
	question: {
		flex: 1
	},
	answer: {
		padding: '0 20px',
		marginLeft: 5,
		marginRight: 4
	}
}));
