import { API_URL_PREFIX } from 'helpers/api/helpers';

import { PATIENT_ID_QUERY_PARAM_KEY } from '../../queryParamKeys';

// ------------- Chart's data -------------
export const GET_CHARTS_DATA_V2_API = `${API_URL_PREFIX}/examination/chart`;

// Side list
export const GET_SIDE_LIST_EXAMINATIONS_API = `${API_URL_PREFIX}/patients/${PATIENT_ID_QUERY_PARAM_KEY}/examinations`;
export const QUERY_SIDE_LIST_EXAMINATIONS_DATA =
	'QUERY_SIDE_LIST_EXAMINATIONS_DATA' as const;
