import { isDate, isPast, isValid } from 'date-fns';
import { concatDates } from 'helpers';

export const validation = ({
	start_date,
	start_time,
	patient_id,
	duration,
	notes
}) => {
	let errors = {};

	const isStartDateValid = isDate(start_date) && isValid(start_date);
	const isStartTimeValid = isDate(start_time) && isValid(start_time);

	if (!start_time) {
		errors.start_time = 'schedule.form.validation.start_time_required';
	}

	if (!start_date) {
		errors.start_date = 'schedule.form.validation.start_date_is_required';
	} else if (isStartDateValid && isStartTimeValid) {
		const startDateWithTime = new Date(concatDates(start_date, start_time));
		const isInThePast = isPast(startDateWithTime);
		if (isInThePast) {
			errors.start_date = 'schedule.form.validation.start_date_is_past';
		}
	}

	if (!duration) {
		errors.duration = 'schedule.form.validation.duration';
	}

	if (!patient_id) {
		errors.patient_id = 'schedule.form.validation.patient_required';
	}

	if (notes && notes.length > 255) {
		errors.notes = 'schedule.form.validation.notes';
	}

	return errors;
};
