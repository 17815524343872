import { arrayOf,node, oneOfType } from 'prop-types';
import React from 'react';

export const ItemWrapperContext = React.createContext(null);

const ItemWrapperProvider = ({ children }) => {
	const [isHoverEnable, setHoverEnable] = React.useState(true);
	const setWrapperHoverEffect = () => setHoverEnable((prev) => !prev);

	const providerValues = {
		isHoverEnable,
		setWrapperHoverEffect
	};

	return (
		<ItemWrapperContext.Provider value={providerValues}>
			{children}
		</ItemWrapperContext.Provider>
	);
};

ItemWrapperProvider.propTypes = {
	children: oneOfType([arrayOf(node), node]).isRequired
};

export default ItemWrapperProvider;
