import { Box } from '@material-ui/core';
import { WithInfoTipTooltip } from 'components/elements/hoc';
import { AccountLinkCollapsedChip } from 'components/templates/Patients/AccountLinkCollapsedChip/AccountLinkCollapsedChip';
import { PatientModel } from 'models/Patient/PatientModel';

import { ChatNavLinkButton } from './ChatNavLinkButton';
import { useStyles } from './styles';

export type IControlsProps = PatientModel['patient'];

export const Controls = ({
	id,
	invitationStatus,
	patientFirstName,
	patientLastName,
	patientEmail
}: IControlsProps) => {
	const classes = useStyles();
	return (
		<Box className={classes.container}>
			<WithInfoTipTooltip
				label={'patients.profile.invite_decline_info_tip'}
			>
				<AccountLinkCollapsedChip
					size="small"
					initialEmail={patientEmail}
					invitationStatus={invitationStatus}
					patientFullName={`${patientFirstName} ${patientLastName}`}
					patientId={id}
				/>
			</WithInfoTipTooltip>
			<ChatNavLinkButton />
		</Box>
	);
};
