import { ForcedSpirometryReportModel } from 'models/Report/Reports';

type ZScoreObject = {
	bestZScoreFvc: number | null | undefined;
	bestZScoreFev1: number | null | undefined;
	bestZScoreTiffeneau: number | null | undefined;
};
export const createZScoreObject = (
	data: ForcedSpirometryReportModel['bestParameters']
): ZScoreObject => ({
	bestZScoreFvc: data.fvc.zScore,
	bestZScoreFev1: data.fev1.zScore,
	bestZScoreTiffeneau: data.fev1Fvc.zScore
});
