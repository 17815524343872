import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		padding: '20px',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	header: {
		display: 'flex',
		justifyContent: 'center'
	}
}));
