import { CancelToken } from 'axios';
import { GET_SIDE_LIST_EXAMINATIONS_API } from 'helpers';
import { ExaminationsListResponseModel } from 'models/Examinations/ExaminationsList';
import { replacePatientIdQueryParam } from 'queries/common';
import services from 'services/services';

type IGetExaminationsList = {
	cancelToken: CancelToken;
	patientId: PatientIdParam;
};

export const ExaminationsListRepository = {
	getExaminationsList: ({
		cancelToken,
		patientId
	}: IGetExaminationsList): Promise<ExaminationsListResponseModel> => {
		const url = `${replacePatientIdQueryParam(
			GET_SIDE_LIST_EXAMINATIONS_API,
			patientId
		)}?full_details=0&sort_order_type=desc`;
		return services.get(url, { cancelToken });
	}
};
