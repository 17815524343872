import { useUrlSearchParams } from 'components/utilities';
import { PANEL_ROLES } from 'helpers';
import {
	ClinicRepository,
	CountriesRepository,
	GendersRepository,
	SpecializationsRepository
} from 'queries';

const SEARCH_PARAM_NAME = 'employee-invitation';

const useEmployeeInvitation = ({ role }) => {
	const searchParam = useUrlSearchParams();
	const paramId = searchParam.get(SEARCH_PARAM_NAME);

	const getResources = ({ cancelToken }) => {
		const promises = [];
		if (role === PANEL_ROLES.doctor) {
			promises.push(CountriesRepository.getAll({ cancelToken }));
			promises.push(GendersRepository.getAll({ cancelToken }));

			promises.push(SpecializationsRepository.getAll({ cancelToken }));
			!!paramId &&
				promises.push(
					ClinicRepository.getInvitations({
						cancelToken,
						id: paramId
					})
				);
		}
		return Promise.all(promises).then((res) => res.map(({ data }) => data));
	};

	return { getResources, paramId };
};

export default useEmployeeInvitation;
