import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	shapeWrapper: {
		flexBasis: 24
	},
	legendElement: {
		display: 'flex',
		alignItems: 'center'
	},
	typography: {
		fontSize: '12px',
		lineHeight: '12px',
		fontWeight: 400
	}
}));
