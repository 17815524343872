export const SET_EMAIL_AVAILABLE = 'SET_EMAIL_AVAILABLE';
export const SET_EMAIL_DUPLICATED = 'SET_EMAIL_DUPLICATED';

export const TOGGLE_FORM_OPEN = 'TOGGLE_FORM_OPEN';

export const NEXT_ACTIVE_STEP = 'NEXT_ACTIVE_STEP';
export const PREV_ACTIVE_STEP = 'PREV_ACTIVE_STEP';
export const RESET_ACTIVE_STEP = 'RESET_ACTIVE_STEP';

export const SET_NEW_PATIENT_ID = 'SET_NEW_PATIENT_ID';

export const SET_MARK_PATIENT_AS_INVITED = 'SET_MARK_PATIENT_AS_INVITED';
