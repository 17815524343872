import { useMutation } from 'components/utilities';

import { DoctorRepository } from './repository';

/**
 * fetch doctor's data
 * @param {object} options useMutation options
 * @returns doctor's data mutation
 */
const useFetchMutation = (options = {}) => {
	return useMutation((payload, cancelToken) => {
		return DoctorRepository.get({ ...payload, cancelToken });
	}, options);
};

export default useFetchMutation;
