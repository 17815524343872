import React from 'react';

import { CircledNumber } from '../../../components/AttemptsNumbers/AttemptNumber/CircledNumber';
import { PrePostType } from '../../context/types';
import { UseArrowSelectorsProps } from './useArrowSelectors';
import useAttemptNumber from './useAttemptNumber';

export type AttemptNumberProps = UseArrowSelectorsProps & {
	activeMeasurementIndex: number;
	attemptNumber: number;
	isBest: boolean;
	type: PrePostType;
	color?: string;
};
export const AttemptNumber = ({
	attemptNumber,
	activeMeasurementIndex,
	prePostIndexSetter,
	type,
	color,
	isBest
}: AttemptNumberProps) => {
	const { isAttemptActive, onMouseEnter, onMouseLeave, setActive } =
		useAttemptNumber({
			activeMeasurementIndex,
			attemptNumber,
			type,
			prePostIndexSetter
		});

	return (
		<CircledNumber
			color={color}
			number={attemptNumber}
			isBest={isBest}
			isActive={isAttemptActive}
			getEvents={() => ({
				onClick: setActive,
				onMouseEnter,
				onMouseLeave
			})}
		/>
	);
};
