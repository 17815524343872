import { Chip } from 'components/elements';
import { IChipProps } from 'components/elements/atoms/Chip';
import { TableCellContent } from 'components/elements/molecules/Table';
import { PatientListItemModel } from 'models/Patient/PatientListItem';
import { INVITATION_STATUS } from 'models/Patient/PatientModel';
import React from 'react';
import palette from 'theme/palette';

import { PatientInvitationModal } from '../PatientInvitation/PatientInvitationModal';
import { getLinkedDisplayValue } from './helpers';
import { useInvitationStatusMessagingListener } from './useInvitationStatusMessagingListener';

const accountLinkCollapsedChipVariants = {
	default: (props) => <Chip {...props} />,
	tableCell: (props) => <TableCellContent.Chip {...props} />
} as const;

export interface IAccountLinkCollapsedChipProps {
	invitationStatus: PatientListItemModel['invitationStatus'];
	initialEmail: string | null;
	patientId: string | number;
	patientFullName: string;
	className?: string;
	variant?: keyof typeof accountLinkCollapsedChipVariants;
	size?: IChipProps['size'];
}

export const AccountLinkCollapsedChip = ({
	invitationStatus,
	initialEmail,
	patientId,
	patientFullName,
	className = '',
	variant = 'default',
	size = 'medium'
}: IAccountLinkCollapsedChipProps) => {
	const { status, setStatusPending } = useInvitationStatusMessagingListener({
		invitationStatus,
		patientId
	});

	const ChipVariant = accountLinkCollapsedChipVariants[variant];
	return (
		<>
			<PatientInvitationModal
				initialEmail={initialEmail ?? ''}
				invitationStatus={status}
				patientFullName={patientFullName}
				patientId={patientId}
				onExited={setStatusPending}
			>
				{({ isLoading, setOpen }) => (
					<ChipVariant
						{...{
							size: size,
							isLoading: isLoading,
							loaderSize: 10,
							className,
							collapsedLabel:
								status === INVITATION_STATUS.UNLINKED
									? 'btn.invite'
									: 'btn.disconnect',
							collapsedButton: true,
							onClick: () => {
								setOpen(true);
							},
							label: getLinkedDisplayValue(status),
							backgroundColor: {
								[INVITATION_STATUS.UNLINKED]:
									palette.chip.red.light,
								[INVITATION_STATUS.LINKED]: `${palette.chip.green.medium}30`,
								[INVITATION_STATUS.PENDING]:
									palette.chip.gray.main
							}[status]
						}}
					/>
				)}
			</PatientInvitationModal>
		</>
	);
};
