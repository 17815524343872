import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiDialogTitle-root': {
			padding: '38px 49px 20px 49px'
		},
		'& .MuiDialogContent-root': {
			padding: '8px 44px 32px',
			overflowY: 'initial'
		},
		'& .MuiPaper-root': {
			backgroundColor: '#F8F8F8'
		},
		'& .MuiAlert-standardError': {
			backgroundColor: 'rgb(255, 236, 236)'
		},
		'& .MuiDialog-container': {
			backdropFilter: 'blur(3px)'
		}
	},
	alert: { marginBottom: 10 },
	iconWrapper: {
		position: 'absolute',
		top: 24,
		right: 12,
		transform: 'translateY(-25%)'
	},
	closeButton: {
		color: theme.palette.primary.main
	},
	iconClose: {
		width: 20
	}
}));
