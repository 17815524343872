import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		borderRight: `1px solid ${theme.palette.border.light}`,
		display: 'flex',
		alignItems: 'center'
	},
	type: {
		minWidth: 90,
		fontSize: '16px',
		lineHeight: '16px',
		marginBottom: 25
	}
}));
