export const capitalize = (text) => {
	if (!text) return '';
	const str = text.toLowerCase();
	return str.charAt(0).toUpperCase() + str.slice(1);
};

export const replaceAll = (string, mapObj) => {
	const regex = new RegExp(Object.keys(mapObj).join('|'), 'gi');
	const newString = string.replace(regex, (matched) => mapObj[matched]);
	return newString;
};
