import { Box, TextField } from '@material-ui/core';
import { SendIcon } from 'assets/icons/commonMUIIcons';
import { WithInfoTipTooltip } from 'components/elements/hoc';
import { useTranslations } from 'components/utilities';
import { bool } from 'prop-types';
import React, { useMemo } from 'react';

import { Button } from '../../../elements/atoms';
import { useStyles } from './styles';
import useMessageTextField from './useMessageTextField';

const MessageTextField = ({ isAvailable }) => {
	const { t } = useTranslations();

	const classes = useStyles();

	const { onTextChange, onEnterPress, onSendMessage, message } =
		useMessageTextField();

	const Wrapper = useMemo(
		() => (isAvailable ? ({ children }) => children : WithInfoTipTooltip),
		[isAvailable]
	);

	return (
		<Box className={classes.root}>
			<Wrapper
				className={classes.infoTipTooltipContainer}
				label={'chat.unavailable_conversation'}
			>
				<TextField
					disabled={!isAvailable}
					className={classes.input}
					type="text"
					name="message"
					label={t('chat.sidebar.search_type_message')}
					margin="dense"
					variant="outlined"
					value={message}
					onKeyDown={onEnterPress}
					onChange={onTextChange}
				/>
				<Button
					disabled={!isAvailable}
					onClick={onSendMessage}
					variant="contained"
					color="primary"
					endIcon={<SendIcon />}
				>
					{t('btn.send')}
				</Button>
			</Wrapper>
		</Box>
	);
};

MessageTextField.propTypes = {
	isAvailable: bool
};

export default MessageTextField;
