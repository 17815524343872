import { ParameterGroup } from './classes/ParameterGroup';

const useActions = ({ setActiveParameterGroup }) => {
	const handleSetActiveParameterGroup = (type) => {
		setActiveParameterGroup(new ParameterGroup(type));
	};
	return { setActiveParameterGroup: handleSetActiveParameterGroup };
};

export default useActions;
