import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		'& .MuiInputLabel-outlined': {
			transform: 'translate(14px, 12px) scale(1)'
		},
		'& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
			transform: 'translate(14px, -6px) scale(0.75)'
		}
	},
	selectLabel: {
		backgroundColor: 'white'
	},
	spacer: {
		flex: 1
	},
	menuItem: {
		borderBottom: 'none !important',
		width: '100%',
		paddingLeft: 0
	},
	checkBox: {
		padding: '0px 5px'
	}
}));
