import { ExaminationsListConvertedItemModel } from 'models/Examinations/ExaminationsList';
import { ExaminationsListServicesV2 } from 'queries';

export const findExaminationInList = (
	examinationsList: ExaminationsListConvertedItemModel[],
	examinationType: ExaminationType,
	examinationId: number | string
): { data: ExaminationsListConvertedItemModel | null; index: number } => {
	const index = ExaminationsListServicesV2.getExaminationIndexFromList({
		examinationsList,
		id: examinationId?.toString(),
		type: examinationType
	});
	if (index > -1) {
		return { data: examinationsList[index], index };
	}
	return { data: null, index };
};
