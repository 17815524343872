import { Grid } from '@material-ui/core';
import {
	GendersField,
	KeyboardDatePickerField,
	PasswordFormField
} from 'components/elements/molecules';
import { useTranslations } from 'components/utilities';
import { EMPLOYEES_INVITATION } from 'helpers/Forms/fieldNames/employeesInvitation';
import { array, bool, func, string } from 'prop-types';
import React from 'react';
import { Field, useForm } from 'react-final-form';
import { useSelector } from 'react-redux';

import CountryField from './CountryField';
import { fields } from './helpers';
import PhonePrefixField from './PhonePrefixField';
import SpecializationField from './SpecializationField';
import { useStyles } from './styles';
import useFields from './useFields';

const Fields = ({
	data,
	disabledFields = [],
	translationKeyBase,
	disableUnknownGender,
	fieldSettings
}) => {
	const { t, tNoParser } = useTranslations();
	const { lng } = useSelector((state) => state.language);
	const classes = useStyles({ lng });
	const { change } = useForm();

	const handleToSCheckboxChange = React.useCallback((e) => {
		const fieldsToBeUpdated = [
			EMPLOYEES_INVITATION.PRIVACY_POLICY,
			EMPLOYEES_INVITATION.CONSENT_PERSONAL_DATA
		];
		fieldsToBeUpdated.forEach((fieldName) => {
			change(fieldName, e.target.checked);
		});
	}, []);

	const { submitting, password } = useFields();

	const [countries, genders, specializations] = data;

	const link = ({ url, linkText }) => `<a
			class=${classes.link}
			rel="noopener noreferrer"
			target="_blank"
			href=${tNoParser(url)}
		>${t(linkText)}</a>`;

	const renderField = React.useCallback(
		(fieldProps) => {
			switch (fieldProps.name) {
				case EMPLOYEES_INVITATION.GENDER:
					return (
						<GendersField
							genders={genders}
							label={fieldProps.label}
							submitting={submitting}
							disableUnknownGender={disableUnknownGender}
							required
							{...fieldProps}
						/>
					);
				case EMPLOYEES_INVITATION.DOB:
					return (
						<KeyboardDatePickerField
							{...fieldProps}
							openTo="year"
							submitting={submitting}
							required
						/>
					);
				case EMPLOYEES_INVITATION.PHONE_PREFIX:
					return (
						<PhonePrefixField options={countries} {...fieldProps} />
					);
				case EMPLOYEES_INVITATION.COUNTRY_CODE:
					return <CountryField options={countries} {...fieldProps} />;

				case EMPLOYEES_INVITATION.SPECIALIZATION_ID:
					return (
						<SpecializationField
							options={specializations}
							disabled={submitting}
							{...fieldProps}
							label={t(fieldProps.label)}
							autoComplete={'false'}
							fullWidth
						/>
					);
				case EMPLOYEES_INVITATION.PASSWORD:
				case EMPLOYEES_INVITATION.PASSWORD_CONFIRMATION:
					return <PasswordFormField {...fieldProps} />;

				case EMPLOYEES_INVITATION.TERMS_CONDITIONS:
				case EMPLOYEES_INVITATION.PRIVACY_POLICY:
				case EMPLOYEES_INVITATION.MARKETING_AGREEMENT:
					const { linkText, subLabel, url, required, ...rest } =
						fieldProps;
					const handleChange =
						fieldProps.name ===
						EMPLOYEES_INVITATION.MARKETING_AGREEMENT
							? () => {}
							: handleToSCheckboxChange;
					return (
						<Field
							{...rest}
							onChange={handleChange}
							label={{
								key: fieldProps.label,
								replaceKeys: {
									'%1': link({
										linkText,
										url
									})
								}
							}}
							subLabel={fieldProps?.subLabel && `${t(subLabel)}}`}
							autoComplete="false"
							disabled={submitting}
							required={required}
						/>
					);

				default:
					return (
						<Field
							{...fieldProps}
							label={t(fieldProps.label)}
							fullWidth
							autoComplete="false"
							disabled={submitting}
							required
						/>
					);
			}
		},
		//eslint-disable-next-line
		[submitting, password]
	);

	return (
		<Grid container spacing={2}>
			{fields(translationKeyBase, fieldSettings).map(
				({ grid, ...fieldProps }) =>
					!disabledFields.includes(fieldProps.name) ? (
						<Grid key={fieldProps.name} item {...grid}>
							{renderField(fieldProps)}
						</Grid>
					) : null
			)}
		</Grid>
	);
};

Fields.propTypes = {
	checkComponentRole: func.isRequired,
	data: array.isRequired,
	disabledFields: array,
	fieldSettings: array,
	translationKeyBase: string.isRequired,
	disableUnknownGender: bool
};

export default Fields;
