import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center',
		padding: 16
	},
	divider: {
		opacity: '0.3',
		background: theme.palette.gray.medium,
		width: 1,
		height: 30,
		margin: '0 16px'
	},
	title: { fontSize: 15, textTransform: 'none' }
}));
