import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		flexDirection: 'column',
		gap: 25,
		padding: 0,
		paddingTop: 20,
		width: '100%',
		justifyContent: 'flex-start',
		'&.MuiDialogActions-spacing > :not(:first-child)': {
			marginLeft: 0
		}
	},
	button: {
		width: '100%'
	}
}));
