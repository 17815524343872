import { makeStyles } from '@material-ui/styles';
import palette from 'theme/palette';

const useStyles = makeStyles(() => ({
	popoverPaper: {
		marginTop: 0
	},
	menuItems: {
		maxHeight: 204,
		'& .MuiMenuItem-root': {
			borderBottom: `1px solid ${palette.border.light}`
		},

		'& > :last-child': {
			borderBottom: 'none'
		}
	}
}));

const useSelectProps = () => {
	const classes = useStyles();
	return {
		getContentAnchorEl: null,
		anchorOrigin: {
			vertical: 'bottom',
			horizontal: 'center'
		},
		transformOrigin: {
			vertical: 'top',
			horizontal: 'center'
		},
		PopoverClasses: {
			paper: classes.popoverPaper
		},
		MenuListProps: {
			disablePadding: true,
			className: classes.menuItems
		}
	} as const;
};

export default useSelectProps;
