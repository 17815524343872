import { PulseOximetryPreviewModel } from 'models/Examinations/allExaminationsTypes';

import {
	CommonParametersDirector,
	HrValueBuilder,
	SpO2ValueBuilder
} from '../Parameters';

export class PulseOximetry {
	private hr: CommonExaminationParameter;
	private spO2: CommonExaminationParameter;
	constructor(data: PulseOximetryPreviewModel) {
		this.hr = data.hr;
		this.spO2 = data.spO2;
	}

	protected getHrValues = () => {
		const director = new CommonParametersDirector(this.hr);
		const hrValueBuilder = new HrValueBuilder();
		director.createHrValue(hrValueBuilder);
		return hrValueBuilder.getValue();
	};

	protected getSpO2Values = () => {
		const director = new CommonParametersDirector(this.spO2);
		const spO2ValueBuilder = new SpO2ValueBuilder();
		director.createSpO2Value(spO2ValueBuilder);
		return spO2ValueBuilder.getValue();
	};
}
