import { Box } from '@material-ui/core';
import {
	ContainerWithBorder,
	Typography
} from 'components/elements/atoms/ExaminationsV2';
import { useTranslations } from 'components/utilities';
import React from 'react';

import { LEGEND_ITEMS } from './helpers';
import { useStyles } from './styles';

export const Legend = () => {
	const classes = useStyles();
	const { t } = useTranslations();
	return (
		<Box className={classes.legendContainer}>
			<Typography>{t('report.bronchodilator.legend')}</Typography>
			{LEGEND_ITEMS.map(({ label, lineColor }) => (
				<ContainerWithBorder key={label} className={classes.legendItem}>
					<Typography>{`${t(label)}:`}</Typography>
					<Box className={classes.legendLine} bgcolor={lineColor} />
				</ContainerWithBorder>
			))}
		</Box>
	);
};
