export const EMPLOYEES_INVITATION = {
	GENDER: 'gender',
	FIRST_NAME: 'first_name',
	LAST_NAME: 'last_name',
	EMAIL: 'email',
	DOB: 'birth_date',
	PHONE_PREFIX: 'phone_prefix',
	PHONE_NUMBER: 'phone',
	COUNTRY_CODE: 'countryCode',
	CITY: 'city',
	SPECIALIZATION_ID: 'specialization_id',
	SPECIALIZATION_NAME: 'specialization',
	PASSWORD: 'password',
	PASSWORD_CONFIRMATION: 'password_confirmation',
	TERMS_CONDITIONS: 'terms_conditions',
	MARKETING_AGREEMENT: 'marketingAgreement',
	PRIVACY_POLICY: 'privacy_policy',
	CONSENT_PERSONAL_DATA: 'consent_personal_data'
};
