import { Menu, MenuItem, TextField } from '@material-ui/core';
import clsx from 'clsx';
import { useSelectProps } from 'components/utilities';
import { bool, func, node, number, object, string } from 'prop-types';
import React from 'react';

import InputAdornment from './InputAdornment';
import { useStyles } from './styles';
import TimeInputMask from './TimeInputMask';
import useTimeFieldAdapter from './useTimeFieldAdapter';

const TimeFieldAdapter = ({
	input,
	meta,
	helperText = '',
	inputOnChange,
	disabled,
	icon,
	ampm = true,
	minutesStep = 15,
	classNameTextFieldInput = '',
	classNameTextField = '',
	...rest
}) => {
	const {
		menuTimeArray,
		fieldWidth,
		value,
		menuItemSelectedIndex,
		anchorEl,
		error,
		errorText,
		ref,
		change,
		open,
		close,
		setTime
	} = useTimeFieldAdapter({
		input,
		inputOnChange,
		ampm,
		meta,
		minutesStep
	});

	const classes = useStyles({ fieldWidth });
	const selectProps = useSelectProps();

	return (
		<>
			<TextField
				className={classNameTextField}
				style={{ paddingRight: 0 }}
				ref={ref}
				{...input}
				{...rest}
				value={value}
				onChange={change}
				error={error}
				helperText={errorText || helperText}
				disabled={disabled}
				InputProps={{
					inputComponent: TimeInputMask,
					className: clsx([
						classes.textFieldInput,
						classNameTextFieldInput
					]),
					endAdornment: icon && (
						<InputAdornment open={open} disabled={disabled}>
							{icon}
						</InputAdornment>
					)
				}}
			/>
			<Menu
				keepMounted
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={close}
				{...selectProps}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
				transformOrigin={{ vertical: 'top', horizontal: 'left' }}
			>
				{menuTimeArray.map((time, index) => (
					<MenuItem
						key={time}
						className={classes.menuItem}
						selected={menuItemSelectedIndex === index}
						onClick={() => setTime(time)}
					>
						{time}
					</MenuItem>
				))}
			</Menu>
		</>
	);
};

TimeFieldAdapter.propTypes = {
	input: object.isRequired,
	meta: object.isRequired,
	inputOnChange: func,
	helperText: string,
	classNameTextFieldInput: string,
	classNameTextField: string,
	icon: node.isRequired,
	ampm: bool,
	disabled: bool,
	minutesStep: number
};

export default TimeFieldAdapter;
