import { EMPLOYEES_INVITATION } from 'helpers/Forms/fieldNames';

export const FIELD_SPECIALIZATION_NAME = 'specialization';
export const FIELD_SPECIALIZATION_ID = 'specialization_id';
export const FIELD_PHONE_PREFIX_NAME = 'phone_prefix';
export const FIELD_COUNTRY_CODE = 'countryCode';
export const GEOLOCATION_COUNTRY_FIELD_NAME = 'name';
export const FIELD_COUNTRY = 'city';

export const INITIAL_DOCTOR_SIGNUP_STATE = {
	gender: '',
	first_name: '',
	last_name: '',
	email: '',
	birth_date: null,
	[FIELD_PHONE_PREFIX_NAME]: '+48',
	phone: '',
	[FIELD_SPECIALIZATION_ID]: null,
	password: '',
	password_confirmation: '',
	terms_conditions: false,
	privacy_policy: false,
	consent_personal_data: false,
	[EMPLOYEES_INVITATION.MARKETING_AGREEMENT]: false
};

export const DISABLED_FIELDS = [FIELD_COUNTRY_CODE, FIELD_COUNTRY];

export const FIELDS_SETTINGS = [
	{
		name: 'gender',
		label: 'settings.user.gender'
	}
];
