import { Loader } from 'components/elements/molecules';
import { Notifications } from 'components/templates/Notifications';
import { node } from 'prop-types';
import React from 'react';

import useDashboardTemplate from './useDashboardTemplate';

const DashboardTemplate = ({ children }) => {
	const { isSuccess, isLngLoaded } = useDashboardTemplate();

	return isSuccess && isLngLoaded ? (
		<>
			{children}
			<Notifications />
		</>
	) : (
		<Loader />
	);
};

DashboardTemplate.propTypes = {
	children: node
};

export default DashboardTemplate;
