import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	card: {
		overflow: 'initial',
		position: 'sticky',
		top: 100
	},
	button: {
		'& .MuiIconButton-label': {
			color: theme.palette.primary.main
		}
	},
	radioLabel: {
		fontWeight: 400,
		color: theme.palette.black,
		fontSize: '12px'
	}
}));
