import { useSelector } from 'react-redux';

const useMetricSystem = () => {
	const { metrics } = useSelector((store) => store.metrics);
	const { separator } = metrics;
	const handleSetMetric = (value) => String(value).replace(/\./g, separator);
	return {
		numberSeparator: handleSetMetric
	};
};

export default useMetricSystem;
