import { Point } from 'types/Examinations/trendChart';

import { Tooltip } from '../../../../views/PatientsDetails/views/ExaminationsV2/TrendChart/ExaminationsCharts/Chart/components/classes/Tooltip';
import { useExaminationsV2RefsContext } from '../ExaminationsV2';

export const useActions = (setStore) => {
	const { store: trendChartRef } = useExaminationsV2RefsContext(
		(store) => store.trendChartRef
	);

	const setTooltip = (tooltip: Tooltip) => {
		setStore({ examinationTooltip: tooltip });
	};

	const activateTooltip = () => {
		setStore((store) => {
			const tooltip = new Tooltip(store.examinationTooltip);
			tooltip.setActive(true);
			return { examinationTooltip: tooltip };
		});
	};
	const deactivateTooltip = () => {
		setStore((store) => {
			const tooltip = new Tooltip(store.examinationTooltip);
			tooltip.setActive(false);
			tooltip.disable();
			return { examinationTooltip: tooltip };
		});
	};

	const showTooltip = (markerTarget: Point): Tooltip => {
		const tooltip = new Tooltip();
		tooltip.setExaminationType(markerTarget);
		tooltip.setExaminationId(markerTarget);
		tooltip.setTooltipDimensions(markerTarget);
		tooltip.setAbsolutePosition(
			markerTarget,
			trendChartRef?.current?.container?.current
		);
		tooltip.enable();

		setStore({ examinationTooltip: tooltip });
		return tooltip;
	};

	const hideTooltip = () => {
		setStore((store) => {
			if (store.examinationTooltip.active) {
				return { examinationTooltip: store.examinationTooltip };
			}
			const tooltip = new Tooltip(store.examinationTooltip);
			tooltip.disable();
			return { examinationTooltip: tooltip };
		});
	};

	const setCorrespondingExaminationsListIndex = (
		listIndex: number,
		forceRerender = true
	) => {
		setStore((store) => {
			const tooltip = new Tooltip(store.examinationTooltip);
			tooltip.setExaminationListItemIndex(listIndex);
			return { examinationTooltip: tooltip };
		}, forceRerender);
	};

	return {
		showTooltip,
		setTooltip,
		activateTooltip,
		deactivateTooltip,
		hideTooltip,
		setCorrespondingExaminationsListIndex
	};
};
