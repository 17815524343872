import { Box, Typography as MuiTypography } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslations } from 'components/utilities';
import { bool, string } from 'prop-types';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import { useStyles } from './styles';

const Type = ({ name = null, showType = false, classNameType = '' }) => {
	const { t } = useTranslations();
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<MuiTypography
				data-testid="ptag"
				className={clsx([classNameType, classes.type])}
				variant="h4"
				component="p"
			>
				{showType ? ReactHtmlParser(name) : t(name)}
			</MuiTypography>
		</Box>
	);
};

Type.propTypes = {
	name: string,
	showType: bool,
	classNameType: string
};

export default Type;
