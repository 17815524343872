import { API_URL_PREFIX } from './helpers';
import { TOKEN_QUERY_PARAM_KEY } from './queryParamKeys';

//  Find users by email
export const FIND_USER_BY_EMAIL_API = `${API_URL_PREFIX}/users/find-by-email`;

export const ACCOUNT_DELETION_AVAILABILITY_API = `${API_URL_PREFIX}/account/delete/available`;

export const ACCOUNT_DELETION_CREDENTIALS_CONFIRMATiON_API = `${API_URL_PREFIX}/account/delete/confirm-credentials`;

export const ACCOUNT_DELETION_CONFIRMATION = `${API_URL_PREFIX}/account/delete/confirm`;

export const ACCOUNT_DELETION_DELETE = `${API_URL_PREFIX}/account/delete/${TOKEN_QUERY_PARAM_KEY}`;
export const ACCOUNT_DELETION_DELETE_QUERY_KEY =
	'ACCOUNT_DELETION_DELETE_QUERY_KEY';
