import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	loaderTransitioned: {
		transition: 'all 150ms ease-in-out'
	},
	absolute: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		height: '100%',
		transform: 'translate(-50%, -50%)',
		zIndex: 99999,
		width: '100%',
		backgroundColor: 'white',
		opacity: 0.8
	}
}));
