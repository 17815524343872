import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	cardContent: {
		padding: '41px 38px 71px 38px',

		'&:last-child': {
			paddingBottom: 71
		}
	}
}));
