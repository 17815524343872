import { EXAMINATION_CRITERIA } from 'helpers/charts/measurement/common';

import { Criteria } from './TechnicalErrors';

const { ATS2019, ATS2005 } = EXAMINATION_CRITERIA;

const ERRORS = {
	ET: 'eterror'
};

const DISABLED_ERRORS = { [ATS2019]: [ERRORS.ET], [ATS2005]: [] };

export const filterErrors = (
	errors: string[] | null,
	criteria: Criteria = ATS2019
): string[] => {
	if (!errors) {
		return [];
	}
	return errors.filter(
		(err, index) =>
			!DISABLED_ERRORS?.[criteria]?.includes(
				err?.toLowerCase() as never
			) && errors.indexOf(err) === index
	);
};
