import { makeStyles } from '@material-ui/styles';

import { FONT } from '../../components/styles';

export const useLocalTheme = makeStyles(() => ({
	valueLabel: {
		...FONT,
		fontSize: '12px'
	},
	row: {
		height: 22,
		'& > :first-child': {
			width: 80
		}
	},
	headerLabel: {
		...FONT,
		fontSize: '11px'
	}
}));
