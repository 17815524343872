import { DialogContent, Grid } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslations } from 'components/utilities';
import {
	array,
	arrayOf,
	bool,
	func,
	node,
	number,
	object,
	oneOfType,
	string
} from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';

import { Dialog as MuiDialog } from '../../atoms';
import { Alert } from '../Alerts';
import * as Dialog from './Dialog';
import { useStyles } from './styles';

const FormDialog = ({
	title = '',
	titleVariant = 'h5',
	open,
	close = () => {},
	resetMutationState = () => {},
	onSubmit,
	initialValues,
	validation,
	children,
	fullWidth = true,
	disableBackdropClick = false,
	maxWidth = 'sm',
	actionControllers: Controllers,
	disableActions = false,
	classes,
	contentSpacing = 1,
	error = null,
	isFormDialog = true,
	scrollOption = 'body',
	submitLoaderColor,
	isSubmitLoading = false,
	customRef,
	apiError = '',
	keepMounted = true,
	alignChildren = 'center',
	actionButtonSubmitText,
	apiFieldErrorTranslationKeyPrefix = ''
}) => {
	const formRef = React.useRef();
	const classNames = useStyles();
	const { t } = useTranslations();

	const message = error?.response?.data?.message ?? apiError;

	const handleResetForm = () =>
		formRef.current && formRef.current.reset(initialValues);

	const handleClose = () => {
		close();
		resetMutationState();
		handleResetForm();
	};

	const handleOnSubmit = (handleSubmit) => async (event) => {
		const error = await handleSubmit(event);
		if (error) return error;
		handleResetForm();
	};

	const renderTitle = () => (
		<Dialog.Title
			id="title"
			variant={titleVariant}
			className={classes?.title || ''}
		>
			{t(title)}
		</Dialog.Title>
	);

	const renderAlert = () => (
		<Grid item xs={12}>
			<Alert
				className={classNames.alert}
				message={message}
				apiFieldErrorTranslationKeyPrefix={
					apiFieldErrorTranslationKeyPrefix
				}
			/>
		</Grid>
	);

	const renderController = (submitting) =>
		!disableActions &&
		(Controllers ? (
			<Controllers
				close={handleClose}
				submitting={isSubmitLoading || submitting}
			/>
		) : (
			<Dialog.Actions
				submitting={isSubmitLoading || submitting}
				close={handleClose}
				loaderColor={submitLoaderColor}
				submitText={actionButtonSubmitText}
			/>
		));

	return (
		<MuiDialog
			className={clsx([classNames.root, classes && classes.root])}
			disableBackdropClick={disableBackdropClick}
			keepMounted={keepMounted}
			fullWidth={fullWidth}
			maxWidth={maxWidth}
			open={open}
			scroll={scrollOption}
			onClose={close}
			aria-labelledby="form-dialog"
		>
			{title && (
				<Dialog.CloseBtn classNames={classNames} close={handleClose} />
			)}

			{isFormDialog ? (
				<Form
					onSubmit={onSubmit}
					initialValues={initialValues}
					validate={validation}
					render={({ handleSubmit, submitting, ...rest }) => (
						<>
							{title && renderTitle()}

							<DialogContent
								className={classes && classes.dialogContent}
							>
								<form
									noValidate
									onSubmit={handleOnSubmit(handleSubmit)}
									ref={customRef || formRef}
								>
									<Grid
										alignItems={alignChildren}
										container
										spacing={contentSpacing}
									>
										{message && renderAlert()}
										{children({
											...rest,
											submitting,
											formRef
										})}
									</Grid>
									{renderController(submitting)}
								</form>
							</DialogContent>
						</>
					)}
				/>
			) : (
				<>
					{title && renderTitle}
					<DialogContent className={classes && classes.dialogContent}>
						<Grid
							alignItems="center"
							container
							spacing={contentSpacing}
						>
							{message && renderAlert}
							{children}
						</Grid>
					</DialogContent>
				</>
			)}
		</MuiDialog>
	);
};

FormDialog.propTypes = {
	title: string,
	scrollOption: string,
	maxWidth: string,
	open: bool.isRequired,
	isFormDialog: bool,
	disableActions: bool,
	actionControllers: func,
	fullWidth: bool,
	disableBackdropClick: bool,
	isSubmitLoading: bool,
	isSubmitError: bool,
	close: func.isRequired,
	initialValues: object,
	validation: func,
	onSubmit: func,
	resetMutationState: func,
	children: oneOfType([arrayOf(node), node, func]).isRequired,
	classes: object,
	titleVariant: string,
	submitLoaderColor: string,
	contentSpacing: number,
	error: object,
	customRef: object,
	keepMounted: bool,
	apiError: oneOfType([string, array]),
	alignChildren: string,
	actionButtonSubmitText: string,
	apiFieldErrorTranslationKeyPrefix: string
};

export default FormDialog;
