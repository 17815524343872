import { makeStyles } from '@material-ui/styles';
import { SIZES } from 'helpers/variables';

const { SMALL, NORMAL } = SIZES;

export const useStyles = makeStyles((theme) => ({
	typography: {
		fontSize: ({ size }) => {
			switch (size) {
				case SMALL:
					return '12px';
				case NORMAL:
					return '15px';
				default:
					return '12px';
			}
		},
		color: theme.palette.black,
		opacity: 0.5,
		fontWeight: 400
	},
	marginNone: {
		marginBottom: 0
	}
}));
