import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: { height: '100%' },
	list: { padding: '0', height: '100%' },
	button: {
		display: 'inline-block',
		[theme.breakpoints.down(1362)]: {
			fontSize: 12
		},
		'& > span': {
			display: 'inline-block'
		},
		'& p': {
			display: 'inline-block',
			verticalAlign: 'middle',
			lineHeight: '14px',
			paddingLeft: 10,
			color: theme.palette.gray.medium,
			textTransform: 'none',
			fontWeight: 400,
			fontSize: 16
		},
		'& .MuiButton-startIcon': {
			lineHeight: 'normal',
			verticalAlign: 'middle'
		},
		'& svg': {
			margin: 'auto',
			'& path': {
				fill: `${theme.palette.gray.medium} !important`
			},
			'& circle': {
				fill: `${theme.palette.gray.medium} !important`
			}
		}
	},
	btnIcon: {
		display: 'inline-block',
		verticalAlign: 'middle'
	},
	item: {
		'& .MuiButton-text': {
			padding: '14px 10px',
			width: '100%',
			height: '100%',
			borderRadius: 0
		},
		'& .MuiButton-root:hover': {
			backgroundColor: 'rgb(236 236 236 / 29%)'
		}
	},
	span: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	divider: {
		height: '100%',
		background: theme.palette.border.custom,
		width: 2
	},
	sectionDivider: {
		height: '40%'
	},
	active: {
		'& p': {
			color: theme.palette.primary.main
		},

		'& svg': {
			'& path': {
				fill: `${theme.palette.primary.main} !important`
			},
			'& circle': {
				fill: `${theme.palette.primary.main} !important`
			}
		}
	}
}));
