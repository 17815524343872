import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		justifyContent: 'space-between',
		gap: '8px'
	},
	container: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderRadius: 10
	},
	clinicLogoContainer: {
		flex: 1,
		padding: '2px 5px'
	},
	clinicImage: {
		height: '40px',
		width: 'auto'
	},
	examinationType: {
		fontFamily: ['Tiempos', 'Arial', 'sans-serif'].join(','),
		color: '#FFFFFF',
		fontSize: '15.6px',
		fontWeight: 400,
		lineHeight: '19.5px'
	}
}));
