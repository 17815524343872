import { Typography as MuiTypography } from '@material-ui/core';
import { useTranslations } from 'components/utilities';
import { string } from 'prop-types';
import React from 'react';

import { VALUE_LABEL } from './helpers';
import { useStyles } from './styles';

const ObturationLevelItem = ({ value, labelKey }) => {
	const classes = useStyles();
	const { t } = useTranslations();

	return (
		<MuiTypography variant="body2" className={classes.row}>
			{`${VALUE_LABEL} ${value}`}
			<MuiTypography
				component="span"
				variant="body2"
				className={classes.label}
			>
				&nbsp;
				{`- ${t(
					`patients.examinations.details.bronchodilator.${labelKey}`
				)}`}
			</MuiTypography>
		</MuiTypography>
	);
};

ObturationLevelItem.propTypes = {
	value: string.isRequired,
	labelKey: string.isRequired
};

export default ObturationLevelItem;
