import { IDLE } from 'helpers/variables';
import { ExaminationsListConvertedItemModel } from 'models/Examinations/ExaminationsList';
import {
	useExaminationsListQueryKey,
	useGetExaminationsListFromCache
} from 'queries';
import { QueryStatus, useIsFetching } from 'react-query';

import { findExaminationInList } from './helpers';

type Props = {
	examinationId: number | string;
	examinationType: ExaminationType;
};
type ReturnUseExaminationDataFromSidelist = {
	idle: QueryStatus;
	examinationListItemData: ExaminationsListConvertedItemModel | null;
	apiError: 'error_codes.unknown_error_occurred';
};

const useExaminationDataFromSidelist = ({
	examinationId,
	examinationType
}: Props): ReturnUseExaminationDataFromSidelist => {
	const queryKey = useExaminationsListQueryKey();
	const isFetchingPosts = useIsFetching(queryKey);

	const { examinationsListData: examinationsListDataCache, queryState } =
		useGetExaminationsListFromCache();

	const { data } = examinationsListDataCache
		? findExaminationInList(
				examinationsListDataCache,
				examinationType,
				examinationId
		  )
		: { data: null };

	const idle = isFetchingPosts
		? IDLE.LOADING
		: queryState?.status || IDLE.LOADING;

	return {
		idle,
		examinationListItemData: data,
		apiError: 'error_codes.unknown_error_occurred'
	};
};

export default useExaminationDataFromSidelist;
