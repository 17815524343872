import { MutationOptions, useMutation } from 'components/utilities';

import { AccountRepository } from './repository';

const useDeletionConfirmation = (
	options: MutationOptions<void, TMyError, void> = {}
) => {
	const mutation = useMutation<void, TMyError, void>(
		(_, cancelToken) => AccountRepository.confirmDeletion(_, cancelToken),
		options
	);
	return mutation;
};

export default useDeletionConfirmation;
