import { LoaderSpinner } from 'components/elements';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// Import dash routes
import { SETTINGS_ROUTES } from './helpers';
import { DASH_PATHS } from './paths';

const SettingsRoutes = () => {
	const { 1: redirectPath } = SETTINGS_ROUTES;
	return (
		<React.Suspense fallback={<LoaderSpinner />}>
			<Switch>
				<Redirect
					exact
					from={DASH_PATHS.SETTINGS}
					to={redirectPath.path}
				/>
				{SETTINGS_ROUTES.map((route) => (
					<Route
						key={route.path}
						path={route.path}
						render={(matchProps) => (
							<>
								{route.helmet}
								<route.component {...matchProps} />
							</>
						)}
					/>
				))}

				<Redirect to="/not-found" />
			</Switch>
		</React.Suspense>
	);
};

export default SettingsRoutes;
