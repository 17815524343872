import { makeStyles } from '@material-ui/styles';

import { FONT } from '../styles';

export const useStyles = makeStyles(() => ({
	root: {
		width: '100%',
		alignSelf: 'flex-start',
		paddingTop: 10
	}
}));

export const useDeviceStyles = makeStyles(() => ({
	title: {
		...FONT,
		fontSize: '11px',
		lineHeight: '11px'
	},
	font: {
		...FONT,
		fontSize: '10px',
		lineHeight: '10px'
	},
	fieldsWrapper: {
		'& p': {
			width: 'auto'
		},
		'& > div': {
			justifyContent: 'center',
			marginBottom: 0
		},
		'& > :first-child': {
			justifyContent: 'flex-start'
		},
		'& > :last-child': {
			justifyContent: 'flex-end'
		},
		'& > :nth-child(3n+2)': {
			'& .parameterLabel': {
				width: 'auto'
			}
		}
	}
}));
