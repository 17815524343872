import { func } from 'prop-types';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AllergyForm from '../AllergyForm';
import ConditionForm from '../ConditionForm';
import { TABLE_NAME } from '../helpers/tables_data';
import SurgeryForm from '../SurgeryForm';
import { getFormRoutes } from './helpers';

const { CONDITIONS, SURGERIES, ALLERGIES } = TABLE_NAME;

const FormRoutes = ({ onManualRefetch }) => {
	const {
		ROUTES_CONDITIONS_FORM,
		ROUTES_SURGERIES_FORM,
		ROUTES_ALLERGIES_FORM
	} = getFormRoutes();

	const renderConditionForm = () => (
		<ConditionForm setManualRefetch={() => onManualRefetch(CONDITIONS)} />
	);

	const renderSurgeryForm = () => (
		<SurgeryForm setManualRefetch={() => onManualRefetch(SURGERIES)} />
	);

	const renderAllergyForm = () => (
		<AllergyForm setManualRefetch={() => onManualRefetch(ALLERGIES)} />
	);
	return (
		<Switch>
			<Route
				path={ROUTES_CONDITIONS_FORM}
				component={renderConditionForm}
			/>
			<Route path={ROUTES_SURGERIES_FORM} component={renderSurgeryForm} />
			<Route path={ROUTES_ALLERGIES_FORM} component={renderAllergyForm} />
		</Switch>
	);
};

FormRoutes.propTypes = {
	onManualRefetch: func.isRequired
};

export default FormRoutes;
