import { ContainerWithBorder } from 'components/elements/atoms/ExaminationsV2';
import { InformationSection } from 'components/elements/molecules/ExaminationsV2';
import { useUserRole } from 'components/utilities';
import { ClinicDataModelWithDoctor } from 'models/Clinic/Clinic';
import { DeviceModel } from 'models/Device/Device';
import { PatientModel } from 'models/Patient/PatientModel';
import { CommonReportFieldsModel } from 'models/Report/Reports';
import { useGetCachedReportData } from 'queries/Examinations/ReportsV2/getter';
import React from 'react';
import { DEVICE_FIELDS } from 'utilities/Examinations/ExaminationDetails/helpers';

import { Section } from '../../types';
import { CLINIC_FIELDS, createDoctorName, PATIENT_FIELDS } from './helpers';
import { useStyles } from './styles';

type Props = {
	clinic?: Section;
	patient?: Section;
	device?: Section;
	patientData?: ReturnType<
		typeof useGetCachedReportData<CommonReportFieldsModel>
	>['patient'];
};

export const PatientCard = ({
	clinic,
	patient,
	device,
	patientData: patientDataProp
}: Props) => {
	const {
		patient: patientData,
		examination: examinationData,
		clinic: clinicData,
		doctorUser: doctorUserData
	} = useGetCachedReportData<CommonReportFieldsModel>();

	const { validateRoleComponent } = useUserRole();

	const classes = useStyles();
	return (
		<ContainerWithBorder className={classes.root}>
			{!clinic?.disable ? (
				validateRoleComponent(() => (
					<InformationSection<ClinicDataModelWithDoctor>
						orientation="row"
						title="report.clinic.title"
						fields={CLINIC_FIELDS}
						data={
							{
								...clinicData,
								doctorName: createDoctorName(
									doctorUserData?.first_name,
									doctorUserData?.last_name
								)
							} as unknown as ClinicDataModelWithDoctor
						}
					/>
				))
			) : (
				<></>
			)}
			{!patient?.disable ? (
				<InformationSection<PatientModel['patient']>
					orientation="row"
					title="report.patient.title"
					data={patientDataProp ?? patientData}
					fields={PATIENT_FIELDS}
				/>
			) : (
				<></>
			)}
			{!device?.disable ? (
				<InformationSection<DeviceModel>
					orientation="row"
					title="report.device.title"
					data={examinationData?.device}
					fields={DEVICE_FIELDS}
				/>
			) : (
				<></>
			)}
		</ContainerWithBorder>
	);
};
