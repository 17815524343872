import { Grid } from '@material-ui/core';
import React from 'react';

import CalendarControls from '../CalendarControls';
import DateControls from '../DateControls';
import { useStyles } from './styles';
import useTopBar from './useTopBar';

const TopBar = () => {
	const classes = useStyles();
	const { grid } = useTopBar();
	const { spanColumns, calendarColumns, sidebarColumns } = grid;

	return (
		<Grid container className={classes.root} spacing={2}>
			<Grid item {...spanColumns}>
				&nbsp;
			</Grid>
			<Grid item {...calendarColumns} className={classes.header}>
				<DateControls />
			</Grid>
			<Grid item {...sidebarColumns}>
				<CalendarControls />
			</Grid>
		</Grid>
	);
};

export default TopBar;
