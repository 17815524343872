import { format, Locale } from 'date-fns';
import { DATE_FORMAT, TIME_FORMAT } from 'helpers/variables';
import { AppLanguage } from 'utilities/AppLanguage';

export type TLocaleDate = Date | string | number | null;
export type DateFormat = DeepValuesOf<typeof DATE_FORMAT>;
export type TimeFormat = DeepValuesOf<typeof TIME_FORMAT>;

interface ILocaleDate {
	getDate: () => Date;
	getLocaleTime: (timeFormat?: TimeFormat) => string;
	getStringFormatDate: (dateFormat: DateFormat) => string;
	getDateWithTimeLabel: (
		dateFormat: DateFormat,
		timeFormat: TimeFormat
	) => {
		dateFirst: () => string;
		timeFirst: () => string;
	};
}

export class LocaleDate implements ILocaleDate {
	#date: Date;
	#locale: Locale = new AppLanguage().getLngLocale();
	constructor(_date?: TLocaleDate) {
		this.#date = !_date
			? new Date()
			: _date instanceof Date
			? _date
			: new Date(this.validateDateStringFormat(_date));
	}

	getDate = () => this.#date;

	getLocaleTime = (timeFormat: TimeFormat = TIME_FORMAT.LONG) => {
		return format(this.#date, timeFormat, { locale: this.#locale });
	};

	getStringFormatDate = (dateFormat = DATE_FORMAT.DEFAULT as DateFormat) => {
		return format(this.#date, dateFormat as string, {
			locale: this.#locale
		});
	};

	getDateWithTimeLabel = (
		dateFormat: DateFormat = DATE_FORMAT.DEFAULT,
		timeFormat: TimeFormat = TIME_FORMAT.DEFAULT
	) => {
		const date = this.getStringFormatDate(dateFormat);
		const localeTime = this.getLocaleTime(timeFormat);

		return {
			dateFirst: (separator = ',') => `${date}${separator} ${localeTime}`,
			timeFirst: (separator = ',') => `${localeTime}${separator} ${date}`
		};
	};

	getISOString() {
		const tzo = -this.#date.getTimezoneOffset(),
			dif = tzo >= 0 ? '+' : '-',
			pad = function (num) {
				return (num < 10 ? '0' : '') + num;
			};

		return (
			this.#date.getFullYear() +
			'-' +
			pad(this.#date.getMonth() + 1) +
			'-' +
			pad(this.#date.getDate()) +
			'T' +
			pad(this.#date.getHours()) +
			':' +
			pad(this.#date.getMinutes()) +
			':' +
			pad(this.#date.getSeconds()) +
			dif +
			pad(Math.floor(Math.abs(tzo) / 60)) +
			pad(Math.abs(tzo) % 60)
		);
	}

	private validateDateStringFormat = (
		date: string | number
	): string | number => {
		if (typeof date === 'string') {
			return date
				?.replace('.000000Z', '')
				.replace(/[+]\d\d[:]\d\d/, '')
				.replace(/[+]\d\d\d\d/, '')
				.replace('Z', '');
		}
		return date;
	};
}
