import { Menu } from '@material-ui/core';
import { SETTINGS_NAV_LINKS } from 'helpers/navigation';
import React from 'react';

import { MenuItem } from './components';
import LogoutItem from './components/LogoutItem/LogoutItem';
import { useStyles } from './styles';

//eslint-disable-next-line
const ToggleMenu = ({ anchorEl, close, signOut }) => {
	const classes = useStyles();

	return (
		<>
			<Menu
				className={classes.container}
				keepMounted
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={close}
				getContentAnchorEl={null}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				{SETTINGS_NAV_LINKS.map(({ title, href, ...rest }) => (
					<MenuItem
						key={`${title}-${href}`}
						title={title}
						href={href}
						{...rest}
					/>
				))}
				<LogoutItem signOut={signOut} />
			</Menu>
		</>
	);
};

export default ToggleMenu;
