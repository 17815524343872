import { CHART_MEASUREMENT_PARAMETER_OPTION_NAMES } from 'helpers';

export class SelectedParameters {
	#selectedParameters;
	constructor(currentData) {
		this.#selectedParameters = [...currentData];
	}

	getSelectedParameters() {
		return this.#selectedParameters;
	}

	getSelectedParametersLabels() {
		return Object.values(CHART_MEASUREMENT_PARAMETER_OPTION_NAMES).reduce(
			(acc, parameter) => {
				if (this.#includesParameter(parameter.name)) {
					acc.push({ label: parameter.label, name: parameter.name });
				}
				return acc;
			},
			[]
		);
	}

	toggleParameter(parameter) {
		if (this.#includesParameter(parameter)) {
			this.#removeParameter(parameter);
			return this;
		}
		this.#addParameter(parameter);
		return this;
	}

	#addParameter(parameter) {
		this.#selectedParameters.push(parameter);
	}

	#removeParameter(parameter) {
		this.#selectedParameters = this.#selectedParameters.filter(
			(p) => p !== parameter
		);
	}

	#includesParameter(parameter) {
		return this.#selectedParameters.includes(parameter);
	}
}
