import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	button: {
		minWidth: 50,
		padding: 5
	},
	typography: {
		marginBottom: 5
	},
	dosageWrapper: {
		display: 'flex',
		flexDirection: 'column',
		'& button': {
			maxWidth: 140
		},
		'& > :last-child': {
			marginBottom: 0
		}
	},
	days: {
		paddingTop: theme.spacing(1)
	},
	icon: {
		height: 20,
		minWidth: 20,
		marginLeft: 10,
		marginBottom: 2
	}
}));
