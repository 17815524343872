import { PropTypes } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import theme from 'theme';

export const useStyles = makeStyles<
	typeof theme,
	{ textAlign: PropTypes.Alignment }
>(() => ({
	label: {
		color: '#707070',
		fontWeight: 400,
		textAlign: ({ textAlign }) => textAlign
	}
}));
