import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		cursor: 'pointer',
		marginTop: 4
	},
	label: {
		paddingLeft: 8,
		fontSize: '14px',
		lineHeight: '14px',
		fontWeight: 400,
		verticalAlign: 'middle'
	},
	icon: {
		verticalAlign: 'text-top'
	}
}));
