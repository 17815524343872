import { Box } from '@material-ui/core';
import {
	Language,
	Loader,
	SignInSideBanner
} from 'components/elements/molecules';
import { useInitialLanguageTranslations } from 'components/utilities';
import { node } from 'prop-types';

import Copyrights from './Copyrights';
import { useStyles } from './styles';

const LoginTemplate = ({ children }) => {
	const classes = useStyles();

	const { isLoaded: isLanguageLoaded } = useInitialLanguageTranslations();

	return (
		<Box className={classes.root}>
			{!isLanguageLoaded ? (
				<Loader classNameRoot={classes.loader} />
			) : (
				<Box
					style={{
						minHeight: '100vh',
						display: 'flex'
					}}
				>
					<SignInSideBanner classes={classes} />
					<Box className={classes.contentContainer}>
						<Box className={classes.content}>
							<Language
								classNameRoot={classes.lng}
								isAuthForm={true}
							/>
							<Box className={classes.children}>{children}</Box>
						</Box>
						<Copyrights />
					</Box>
				</Box>
			)}
		</Box>
	);
};

LoginTemplate.propTypes = {
	children: node
};

export default LoginTemplate;
