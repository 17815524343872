import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { array, func,number } from 'prop-types';
import React from 'react';
import { AutoSizer,Grid } from 'react-virtualized';

const useStyles = makeStyles((theme) => ({
	root: { width: '100%', marginTop: theme.spacing(1) },
	grid: {
		overflow: 'hidden !important',
		border: '1px solid #7070703B',
		borderRadius: 6,
		'&:focus': {
			outline: 'none'
		},
		padding: '0 3px'
	}
}));

const ScrollGrid = ({
	results,
	cellRenderer,
	columnWidth,
	height,
	rowHeight,
	...other
}) => {
	const classes = useStyles();
	return (
		<Box className={classes.root}>
			<AutoSizer disableHeight={true}>
				{({ width }) => (
					<Grid
						className={clsx(classes.grid)}
						cellRenderer={cellRenderer}
						columnCount={results[0].length}
						columnWidth={columnWidth}
						height={height}
						rowCount={results.length}
						rowHeight={rowHeight}
						width={width}
						scrollToAlignment="start"
						{...other}
					/>
				)}
			</AutoSizer>
		</Box>
	);
};

ScrollGrid.propTypes = {
	results: array.isRequired,
	cellRenderer: func.isRequired,
	columnWidth: number.isRequired,
	height: number.isRequired,
	rowHeight: number.isRequired
};

export default ScrollGrid;
