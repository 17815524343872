import { Box, IconButton, TextField, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { CloseIcon, SearchIcon } from 'assets/icons/commonMUIIcons';
import clsx from 'clsx';
import { useTranslations } from 'components/utilities';
import { func,string } from 'prop-types';
import React from 'react';

import { useStyles } from './styles';

const Search = ({
	classNameBox = '',
	classNameInput = '',
	classNameTexFiled = '',
	classNameIconSearch = '',
	classNameIcon = '',
	setQuery,
	clearQuery,
	query = '',
	placeholder = '',
	name = 'email'
}) => {
	const theme = useTheme();
	const { t } = useTranslations();

	const isXsDeviceResolution = useMediaQuery(theme.breakpoints.down('xs'), {
		defaultMatches: true
	});

	const classes = useStyles(isXsDeviceResolution);

	return (
		<Box className={clsx(classes.root, classNameBox)}>
			<Box className={clsx(classes.input, classNameInput)}>
				<SearchIcon
					className={clsx(classes.iconSearch, classNameIconSearch)}
				/>
				<TextField
					className={classNameTexFiled}
					value={query || ''}
					onChange={setQuery}
					type="text"
					fullWidth
					size="small"
					label={t(placeholder)}
					name={name}
					variant="outlined"
				/>
				{query && (
					<IconButton
						className={classes.buttonClose}
						onClick={clearQuery}
					>
						<CloseIcon
							className={clsx(classes.iconClose, classNameIcon)}
						/>
					</IconButton>
				)}
			</Box>
		</Box>
	);
};

Search.propTypes = {
	classNameBox: string,
	classNameInput: string,
	classNameTexFiled: string,
	classNameIconSearch: string,
	classNameIcon: string,
	setQuery: func.isRequired,
	clearQuery: func.isRequired,
	query: string,
	name: string,
	placeholder: string
};

export default Search;
