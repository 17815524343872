import { useQuery } from 'components/utilities';
import { QUERY_GENDERS } from 'helpers/api';

import { getFilteredGenders } from './helpers';
import { GendersRepository } from './repository';

const useGenders = (options = {}, { isGendersFiltered = false } = {}) => {
	const query = useQuery(
		QUERY_GENDERS,
		(payload) => GendersRepository.getAll(payload),
		{ cacheTime: 8 * 60 * 60 * 1000, ...options }
	);

	const convertedData =
		query.isSuccess &&
		(isGendersFiltered
			? getFilteredGenders(query?.data)
			: Object.values(query?.data?.data));

	return { ...query, data: { ...query.data, data: convertedData } };
};

export default useGenders;
