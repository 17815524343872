import {
	endOfWeek,
	format,
	lastDayOfMonth,
	startOfMonth,
	startOfWeek} from 'date-fns';

import { CALENDAR_VIEWS_VARIANTS } from '../schedule/calendar';

const { MONTH, WEEK, DAY } = CALENDAR_VIEWS_VARIANTS;
class DateRangeFormatterCreator {
	constructor(startDateFormatter, endDateFormatter) {
		this.startDateFormatter = startDateFormatter;
		this.endDateFormatter = endDateFormatter;
	}
}

const defaultFormat = (date) => date;

const DATE_FORMATTERS = {
	[MONTH]: new DateRangeFormatterCreator(startOfMonth, lastDayOfMonth),
	[WEEK]: new DateRangeFormatterCreator(
		(date) => startOfWeek(date, { weekStartsOn: 1 }),
		(date) => endOfWeek(date, { weekStartsOn: 1 })
	),
	[DAY]: new DateRangeFormatterCreator(defaultFormat, defaultFormat)
};

export const getDateRangeFormatters = (rangeType) => DATE_FORMATTERS[rangeType];

export const formatDateToDateRange = (date, rangeType, formatType) => {
	const { startDateFormatter, endDateFormatter } =
		getDateRangeFormatters(rangeType);

	let first = startDateFormatter(date);
	let last = endDateFormatter(date);

	if (formatType) {
		first = format(first, formatType);
		last = format(last, formatType);
	}

	return {
		first,
		last
	};
};
