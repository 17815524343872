import { makeStyles } from '@material-ui/styles';

import { FONT } from '../styles';

export const useStyles = makeStyles(() => ({
	zScoreRoot: {
		minWidth: 400
	},
	title: {
		...FONT
	},
	zScoreContainer: {
		paddingBottom: 0,
		'& .z-score--row': {
			borderRadius: 10
		}
	}
}));
