import { validateExaminationValue } from 'helpers';

export interface IParameterValuesData {
	value: number;
	unit: string;
	color: string;
	validator: (value: number) => boolean;
	setValue: (
		v: number | string | null,
		validator?: (v: number | null) => number | string | null
	) => void;
	setUnit: (u: string) => void;
	setColor: (c: string) => void;
	setValidator: (validator?: (value: number) => boolean) => void;
}

export class ParameterValuesData implements IParameterValuesData {
	value;
	unit;
	color;
	validator;
	constructor() {}

	setValue: IParameterValuesData['setValue'] = (v, validator = (v) => v) => {
		//convert value to EMPTY_VALUE "-" if v = -0, null, NaN or this.validator() === false
		this.value = validator(validateExaminationValue(v, this.validator));
	};
	setUnit = (u) => {
		this.unit = u;
	};
	setColor = (c) => {
		this.color = c;
	};
	setValidator = (validator) => {
		this.validator = validator;
	};
}
