import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	divider: {
		width: 100,
		backgroundColor: theme.palette.border.light
	},
	dividerContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: 20
	}
}));
