import { APP_NODE_ENV_TYPES } from 'helpers/variables';

export class MyConsole {
	constructor() {}

	static init() {
		this.overrideLog();
	}

	private static overrideLog() {
		// eslint-disable-next-line
		if (process.env.REACT_APP_NODE_ENV !== APP_NODE_ENV_TYPES.DEVELOPMENT) {
			// eslint-disable-next-line
			console.log = () => {};
		}
	}
}
