import { CancelToken } from 'axios';
import {
	GET_SETTINGS_LIST,
	PATCH_SETTING_EDIT,
	UPDATE_MARKETING_AGREEMENT
} from 'helpers';
import {
	MarketingAgreementRequestPayload,
	MarketingAgreementRequestPayloadSchema,
	MarketingAgreementResponseSchema
} from 'models/Settings/MarketingAgreement';
import { UserSettingModel } from 'models/Settings/Settings';
import { replaceSettingIdQueryParam } from 'queries/common';
import services from 'services/services';
import { servicesV2 } from 'services/servicesV2';
import { z } from 'zod';

import { UserSettingValue } from '../../models/Settings/Settings';

type GetSettingsList = ({
	cancelToken
}: {
	cancelToken: CancelToken;
}) => Promise<UserSettingModel[]>;

type UpdateValueByID = ({
	settingId,
	newValue
}: {
	settingId: UserSettingModel['id'];
	newValue: UserSettingValue;
}) => Promise<{ value: UserSettingModel['value'] }>;

export class SettingsRepository {
	constructor() {}

	static updateMarketingAgreement(payload: MarketingAgreementRequestPayload) {
		return servicesV2.put(
			UPDATE_MARKETING_AGREEMENT,
			{
				requestSchema: MarketingAgreementRequestPayloadSchema,
				responseSchema: MarketingAgreementResponseSchema
			},
			payload
		);
	}

	static getSettingsList: GetSettingsList = ({ cancelToken }) =>
		servicesV2.get(GET_SETTINGS_LIST, {
			cancelToken,
			requestSchema: z.undefined(),
			responseSchema: z.any()
		});

	static updateValueByID: UpdateValueByID = async ({
		settingId,
		newValue
	}) => {
		const url = replaceSettingIdQueryParam(PATCH_SETTING_EDIT, settingId);
		const payload = { value: [newValue] };
		await services.patch(url, payload);
		return payload;
	};
}
