import { Box, IconButton, Typography } from '@material-ui/core';
import { ReactComponent as BackIcon } from 'assets/icons/ic_back.svg';
import { RouterLink } from 'components/elements/atoms';
import { APP_PATHS } from 'components/routes/paths';
import { useTranslations } from 'components/utilities';
import { PANEL_ROLES } from 'helpers';
import { bool, object, string } from 'prop-types';
import React from 'react';

import { useStyles } from './styles';

const { SIGN_IN } = APP_PATHS;

const TopHeader = ({ isInvitation, invitation, role }) => {
	const { t } = useTranslations();

	const classes = useStyles();

	const renderHeader = () => {
		if (isInvitation) {
			const {
				invitation: { clinic_name }
			} = invitation;
			return (
				<>
					<Typography className={classes.title} variant="h2">
						{t('sign_up.doctor.welcome_on_board')}
					</Typography>
					<Typography
						component="p"
						className={classes.subtitle}
						variant="h4"
					>
						{t('sign_up.doctor.invite')} {clinic_name}.
						{t('sign_up.doctor.fill_the_form')} {clinic_name}.
					</Typography>
				</>
			);
		}

		return (
			<Typography className={classes.title} variant="h2">
				{t(`sign_up.${role}.create_account_title`)}
			</Typography>
		);
	};

	return (
		<Box className={classes.titleWrapper}>
			{role === PANEL_ROLES.doctor && (
				<IconButton component={RouterLink} to={SIGN_IN}>
					<BackIcon />
				</IconButton>
			)}
			{renderHeader()}
		</Box>
	);
};

TopHeader.propTypes = {
	role: string.isRequired,
	invitation: object,
	isInvitation: bool
};

export default TopHeader;
