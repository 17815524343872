import { Divider } from 'components/elements';
import { Container } from 'components/elements/atoms/ExaminationsV2';
import React from 'react';

import { PageSize } from '../../components/atoms';
import { PageLayout } from '../../components/layouts';
import { PatientCard } from '../../components/organisms';
import { CheckTable } from '../CheckTable';
import { SubHeader } from '../SubHeader';
import { useGetCachedCalibrationCheckReportData } from '../useGetCachedCalibrationCheckReportData';
import { VerificationHistory } from '../VerificationHistory';
import { VerificationResult } from '../VerificationResult';
import { useStyles } from './styles';

export const FirstPage = () => {
	const classes = useStyles();
	const { examination } = useGetCachedCalibrationCheckReportData();
	return (
		<PageSize>
			<PageLayout
				title={'report.type.calibration_check'}
				subHeader={{ component: SubHeader }}
				footerProps={{
					pageCount: 1,
					pages: 1
				}}
			>
				<PatientCard patient={{ disable: true }} />
				<CheckTable
					//eslint-disable-next-line
					checkType={examination!.expiratory}
					title="report.calibration_check.expiratory_check"
				/>
				{/* <CheckTable
					//eslint-disable-next-line
					checkType={examination!.inspiratory}
					title="report.calibration_check.inspiratory_check"
				/> */}
				<VerificationResult />

				{examination?.verification.history?.length &&
				examination?.verification.history?.length > 0 ? (
					<>
						<Container className={classes.dividerContainer}>
							<Divider className={classes.divider} />
						</Container>
						<VerificationHistory />
					</>
				) : null}
			</PageLayout>
		</PageSize>
	);
};
