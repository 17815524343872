import { CHART_MEASUREMENT_PARAMETER_OPTION_NAMES } from 'helpers';
import { ReportParameterModel } from 'models/Report/common';
import { SlowVitalCapacitySVCBestParametersModel } from 'models/Report/SlowVitalCapacitySVC';
import { ColumnDataImpl } from 'utilities/Examinations/ExaminationDetails/common';
import { SVC_ALL_PARAMETERS } from 'utilities/Examinations/ExaminationDetails/helpers';

const PARAMETERS = {
	...SVC_ALL_PARAMETERS,
	ic: {
		...SVC_ALL_PARAMETERS.ic,
		label: CHART_MEASUREMENT_PARAMETER_OPTION_NAMES.ic.secondaryLabel
	} as const
};

export const convertBestResultsToColumnsData = (
	data?: SlowVitalCapacitySVCBestParametersModel
) => {
	if (!data) {
		return [];
	}

	const svcColumnData = new ColumnDataImpl<
		SlowVitalCapacitySVCBestParametersModel,
		ReportParameterModel
	>(PARAMETERS, data);
	svcColumnData.createColumnData();

	return svcColumnData.columnData;
};
