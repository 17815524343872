import { format } from 'date-fns';
import {
	calculateBmi,
	convertCountryCodeToName,
	convertCountryNameToCode,
	convertValueUnit
} from 'helpers';
import { PATIENT_SMOKER_FIELD_NAMES } from 'helpers/Forms/fieldNames/patient';
import {
	DATE_FORMAT,
	DEFAULT_UNITS,
	SMOKER_OPTIONS_VALUES
} from 'helpers/variables';
import _ from 'lodash';

import { standardizeHeightWeightUnits } from './helpers/common';

export const PatientServices = {
	addBmiToPatientData: (patientData) => {
		const patientBmi = calculateBmi({
			weight: patientData.patientWeight,
			height: patientData.patientHeight,
			weightUnit: patientData.patientWeightUnit,
			heightUnit: patientData.patientHeightUnit
		});
		return { ...patientData, patientBmi };
	},
	/**
	 * conversion of form data structure to structure and values accepted by backend
	 * @param {*} args arguments
	 * @param {object} args.values form values
	 * @param {object} args.countries list of countries fetched from DB
	 * @param {object} args.fieldsToOmit list of fields names to omit
	 * @returns {object} converted payload data
	 */
	createNewPatientPayloadConversion: ({
		values,
		countries,
		fieldsToOmit = []
	}) => {
		const convertedValues = convertPatientValues(values, countries);
		const patientSmokerValues = convertPatientSmoker(values);
		const data = { ...convertedValues, ...patientSmokerValues };

		const finalPayload = _.omit(data, fieldsToOmit);

		return finalPayload;
	},
	/**
	 * convert fetched patient basic informations data to presentational structure and values
	 * @param {object} initialValues data fetched from DB
	 * @param {object} countries list of countries fetched from DB
	 * @returns {object} converted patient basic information data
	 */
	convertBasicInformationsInitialValues: (initialValues, countries) => {
		const { patientCountryCode } = initialValues;
		if (countries?.data) {
			const countryName =
				patientCountryCode &&
				convertCountryCodeToName(patientCountryCode, countries.data);
			return { ...initialValues, patientCountryCode: countryName };
		}
		return initialValues;
	},
	/**
	 * final conversion of basic information form data structure to structure and values accepted by backend
	 * @param {*} args arguments
	 * @param {object} args.values form values
	 * @param {object} args.countries list of countries fetched from DB
	 * @param {string} args.initialPatientEmail initial patient's email
	 * @param {object} args.fieldsToOmit list of fields names to omit
	 * @returns {object} final converted patient basic information data
	 */
	editBasicInformationsPayloadConversion: ({
		values,
		countries,
		initialPatientEmail,
		fieldsToOmit
	}) => {
		const { patientEmail } = values;
		const convertedValues = convertPatientValues(values, countries);
		const finalPayload =
			initialPatientEmail === patientEmail
				? _.omit(convertedValues, fieldsToOmit)
				: convertedValues;

		return finalPayload;
	},
	/**
	 * convert fetched patient additional informations data to presentational structure and values
	 * @param {object} initialValues data fetched from DB
	 * @returns {object} converted patient additional information data
	 */
	convertAdditionalInformationsInitialValues: (init) => {
		// smoker
		const exSmokerSince = getExSmokerSince(init);

		const newInitValues = { ...init, ...exSmokerSince };
		const { patientExSmoker, patientPassiveSmoker, patientSmoker } =
			newInitValues;

		const patientSmokerState = {
			patientExSmoker,
			patientPassiveSmoker,
			patientSmoker
		};

		Object.entries(patientSmokerState).forEach(([key, val]) => {
			if (val) {
				newInitValues.patientSmoker = getSmokerValue(key);
				return newInitValues;
			}
		});

		const patientHeight = convertValueUnit(
			init.patientHeight,
			DEFAULT_UNITS.HEIGHT,
			init.patientHeightUnit
		);
		const patientWeight = convertValueUnit(
			init.patientWeight,
			DEFAULT_UNITS.WEIGHT,
			init.patientWeightUnit
		);
		return { ...newInitValues, patientHeight, patientWeight };
	},
	/**
	 * final conversion of additional information form data structure to structure and values accepted by backend
	 * @param {*} args arguments
	 * @param {object} args.values form values
	 * @param {object} args.fieldsToOmit list of fields names to omit
	 * @returns {object} final converted patient additional information data
	 */
	editAdditionalInformationsPayloadConversion: ({ values, fieldsToOmit }) => {
		const convertedSmoker = convertPatientSmoker(values);
		const convertedValues = values.patientEmail
			? _.omit(values, fieldsToOmit)
			: values;

		const patientHeightWeight = standardizeHeightWeightUnits(values);

		return {
			...convertedValues,
			...convertedSmoker,
			...patientHeightWeight
		};
	}
};

/**
 * conversion of country name to country code and phone number
 * @param {object} payload patient data
 * @param {object} countries list of countries fetched from DB
 * @returns {object} payload with converted values
 */
function convertPatientValues(payload, countries) {
	const {
		patientCountryCode: countryName,
		patientPhoneNumber,
		patientDob
	} = payload;

	const countryCode =
		countryName && convertCountryNameToCode(countryName, countries);

	const phoneNumber = patientPhoneNumber?.replace(/\s/g, '');

	const birthDate =
		patientDob instanceof Date
			? format(patientDob, DATE_FORMAT.KEYBOARD_DATE_PICKER_VALUE)
			: patientDob;

	const patientHeightWeight = standardizeHeightWeightUnits(payload);

	const patientBmi = calculateBmi({
		weight: patientHeightWeight.patientWeight,
		height: patientHeightWeight.patientHeight,
		weightUnit: patientHeightWeight.patientWeightUnit,
		heightUnit: patientHeightWeight.patientHeightUnit
	});
	return {
		...payload,
		...patientHeightWeight,
		patientCountryCode: countryCode || countryName,
		patientPhoneNumber: phoneNumber,
		patientDob: birthDate,
		patientBmi
	};
}

/**
 * conversion of patient smoker information
 * @param {object} values patient data
 * @returns {object} converted smoker values
 */
function convertPatientSmoker(values) {
	let newSmokerValues = {
		patientSmoker: false,
		patientSmokerCigarettes: null,
		patientSmokerYears: null,
		patientPassiveSmoker: false,
		patientPassiveSmokerYears: null,
		patientExSmoker: false,
		patientExSmokerSince: ''
	};

	const {
		patientSmoker: smoker,
		patientSmokerCigarettes: cigarettes,
		patientSmokerYears: smokerYears,
		patientPassiveSmokerYears: passiveSmokerYears,
		exSmokerMonth,
		exSmokerYear
	} = values;

	switch (smoker) {
		case SMOKER_OPTIONS_VALUES.YES:
			newSmokerValues = {
				...newSmokerValues,
				patientSmoker: true,
				patientSmokerCigarettes: cigarettes,
				patientSmokerYears: smokerYears
			};
			break;
		case SMOKER_OPTIONS_VALUES.PASSIVE_SMOKER:
			newSmokerValues = {
				...newSmokerValues,
				patientPassiveSmoker: true,
				patientPassiveSmokerYears: passiveSmokerYears
			};
			break;
		case SMOKER_OPTIONS_VALUES.EX_SMOKER:
			const patientExSmokerSince = exSmokerMonth
				? `${exSmokerYear}-${exSmokerMonth}`
				: `${exSmokerYear}`;
			newSmokerValues = {
				...newSmokerValues,
				patientExSmoker: true,
				patientExSmokerSince
			};
			break;
		default:
			break;
	}
	return newSmokerValues;
}

/**
 * conversion of patient's ex smoker information
 * @param {object} val patient ex smoker value
 * @returns {object} dates since patient is an ex smoker
 */
const getExSmokerSince = (val) => {
	const { patientExSmokerSince } = val;
	if (!patientExSmokerSince) return '';

	const exSmokerMonth = patientExSmokerSince.slice(5);
	const exSmokerYear = patientExSmokerSince.slice(0, 4);
	return { exSmokerMonth, exSmokerYear };
};

const { NO, YES, PASSIVE_SMOKER, EX_SMOKER } = SMOKER_OPTIONS_VALUES;

/**
 * maps field names to its values
 * @param {string} key field name
 * @returns {string} field value
 */
const getSmokerValue = (key) => {
	switch (key) {
		case PATIENT_SMOKER_FIELD_NAMES.EX_SMOKER:
			return EX_SMOKER;
		case PATIENT_SMOKER_FIELD_NAMES.PASSIVE_SMOKER:
			return PASSIVE_SMOKER;
		case PATIENT_SMOKER_FIELD_NAMES.SMOKER:
			return YES;
		default:
			return NO;
	}
};
