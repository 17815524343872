import { Box, Typography } from '@material-ui/core';
import { ReactComponent as HelpIcon } from 'assets/icons/ic_help-question-circle.svg';
import { useTranslations } from 'components/utilities';
import React from 'react';

import { useStyles } from './styles';

const LegendLabel = (props) => {
	const classes = useStyles();
	const { t } = useTranslations();
	return (
		<Box className={classes.root} {...props}>
			<HelpIcon className={classes.icon} />
			<Typography component="span" className={classes.label}>
				{t('patients.examinations.chart.legend.title')}
			</Typography>
		</Box>
	);
};

export default LegendLabel;
