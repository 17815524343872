import { capitalize } from 'helpers';
import { CHART_MEASUREMENT_PARAMETER_OPTION_NAMES } from 'helpers/charts/measurement/parameters';

const { tiffeneau, fvc, fev1 } = CHART_MEASUREMENT_PARAMETER_OPTION_NAMES;

const DEFAULT_MIN_Z_SCORE_VALUE = -5;
const DEFAULT_MAX_Z_SCORE_VALUE = 3;

export const Z_SCORES_PICTOGRAMS = [fvc, fev1, tiffeneau];

// get Z-Score value from api data
export const getZScoreValue = ({ name, spirometryData }) => {
	const value = spirometryData[`bestZScore${capitalize(name)}`];
	return value ? value : 0;
};

// generate initial x-axis range values based on min/max api Z-Scores and defaults
export const getInitialXAxisRangeValues = (spirometryData) => {
	const zScoresValues = Z_SCORES_PICTOGRAMS.map(({ name }) =>
		getZScoreValue({ name, spirometryData })
	);
	const minZScoreValue = Math.min(...zScoresValues);
	const maxZScoreValue = Math.max(...zScoresValues);

	const minXAxisValue =
		DEFAULT_MIN_Z_SCORE_VALUE < minZScoreValue
			? DEFAULT_MIN_Z_SCORE_VALUE
			: Math.floor(minZScoreValue);
	const maxXAxisValue =
		DEFAULT_MAX_Z_SCORE_VALUE > maxZScoreValue
			? DEFAULT_MAX_Z_SCORE_VALUE
			: Math.ceil(maxZScoreValue);
	return [minXAxisValue, maxXAxisValue];
};

// generate scale lines base on x-axis integer values quantity
export const getScaleLines = (SCALE_VALUES_QUANTITY) =>
	new Array(SCALE_VALUES_QUANTITY).fill('').map((_, index) => index);

// get position on pictogram based on passed value and SCALE_VALUES_QUANTITY.
// It returns right position proportional to SCALE_VALUES_QUANTITY value in percentage unit.
export const getValuePosition = ({
	value,
	scaleValuesQuantity,
	xAxisNonPositiveValues
}) => {
	const scaledValue =
		scaleValuesQuantity - (value + xAxisNonPositiveValues + 1);
	const position = (scaledValue * 100) / scaleValuesQuantity;

	return position;
};

// calculate x-axis integer values quantity
export const getXAxisValuesDifference = (minValue, maxValue) => {
	if (minValue === maxValue || maxValue < minValue) {
		throw Error('Z-Score pictogram values range is incorrect');
	}

	if (minValue >= 0 && maxValue > 0) {
		return minValue + maxValue - 1;
	}

	return Math.abs(minValue) + maxValue;
};
