import { Button, TableCell } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useControlledTableContextProvider } from 'components/context';
import { useTranslations } from 'components/utilities';
import { func, object } from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
	cell: {
		display: 'flex',
		alignItems: 'center'
	},
	button: {
		margin: theme.spacing(0),
		padding: '1px 16px',
		fontSize: '12px'
	}
}));

const ActiveTableCell = ({ cell, activeAction = () => {} }) => {
	const classes = useStyles();

	const dispatch = useDispatch();

	const { fetchData } = useControlledTableContextProvider();

	const { t } = useTranslations();

	const isActive = cell.row.values.active;

	const handleActiveItem = async () => {
		const id = cell.row.original.uuid;
		await activeAction({ isActive, id })(dispatch);
		fetchData();
	};

	const activeText = t('table.actions.active');
	const inactiveText = t('table.actions.inactive');
	const text = isActive ? activeText : inactiveText;

	const variant = isActive ? 'contained' : 'outlined';

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<Button
				variant={variant}
				onClick={handleActiveItem}
				color="primary"
				className={classes.button}
			>
				{text}
			</Button>
		</TableCell>
	);
};

ActiveTableCell.propTypes = {
	cell: object.isRequired,
	activeAction: func
};

export default ActiveTableCell;
