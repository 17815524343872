import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		padding: '8px 15px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	input: {
		flex: 1,
		marginRight: theme.spacing(2),
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: `${theme.palette.black}33`
		},
		'& .MuiInputLabel-formControl': {
			color: theme.palette.gray.medium,
			opacity: 0.5
		},
		'& .MuiFormLabel-root.Mui-focused': {
			color: theme.palette.primary.main,
			opacity: 1
		}
	},
	infoTipTooltipContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center'
	}
}));
