import { GET_APPOINTMENT_RESOURCES, GET_SCHEDULER_API } from 'helpers';
import { replaceCommonIdQueryParam } from 'queries/common';
import services from 'services/services';

export const AppointmentsRepository = {
	/**
	 * fetch appointment data
	 * @param {*} args
	 * @param {number} args.appointmentId appointment ID to be fetch
	 * @param {object} args.cancelToken request cancellation token
	 * @returns Promise
	 */
	getAppointment: ({ appointmentId, cancelToken }) => {
		const apiUrl = replaceCommonIdQueryParam(
			GET_APPOINTMENT_RESOURCES,
			appointmentId
		);
		return services.get(apiUrl, {
			cancelToken
		});
	},
	/**
	 * fetch appointments list in given date range
	 * @param {object} args
	 * @param {object} args.payload request params
	 * @param {string} args.payload.start appointments start date range query param
	 * @param {string} args.payload.end appointments end date range query param
	 * @param {object} args.cancelToken request cancellation token
	 * @returns Promise
	 */
	getAll: ({ payload, cancelToken }) => {
		const apiUrl = `${GET_SCHEDULER_API}/appointments?from=${payload.start}&to=${payload.end}`;
		return services.get(apiUrl, { cancelToken });
	},
	/**
	 * create new appointment
	 * @param {*} payload appointment form data
	 * @returns Promise
	 */
	create: (payload) =>
		services.post(`${GET_SCHEDULER_API}/appointments`, payload),
	/**
	 * edit appointment data
	 * @param {*} args
	 * @param {number} args.appointmentId appointment ID to be edited
	 * @param {object} args.payload request payload - edited appointment data
	 * @returns Promise
	 */
	edit: ({ appointmentId, payload }) =>
		services.put(
			`${GET_SCHEDULER_API}/appointment/${appointmentId}`,
			payload
		),
	/**
	 * delete appointment
	 * @param {number} appointmentId appointment ID to be deleted
	 * @returns Promise
	 */
	delete: (appointmentId) =>
		services.delete(`${GET_SCHEDULER_API}/appointment/${appointmentId}`)
};
