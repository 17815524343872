import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import { ReactContext } from 'utilities';

interface IPrePostProvider {
	preActiveIndex: number;
	postActiveIndex: number;
	setPreActiveIndex: React.Dispatch<React.SetStateAction<number>>;
	setPostActiveIndex: React.Dispatch<React.SetStateAction<number>>;
	flowVolumeGraphRef: React.RefObject<HighchartsReact.RefObject>;
	volumeTimeGraphRef: React.RefObject<HighchartsReact.RefObject>;
}

const [useCtx, Ctx] = ReactContext.createContext<IPrePostProvider>();

type Props = {
	children: CmpChildren;
};

export const DEFAULT_ACTIVE_ATTEMPT_INDEX = 0;

export const PrePostProvider = ({ children }: Props) => {
	const flowVolumeGraphRef = React.useRef<HighchartsReact.RefObject>(null);
	const volumeTimeGraphRef = React.useRef<HighchartsReact.RefObject>(null);

	const [preActiveIndex, setPreActiveIndex] = React.useState<number>(
		DEFAULT_ACTIVE_ATTEMPT_INDEX
	);
	const [postActiveIndex, setPostActiveIndex] = React.useState<number>(
		DEFAULT_ACTIVE_ATTEMPT_INDEX
	);

	return (
		<Ctx.Provider
			value={{
				flowVolumeGraphRef,
				volumeTimeGraphRef,
				postActiveIndex,
				preActiveIndex,
				setPreActiveIndex,
				setPostActiveIndex
			}}
		>
			{children}
		</Ctx.Provider>
	);
};

export const usePrePostContext = useCtx;
export const PrePostContext = Ctx;
