import { Grid, Typography } from '@material-ui/core';
import { useTranslations } from 'components/utilities';
import React from 'react';

import { useStyles } from './styles';

const NotAuthorized = () => {
	const classes = useStyles();
	const { t } = useTranslations();

	return (
		<div className={classes.root}>
			<Grid container justify="center" spacing={4}>
				<Grid item lg={6} xs={12}>
					<div className={classes.content}>
						<Typography variant="h1">
							{t('global.not_authorized')}
						</Typography>
						<Typography variant="subtitle2"></Typography>
					</div>
				</Grid>
			</Grid>
		</div>
	);
};

export default NotAuthorized;
