import {
	useActiveExaminationActions,
	useExaminationsV2Context,
	useExaminationsV2RefsContext
} from 'components/context/ExaminationsV2';
import { PATIENTS_DETAILS_PATHS } from 'components/routes/paths';
import { useTranslations } from 'components/utilities';
import {
	ExaminationsListConvertedItemModel,
	ExaminationsListItemModel
} from 'models/Examinations/ExaminationsList';
import { EXAMINATION_TYPES_FILTER_OPTIONS } from 'queries/Examinations/ExaminationsList/helpers';
import React from 'react';
import { generatePath } from 'react-router-dom';
import { useParams } from 'utilities';

import { getRenderValue } from './helpers';

const useExaminationTypeFilter = () => {
	const { t } = useTranslations();
	const params = useParams();

	const { store: activeExamination } = useExaminationsV2Context(
		(store) => store.activeExamination
	);
	const { store: examinationDateExtremes } = useExaminationsV2Context(
		(store) => store.examinationDateExtremes
	);

	const {
		store: selectedExaminationTypes,
		setExaminationsListDataWithFilters,
		toggleSelectedExaminationTypes,
		selectAllExaminationTypes,
		selectOneExaminationType,
		setExaminationsListData
	} = useExaminationsV2Context((store) => store.selectedExaminationTypes);

	const { scrollToExaminationsListItem } = useExaminationsV2RefsContext();

	const {
		setActiveExaminationWithListItemPositioning,
		removeActiveExaminationIndexFromList
	} = useActiveExaminationActions();

	const { resetExaminationsVirtualList } = useExaminationsV2RefsContext();

	const handleToggleSelectedExaminationTypes = (value) => {
		const newSelectedParameters = toggleSelectedExaminationTypes(value);
		const filteredData = setExaminationsListDataWithFilters({});
		validateActiveExamination(newSelectedParameters, filteredData);
		resetExaminationsVirtualList();
	};
	const handleSelectAllExaminationTypes = () => {
		const newSelectedParameters = selectAllExaminationTypes(
			EXAMINATION_TYPES_FILTER_OPTIONS.map(({ value }) => value)
		);
		const filteredData = setExaminationsListDataWithFilters({});
		validateActiveExamination(newSelectedParameters, filteredData);
		resetExaminationsVirtualList();
	};
	const handleSelectOneExaminationType = (value) => {
		const newSelectedParameters = selectOneExaminationType(value);
		const filteredData = setExaminationsListDataWithFilters({});
		validateActiveExamination(newSelectedParameters, filteredData);
		resetExaminationsVirtualList();
	};

	const validateActiveExamination = (
		parameters: ExaminationTypeParam[],
		examinationListData: ExaminationsListConvertedItemModel[]
	) => {
		if (
			activeExamination.type &&
			!parameters.includes(activeExamination.type)
		) {
			const index = 1;
			const examinationListItem = examinationListData?.[
				index
			] as ExaminationsListItemModel;

			if (examinationListItem) {
				const newActiveExamination =
					setActiveExaminationWithListItemPositioning({
						examinationsListData: examinationListData,
						activeExamination: {
							id: examinationListItem.id.toString(),
							type: examinationListItem.type,
							index
						},
						forceUpdateSearchParams: true,
						scrollToItem: true
					});
				const data = removeActiveExaminationIndexFromList(
					examinationListData,
					newActiveExamination
				);
				setExaminationsListData(data);
				return;
			}
		} else if (activeExamination.index) {
			const data = removeActiveExaminationIndexFromList(
				examinationListData,
				activeExamination
			);
			setExaminationsListData(data);
			scrollToExaminationsListItem(activeExamination.index);
		}
	};

	const handleRenderValue = (value) => getRenderValue(value, t);

	const iValueValid = (value: ExaminationTypeParam[]) => {
		const isAtLeastOneElement = value.length >= 1;
		const isActiveExaminationSelected = activeExamination.type
			? value.includes(activeExamination.type)
			: location.pathname !==
			  generatePath(PATIENTS_DETAILS_PATHS.EXAMINATIONS.PREVIEW, params);

		return isAtLeastOneElement && isActiveExaminationSelected;
	};

	const isDisabled = React.useMemo(
		() =>
			examinationDateExtremes?.startDate === null &&
			examinationDateExtremes?.endDate === null,
		[examinationDateExtremes.startDate, examinationDateExtremes.endDate]
	);

	return {
		selectedExaminationTypes,
		renderValue: handleRenderValue,
		toggleSelectedExaminationTypes: handleToggleSelectedExaminationTypes,
		selectAllExaminationTypes: handleSelectAllExaminationTypes,
		selectOneExaminationType: handleSelectOneExaminationType,
		iValueValid,
		isDisabled
	};
};

export default useExaminationTypeFilter;
