import { MenuItem } from '@material-ui/core';
import { PatientInvitationModal } from 'components/templates/Patients/PatientInvitation/PatientInvitationModal';
import { useTranslations } from 'components/utilities';
import React from 'react';

import { Ctx } from '../../types';
import { usePatientFullName } from '../../usePatientFullName';

export interface IPatientInvitationMenuItemProps extends Ctx {
	close: () => void;
	onExited?: () => void;
}

export const PatientInvitationMenuItem = ({
	ctx,
	close
}: IPatientInvitationMenuItemProps) => {
	const { t } = useTranslations();
	const patientFullName = usePatientFullName({ ctx });

	return (
		<PatientInvitationModal
			initialEmail={ctx.row.original.email ?? ''}
			invitationStatus={ctx.row.original.invitationStatus}
			patientFullName={patientFullName}
			patientId={ctx.row.original.id}
			onClose={() => {
				close();
			}}
		>
			{({ isPatientUnlinked, setOpen }) => (
				<MenuItem
					onClick={() => {
						setOpen(true);
					}}
				>
					{t(isPatientUnlinked ? 'btn.invite' : 'btn.disconnect')}
				</MenuItem>
			)}
		</PatientInvitationModal>
	);
};
