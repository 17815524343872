import { z } from 'zod';

import { CommonReportFieldsSchema } from './common';

export const PeakFlowBestParametersSchema = z.object({
	pef1: z.number(),
	pef2: z.number(),
	pef3: z.number(),
	result: z.number()
});

export type PeakFlowBestParameterModel = z.infer<
	typeof PeakFlowBestParametersSchema
>;

export const PeakFlowReportSchema = CommonReportFieldsSchema.merge(
	z.object({
		bestParameters: PeakFlowBestParametersSchema
	})
);
