import { useTooltipContext } from 'components/context/ExaminationsV2';
import {
	Alert,
	CardTopBar,
	ErrorBoundary,
	IdleRender,
	LoaderSpinner
} from 'components/elements';
import React from 'react';

import { useStyles } from './styles/styles';
import { Tooltip, TooltipRPC } from './Tooltip';
import useExaminationDataTooltip from './useExaminationDataFromSidelist';

const ExaminationDataTooltip = () => {
	const { store: examinationTooltip } = useTooltipContext(
		(s) => s.examinationTooltip
	);

	const { idle, examinationListItemData, apiError } =
		useExaminationDataTooltip(examinationTooltip);

	const classes = useStyles({ examinationTooltip });

	const renderSuccessComponent = () => (
		<TooltipRPC examinationListItemData={examinationListItemData}>
			{(props) => !!props && <Tooltip {...props} />}
		</TooltipRPC>
	);

	if (examinationTooltip.enabled || examinationTooltip.active) {
		return (
			<ErrorBoundary key={idle}>
				<CardTopBar isBox classNameBox={classes.tooltip}>
					<IdleRender
						idle={idle}
						loading={() => <LoaderSpinner rootSize="full" />}
						error={() => (
							<Alert
								className={classes.alertWrapper}
								classNameAlert={classes.alert}
								message={apiError}
							/>
						)}
						success={() => renderSuccessComponent()}
					/>
				</CardTopBar>
			</ErrorBoundary>
		);
	}
	return null;
};

export default ExaminationDataTooltip;
