import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslations } from 'components/utilities';
import { func, number } from 'prop-types';
import React from 'react';

import { ScaleLinesWrapper } from '../components';
import { REFERENCE_LINES_VALUES } from './helpers';
import {
	getReferenceLineDisplay,
	getReferenceLinePosition,
	useStyles
} from './styles';

const ReferenceLines = ({ getValuePosition, minXAxisValue, maxXAxisValue }) => {
	const classes = useStyles();
	const { t } = useTranslations();

	return (
		<Box className={classes.root}>
			<Box className={classes.spacer} />
			<ScaleLinesWrapper className={classes.container}>
				{REFERENCE_LINES_VALUES.map(({ value, label }) => (
					<Box
						key={`${value}+${label}`}
						className={clsx([
							classes.referenceLine,
							'z-score--ref-line--wrapper'
						])}
						style={{
							right: getReferenceLinePosition(
								value,
								getValuePosition
							),
							display: getReferenceLineDisplay({
								value,
								minXAxisValue,
								maxXAxisValue
							})
						}}
					>
						<Typography
							className={clsx([
								classes.label,
								'z-score--ref-line--label'
							])}
						>
							{t(label)}
						</Typography>
						<Box className={classes.line} />
					</Box>
				))}
			</ScaleLinesWrapper>
		</Box>
	);
};

ReferenceLines.propTypes = {
	getValuePosition: func.isRequired,
	minXAxisValue: number.isRequired,
	maxXAxisValue: number.isRequired
};

export default ReferenceLines;
