import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	formControlLabel: {
		marginLeft: 0,
		minWidth: ({ minValueWidth }) => minValueWidth
	},
	radio: {
		padding: '5px',
		margin: '0 5px'
	},
	label: {
		fontSize: '16px',
		fontWeight: 400,
		lineHeight: '17.6px',
		letterSpacing: '0.15px',
		verticalAlign: 'middle',
		paddingTop: 3
	}
}));
