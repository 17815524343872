import {
	Box,
	CircularProgress,
	Typography as MuiTypography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { array, number, object, oneOfType, string } from 'prop-types';
import React from 'react';

const handleRootSize = (rootSize) => {
	switch (rootSize) {
		case 'full':
			return '100%';
		case 'page':
			return '50vh';
		case 'medium':
			return '200px';
		case 'small':
			return '80px';

		default:
			break;
	}
};

const useStyles = makeStyles(() => ({
	root: {
		height: ({ rootSize }) => handleRootSize(rootSize),
		width: ({ width }) => width,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		zIndex: 100,
		minHeight: ({ minHeight }) => minHeight
	},
	box: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center'
	},
	text: {
		marginTop: 8,
		fontSize: ({ textSize }) => textSize
	},
	logo: {
		height: 50,
		width: 260
	}
}));

const LoaderSpinner = ({
	classNameRoot,
	rootSize = 'page',
	size = 52,
	color = 'primary',
	minHeight = 'auto',
	width = '100%',
	text = '',
	textSize = 12
}) => {
	const classes = useStyles({ rootSize, minHeight, width, textSize });
	return (
		<Box className={clsx([classNameRoot, classes.root])}>
			<Box className={classes.box}>
				<Box className={classes.content}>
					<CircularProgress
						size={size}
						color={color}
						data-testid="LoaderSpinner-CircularProgress"
					/>
					{text && (
						<MuiTypography
							className={classes.text}
							variant="body1"
							component="p"
						>
							{text}
						</MuiTypography>
					)}
				</Box>
			</Box>
		</Box>
	);
};

LoaderSpinner.propTypes = {
	classNameRoot: string,
	rootSize: string,
	size: number,
	textSize: number,
	color: string,
	text: oneOfType([string, object, array]),
	minHeight: oneOfType([string, number]),
	width: oneOfType([string, number])
};

export default LoaderSpinner;
