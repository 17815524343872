import { DialogContent as MuiDialogContent } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import { useCommonStyles } from '../../../../commonStyles';
import { useStyles } from './styles';

export interface IContentContainerProps {
	children: CmpChildren | CmpChildren[];
	classNameContent?: string;
}
export const ContentContainer = ({
	classNameContent,
	children
}: IContentContainerProps) => {
	const classes = useStyles();
	const commonClasses = useCommonStyles();

	return (
		<MuiDialogContent
			className={clsx([
				classNameContent,
				classes.content,
				commonClasses.containerPadding
			])}
		>
			{children}
		</MuiDialogContent>
	);
};
