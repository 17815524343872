import { convertDecimalNumber } from 'helpers/numbers';
import { SUPPORTED_UNITS } from 'helpers/variables';

import { convertValueUnit } from './units';

export const calculateBmi = ({ weight, height, weightUnit, heightUnit }) => {
	validateArgs(weight, weightUnit);
	validateArgs(height, heightUnit);

	const weightInMeters = convertValueUnit(
		weight,
		weightUnit,
		SUPPORTED_UNITS.KG
	);
	const heightInKilograms = convertValueUnit(
		height,
		heightUnit,
		SUPPORTED_UNITS.M
	);
	const bmi = weightInMeters / Math.pow(heightInKilograms, 2);
	return convertDecimalNumber(bmi);
};

const validateArgs = (value, unit) => {
	if ((!value && value != 0) || !unit) {
		throw new Error(
			`Given value: ${value || null} or Unit: ${
				unit || null
			} are not defined, or their values are illegal!`
		);
	}
};
