import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
	alert: {
		marginBottom: theme.spacing(3),
		maxWidth: 533,
		'& .MuiAlert-message': {
			fontSize: '15px'
		}
	}
}));
