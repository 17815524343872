import { TableBody } from 'components/elements/atoms';
import { IDLE } from 'helpers';
import { QueryStatus } from 'react-query';

import { EmptyDataTableRow } from '../../../molecules/Table/EmptyDataTableRow';
import { TableBodyContentLoader } from '../../../molecules/Table/TableBodyContentLoader';
import {
	ITabelBodyRows,
	TableBodyRows
} from '../../../molecules/Table/TableBodyRows';
import {
	ITableErrorAlertProps,
	TableErrorAlert
} from '../../../molecules/Table/TableErrorAlert';

export interface ITableBodyContentProps<TRow, TCell>
	extends ITabelBodyRows<TRow, TCell> {
	numberOfColumns: number;
	idle: QueryStatus;
	apiError: ITableErrorAlertProps['apiError'];
	className?: string;
}

export const TableBodyContent = <TRow, TCell>({
	numberOfColumns,
	idle,
	apiError,
	className = '',
	// ---- ITabelBodyRows ---- //
	rows,
	getRowData,
	cellValueRenderer,
	cellAlignGetter,
	cellValueGetter
}: ITableBodyContentProps<TRow, TCell>) => {
	const renderBody = () => {
		if (rows.length === 0 && IDLE.SUCCESS === idle) {
			return <EmptyDataTableRow numberOfColumns={numberOfColumns} />;
		}

		if (apiError && IDLE.ERROR === idle) {
			return (
				<TableErrorAlert
					numberOfColumns={numberOfColumns}
					apiError={apiError}
				/>
			);
		}

		if (IDLE.LOADING === idle) {
			return <TableBodyContentLoader numberOfColumns={numberOfColumns} />;
		}

		if (IDLE.SUCCESS === idle) {
			return (
				<TableBodyRows
					rows={rows}
					getRowData={getRowData}
					cellValueRenderer={cellValueRenderer}
					cellAlignGetter={cellAlignGetter}
					cellValueGetter={cellValueGetter}
				/>
			);
		}
	};

	return <TableBody className={className}>{renderBody()}</TableBody>;
};
