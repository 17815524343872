import { API_URL_PREFIX } from './helpers';

// Create doctor account api
export const CREATE_DOCTOR_ACCOUNT_API = `${API_URL_PREFIX}/workers/doctor`;
export const QUERY_CREATE_DOCTOR_RESOURCES = 'QUERY_CREATE_DOCTOR_RESOURCES';

// Invite employee
export const EMPLOYEE_INVITE_API = `${API_URL_PREFIX}/clinics/:clinicId/employees/invite`;

// Verify Account
export const QUERY_DOCTOR_ACCOUNT_VERIFICATION = `QUERY_DOCTOR_ACCOUNT_VERIFICATION`;
export const DOCTOR_ACCOUNT_VERIFICATION_API = `${API_URL_PREFIX}/auth/me/activate`;
