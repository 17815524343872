import { Box } from '@material-ui/core';
import { AppStoreLinkButton } from 'components/elements/atoms';
import { APP_STORE_LINK_TYPES } from 'components/elements/atoms/Buttons/AppStoreLinkButton/AppStoreLinkButton';
import React from 'react';

import { useStyles } from './styles';

export const PatientContextActionButtons = () => {
	const classes = useStyles();
	return (
		<Box className={classes.container}>
			<AppStoreLinkButton type={APP_STORE_LINK_TYPES.APP_STORE} />
			<AppStoreLinkButton type={APP_STORE_LINK_TYPES.GOOGLE_PLAY} />
		</Box>
	);
};
