import clsx from 'clsx';
import React from 'react';

import { CloseButton } from '../../atoms';
import { useStyles } from './styles';

export interface ICornerCloseButtonProps extends DataTestId {
	disabled?: boolean;
	onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const CornerCloseButton = ({
	disabled = false,
	onClick
}: ICornerCloseButtonProps) => {
	const classes = useStyles();
	return onClick ? (
		<CloseButton
			data-testid={'corner-close-button'}
			disabled={disabled}
			onClick={onClick}
			className={clsx([classes.iconButton, 'disable-animation'])}
		/>
	) : null;
};
