import { Box, Typography } from '@material-ui/core';
import { ParameterValue } from 'components/elements/molecules/ExaminationsV2';
import React from 'react';

import { useStyles } from './styles';

type Props = {
	value: number;
	unit: string | null;
	color: string;
};
const BestResult = ({ value, unit = null, color }: Props) => {
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<ParameterValue
				classNameRoot={classes.bestResultValue}
				value={value}
				color={color}
				isValueColoured
			/>
			<Typography data-testid="BestResult-unit" className={classes.unit}>
				{unit}
			</Typography>
		</Box>
	);
};

export default BestResult;
