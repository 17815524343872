import * as types from './types';

export const setAuth = (payload) => (dispatch) => {
	dispatch({ type: types.SET_AUTH, payload });
};

export const setConversationList = (payload) => (dispatch) => {
	dispatch({ type: types.SET_CONVERSATION_LIST, payload });
};

export const setConversation = (payload) => (dispatch) => {
	dispatch({ type: types.SET_NEW_CONVERSATION, payload });
};

export const setConversationHistory = (payload) => (dispatch) => {
	dispatch({ type: types.SET_CONVERSATION_HISTORY, payload });
};

export const receiveMessage = (payload) => (dispatch) => {
	dispatch({ type: types.RECEIVE_MESSAGE, payload });
};
