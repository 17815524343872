import { format, getHours, getMinutes } from 'date-fns';

export const getDateTime = (createdAt) => {
	const newDate = new Date(createdAt);
	const date = format(newDate, 'dd.MM.yyyy');
	const h = getHours(newDate);
	const m = getMinutes(newDate);
	const time = `${h}:${m}`;
	return { date, time };
};
