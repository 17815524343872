import { TIME_FORMAT } from 'helpers';
import React from 'react';
import { flushSync } from 'react-dom';
import { LocaleDate } from 'utilities';

import { useAttemptsContext } from './AttemptsProvider';
const useAttemptsArrowSelector = () => {
	const {
		measurements,
		activeMeasurementIndex,
		isBestIndex,
		setActiveMeasurementIndex,
		setActiveGraphCurve,
		attemptsArray,
		showPreviewOfNewActiveGraphCurve,
		hidePreviewOfNewActiveGraphCurve
	} = useAttemptsContext();

	const handleClickLeft = () => {
		const newActiveMeasurementIndex = activeMeasurementIndex - 1;
		flushSync(() => {
			setActiveGraphCurve(
				newActiveMeasurementIndex,
				activeMeasurementIndex,
				{
					disableHide: activeMeasurementIndex === isBestIndex
				}
			);
			setActiveMeasurementIndex(newActiveMeasurementIndex);
		});
		showPreviewOfNewActiveGraphCurve(newActiveMeasurementIndex - 1);
	};

	const handleClickRight = () => {
		const newActiveMeasurementIndex = activeMeasurementIndex + 1;
		flushSync(() => {
			setActiveGraphCurve(
				newActiveMeasurementIndex,
				activeMeasurementIndex,
				{
					disableHide: activeMeasurementIndex === isBestIndex
				}
			);
			setActiveMeasurementIndex(newActiveMeasurementIndex);
		});
		showPreviewOfNewActiveGraphCurve(newActiveMeasurementIndex + 1);
	};

	const handleMouseEnterRight = React.useCallback(() => {
		showPreviewOfNewActiveGraphCurve(activeMeasurementIndex + 1);
	}, [activeMeasurementIndex]);
	const handleMouseLeaveRight = React.useCallback(() => {
		hidePreviewOfNewActiveGraphCurve(activeMeasurementIndex + 1);
	}, [activeMeasurementIndex]);

	const handleMouseEnterLeft = React.useCallback(() => {
		showPreviewOfNewActiveGraphCurve(activeMeasurementIndex - 1);
	}, [activeMeasurementIndex]);
	const handleMouseLeaveLeft = React.useCallback(() => {
		hidePreviewOfNewActiveGraphCurve(activeMeasurementIndex - 1);
	}, [activeMeasurementIndex]);

	const leftArrowDisabled = React.useMemo(
		() => activeMeasurementIndex === 0,
		[activeMeasurementIndex]
	);
	const rightArrowDisabled = React.useMemo(
		() => activeMeasurementIndex + 1 === measurements?.length,
		[activeMeasurementIndex]
	);

	const attemptDateTime = React.useMemo(
		() =>
			new LocaleDate(
				measurements?.[activeMeasurementIndex]?.createDateTime
			).getLocaleTime(TIME_FORMAT.DEFAULT_WITH_SECONDS),
		[activeMeasurementIndex]
	);

	const getSelectorActions = React.useCallback(
		() => ({
			onClickLeft: handleClickLeft,
			onClickRight: handleClickRight,
			onMouseEnterRight: handleMouseEnterRight,
			onMouseLeaveRight: handleMouseLeaveRight,
			onMouseEnterLeft: handleMouseEnterLeft,
			onMouseLeaveLeft: handleMouseLeaveLeft,
			leftArrowDisabled,
			rightArrowDisabled,
			attemptDateTime,
			activeMeasurementIndex,
			isBestIndex,
			attemptsArray
		}),
		[activeMeasurementIndex]
	);

	return { getSelectorActions };
};

export default useAttemptsArrowSelector;
