import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		padding: '10px 0',
		paddingBottom: 0,
		'& .MuiCardContent-root:last-child': {
			paddingBottom: 0
		}
	},
	cardContent: {
		padding: 0,
		paddingBottom: 0
	}
}));
