import { QUERY_PATIENT_DATA_AND_PERSONAL_BESTS } from 'helpers/api';
import { INVITATION_STATUS } from 'models/Patient/PatientModel';
import { usePatientDataMutation } from 'queries';
import React from 'react';
import { useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import { getPatientDetailsPath, INITIAL_STATE, TRANSLATIONS } from './helpers';
const { PATIENT_NOT_AVAILABLE, NO_ACTIVE_CONNECTION, PATIENT_NOT_FOUND } =
	TRANSLATIONS;

const useChatPatientLinked = ({
	doctorPatientId = null,
	pathUrl = '',
	paramKey = '',
	onError = () => {},
	onSuccess = () => {}
} = {}) => {
	const history = useHistory();
	const { patientId: paramPatientId } = useParams();
	const [error, setError] = React.useState(INITIAL_STATE);
	const queryClient = useQueryClient();
	const patientId = doctorPatientId || paramPatientId || 0;
	const { patientRedirectUrl } = getPatientDetailsPath({
		pathUrl,
		paramKey,
		paramPatientId: patientId
	});

	const { mutateAsync, mutate, isLoading, reset } = usePatientDataMutation(
		{
			onSuccess: handleOnSuccess,
			onError: handleOnError
		},
		{ patientId }
	);

	const redirectToChat = ({ invitationStatus }) => {
		if (invitationStatus === INVITATION_STATUS.UNLINKED) {
			setError({
				isError: true,
				message: PATIENT_NOT_AVAILABLE
			});
		} else if (invitationStatus === INVITATION_STATUS.PENDING) {
			setError({
				isError: true,
				message: NO_ACTIVE_CONNECTION
			});
		} else if (invitationStatus === INVITATION_STATUS.LINKED) {
			return history.push(patientRedirectUrl);
		}
	};

	function handleOnSuccess({ patient }) {
		const { invitationStatus } = patient;
		redirectToChat({ invitationStatus });
		onSuccess?.(patient);
	}

	function handleOnError() {
		setError({ isError: true, message: PATIENT_NOT_FOUND });
		onError();
	}

	const handleReset = () => {
		setError(INITIAL_STATE);
		reset();
	};

	const handleClick = async () => {
		const [, patientData] = queryClient.getQueryData([
			QUERY_PATIENT_DATA_AND_PERSONAL_BESTS,
			patientId
		]);

		if (!patientData) {
			await mutateAsync();
			return;
		}

		const { patient } = patientData;

		const { invitationStatus } = patient;
		redirectToChat({ invitationStatus });
	};

	return {
		patientRedirectUrl,
		fetchProfile: mutate,
		error,
		isLoading,
		reset: handleReset,
		onClick: handleClick
	};
};

export default useChatPatientLinked;
