import { Box, DialogTitle, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { arrayOf, node, oneOfType, string } from 'prop-types';
import React from 'react';

import { useStyles } from './styles';

const Title = ({ variant, children, className, ...rest }) => {
	const classes = useStyles();
	return (
		<DialogTitle disableTypography {...rest} className={classes.root}>
			<Box className={classes.wrapper}>
				<Typography
					className={clsx([classes.title, className])}
					variant={variant}
					style={{ flex: 1 }}
				>
					{children}
				</Typography>
			</Box>
		</DialogTitle>
	);
};

Title.propTypes = {
	variant: string,
	className: string,
	children: oneOfType([arrayOf(node), node]).isRequired
};

export default Title;
