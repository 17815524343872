import { OpenInNewIcon } from 'assets/icons/commonMUIIcons';
import { useExaminationsV2Context } from 'components/context/ExaminationsV2';
import { useDownloadPDFReportByLink } from 'queries/Examinations/ReportsV2';
import React from 'react';
import { useParams } from 'utilities';

import { ButtonCell } from '../components';

type Props = {
	value: string;
};

export const PDFReportLinkCell = ({ value }: Props) => {
	const { patientId } = useParams();
	const {
		mutateAsync: downloadPDFReportByLinkAsync,
		reset,
		apiError,
		isLoading
	} = useDownloadPDFReportByLink();
	const { store: activeExamination } = useExaminationsV2Context(
		(s) => s.activeExamination
	);
	const handleClick = React.useCallback(() => {
		reset();
		downloadPDFReportByLinkAsync({
			patientId,
			fileKey: value,
			// disable @typescript-eslint/no-non-null-assertion
			// examination id and type is 100% defined inside examination details
			/* eslint-disable */
			examinationId: activeExamination.id!,
			examinationType: activeExamination.type!
			/*eslint-enable*/
		});
	}, []);

	return (
		<ButtonCell
			label="patients.examinations_v2.details.pdf_report"
			onClick={handleClick}
			isLoading={isLoading}
			apiError={apiError}
			icon={OpenInNewIcon}
		/>
	);
};
