import { Box } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import { useStyles } from './styles';

type Props = {
	children: CmpChildren;
	className?: string;
};
export const ContainerWithBorder = React.forwardRef<
	HTMLDivElement | undefined,
	Props
>(({ children, className = '' }, ref) => {
	const classes = useStyles();
	return (
		<Box
			{...({ ref } as AnyType)}
			className={clsx([className, classes.container])}
		>
			{children}
		</Box>
	);
});
