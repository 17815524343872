import { makeStyles } from '@material-ui/styles';
import theme from 'theme';

import { TitleProps } from './TopBar';

export const useStyles = makeStyles<
	typeof theme,
	{ isTitle: boolean; variant: TitleProps['variant'] }
>(() => ({
	topBar: {
		display: 'flex',
		justifyContent: ({ isTitle }) =>
			isTitle ? 'space-between' : 'flex-end',
		alignItems: 'center'
	},
	title: {
		fontWeight: 600,
		paddingRight: 10
	},
	icon: {
		color: ({ variant }) => {
			switch (variant) {
				case 'primary':
					return 'white !important';
				case 'secondary':
					return `${theme.palette.primary.main} !important`;
				default:
					return 'white !important';
			}
		}
	}
}));
