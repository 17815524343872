import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	btnChat: {
		height: 30,
		padding: 0,
		minWidth: 0,
		width: 30,
		borderRadius: '50%',
		'& svg': {
			height: 20,
			width: 20
		}
	}
}));
