import { useTranslations } from 'components/utilities';

import {
	ObstructionLevelProps,
	useObstructionLevel
} from './useObstructionLevel';

export const useReportInterpretationNote = ({
	fev1PredictedPercent,
	percentileFev1Fvc
}: ObstructionLevelProps) => {
	const {
		obstructionLabels,
		fev1Predicted,
		percentileFev1Fvc: fixedPercentileFev1Fvc
	} = useObstructionLevel({ fev1PredictedPercent, percentileFev1Fvc });
	const { tNoParser } = useTranslations();

	const reportInterpretationNote = obstructionLabels?.level
		? tNoParser({
				key: 'report.examination_note.automated_interpretation_input_value.obstruction',
				replaceKeys: {
					['%x1']: tNoParser(obstructionLabels?.obstruction) || '',
					['%y1']: tNoParser(obstructionLabels?.level) || '',
					['%x2']: fixedPercentileFev1Fvc,
					['%y2']: fev1Predicted
				}
				//eslint-disable-next-line
		  })
		: tNoParser({
				key: 'report.examination_note.automated_interpretation_input_value.no_obstruction',
				replaceKeys: {
					['%x1']: tNoParser(obstructionLabels?.obstruction) || '',
					['%x2']: fixedPercentileFev1Fvc
				}
				//eslint-disable-next-line
		  });

	return {
		reportInterpretationNote
	};
};
