import {
	setDuration,
	setPatient,
	setSelectedAppointment,
	setSelectedDate} from './store/actions';

const useActions = ({ dispatch }) => {
	const handleSetPatient = (payload) => setPatient(payload)(dispatch);

	const handleSetSelectedDate = (payload) =>
		setSelectedDate(payload)(dispatch);

	const handleSetDuration = (payload) => setDuration(payload)(dispatch);

	const handleSetSelectedAppointment = (payload) =>
		setSelectedAppointment(payload)(dispatch);

	return {
		setPatient: handleSetPatient,
		setSelectedDate: handleSetSelectedDate,
		setDuration: handleSetDuration,
		setSelectedAppointment: handleSetSelectedAppointment
	};
};

export default useActions;
