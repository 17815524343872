import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		paddingTop: 0,
		paddingBottom: 0,
		gap: 20,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	}
}));
