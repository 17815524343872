import { PatientServices } from './services';
import useEdit from './useEdit';

/**
 * edit patient's basic informations
 * @param {object} options mutation options
 * @param {object} additionalData mutation additional data e.g services arguments
 * @returns edit patient account mutation
 */
const useEditBasicInformations = (options = {}, additionalData = {}) => {
	return useEdit(
		options,
		additionalData,
		PatientServices.editBasicInformationsPayloadConversion
	);
};

export default useEditBasicInformations;
