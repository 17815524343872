import { PDFHistoryModel } from 'models/Report/common';
import { z } from 'zod';

import { EXAMINATIONS_TYPES } from '../../helpers/variables';
import { ExaminationsListItemModel } from './ExaminationsList';

const {
	SPIROMETRY,
	COPD_FINAL,
	COPD_TEST,
	SVC,
	PEAK_FLOW,
	PULSE_OXIMETRY,
	MEDICAL_NOTE,
	CALIBRATION_CHECK
} = EXAMINATIONS_TYPES;

export const ExaminationTypeSchema = z.union([
	z.literal(SPIROMETRY.type),
	z.literal(COPD_FINAL.type),
	z.literal(COPD_TEST.type),
	z.literal(SVC.type),
	z.literal(PEAK_FLOW.type),
	z.literal(PULSE_OXIMETRY.type),
	z.literal(MEDICAL_NOTE.type),
	z.literal(CALIBRATION_CHECK.type)
]);

export type ExaminationTypeType = z.infer<typeof ExaminationTypeSchema>;

export type PDFReportType = PDFHistoryModel['key'];

export type ActiveExaminationBase = {
	id: IDParam | undefined;
	type: ExaminationTypeType | undefined;
};

export type ActiveExamination = ActiveExaminationBase & {
	index?: number;
	positionInList?: number;
	data?: ExaminationsListItemModel;
};
