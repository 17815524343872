import { Box, Collapse } from '@material-ui/core';
import { ControlledTableProvider, useDrugsContext } from 'components/context';
import { ControlledTable } from 'components/elements';
import { ToggleRPC } from 'components/utilities';
import {
	ARCHIVED_DRUGS_TABLE_QUERY_KEY,
	ARCHIVED_DRUGS_TABLE_RESOURCES_API_URL
} from 'helpers';
import { DrugsRepository } from 'queries';
import { replacePatientIdQueryParam } from 'queries/common';
import React from 'react';
import { useParams } from 'react-router-dom';

import { columns } from '../../columns';
import TableBodyRow from '../TableBodyRow';
import { Title } from './components';
import { useStyles } from './styles';

const ArchivedDrugs = () => {
	const classes = useStyles();
	const { patientId } = useParams();

	const { isRefetchArchivedDrugs, toggleArchivedDrugs } = useDrugsContext();

	const getSettingsCellProps = React.useCallback(
		() => ({
			label: 'patients.drugs.table.unarchive',
			action: DrugsRepository.unarchive
		}),
		[]
	);

	return (
		<Box className={classes.root}>
			<ToggleRPC>
				{({ on, toggle }) => (
					<>
						<Title toggleCollapse={toggle} isOpen={on} />
						<Collapse
							in={on}
							className={classes.collapse}
							classes={{ wrapper: classes.marginBottom }}
						>
							<ControlledTableProvider
								url={replacePatientIdQueryParam(
									ARCHIVED_DRUGS_TABLE_RESOURCES_API_URL,
									patientId
								)}
								columns={columns}
								queryKey={`${ARCHIVED_DRUGS_TABLE_QUERY_KEY}-${patientId}`}
							>
								<ControlledTable
									isDefaultRow={false}
									className={classes.table}
									isToolbarHidden={true}
									manualRefetch={isRefetchArchivedDrugs}
									setManualRefetch={toggleArchivedDrugs}
								>
									{({ row }) => (
										<TableBodyRow
											key={row.id}
											row={row}
											getSettingsCellProps={
												getSettingsCellProps
											}
										/>
									)}
								</ControlledTable>
							</ControlledTableProvider>
						</Collapse>
					</>
				)}
			</ToggleRPC>
		</Box>
	);
};

export default ArchivedDrugs;
