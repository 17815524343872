import {
	useAppointmentFormContext,
	useScheduleCalendarContext
} from 'components/context';
import { useForm } from 'react-final-form';

import { FIELD_NAME } from './PatientSelect';

const usePatientSelect = () => {
	const { change } = useForm();

	const { patientsList } = useAppointmentFormContext();
	const { isAppointmentReview } = useScheduleCalendarContext();

	const renderOptionSelected = (option, value) => {
		const fullNameOption = `${option.firstName} ${option.lastName}`;
		const fullNameValue =
			value?.firstName && value?.lastName
				? `${value?.firstName} ${value?.lastName}`
				: value?.name ?? null;
		return fullNameOption === (fullNameValue || value);
	};

	const handleChange = (patient) => {
		change(FIELD_NAME.PATIENT_ID, patient?.id || null);
	};

	return {
		patientsList,
		isAppointmentReview,
		renderOptionSelected,
		onChange: handleChange
	};
};

export default usePatientSelect;
