import type { UseQueryOptionsProps } from 'components/utilities';
import { useQuery } from 'components/utilities';
import { PATIENT_LINK_INVITATION_QUERY_KEY } from 'helpers';
import { PatientLinkInvitationAcceptanceModel } from 'models/Patient/PatientLink';
import { useParams } from 'utilities';

import { PatientRepository } from './repository';

export const useAcceptLinkInvitation = (
	options: UseQueryOptionsProps<PatientLinkInvitationAcceptanceModel> = {}
) => {
	const { token } = useParams();

	const queryData = useQuery(
		[PATIENT_LINK_INVITATION_QUERY_KEY, token],
		() => PatientRepository.acceptLinkInvitation({ token }),
		options
	);
	return queryData;
};
