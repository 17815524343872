import clsx from 'clsx';
import { Button, NotistackSnackbar } from 'components/elements';
import { ISnackbarMessageProps } from 'components/elements/molecules/Snackbar/components/SnackbarMessage/SnackbarMessage';
import { useTranslations } from 'components/utilities';
import { closeSnackbar, CustomContentProps, SnackbarKey } from 'notistack';
import React from 'react';

import { useStyles } from './styles';

export interface IActionNotificationProps
	extends Omit<CustomContentProps, 'variant' | 'message'> {
	contentMessage: ISnackbarMessageProps['message'];
	actionLabel?: TranslationKey;
	secondaryLabel?: TranslationKey;
	onActionClick?: (id: SnackbarKey) => void;
	onSecondaryActionClick?: (id: SnackbarKey) => void;
	title?: string;
}

//eslint-disable-next-line
export const ActionNotification = React.forwardRef<
	HTMLDivElement,
	IActionNotificationProps
>(
	(
		{
			id,
			contentMessage,
			actionLabel,
			secondaryLabel,
			onActionClick,
			onSecondaryActionClick,
			title,
			...rest
		},
		ref
	) => {
		const classes = useStyles();
		const { t } = useTranslations();

		return (
			<NotistackSnackbar
				ref={ref}
				contentVariant="secondary"
				id={id}
				titleProps={{ title: title }}
				{...rest}
				message={contentMessage}
			>
				<div className={classes.actionButtonsContainer}>
					{secondaryLabel && (
						<Button
							variant="text"
							size="tiny"
							onClick={() => {
								onSecondaryActionClick?.(id);
								closeSnackbar(id);
							}}
							className={clsx([
								classes.button,
								classes.secondaryButton
							])}
						>
							{t(secondaryLabel)}
						</Button>
					)}
					{actionLabel && (
						<Button
							variant="contained"
							size="tiny"
							onClick={() => {
								onActionClick?.(id);
								closeSnackbar(id);
							}}
							className={classes.button}
						>
							{t(actionLabel)}
						</Button>
					)}
				</div>
			</NotistackSnackbar>
		);
	}
);
