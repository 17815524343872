import { CornerCloseButton } from 'components/elements/molecules';
import { ModalDialog } from 'components/elements/organisms';
import { IModalDialogProps } from 'components/elements/organisms/ModalDialog/ModalDialog';
import React from 'react';

import { CustomAlert } from '../../../elements/molecules/Alerts';

export interface IPatientNotLinkedModalDialogProps {
	open: IModalDialogProps<never>['open'];
	error: {
		isError: boolean;
		message: string;
	};
	close: IModalDialogProps<never>['close'];
	onExited: IModalDialogProps<never>['onExited'];
}
export const PatientNotLinkedModalDialog = ({
	open,
	error: { isError, message } = { isError: false, message: '' },
	onExited,
	close
}: IPatientNotLinkedModalDialogProps) => {
	if (!isError) return null;

	return (
		<ModalDialog
			open={open}
			close={close}
			maxWidth="sm"
			actionButtonsProps={{
				disableActionButtons: false,
				cancel: {
					label: 'btn.ok',
					variant: 'contained'
				}
			}}
			onExited={onExited}
		>
			{() => (
				<>
					<CornerCloseButton onClick={close} />
					<CustomAlert message={message} />
				</>
			)}
		</ModalDialog>
	);
};
