import { makeStyles } from '@material-ui/styles';
import { addDays } from 'date-fns';
import { bool, number, object, string } from 'prop-types';

import { ItemWrapper } from '../components';
import {
	getExaminationProps,
	getHeartRateProps,
	getPefProps,
	RESULT_TYPES
} from '../helpers';
import Item from './Item';

const useStyles = makeStyles(({ palette }) => ({
	root: {
		alignSelf: ({ isUser }) => (isUser ? 'flex-end' : 'flex-start'),
		marginBottom: 7
	},
	item: {
		maxWidth: 300,
		marginBottom: 3,
		borderRadius: 12,
		padding: 0,
		backgroundColor: 'transparent'
	},
	iconWrapper: {
		display: 'flex',
		justifyContent: 'flex-end',
		paddingRight: 2
	},
	readIcon: {
		height: 5
	},
	timeStamp: {
		color: palette.primary.main
	}
}));

const { PEAK_FLOW, EXAMINATION, HEART_RATE } = RESULT_TYPES;

const Result = ({
	author_id: authorId,
	userId,
	type,
	created_at,
	next,
	...rest
}) => {
	const isUser = authorId === userId;
	const isNextUser = next.author_id === userId;
	const classes = useStyles({ isUser });

	const getItemProps = () => {
		switch (type) {
			case HEART_RATE:
				return getHeartRateProps(rest);

			case EXAMINATION:
				return getExaminationProps(rest);

			case PEAK_FLOW:
				return getPefProps(rest);

			default:
				break;
		}
	};
	return (
		<ItemWrapper
			classNameItem={classes.item}
			isUser={isUser}
			isNextUser={isNextUser}
			messageTimestamp={new Date(created_at).getTime()}
			nextMessageTimestamp={(next?.created_at
				? new Date(next?.created_at)
				: addDays(new Date(), 1)
			).getTime()}
			timestampColorClassName={classes.timeStamp}
			{...rest}
		>
			<Item data={getItemProps()} />
		</ItemWrapper>
	);
};

Result.propTypes = {
	type: string.isRequired,
	author_id: number.isRequired,
	userId: number.isRequired,
	viewed: bool.isRequired,
	created_at: string.isRequired,
	next: object.isRequired
};

export default Result;
