import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslations } from 'components/utilities';
import { number, string } from 'prop-types';
import React from 'react';

import { Label } from '../../Typography';
import { useStyles } from './styles';

const Question = ({ question, answer, spacing = 6, classNameAnswer }) => {
	const classes = useStyles({ spacing });
	const { t } = useTranslations();
	return (
		<Box className={classes.wrapper}>
			<Label label={t(question)} className={classes.question} />
			<Label
				label={t(answer)}
				className={clsx([classes.answer, classNameAnswer])}
			/>
		</Box>
	);
};

Question.propTypes = {
	question: string.isRequired,
	answer: string.isRequired,
	classNameAnswer: string,
	spacing: number
};

export default Question;
