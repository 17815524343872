import { makeStyles } from '@material-ui/styles';
import palette from 'theme/palette';

export const useCommonStyles = makeStyles(() => ({
	rootContainer: {
		width: '100%',
		display: 'flex',
		borderBottom: `1px solid ${palette.border.light}`
	}
}));
