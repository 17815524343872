import { endOfDay, startOfDay } from 'date-fns';
import { isLastArrayIndex } from 'helpers';
import theme from 'theme';
import { LocaleDate } from 'utilities';

import { LinkedSeriesFacade } from './LinkedSeriesFacade';
import { POINTS_TOWER_ROLE, ScatterLineChart } from './ScatterLineChart';
import { SplineChart } from './SplineChart';
import { YAxis } from './YAxis';

export class HighCharts {
	#highChart;
	#highChartContainer;

	constructor(highChartRef) {
		this.#highChartContainer = highChartRef?.current?.container;
		this.#highChart = highChartRef?.current?.chart;
	}

	removeAllSeries = () => {
		this.#highChart.series.forEach((serie) => serie.remove());
	};

	resizeChart = () => {
		this.#highChart.reflow();
	};

	addSeries = (
		{ chartName, data: { trend, scatter } },
		isLastSerie = true
	) => {
		const chartNameUpperCase = chartName.toUpperCase();
		const spline = new SplineChart(trend, chartNameUpperCase);
		const scatterLine = new ScatterLineChart(scatter, chartNameUpperCase);
		const series = [spline, scatterLine];
		series.forEach((serie, index) => {
			const lastChart = isLastSerie && isLastArrayIndex(index, series);
			this.#highChart.addSeries(serie, lastChart);
		});
	};

	removeSerie = (chartName) => {
		const serie = this.#highChart.get(chartName.toUpperCase());
		if (serie) {
			const linkedSeries = new LinkedSeriesFacade(serie.linkedSeries);
			linkedSeries.remove();
			serie?.remove?.();
		}
	};

	findSerieIndex(chartName, type) {
		return this.#highChart.series.findIndex(
			(serie) => serie.name === chartName && serie.type === type
		);
	}

	getHighChart = () => {
		return { chart: this.#highChart, container: this.#highChartContainer };
	};

	getContainerClientWidth = () =>
		this.#highChartContainer.current.clientWidth;

	getContainerClientHeight = () =>
		this.#highChartContainer.current.clientHeight;

	setDefaultStyles = (target) => {
		target.update(
			{
				selected: false,
				opacity: 1,
				states: {
					hover: {
						enabled: true
					},
					inactive: {
						opacity: 0.2
					}
				}
			},
			false
		);
	};

	setInitialYAxisVisibility = (patientPersonalBests) => {
		this.#resetYAxisVisibility();
		const indexOfYAxisGroup = YAxis.getSeriesYAxisGroupIndex(
			this.#highChart.series
		);

		if (indexOfYAxisGroup !== -1) {
			const { data } = YAxis.getYAxisGroupInitialData(
				patientPersonalBests
			)(this.#highChart.series, indexOfYAxisGroup);
			this.#highChart.yAxis[indexOfYAxisGroup].update(data);
		}
	};

	setYAxisSoftMinSoftMax = (patientPersonalBest, selectedChartParameters) => {
		selectedChartParameters.forEach((parameter) => {
			const parameterUpperCase = parameter.toUpperCase();
			const personalBestValue = patientPersonalBest?.[parameter];

			const serie = this.#highChart.get(parameterUpperCase);

			const minMaxScatterValues =
				ScatterLineChart.getMinMaxValueInExtremesRange(
					serie.linkedSeries[0]
				);
			const yAxis = new YAxis(serie.yAxis);

			yAxis.updateSoftMinSoftMax(
				minMaxScatterValues,
				personalBestValue,
				serie.yAxis.userOptions
			);
		});
	};

	toggleSerie = async (
		chartName,
		selectedParameters,
		patientPersonalBests,
		fetchSerieDataCallback
	) => {
		if (selectedParameters.includes(chartName)) {
			await fetchSerieDataCallback();
		} else {
			const isRemovedSerieSelected =
				this.#highChart.getSelectedSeries()?.[0]?.name ===
				chartName.toUpperCase();
			this.removeSerie(chartName);
			if (isRemovedSerieSelected) {
				this.defaultSeriesSelection();
			}
			if (
				this.#highChart.yAxis?.[0]?.userOptions?.axisNames?.every(
					(name) => selectedParameters.includes(name.toUpperCase())
				)
			) {
				this.setInitialYAxisVisibility(patientPersonalBests);
			}
		}
		const serie = this.#highChart.series?.[0];
		if (serie && selectedParameters.length === 1) {
			this.showSerie(serie);
		}
		this.setInitialYAxisVisibility(patientPersonalBests);
	};

	hideSeriesOtherThanGiven = (chartName) => {
		this.#highChart.series.forEach((serie) => {
			if (this.#shouldHideSerie(serie, chartName)) {
				const isSelectedChartNameInSerieYAxisGroup =
					serie.yAxis.userOptions.axisNames.includes(chartName);
				const linkedSeries = new LinkedSeriesFacade(
					serie?.linkedSeries
				);

				const yAxis = new YAxis(serie.yAxis);
				linkedSeries.setDefaultStyles();
				yAxis.setDefaultStyles(isSelectedChartNameInSerieYAxisGroup);

				serie.update(
					{
						selected: false,
						opacity: 0.2
					},
					false
				);
			}
		});
	};

	defaultSeriesSelection = () => {
		const isYAxisVisibility =
			YAxis.getSeriesYAxisGroupIndex(this.#highChart.series) !== -1;

		this.#highChart.series.forEach((serie) => {
			if (
				serie.name.includes('Navigator') ||
				serie?.userOptions?.role === POINTS_TOWER_ROLE
			)
				return;
			const yAxis = new YAxis(serie.yAxis);
			const linkedSeries = new LinkedSeriesFacade(serie.linkedSeries);
			yAxis.setDefaultStyles(isYAxisVisibility);
			if (serie.options.data.length === 1) {
				linkedSeries.setVisibility(true);
				linkedSeries.showSerie();
			} else {
				linkedSeries.setDefaultStyles();
			}
			this.setDefaultStyles(serie);
		});
		this.#highChart.redraw();
	};

	updateXAxisEvents = (events) => {
		this.#highChart.update({
			xAxis: {
				events
			}
		});
	};

	updateXAxisExtremes = ({ startDate, endDate }) => {
		const xAxis = this.#getXAxis();
		xAxis.setExtremes(
			startOfDay(startDate).getTime(),
			endOfDay(endDate).getTime()
		);
	};

	chartClickEvent = () => {
		const selectedSeries = this.#highChart.getSelectedSeries();

		if (selectedSeries.length > 0 && this.#highChart.series.length > 3) {
			this.defaultSeriesSelection();
		}
	};

	updateChartEvents = (callback) => {
		this.#highChart.update({
			chart: {
				events: {
					click: () => {
						this.chartClickEvent();
						callback?.();
					}
				}
			}
		});
	};
	updateSeriesEvents = (patientPersonalBest, callback) => {
		this.#highChart.update({
			chart: {
				events: {
					click: () => {
						this.chartClickEvent();
						callback?.();
					}
				}
			},
			plotOptions: {
				line: {
					events: {
						mouseOver: YAxis.mouseOver(patientPersonalBest),
						mouseOut: YAxis.mouseOut(patientPersonalBest),
						click: (e) => {
							this.setActivePlotLine(e.point.series);
							callback?.();
						}
					}
				}
			}
		});
	};

	setActivePlotLine({ selected, name: chartName }) {
		if (
			this.#highChart?.getSelectedSeries()?.length > 0 &&
			this.#highChart?.series?.length > 3
		) {
			this.defaultSeriesSelection();
		}

		if (!selected) {
			const serie = this.#highChart.get(chartName);
			this.showSerie(serie);
			this.hideSeriesOtherThanGiven(chartName);
		}
		this.#highChart.redraw();
	}

	updateNavigatorColouredZone = (startDate, endDate) => {
		const startDateTimestamp = new LocaleDate(startDate)
			.getDate()
			.getTime();
		const endDateTimestamp = new LocaleDate(endDate).getDate().getTime();

		this.#highChart.navigator.series.forEach((serie, index) => {
			const isLastItem = isLastArrayIndex(
				index,
				this.#highChart.navigator.series
			);

			serie.update(
				{
					zoneAxis: 'x',
					zones: [
						{
							value: startDateTimestamp,
							color: theme.palette.parameters.navigator.outerZone
						},
						{
							value: endDateTimestamp,
							color: serie.color
						},
						{ color: theme.palette.parameters.navigator.outerZone }
					]
				},
				isLastItem
			);
		});
	};

	updateNavigatorXAxisExtremes = (data) => {
		let seriesData = data;
		if (Array.isArray(data)) {
			seriesData = data[0];
		}
		this.#highChart.navigator.update({
			xAxis: {
				min: new Date(seriesData.chartStartDate).getTime(),
				max: new Date(seriesData.chartEndDate).getTime()
			}
		});
	};

	#resetYAxisVisibility = () => {
		this.#highChart.yAxis.forEach((axis, index) => {
			const isLastItem = isLastArrayIndex(index, this.#highChart.yAxis);
			axis.visible && axis.update({ visible: false }, isLastItem);
		});
	};

	showSerie = (serie) => {
		serie.update(
			{
				visible: true,
				selected: true,
				opacity: 1,
				states: {
					inactive: {
						opacity: 1
					}
				}
			},
			false
		);

		const linkedSeries = new LinkedSeriesFacade(serie?.linkedSeries);
		linkedSeries.showSerie();
		linkedSeries.setVisibility(true);
	};

	#shouldHideSerie = (serie, chartName) =>
		serie.name !== chartName &&
		!serie.name.includes('Navigator') &&
		serie.userOptions?.role !== POINTS_TOWER_ROLE;

	#getXAxis = () => this.#highChart.xAxis[0];
}
