import palette from '../palette';

const TRANSITION_DURATION = '300ms';
export default {
	root: {
		width: 46,
		height: 24,
		padding: 0,
		'& .MuiSwitch-switchBase': {
			padding: 0,
			margin: 2,
			transitionDuration: TRANSITION_DURATION,
			'&.Mui-checked': {
				transform: 'translateX(22px)',
				color: palette.white,
				'& + .MuiSwitch-track': {
					backgroundColor: palette.primary.main,
					opacity: 1,
					border: 0
				},
				'&.Mui-disabled + .MuiSwitch-track': {
					opacity: 0.5
				}
			},
			'&.Mui-focusVisible .MuiSwitch-thumb': {
				color: '#33cf4d',
				border: `6px solid ${palette.white}`
			},
			'&.Mui-disabled .MuiSwitch-thumb': {
				color: palette.switches.dark
			},
			'&.Mui-disabled + .MuiSwitch-track': {
				opacity: 0.3
			}
		},
		'& .MuiSwitch-thumb': {
			boxSizing: 'border-box',
			width: 20,
			height: 20
		},
		'& .MuiSwitch-track': {
			borderRadius: 26 / 2,
			backgroundColor: palette.switches.main,
			opacity: 1,
			transition: `background-color ${TRANSITION_DURATION} ease-in-out`
		}
	}
};
