import { GOOGLE_TASK_MANAGER } from 'helpers/variables';
import React from 'react';
// import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router-dom';

const tagManagerArgs = {
	// eslint-disable-next-line
	gtmId: process.env.REACT_APP_GTM_ID
};

const { PAGE_VIEW } = GOOGLE_TASK_MANAGER;

const useGTM = () => {
	const { pathname } = useLocation();
	function gtag() {
		window.dataLayer.push(arguments);
	}
	React.useEffect(() => {
		// TagManager.initialize(tagManagerArgs);
		window.dataLayer = window.dataLayer || [];
		gtag('js', new Date());
		gtag('config', tagManagerArgs.gtmId);
	}, []);

	React.useEffect(() => {
		window.dataLayer = window.dataLayer || [];
		gtag({
			event: PAGE_VIEW
		});
	}, [pathname]);
};

export default useGTM;
