import { Box } from '@material-ui/core';
import Image from 'material-ui-image';
import { object } from 'prop-types';
import React from 'react';

const SignInSideBanner = ({ classes }) => (
	<Box className={classes.siedeBannerWrapper} style={{ flex: 1 }}>
		<Image
			src="/images/wiz_960x944.png"
			srcSet="/images/wiz_960x944.png 1x, /images/wiz_1440x1416.png 2x"
			className={classes.image}
		/>
	</Box>
);

SignInSideBanner.propTypes = {
	classes: object.isRequired
};

export default SignInSideBanner;
