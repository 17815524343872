import { PatientListItemModel } from 'models/Patient/PatientListItem';
import { INVITATION_STATUS } from 'models/Patient/PatientModel';
import React from 'react';
import { FIREBASE_MESSAGING_TYPES } from 'utilities/firebase/types';
import { PubSub } from 'utilities/PubSub';

import { IAccountLinkCollapsedChipProps } from './AccountLinkCollapsedChip';
export const useInvitationStatusMessagingListener = ({
	invitationStatus,
	patientId
}: {
	invitationStatus: PatientListItemModel['invitationStatus'];
	patientId: IAccountLinkCollapsedChipProps['patientId'];
}) => {
	const [status, setStatus] = React.useState(invitationStatus);

	React.useEffect(() => {
		setStatus(invitationStatus);
	}, [invitationStatus]);

	React.useEffect(() => {
		function validatePatientId(id?: string) {
			return id != patientId;
		}
		const token = PubSub.firebaseMessaging.subscribe((message) => {
			switch (message.data.type) {
				case FIREBASE_MESSAGING_TYPES.INVITATION_ACCEPTED_BY_PATIENT:
					if (validatePatientId(message.data.patientId)) {
						return;
					}
					setStatus(INVITATION_STATUS.LINKED);
					break;
				case FIREBASE_MESSAGING_TYPES.INVITATION_DECLINED_BY_PATIENT:
				case FIREBASE_MESSAGING_TYPES.SHARING_CANCELLED_BY_PATIENT:
					if (validatePatientId(message.data.patientId)) {
						return;
					}
					setStatus(INVITATION_STATUS.UNLINKED);
					break;
			}
		});

		return () => {
			PubSub.firebaseMessaging.unsubscribe(token);
		};
	}, []);

	const setStatusPending = React.useCallback(() => {
		if (status === INVITATION_STATUS.UNLINKED) {
			setStatus(INVITATION_STATUS.PENDING);
		}
		if (status === INVITATION_STATUS.LINKED) {
			setStatus(INVITATION_STATUS.UNLINKED);
		}
	}, []);

	return { status, setStatusPending };
};
