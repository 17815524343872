import { numberRegex, numberWithDecimalRegex } from 'helpers';
import { HEIGHT_RANGE } from 'helpers/variables';

import { ADDITIONAL_FIELD_NAMES } from './fields';

const [, , , ft] = Object.keys(HEIGHT_RANGE);

export const getFieldRegex = (regex, fieldName, values) => {
	if (!regex) {
		return;
	}

	switch (fieldName) {
		case ADDITIONAL_FIELD_NAMES.HEIGHT:
			return getHeightValueRegex(
				values[ADDITIONAL_FIELD_NAMES.HEIGHT_UNIT]
			);
		case ADDITIONAL_FIELD_NAMES.WEIGHT:
			return getWeightValueRegex(
				values[ADDITIONAL_FIELD_NAMES.WEIGHT_UNIT]
			);
		default:
			return;
	}
};

const getWeightValueRegex = (unit) => {
	switch (unit) {
		default:
			return numberRegex;
	}
};

const getHeightValueRegex = (unit) => {
	switch (unit) {
		case ft:
			return numberWithDecimalRegex;
		default:
			return numberRegex;
	}
};
