import { FormControl, FormLabel, RadioGroup } from '@material-ui/core';
import { useTranslations } from 'components/utilities';
import { bool, node, object, oneOfType, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-final-form';

import { InputError } from '../../atoms';

const RadioFieldAdapter = ({
	input,
	meta,
	label,
	required,
	disabled,
	initialVal = '',
	currentValue = '',
	classNameLabel = '',
	children,
	...rest
}) => {
	const [value, setValue] = useState(initialVal || input.value);
	const { t } = useTranslations();
	const { change } = useForm();

	useEffect(() => {
		currentValue ? setValue(currentValue) : change(input.name, initialVal);
		//eslint-disable-next-line
	}, [currentValue]);

	const handleChange = (e) => {
		const value = e.target.value;
		change(input.name, value);
		setValue(value);
	};

	return (
		<FormControl
			component="fieldset"
			required={required}
			disabled={disabled}
			style={{ width: '100%' }}
		>
			{label && (
				<FormLabel
					component="legend"
					focused={false}
					className={classNameLabel}
				>
					{t(label)}
				</FormLabel>
			)}
			<RadioGroup
				aria-label="radio-group"
				value={value}
				onChange={handleChange}
				{...rest}
			>
				{children}
			</RadioGroup>
			<InputError meta={meta} />
		</FormControl>
	);
};

RadioFieldAdapter.propTypes = {
	input: object.isRequired,
	label: string,
	meta: object.isRequired,
	required: bool.isRequired,
	disabled: bool.isRequired,
	initialVal: string,
	classNameLabel: string,
	currentValue: oneOfType([string, bool]),
	children: node.isRequired
};

export default RadioFieldAdapter;
