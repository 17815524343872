import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		width: 97,
		display: 'flex',
		justifyContent: 'center',
		background: ({ isGroupActive }) =>
			isGroupActive ? theme.palette.gray.main : 'transparent',
		cursor: 'pointer',

		[theme.breakpoints.down(890)]: {
			marginRight: 0
		}
	},
	wrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		margin: `${theme.spacing(0.3)}px 0`,
		width: 90,
		background: ({ isGroupActive }) =>
			isGroupActive ? theme.palette.white : 'transparent',
		padding: '15px 8px',
		borderRadius: 6,
		position: 'relative'
	},
	name: { color: theme.palette.black, marginTop: 12, fontSize: '13px' },
	icon: {
		height: 33,
		marginTop: theme.spacing(0.5)
	},
	iconActive: {
		'& path': {
			fill: theme.palette.primary.main
		}
	},
	arrowDownIcon: {
		width: 90,
		position: 'absolute',
		bottom: -15,
		[theme.breakpoints.down(890)]: {
			display: 'none'
		},
		'& path': {
			fill: theme.palette.primary.main
		}
	}
}));
