import { Box, Button, Divider, List, ListItem } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslations } from 'components/utilities';
import { SETTINGS_NAV_LINKS, SETTINGS_NAV_LINKS_INFO } from 'helpers';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { useStyles } from './styles';

// eslint-disable-next-line
const CustomRouterNavLink = React.forwardRef((props, ref) => (
	<NavLink ref={ref} {...props} />
));

const Navigation = () => {
	const roles = useSelector(({ auth }) => auth.authenticate.data.roles);
	const classes = useStyles();
	const { t } = useTranslations();

	const renderLinks = (links) =>
		links.map(
			({ title, href, icon, role }) =>
				role.includes(roles[0]) && (
					<ListItem
						key={`${title}-${href}`}
						className={classes.item}
						disableGutters
					>
						<Button
							activeClassName={classes.active}
							className={classes.button}
							component={CustomRouterNavLink}
							to={href}
							startIcon={icon}
						>
							{t(title)}
						</Button>
					</ListItem>
				)
		);

	return (
		<Box className={classes.root}>
			<List className={classes.list}>
				{renderLinks(SETTINGS_NAV_LINKS)}
			</List>
			{/* <Hidden mdUp> */}
			<Divider />
			{/* </Hidden> */}
			<List className={clsx(classes.list, classes.listSystem)}>
				{renderLinks(SETTINGS_NAV_LINKS_INFO)}
			</List>
		</Box>
	);
};

export default Navigation;
