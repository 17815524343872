import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import {
	AppointmentFormProvider,
	ScheduleCalendarProvider
} from 'components/context';
import { ScheduleRoutes } from 'components/routes';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useBaseLayoutStyles } from 'theme/styles';

import Calendar from './Calendar';
import { getGridColumns } from './helpers';
import { useStyles } from './styles';

const Schedule = () => {
	const classes = useStyles();
	const baseLayoutClasses = useBaseLayoutStyles();
	const { pathname } = useLocation();

	const { calendarColumns } = getGridColumns(pathname);

	return (
		<ScheduleCalendarProvider>
			<AppointmentFormProvider>
				<Grid
					container
					spacing={0}
					className={clsx([
						baseLayoutClasses.portViewFullHeight,
						classes.container
					])}
				>
					<Grid item {...calendarColumns}>
						<Calendar />
					</Grid>
					<ScheduleRoutes />
				</Grid>
			</AppointmentFormProvider>
		</ScheduleCalendarProvider>
	);
};

export default Schedule;
