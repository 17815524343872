import { Box, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { Sidebar, Topbar } from './components';
import useMain from './useMain';

const Main = (props) => {
	const { children } = props;

	const {
		isSignOutLoading,
		baseLayoutClasses,
		classes,
		shouldOpenSidebar,
		handleSidebarOpen,
		handleSidebarClose,
		isDesktop
	} = useMain();

	return (
		<Box
			className={clsx({
				[classes.root]: true
			})}
		>
			<Topbar onSidebarOpen={handleSidebarOpen} />
			{isSignOutLoading && (
				<Box className={classes.signOutBox}>
					<CircularProgress size={70} color="primary" />
				</Box>
			)}
			{!isDesktop && (
				<Sidebar
					onClose={handleSidebarClose}
					open={shouldOpenSidebar}
					variant={isDesktop ? 'persistent' : 'temporary'}
				/>
			)}

			<main>
				<Box className={baseLayoutClasses.root}>
					<Box className={classes.bg} />
					<Box className={baseLayoutClasses.content}>{children}</Box>
					{/* <ToastContainer /> */}
				</Box>
			</main>
		</Box>
	);
};

Main.propTypes = {
	children: PropTypes.node
};

export default Main;
