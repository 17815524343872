import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	loader: {
		textAlign: 'center'
	},
	btnWrapper: {
		margin: '38px 0 10px 0'
	}
}));
