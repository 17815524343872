import { Box } from '@material-ui/core';
import { ExaminationCopdTypeLabel } from 'components/elements/atoms/ExaminationsV2';
import { SpirometryPreviewModel } from 'models/Examinations/allExaminationsTypes';
import React from 'react';

import DateTimeLabel from '../DateTimeLabel';
import { useStyles } from './styles';

type Props = {
	createDateTime: string;
	examinationType: SpirometryPreviewModel['examinationType'];
};

const TopBar = ({ createDateTime, examinationType }: Props) => {
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<ExaminationCopdTypeLabel
				examinationType={examinationType}
				className={classes.copdTypeLabel}
			/>
			<DateTimeLabel createDateTime={createDateTime} />
		</Box>
	);
};

export default TopBar;
