import { SMOKER_OPTIONS_VALUES } from 'helpers/variables';

const { NO, YES, PASSIVE_SMOKER, EX_SMOKER } = SMOKER_OPTIONS_VALUES;

export const OPTIONS = [
	{
		value: NO,
		label: 'patients.form.no',
		grid: {
			lg: 2,
			xs: 6
		}
	},
	{
		value: YES,
		label: 'patients.form.yes',
		grid: {
			lg: 2,
			xs: 6
		}
	},
	{
		value: PASSIVE_SMOKER,
		label: 'patients.form.passive_smoker',
		grid: {
			lg: 4,
			xs: 6
		}
	},
	{
		value: EX_SMOKER,
		label: 'patients.form.patient_ex_smoker_since',
		grid: {
			lg: 4,
			xs: 6
		}
	}
];
