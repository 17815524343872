import { Typography as MuiTypography } from '@material-ui/core';
import { useTranslations } from 'components/utilities';
import React from 'react';

import { useStyles } from './styles';

const AddAppointment = () => {
	const { t } = useTranslations();
	const classes = useStyles();
	return (
		<MuiTypography
			component="span"
			variant="body1"
			color="primary"
			className={classes.label}
		>
			{t('schedule.calendar.add_appointment')}
		</MuiTypography>
	);
};

export default AddAppointment;
