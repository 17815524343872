import { RequestConfirmationModalDialog } from 'components/elements';
import { InvitationStatus } from 'models/Patient/PatientModel';
import React from 'react';

import { PatientInvitationAdditionalContent } from '../common/PatientInvitationAdditionalContent';
import { usePatientInvitation } from '../usePatientInvitation';

export interface IPatientInvitationModalProps {
	initialEmail: string;
	invitationStatus: InvitationStatus;
	patientId: string | number;
	patientFullName: string;
	children: (props: {
		isLoading: boolean;
		invitationStatus: InvitationStatus;
		isPatientUnlinked: boolean;
		setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	}) => CmpChildren;
	onClose?: () => void;
	onSuccess?: () => void;
	onExited?: () => void;
}

export const PatientInvitationModal = ({
	initialEmail,
	invitationStatus,
	patientId,
	patientFullName,
	children,
	onClose = () => {},
	onSuccess = () => {},
	onExited = () => {}
}: IPatientInvitationModalProps) => {
	const {
		open,
		setOpen,
		modalProps,
		mutationOptions,
		patientCardAPIStatus,
		invitation,
		isPatientUnlinked,
		callInvitationAction,
		onChange,
		onInvitationSuccess,
		email,
		isValidEmail,
		isDirty,
		setEmailInputAsDirty,
		setEmail
	} = usePatientInvitation({
		patientFullName,
		patientId,
		initialEmail,
		invitationStatus,
		onSuccess
	});
	return (
		<>
			{children({
				isLoading: patientCardAPIStatus?.isFetching ?? false,
				setOpen,
				isPatientUnlinked,
				invitationStatus: invitation
			})}
			<RequestConfirmationModalDialog
				apiError={mutationOptions.apiError}
				isLoading={mutationOptions.isLoading}
				isSuccess={mutationOptions.isSuccess}
				requestConfirmation={callInvitationAction}
				open={open}
				setClose={() => {
					setOpen(false);
					onClose?.();
				}}
				onExited={() => {
					if (mutationOptions.isSuccess) {
						mutationOptions.reset();
						setEmail(initialEmail ?? '');
						onExited?.();
					}
				}}
				{...modalProps}
				successProps={{
					...modalProps.successProps,
					close: () => {
						setOpen(false);
						onClose?.();
						onInvitationSuccess();
					}
				}}
			>
				<PatientInvitationAdditionalContent
					isPatientUnlinked={isPatientUnlinked}
					emailValue={email}
					onChange={onChange}
					isValidEmail={isValidEmail}
					isDirty={isDirty}
					setEmailInputAsDirty={setEmailInputAsDirty}
				/>
			</RequestConfirmationModalDialog>
		</>
	);
};
