import { z } from 'zod';

import { ExaminationTypeSchema } from './common';

export const TranslateIdToSignStrRequestSchema = z.undefined();

export const TranslateIdToSignStrResponseSchema = z.object({
	examinationId: z.string(),
	signStr: z.string(),
	examinationType: ExaminationTypeSchema
});
