import { format } from 'date-fns';
import {
	calculateAge,
	convertCountryCodeToName,
	convertPhoneNumber
} from 'helpers';
import { DATE_FORMAT } from 'helpers/variables';

export const BASIC_FIELD_NAMES = {
	FIRST_NAME: 'patientFirstName',
	LAST_NAME: 'patientLastName',
	EMAIL: 'patientEmail',
	DATE: 'patientDob',
	PREFIX: 'patientPhonePrefix',
	PHONE_NUMBER: 'patientPhoneNumber',
	COUNTRY_CODE: 'patientCountryCode',
	CITY: 'patientCity',
	GENDER: 'patientGender',
	ID_NUMBER: 'patientIdNumber'
};

const { DATE, COUNTRY_CODE, PHONE_NUMBER, EMAIL, CITY, ID_NUMBER, GENDER } =
	BASIC_FIELD_NAMES;

export const basicInformationsFields = (patientData, countries, classes) => [
	{
		label: 'patients.basic_data.field.email',
		name: EMAIL,
		type: 'email',
		rowClassName: classes.emailRow
	},
	{
		label: 'patients.basic_data.field.gender',
		name: GENDER,
		valueConverter: (gender) => `global.${gender?.toLowerCase()}`
	},
	{
		label: 'patients.basic_data.field.birth_date',
		name: DATE,
		valueConverter: (value) => ({
			key: 'settings.preferences.value_age',
			replaceKeys: {
				['%v1']: format(new Date(value), DATE_FORMAT.DEFAULT),
				['%v2']: calculateAge(value)
			}
		})
	},
	{
		label: 'patients.basic_data.field.phone',
		name: PHONE_NUMBER,
		valueConverter: (phoneNumber) =>
			convertPhoneNumber(phoneNumber, patientData.patientPhonePrefix)
	},
	{
		label: 'patients.basic_data.field.country',
		name: COUNTRY_CODE,
		valueConverter: (countryCode) =>
			countryCode
				? convertCountryCodeToName(countryCode, countries?.data)
				: null
	},
	{
		label: 'patients.basic_data.field.city',
		name: CITY
	},
	{
		label: 'patients.basic_data.field.id',
		name: ID_NUMBER
	}
];
