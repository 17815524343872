import { useTranslations } from 'components/utilities';
import { arrayOf, node, oneOfType, string } from 'prop-types';
import React from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

import { Alert } from '../Alerts';

const ErrorBoundary = ({
	children,
	text = 'patients.examinations.error.message',
	...rest
}) => {
	const { t } = useTranslations();

	return (
		<ReactErrorBoundary
			{...rest}
			FallbackComponent={() => <Alert message={t(text)} />}
		>
			{children}
		</ReactErrorBoundary>
	);
};

ErrorBoundary.propTypes = {
	children: oneOfType([arrayOf(node), node]).isRequired,
	text: string
};

export default ErrorBoundary;
