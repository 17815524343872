import { ExaminationTypeSchema } from 'models/Examinations/common';
import { z } from 'zod';

export const DownloadPDFReportByLinkRequestSchema = z.object({
	patientId: z.string(),
	examinationType: ExaminationTypeSchema,
	examinationId: z.union([z.string(), z.number()]),
	fileKey: z.string()
});

export type DownloadPDFReportByLinkRequestModel = z.infer<
	typeof DownloadPDFReportByLinkRequestSchema
>;

export const DownloadPDFReportByLinkResponseSchema = z.object({
	url: z.string()
});

export type DownloadPDFReportByLinkResponseModel = z.infer<
	typeof DownloadPDFReportByLinkResponseSchema
>;
