import { Box, MenuItem as MuiMenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { RouterLink } from 'components/elements';
import { useTranslations } from 'components/utilities';
import { array, node, string } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	wrapper: {
		minWidth: 234,
		width: '100%',
		padding: '8px 25px 8px 25px',
		display: 'flex',
		borderBottom: `1px solid ${theme.palette.border.light}`,
		fontWeight: ({ isActive }) => (isActive ? 500 : 400),
		color: ({ isActive }) =>
			isActive ? theme.palette.primary.main : theme.palette.black,
		'& svg': {
			width: '15px',
			height: '15px',
			'& path': {
				fill: ({ isActive }) =>
					isActive ? theme.palette.primary.main : theme.palette.black
			}
		}
	},
	iconWrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		paddingRight: 15
	}
}));

//eslint-disable-next-line
const MenuItem = React.forwardRef(({ title, href, icon, role }, ref) => {
	const roles = useSelector(({ auth }) => auth.authenticate.data.roles);
	const { t } = useTranslations();
	const location = useLocation();
	const classes = useStyles({ isActive: location.pathname === href });

	return (
		role.includes(roles[0]) && (
			<MuiMenuItem
				component={RouterLink}
				to={href}
				className={classes.wrapper}
				ref={ref}
			>
				<Box className={classes.iconWrapper}>{icon}</Box>
				{t(title)}
			</MuiMenuItem>
		)
	);
});

MenuItem.propTypes = {
	title: string.isRequired,
	href: string.isRequired,
	icon: node.isRequired,
	role: array.isRequired
};

export default MenuItem;
