import { Cell, flexRender } from '@tanstack/react-table';
import { useVirtualizer, VirtualItem } from '@tanstack/react-virtual';
import clsx from 'clsx';
import { Table } from 'components/elements/atoms';
import {
	LoaderSpinnerTransitioned,
	TableHeadRow
} from 'components/elements/molecules';
import { ITableErrorAlertProps } from 'components/elements/molecules/Table/TableErrorAlert';
import {
	IUseTanStackTable,
	useTanStackTable
} from 'components/utilities/hooks/Table';
import { IDLE } from 'helpers';
import React from 'react';
import { QueryStatus } from 'react-query';

import { TableBodyContent } from '../TableBodyContent';
import { useStyles } from './styles';
import { IToolbarProps, Toolbar } from './Toolbar';
import { useVirtualTable } from './useVirtualTable';

export interface IVirtualTable<TData> extends IUseTanStackTable<TData> {
	/**
	 * IDLE value determines rendered components inside TableBody.
	 * Usually taken from react-query `status` field.
	 */
	idle: QueryStatus;
	/**
	 * apiError message threw by API. Usually taken as `apiError` field returned by react-query useQuery or useMutation hook.
	 * Value is connected with `idle` state. Message will be rendered only if `idle="ERROR"`
	 */
	apiError: ITableErrorAlertProps['apiError'];
	/**
	 * Additional actions/components rendered above table. Avoid to pass components other than buttons.
	 */
	toolbarActions?: IToolbarProps['toolbarActions'];
	/**
	 * Function which updates `data` array. Usually used when table row has been deleted or added.
	 */
	refreshData?: () => void;
	/**
	 * Determines if overlay loader should be rendered. Required to be changed when `refreshData` function is called.
	 */
	isFetching?: boolean;
	/**
	 * Determines if global filter searchbox input should be enabled.
	 */
	disableGlobalFilter?: Partial<IToolbarProps['disableGlobalFilter']>;
	/**
	 * CSS classNames object
	 */
	classes?: {
		root?: string;
		tableHead?: string;
		tableBody?: string;
	};
}

export const VirtualTable = <TData,>({
	idle,
	apiError,
	refreshData = () => {},
	isFetching = false,
	toolbarActions = null,
	classes: classesProp = { root: '', tableHead: '', tableBody: '' },
	// ---- IUseTanStackTable ---- //
	data,
	columns,
	defaultSortBy,
	middleware,
	// ----- IToolbarProps ----- //
	disableGlobalFilter = false
}: IVirtualTable<TData>) => {
	const tableContainerRef = React.useRef<HTMLDivElement>(null);
	const toolbarContainerRef = React.useRef<HTMLDivElement>(null);

	const { table, globalFilter, setGlobalFilter } = useTanStackTable({
		data,
		columns,
		defaultSortBy,
		middleware
	});

	const { rows } = table.getRowModel();

	const virtualizer = useVirtualizer<HTMLDivElement, HTMLTableRowElement>({
		count: rows.length,
		getScrollElement: () => tableContainerRef.current,
		estimateSize: () => 48,
		overscan: 5
	});

	const { cellSizingGetter, renderCell, refreshDataHandler } =
		useVirtualTable<TData>({ refreshData });

	const items = virtualizer.getVirtualItems();

	const classes = useStyles({
		toolbarContainerHeight:
			toolbarContainerRef?.current?.getClientRects()[0]?.height ?? 0
	});

	return (
		<div className={classes.root}>
			<Toolbar
				ref={toolbarContainerRef}
				refreshData={refreshData}
				disableGlobalFilter={disableGlobalFilter}
				globalFilter={globalFilter}
				setGlobalFilter={setGlobalFilter}
				toolbarActions={toolbarActions}
			/>
			<LoaderSpinnerTransitioned
				className={classes.refreshDataLoader}
				isLoading={idle === IDLE.SUCCESS && isFetching}
				isCover
			/>

			<div
				ref={tableContainerRef}
				className={clsx([classesProp?.root, classes.container])}
				style={{
					overflowY:
						idle === IDLE.LOADING || idle === IDLE.ERROR
							? 'hidden'
							: 'auto'
				}}
			>
				<div
					style={{
						height: virtualizer.getTotalSize() ?? 100,
						width: '100%',
						position: 'relative'
					}}
				>
					<div
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
							width: '100%',
							transform: `translateY(${items[0]?.start ?? 0}px)`
						}}
					>
						<Table>
							<TableHeadRow
								headerGroups={table.getHeaderGroups()}
								cellRenderer={renderCell}
								className={clsx([
									classesProp?.tableHead,
									classes.tableHead
								])}
								style={{
									top: -items[0]?.start || 0
								}}
								columnSizeGetter={cellSizingGetter}
								columnToggleSortingHandlerGetter={(header) =>
									header.column.getToggleSortingHandler()
								}
								columnIsSortedGetter={(header) =>
									header.column.getIsSorted()
								}
								columnCanSortGetter={(header) =>
									header.column.getCanSort()
								}
								columnTextAlignGetter={(header) =>
									header.column.columnDef.meta?.textAlign
								}
							/>
							<TableBodyContent<VirtualItem, Cell<TData, unknown>>
								className={classesProp.tableBody}
								idle={idle}
								apiError={apiError}
								rows={virtualizer.getVirtualItems()}
								numberOfColumns={columns.length}
								getRowData={(index) =>
									rows[index].getVisibleCells()
								}
								cellValueRenderer={(cell) =>
									flexRender(cell.column.columnDef.cell, {
										...cell.getContext(),
										refreshData: refreshDataHandler
									})
								}
								cellValueGetter={(cell) => cell.getValue()}
								cellAlignGetter={(cell) =>
									cell.column.columnDef.meta?.textAlign
								}
							/>
						</Table>
					</div>
				</div>
			</div>
		</div>
	);
};
