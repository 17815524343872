import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
		position: 'relative',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	divider: {
		height: 37,
		width: 1,
		background: theme.palette.gray.medium,
		opacity: 0.1
	},
	content: {
		display: 'flex',
		alignItems: 'center',
		marginLeft: 25
	},
	container: { display: 'flex', alignItems: 'center', height: 80 },
	nameWrapper: {
		textAlign: 'end',
		marginRight: 11
	},
	name: {
		color: theme.palette.customBlack.main,
		fontWeight: theme.typography.h1.fontWeight,
		lineHeight: '10px'
	},
	role: {
		textTransform: 'capitalize',
		fontSize: '11px',
		lineHeight: '15px',
		fontWeight: 400,
		color: theme.palette.gray.custom,
		opacity: 0.5
	},
	clinicName: {
		textTransform: 'none',
		fontSize: '12px',
		lineHeight: '15px',
		fontWeight: 500,
		color: theme.palette.gray.custom,
		opacity: 0.5
	},
	clinicLoader: {
		justifyContent: 'flex-end',
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		backgroundColor: 'white'
	},
	icon: { width: 20, color: theme.palette.blue.main, marginLeft: 4 }
}));
