import { Box } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import { useStyles } from './styles';

export type DotsWrapperProps = {
	className?: string;
	upperDotClassName?: string;
	bottomDotClassName?: string;
	upperDotIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
	bottomDotIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
};

export const DotsWrapper = ({
	upperDotClassName,
	bottomDotClassName,
	upperDotIcon: UpperDotIcon,
	bottomDotIcon: BottomDotIcon,
	className = ''
}: DotsWrapperProps) => {
	const classes = useStyles();

	return (
		<Box className={clsx([className, classes.dots])}>
			<Box className={clsx([classes.dot, upperDotClassName])}>
				{UpperDotIcon ? <UpperDotIcon /> : <></>}
			</Box>
			<Box className={clsx([classes.dot, bottomDotClassName])}>
				{BottomDotIcon ? <BottomDotIcon /> : <></>}
			</Box>
		</Box>
	);
};
