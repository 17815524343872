import { arrayOf, node, oneOfType } from 'prop-types';
import React, { useReducer } from 'react';

import { INITIAL_STATE } from './helpers';
// Import reducer
import reducer from './store/reducer';
// Import hooks
import useActions from './useActions';

export const DrugsContext = React.createContext();

const DrugsProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

	const actions = useActions({ dispatch });

	const providerValues = {
		...state,
		...actions
	};

	return (
		<DrugsContext.Provider value={{ ...providerValues }}>
			{children}
		</DrugsContext.Provider>
	);
};

DrugsProvider.propTypes = {
	children: oneOfType([arrayOf(node), node]).isRequired
};

export default DrugsProvider;
