import { TableCell as MuiTableCell, TableCellProps } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import { useStyles } from './styles';

export interface ITableCellProps extends TableCellProps {
	className?: string;
	children: React.ReactNode;
	textAlign?:
		| undefined
		| 'start'
		| 'end'
		| 'left'
		| 'right'
		| 'center'
		| 'justify'
		| 'initial'
		| 'match-parent';
}

export const TableCell = ({
	className,
	children,
	textAlign = 'right',
	...rest
}: ITableCellProps) => {
	const classes = useStyles({ textAlign });
	return (
		<MuiTableCell className={clsx([className, classes.cell])} {...rest}>
			{children}
		</MuiTableCell>
	);
};
