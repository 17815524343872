import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	rootCard: {
		background: theme.palette.white,
		borderRadius: 5,
		boxShadow: '0px 3px 6px #00000029',
		// paddingBottom: theme.spacing(1),
		position: 'relative',
		overflow: 'hidden',
		'& .MuiCardContent-root:last-child': {
			paddingBottom: 16
		}
	},
	topBorder: {
		'&::before': {
			position: 'absolute',
			top: 0,
			left: 0,
			display: 'block',
			content: '" "',
			background: theme.palette.blue.main,
			height: 10,
			width: '100%'
		}
	},
	rootDefault: {
		background: theme.palette.white,
		borderRadius: 5,
		boxShadow: '0px 3px 6px #00000029',
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		overflow: 'hidden'
	},
	mainTitle: {
		fontSize: 20
	},
	titleContainer: {
		padding: '46px 30px 36px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	}
}));
