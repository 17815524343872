import {
	useExaminationsTrendChartContext,
	useExaminationsV2Context,
	useExaminationsV2RefsContext
} from 'components/context/ExaminationsV2';
import { HighCharts } from 'components/context/ExaminationsV2/classes';
import { QUERY_PATIENT_DATA_AND_PERSONAL_BESTS } from 'helpers';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

const useParameter = ({ name }) => {
	const { patientId } = useParams();
	const {
		selectedParameters,
		singleChartParameterMutation,
		toggleExaminationParameters,
		getNewSelectedExaminationParameters
	} = useExaminationsTrendChartContext();

	const { store: examinationDateExtremes } = useExaminationsV2Context(
		(s) => s.examinationDateExtremes
	);
	const { store: trendChartRef } = useExaminationsV2RefsContext(
		(s) => s.trendChartRef
	);

	const cache = useQueryClient();
	const [patientPersonalBests] = cache.getQueryData([
		QUERY_PATIENT_DATA_AND_PERSONAL_BESTS,
		patientId
	]);

	const handleToggleCheckbox = () => {
		const newSelectedParameters = getNewSelectedExaminationParameters(
			selectedParameters,
			name
		);
		toggleExaminationParameters(name);

		const highChart = new HighCharts(trendChartRef);
		highChart.toggleSerie(
			name,
			newSelectedParameters,
			patientPersonalBests?.best,
			() =>
				singleChartParameterMutation.mutateAsync({
					chartEndDate: examinationDateExtremes.endDate,
					chartStartDate: examinationDateExtremes.startDate,
					chartName: name
				})
		);
		trendChartRef.current = highChart.getHighChart();
	};

	const isParameterChecked = !!selectedParameters.includes(name);

	return {
		toggleParameterCheckbox: handleToggleCheckbox,
		isParameterChecked
	};
};

export default useParameter;
