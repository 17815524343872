import 'utilities/firebase/firebase';

import { SnackbarProps } from 'components/elements/molecules/Snackbar/Snackbar';
import { SnackbarProvider } from 'notistack';
import React from 'react';

import { AllNotifications } from './AllNotifications';
import { ActionNotification, ErrorNotification } from './components';
import { ReleaseNoteSnackbar } from './ReleaseNoteSnackbar';
import { SystemNotificationsPermissionSnackbar } from './SystemNotificationsPermissionSnackbar';

export const Notifications = () => {
	return (
		<>
			<SnackbarProvider
				maxSnack={4}
				autoHideDuration={5000}
				Components={{
					error: ErrorNotification,
					action: ActionNotification,
					releaseNote: ReleaseNoteSnackbar,
					permissionRequest: SystemNotificationsPermissionSnackbar
				}}
			>
				<AllNotifications />
			</SnackbarProvider>
		</>
	);
};

declare module 'notistack' {
	interface VariantOverrides {
		releaseNote: true;
		permissionRequest: {
			actionLabel: TranslationKey;
			secondaryLabel?: TranslationKey;
			onActionClick: () => void;
			onSecondaryActionClick: () => void;
			title?: string;
		};
		action: {
			contentMessage: SnackbarProps['message'];
			title: string;
			actionLabel?: TranslationKey;
			onActionClick?: (id: SnackbarKey) => void;
		};
		error: {
			title: TranslationKey;
			contentMessage: SnackbarProps['message'];
		};
	}
}
