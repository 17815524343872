import { useQuery } from 'components/utilities';
import { LENGTH_UNITS_API, RACES_API, WEIGHT_UNITS_API } from 'helpers/api';
import services from 'services/services';

const QUERY_CREATE_PATIENT_RESOURCES = 'QUERY_CREATE_PATIENT_RESOURCES';

const useFetchDictionaries = () => {
	const { data: resources, isLoading } = useQuery(
		QUERY_CREATE_PATIENT_RESOURCES,
		getResources
	);

	// Form handlers
	const fetchData = (query, cancelToken) =>
		services.get(query, { cancelToken });

	function getResources({ cancelToken }) {
		return Promise.all([
			fetchData(LENGTH_UNITS_API, cancelToken),
			fetchData(RACES_API, cancelToken),
			fetchData(WEIGHT_UNITS_API, cancelToken)
		]).then((response) => response.map(({ data }) => data));
	}

	return {
		isLoading,
		resources
	};
};

export default useFetchDictionaries;
