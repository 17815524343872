import { Box, Divider, Drawer, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
// Import nav links
import { NAV_LINKS } from 'helpers/navigation';
import PropTypes from 'prop-types';
import React from 'react';

import Navigation from './Navigation';
import Profile from './Profile';

const useStyles = makeStyles((theme) => ({
	drawer: {
		width: 240,
		[theme.breakpoints.up('lg')]: {
			marginTop: 64,
			height: 'calc(100% - 64px)'
		}
	},
	root: {
		backgroundColor: theme.palette.white,
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		padding: theme.spacing(2)
	},
	divider: {
		margin: theme.spacing(2, 0)
	},
	nav: {
		marginBottom: theme.spacing(2)
	}
}));

const Sidebar = ({ open, variant, onClose, className, ...rest }) => {
	const classes = useStyles();

	return (
		<Hidden only={['md', 'lg']}>
			<Drawer
				anchor="left"
				classes={{ paper: classes.drawer }}
				onClose={onClose}
				open={open}
				variant={variant}
			>
				<Box {...rest} className={clsx(classes.root, className)}>
					<Profile />
					<Divider className={classes.divider} />
					<Navigation className={classes.nav} pages={NAV_LINKS} />
				</Box>
			</Drawer>
		</Hidden>
	);
};

Sidebar.propTypes = {
	className: PropTypes.string,
	onClose: PropTypes.func,
	open: PropTypes.bool.isRequired,
	variant: PropTypes.string.isRequired
};

export default Sidebar;
