import React from 'react';

import { ScheduleCalendarContext } from './ScheduleCalendarProvider';

const useScheduleCalendarContext = () => {
	const context = React.useContext(ScheduleCalendarContext);

	return { ...context };
};

export default useScheduleCalendarContext;
