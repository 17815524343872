import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	typography: {
		fontSize: '10px',
		lineHeight: '11px',
		letterSpacing: '0.15px',
		fontWeight: 500,
		color: '#B4B4B4',
		paddingBottom: 7
	}
}));
