import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		gap: 10,
		height: '100%'
	}
}));
