import { useDrugsContext } from 'components/context';
import { SettingsTableCell as DefaultSettingsTableCell } from 'components/elements';
import { PATIENTS_DETAILS_PATHS } from 'components/routes/paths';
import { func, object, string } from 'prop-types';
import { DrugsRepository } from 'queries';
import { replacePatientIdQueryParam } from 'queries/common';
import React from 'react';
import { useParams } from 'react-router-dom';

import ArchiveSetting from './ArchiveSetting';

const {
	DRUGS: { EDIT }
} = PATIENTS_DETAILS_PATHS;

const SettingsTableCell = ({ cell, label, action }) => {
	const { patientId } = useParams();
	const { toggleArchivedDrugs } = useDrugsContext();

	const editPath = replacePatientIdQueryParam(EDIT, patientId);

	return (
		<DefaultSettingsTableCell
			cell={cell}
			deletePromise={DrugsRepository.delete}
			editRoutePath={editPath}
			deleteModalDisableTitle="patients.drugs.deletion.title"
			deleteModalTextContent={{
				key: 'patients.drugs.deletion.text',
				replaceKeys: {
					['%1']: `<strong>${cell.row.original.name}</strong>`
				}
			}}
		>
			{({ close }) => (
				<ArchiveSetting
					close={close}
					label={label}
					promise={action}
					id={cell.row.original.id}
					setRefetch={toggleArchivedDrugs}
				/>
			)}
		</DefaultSettingsTableCell>
	);
};

SettingsTableCell.propTypes = {
	cell: object.isRequired,
	label: string.isRequired,
	action: func.isRequired
};

export default SettingsTableCell;
