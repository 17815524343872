import { EmailTextInput, IEmailTextInputProps } from '../EmailTextInput';

export interface IPatientInvitationAdditionalContentProps {
	isPatientUnlinked: boolean;
	emailValue: IEmailTextInputProps['value'];
	onChange: IEmailTextInputProps['onChange'];
	isValidEmail: IEmailTextInputProps['isValidEmail'];
	setEmailInputAsDirty: IEmailTextInputProps['setEmailInputAsDirty'];
	isDirty: IEmailTextInputProps['isDirty'];
}

export const PatientInvitationAdditionalContent = ({
	isPatientUnlinked,
	emailValue,
	onChange,
	isValidEmail,
	setEmailInputAsDirty,
	isDirty
}: IPatientInvitationAdditionalContentProps) => {
	return isPatientUnlinked ? (
		<EmailTextInput
			value={emailValue}
			onChange={onChange}
			isValidEmail={isValidEmail}
			setEmailInputAsDirty={setEmailInputAsDirty}
			isDirty={isDirty}
		/>
	) : null;
};
