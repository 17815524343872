import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'center'
	},
	divider: {
		width: 1,
		marginLeft: theme.spacing(1),
		height: 21
	},
	modifier: {
		marginBottom: theme.spacing(0.3)
	},
	endIconWrapper: {
		display: 'flex',
		height: ' 100%',
		paddingRight: theme.spacing(0.25)
	},
	endIcon: {
		marginLeft: theme.spacing(0.25)
	},
	button: {
		height: 36,
		padding: theme.spacing(0.25),
		marginLeft: theme.spacing(0.4),
		paddingLeft: theme.spacing(1.86),
		paddingRight: theme.spacing(0.5)
	},
	menu: {
		marginTop: 6,
		'& ul': {
			paddingTop: 0,
			paddingBottom: 0
		},
		'& :last-child': {
			borderBottom: `none`
		}
	},
	menuItem: {
		height: 30,
		width: 143,
		borderBottom: `solid 1px ${theme.palette.border.light}`
	},
	valueWrapper: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between'
	},
	defaultFormat: {
		color: theme.palette.gray.disabled,
		fontSize: '10px',
		fontWeight: 400
	}
}));
