import { makeStyles } from '@material-ui/styles';
import theme from 'theme';

import { IRequestConfirmationContentProps } from './RequestConfirmationContent';

export const useStyles = makeStyles<
	typeof theme,
	{ textAlign: IRequestConfirmationContentProps['textAlign'] }
>(() => ({
	contentLabel: {
		textAlign: ({ textAlign }) => (textAlign ? textAlign : 'center'),
		fontSize: 18,
		lineHeight: '20px'
	},
	alert: {
		marginBottom: 20
	}
}));
