import {
	nextStep,
	prevStep,
	resetActiveStep,
	setEmailAvailable,
	setEmailDuplicated,
	setMarkPatientAsInvited,
	setNewPatientId,
	toggleFormOpen
} from './store/actions';

const useActions = ({ dispatch }) => {
	const handleToggleModal = () => toggleFormOpen()(dispatch);

	const handleSetEmailAvailable = (payload) =>
		setEmailAvailable(payload)(dispatch);

	const handleSetEmailDuplicated = (payload) =>
		setEmailDuplicated(payload)(dispatch);

	const handleNextStep = (payload) => nextStep(payload)(dispatch);

	const handlePrevStep = (payload) => prevStep(payload)(dispatch);

	const handleResetActiveStep = (payload) =>
		resetActiveStep(payload)(dispatch);

	const handleSetNewPatientId = (payload) =>
		setNewPatientId(payload)(dispatch);

	const handleMarkPatientAsInvited = (payload) => {
		setMarkPatientAsInvited(payload)(dispatch);
	};

	return {
		toggleFormOpen: handleToggleModal,
		setEmailAvailable: handleSetEmailAvailable,
		setEmailDuplicated: handleSetEmailDuplicated,
		nextStep: handleNextStep,
		prevStep: handlePrevStep,
		resetActiveStep: handleResetActiveStep,
		setNewPatientId: handleSetNewPatientId,
		setMarkPatientAsInvited: handleMarkPatientAsInvited
	};
};

export default useActions;
