import { z } from 'zod';

import {
	AdditionalInfoQuestionsSchema,
	ChartPointSchema,
	CommonReportFieldsSchema,
	ReportParameterSchema,
	StatusSchema
} from './common';
import { SlowVitalCapacitySVCReportSchema } from './SlowVitalCapacitySVC';

export const ForcedSpirometryParamsSchema = z.object({
	fvc: ReportParameterSchema,
	fev1: ReportParameterSchema,
	fev1Fvc: ReportParameterSchema,
	pef: ReportParameterSchema,
	fev6: ReportParameterSchema,
	fef25_75: ReportParameterSchema,
	fef25: ReportParameterSchema,
	fef50: ReportParameterSchema,
	fef75: ReportParameterSchema,
	vptefVe: ReportParameterSchema,
	fivc: ReportParameterSchema,
	pif: ReportParameterSchema,
	mif25: ReportParameterSchema,
	mif50: ReportParameterSchema,
	mif75: ReportParameterSchema,
	tptefTe: ReportParameterSchema,
	bev: ReportParameterSchema,
	fet: ReportParameterSchema,
	tpef: ReportParameterSchema,
	rt: ReportParameterSchema
});

export const ForcedSpirometryMeasurementSchema =
	ForcedSpirometryParamsSchema.extend({
		additionalInfo: AdditionalInfoQuestionsSchema.nullable(),
		atsError: z.array(z.string()).nullable(),
		createDateTime: z.string().nullable(),
		fev1Status: StatusSchema,
		flowVolumeChart: z.array(ChartPointSchema).nullable(),
		fvcStatus: StatusSchema,
		id: z.union([z.string(), z.number()]),
		isBest: z.boolean().nullable(),
		num: z.number().nullable(),
		volumeTimeChart: z.array(ChartPointSchema).nullable()
	});
export type ForcedSpirometryMeasurementModel = z.infer<
	typeof ForcedSpirometryMeasurementSchema
>;

export const ForcedSpirometryBestParametersSchema = z.object({
	fef25: ReportParameterSchema,
	fef25_75: ReportParameterSchema,
	fef50: ReportParameterSchema,
	fef75: ReportParameterSchema,
	fev1: ReportParameterSchema,
	fvc: ReportParameterSchema,
	fev1Fvc: ReportParameterSchema,
	pef: ReportParameterSchema,
	bev: ReportParameterSchema.pick({ value: true }),
	fet: ReportParameterSchema.pick({ value: true }),
	tpef: ReportParameterSchema.pick({ value: true })
});

export type ForcedSpirometryBestParametersModel = z.infer<
	typeof ForcedSpirometryBestParametersSchema
>;

const AnswerSchema = z.object({
	question_id: z.number(),
	value_id: z.number()
});
export const ForcedSpirometryReportSchema = CommonReportFieldsSchema.merge(
	z.object({
		bestMeasurementChart: z
			.object({
				flowVolumeChart: z.array(ChartPointSchema),
				volumeTimeChart: z.array(ChartPointSchema)
			})
			.nullable()
			.optional(),
		bestParameters: ForcedSpirometryBestParametersSchema,
		measurements: z.array(ForcedSpirometryMeasurementSchema).nullable(),
		scoreFvc: z
			.union([
				z.literal('A'),
				z.literal('B'),
				z.literal('C'),
				z.literal('D'),
				z.literal('E'),
				z.literal('F'),
				z.literal('U')
			])
			.nullable()
			.optional(),
		scoreFev1: z
			.union([
				z.literal('A'),
				z.literal('B'),
				z.literal('C'),
				z.literal('D'),
				z.literal('E'),
				z.literal('F'),
				z.literal('U')
			])
			.nullable()
			.optional(),
		survey: z
			.object({
				response: z.array(
					z.object({ id: z.number(), answers: z.array(AnswerSchema) })
				)
			})
			.optional(),
		svc: SlowVitalCapacitySVCReportSchema.nullable()
	})
);
