import {
	CheckboxFieldAdapter,
	CountryAutocompleteAdapter,
	PhoneFieldAdapter,
	PhoneOption,
	SelectFieldAdapter,
	TextFieldAdapter
} from 'components/elements/molecules';
import {
	convertFormInputValueToNumbers,
	lettersAndSpecialCharacters
} from 'helpers';
import { EMPLOYEES_INVITATION } from 'helpers/Forms/fieldNames/employeesInvitation';

export const GENDERS_MOCK = ['male', 'female', 'not_specified'];

export const fields = (translationKeyBase, fieldSettings = []) =>
	[
		{
			grid: { lg: 12, xs: 12 },
			component: TextFieldAdapter,
			type: 'text',
			label: `${translationKeyBase}.${EMPLOYEES_INVITATION.FIRST_NAME}`,
			regex: lettersAndSpecialCharacters,
			name: EMPLOYEES_INVITATION.FIRST_NAME,
			variant: 'outlined'
		},
		{
			grid: { lg: 12, xs: 12 },
			component: TextFieldAdapter,
			type: 'text',
			label: `${translationKeyBase}.${EMPLOYEES_INVITATION.LAST_NAME}`,
			regex: lettersAndSpecialCharacters,
			name: EMPLOYEES_INVITATION.LAST_NAME,
			variant: 'outlined'
		},
		{
			grid: { lg: 12, xs: 12 },
			component: TextFieldAdapter,
			type: 'text',
			label: `${translationKeyBase}.${EMPLOYEES_INVITATION.EMAIL}`,
			name: EMPLOYEES_INVITATION.EMAIL,
			variant: 'outlined'
		},
		{
			grid: { lg: 4, md: 4, sm: 12, xs: 12 },
			component: PhoneFieldAdapter,
			renderOption: PhoneOption,
			type: 'text',
			label: `${translationKeyBase}.${EMPLOYEES_INVITATION.PHONE_PREFIX}`,
			name: EMPLOYEES_INVITATION.PHONE_PREFIX,
			variant: 'outlined'
		},
		{
			grid: { lg: 8, md: 8, sm: 12, xs: 12 },
			component: TextFieldAdapter,
			type: 'text',
			label: `${translationKeyBase}.${EMPLOYEES_INVITATION.PHONE_NUMBER}`,
			name: EMPLOYEES_INVITATION.PHONE_NUMBER,
			variant: 'outlined',
			inputOnChange: convertFormInputValueToNumbers
		},
		{
			grid: { lg: 5, md: 5, sm: 12, xs: 12 },
			label: `${translationKeyBase}.${EMPLOYEES_INVITATION.DOB}`,
			name: EMPLOYEES_INVITATION.DOB,
			variant: 'outlined'
		},
		{
			grid: { lg: 7, md: 7, sm: 12, xs: 12 },
			component: SelectFieldAdapter,
			type: 'select',
			label: `${translationKeyBase}.${EMPLOYEES_INVITATION.GENDER}`,
			name: EMPLOYEES_INVITATION.GENDER,
			variant: 'outlined'
		},
		{
			grid: { md: 6, xs: 12 },
			component: CountryAutocompleteAdapter,
			renderOption: ({ name }) => name,
			type: 'text',
			label: `${translationKeyBase}.${EMPLOYEES_INVITATION.COUNTRY_CODE.toLowerCase()}`,
			name: EMPLOYEES_INVITATION.COUNTRY_CODE,
			variant: 'outlined'
		},
		{
			grid: { md: 6, xs: 12 },
			component: TextFieldAdapter,
			type: 'text',
			label: `${translationKeyBase}.${EMPLOYEES_INVITATION.CITY}`,
			name: EMPLOYEES_INVITATION.CITY,
			variant: 'outlined'
		},
		{
			grid: { md: 12, xs: 12 },
			component: TextFieldAdapter,
			renderOption: ({ name }) => name,
			type: 'text',
			label: `${translationKeyBase}.${EMPLOYEES_INVITATION.SPECIALIZATION_NAME}`,
			name: EMPLOYEES_INVITATION.SPECIALIZATION_ID,
			variant: 'outlined'
		},
		{
			grid: { md: 12, xs: 12 },
			component: TextFieldAdapter,
			type: 'text',
			label: `${translationKeyBase}.${EMPLOYEES_INVITATION.PASSWORD}`,
			name: EMPLOYEES_INVITATION.PASSWORD,
			variant: 'outlined',
			isPasswordChecker: true
		},
		{
			grid: { md: 12, xs: 12 },
			component: TextFieldAdapter,
			type: 'text',
			label: `${translationKeyBase}.${EMPLOYEES_INVITATION.PASSWORD_CONFIRMATION}`,
			name: EMPLOYEES_INVITATION.PASSWORD_CONFIRMATION,
			variant: 'outlined',
			isPasswordChecker: false
		},
		{
			grid: { md: 12, xs: 12 },
			type: 'checkbox',
			component: CheckboxFieldAdapter,
			label: `${translationKeyBase}.accept_terms_conditions`,
			name: EMPLOYEES_INVITATION.TERMS_CONDITIONS,
			url: 'global.terms_of_service.url',
			linkText: `${translationKeyBase}.${EMPLOYEES_INVITATION.TERMS_CONDITIONS}`,
			isLink: true,
			required: true
		},
		{
			grid: { md: 12, xs: 12 },
			type: 'checkbox',
			component: CheckboxFieldAdapter,
			label: `${translationKeyBase}.marketing_agreement.label`,
			name: EMPLOYEES_INVITATION.MARKETING_AGREEMENT,
			url: 'global.newsletter_agreement.url',
			linkText: `${translationKeyBase}.marketing_agreement.link_text`,
			isLink: true,
			required: false
		}
	].map((f) => {
		const setting = fieldSettings.find((s) => s?.name === f.name);
		return { ...f, ...setting };
	});
