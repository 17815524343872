import { CommonReportFieldsModel } from 'models/Report/Reports';
import { LocaleDate } from 'utilities';

import { ReportTemplateConfig } from '../../templates/config';

type SubheaderLabel = {
	[K in keyof CommonReportFieldsModel]: {
		disabled: boolean;
		fieldName: K;
		label: string;
		valueConverter: (
			value: string | undefined | null
		) => TranslationKey | null;
	};
	//eslint-disable-next-line
}[keyof CommonReportFieldsModel];

type SubheaderLabels = Record<string, SubheaderLabel>;

export const SUBHEADER_LABELS = {
	createDateTime: {
		disabled: false,
		fieldName: 'examinationCreateDateTime',
		label: 'report.subheader.examination_date',
		valueConverter: (v) =>
			v ? new LocaleDate(v).getDateWithTimeLabel().dateFirst(' |') : v
	},
	refValue: {
		disabled: false,
		fieldName: 'referenceStandard',
		label: 'report.subheader.ref_value',
		valueConverter: (v) => v
	}
} as const satisfies SubheaderLabels;

export const getSubheaderLabels = ({
	refValue
}: {
	refValue: ReportTemplateConfig['refValue'];
}) => {
	return Object.values({
		...SUBHEADER_LABELS,
		refValue: {
			...SUBHEADER_LABELS.refValue,
			disabled: !!refValue?.disabled
		}
	});
};
