import React from 'react';
import { LocaleDate } from 'utilities';

import { ParameterLabel } from '../../Typography';

type Props = { value: string; className?: string };

export const CreateDateTimeCell = ({ value, className }: Props) => {
	const dateTimeLabel = React.useMemo(
		() =>
			new LocaleDate(new Date(value))
				.getDateWithTimeLabel()
				.dateFirst(' | '),
		[value]
	);
	return (
		<ParameterLabel
			label={dateTimeLabel}
			className={className}
			disableTranslation
		/>
	);
};
