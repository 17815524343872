import { produce } from 'immer';

import { checkConversation, getMessages } from './helpers';
import * as types from './types';

const reducer = (state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.SET_AUTH:
				draft.auth = action.payload;
				break;

			// ------- CONVERSATION -------
			case types.SET_CONVERSATION_LIST:
				draft.conversationList = action.payload;
				break;

			case types.SET_NEW_CONVERSATION:
				draft.conversationId = action.payload;
				break;

			case types.SET_CONVERSATION_HISTORY:
				const { nextPage, status, converstaionId, ...rest } =
					action.payload;
				const previousMessages = [
					...draft.conversationHistory.messages
				];
				draft.conversationHistory.messages = getMessages({
					...rest,
					previousMessages
				});
				draft.conversationHistory.nextPage = nextPage;
				draft.conversationHistory.status = status;
				draft.conversationId = converstaionId;

				break;

			// ------- RECEIVE MESSAGE -------
			case types.RECEIVE_MESSAGE:
				const isCurrentConversation = checkConversation(
					action.payload,
					draft.conversationId
				);
				isCurrentConversation &&
					draft.conversationHistory.messages.push(action.payload);
				break;

			default:
				return state;
		}
	});

export default reducer;
