import { Grid, Typography as MuiTypography } from '@material-ui/core';
import { DatePicker } from 'components/elements';
import { useTranslations } from 'components/utilities';
import { string } from 'prop-types';
import React from 'react';

import { useStyles } from './styles';

const DateField = ({ label }) => {
	const { t } = useTranslations();
	const classes = useStyles();
	return (
		<Grid container spacing={1}>
			<Grid item lg={12} xs={12} className={classes.labelWrapper}>
				<MuiTypography
					component="span"
					variant="body2"
					className={classes.label}
				>
					{t(label)}
				</MuiTypography>
			</Grid>
			<Grid item lg={12} xs={12}>
				<DatePicker />
			</Grid>
		</Grid>
	);
};

DateField.propTypes = {
	label: string.isRequired
};

export default DateField;
