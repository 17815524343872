import {
	ContainerWithBorder,
	Title
} from 'components/elements/atoms/ExaminationsV2';
import { ParametersTable } from 'components/elements/organisms/ExaminationsV2';
import React from 'react';
import {
	convertToColumnsData,
	getColumnsValueOnly
} from 'utilities/Examinations/ExaminationDetails/common';
import { PEAK_FLOW_ALL_PARAMETERS } from 'utilities/Examinations/ExaminationDetails/helpers';

import { useGetCachedPeakFlowReportData } from '../useGetCachedPeakFlowReportData';
import { useStyles } from './styles';

export const BestResults = () => {
	const classes = useStyles();
	const { examination } = useGetCachedPeakFlowReportData();
	return (
		<ContainerWithBorder className={classes.container}>
			<Title title="report.best_results.title" />
			<ParametersTable
				columns={getColumnsValueOnly({ isInteger: { rows: ['pef'] } })}
				data={convertToColumnsData(
					examination?.bestParameters,
					PEAK_FLOW_ALL_PARAMETERS
				)}
			/>
		</ContainerWithBorder>
	);
};
