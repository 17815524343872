import { Box, Typography as MuiTypography } from '@material-ui/core';
import { useTranslations } from 'components/utilities';
import { MEDICATION_TYPES_FIELDS } from 'helpers';
import { bool, func, object, string } from 'prop-types';
import React from 'react';

import OptionButton from '../OptionButton';
import { useStyles } from './styles';
import useMedicationTypes from './useMedicationTypes';

const MedicationTypes = ({
	submitFailed,
	errors,
	dosage_form: dosageForm,
	setDosageForm,
	classNameRoot
}) => {
	const classes = useStyles();
	const { t } = useTranslations();

	const { setMedicationType, setVariant } = useMedicationTypes({
		dosageForm,
		setDosageForm
	});

	return (
		<Box className={classNameRoot}>
			<MuiTypography
				variant="body2"
				component="p"
				className={classes.typography}
			>
				{t('patients.drugs.form.type')}
			</MuiTypography>
			{submitFailed && (errors.dosage_form || errors.drug?.dosage_form) && (
				<MuiTypography variant="body2" component="p" color="error">
					{t(errors.dosage_form || errors.drug.dosage_form)}
				</MuiTypography>
			)}
			<Box className={classes.wrapper}>
				{MEDICATION_TYPES_FIELDS.map(({ value, icon }) => (
					<OptionButton
						key={value}
						value={value}
						icon={icon}
						state={dosageForm}
						onClick={() => setMedicationType(value)}
						variant={setVariant(value)}
						className={classes.button}
					/>
				))}
			</Box>
		</Box>
	);
};

MedicationTypes.propTypes = {
	errors: object,
	submitFailed: bool,
	classNameRoot: string,
	dosage_form: string.isRequired,
	setDosageForm: func.isRequired
};

export default MedicationTypes;
