import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	title: {
		textTransform: 'none',
		marginRight: 12,
		fontSize: ({ fontSize }) => fontSize,
		lineHeight: ({ fontSize }) => fontSize
	}
}));
