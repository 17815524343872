import { SPIROMETRY_EXAMINATION_VALUE_KEYS } from 'helpers';
import { SpirometryPreviewModel } from 'models/Examinations/allExaminationsTypes';
import { PersonalBestModel } from 'models/Examinations/PersonalBest';

import { ParameterValues, Spirometry } from '../common/Spirometry';
import { ExaminationDataRowType } from '../Parameters/Builder/Spirometry/SpirometryParametersDirector';
import { IParameterTooltip } from './common';

const {
	best: { key: bestKey },
	PBPercentage: { key: PBPercentageKey },
	predicted: { key: predictedKey },
	bestPercentile: { key: bestPercentileKey }
} = SPIROMETRY_EXAMINATION_VALUE_KEYS;

export interface ISpirometryTooltip {
	getFev1Tooltip: () => IParameterTooltip;
	getFvcTooltip: () => IParameterTooltip;
	getPefTooltip: () => IParameterTooltip;
	getTiffeneauTooltip: () => IParameterTooltip;
}
export class SpirometryTooltip
	extends Spirometry
	implements ISpirometryTooltip
{
	constructor(
		data: SpirometryPreviewModel,
		personalBest: PersonalBestModel,
		chartName?: ExaminationDataRowType
	) {
		super(data, personalBest, chartName);
	}

	public getFev1Tooltip = (): IParameterTooltip => {
		const fev1Values = this.getFev1Values();
		return this.constructReturnObject(fev1Values);
	};

	public getFvcTooltip = (): IParameterTooltip => {
		const fvcValues = this.getFvcValues();
		return this.constructReturnObject(fvcValues);
	};

	public getPefTooltip = (): IParameterTooltip => {
		const pefValues = this.getPefValues();
		return {
			bestValue: pefValues[bestKey]
		};
	};

	public getTiffeneauTooltip = (): IParameterTooltip => {
		const tiffeneauValues = this.getTiffeneauValues();
		return this.constructReturnObject(tiffeneauValues);
	};

	private constructReturnObject = (
		values: ParameterValues
	): IParameterTooltip => ({
		bestValue: values[bestKey],
		restValues: [
			values[PBPercentageKey],
			values[predictedKey],
			values[bestPercentileKey]
		]
	});
}
