import { Box } from '@material-ui/core';
import { bool } from 'prop-types';
import React from 'react';

import { Divider } from '../../elements/atoms/Dividers';
import Messages from './Messages';
import MessageTextField from './MessageTextField';
import { useStyles } from './styles';

const Messenger = ({ isAvailable }) => {
	const classes = useStyles();
	return (
		<Box className={classes.root}>
			<Messages />
			<Divider />
			<MessageTextField isAvailable={isAvailable} />
		</Box>
	);
};

Messenger.propTypes = {
	isAvailable: bool
};

export default Messenger;
