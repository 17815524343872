import { calculateBmi } from 'helpers';
import React from 'react';
import { useForm } from 'react-final-form';

import { ADDITIONAL_FIELD_NAMES } from '../helpers';

const usePatientHeightWeight = ({
	patientWeight,
	patientHeight,
	patientWeightUnit,
	patientHeightUnit
}) => {
	const { change } = useForm();

	const changeBmiFormValue = (value) =>
		change(ADDITIONAL_FIELD_NAMES.BMI, value);

	const setBmiFormValue = () => {
		const bmi = calculateBmi({
			weight: patientWeight,
			height: patientHeight,
			weightUnit: patientWeightUnit,
			heightUnit: patientHeightUnit
		});
		changeBmiFormValue(bmi);
	};

	React.useEffect(() => {
		if (
			patientWeight &&
			patientHeight &&
			patientWeightUnit &&
			patientHeightUnit
		) {
			setBmiFormValue();
		} else {
			changeBmiFormValue(null);
		}

		//eslint-disable-next-line
	}, [patientWeight, patientHeight, patientWeightUnit, patientHeightUnit]);
};

export default usePatientHeightWeight;
