import { format, parseISO } from 'date-fns';
import { LocaleDate } from 'utilities';
import { TLocaleDate } from 'utilities/LocaleDate/LocaleDate';

import { validateDateFormat } from './validators';
import { DATE_FORMAT } from './variables';
export const standardizedDate = (date: string): string =>
	date.replace(/\s/g, 'T');

export const getStandardizedDateTime = ({
	timeCreated = '',
	dateCreated,
	date
}) => {
	// Format date to serialize date to all browsers such safari
	const isUTCDate = dateCreated?.includes('T');
	const formattedDateCreated = isUTCDate
		? dateCreated
		: dateCreated?.replace(/[-]/g, '/') || null;

	const dateTime = `${formattedDateCreated} ${timeCreated}`;
	const examinationDate = date
		? standardizedDate(date)
		: timeCreated
		? dateTime
		: formattedDateCreated;

	// Split date to time and date
	const localeDate = new LocaleDate(examinationDate);

	return {
		time: localeDate.getLocaleTime(),
		date: localeDate.getStringFormatDate()
	};
};

export type DateYearMonthDay =
	| { year: number; month?: undefined; day?: undefined }
	| { year: number; month: string; day?: undefined }
	| { year: number; month: string; day: string };
export type PHPDateFormat =
	(typeof DATE_FORMAT.PHP)[keyof typeof DATE_FORMAT.PHP];
interface IDateFormatConversion {
	PHPToJS: ({
		date,
		date_format
	}: {
		date: string;
		date_format: PHPDateFormat;
	}) => DateYearMonthDay;

	dayMonthYearToPHP: (values: DateYearMonthDay) => {
		date: string;
		date_format: PHPDateFormat;
	};
	laravelToISO: (date: string) => Date;
	toKeyboardDatePicker: (date: TLocaleDate) => string;
}
export const DateFormatConversion: IDateFormatConversion = {
	PHPToJS: ({ date, date_format = DATE_FORMAT.PHP.YEAR }) => {
		const ISODate = new Date(date);
		validateDateFormat(ISODate);
		const year = ISODate.getFullYear();
		const month = ('0' + (ISODate.getMonth() + 1)).slice(-2);
		const day = ('0' + ISODate.getDate()).slice(-2);

		switch (date_format) {
			case DATE_FORMAT.PHP.YEAR:
				return {
					year
				};
			case DATE_FORMAT.PHP.YEAR_MONTH:
				return {
					year,
					month
				};
			default:
				return {
					year,
					month,
					day
				};
		}
	},
	dayMonthYearToPHP: (values) => {
		if (!values?.year) {
			throw Error("Values doesn't contain year field");
		}

		const { day, month, year } = values;
		const monthText = month ? `-${month}` : '';
		const dayText = day ? `-${day}` : '';
		const fullDate = `${year}` + monthText + dayText;

		const dateFormat = getDateFormat(fullDate);

		return {
			date: fullDate,
			date_format: dateFormat
		};
	},
	laravelToISO: (date) => {
		const newDate = date?.replace('.000000Z', '');
		const ISODate = parseISO(newDate);
		validateDateFormat(ISODate);
		return ISODate;
	},
	toKeyboardDatePicker: (date) => {
		const ISODate = new LocaleDate(date).getDate();
		validateDateFormat(ISODate);
		return format(ISODate, DATE_FORMAT.KEYBOARD_DATE_PICKER_VALUE);
	}
};

function getDateFormat(date: string): PHPDateFormat {
	switch (date.length) {
		case 7:
			return DATE_FORMAT.PHP.YEAR_MONTH;
		case 10:
			return DATE_FORMAT.PHP.YEAR_MONTH_DAY;
		default:
			return DATE_FORMAT.PHP.YEAR;
	}
}
