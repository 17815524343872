// Import context
import { useScheduleCalendarContext } from 'components/context';

import { getDatepickerSettings } from './helpers';

const useDateControls = () => {
	const {
		selectedDate,
		setAppointmentsDateRange,
		setStepDateChange,
		isMonthView
	} = useScheduleCalendarContext();

	const handleSetSelectedDate = (date) => setAppointmentsDateRange(date);

	const handleStepDateChange = (type) => () => setStepDateChange(type);

	const { openTo, views, format } = getDatepickerSettings(isMonthView);

	return {
		selectedDate,
		setSelectedDate: handleSetSelectedDate,
		stepDateChange: handleStepDateChange,
		openTo,
		views,
		format
	};
};

export default useDateControls;
