import { CHART_Y_AXIS, DEFAULT_Y_AXIS_COLOR } from './variables';

export const getAvailableExaminationOptions = (options, type) => {
	const group = Object.values(options[type]).map((item) => {
		return { ...item, checked: item.checked };
	});

	options[type] = group;
	return options;
};

export const getIndexOfActiveYAxis = (curve, isDefaultColor = false) => {
	const activeYAxisGroup = CHART_Y_AXIS.find(({ group }) =>
		group.names.includes(curve.name)
	);
	const activeYAxisColor = isDefaultColor
		? DEFAULT_Y_AXIS_COLOR
		: activeYAxisGroup.group.colors[curve.name];
	const indexOfAxis = CHART_Y_AXIS.indexOf(activeYAxisGroup);
	return { index: indexOfAxis, color: activeYAxisColor };
};

export const getParameters = (options) =>
	Object.values(options)
		.flat()
		.filter(({ checked }) => checked);
