import React from 'react';

import { CircledNumber } from './CircledNumber';
import useAttemptNumber from './useAttemptNumber';

export type Cursor = 'auto' | 'pointer';

export type AttemptNumberProps = {
	disableTransition?: boolean;
	cursor?: Cursor;
	isBest: boolean;
	isBestIndex: number;
	number: number;
	isActive: boolean;
};
export const AttemptNumber = ({
	disableTransition = false,
	cursor = 'pointer',
	isBest,
	isActive,
	number,
	isBestIndex
}: AttemptNumberProps) => {
	const { onMouseEnter, onMouseLeave, setActive } = useAttemptNumber({
		isBestIndex,
		number
	});
	return (
		<CircledNumber
			cursor={cursor}
			number={number}
			isBest={isBest}
			isActive={isActive}
			disableTransition={disableTransition}
			getEvents={() => ({
				onMouseEnter,
				onMouseLeave,
				onClick: setActive
			})}
		/>
	);
};
