import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		padding: '8px 15px'
	},
	withBorder: {
		border: '1px solid',
		borderRadius: '10px',
		borderColor: theme.palette.border.light
	},
	withPrimaryBackground: {
		'-webkit-print-color-adjust': 'exact',
		color: '#FFFFFF',
		backgroundColor: theme.palette.primary.dark
	}
}));
