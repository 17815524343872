import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	correctnessContainer: {
		display: 'flex',
		gap: 5,
		justifyContent: 'space-between',
		alignItems: 'center',
		// borderTop: '1px solid #E2E2E2',
		borderRadius: 0
	}
}));
