export default {
	0: '#E2A593',
	1: '#DDCC36',
	2: '#898CB5',
	3: '#6DD461',
	4: '#4EBFF1',
	5: '#A7015A',
	6: '#707070',
	unknown: '#CCC',
	danger: '#F39B9B',
	success: '#90DE77',
	warning: '#E9E477'
};
