import React from 'react';
import { ReactContext } from 'utilities';

export interface IContextValues {
	apiErrorMessage: AlertMessage;
	setApiErrorMessage: React.Dispatch<React.SetStateAction<AlertMessage>>;
	contentRef: React.RefObject<HTMLDivElement>;
	notesRef: React.RefObject<HTMLDivElement>;
	isNoteInNextPage: boolean;
	setNoteInNextPage: React.Dispatch<React.SetStateAction<boolean>>;
}

type Props = {
	children: CmpChildren;
};

const [useCtx, Ctx] = ReactContext.createContext<IContextValues>();

export const ReportContextProvider = ({ children }: Props) => {
	const contentRef = React.useRef<HTMLDivElement>(null);
	const notesRef = React.useRef<HTMLDivElement>(null);
	const [isNoteInNextPage, setNoteInNextPage] =
		React.useState<boolean>(false);
	const [apiErrorMessage, setApiErrorMessage] =
		React.useState<AlertMessage>(null);

	const providerValues: IContextValues = {
		apiErrorMessage,
		setApiErrorMessage,
		contentRef,
		notesRef,
		isNoteInNextPage,
		setNoteInNextPage
	};

	return <Ctx.Provider value={providerValues}>{children}</Ctx.Provider>;
};

export const useReportContext = useCtx;
