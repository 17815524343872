import { Rgb } from './Rgb';

export class ColorFormatConverter {
	static rgbToHex = (rgb) => {
		this.validateRgb(rgb);
		return (
			'#' +
			this.#colorToHex(rgb.r) +
			this.#colorToHex(rgb.g) +
			this.#colorToHex(rgb.b)
		);
	};

	static hexToRbg = (hex) => {
		const validHex = this.validateHex(hex);
		if (!validHex.isValid) {
			throw Error(
				`Wrong hex format in hexToRgb converter. Given: ${hex}`
			);
		}
		const rgb = new Rgb(
			parseInt(validHex.result[1], 16),
			parseInt(validHex.result[2], 16),
			parseInt(validHex.result[3], 16)
		);

		return rgb.getColorString();
	};

	static validateHex = (hex) => {
		const result = /^#([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return { result, isValid: !!result };
	};

	static validateRgb = (rgb) => {
		if (!(rgb instanceof Rgb)) {
			throw new Error(
				`Given argument: ${rgb} is not instance of Rgb class`
			);
		}
	};

	static #colorToHex = (color) => {
		var hex = color.toString(16);
		return hex.length == 1 ? '0' + hex : hex;
	};
}
