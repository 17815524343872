import { Box } from '@material-ui/core';
// import { Alert } from 'components/elements';
import React from 'react';

import { useReportContext } from '../../context';
import { Footer, Header, SubHeader } from '../molecules';
import { ReportTemplateConfig } from '../templates/config';
import { Section } from '../types';
import { FooterProps } from './Footer';
import { useStyles } from './styles';

export interface PageLayoutProps {
	children: CmpChildren;
	title: string;
	footerProps: FooterProps;
	subHeader?: Section;
	refValue?: ReportTemplateConfig['refValue'];
}

export const PageLayout = ({
	children,
	footerProps,
	title,
	subHeader,
	refValue
}: PageLayoutProps) => {
	const classes = useStyles();

	const { contentRef, notesRef, setNoteInNextPage } = useReportContext();
	const footerRef = React.useRef<HTMLDivElement>(null);

	React.useLayoutEffect(() => {
		if (notesRef.current) {
			const contentBoundingBox =
				contentRef.current?.getBoundingClientRect() as DOMRect;
			const noteBoundingBox = notesRef.current?.getBoundingClientRect();
			const footerBoundingBox =
				footerRef.current?.getBoundingClientRect();

			if (
				contentBoundingBox?.bottom -
					noteBoundingBox.bottom -
					(footerBoundingBox?.height ?? 0) <
				0
			) {
				setNoteInNextPage(true);
			}
		}
	}, []);

	return (
		<Box className={classes.root} {...{ ref: contentRef }}>
			{/* {apiErrorMessage && (
				<Alert
					message={apiErrorMessage}
					className={classes.alert}
					disableTransition
				/>
			)} */}
			<Header title={title} />
			{subHeader?.disable ? (
				<></>
			) : subHeader?.component ? (
				<subHeader.component />
			) : (
				<SubHeader refValue={refValue} />
			)}
			<Box className={classes.content}>{children}</Box>
			<Footer {...footerProps} ref={footerRef} />
		</Box>
	);
};
