import { Graph } from 'components/elements/atoms/ExaminationsV2';
import { useTranslations } from 'components/utilities';
import React from 'react';
import palette from 'theme/palette';

import { useGetCachedBronchodilatorReportData } from '../../useGetCachedBronchodilatorReportData';

export const Graphs = () => {
	const { examination } = useGetCachedBronchodilatorReportData();
	const { t } = useTranslations();
	return (
		<>
			<Graph
				data={[
					{
						lineWidth: 2,
						color: palette.report.blue.main,
						data: examination?.pre.bestMeasurementChart
							? examination?.pre.bestMeasurementChart
									.flowVolumeChart || []
							: []
					},
					{
						lineWidth: 2,
						color: palette.report.green.main,
						data: examination?.post.bestMeasurementChart
							? examination?.post.bestMeasurementChart
									.flowVolumeChart || []
							: []
					}
				]}
				xAxisLabel={t('report.graph.volume.label')}
				yAxisLabel={t('report.graph.flow.label')}
				title={{ label: 'report.graph.best_flow.title' }}
				axisValuesRatio="2:1"
				axisBoundaries={{
					MIN_Y: -6,
					MAX_Y: 8,
					MIN_X: -1,
					MAX_X: 8
				}}
				fvcPredicted={examination?.pre.bestParameters.fvc.predicted}
				pefPredicted={examination?.pre.bestParameters.pef.predicted}
			/>
			<Graph
				data={[
					{
						lineWidth: 2,
						color: palette.report.blue.main,
						data: examination?.pre.bestMeasurementChart
							? examination?.pre.bestMeasurementChart
									.volumeTimeChart || []
							: []
					},
					{
						lineWidth: 2,
						color: palette.report.green.main,
						data: examination?.pre.bestMeasurementChart
							? examination?.pre.bestMeasurementChart
									.volumeTimeChart || []
							: []
					}
				]}
				xAxisLabel={t('report.graph.time.label')}
				yAxisLabel={t('report.graph.volume.label')}
				title={{ label: 'report.graph.best_volume.title' }}
				axisValuesRatio="1:1"
				axisBoundaries={{
					MIN_Y: 0,
					MAX_Y: 6,
					MIN_X: 0,
					MAX_X: 8
				}}
				disableTriangle
			/>
		</>
	);
};
