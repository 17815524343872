import { differenceInCalendarYears } from 'date-fns';

/**
 * generate array of years in given range
 * @example
 * // return [2022, 2021, 2020]
 * getYearsArray(3);
 *
 * @param {Date} dateOfBirth years range
 * @returns {number[]} array of years in given range
 */
export const getYearsArray = (dateOfBirth) => {
	const today = new Date();
	const currentYear = today.getFullYear();

	return new Array(differenceInCalendarYears(today, dateOfBirth) + 1)
		.fill(0)
		.map((_, index) => currentYear - index);
};

/**
 * generate array of months based on month/year of birth and chosen year
 * @example
 * // return [11,12]
 * getMonthsArray(values, 11, 1990); chosen year=1990
 * // return [1,2,3,4,5,6,7,8,9,10,11,12]
 * getMonthsArray(values, 11, 1990); chosen year=2000
 *
 * @param {any} values form values
 * @param {number} monthOfBirth month of birth
 * @param {number} yearOfBirth year of birth
 * @returns {array} array of allowed months
 */
export const getMonthsArray = (value, dateOfBirth) => {
	const { first, last } = getMonthsRanges(value, dateOfBirth);
	return new Array(12)
		.fill(0)
		.map((_, index) => Number('0' + (index + 1)))
		.slice(first, last);
};

function getMonthsRanges(year, dateOfBirth) {
	const today = new Date();
	const currentYear = today.getFullYear();
	const currentMonth = today.getMonth() + 1;

	const birthYear = dateOfBirth.getFullYear();
	const monthBirth = dateOfBirth.getMonth() + 1;

	const sinceBirthDate =
		Number(year) === birthYear && monthBirth >= currentMonth;
	if (sinceBirthDate) {
		return { first: monthBirth - 1, last: 12 };
	}

	const sinceTodayDate = Number(year) === currentYear;
	if (sinceTodayDate) {
		return { first: 0, last: currentMonth };
	}

	return { first: 0, last: 12 };
}

export const FIELD_NAMES = {
	exSmokerYear: 'exSmokerYear',
	exSmokerMonth: 'exSmokerMonth'
};
