import { useMutation } from 'components/utilities';
import React from 'react';

import { IModalDialogProps } from '../../ModalDialog/ModalDialog';

export interface IUseRequestMenuItemProps<TResponseData> {
	closeMenu: () => void;
	promiseFn: () => Promise<TResponseData>;
	onSuccess?: (data?: TResponseData) => void;
	validate?: () => boolean;
	actionButtonsProps?: IModalDialogProps<
		React.MouseEvent<HTMLButtonElement, MouseEvent>
	>['actionButtonsProps'];
}

export const useRequestMenuItem = <TResponseData>({
	promiseFn,
	closeMenu,
	onSuccess = () => {},
	validate = () => true,
	actionButtonsProps = {}
}: IUseRequestMenuItemProps<TResponseData>) => {
	const [open, setOpen] = React.useState(false);

	const {
		mutate,
		status: idle,
		apiError,
		reset
	} = useMutation<TResponseData, TMyError>(promiseFn, {
		onSuccess: (data) => {
			closeMenu();
			if (onSuccess) {
				onSuccess(data);
			}
		}
	});

	const handleClose = (e?, reason?) => {
		if (reason === 'backdropClick') {
			return false;
		}
		setOpen(false);
		closeMenu();
		actionButtonsProps?.cancel?.onClick?.(e);
	};

	const handleOpen = () => setOpen(true);

	const handleRequestConfirmation = (e) => {
		if (validate()) {
			mutate();
			actionButtonsProps?.confirm?.onClick?.(e);
		}
	};

	return {
		mutate,
		setOpen: handleOpen,
		setClose: handleClose,
		reset,
		requestConfirmation: handleRequestConfirmation,
		open,
		idle,
		apiError: apiError
	};
};
