import { format, isValid } from 'date-fns';

type Callback = (
	message: ApiErrorState
) => void | React.SetStateAction<QueryApiError[] | null>;

export const apiFormErrorsHandler = (
	error: TErrorResponse,
	callback: Callback
): AlertMessage => {
	if (error.response && error.response.data?.validator?.errors) {
		const errors = error.response.data.validator.errors;
		// Convert api error messages to show above the form
		const messages = convertApiFormErrorMessages(errors);
		callback && callback(messages);
		return messages;
	}
	if (error.code) {
		callback(error.code);

		return error.code;
	}
	return null;
};

export const convertApiFormErrors = (
	errors: TErrorResponseErrorsObject
): TApiFormError =>
	Object.keys(errors).reduce((acc, next) => {
		if (next.includes('.')) {
			const [section, field] = next.split('.');
			acc[section] = { ...acc[section], [field]: errors[next][0] };
		} else {
			acc[next] = errors[next][0];
		}
		return acc;
	}, {});

export const convertApiFormErrorMessages = (
	errors: ResponseValidatorErrors
): QueryApiError[] =>
	Object.keys(errors).reduce((acc: QueryApiError[], next: string) => {
		const convertString = (text: string): string =>
			text.replaceAll(/[._]/g, ' ');
		const nameArray = next.split('.');
		const name = nameArray[nameArray.length - 1]
			.replace(/([A-Z])/g, '_$1')
			.trim()
			.toLowerCase();

		const message = {
			description: convertString(errors[next][0]),
			name
		};
		acc.push(message);
		return acc;
	}, []);

export const getConvertedApiFormErrors = (
	error: TErrorResponse
): TApiFormError | undefined => {
	if (error.response && error.response.data?.errors) {
		const errors = error.response.data.errors;
		return convertApiFormErrors(errors);
	}
};

export const getApiErrorMessages = (errors, callback) => {
	if (errors) {
		typeof errors.response.data.message === 'string'
			? callback(errors.response.data.message || 'Unknown error occurred')
			: apiFormErrorsHandler(errors, callback);
	}
};

// ------ DATE FORMAT ------
export const formatDate = (date, dateFormat) =>
	isValid(date) ? format(date, dateFormat) : null;

// ------ REPORTS VALIDATION ------
export const reportFormValidation = (formRef) => {
	if (formRef?.current) {
		const formWizard = formRef.current;

		// Check if form is valid, than move to next wizard page
		const isFormValid = formWizard.checkValidity();
		return isFormValid;
	}
	return false;
};
