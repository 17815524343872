import {
	IconButton,
	InputAdornment as MuiInputAdornment
} from '@material-ui/core';
import { arrayOf, bool, func, node, oneOfType } from 'prop-types';
import React from 'react';

const InputAdornment = ({ children, disabled, open }) => {
	return (
		<MuiInputAdornment position="end">
			<IconButton onClick={open} disabled={disabled}>
				{children}
			</IconButton>
		</MuiInputAdornment>
	);
};

InputAdornment.propTypes = {
	children: oneOfType([node, arrayOf(node)]).isRequired,
	disabled: bool,
	open: func
};

export default InputAdornment;
