import { bool } from 'prop-types';
import React from 'react';

import Result from '../Result/Result';
import Tooltip from '../Tooltip';

const ResultWithTooltip = ({ disableObturationTooltip = false, ...props }) => {
	return (
		<>
			<Result {...props} />
			{!disableObturationTooltip && <Tooltip {...props} />}
		</>
	);
};

ResultWithTooltip.propTypes = {
	disableObturationTooltip: bool
};

export default ResultWithTooltip;
