import { CHART_MEASUREMENT_PARAMETER_OPTION_NAMES } from '../charts/measurement/parameters';
import { capitalizeString } from '../common';

const { fvc, fev1, tiffeneau } = CHART_MEASUREMENT_PARAMETER_OPTION_NAMES;

const Z_SCORES_PARAMETERS = [fvc, fev1, tiffeneau];

export const Z_SCORE_FIELD_NAMES = Z_SCORES_PARAMETERS.map(
	({ name }) => `bestZScore${capitalizeString(name)}`
);
