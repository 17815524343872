import { MEASUREMENT_STATUS_DOT_TYPE } from 'helpers/examinations';
import { DeviceSchema } from 'models/Device/Device';
import { ExaminationTypeSchema } from 'models/Examinations/common';
import { z } from 'zod';

export const PDFHistorySchema = z.object({
	key: z.string(),
	revision: z.number(),
	created_at: z.string(),
	note: z.string().optional(),
	doctor: z.object({
		firstName: z.string(),
		lastName: z.string()
	})
});
export type PDFHistoryModel = z.infer<typeof PDFHistorySchema>;

export const PDFsSchema = z
	.object({
		id: z.string(),
		examinationType: ExaminationTypeSchema,
		examinationId: z.string(),
		createdAt: z.string(),
		updatedAt: z.string(),
		history: PDFHistorySchema.array()
	})
	.nullable();
export type PDFsModel = z.infer<typeof PDFsSchema>;

export const PatientDemographicsSchema = z.object({
	patientBmi: z.number().nullable().optional(),
	patientAge: z.number().nullable(),
	patientGender: z.string().nullable(),
	patientHeight: z.number().nullable(),
	patientRace: z.string().nullable(),
	patientWeight: z.number().nullable(),
	patientWeightUnit: z.string().nullable().optional(),
	patientHeightUnit: z.string().nullable().optional()
});

export const CommonReportFieldsSchema = z.object({
	examinationCreateDateTime: z.string().nullable(),
	referenceStandard: z
		.union([
			z.literal(''),
			z.literal('ECCS 1993, Zapetal 2003'),
			z.literal('GLI(2012)'),
			z.literal('GLI+ECCS')
		])
		.nullable(),
	device: DeviceSchema,
	reports: PDFsSchema.optional(),
	patientData: PatientDemographicsSchema.optional()
});

export const ChartPointSchema = z.object({
	x: z.number(),
	y: z.number().nullable()
});

export const StatusSchema = z.union([
	z.literal(MEASUREMENT_STATUS_DOT_TYPE.valid),
	z.literal(MEASUREMENT_STATUS_DOT_TYPE.invalid),
	z.literal(MEASUREMENT_STATUS_DOT_TYPE.usable)
]);

export type StatusModel = z.infer<typeof StatusSchema>;

export const ReportParameterSchema = z.object({
	value: z.number().nullable(),
	lln: z.number().nullable().optional(),
	predicted: z.number().nullable().optional(),
	zScore: z.number().nullable().optional(),
	predictedPercent: z.number().nullable().optional(),
	percentile: z.number().nullable().optional(),
	personalBest: z.number().nullable().optional()
});

export type ReportParameterModel = z.infer<typeof ReportParameterSchema>;

export const AdditionalInfoQuestionsSchema = z.object({
	q1: z.boolean(),
	q2: z.boolean(),
	q3: z.boolean(),
	q4: z.boolean(),
	q5: z.boolean()
});
