export class FileDownloader {
	link = document.createElement('a');

	constructor(href, filename) {
		this.link.href = href;
		this.link.setAttribute('download', filename);
	}

	download = () => {
		this.#mount();
		this.link.click();
		this.#unmount();
	};

	#mount = () => {
		document.body.appendChild(this.link);
	};

	#unmount = () => {
		document.body.removeChild(this.link);
	};
}
