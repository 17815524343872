import { PulseOximetryPreviewModel } from 'models/Examinations/allExaminationsTypes';
import { PulseOximetryTooltip } from 'utilities/Examinations/Tooltips/PulseOximetryTooltip';

import { convertCreateDateTime } from './common';
import { ITooltipData } from './ExaminationTooltipFactory';

export const getHrTooltipData = (
	examinationData: PulseOximetryPreviewModel
): ITooltipData => {
	const pulseOximetryTooltip = new PulseOximetryTooltip(examinationData);

	const hrTooltipData = pulseOximetryTooltip.getHrTooltip();

	return {
		createDateTime: convertCreateDateTime(examinationData.createDateTime),
		...hrTooltipData
	};
};

export const getSpO2TooltipData = (
	examinationData: PulseOximetryPreviewModel
): ITooltipData => {
	const pulseOximetryTooltip = new PulseOximetryTooltip(examinationData);

	const spO2TooltipData = pulseOximetryTooltip.getSpO2Tooltip();

	return {
		createDateTime: convertCreateDateTime(examinationData.createDateTime),
		...spO2TooltipData
	};
};
