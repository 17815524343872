import { MenuItem } from '@material-ui/core';
import { useTranslations } from 'components/utilities';
import { func, number, string } from 'prop-types';
import React from 'react';

import useArchiveSetting from './useArchiveSetting';

const ArchiveSetting = ({ close, label, promise, id, setRefetch }) => {
	const { t } = useTranslations();
	const { archiveMutation } = useArchiveSetting({
		promise,
		close,
		id,
		setRefetch
	});

	return <MenuItem onClick={archiveMutation}>{t(label)}</MenuItem>;
};

ArchiveSetting.propTypes = {
	close: func.isRequired,
	setRefetch: func.isRequired,
	label: string.isRequired,
	promise: func.isRequired,
	id: number.isRequired
};

export default ArchiveSetting;
