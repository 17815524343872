import { UserSettingModel } from 'models/Settings/Settings';
import { useSelector } from 'react-redux';
import { Settings } from 'utilities';
import { ISettings } from 'utilities/Settings/Settings';

const useSettingsServices = () => {
	const { settings: reduxSettings } = useSelector<
		{
			settings: { settings: UserSettingModel[] };
		},
		{ settings: UserSettingModel[] }
	>((store) => store.settings);

	const handleFindByName: ISettings['findByName'] = (settingName) => {
		const settings = new Settings(reduxSettings);
		return settings.findByName(settingName);
	};

	return { findByName: handleFindByName };
};

export default useSettingsServices;
