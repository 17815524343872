import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ReactComponent as BackIcon } from 'assets/icons/ic_back.svg';
import clsx from 'clsx';
import { shape, string } from 'prop-types';
import React from 'react';

import RouterLink from '../Links/RouterLink';

const useStyles = makeStyles((theme) => ({
	link: { marginRight: theme.spacing(0.5) }
}));

const ButtonBack = ({ to, classNames: { classNameButton = '' } = {} }) => {
	const classes = useStyles();
	return (
		<IconButton
			className={clsx(classes.link, classNameButton)}
			component={RouterLink}
			to={to}
		>
			<BackIcon />
		</IconButton>
	);
};

ButtonBack.propTypes = {
	to: string.isRequired,
	classNames: shape({ classNameButton: string })
};

export default ButtonBack;
