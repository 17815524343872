import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { object } from 'prop-types';
import React from 'react';

import Bottom from './Bottom';
import Top from './Top';

const useStyles = makeStyles((theme) => ({
	root: {
		border: `1px solid ${theme.palette.divider}`,
		borderRadius: 8,
		overflow: 'hidden'
	}
}));

const Item = ({ data }) => {
	const classes = useStyles();
	const { createdAt } = data;
	return (
		<Box className={classes.root}>
			<Top createdAt={createdAt} />
			<Bottom data={data} />
		</Box>
	);
};

Item.propTypes = {
	data: object.isRequired
};

export default Item;
