import { TRANSITIONS } from 'helpers';
import _ from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, Switch, useLocation } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { setPrevLocation } from 'store/actions/location';
import { useParams } from 'utilities';

import { LocationState } from '../../Links/RouterLink';
import { useFadeTransitionStyles } from '../styles';

type Props = {
	routes: AnyType[];
	children: CmpChildren;
};

export const RouterSwitchWithTransition = ({ routes, children }: Props) => {
	const transitionClasses = useFadeTransitionStyles();
	const params = useParams();

	const location = useLocation<LocationState>();
	const prevLocation = useSelector<
		{ location: { prevLocation: string } },
		string
	>((store) => store.location.prevLocation);
	const dispatch = useDispatch();

	React.useEffect(() => {
		const route = routes.find(
			({ path }) => generatePath(path, params) === location.pathname
		);
		if (route) {
			setPrevLocation(
				route?.pathBase
					? generatePath(route?.pathBase, params)
					: location.pathname
			)(dispatch);
		}
	}, []);

	const isCurrentPathIncludedRootRoutes = !!routes.find((route) => {
		if (typeof route.path === 'string') {
			return generatePath(route.path, params) === location.pathname;
		}

		if (_.isArray(route.path)) {
			const isCurrentPath = !!route.path.find(
				(p) => generatePath(p, params) === location.pathname
			);

			const isPrevPath = !!route.path.find(
				(p) =>
					generatePath(p, params) === prevLocation ||
					generatePath(p, params) ===
						location.state?.prevLocation?.pathname
			);

			// do not transition nested routes:
			// that means current path and prev path is included in routes group (array)
			if (isCurrentPath && isPrevPath) {
				return false;
			}

			return true;
		}

		return false;
	});

	const transitionKey = isCurrentPathIncludedRootRoutes
		? location.pathname
		: (prevLocation
				? prevLocation
				: location.state?.prevLocation?.pathname) ?? '';

	return (
		<SwitchTransition>
			<CSSTransition
				key={transitionKey}
				timeout={TRANSITIONS.DURATIONS.enteringScreen}
				classNames={{
					enter: transitionClasses.enter,
					exit: transitionClasses.exit,
					enterActive: transitionClasses.enterActive,
					exitActive: transitionClasses.exitActive
				}}
				unmountOnExit
			>
				<Switch location={location}>{children}</Switch>
			</CSSTransition>
		</SwitchTransition>
	);
};
