import { ReactComponent as ChatIcon } from 'assets/icons/ic_chat.svg';
import { ButtonProgress } from 'components/elements';
// Import patient paths
import { DASH_PATHS } from 'components/routes/paths';
import { PatientNotLinkedModalDialog } from 'components/templates/Patients';
import { useChatPatientLinked } from 'components/utilities';
import { PATIENT_ID_QUERY_PARAM_KEY } from 'helpers';
import React from 'react';

import { useStyles } from './styles';
import { useChat } from './useChat';

const { CHAT_PATIENT_CONVERSATION } = DASH_PATHS;

export const ChatNavLinkButton = () => {
	const { isChatAvailable } = useChat();

	if (!isChatAvailable) {
		return null;
	}

	const classes = useStyles();

	const [openModal, setOpenModal] = React.useState(false);

	const { error, isLoading, reset, onClick } = useChatPatientLinked({
		pathUrl: CHAT_PATIENT_CONVERSATION,
		paramKey: PATIENT_ID_QUERY_PARAM_KEY
	});

	const handleClick = () => {
		setOpenModal(true);
		onClick();
	};

	return (
		<>
			<ButtonProgress
				onClick={handleClick}
				isLoading={isLoading}
				className={classes.btnChat}
				color="default"
			>
				<ChatIcon />
			</ButtonProgress>
			<PatientNotLinkedModalDialog
				open={openModal}
				error={error}
				close={() => {
					setOpenModal(false);
				}}
				onExited={reset}
			/>
		</>
	);
};
