import { makeStyles } from '@material-ui/styles';

const HEIGHT = `calc(100vh -
	${/* Page Header */ '80px'} - 
	${/* Gap between content and PatientCardNav*/ '20px'} - 
	${/* Page padding bottom */ '20px'} - 
	${/* PatientCardNav height */ '200px'} - 
	49px)`;

export const useStyles = makeStyles(() => ({
	root: {
		width: '100%'
	},
	placeholderRoot: {
		display: 'flex',
		flexDirection: 'column'
	},
	placeHolderWrapper: {
		position: 'relative'
	},
	// cardContainer: {
	// 	width: '100%',
	// 	minHeight: HEIGHT,
	// 	paddingBottom: '0px !important',
	// 	paddingTop: '0px !important',
	// 	paddingRight: 0,
	// 	position: 'relative'
	// },
	cardContent: {
		// width: '100%',
		// height: '100%',
		display: 'flex',
		flexDirection: 'column',
		minHeight: HEIGHT,
		// position: 'relative',
		padding: 0,
		flex: 1
	},
	loader: {
		position: 'absolute',
		top: 0,
		left: 0,
		height: '100%',
		width: '100%',
		backgroundColor: 'white'
	}
}));
