import { useMutation } from 'components/utilities';
import { useSelector } from 'react-redux';

import { AuthRepository } from './repository';

/**
 * edit user password
 * @param {object} options useMutation options
 * @returns edit user password mutation
 */
const useEditPassword = (options = {}) => {
	const { id: userId } = useSelector(
		({ auth }) => auth.authenticate.data.user
	);

	const mutate = useMutation(
		(payload) => AuthRepository.editPassword({ userId, payload }),
		options
	);

	return mutate;
};

export default useEditPassword;
