import {
	QUERY_PATIENT_BASIC_DATA,
	QUERY_PATIENT_DATA_AND_PERSONAL_BESTS,
	QUERY_PATIENTS_LIST
} from 'helpers';
import { INVITATION_STATUS } from 'models/Patient/PatientModel';
import { PatientModel } from 'models/Patient/PatientModel';
import {
	useLinkPatientWithDoctorMutation,
	useUnlinkPatientAccountFromDoctorMutation
} from 'queries';
import React from 'react';
import { useQueryClient } from 'react-query';

import { useEmailTextInput } from './common/EmailTextInput';
import { IPatientInvitationModalProps } from './PatientInvitationModal';
import {
	linkModalProps,
	unlinkModalProps
} from './PatientInvitationModal/helpers';

export interface IUsePatientInvitation {
	initialEmail: IPatientInvitationModalProps['initialEmail'];
	patientId: IPatientInvitationModalProps['patientId'];
	patientFullName: IPatientInvitationModalProps['patientFullName'];
	invitationStatus: IPatientInvitationModalProps['invitationStatus'];
	onSuccess?: IPatientInvitationModalProps['onSuccess'];
	isQuerySuccessEnabled?: boolean;
}

export const usePatientInvitation = ({
	initialEmail,
	patientId,
	invitationStatus,
	patientFullName,
	onSuccess = () => {},
	isQuerySuccessEnabled = false
}: IUsePatientInvitation) => {
	const [open, setOpen] = React.useState(false);
	const {
		setEmail,
		onChange,
		isValidEmail,
		email,
		setEmailInputAsDirty,
		isDirty
	} = useEmailTextInput({
		initialEmail: initialEmail ?? ''
	});

	const queryClient = useQueryClient();

	const data = queryClient.getQueriesData<PatientModel['patient']>([
		QUERY_PATIENT_BASIC_DATA,
		patientId?.toString()
	]);
	queryClient.invalidateQueries;
	const patientCardAPIStatus = queryClient.getQueryState<
		PatientModel['patient']
	>([QUERY_PATIENT_BASIC_DATA, patientId?.toString()]);
	const handleSuccess = () => {
		onSuccess();
		queryClient.invalidateQueries(QUERY_PATIENTS_LIST);
		queryClient.invalidateQueries([
			QUERY_PATIENT_DATA_AND_PERSONAL_BESTS,
			patientId?.toString()
		]);
		queryClient.invalidateQueries([
			QUERY_PATIENT_BASIC_DATA,
			patientId?.toString()
		]);
	};

	const invitation = data?.[0]?.[1]?.invitationStatus ?? invitationStatus;
	const isPatientUnlinked = invitation === INVITATION_STATUS.UNLINKED;

	const linkMutation = useLinkPatientWithDoctorMutation({
		onSuccess: () => {
			if (isQuerySuccessEnabled) {
				handleSuccess();
			}
		}
	});

	const unlinkMutation = useUnlinkPatientAccountFromDoctorMutation({
		onSuccess: () => {
			if (isQuerySuccessEnabled) {
				handleSuccess();
			}
		}
	});

	const mutationOptions = isPatientUnlinked ? linkMutation : unlinkMutation;

	const handleRequestConfirmation = () => {
		if (!isValidEmail) {
			setEmailInputAsDirty();
			return;
		}
		if (isPatientUnlinked) {
			linkMutation.mutate({
				id: patientId?.toString(),
				email: email,
				message: ''
			});
		} else {
			unlinkMutation.mutate({
				id: patientId?.toString()
			});
		}
	};
	const modalProps = isPatientUnlinked
		? linkModalProps(patientFullName)
		: unlinkModalProps(patientFullName);

	return {
		modalProps,
		callInvitationAction: handleRequestConfirmation,
		mutationOptions,
		open,
		setOpen,
		setEmail,
		onChange,
		isDirty,
		patientCardAPIStatus,
		onInvitationSuccess: handleSuccess,
		isPatientUnlinked,
		invitation,
		email,
		isValidEmail,
		setEmailInputAsDirty
	};
};
