import { Box, IconButton } from '@material-ui/core';
import { CloseIcon } from 'assets/icons/commonMUIIcons';
import { func,object } from 'prop-types';
import React from 'react';
const CloseBtn = ({ classNames, close }) => {
	return (
		<Box className={classNames.iconWrapper}>
			<IconButton
				aria-label="close"
				className={classNames.closeButton}
				onClick={close}
			>
				<CloseIcon className={classNames.iconClose} />
			</IconButton>
		</Box>
	);
};

CloseBtn.propTypes = {
	close: func,
	classNames: object
};

export default CloseBtn;
