import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		width: '100%',
		padding: 0,
		gap: 0,
		'& > div': {
			flex: 1
		},
		'& > :first-child': {
			paddingRight: 10
		},
		'& > :last-child': {
			paddingLeft: 10
		}
	},
	obstructionContainer: {
		padding: 0,
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
		gap: 10,
		'& > :first-child': {
			flexDirection: 'row'
		},
		'& > div': {
			width: '100%',
			justifyContent: 'center'
		}
	},
	insideTitle: {
		marginBottom: 0
	},
	spacer: {
		flex: 1
	}
}));
