import { QUERY_COUNTRIES } from 'helpers';
import { CountriesModel } from 'models/Countries/Countries';
import { queryClient } from 'utilities';

export const getCachedCountryData = (): { data: CountriesModel } | undefined =>
	queryClient.getQueryData([QUERY_COUNTRIES]);

export const useGetCachedCountryData = ():
	| { data: CountriesModel }
	| undefined => {
	return getCachedCountryData();
};
