import { CardTopBar } from 'components/elements';
import React from 'react';

import Navigation from './Navigation';
import Profile from './Profile/Profile';
import { useStyles } from './styles';

const Sidebar = () => {
	const classes = useStyles();
	return (
		<CardTopBar
			classNameBox={classes.root}
			cardContentClassName={classes.cardContent}
			titleComponent={Profile}
		>
			<Navigation />
		</CardTopBar>
	);
};

export default Sidebar;
