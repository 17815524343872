import { API_URL_PREFIX } from './helpers';

export const AUTH_API_URL = `${API_URL_PREFIX}/auth/login`;
export const AUTH_RESEND_2FA_VERIFICATION_TOKEN_API_URL = `${API_URL_PREFIX}/auth/login/two-factor-authentication/resend`;

export const AUTH_LOGOUT_API_URL = `${API_URL_PREFIX}/auth/logout`;
export const AUTH_REFRESH_TOKEN_API_URL = `${API_URL_PREFIX}/auth/refresh`;
export const AUTH_RESET_PASSWORD_EMAIL_REQUEST_API_URL = `${API_URL_PREFIX}/auth/password/reset/email`;
export const AUTH_RESET_USER_PASSWORD_API_URL = `${API_URL_PREFIX}/auth/password/reset`;
export const AUTH_CHANGE_USER_LANGUAGE_API_URL = `${API_URL_PREFIX}/user/set-panel-language`;
