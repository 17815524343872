import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		gap: 2,
		alignItems: 'flex-end'
	},
	bestResultValue: {
		'& p': { fontSize: '35px', lineHeight: '35px' },
		'& > div': {
			width: 'auto'
		}
	},
	unit: {
		fontSize: '18px',
		lineHeight: '23.4px'
	}
}));
