import React from 'react';
import {
	convertToColumnsData,
	getColumnsValueOnly
} from 'utilities/Examinations/ExaminationDetails/common';
import { PULSE_OXIMETRY_ALL_PARAMETERS } from 'utilities/Examinations/ExaminationDetails/helpers';

import {
	ContainerWithBorder,
	ParametersTable,
	Title
} from '../../../components';
import { usePulseOximetryData } from '../../usePulseOximetryData';
import { useStyles } from './styles';

export const BestResultsTable = () => {
	const classes = useStyles();
	const examinationData = usePulseOximetryData();
	return (
		<ContainerWithBorder className={classes.container}>
			<Title title="report.best_results.title" />
			<ParametersTable
				columns={getColumnsValueOnly({ isInteger: true })}
				data={convertToColumnsData(
					examinationData?.bestParameters,
					PULSE_OXIMETRY_ALL_PARAMETERS
				)}
			/>
		</ContainerWithBorder>
	);
};
