import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	grid: {
		border: `2px solid ${theme.palette.border.custom}`,
		borderRadius: 5,
		padding: ({ matchMediaQuery }) =>
			matchMediaQuery ? '3px 0px 3px 45px' : '14px 18px',

		[theme.breakpoints.down('md')]: {
			padding: '14px 18px'
		}
	},
	resultsBox: {
		display: 'flex',
		justifyContent: 'space-around',
		flexWrap: 'wrap',
		gap: '10px',
		margin: `${theme.spacing(1)}px 0`,
		marginBottom: ({ matchMediaQuery }) => !matchMediaQuery && 0
	},
	title: {
		fontSize: 16,
		textTransform: 'uppercase',
		letterSpacing: '0.15px',
		marginBottom: ({ matchMediaQuery }) => !matchMediaQuery && '14px',
		color: theme.palette.gray.heavy
	}
}));
