import { Card, CardContent, Grid } from '@material-ui/core';
import React from 'react';

import { ConditionsTable } from './components';
import FormRoutes from './FormRoutes';
import { TABLE_CONSTRUCTORS, TABLE_NAME } from './helpers/tables_data';
import { useStyles } from './styles';
import useConditions from './useConditions';

const { CONDITIONS, SURGERIES, ALLERGIES } = TABLE_NAME;

const Conditions = () => {
	const classes = useStyles();

	const {
		setManualRefetch,
		manualRefetch,
		gridTemplateColumns,
		isFormShown
	} = useConditions({ CONDITIONS, SURGERIES, ALLERGIES });

	return (
		<Grid container spacing={isFormShown ? 2 : 0}>
			<Grid
				item
				xs={12}
				md={gridTemplateColumns.md}
				lg={gridTemplateColumns.lg}
				xl={gridTemplateColumns.xl}
			>
				<Card>
					<CardContent className={classes.cardContent}>
						{TABLE_CONSTRUCTORS.map((item, index) => (
							<ConditionsTable
								key={index}
								manualRefetch={manualRefetch[item.tableName]}
								setManualRefetch={() =>
									setManualRefetch(item.tableName)
								}
								{...item}
							/>
						))}
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={12} md={4} lg={4} xl={3}>
				<FormRoutes onManualRefetch={setManualRefetch} />
			</Grid>
		</Grid>
	);
};

export default Conditions;
