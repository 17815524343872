import { addDays, format, getYear, isAfter } from 'date-fns';
import { convertResults, getParameters, getSelectedParameters } from 'helpers';
import { DATE_FORMAT } from 'helpers/variables';

import PreviewDates from './classes';

const { CHART_RANGE } = DATE_FORMAT;

export const ExaminationsServices = {
	/**
	 * generate api route query param based on selected examinations types e.g [fev1,fvc]
	 * @param {object} options grouped chart parameters config. By default it is CHART_EXAMINATION_PARAMETER_GROUPS: src/helpers/charts/measurement/groups.js
	 * @returns api route query param
	 */
	getSelectedChartParameters: (options) => {
		const checkedOptions = getParameters(options);
		if (checkedOptions.length !== 0) {
			return checkedOptions
				.map(({ name }) => `parameters[]=${name}`)
				.join('&');
		}
		return 'parameters[]=0';
	},
	/**
	 * converts data returned from DB - define marker for each returned point and sort data by date
	 * @param {object} args function arguments
	 * @param {object} args.data data returned from DB
	 * @param {boolean} args.showMarkers decide whether to hide the point marker
	 * @returns converted chart data
	 */
	convertChartsData: ({ data, showMarkers = true }) => {
		const { measurements } = data.data;
		const newMeasurements = Object.entries(measurements).reduce(
			(acc, [key, arr]) => {
				const results = Object.values(arr);
				acc[key] = results
					.map((item) => convertResults(item, showMarkers, key))
					.sort((a, b) => (a.x > b.x ? 1 : -1));

				return acc;
			},
			{}
		);
		return newMeasurements;
	},
	/**
	 * convert daily checks list
	 * @param {object} data data returned from DB
	 * @returns converted data
	 */
	convertDailyCheckList: (data) =>
		Object.entries(data).reduce((acc, [key, values]) => {
			acc[key] = Object.entries(values).map(([date, value]) => {
				const data =
					typeof value === 'object' && value?.length === 0
						? null
						: value;
				return {
					date,
					value: data
				};
			});
			return acc;
		}, {}),
	/**
	 * api routes query params conversions
	 */
	getApiRouteParams: {
		/**
		 * converts selected and occurred examinations dates extremes to ExaminationsRepository.getMainChartData query params
		 * @param {object} args function arguments
		 * @param {object} args.lastExaminationDate date of last occurred examination
		 * @param {string} args.min selected start date from a datepicker
		 * @param {string} args.max selected end date from a datepicker
		 * @param {number} [args.groupByDay=1] group chart point in given dates amount
		 * @param {object} args.options grouped chart parameters config. By default it is CHART_EXAMINATION_PARAMETER_GROUPS: src/helpers/charts/measurement/groups.js	 * @returns converted chart data
		 * @returns {object} converted dates extremes
		 */
		mainChart: ({ lastExaminationDate, min, max, groupByDay, options }) => {
			const lastChosenDate = new Date(max);
			const lastExaminationExtreme = new Date(lastExaminationDate);
			const lastChosen = isAfter(lastChosenDate, lastExaminationExtreme)
				? lastExaminationExtreme
				: lastChosenDate;

			const formattedLastChosenDate = format(
				addDays(lastChosen, 1),
				CHART_RANGE
			);
			const parameters = getSelectedParameters(options);

			return {
				firstChosen: min,
				lastChosen: formattedLastChosenDate,
				groupByDay,
				parameters
			};
		},
		/**
		 * converts selected and occurred examinations dates extremes to ExaminationsRepository.getPreviewChartData query params
		 * @param {object} args function arguments
		 * @param {object} args.lastExaminationDate date of last occurred examination
		 * @param {string} args.min selected start date from a datepicker
		 * @param {string} args.max selected end date from a datepicker
		 * @returns {object} converted dates extremes
		 */
		previewChart: ({
			lastExaminationDate,
			firstExaminationDate,
			min,
			max
		}) => {
			const lastExaminationExtreme = new Date(lastExaminationDate);
			const firstExaminationExtreme = new Date(firstExaminationDate);
			const firstChosenDate = new Date(min);
			const lastChosenDate = new Date(max);

			const previewDates = new PreviewDates(
				lastExaminationExtreme,
				firstExaminationExtreme
			);

			const lastPreviewYear = previewDates.getLastPreviewYear();
			const lastChosenYear = getYear(lastChosenDate);

			if (lastChosenYear !== lastPreviewYear) {
				previewDates.setTwoYearsPeriod({
					firstChosenDate,
					lastChosenDate,
					lastExaminationExtreme,
					firstExaminationExtreme
				});
			}

			const formattedLastPreviewDate = previewDates.getLastPreviewDate();
			const formattedFirstPreviewDate =
				previewDates.getFirstPreviewDate();

			return {
				firstPreviewDate: formattedFirstPreviewDate,
				lastPreviewDate: formattedLastPreviewDate
			};
		}
	}
};
