import { GET_EXAMINATION_REPORT_DATA_QUERY_KEY } from 'helpers';
import {
	BronchodilatorReportModel,
	CalibrationCheckReportModel,
	CommonReportFieldsModel,
	ForcedSpirometryReportModel,
	PeakFlowReportModel,
	PulseOximetryReportModel,
	SlowVitalCapacitySVCReportModel
} from 'models/Report/Reports';
import { useGetCachedData } from 'queries/utilities/useGetCachedData';
import { useParams } from 'utilities';

import { AdditionalData } from '..';

export const useGetCachedExaminationReportData = <
	T extends
		| ForcedSpirometryReportModel
		| SlowVitalCapacitySVCReportModel
		| PulseOximetryReportModel
		| PeakFlowReportModel
		| BronchodilatorReportModel
		| CalibrationCheckReportModel
		| CommonReportFieldsModel
>({ examinationId, examinationType }: AdditionalData = {}) => {
	const { id: examinationIdParam, examinationType: examinationTypeParam } =
		useParams();
	const id = examinationIdParam || (examinationId as string);
	const type = examinationTypeParam || examinationType;

	const examinationData = useGetCachedData<T>(
		GET_EXAMINATION_REPORT_DATA_QUERY_KEY(id, type)
	);

	return examinationData;
};
