import { format, isBefore, isFuture, isPast, isValid } from 'date-fns';
import { DATE_FORMAT } from 'helpers/variables';
import { LocaleDate } from 'utilities';

const EARLIEST_POSSIBLE_DATE = '1900-01-01' as const;

interface IValidator {
	isError: () => boolean;
	getErrorMessage: () => ErrorMessage;
}

export class Validator implements IValidator {
	#disablePast: boolean;
	#disableFuture: boolean;
	#ISODate: Date;
	#today: string = format(new Date(), DATE_FORMAT.KEYBOARD_DATE_PICKER);

	constructor(disablePast = false, disableFuture = false, date) {
		this.#disablePast = disablePast;
		this.#disableFuture = disableFuture;
		this.#ISODate = new Date(date);
	}

	isError = () =>
		this.#isFutureDisabled() ||
		this.#isPastDisabled() ||
		this.#isDateInvalid() ||
		this.#isDateBeforeTheEarliestPossibleDate();

	getErrorMessage = () => {
		if (this.#isFutureDisabled()) {
			return this.#getErrorMessage(
				'global.date_picker.validation.future_is_disabled'
			);
		}

		if (this.#isPastDisabled()) {
			return this.#getErrorMessage(
				'global.date_picker.validation.past_is_disabled'
			);
		}

		if (this.#isDateBeforeTheEarliestPossibleDate()) {
			const localeDate = new LocaleDate(EARLIEST_POSSIBLE_DATE);

			return this.#getErrorMessage(
				'global.date_picker.validation.past_is_disabled',
				localeDate.getStringFormatDate()
			);
		}

		if (this.#isDateInvalid()) {
			return 'global.date_picker.validation.invalid_date';
		}

		return undefined;
	};

	#getErrorMessage = (translationKey, dateToReplace = this.#today) => ({
		key: translationKey,
		replaceKeys: { ['%date']: dateToReplace }
	});

	#isDateBeforeTheEarliestPossibleDate = () => {
		const localeDate = new LocaleDate(EARLIEST_POSSIBLE_DATE);
		return this.#ISODate && isBefore(this.#ISODate, localeDate.getDate());
	};

	#isDateInvalid = () => this.#ISODate && !isValid(this.#ISODate);

	#isDateDisabled = (disabled, isDisabledMethod) =>
		this.#ISODate && disabled && isDisabledMethod(this.#ISODate);
	#isFutureDisabled = () =>
		this.#isDateDisabled(this.#disableFuture, isFuture);
	#isPastDisabled = () => this.#isDateDisabled(this.#disablePast, isPast);
}
