import { Box, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { bool, node, number, string } from 'prop-types';
import React from 'react';
import palette from 'theme/palette';

import Button from './Button';

const useStyles = makeStyles(() => ({
	button: {
		width: '100%',
		position: 'relative'
	},
	wrapper: {
		position: 'relative'
	},
	progress: {
		color: ({ loaderColor }) => loaderColor || palette.primary.main,
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12
	}
}));

const ButtonProgress = ({
	children,
	classNameButton = '',
	classNameWrapper = '',
	isLoading = false,
	loaderColor = '',
	loaderSize = 24,
	disabled = false,
	...props
}) => {
	const classes = useStyles({ loaderColor });
	return (
		<Box className={clsx(classes.wrapper, classNameWrapper)}>
			<Button
				className={clsx([classes.button, classNameButton])}
				{...props}
				disabled={isLoading || disabled}
			>
				{isLoading && (
					<CircularProgress
						size={loaderSize}
						className={classes.progress}
					/>
				)}
				{children}
			</Button>
		</Box>
	);
};

ButtonProgress.propTypes = {
	children: node,
	isLoading: bool,
	disabled: bool,
	classNameWrapper: string,
	classNameButton: string,
	loaderColor: string,
	loaderSize: number
};

export default ButtonProgress;
