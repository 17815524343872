// import { ReactComponent as ActionPlanIcon } from 'assets/icons/ic_action_plan.svg';
import { ReactComponent as ProfileIcon } from 'assets/icons/ic_basic_data.svg';
import { ReactComponent as MeasurementsIcon } from 'assets/icons/ic_breath.svg';
import { ReactComponent as HealthIcon } from 'assets/icons/ic_health.svg';
// import { ReactComponent as RecommendationIcon } from 'assets/icons/ic_recommendations.svg';
import { ReactComponent as TrendChartIcon } from 'assets/icons/ic_measurements.svg';
import { ReactComponent as MedicationsIcon } from 'assets/icons/ic_medications.svg';
// Import routes
import { PATIENTS_DETAILS_PATHS } from 'components/routes/paths';
import { DOCTOR, RECEPTIONIST } from 'helpers';
import React from 'react';

const {
	// FOLDERS,
	// RECOMMENDATIONS,
	EXAMINATIONS,
	CONDITIONS,
	// ACTION_PLAN,
	DRUGS
} = PATIENTS_DETAILS_PATHS;

export const NAV_LINKS = [
	{
		isInfo: false,
		lg: 1,
		label: 'patients.nav.basic_data',
		path: '/patient/:patientId/basic-data',
		icon: <ProfileIcon />,
		role: RECEPTIONIST
	},
	{
		isInfo: false,
		label: 'patients.nav.conditions',
		path: CONDITIONS.ROOT,
		icon: <HealthIcon />,
		role: DOCTOR
	},
	{
		isInfo: false,
		label: 'patients.nav.examinations',
		path: EXAMINATIONS.PREVIEW,
		pathBase: EXAMINATIONS.ROOT,
		icon: <MeasurementsIcon />,
		role: DOCTOR
	},
	{
		isInfo: false,
		label: 'patients.nav.trend_chart',
		path: EXAMINATIONS.TREND_CHART,
		pathBase: EXAMINATIONS.ROOT,
		icon: <TrendChartIcon />,
		role: DOCTOR
	},
	{
		label: 'patients.nav.drugs',
		isInfo: false,
		path: DRUGS.ROOT,
		icon: <MedicationsIcon />,
		role: DOCTOR
	}
	// {
	// 	label: 'patients.nav.action_plan',
	// 	isInfo: true,
	// 	path: ACTION_PLAN.ROOT,
	// 	icon: <ActionPlanIcon />,
	// 	role: DOCTOR
	// }
];

// export const NAV_LINKS_INFO = [
// 	{
// 		label: 'patients.nav.action_plan',
// 		isInfo: true,
// 		path: ACTION_PLAN.ROOT,
// 		icon: <ActionPlanIcon />,
// 		role: DOCTOR
// 	}
// 	{
// 		isInfo: true,
// 		label: 'patients.nav.recommendations',
// 		path: RECOMMENDATIONS.ROOT,
// 		icon: <RecommendationIcon />,
// 		role: DOCTOR
// 	}
// 	{
// 		isInfo: false,
// 		label: 'patients.nav.files',
// 		path: FOLDERS.ROOT,
// 		icon: <DescriptionIcon />
// 	}
// ];
