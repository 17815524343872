import * as locale from 'date-fns/locale';
import { EN, PL } from 'helpers';
import Store from 'store/store';

import { Cookies } from '../Cookies';

export class AppLanguage {
	availableLanguages = Store.getState()?.language?.available?.data;
	#reduxLng: Languages = Store.getState()?.language?.lng;
	#cookieLng: Languages = Cookies.language.get();

	#lng = this.#reduxLng ?? this.#cookieLng;

	constructor() {}

	getLngString = () => {
		return this.#lng;
	};

	getLngLocale = () => {
		return (
			Object.values(locale).find((l) => l.code?.includes(this.#lng)) ||
			locale.enUS
		);
	};

	getLocale = () => {
		const localNavigatorCode =
			navigator?.languages[0] || navigator?.language;
		return Object.values(locale).find((l) => l.code === localNavigatorCode);
	};

	getInitialLanguage = (countryCode) => {
		if (this.#isLanguageAvailable(countryCode?.toLowerCase())) {
			return countryCode?.toLowerCase();
		}

		if (this.#cookieLng) {
			return this.#cookieLng;
		}

		const browserLng = this.getBrowserLanguage();
		if (this.#isLanguageAvailable(browserLng)) {
			return browserLng;
		}

		return EN;
	};

	getBrowserLanguage = () => {
		const { language } = navigator;
		return this.#convertBrowserLng(language);
	};

	filterAvailableLanguages = () => {
		return this.availableLanguages.filter((lng) => [PL, EN].includes(lng));
	};

	#convertBrowserLng = (lng) => lng.split('-')[0];
	#isLanguageAvailable = (lng) =>
		!!this.availableLanguages.find((item) => item === lng);
}
