import { TableCell } from '@material-ui/core';
import { EMPTY_VALUE } from 'helpers';
import { object } from 'prop-types';
import React from 'react';

import { useCommonStyles } from '../../styles';
import { getDaysDiff } from './helpers';

const DateTableCell = ({ cell }) => {
	const commonClasses = useCommonStyles();

	return (
		<TableCell {...cell.getCellProps()} className={commonClasses.cell}>
			{cell.value ? `${cell.value} ${getDaysDiff(cell)}` : EMPTY_VALUE}
		</TableCell>
	);
};

DateTableCell.propTypes = {
	cell: object.isRequired
};

export default DateTableCell;
