import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { number } from 'prop-types';
import React from 'react';

import { ScaleLinesWrapper } from '../components';
import { getXAxisLabels } from './helpers';
import { useStyles } from './styles';
const XAxisLabels = ({ scaleValuesQuantity, xAxisNonPositiveValues }) => {
	const classes = useStyles();

	const xAxisLabels = getXAxisLabels({
		scaleValuesQuantity,
		xAxisNonPositiveValues
	});

	const renderAxisLabel = (value) => (
		<Box key={value} className={classes.labelWrapper}>
			<Typography
				className={clsx([classes.label, 'z-score--x-axis-label'])}
			>
				{value}
			</Typography>
		</Box>
	);

	return (
		<ScaleLinesWrapper className={classes.root}>
			{renderAxisLabel(xAxisLabels[0] - 1)}
			{xAxisLabels.map(renderAxisLabel)}
		</ScaleLinesWrapper>
	);
};

XAxisLabels.propTypes = {
	scaleValuesQuantity: number.isRequired,
	xAxisNonPositiveValues: number.isRequired
};

export default XAxisLabels;
