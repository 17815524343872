import PropTypes from 'prop-types';
import React from 'react';

import InputErrorLabel from './InputErrorLabel';

const InputError = ({ meta }) => {
	return meta.touched && (meta.error || meta.submitError) ? (
		<InputErrorLabel
			translatedError={meta.error}
			message={meta.submitError}
		/>
	) : null;
};

InputError.propTypes = {
	meta: PropTypes.object.isRequired
};

export default InputError;
