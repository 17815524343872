import { useTranslations } from 'components/utilities';
import React from 'react';

const TimeOfDay = ({ taking, className, component: Component }) => {
	const { t } = useTranslations();
	return taking.map(({ time_of_day, amount, applying_way }) => (
		<Component
			key={time_of_day}
			component="p"
			variant="body2"
			className={className}
		>
			{`${amount}x ${t(
				`patients.action_plan.create.medications.${applying_way}`
			)} ${t(
				`patients.action_plan.create.medications.preview.${time_of_day}`
			)}`}
		</Component>
	));
};

export default TimeOfDay;
