import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		position: 'absolute',
		top: 0,
		left: 0,
		backgroundColor: ({ backgroundColor }) => backgroundColor,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%',
		width: '100%',
		zIndex: 9999,
		backdropFilter: 'blur(2px)'
	},
	label: {
		fontSize: '24px',
		color: theme.palette.customBlack.main,
		textAlign: 'center'
	},
	relativeContainer: {
		height: '100%',
		width: '100%',
		position: 'relative'
	}
}));
