import { useMutation } from 'components/utilities';

import { AuthRepository } from './repository';

/**
 * reset password request
 * @param {object} options useQuery options
 * @returns reset password request mutation
 */
const useResetPasswordEmailRequest = (options = {}) => {
	return useMutation(
		(payload) => AuthRepository.resetPasswordEmailRequest(payload),
		options
	);
};

export default useResetPasswordEmailRequest;
