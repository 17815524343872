import { Box } from '@material-ui/core';
import { Title } from 'components/elements/atoms/ExaminationsV2';
import { ValueInBorder } from 'components/elements/molecules/ExaminationsV2';
import { BronchodilatorReportModel } from 'models/Report/Reports';
import React from 'react';
import { useObstructionLevel } from 'utilities/Examinations/ExaminationDetails/common';

import { OrientationType } from '../Obstruction';
import { useStyles } from './styles';

type Props = {
	examination: BronchodilatorReportModel | undefined;
	orientation: OrientationType;
};

export const ObstructionPrePostValues = ({
	examination,
	orientation
}: Props) => {
	const classes = useStyles({ orientation });
	const { obstructionLabels: preLabels } = useObstructionLevel({
		fev1PredictedPercent:
			examination?.pre.bestParameters.fev1.predictedPercent ?? null,
		percentileFev1Fvc:
			examination?.pre.bestParameters.fev1Fvc.percentile ?? null
	});

	const { obstructionLabels: postLabels } = useObstructionLevel({
		fev1PredictedPercent:
			examination?.post.bestParameters.fev1.predictedPercent ?? null,
		percentileFev1Fvc:
			examination?.post.bestParameters.fev1Fvc.percentile ?? null
	});

	return (
		<Box className={classes.prePostValuesContainer}>
			<Box className={classes.obstructionValue}>
				<Title disableTranslation title={'PRE'} />
				<ValueInBorder
					className={classes.valueInBorder}
					value={
						preLabels?.level
							? preLabels.level
							: preLabels?.obstruction
					}
				/>
			</Box>
			<Box className={classes.obstructionValue}>
				<Title disableTranslation title={'POST'} />
				<ValueInBorder
					className={classes.valueInBorder}
					value={
						postLabels?.level
							? postLabels.level
							: postLabels?.obstruction
					}
				/>
			</Box>
		</Box>
	);
};
