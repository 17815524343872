import { Typography } from '@material-ui/core';
import { ReactComponent as SuccessIcon } from 'assets/icons/ic_checkbox_on_green.svg';
import clsx from 'clsx';
import { useTranslations } from 'components/utilities';
import React from 'react';

import { CornerCloseButton } from '../../../../../molecules/CornerCloseButton';
import { ContentLayout, IContentLayoutProps } from '../ContentLayout';
import { useStyles } from './styles';
import { Text } from './Text';

export interface ISuccessContentProps {
	classNameContent?: IContentLayoutProps<never>['classNameContent'];
	textContent?: TranslationKey;
	close: () => void;
}

export const SuccessContent = ({
	close,
	textContent = '',
	classNameContent = ''
}: ISuccessContentProps) => {
	const classes = useStyles();
	const { t } = useTranslations();
	return (
		<>
			<ContentLayout
				close={close}
				classNameContent={clsx([
					classNameContent,
					classes.contentContainer
				])}
				actionButtonsProps={{
					confirm: {
						onClick: close,
						label: 'btn.ok'
					},
					cancel: {
						isHide: true
					}
				}}
			>
				<>
					<CornerCloseButton onClick={close} />
					<SuccessIcon data-testid="success-icon" />
					<Text
						text={'global.success'}
						variant="title"
						suffix={'!'}
					/>
					{textContent && (
						<Typography className={classes.contentLabel}>
							{t(textContent)}
						</Typography>
					)}
				</>
			</ContentLayout>
		</>
	);
};
