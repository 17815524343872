import { format, getHours, getMinutes, isValid } from 'date-fns';

import { TIME_FORMAT } from './variables';

const timeFormat = (time) => (time < 10 ? `0${time}` : time);

export const concatDates = (date, time) => {
	const newDate = format(date, 'yyy-MM-dd');
	const newTime = format(time, 'HH:mm');

	return `${newDate} ${newTime}:00`;
};

export const getTime = (dateToConvert) => {
	const date = new Date(dateToConvert);
	const h = getHours(date);
	const m = getMinutes(date);
	const minutes = timeFormat(m);
	const hours = timeFormat(h);
	return `${hours}:${minutes}`;
};

export const stringToISO = (time, ampm) => {
	const today = format(new Date(), 'dd:MM:yyy');

	// get hh:mm values from time string
	const [hh, mm] = time.slice(0, 5).split(':');

	// delete 0 from hour format (03 => 3)
	const convertedHh = hh.charAt(0) === '0' ? Number(hh.slice(0)) : Number(hh);

	// get AM/PM value if exists
	const ampmValue = time.slice(6);

	// split today date
	const [dd, MM, yyyy] = today.split(':');

	// convert AMPM format to 24
	const hour =
		ampm && ampmValue === TIME_FORMAT.PM ? convertedHh + 12 : convertedHh;

	// create ISO format
	const ISOTime = new Date(
		Number(yyyy),
		Number(MM) - 1,
		Number(dd),
		hour,
		Number(mm)
	);
	return isValid(ISOTime) && ISOTime;
};
