import React from 'react';

import { validateEmail } from './validation';

export interface IUseEmailTextInputProps {
	initialEmail: string;
}

export const useEmailTextInput = ({
	initialEmail
}: IUseEmailTextInputProps) => {
	const [email, setEmail] = React.useState(initialEmail ?? '');
	const [isDirty, setDirty] = React.useState<boolean>(false);
	const [isValidEmail, setIsValidEmail] = React.useState(
		validateEmail(email)
	);

	const handleChange = React.useCallback((e) => {
		setEmail(e.target.value);
		setIsValidEmail(validateEmail(e.target.value));
	}, []);

	const handleSetEmailInputDirty = () => {
		setDirty(true);
	};

	return {
		onChange: handleChange,
		setEmail,
		setIsValidEmail,
		setEmailInputAsDirty: handleSetEmailInputDirty,
		isValidEmail,
		email,
		isDirty
	};
};
