import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		flex: 1
	},
	obstructionContainer: {
		justifyContent: 'center',
		flexDirection: ({ orientation }) => orientation,
		display: 'flex',
		alignItems: ({ orientation }) =>
			orientation === 'row' ? 'center' : 'initial',
		gap: ({ orientation }) => (orientation === 'row' ? 10 : 0)
	},
	title: {
		marginBottom: 0
	},
	subTitle: {
		paddingBottom: 5
	},
	obstructionLegend: {
		fontSize: '10.5px',
		lineHeight: '13.4px',
		color: theme.palette.gray.medium,
		width: '100%',
		flex: 3
	},
	rowLegend: {
		display: 'flex',
		width: '100%',
		justifyContent: 'space-betweenś'
	},
	spacer: {
		flex: 1
	}
}));
