import palette from 'theme/palette';

export const LEGEND_ITEMS = [
	{
		label: 'report.bronchodilator.pre',
		lineColor: palette.report.blue.main
	},
	{
		label: 'report.bronchodilator.post',
		lineColor: palette.report.green.main
	}
];
