import { RouterSwitchWithTransition } from 'components/elements';
import React from 'react';
import { Route } from 'react-router-dom';

import { EXAMINATIONS_V2_ROUTES } from './helpers/examinationsV2Routes';

export const ExaminationsV2Routes = () => {
	return (
		<RouterSwitchWithTransition routes={EXAMINATIONS_V2_ROUTES}>
			{EXAMINATIONS_V2_ROUTES.map((route) => (
				<Route
					exact={false}
					key={route.path}
					path={route.path}
					render={(matchProps) => (
						<>
							{route.helmet}
							<route.component {...matchProps} />
						</>
					)}
				/>
			))}
		</RouterSwitchWithTransition>
	);
};
