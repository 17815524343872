import { useMutation } from 'components/utilities';
import { AppointmentsRepository, usePatientDataMutation } from 'queries';
import { useParams } from 'react-router-dom';

const useAppointment = () => {
	const { appointmentId } = useParams();

	const { mutateAsync } = usePatientDataMutation();

	const fetchData = async (_, cancelToken) => {
		try {
			const { data: appointmentData } =
				await AppointmentsRepository.getAppointment({
					appointmentId,
					cancelToken
				});

			const { patient_id } = appointmentData;
			const { patient: patientData } = await mutateAsync({
				cancelToken,
				patientId: patient_id
			});

			const { patientFirstName, patientLastName } = patientData;
			const patientName = `${patientFirstName} ${patientLastName}`;
			return { ...appointmentData, patientName };
		} catch (error) {
			throw new Error(error);
		}
	};

	const mutation = useMutation(fetchData);

	return mutation;
};

export default useAppointment;
