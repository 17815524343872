// Chart lines
// FEV1 #11A0F7, PEF #4AE0B2, AQI #4A4FE0, FVC #815745, PM 2.5 #DB5A35, PM 10 #D4D181, VOC #FC9DEE, FEV1/FVC #3DC638;
import { CHART_MEASUREMENT_PARAMETER_OPTION_NAMES } from './parameters';

export const CHART_EXAMINATION_PARAMETER_TYPES = {
	LUNG: 'lung',
	HEART: 'heart',
	SLEEP: 'sleep',
	VITALS: 'vitals',
	BODY: 'body',
	ACTIVITY: 'activity',
	NUTRITION: 'nutrition',
	AIR: 'air',
	LAB_TEST: 'lab_test',
	ALL: 'all',
	MEDICAL_NOTE: 'medicalNote'
};

const { LUNG, HEART } = CHART_EXAMINATION_PARAMETER_TYPES;

const { fev1, fvc, pef, tiffeneau, ic, vc } =
	CHART_MEASUREMENT_PARAMETER_OPTION_NAMES;

const { hrAvg, spO2 } = CHART_MEASUREMENT_PARAMETER_OPTION_NAMES;

export const CHART_EXAMINATION_PARAMETER_GROUPS = {
	[LUNG]: [fvc, fev1, tiffeneau, pef, ic, vc],
	[HEART]: [hrAvg, spO2]
};
