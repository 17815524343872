import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	button: {
		padding: 0,
		marginLeft: 3
	},
	container: {
		minWidth: 234,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		'& .MuiList-padding': {
			paddingTop: 0,
			paddingBottom: 0
		}
	}
}));
