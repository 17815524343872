import { DASH_PATHS } from './dashboard_paths';

const { SCHEDULE } = DASH_PATHS;

export const SCHEDULE_PATHS_PARAMS_KEYS = {
	PATIENT_ID: ':patientId',
	APPOITMENT_ID: ':appointmentId'
};

const { PATIENT_ID, APPOITMENT_ID } = SCHEDULE_PATHS_PARAMS_KEYS;

export const SCHEDULE_SUB_PATH = 'appointment';
export const SCHEDULE_PATHS = {
	CREATE_APPOINTMENT: `${SCHEDULE}/${SCHEDULE_SUB_PATH}/create`,
	CREATE_PATIENT_APPOINTMENT: `${SCHEDULE}/${SCHEDULE_SUB_PATH}/create/${PATIENT_ID}`,
	EDIT_APPOINTMENT: `${SCHEDULE}/${SCHEDULE_SUB_PATH}/${APPOITMENT_ID}/edit`,
	REVIEW_APPOINTMENT: `${SCHEDULE}/${SCHEDULE_SUB_PATH}/${APPOITMENT_ID}/review`
};
