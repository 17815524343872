import {
	CHART_MEASUREMENT_PARAMETER_OPTION_NAMES,
	getUserUnit,
	recalculateParameterValueUnit,
	SPIROMETRY_EXAMINATION_VALUE_KEYS
} from 'helpers';
import { PeakFlowPreviewModel } from 'models/Examinations/allExaminationsTypes';

import { IParameterValuesData } from '../Parameters/ParameterValuesData';
import { SpirometryParameters } from '../Parameters/SpirometryParameters';
import { IParameterPreviewRow } from './common';

const {
	best: { key: bestKey },
	PBPercentage: { key: PBPercentageKey }
} = SPIROMETRY_EXAMINATION_VALUE_KEYS;
const { pef } = CHART_MEASUREMENT_PARAMETER_OPTION_NAMES;
export class PeakFlowPreview {
	private pef: ISpirometryParameters;
	private spirometryParameters: SpirometryParameters;

	constructor(data: PeakFlowPreviewModel, personalBest) {
		this.spirometryParameters = new SpirometryParameters(
			personalBest,
			CHART_MEASUREMENT_PARAMETER_OPTION_NAMES.pef.name
		);
		this.pef = data.pef;
	}
	getPefPreview = (): IParameterPreviewRow<IParameterValuesData> => {
		const pefRecalculatedData = {
			...this.pef,
			[bestKey]: recalculateParameterValueUnit(
				pef.name,
				this.pef[bestKey]
			)
		};
		const pefValues =
			this.spirometryParameters.getValuesWithColors(pefRecalculatedData);

		pefValues[bestKey].setUnit(getUserUnit(pef.name));

		return {
			name: CHART_MEASUREMENT_PARAMETER_OPTION_NAMES.pef.name.toUpperCase(),
			values: [pefValues[bestKey], pefValues[PBPercentageKey]]
		};
	};
}
