import {
	AutocompleteControlledField,
	TextFieldAdapter
} from 'components/elements';
import { useTranslations } from 'components/utilities';
import React from 'react';
import { Field, useFormState } from 'react-final-form';

import usePatientSelect from './usePatientSelect';

export const FIELD_NAME = {
	PATIENT_ID: 'patient_id',
	PATIENT_NAME: 'patient_name'
};

const { PATIENT_ID, PATIENT_NAME } = FIELD_NAME;

const LABEL = 'schedule.form.labels.patient';

const PatientSelect = () => {
	const { t } = useTranslations();
	const { submitting, values } = useFormState();

	const {
		patientsList,
		isAppointmentReview,
		renderOptionSelected,
		onChange
	} = usePatientSelect();

	/* eslint-disable */
	const init =
		patientsList && values[PATIENT_ID]
			? {
					[PATIENT_ID]: values[PATIENT_ID],
					name: values[PATIENT_NAME]
			  }
			: null;
	/* eslint-enable */

	return isAppointmentReview ? (
		<Field
			fullWidth
			component={TextFieldAdapter}
			type="textarea"
			label={t(LABEL)}
			name={PATIENT_NAME}
			variant="outlined"
			autoComplete="false"
			disabled={submitting || isAppointmentReview}
			margin="dense"
		/>
	) : (
		<AutocompleteControlledField
			fullWidth
			initialValue={init}
			options={patientsList}
			getOptionSelected={renderOptionSelected}
			getOptionLabel={({ firstName, lastName, name }) =>
				name ? name : `${firstName} ${lastName}`
			}
			type="text"
			name={PATIENT_ID}
			component={TextFieldAdapter}
			label={t(LABEL)}
			margin="dense"
			variant="outlined"
			autoComplete="false"
			disabled={submitting}
			defaultValue={{}}
			onInputChange={onChange}
			required={true}
		/>
	);
};

export default PatientSelect;
