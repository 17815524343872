import { useTranslations } from 'components/utilities';
import { IDLE } from 'helpers/variables';
import { func, shape } from 'prop-types';
import { useQueryClient } from 'react-query';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { useMutation, useQuery } from './react-query';

const useForm = ({
	fetchFormResAction,
	submitFormAction,
	QUERY_KEY,
	goBackPath,
	isGoBackAction,
	queryOptions
}) => {
	const { pathname } = useLocation();
	const history = useHistory();
	const { patientId, id } = useParams();
	const isEdit = pathname.includes('edit');
	const queryClient = useQueryClient();
	const { t } = useTranslations();

	const {
		data,
		isLoading,
		isError: isResourcesError,
		apiError: fetchResourcesApiError
	} = useQuery(QUERY_KEY, ({ cancelToken }) =>
		fetchFormResAction({ isEdit, patientId, id, cancelToken })
	);

	const {
		mutateAsync,
		status: idle,
		apiError: submitApiError
	} = useMutation(
		(values, cancelToken) =>
			submitFormAction({ isEdit, values, patientId, id, cancelToken }),
		{
			onSuccess: handleSuccess
		}
	);

	async function handleSuccess(data) {
		await queryClient.invalidateQueries(QUERY_KEY, {
			refetchInactive: true,
			exact: true,
			refetchActive: true
		});
		goBackPath
			? history.push(goBackPath)
			: isGoBackAction && history.goBack();
		queryOptions?.onSuccess?.(data, isEdit);
	}

	return {
		isEdit,
		patientId,
		data: data?.data?.data || data?.data,
		isResourcesLoading: isLoading,
		isResourcesError,
		isSubmitError: idle === IDLE.ERROR,
		isSubmitLoading: idle === IDLE.LOADING,
		apiError:
			fetchResourcesApiError ||
			submitApiError ||
			t('unknown_error_occurred'),
		submitForm: mutateAsync,
		queryOptions: shape({
			onSuccess: func
		})
	};
};

export default useForm;
