import { z } from 'zod';

export interface UserModel {
	id: number;
	email: string;
	first_name: string;
	last_name: string;
	token: string;
	ttl: number;
}

export const RegisterFirebaseMessagingTokenRequestSchema = z.object({
	fir_token: z.string()
});
export type RegisterFirebaseMessagingTokenRequestModel = z.infer<
	typeof RegisterFirebaseMessagingTokenRequestSchema
>;

export const RegisterFirebaseMessagingTokenResponseSchema = z.object({
	status: z.literal('OK')
});
export type RegisterFirebaseMessagingTokenResponseModel = z.infer<
	typeof RegisterFirebaseMessagingTokenResponseSchema
>;

export const UnregisterFirebaseMessagingTokenRequestSchema = z.object({
	fir_token: z.string()
});
export type UnregisterFirebaseMessagingTokenRequestModel = z.infer<
	typeof UnregisterFirebaseMessagingTokenRequestSchema
>;

export const UnregisterFirebaseMessagingTokenResponseSchema = z.object({
	status: z.literal('OK')
});
export type UnregisterFirebaseMessagingTokenResponseModel = z.infer<
	typeof UnregisterFirebaseMessagingTokenResponseSchema
>;
