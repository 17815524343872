import { makeStyles } from '@material-ui/styles';

const GAP = 5;

export const useStyles = makeStyles(() => ({
	root: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between'
	},
	content: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		gap: GAP,
		position: 'relative'
	},
	alert: {
		marginBottom: GAP
	}
}));
