import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	stickyColumn: {
		'& tr > :first-child': {
			position: 'sticky',
			left: 0
		}
	}
}));
