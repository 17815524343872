import React from 'react';
import { ExaminationsParametersType } from 'types/Examinations/parameters';
import { ReactContext } from 'utilities';

import { useExaminationsV2RefsContext } from '../ExaminationsV2';
import useExaminationsTrendChartData from './hooks/useExaminationsTrendChartData';
import useExaminationsTrendChartProviderInitialState from './hooks/useExaminationsTrendChartProviderInitialState';

export type ExaminationsTrendChartContextType = {
	selectedParameters: ExaminationsParametersType[] | null;
	getNewSelectedExaminationParameters: (
		selectedParameters: ExaminationsParametersType[],
		parameter: ExaminationsParametersType
	) => ExaminationsParametersType[];
	toggleExaminationParameters: (
		parameter: ExaminationsParametersType
	) => void;
} & ReturnType<typeof useExaminationsTrendChartData>;

const [useCtx, Ctx] =
	ReactContext.createContext<ExaminationsTrendChartContextType>();

type Props = {
	children: CmpChildren;
};

export const ExaminationsTrendChartProvider = ({ children }: Props) => {
	const { store: trendChartRef } = useExaminationsV2RefsContext(
		(s) => s.trendChartRef
	);
	const initialState = useExaminationsTrendChartProviderInitialState();

	const examinationsChartMutation = useExaminationsTrendChartData({
		trendChartRef
	});

	return (
		<Ctx.Provider
			value={{
				...initialState,
				...examinationsChartMutation
			}}
		>
			{children}
		</Ctx.Provider>
	);
};

export const useExaminationsTrendChartContext = useCtx;
