import { ForcedSpirometryMeasurementModel } from 'models/Report/ForcedSpirometry';
import { SlowVitalCapacitySVCMeasurementModel } from 'models/Report/SlowVitalCapacitySVC';

export type CorrectnessMeasurementModel =
	| ForcedSpirometryMeasurementModel
	| SlowVitalCapacitySVCMeasurementModel;

export const getMeasurementByNumber = (
	index: number,
	measurements?: CorrectnessMeasurementModel[]
): CorrectnessMeasurementModel | undefined =>
	measurements?.find((meas) => meas.num === index + 1);
