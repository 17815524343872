import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	form: {
		flexBasis: 700,
		[theme.breakpoints.down('sm')]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2)
		}
	},
	titleWrapper: {
		display: 'flex',
		alignItems: 'center',
		marginTop: 10,
		marginBottom: 20
	},
	title: {
		verticalAlign: 'middle',
		marginTop: 4,
		marginLeft: theme.spacing(1),
		fontSize: '24px'
	},
	subtitle: {
		fontWeight: 400,
		color: theme.palette.text.main,
		marginBottom: 50,
		textTransform: 'inherit',
		fontSize: 20,
		opacity: 0.7
	},
	buttonProgress: {
		fontWeight: 500
	},
	wrapper: {
		margin: `${theme.spacing(2.5)}px 0`,
		position: 'relative'
	},
	error: { marginBottom: theme.spacing(3) }
}));
