import { produce } from 'immer';
import * as types from 'store/types';

const INITIAL_STATE = {
	settings: {},
	units: {}
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.SET_USER_SETTINGS:
				draft.settings = action.payload;
				break;
			case types.SET_USER_UNITS:
				draft.units = action.payload;
				break;
			case types.UPDATE_USER_SETTING:
				draft.settings = action.payload;
				break;
			default:
				return state;
		}
	});
