import { useQuery } from 'components/utilities';
import { QUERY_COUNTRIES } from 'helpers';

import { CountriesRepository } from './repository';

const useFetch = (options = {}) => {
	return useQuery(
		[QUERY_COUNTRIES],
		(payload) => CountriesRepository.getAll(payload),
		{ cacheTime: 8 * 60 * 60 * 1000, ...options }
	);
};

export default useFetch;
