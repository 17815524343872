import { Box, Button } from '@material-ui/core';
import { ButtonProgress, RouterLink } from 'components/elements';
import { APP_PATHS } from 'components/routes/paths';
import { useTranslations } from 'components/utilities';
import { bool } from 'prop-types';
import React from 'react';

import { useStyles } from './styles';

const { SIGN_IN } = APP_PATHS;

const ActionButtons = ({ submitting, isSuccess }) => {
	const classes = useStyles();
	const { t } = useTranslations();

	return (
		<Box className={classes.buttonWrapper}>
			{isSuccess ? (
				<Button
					fullWidth
					className={classes.button}
					component={RouterLink}
					to={SIGN_IN}
					color="primary"
					size="large"
					variant="contained"
				>
					{t('btn.ok')}
				</Button>
			) : (
				<ButtonProgress
					className={classes.button}
					color="primary"
					disabled={submitting}
					isLoading={submitting}
					fullWidth
					size="large"
					type="submit"
					variant="contained"
				>
					{t('reset_password.reset_btn')}
				</ButtonProgress>
			)}
		</Box>
	);
};

ActionButtons.propTypes = {
	submitting: bool.isRequired,
	isSuccess: bool.isRequired
};

export default ActionButtons;
