import clsx from 'clsx';
import React from 'react';

import { ContainerWithBorder } from '../../../atoms/ExaminationsV2';
import {
	ValueLabel,
	ValueLabelProps
} from '../../../atoms/ExaminationsV2/Typography/ValueLabel';
import { useStyles } from './styles';

type Props = {
	value: ValueLabelProps['value'];
	className?: string;
};
export const ValueInBorder = ({ value, className }: Props) => {
	const classes = useStyles();

	return (
		<ContainerWithBorder className={clsx([className, classes.container])}>
			<ValueLabel className={classes.value} value={value} />
		</ContainerWithBorder>
	);
};
