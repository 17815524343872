import {
	AUTH_API_URL,
	AUTH_LOGOUT_API_URL,
	AUTH_REFRESH_TOKEN_API_URL,
	AUTH_RESEND_2FA_VERIFICATION_TOKEN_API_URL,
	AUTH_RESET_PASSWORD_EMAIL_REQUEST_API_URL,
	AUTH_RESET_USER_PASSWORD_API_URL,
	DOCTOR_ACCOUNT_VERIFICATION_API,
	MY_PROFILE_EDIT_PASSWORD_API,
	MY_PROFILE_USER_PARAM_KEY
} from 'helpers';
import { TwoFactorAuthModel } from 'models/Auth/SignIn/signIn';
import { DoctorUserModel } from 'models/User/Doctor';
import services from 'services/services';

export const AuthRepository = {
	signIn: (
		payload: SignInPayload
	): Promise<DoctorUserModel | TwoFactorAuthModel> =>
		services.post(AUTH_API_URL, payload),
	/**
	 * user sign out action
	 * @returns Promise
	 */
	signOut: () => services.post(AUTH_LOGOUT_API_URL),
	/**
	 * send email with reset password link
	 * @param {object} payload request payload
	 * @returns Promise
	 */
	resetPasswordEmailRequest: (payload) =>
		services.post(AUTH_RESET_PASSWORD_EMAIL_REQUEST_API_URL, payload),
	/**
	 * resets user password
	 * @param {object} payload request payload
	 * @returns Promise
	 */
	resetPassword: (payload) =>
		services.post(AUTH_RESET_USER_PASSWORD_API_URL, payload),
	/**
	 * edit user password
	 * @param {object} args
	 * @param {number} args.userId users ID
	 * @param {object} args.payload new password payload
	 * @returns Promise
	 */
	editPassword: ({ userId, payload }) => {
		const apiUrl = MY_PROFILE_EDIT_PASSWORD_API.replace(
			MY_PROFILE_USER_PARAM_KEY,
			userId
		);
		return services.put(apiUrl, payload);
	},
	/**
	 * refresh expiration time of sign in token
	 * @returns Promise
	 */
	refreshToken: () => services.post(AUTH_REFRESH_TOKEN_API_URL),
	/**
	 * user account verification
	 * @param {object} payload request payload
	 * @returns Promise
	 */
	verifyAccount: (payload) =>
		services.post(DOCTOR_ACCOUNT_VERIFICATION_API, payload),

	resend2FAVerificationToken: (
		payload: Resend2FAVerificationTokenPayload
	): Promise<TwoFactorAuthModel> =>
		services.post(AUTH_RESEND_2FA_VERIFICATION_TOKEN_API_URL, payload)
};
