import { useExaminationItemEvents } from 'components/context/ExaminationsV2';
import { bool, object, string } from 'prop-types';
import React from 'react';

import { Content, ItemWrapper, Parameter } from './components';
import { useStyles } from './styles';

const Examination = ({
	data,
	isListItem = false,
	classNameRoot = '',
	isActive
}) => {
	const classes = useStyles();
	const { examinationItemOnClick } = useExaminationItemEvents();
	const { parameters, scores, showType, component: Component } = data;

	const handleSetExamination = () => {
		examinationItemOnClick(data);
	};

	return (
		<ItemWrapper
			className={classNameRoot}
			isListItem={isListItem}
			onClick={handleSetExamination}
			isActive={isActive}
		>
			<Content scores={scores} {...data} isActive={isActive}>
				{Component ? (
					<Component {...data} />
				) : (
					<>
						{parameters?.map((parameter, index) => (
							<Parameter
								key={
									parameter?.name
										? parameter?.name
										: JSON.stringify(parameter)
								}
								className={
									index === parameters.length - 1
										? classes.parameters
										: ''
								}
								{...parameter}
								showType={showType}
							/>
						))}
					</>
				)}
			</Content>
		</ItemWrapper>
	);
};

Examination.propTypes = {
	classNameRoot: string,
	isListItem: bool,
	isActive: bool,
	data: object.isRequired
};

export default Examination;
