import {
	Box,
	Button,
	MenuItem,
	Typography as MuiTypography} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
	ArrowBackIosIcon,
	ArrowForwardIosIcon,
	ExpandMoreIcon} from 'assets/icons/commonMUIIcons';
import clsx from 'clsx';
import { SelectField } from 'components/elements/molecules';
import { useTranslations } from 'components/utilities';
import { DIRECTIONS } from 'helpers';
import { array, bool, func, number, string } from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
	wrapper: {
		display: 'flex',
		alignItems: 'center'
	},
	infoText: {
		color: theme.palette.gray.medium,
		marginRight: theme.spacing(3.25)
	},
	rowsPerSide: {
		color: theme.palette.gray.medium,
		marginRight: theme.spacing(1)
	},
	select: {
		marginRight: theme.spacing(5.7),
		color: theme.palette.gray.medium,
		'& .MuiInputBase-inputMarginDense': {
			paddingTop: 10
		},
		'&::after': {
			content: "''",
			borderBottom: 'none'
		},
		'&::before': {
			content: "''",
			borderBottom: 'none'
		}
	},
	icon: {
		width: '16px',
		height: '16px',
		color: theme.palette.gray.medium
	},
	btnPagination: {
		minWidth: '50px',
		padding: '12px 16px'
	},
	paddingTop: {
		paddingTop: 1
	}
}));

const TablePagination = (props) => {
	const { t } = useTranslations();

	const {
		canPreviousPage,
		previousPage,
		nextPage,
		canNextPage,
		pageOptions,
		pageIndex,
		pageSize,
		setPageSize,
		rowsPerPageOptions,
		direction
	} = props;

	const classes = useStyles();

	const handleChangeSize = (e) => setPageSize(Number(e.target.value));

	return (
		<Box className={classes.wrapper}>
			<MuiTypography
				component="span"
				className={clsx([classes.rowsPerSide, classes.paddingTop])}
			>
				{t('table.controllers.rows_per_page')}
			</MuiTypography>
			<SelectField
				classNameSelect={classes.select}
				value={pageSize}
				onChange={handleChangeSize}
				margin="dense"
				variant="standard"
				IconComponent={ExpandMoreIcon}
				minWidth={33}
			>
				{rowsPerPageOptions.map((pageSize) => (
					<MenuItem key={pageSize} value={pageSize}>
						{pageSize}
					</MenuItem>
				))}
			</SelectField>
			<MuiTypography
				component="span"
				className={clsx([classes.infoText, classes.paddingTop])}
			>
				{t('table.controllers.page')} {pageIndex + 1} {t('global.of')}{' '}
				{pageOptions.length}
			</MuiTypography>
			<Button
				className={classes.btnPagination}
				onClick={() => previousPage()}
				disabled={!canPreviousPage}
			>
				{direction === DIRECTIONS.RTL ? (
					<ArrowForwardIosIcon className={classes.icon} />
				) : (
					<ArrowBackIosIcon className={classes.icon} />
				)}
			</Button>
			<Button
				className={classes.btnPagination}
				onClick={() => nextPage()}
				disabled={!canNextPage}
			>
				{direction === DIRECTIONS.RTL ? (
					<ArrowBackIosIcon className={classes.icon} />
				) : (
					<ArrowForwardIosIcon className={classes.icon} />
				)}
			</Button>
		</Box>
	);
};

TablePagination.propTypes = {
	canPreviousPage: bool.isRequired,
	previousPage: func.isRequired,
	nextPage: func.isRequired,
	canNextPage: bool.isRequired,
	pageOptions: array.isRequired,
	pageIndex: number.isRequired,
	pageSize: number.isRequired,
	setPageSize: func.isRequired,
	rowsPerPageOptions: array.isRequired,
	direction: string.isRequired
};

export default TablePagination;
