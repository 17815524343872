import { Box } from '@material-ui/core';
import { Graph } from 'components/elements/atoms/ExaminationsV2';
import { useTranslations } from 'components/utilities';
import React from 'react';
import palette from 'theme/palette';

import { ContainerWithBorder } from '../../../components';
import {
	DEFAULT_ACTIVE_ATTEMPT_INDEX,
	usePrePostContext
} from '../../context/PrePostProvider';
import { useCopdData } from '../../useCopdData';
import { prepareAllMeasurementsDataToRenderInGraph } from './helpers';
import { Legend } from './Legend';
import { useStyles } from './styles';

export const Graphs = () => {
	const { t } = useTranslations();
	const classes = useStyles();
	const examinationData = useCopdData();

	const { flowVolumeGraphRef, volumeTimeGraphRef } = usePrePostContext();

	React.useEffect(() => {
		examinationData?.pre.measurements?.forEach((_, index) => {
			const flowVolumeSerie =
				flowVolumeGraphRef.current?.chart.series[index];
			const volumeTimeSerie =
				volumeTimeGraphRef.current?.chart.series[index];

			if (index > DEFAULT_ACTIVE_ATTEMPT_INDEX) {
				flowVolumeSerie?.hide();
				volumeTimeSerie?.hide();
			}
		});

		examinationData?.post.measurements?.forEach((_, index) => {
			const flowVolumeSerie =
				flowVolumeGraphRef.current?.chart.series[
					index + (examinationData?.pre.measurements ?? [])?.length
				];
			const volumeTimeSerie =
				volumeTimeGraphRef.current?.chart.series[
					index + (examinationData?.pre.measurements ?? [])?.length
				];

			if (index > DEFAULT_ACTIVE_ATTEMPT_INDEX) {
				flowVolumeSerie?.hide();
				volumeTimeSerie?.hide();
			}
		});
	}, []);

	return (
		<>
			<ContainerWithBorder className={classes.root}>
				<Box className={classes.graphsContainer}>
					<Graph
						ref={flowVolumeGraphRef}
						data={[
							...prepareAllMeasurementsDataToRenderInGraph(
								examinationData?.pre.measurements || [],
								'flowVolumeChart',
								palette.report.blue.dark,
								'pre'
							),
							...prepareAllMeasurementsDataToRenderInGraph(
								examinationData?.post.measurements || [],
								'flowVolumeChart',
								palette.orange.secondary,
								'post'
							)
						]}
						xAxisLabel={t('report.graph.volume.label')}
						yAxisLabel={t('report.graph.flow.label')}
						title={{ label: 'report.graph.best_flow.title' }}
						axisValuesRatio="2:1"
						axisBoundaries={{
							MIN_Y: -6,
							MAX_Y: 8,
							MIN_X: -1,
							MAX_X: 8
						}}
						containerBoundaries={{
							height: 320,
							width: 400
						}}
						disableTriangle
					/>
					<Graph
						ref={volumeTimeGraphRef}
						data={[
							...prepareAllMeasurementsDataToRenderInGraph(
								examinationData?.pre.measurements || [],
								'volumeTimeChart',
								palette.report.blue.dark,
								'pre'
							),
							...prepareAllMeasurementsDataToRenderInGraph(
								examinationData?.post.measurements || [],
								'volumeTimeChart',
								palette.orange.secondary,
								'post'
							)
						]}
						xAxisLabel={t('report.graph.time.label')}
						yAxisLabel={t('report.graph.volume.label')}
						title={{ label: 'report.graph.best_volume.title' }}
						axisValuesRatio="1:1"
						axisBoundaries={{
							MIN_Y: 0,
							MAX_Y: 6,
							MIN_X: 0,
							MAX_X: 8
						}}
						containerBoundaries={{
							height: 320,
							width: 400
						}}
					/>
				</Box>
				<Legend />
			</ContainerWithBorder>
		</>
	);
};
