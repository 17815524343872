import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	menuItem: {
		width: '100%',
		paddingLeft: 5,
		borderBottom: 'none !important'
	},
	checkBox: {
		paddingLeft: '5px'
	}
}));
