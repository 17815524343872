export class ExSmoker {
	constructor({ patientExSmokerSince }, translations) {
		this.exSmokerSince = patientExSmokerSince;
		this.translations = translations;
	}

	getLabel = () => {
		const exSmokerLabel =
			this.translations['patients.basic_data.field.smoker_ex_smoker'];

		return `${exSmokerLabel} (${this.exSmokerSince})`;
	};
}
