import { recalculateParameterValueUnit } from 'helpers';
import { ReportParameterModel } from 'models/Report/common';
import {
	ForcedSpirometryBestParametersModel,
	ForcedSpirometryMeasurementModel
} from 'models/Report/ForcedSpirometry';
import { SlowVitalCapacitySVCBestParametersModel } from 'models/Report/SlowVitalCapacitySVC';

import { ColumnDataImpl, ColumnValues } from '../common/createColumnData';
import {
	FVC_ALL_PARAMETERS,
	FVC_BEST_PARAMETERS,
	SVC_PARAMETERS
} from '../helpers/parameters';
import { ForcedSpirometryMeasurementModelWithExtendedParametersData } from './types';

export const convertBestResultsToColumnsData = (
	data?: ForcedSpirometryBestParametersModel,
	parameters: Partial<typeof FVC_ALL_PARAMETERS> = FVC_BEST_PARAMETERS
): ColumnValues<ReportParameterModel>[] => {
	if (!data) {
		return [];
	}

	const fvcColumnData = new ColumnDataImpl<
		ForcedSpirometryBestParametersModel,
		ReportParameterModel
	>(parameters, data);
	fvcColumnData.createColumnData();

	return fvcColumnData.columnData;
};

export const convertSVCBestResultToColumnData = (
	svcData?: SlowVitalCapacitySVCBestParametersModel
): ColumnValues<ReportParameterModel>[] => {
	const svcColumnData = new ColumnDataImpl<
		SlowVitalCapacitySVCBestParametersModel,
		ReportParameterModel
	>(SVC_PARAMETERS, svcData);
	svcColumnData.createColumnData();

	return svcColumnData.columnData;
};

const forceInteger = (v: string, isInteger: boolean) => {
	if (v && isInteger) {
		return Number(v).toFixed(0);
	}
	return v ? Number(v).toFixed(0) : v;
};

export const addBestValueToAllMeasurements = (
	measurements?: ForcedSpirometryMeasurementModel[],
	bestParameters?: ForcedSpirometryBestParametersModel
): ForcedSpirometryMeasurementModelWithExtendedParametersData[] => {
	if (!measurements) {
		return [] as ForcedSpirometryMeasurementModelWithExtendedParametersData[];
	}

	const bestMeasurement = measurements.find((m) => m.isBest);

	const parametersKeys = Object.keys(FVC_ALL_PARAMETERS) as Array<
		keyof typeof FVC_ALL_PARAMETERS
	>;
	return new Array(measurements.length).fill('').map((_, index) => {
		const element: (typeof measurements)[0] = measurements[index] || {};

		const parameterObjects = parametersKeys.reduce((acc, key) => {
			const bestValue: number | null =
				bestParameters?.[key]?.value ??
				bestMeasurement?.[key]?.value ??
				null;
			acc[key] = {
				...element[key],
				best: recalculateParameterValueUnit(key, bestValue)
			};

			if (key === 'tptefTe' || key === 'vptefVe') {
				acc[key] = {
					...acc[key],
					validator: forceInteger
				};
			}
			return acc;
		}, {} as ForcedSpirometryMeasurementModelWithExtendedParametersData);

		return parameterObjects;
	});
};
