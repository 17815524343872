import { Box } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import {
	ParameterLabel,
	ParameterLabelProps
} from '../../../atoms/ExaminationsV2/Typography/ParameterLabel';
import {
	ValueLabel,
	ValueLabelProps
} from '../../../atoms/ExaminationsV2/Typography/ValueLabel';
import { useStyles } from './styles';

export type Props = {
	labelProps: ParameterLabelProps;
	valueProps: ValueLabelProps;
	className?: string;
};
export const LabeledValue = ({
	labelProps,
	valueProps,
	className = ''
}: Props) => {
	const classes = useStyles();

	return (
		<Box className={clsx([classes.root, className])}>
			<ParameterLabel {...labelProps} />
			<ValueLabel {...valueProps} />
		</Box>
	);
};
