import { Loader } from 'components/elements';
import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { ROUTES } from './helpers/routes';
import { APP_PATHS } from './paths';

const { SIGN_IN } = APP_PATHS;

const Routes = () => (
	<React.Suspense fallback={<Loader />}>
		<Switch>
			<Redirect exact from="/" to={SIGN_IN} />
			{ROUTES.map(
				({
					component: Component,
					routeRenderer: RouteRenderer,
					path
				}) => (
					<RouteRenderer
						key={path}
						path={path}
						component={Component}
						render={(matchProps) => <Component {...matchProps} />}
					/>
				)
			)}
			<Redirect to={SIGN_IN} />
		</Switch>
	</React.Suspense>
);

export default Routes;
