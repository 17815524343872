import { makeStyles } from '@material-ui/styles';

export const useGlobalStyles = makeStyles((theme) => ({
	gridItem: { display: 'flex', alignItems: 'center' },
	item: {
		padding: '0px',
		textAlign: 'center',
		display: 'flex',
		justifyContent: 'center',
		[theme.breakpoints.down('xs')]: {
			padding: '8px 10px'
		}
	}
}));

export const useStyles = makeStyles(() => ({
	item: {
		width: '100%',
		height: '100%',
		justifyContent: 'flex-end'
	}
}));
