const transparent = 'transparent !important';

export default {
	'.fc': {
		fontFamily: 'Geomanist, sans-serif'
	},
	'.fc-daygrid-day': {
		background: transparent
	},
	'td.fc-day-today': {
		background: transparent
	},
	'.fc-timegrid-event': {
		background: transparent
	},
	'.fc-col-header-cell': {
		textTransform: 'capitalize',
		fontSize: '13px',
		fontWeight: 400
	},
	'.fc-daygrid-day-top': {
		position: 'absolute',
		bottom: 0,
		right: 0
	},
	'.fc-daygrid-day-events': {
		maxHeight: '6rem !important',
		minHeight: '6rem !important'
	},
	'.fc-timegrid-axis': {
		visibility: 'hidden'
	},
	'.fc-timegrid-slot': {
		borderBottomColor: transparent
	},
	'.fc-timegrid-slot-minor': {
		borderTopColor: transparent
	},
	'.fc-timegrid-slot-label': {
		borderColor: transparent,
		paddingRight: '0.5rem !important'
	},
	'.fc-timegrid-slot-label-cushion': {
		opacity: 0.5,
		fontSize: '15px',
		textTransform: 'uppercase'
	},
	'.fc-scrollgrid-sync-inner': {
		overflow: 'hidden'
	},
	'.fc-col-header-cell-cushion': {
		width: '100% !important',
		padding: '0 !important'
	},
	'.fc-daygrid-event-harness': {
		width: '100% !important'
	},
	'.fc-h-event': {
		background: transparent
	},
	'.fc-daygrid-event': {
		'&:hover': {
			backgroundColor: transparent
		}
	},
	'.fc-media-screen': {
		height: 'inherit'
	}
	// 'fc-theme-standard'
};
