import {
	useExaminationsTrendChartContext,
	useExaminationsV2RefsContext,
	useTooltipContext
} from 'components/context/ExaminationsV2';
import { HighCharts } from 'components/context/ExaminationsV2/classes';
import _ from 'lodash';
import React from 'react';

export const useInitialTooltipActivity = () => {
	const { store: trendChartRef } = useExaminationsV2RefsContext(
		(store) => store.trendChartRef
	);
	const { scrollToExaminationsListItem, resetExaminationsVirtualList } =
		useExaminationsV2RefsContext();

	const { isSuccess, isLoading } = useExaminationsTrendChartContext();
	const { store: examinationTooltip } = useTooltipContext(
		(s) => s.examinationTooltip
	);

	const activatePlot = _.debounce(() => {
		const highChart = new HighCharts(trendChartRef);
		highChart.setActivePlotLine({
			selected: false,
			name: examinationTooltip.chartName
		});
	}, 50);

	React.useEffect(() => {
		if (!isSuccess && isLoading) {
			return;
		}

		if (examinationTooltip.active) {
			activatePlot();
			if (examinationTooltip.listItemIndex) {
				scrollToExaminationsListItem(examinationTooltip.listItemIndex);
				resetExaminationsVirtualList();
			}
		}
	}, [isSuccess]);
};
