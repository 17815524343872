import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		minHeight: 50,
		display: 'flex',
		justifyContent: 'space-between',
		gap: '18px'
	}
}));
