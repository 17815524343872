import { TABLE_OPTIONS } from 'helpers/variables';

export const INITIAL_OPTIONS = {
	pageSize: TABLE_OPTIONS.PAGE_SIZE,
	pageIndex: TABLE_OPTIONS.PAGE_INDEX,
	sortBy: [],
	globalFilter: ''
};

export const INITIAL_STATE = {
	pageCount: 0,
	options: INITIAL_OPTIONS,
	tableColumns: [],
	inputColumns: [],
	resources: {},
	filters: null,
	error: '',
	items: []
};
