import { makeStyles } from '@material-ui/styles';
import { SIZES } from 'helpers/variables';

const { SMALL, NORMAL } = SIZES;

export const useStyles = makeStyles((theme) => ({
	root: {
		padding: ({ disableBorder }) => (disableBorder ? 0 : theme.spacing(2)),
		border: ({ disableBorder }) =>
			disableBorder ? 'none' : `1px solid ${theme.palette.border.light}`,
		borderRadius: 5,
		width: '90%',
		marginTop: 15,
		display: 'flex',
		justifyContent: 'space-between'
	},
	imageWrapper: {
		paddingLeft: 15,
		display: 'flex',
		alignItems: 'center'
	},
	container: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		paddingBottom: 13
	},
	chip: {
		marginLeft: ({ size }) => {
			switch (size) {
				case SMALL:
					return theme.spacing(1.75);
				case NORMAL:
					return theme.spacing(2.2);
				default:
					return theme.spacing(1.75);
			}
		},
		backgroundColor: ({ type }) => theme.palette.medication[type],
		padding: ({ size }) => {
			switch (size) {
				case SMALL:
					return theme.spacing(0.625);
				case NORMAL:
					return theme.spacing(0.8);
				default:
					return theme.spacing(0.625);
			}
		},
		borderRadius: ({ size }) => {
			switch (size) {
				case SMALL:
					return '5px';
				case NORMAL:
					return '6.13px';
				default:
					return '5px';
			}
		},
		height: ({ size }) => {
			switch (size) {
				case SMALL:
					return 20;
				case NORMAL:
					return 24.5;
				default:
					return 20;
			}
		},
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	chipText: {
		color: theme.palette.white,
		fontSize: ({ size }) => {
			switch (size) {
				case SMALL:
					return '10px';
				case NORMAL:
					return '12.3px';
				default:
					return '10px';
			}
		},
		lineHeight: ({ size }) => {
			switch (size) {
				case SMALL:
					return '10px';
				case NORMAL:
					return '12.3px';
				default:
					return '10px';
			}
		}
	},
	flex: {
		flex: 1
	},
	typography: {
		paddingTop: theme.spacing(0.4),
		fontWeight: 400,
		color: theme.palette.black,
		opacity: 0.8,
		fontSize: ({ size }) => {
			switch (size) {
				case SMALL:
					return '18px';
				case NORMAL:
					return '20px';
				default:
					return '18px';
			}
		}
	}
}));
