import { useScheduleCalendarContext } from 'components/context';
import { DASH_PATHS } from 'components/routes/paths';
import { useDeleteAppointment as useDeleteAppointmentQuery } from 'queries';
import { useHistory, useParams } from 'react-router-dom';

const useDeleteAppointment = () => {
	const history = useHistory();
	const { appointmentId } = useParams();
	const { appointmentsDateRange, getCalendarApi } =
		useScheduleCalendarContext();

	const {
		mutate: eventRemoveMutate,
		isLoading,
		isSuccess
	} = useDeleteAppointmentQuery(
		{
			onSuccess: handleSuccess
		},
		{ appointmentsDateRange, getCalendarApi }
	);

	function handleSuccess() {
		history.push(DASH_PATHS.SCHEDULE);
	}

	const handleDeleteItem = async (close) => {
		await eventRemoveMutate(appointmentId);
		isSuccess && close();
	};

	return { deleteAppointment: handleDeleteItem, isLoading };
};

export default useDeleteAppointment;
