export class Rgb {
	r;
	g;
	b;
	constructor(_r, _g, _b) {
		this.#validateColor(_r);
		this.#validateColor(_g);
		this.#validateColor(_b);
		this.r = _r;
		this.g = _g;
		this.b = _b;
	}

	getColorString = () => {
		return `rgb(${this.r}, ${this.g}, ${this.b})`;
	};

	#validateColor = (color) => {
		if (color > 255 || color < 0 || Number(color) % 1 !== 0) {
			throw new Error(
				`Wrong color format. Given: ${color}. Should be 0 > color > 255, integer number`
			);
		}
	};
}
