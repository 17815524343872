import React from 'react';

import { PatientCard } from '../components/organisms';
import { ReportTemplate } from '../components/templates';
import { BestResults } from './BestResults';

export const FirstPage = () => {
	return (
		<ReportTemplate
			config={{
				title: 'report.type.pulse_oximetry',
				footer: { pageCount: 1, pages: 1 },
				lastPage: true,
				refValue: {
					disabled: true
				}
			}}
		>
			<PatientCard />
			<BestResults />
		</ReportTemplate>
	);
};
