import React from 'react';

import { Action, ActionProps } from './types';

const useComponentFadeInOut = () => {
	const [animate, setAnimate] = React.useState<boolean>(false);
	const [onEnteredAction, setOnEnteredAction] = React.useState<{
		props: ActionProps;
		action: Action;
	}>({ props: undefined, action: () => {} });

	const handleSetOnEnteredAction = React.useCallback(
		(action: Action, props: ActionProps) => {
			setOnEnteredAction({ props, action });
		},
		[]
	);
	const handleTriggerAnimation = React.useCallback(() => {
		setAnimate(true);
	}, []);

	return {
		animate,
		setAnimate,
		onEnteredAction,
		setOnEnteredAction: handleSetOnEnteredAction,
		triggerAnimation: handleTriggerAnimation
	};
};

export default useComponentFadeInOut;
