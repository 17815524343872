import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslations } from 'components/utilities';
import { bool, number, oneOfType, string } from 'prop-types';
import React from 'react';

import { useGlobalStyles } from '../../styles';
import { useStyles } from './styles';

const Result = React.memo(
	({
		valueLabel,
		color = '',
		secondaryValueLabel = '',
		disableBorder = false,
		isLabel = false,
		fontWeight = 500
	}) => {
		const classes = useStyles({ color, fontWeight });
		const globalClasses = useGlobalStyles();
		const { t } = useTranslations();

		const labelComponent = React.useMemo(
			() => (
				<>
					{isLabel && (
						<Typography
							variant="h6"
							component="p"
							className={globalClasses.result}
						>
							{t(
								'patients.examinations.details.bronchodilator.obstruction'
							)}
							:&nbsp;
						</Typography>
					)}
					<Typography
						variant="h6"
						component="p"
						className={clsx([globalClasses.result, classes.result])}
					>
						{t(valueLabel)}
						{secondaryValueLabel && ', ' + t(secondaryValueLabel)}
					</Typography>
				</>
			),
			//eslint-disable-next-line
			[]
		);

		if (disableBorder) {
			return labelComponent;
		}

		return <Box className={globalClasses.box}>{labelComponent}</Box>;
	}
);

Result.propTypes = {
	valueLabel: string.isRequired,
	secondaryValueLabel: string,
	color: string,
	isLabel: bool,
	disableBorder: bool,
	fontWeight: oneOfType([number, string])
};

export default Result;
