import { IconButton } from '@material-ui/core';
import { EditIcon } from 'assets/icons/commonMUIIcons';
import { useScheduleCalendarContext } from 'components/context';
import { DASH_PATHS } from 'components/routes/paths';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import DeleteAppointment from './DeleteAppointment/DeleteAppointment';

const { SCHEDULE } = DASH_PATHS;

const EditControls = () => {
	const { isAppointmentReview } = useScheduleCalendarContext();
	const { appointmentId } = useParams();

	return (
		<>
			{isAppointmentReview && (
				<IconButton
					component={RouterLink}
					to={`${SCHEDULE}/appointment/${appointmentId}/edit`}
					color="primary"
				>
					<EditIcon fontSize="small" />
				</IconButton>
			)}
			<DeleteAppointment />
		</>
	);
};

export default EditControls;
