import { CHART_MEASUREMENT_PARAMETER_OPTION_NAMES } from 'helpers';

import { IParameterValuesData } from '../../ParameterValuesData';

export interface ICommonParameterBuilder {
	reset: () => void;
	setValidator: (value?: number) => void;
	setValue: (value: number | null) => void;
	setColor: (color?: ExaminationParameterStatus) => void;
	setUnit: (unit: string) => void;
	getValue: () => IParameterValuesData;
}

export class CommonParametersDirector {
	private examinationValues: CommonExaminationParameter;

	constructor(examinationValues: CommonExaminationParameter) {
		this.examinationValues = examinationValues;
	}

	createHrValue = (builder: ICommonParameterBuilder) => {
		builder.setValidator();
		builder.setValue(this.examinationValues.value);
		builder.setUnit(
			CHART_MEASUREMENT_PARAMETER_OPTION_NAMES.hrAvg.defaultUnit.toUpperCase()
		);
		builder.setColor(this.examinationValues.status);
	};

	createSpO2Value = (builder: ICommonParameterBuilder) => {
		builder.setValidator();
		builder.setValue(this.examinationValues.value);
		builder.setUnit(
			CHART_MEASUREMENT_PARAMETER_OPTION_NAMES.spO2.defaultUnit
		);
		builder.setColor(this.examinationValues.status);
	};

	createIcValue = (builder: ICommonParameterBuilder) => {
		builder.setValidator();
		builder.setValue(this.examinationValues.value);
		builder.setUnit(
			CHART_MEASUREMENT_PARAMETER_OPTION_NAMES.ic.defaultUnit
		);
		builder.setColor();
	};

	createVcValue = (builder: ICommonParameterBuilder) => {
		builder.setValidator();
		builder.setValue(this.examinationValues.value);
		builder.setUnit(
			CHART_MEASUREMENT_PARAMETER_OPTION_NAMES.vc.defaultUnit
		);
		builder.setColor();
	};
}
