import { Box, Grid } from '@material-ui/core';
import { CardTopBar, ErrorBoundary } from 'components/elements';
import { array, object } from 'prop-types';
import React from 'react';

import Nav from './Nav';
import PersonalBest from './PersonalBest';
import Profile from './Profile';
import { useStyles } from './styles';

const TopBar = ({ resources, countries }) => {
	const [best, patient] = resources;
	const classes = useStyles();
	return (
		<ErrorBoundary resetKeys={[patient]}>
			<CardTopBar isBox={true} classNameBox={classes.card}>
				<Box className={classes.content}>
					<Grid container spacing={0}>
						<Grid item lg={3} md={3} xs={12}>
							<Profile patient={patient} countries={countries} />
						</Grid>
						<Grid item lg={true} md={9} xs={12}>
							<PersonalBest best={best} />
						</Grid>
					</Grid>
				</Box>
				<Nav />
			</CardTopBar>
		</ErrorBoundary>
	);
};

TopBar.propTypes = {
	patientPersonalData: object,
	resources: array.isRequired,
	countries: object.isRequired
};

export default TopBar;
