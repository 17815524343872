import { makeStyles } from '@material-ui/styles';
import { PL } from 'helpers';

export const useStyles = makeStyles((theme) => ({
	root: {
		'& .PrivateSwitchBase-root-34': {
			padding: '2px 9px'
		}
	},
	label: {
		fontWeight: 600,
		'& span': {
			color: theme.palette.primary.main
		}
	},
	link: {
		marginLeft: ({ lng }) => (lng === PL ? 5 : 1),
		textDecoration: 'underline',
		'&:active': {
			color: theme.palette.primary.dark
		}
	}
}));
