import {
	CHART_MEASUREMENT_PARAMETER_OPTION_NAMES,
	SPIROMETRY_EXAMINATION_VALUE_KEYS
} from 'helpers';
import { SpirometryPreviewModel } from 'models/Examinations/allExaminationsTypes';
import { PersonalBestModel } from 'models/Examinations/PersonalBest';

import { Spirometry } from '../common/Spirometry';
import { IParameterValuesData } from '../Parameters';
import { IParameterPreviewRow } from './common';

const {
	best: { key: bestKey },
	PBPercentage: { key: PBPercentageKey },
	predicted: { key: predictedKey }
} = SPIROMETRY_EXAMINATION_VALUE_KEYS;

export class SpirometryPreview extends Spirometry {
	constructor(data: SpirometryPreviewModel, personalBest: PersonalBestModel) {
		super(data, personalBest);
	}

	getFev1Preview = (): IParameterPreviewRow<IParameterValuesData> => {
		const fev1Values = this.getFev1Values();

		return {
			name: CHART_MEASUREMENT_PARAMETER_OPTION_NAMES.fev1.name.toUpperCase(),
			values: [
				fev1Values[bestKey],
				fev1Values[PBPercentageKey],
				fev1Values[predictedKey]
			]
		};
	};

	getFvcPreview = (): IParameterPreviewRow<IParameterValuesData> => {
		const fvcValues = this.getFvcValues();

		return {
			name: CHART_MEASUREMENT_PARAMETER_OPTION_NAMES.fvc.name.toUpperCase(),
			values: [
				fvcValues[bestKey],
				fvcValues[PBPercentageKey],
				fvcValues[predictedKey]
			]
		};
	};
}
