import { QUERY_PATIENTS_LIST } from 'helpers';
import { bool, number, object, shape } from 'prop-types';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';

import { convertAppointmentTime } from './helpers';

const useAppointment = ({
	isMirror,
	event: {
		start,
		extendedProps: { patient_id, colorNumber = 0 }
	}
}) => {
	const { lng } = useSelector((store) => store.language);
	const queryCache = useQueryClient();

	const patientsList = queryCache.getQueryData(QUERY_PATIENTS_LIST)?.data;

	const startTime = convertAppointmentTime({ date: start, lng });

	const currentPatient = patientsList?.find(({ id }) => id === patient_id);

	const patientName =
		`${currentPatient?.firstName} ${currentPatient?.lastName}` || '';

	const isPastAppointment = start.getTime() < new Date().getTime();

	const stylesProps = { colorNumber, isMirror, isPastAppointment };

	const getEventBoxProps = () => {
		return { startTime, patientName };
	};

	return {
		isMirror,
		stylesProps,
		getEventBoxProps
	};
};

useAppointment.propTypes = {
	event: shape({
		isMirror: bool.isRequired,
		start: object.isRequired,
		extendedProps: shape({
			patient_id: number.isRequired,
			colorNumber: number.isRequired
		})
	})
};

export default useAppointment;
