import { AxiosRequestConfig } from 'axios';
import { AppLanguage, Cookies } from 'utilities';

import { EN } from '../helpers/variables';

export const getHeaders = (): AxiosRequestConfig['headers'] => {
	const token = Cookies.jwt.get();
	const appLanguage = new AppLanguage();

	const headers: AxiosRequestConfig['headers'] = {
		'Access-Control-Allow-Origin': '*',
		'Content-Type': 'application/json',
		Accept: 'application/json',
		'Accept-Language': appLanguage.getLngString() || EN
	};

	if (token) {
		headers['Authorization'] = `Bearer ${token}`;
	}

	return headers;
};
