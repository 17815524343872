import { Box, Grid, Typography as MuiTypography } from '@material-ui/core';
import { AddIcon } from 'assets/icons/commonMUIIcons';
import { Button, RouterLink } from 'components/elements';
import { useTranslations } from 'components/utilities';
import PropTypes from 'prop-types';
import React from 'react';

import { useStyles } from './styles';

const Toolbar = ({ title, pathname }) => {
	const classes = useStyles();

	const { t } = useTranslations();

	return (
		<Box className={classes.titleWrap}>
			<Grid
				container
				justifyContent="space-between"
				alignItems="center"
				spacing={2}
			>
				<Grid item xs="auto">
					<MuiTypography variant="h5">{t(title)}</MuiTypography>
				</Grid>

				<Grid item xs="auto">
					<Button
						className={classes.button}
						startIcon={<AddIcon />}
						component={RouterLink}
						to={pathname}
					>
						{t('patients.conditions.add_new')}
					</Button>
				</Grid>
			</Grid>
		</Box>
	);
};

Toolbar.propTypes = {
	title: PropTypes.string.isRequired,
	pathname: PropTypes.string.isRequired
};

export default Toolbar;
