import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		'&:first-of-type': {
			paddingTop: 0
		}
	},
	label: {
		fontSize: 16,
		color: theme.palette.customBlack.main,
		flexBasis: 200,
		marginBottom: 10
	},
	description: {
		fontWeight: 400,
		marginTop: 10,
		fontSize: 13,
		flexBasis: 600
	},
	value: {
		fontSize: 16,
		fontWeight: 400,
		color: theme.palette.gray.custom,
		flex: 1
	},
	row: {
		display: 'flex',
		gap: 10,
		flexWrap: 'wrap',
		alignItems: 'center',
		padding: '30px 0',
		borderBottom: `1px solid ${theme.palette.border.light}`,
		'&:last-of-type': {
			borderBottom: 'none'
		}
	},
	breakBox: {
		flexBasis: '100%'
	}
}));
