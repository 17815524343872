import { Box } from '@material-ui/core';
import { ReactComponent as InfoTipIcon } from 'assets/icons/ic_info_outline.svg';
import clsx from 'clsx';
import { Tooltip } from 'components/elements/molecules';

import { useStyles } from './styles';

export const PLACEMENT = {
	LEFT: 'left',
	RIGHT: 'right'
} as const;

export type IWithInfoTipTooltipProps = {
	children: CmpChildren;
	placement?: ValuesOf<typeof PLACEMENT>;
	className?: string;
	label?: TranslationKey;
	tooltipContent?: () => JSX.Element;
};
export const WithInfoTipTooltip = ({
	children,
	placement = 'right',
	className = '',
	label,
	tooltipContent = () => <></>
}: IWithInfoTipTooltipProps) => {
	const classes = useStyles({ placement });

	return (
		<Tooltip
			className={classes.tooltip}
			tooltipContent={tooltipContent}
			anchorOrigin={{
				vertical: -2,
				horizontal: placement === PLACEMENT.RIGHT ? 'right' : 'left'
			}}
			transformOrigin={{
				vertical: 'bottom',
				horizontal: placement === PLACEMENT.RIGHT ? 3 : 'right'
			}}
			label={label}
		>
			{({ getAnchorProps }) => (
				<Box
					className={clsx([
						className,
						classes.withInfoTipTooltipRoot
					])}
				>
					{children}
					<InfoTipIcon
						className={classes.icon}
						{...getAnchorProps()}
					/>
				</Box>
			)}
		</Tooltip>
	);
};
