import { ReactComponent as ClinicIcon } from 'assets/icons/clinic_icon.svg';
// import { ReactComponent as ActionPlanTemplateIcon } from 'assets/icons/ic_inhaler.svg';
import {
	NewReleasesOutlinedIcon,
	PersonIcon,
	TuneIcon
} from 'assets/icons/commonMUIIcons';
import { SETTINGS_PATHS } from 'components/routes/paths';
import React from 'react';

import { SETTINGS_PAGE, SETTINGS_ROLES } from '../roles/roles';

const {
	CLINIC,
	// MANAGE_ACTION_PLANS,
	PREFERENCES,
	MY_PROFILE,
	RELEASE_NOTES
} = SETTINGS_PATHS;

export const SETTINGS_NAV_LINKS = [
	{
		title: 'settings.nav.my_profile',
		href: MY_PROFILE,
		icon: <PersonIcon />,
		role: SETTINGS_PAGE.MY_PROFILE_ROLES
	},
	{
		title: 'settings.nav.clinic_profile',
		href: CLINIC,
		icon: <ClinicIcon />,
		role: SETTINGS_PAGE.PREFERENCES_ROLES
	},
	{
		title: 'settings.nav.preferences',
		href: PREFERENCES,
		icon: <TuneIcon />,
		role: SETTINGS_ROLES.PREFERENCES_ROLES
	}

	// {
	// 	title: 'settings.nav.manage_action_plans',
	// 	href: MANAGE_ACTION_PLANS,
	// 	icon: <ActionPlanTemplateIcon />,
	// 	role: SETTINGS_ROLES.MANAGE_ACTION_PLANS_ROLES
	// }
];

export const SETTINGS_NAV_LINKS_INFO = [
	{
		title: 'settings.nav.release_notes',
		href: RELEASE_NOTES,
		icon: <NewReleasesOutlinedIcon />,
		role: SETTINGS_ROLES.PREFERENCES_ROLES
	}
];
