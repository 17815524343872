import { makeStyles } from '@material-ui/styles';

export const TRANSITION_DURATION = 250;
export const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		marginBottom: 30,
		'& .MuiAlert-icon': {
			paddingTop: '4px'
		},
		'-webkit-print-color-adjust': 'exact'
	},

	error: {
		color: theme.palette.error.main
	},
	transitionEnter: {
		maxHeight: 0,
		marginBottom: 0,
		opacity: 0
	},
	transitionEnterActive: {
		maxHeight: '1000px',
		marginBottom: 30,
		transition: `all ${TRANSITION_DURATION}ms ease-in-out`,

		opacity: 1
	},
	transitionExit: {
		maxHeight: '1000px',
		marginBottom: 30,
		transition: `all ${TRANSITION_DURATION}ms ease-in-out`,
		opacity: 1
	},
	transitionExitActive: {
		maxHeight: 0,
		marginBottom: 0,
		opacity: 0
	}
}));
