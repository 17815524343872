// import helpers
import { CALENDAR_VIEWS_VARIANTS } from 'helpers';

const { MONTH, WEEK, DAY } = CALENDAR_VIEWS_VARIANTS;

export const viewVariants = [
	{ id: 1, name: 'month', value: MONTH },
	{ id: 2, name: 'week', value: WEEK },
	{ id: 3, name: 'day', value: DAY }
];
