import { z } from 'zod';

export const MarketingAgreementRequestPayloadSchema = z
	.object({
		processingMarketing: z.boolean()
	})
	.strict();

export const MarketingAgreementResponseSchema = z
	.object({
		status: z.literal('OK'),
		data: z
			.object({
				processingDoctor: z.boolean(),
				processingMarketing: z.boolean(),
				processingTechnical: z.boolean()
			})
			.strict()
	})
	.strict();

export type MarketingAgreementRequestPayload = z.infer<
	typeof MarketingAgreementRequestPayloadSchema
>;
export type MarketingAgreementModel = z.infer<
	typeof MarketingAgreementResponseSchema
>;
