import _ from 'lodash';
import type { InputHTMLAttributes } from 'react';
import React from 'react';
import { Field } from 'react-final-form';

import { InputErrorLabel } from '../../../atoms';

export type FileInputOnClick = (e: React.FormEvent<HTMLButtonElement>) => void;
export type FileInputComponent = ({
	onClick
}: {
	onClick: FileInputOnClick;
}) => JSX.Element;
interface Props extends InputHTMLAttributes<HTMLInputElement> {
	name: string;
	component: FileInputComponent;
}

export const FileField = ({ name, component: Component, ...props }: Props) => {
	const inputRef = React.useRef<HTMLInputElement>(null);

	const handleClick = () => {
		inputRef?.current?.click();
		return inputRef?.current?.value;
	};

	return (
		<Field<FileList> name={name}>
			{({ meta, input: { onChange, ...input } }) => (
				<>
					<input
						ref={inputRef}
						{..._.omit(input, 'value')}
						type="file"
						onChange={({ target }) =>
							onChange(target.files?.item(0))
						} // instead of the default target.value
						{...props}
						style={{ display: 'none' }}
					/>
					<Component onClick={handleClick} />
					{meta.error || meta.submitError ? (
						<InputErrorLabel
							translatedError={meta.error}
							message={meta.submitError || meta.error}
						/>
					) : (
						<></>
					)}
				</>
			)}
		</Field>
	);
};
