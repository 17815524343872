import { ForcedSpirometryReportModel } from 'models/Report/Reports';
import { useGetCachedReportData } from 'queries/Examinations/ReportsV2/getter';

import { usePatientDataConversionInExaminationContext } from '../usePatientDataConversionInExaminationContext';

export const useGetCachedForcedSpirometryReportData = () => {
	const { convertPatientDataInExaminationContext } =
		usePatientDataConversionInExaminationContext();

	const examination = useGetCachedReportData<ForcedSpirometryReportModel>();

	if (
		examination?.examination?.patientData &&
		(!examination?.examination?.patientData?.patientWeightUnit ||
			!examination?.examination?.patientData?.patientHeightUnit)
	) {
		const convertedPatientData = convertPatientDataInExaminationContext(
			examination.examination.patientData
		);
		examination.examination.patientData = {
			...examination.examination.patientData,
			...convertedPatientData
		};
	}

	if (examination.patient?.id) {
		examination.patient = {
			...examination.patient,
			patientBmi: examination.examination?.patientData?.patientBmi ?? 0,
			patientHeight:
				examination.examination?.patientData?.patientHeight?.toString() ??
				'',
			patientWeight:
				examination.examination?.patientData?.patientWeight?.toString() ??
				'',
			patientHeightUnit:
				examination.examination?.patientData?.patientHeightUnit ?? '',
			patientRace:
				examination.examination?.patientData?.patientRace ?? '',
			patientGender:
				examination.examination?.patientData?.patientGender ?? '',
			age: examination.examination?.patientData?.patientAge ?? 0
		};
	}

	return examination;
};
