import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	row: {
		'& > :first-child': {
			width: '75px',
			marginRight: 0
		}
	},
	headerCell: {
		fontSize: '8px'
	}
}));
