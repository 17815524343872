import { useMutation } from 'components/utilities';
import {
	DownloadPDFReportByLinkRequestModel,
	DownloadPDFReportByLinkResponseModel
} from 'models/Report/DownloadPDFReportByLink';
import { MyFile } from 'utilities/MyFile/MyFile';

import { ExaminationReportV2Repository } from './repository';

export const useDownloadPDFReportByLink = () => {
	return useMutation<
		DownloadPDFReportByLinkResponseModel,
		TMyError,
		DownloadPDFReportByLinkRequestModel
	>((data) => ExaminationReportV2Repository.downloadPDFReportByLink(data), {
		onSuccess: (data) => {
			MyFile.openInNewWindowTab(data.url);
		}
	});
};
