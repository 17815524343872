import {
	useActiveExaminationActions,
	useExaminationsV2Context
} from 'components/context/ExaminationsV2';
import { createDateExtremesRange } from 'components/context/ExaminationsV2/ExaminationsV2/helpers';
import { ActiveExamination } from 'models/Examinations/common';
import { ExaminationsListConvertedItemModel } from 'models/Examinations/ExaminationsList';
import { ExaminationsListServicesV2 } from 'queries';
import React from 'react';

import { useInitialListActiveIndex } from './useInitialListActiveIndex';

type Props = {
	setInitialIndex: ReturnType<
		typeof useInitialListActiveIndex
	>['setInitialIndex'];
};
export const useListActions = ({ setInitialIndex }: Props) => {
	const { setActiveExaminationWithListItemPositioning } =
		useActiveExaminationActions();

	const { store: examinationsListData } = useExaminationsV2Context(
		(s) => s.examinationsListData
	);

	const {
		setExaminationsDateExtremes,
		setDateRange,
		setExaminationsListDataWithFilters
	} = useExaminationsV2Context();

	/**
	 * Updates the active examination.
	 * @param examinationsListData - An array of examination data items in the converted model format.
	 * @param activeExamination - The examination data to set as the active examination.
	 * @param forceInitialIndex - A flag indicating whether to force setting the initial index or not. Default is `true`.
	 */
	const updateActiveExamination = React.useCallback(
		(
			examinationsListData: ExaminationsListConvertedItemModel[],
			activeExamination: ActiveExamination,
			forceInitialIndex = true
		) => {
			const activeExam = setActiveExaminationWithListItemPositioning({
				examinationsListData,
				activeExamination,
				forceUpdateSearchParams: true
			});
			forceInitialIndex && setInitialIndex(activeExam.index || 0);
		},
		[]
	);

	/**
	 * Sets the initial data for the Examinations List.
	 * @param newExaminationsListData - An array of converted item models for the Examinations List.
	 * @param isDateExtremesSetter - A boolean flag to determine whether to set the date range extremes or not. Defaults to true.
	 * @returns An array of converted item models for the initial filtered Examinations List.
	 */
	const setInitialExaminationsListData = (
		newExaminationsListData: ExaminationsListConvertedItemModel[],
		isDateExtremesSetter = true
	): ExaminationsListConvertedItemModel[] => {
		if (examinationsListData.length === 0) {
			const initialAvailableExaminationTypes =
				ExaminationsListServicesV2.findAvailableExaminationTypesInExaminationsList(
					newExaminationsListData
				);
			const filteredData = setExaminationsListDataWithFilters({
				examinationsListData: newExaminationsListData,
				selectedExaminationTypes: initialAvailableExaminationTypes
			});
			if (isDateExtremesSetter) {
				const dateRangeExtremes = createDateExtremesRange(
					newExaminationsListData
				);
				setExaminationsDateExtremes(dateRangeExtremes);
				setDateRange(dateRangeExtremes);
			}
			return filteredData;
		}
		return examinationsListData;
	};
	return { updateActiveExamination, setInitialExaminationsListData };
};
