import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		gap: 10,
		alignItems: 'center',
		padding: '10px 0'
	},
	spacer: {
		flex: 1
	}
}));
