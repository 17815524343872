import { makeStyles } from '@material-ui/styles';

export const useGlobalStyles = makeStyles((theme) => ({
	box: {
		borderRadius: 10,
		padding: '0 5px',
		border: `1px solid ${theme.palette.border.light}`,
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		minHeight: 34
	},
	result: {
		textAlign: 'center',
		fontSize: '13px',
		lineHeight: '14.5px',
		paddingTop: 2
	}
}));
