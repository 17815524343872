import { EN } from 'helpers';
import { LanguagesRepository } from 'queries';
import * as types from 'store/types';
import { Cookies } from 'utilities';

export const setLanguage =
	({ lng }) =>
	async (dispatch) => {
		dispatch({
			type: types.SET_LANGUAGE,
			payload: lng
		});
	};

export const fetchLanguages =
	({ lng, isAuthForm = false }) =>
	async (dispatch) => {
		const LOADING_ACTION = isAuthForm
			? types.FETCH_AUTH_LANGUAGES_LOADING
			: types.FETCH_LANGUAGES_LOADING;
		const SUCCESS_ACTION = isAuthForm
			? types.FETCH_AUTH_LANGUAGES_SUCCESS
			: types.FETCH_LANGUAGES_SUCCESS;
		const ERROR_ACTION = isAuthForm
			? types.FETCH_AUTH_LANGUAGES_ERROR
			: types.FETCH_LANGUAGES_ERROR;

		try {
			dispatch({
				type: LOADING_ACTION,
				payload: true
			});
			const lngCookies = Cookies.language.get();

			const language = lng || lngCookies || EN;

			Cookies.language.set(language);

			// Get  response
			const {
				data: { data: translations }
			} = await LanguagesRepository.getTranslations(language);

			setTimeout(() => {
				dispatch({
					type: SUCCESS_ACTION,
					payload: { translations, lng: language }
				});
			}, 1200);
		} catch (error) {
			dispatch({
				type: ERROR_ACTION,
				payload: true
			});
		}
	};

export const fetchAvailableLocales = () => async (dispatch) => {
	try {
		dispatch({
			type: types.FETCH_AVAILABLE_LOCALES_LOADING,
			payload: true
		});

		const { data } = await LanguagesRepository.getLocales();

		dispatch({
			type: types.FETCH_AVAILABLE_LOCALES_SUCCESS,
			payload: data
		});
	} catch (error) {
		dispatch({
			type: types.FETCH_AVAILABLE_LOCALES_ERROR,
			payload: true
		});
	}
};
