import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	container: {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		padding: '10px 0'
	},
	typography: {
		fontWeight: 500,
		textTransform: 'none',
		flex: 1,
		paddingLeft: '40px'
	},
	button: {
		marginRight: 15
	},
	icon: {
		width: '30px',
		height: '30px'
	}
}));
