import { useLocation } from 'react-router-dom';

const useDrugs = () => {
	const { pathname } = useLocation();

	const isFormActive = /(add|edit)/i.test(pathname);

	return {
		isFormActive
	};
};

export default useDrugs;
