import { Obturation } from 'components/elements';

/**
 * this method is called when examinationVersion = 1
 * method to create array of bronchodilator result objects (obturation & result test) inside bronchodilator tile
 * @param {array} values - array of props to render
 * @param {Object} obturation - determined obturation values based on api data and business rules
 * @param {bool} obturation.disableCOPDTest - determines if result test tile is disable or not
 * @param {Object} dbData - bronchodilator tile db data
 * @param {number} dbData.positiveResponseFlag  result test value - from API
 * @param {number} dbData.testStatusAfterPost test status to determine obturation tile message - from API
 * @returns array of objects to render
 */
export const setObturationValues = (
	values,
	{ disableCOPDTest, ...obturationResultValues },
	{ positiveResponseFlag, testStatusAfterPost }
) => {
	const mutateValues = [...values];

	const isLabelDisabled =
		testStatusAfterPost >= 3 && testStatusAfterPost <= 5;

	mutateValues.push({
		component: Obturation.ResultWithTooltip,
		disableBorder: true,
		isLabel: !isLabelDisabled,
		fontWeight: 400,
		...obturationResultValues
	});

	if (!disableCOPDTest) {
		const validPositiveResponseFlag = positiveResponseFlag === 1 ? 1 : 0;
		mutateValues.push({
			valueLabel: `patients.examinations.details.bronchodilator.positive_flag_${validPositiveResponseFlag}`,
			label: 'patients.examinations.details.bronchodilator.result_of_the_test'
		});
	}

	return mutateValues;
};

/**
 * this method is called by default if examinationVersion = 0
 * method to create array of bronchodilator default result object (result test) inside bronchodilator tile
 * @param {array} values - array of props to render
 * @param {Object} data - data to create object inside values array
 * @param {number} data.reversibleObturationStatus - status of bronchodilator examination
 * @param {number} data.obturationSeverity - severity level
 * @returns array of objects to be rendered
 */
export const setDefaultValues = (
	values,
	{ reversibleObturationStatus, obturationSeverity }
) => {
	const mutateValues = [...values];

	const validReversibleObturationStatus =
		reversibleObturationStatus || reversibleObturationStatus === 1 ? 1 : 0;

	mutateValues.push({
		label: 'patients.examinations.details.bronchodilator.result',
		valueLabel: `patients.examinations.details.bronchodilator.obturation_result_${validReversibleObturationStatus}`
	});

	mutateValues.push({
		label: `patients.examinations.details.bronchodilator.level_of_obstruction`,
		valueLabel:
			obturationSeverity >= 1 && obturationSeverity <= 5
				? `patients.examinations.details.bronchodilator.${obturationSeverity}`
				: 'patients.examinations.details.bronchodilator.unknown',
		isDefaultTooltip: true
	});

	return mutateValues;
};
