import {
	mapParameterNameToSupportedUserUnitsSettings,
	validateValueUnitDecimals
} from 'helpers';
import { EMPTY_VALUE } from 'helpers/variables';
import { ExaminationsParametersType } from 'types/Examinations/parameters';

import { IColumn } from './ParametersTable';

export const formatValue = (
	v: string | number | null | undefined,
	isInteger = false
) => {
	if (v === null || v === undefined || v === '' || v === -1) {
		return EMPTY_VALUE;
	}

	if (typeof v === 'string' || typeof v === 'boolean') {
		return v;
	}

	const valueFloatingPoints = isInteger ? 0 : 2;

	const fixedValue = v.toFixed(valueFloatingPoints);

	return fixedValue;
};

export const determineIfCellIsInteger = ({
	isInteger,
	parameterType
}: {
	isInteger: IColumn['isInteger'];
	parameterType: ExaminationsParametersType;
}): boolean => {
	if (!isInteger) {
		return false;
	}

	if (typeof isInteger === 'boolean') {
		return isInteger;
	}

	if (isInteger.rows) {
		const validatedRowsManagement = validateValueUnitDecimals(
			isInteger.rows
		);
		const type = mapParameterNameToSupportedUserUnitsSettings(
			parameterType?.toLowerCase() as ExaminationsParametersType
		);
		return validatedRowsManagement.includes(type);
	}

	return false;
};
