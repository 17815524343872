import { API_URL_PREFIX } from '../helpers';
import {
	DRUG_ID_QUERY_PARAM,
	PATIENT_ID_QUERY_PARAM_KEY
} from '../queryParamKeys';

export const DELETE_DRUG_API_URL = `${API_URL_PREFIX}/patients/drugs/${DRUG_ID_QUERY_PARAM}`;
export const DRUGS_TABLE_RESOURCES_API_URL = `${API_URL_PREFIX}/patients/:patientId/drugs/list`;
export const ARCHIVED_DRUGS_TABLE_RESOURCES_API_URL = `${API_URL_PREFIX}/patients/:patientId/archived-drugs/list`;

export const ARCHIVE_DRUG_API_URL = `${API_URL_PREFIX}/patients/drugs/${DRUG_ID_QUERY_PARAM}/archive`;
export const UNARCHIVE_DRUG_API_URL = `${API_URL_PREFIX}/patients/drugs/${DRUG_ID_QUERY_PARAM}/unarchive`;

export const DRUG_RESOURCES_API_URL = `${API_URL_PREFIX}/patients/drugs/${DRUG_ID_QUERY_PARAM}`;
export const DRUG_RESOURCES_QUERY_KEY = 'DRUG_RESOURCES_QUERY_KEY';

export const DRUGS_SEARCH_BY_NAME_API = `${API_URL_PREFIX}/drugs/search-by-name`;

export const PRESCRIBE_DRUG_TO_PATIENT_API_URL = `${API_URL_PREFIX}/patients/${PATIENT_ID_QUERY_PARAM_KEY}/drugs`;

export const EDIT_PRESCRIBED_DRUG_API_URL = `${API_URL_PREFIX}/patients/drugs/${DRUG_ID_QUERY_PARAM}`;

// QUERIES
export const DRUGS_TABLE_QUERY_KEY = 'DRUGS_TABLE_QUERY_KEY';
export const ARCHIVED_DRUGS_TABLE_QUERY_KEY = 'ARCHIVED_DRUGS_TABLE_QUERY_KEY';
