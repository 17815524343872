import { useDrugsContext } from 'components/context';
import { ControlledTable } from 'components/elements';
import { DrugsRepository } from 'queries';
import React from 'react';

import TableBodyRow from '../TableBodyRow';
import { Toolbar } from './Toolbar';

const PrescribedDrugs = () => {
	const { isRefetchPrescribedDrugs, togglePrescribedDrugs } =
		useDrugsContext();

	const getSettingsCellProps = React.useCallback(
		() => ({
			label: 'patients.drugs.table.archive',
			action: DrugsRepository.archive
		}),
		[]
	);

	return (
		<>
			<Toolbar />
			<ControlledTable
				isDefaultRow={false}
				isToolbarHidden={true}
				manualRefetch={isRefetchPrescribedDrugs}
				setManualRefetch={togglePrescribedDrugs}
			>
				{({ row }) => (
					<TableBodyRow
						key={row.id}
						row={row}
						getSettingsCellProps={getSettingsCellProps}
					/>
				)}
			</ControlledTable>
		</>
	);
};

export default PrescribedDrugs;
