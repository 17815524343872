import { HelmetTags } from 'components/elements';
import { LoginTemplate } from 'components/templates';
import React from 'react';

import ResetPasswordRequestForm from './form/ResetPasswordRequestForm';

const ResetPassword = () => (
	<LoginTemplate>
		<HelmetTags title="pages.cannot_remember_password.title" />
		<ResetPasswordRequestForm />
	</LoginTemplate>
);

export default ResetPassword;
