import { TextField } from '@material-ui/core';
import { useTranslations } from 'components/utilities';
import { func, object, string } from 'prop-types';
import React from 'react';

const TextFieldAdapter = ({
	input,
	meta,
	helperText = '',
	inputOnChange,
	regex,
	...rest
}) => {
	const { t } = useTranslations();
	const error =
		meta.touched && (meta.error || meta.submitError) ? true : false;

	const errorText =
		meta.touched && (meta.error || meta.submitError)
			? t(meta.error) || meta.submitError
			: '';

	const onChange = (e) => {
		input.onChange(e);
		inputOnChange?.(e);
	};

	const handleOnChange = (e) => {
		e?.persist?.();
		const { value } = e.target;

		if (!regex || value === '') {
			onChange(e);
			return;
		}
		regex.test(e.target.value) && onChange(e);
	};

	return (
		<TextField
			{...input}
			{...rest}
			error={error}
			helperText={errorText || helperText}
			onChange={handleOnChange}
		/>
	);
};

TextFieldAdapter.propTypes = {
	input: object.isRequired,
	meta: object.isRequired,
	inputOnChange: func,
	helperText: string,
	regex: object
};

export default TextFieldAdapter;
