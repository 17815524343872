import { AppTemplate } from 'components/templates';
import React from 'react';

const WithAnalyticServices = (Component) => {
	return () => (
		<AppTemplate>
			<Component />
		</AppTemplate>
	);
};

export default WithAnalyticServices;
