import { InformationSectionField } from 'components/elements/molecules/ExaminationsV2';
import { IColumn } from 'components/elements/organisms/ExaminationsV2/ParametersTable/ParametersTable';
import { TIME_FORMAT } from 'helpers/variables';
import { DeviceModel } from 'models/Device/Device';
import { LocaleDate } from 'utilities';

import { ColumnValues } from './types';

export const CREATE_DATE_TIME = {
	label: ' ',
	getUserUnit: () => '',
	convertValue: (v) =>
		v
			? new LocaleDate(v).getLocaleTime(
					TIME_FORMAT.DEFAULT_WITH_SECONDS
					//eslint-disable-next-line
			  )
			: null
} as const;

export const measurementsColumns: {
	Header: string;
	accessor: keyof ColumnValues;
	isInteger?: IColumn<keyof ColumnValues>['isInteger'];
}[] = [
	{ Header: '', accessor: 'parameterTypeLabel' },
	{
		Header: 'report.comparison.best',
		accessor: 'value',
		isInteger: { rows: ['pef', 'pif'] }
	},
	{
		Header: 'report.comparison.pred',
		accessor: 'predicted',
		isInteger: { rows: ['pef', 'pif'] }
	},
	{
		Header: 'report.comparison.meas_1',
		accessor: 'meas_1',
		isInteger: { rows: ['pef', 'pif'] }
	},
	{
		Header: 'report.comparison.meas_2',
		accessor: 'meas_2',
		isInteger: { rows: ['pef', 'pif'] }
	},
	{
		Header: 'report.comparison.meas_3',
		accessor: 'meas_3',
		isInteger: { rows: ['pef', 'pif'] }
	},
	{
		Header: 'report.comparison.meas_4',
		accessor: 'meas_4',
		isInteger: { rows: ['pef', 'pif'] }
	},
	{
		Header: 'report.comparison.meas_5',
		accessor: 'meas_5',
		isInteger: { rows: ['pef', 'pif'] }
	},
	{
		Header: 'report.comparison.meas_6',
		accessor: 'meas_6',
		isInteger: { rows: ['pef', 'pif'] }
	},
	{
		Header: 'report.comparison.meas_7',
		accessor: 'meas_7',
		isInteger: { rows: ['pef', 'pif'] }
	},
	{
		Header: 'report.comparison.meas_8',
		accessor: 'meas_8',
		isInteger: { rows: ['pef', 'pif'] }
	}
];

export const DEVICE_FIELDS: InformationSectionField<DeviceModel>[] = [
	{
		fieldName: 'deviceSerialNumber',
		label: 'report.device.device_serial_number',
		valueConverter: (v) => v
	},
	{
		fieldName: 'tubeSerialNumber',
		label: 'report.device.tube_serial_number',
		valueConverter: (v) => (v ? v : undefined)
	},
	{
		fieldName: 'appVersion',
		label: 'report.device.app',
		valueConverter: (v) => v ?? undefined
	}
];
