import { ReactComponent as AppStoreIcon } from 'assets/icons/appstore.svg';
import { ReactComponent as GooglePlayIcon } from 'assets/icons/googleplay.svg';
import React from 'react';

import { RouterNavLink } from '../../Links';

export const APP_STORE_LINK_TYPES = {
	APP_STORE: 'appStore',
	GOOGLE_PLAY: 'googlePlay'
};

export interface IAppStoreLinkButtonProps {
	type: ValuesOf<typeof APP_STORE_LINK_TYPES>;
}

export const AppStoreLinkButton = ({ type }: IAppStoreLinkButtonProps) => {
	const renderIcon = () => {
		switch (type) {
			case APP_STORE_LINK_TYPES.APP_STORE:
				return <AppStoreIcon />;
			case APP_STORE_LINK_TYPES.GOOGLE_PLAY:
				return <GooglePlayIcon />;
			default:
				return <AppStoreIcon />;
		}
	};

	const getUrl = React.useCallback(() => {
		switch (type) {
			case APP_STORE_LINK_TYPES.APP_STORE:
				return 'https://apps.apple.com/pl/app/aiocare-patient/id1256464210';
			case APP_STORE_LINK_TYPES.GOOGLE_PLAY:
				return 'https://play.google.com/store/apps/details?id=com.healthup';
		}
	}, [type]);

	return (
		//eslint-disable-next-line
		//@ts-ignore
		<RouterNavLink target="_blank" rel="noopener noreferrer" to={getUrl()}>
			{renderIcon()}
		</RouterNavLink>
	);
};
