import { Divider as MuiDivider, DividerTypeMap } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import { useStyles } from './styles';

type Props = DividerTypeMap['props'] & {
	className?: string;
};
const Divider = ({ className = '', ...rest }: Props) => {
	const classes = useStyles();
	return (
		<MuiDivider className={clsx(className, classes.divider)} {...rest} />
	);
};

export default Divider;
