import {
	ACTIVE_EXAMINATION_SESSION_KEY,
	EXAMINATION_CHART_DATE_RANGE_SESSION_KEY,
	SELECTED_TREND_CHART_PARAMETERS_SESSION_KEY
} from 'helpers';

export type ActiveExaminationKeyData = {
	patientId: PatientIdParam;
};
export function createActiveExaminationKey({
	patientId
}: ActiveExaminationKeyData) {
	return [ACTIVE_EXAMINATION_SESSION_KEY, patientId].toString();
}

export type ExaminationsDateRangeKeyData = {
	patientId: PatientIdParam;
};
export const createExaminationsDateRangeKey = ({
	patientId
}: ExaminationsDateRangeKeyData) =>
	[EXAMINATION_CHART_DATE_RANGE_SESSION_KEY, patientId].toString();

export type SelectedTrendChartParametersKeyData = {
	patientId: PatientIdParam;
};
export const createSelectedTrendChartParametersKey = ({
	patientId
}: SelectedTrendChartParametersKeyData) =>
	[SELECTED_TREND_CHART_PARAMETERS_SESSION_KEY, patientId].toString();
