import { useTranslations } from 'components/utilities';

import {
	OBSTRUCTION_PERCENTILE_FEV1_FVC,
	OBTURATION_LEVELS_LIST
} from './helpers';

const useObstructionLevelLegend = () => {
	const { tNoParser, t } = useTranslations();

	const noObstruction = `${tNoParser(
		'report.obstruction.percentile_fev1_fvc'
	)?.replace(':', '')} >= ${OBSTRUCTION_PERCENTILE_FEV1_FVC} - ${tNoParser(
		'report.obstruction.no_obstruction'
	)}`;

	const detectedObstructionLegend = ` ${tNoParser(
		'report.obstruction.percentile_fev1_fvc'
	)?.replace(':', '')} < ${OBSTRUCTION_PERCENTILE_FEV1_FVC} - ${tNoParser(
		'report.obstruction.title'
	)
		?.replace(':', '')
		?.toLowerCase()} ${tNoParser('report.obstruction.detected')}`;

	const obstructionLevelLegend = `
      ${tNoParser('report.obstruction.percentage_predicted')?.replace(
			':',
			''
		)} ${OBTURATION_LEVELS_LIST.map((el, index) => {
		let levelItemLabel = `${el.percentage}&nbsp;${t(el.label)};`;
		if (index !== 0 && index % 2 === 1) {
			levelItemLabel += '<br />';
		}
		return levelItemLabel;
	}).join(' ')}`;

	return { detectedObstructionLegend, noObstruction, obstructionLevelLegend };
};

export default useObstructionLevelLegend;
