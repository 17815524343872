import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	content: {
		paddingBottom: 50,
		paddingTop: 32,
		minHeight: 100,
		display: 'flex',
		flexDirection: 'column',
		gap: 20
	}
}));
