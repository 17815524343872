import { ETHNICITY_LABELS } from 'helpers';

import { ADDITIONAL_FIELD_NAMES } from './fields';

export const getOptions = ({ name, resources }) => {
	const [height, race, weight] = resources;
	switch (name) {
		case ADDITIONAL_FIELD_NAMES.RACE:
			return race;
		case ADDITIONAL_FIELD_NAMES.HEIGHT_UNIT:
			return height;
		case ADDITIONAL_FIELD_NAMES.WEIGHT_UNIT:
			return weight;
		default:
			return race;
	}
};

export const getValue = ({ item, name, t }) => {
	if (name === ADDITIONAL_FIELD_NAMES.RACE) {
		return item ? t(ETHNICITY_LABELS[item]) : t(ETHNICITY_LABELS.UNKNOWN);
	}
	return item;
};
