import { Box, Typography as MuiTypography } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useTranslations } from 'components/utilities';
import { DOSAGE_FIELDS_BLOCKERS } from 'helpers';
import { func, object, string } from 'prop-types';
import React from 'react';

import { PickersUtilsProvider } from '../../../../molecules';
import { useStyles } from './styles';

const DatePicker = ({ dosage, setUntil, classNameRoot = '' }) => {
	const classes = useStyles();
	const { t } = useTranslations();

	const { until, dosage_schedule } = dosage;

	const handleChangeDate = (date) => {
		setUntil(date);
	};

	React.useEffect(() => {
		if (!DOSAGE_FIELDS_BLOCKERS.includes(dosage_schedule) && !until) {
			setUntil(new Date());
		}
		//eslint-disable-next-line
	}, []);

	return (
		<Box className={classNameRoot}>
			<MuiTypography component="p" variant="body2">
				{t('patients.action_plan.create.medications.take_until')}
			</MuiTypography>
			<PickersUtilsProvider>
				{({ translationsProps }) => (
					<KeyboardDatePicker
						className={classes.root}
						animateYearScrolling
						autoOk={true}
						inputVariant="outlined"
						format="dd/MM/yyyy"
						margin="dense"
						value={until}
						onChange={handleChangeDate}
						KeyboardButtonProps={{
							'aria-label': 'change date'
						}}
						{...translationsProps}
					/>
				)}
			</PickersUtilsProvider>
		</Box>
	);
};

DatePicker.propTypes = {
	dosage: object.isRequired,
	setUntil: func.isRequired,
	classNameRoot: string
};

export default DatePicker;
