import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import { ReactHMLParserType } from 'components/utilities/hooks/translations/types';
import React from 'react';

import { useStyles } from './styles';

type Props = {
	label: string | null | ReactHMLParserType;
	className?: string;
	onClick?: () => void;
};

export const Label = ({ label, className = '', onClick = () => {} }: Props) => {
	const classes = useStyles();
	return (
		<Typography
			onClick={onClick}
			className={clsx([className, classes.label])}
		>
			{label}
		</Typography>
	);
};
