import { PatientFormProvider } from 'components/context';
import { PatientsRoutes } from 'components/routes';
import React from 'react';

const PatientsDetails = () => (
	<PatientFormProvider>
		<PatientsRoutes />
	</PatientFormProvider>
);

export default PatientsDetails;
