import {
	CHART_MEASUREMENT_ADDITIONAL_PARAMETERS,
	CHART_MEASUREMENT_PARAMETER_OPTION_NAMES as PARAMETERS,
	getUserUnit
} from 'helpers/charts';

import { CREATE_DATE_TIME } from './constants';

export const FVC_BEST_PARAMETERS = {
	fvc: {
		label: PARAMETERS.fvc.label,
		getUserUnit: () => getUserUnit('fvc')
	},
	fev1: {
		label: PARAMETERS.fev1.label,
		getUserUnit: () => getUserUnit('fev1')
	},
	fev1Fvc: {
		label: PARAMETERS.tiffeneau.label,
		getUserUnit: () => getUserUnit('tiffeneau')
	},
	pef: {
		label: PARAMETERS.pef.label,
		getUserUnit: () => getUserUnit('pef')
	},
	fef25: {
		label: PARAMETERS.fef25.label,
		getUserUnit: () => getUserUnit('fef25')
	},
	fef50: {
		label: PARAMETERS.fef50.label,
		getUserUnit: () => getUserUnit('fef50')
	},
	fef75: {
		label: PARAMETERS.fef75.label,
		getUserUnit: () => getUserUnit('fef75')
	},
	fef25_75: {
		label: PARAMETERS.fef25_75.label,
		getUserUnit: () => getUserUnit('fef25_75')
	}
} as const;

export const ADDITIONAL_FVC_MEASUREMENTS_PARAMETERS = {
	bev: {
		label: PARAMETERS.bev.label,
		getUserUnit: () => getUserUnit('bev')
	},
	fet: {
		label: PARAMETERS.fet.label,
		getUserUnit: () => getUserUnit('fet')
	},
	tpef: {
		label: PARAMETERS.tpef.label,
		getUserUnit: () => getUserUnit('tpef')
	}
};

export const FVC_BEST_PARAMETERS_WITH_CREATE_DATE = {
	...FVC_BEST_PARAMETERS,
	createDateTime: CREATE_DATE_TIME
} as const;

export const FEV1_VC_MAX_PARAMETER = {
	label: 'FEV1/VC(max)',
	getUserUnit: () => getUserUnit('fev1_vc_max')
} as const;

export const SVC_PARAMETERS = {
	vc: {
		label: PARAMETERS.vc.label,
		getUserUnit: () => getUserUnit('vc')
	},
	ic: {
		label: PARAMETERS.ic.secondaryLabel,
		getUserUnit: () => getUserUnit('ic')
	},
	fev1Svc: FEV1_VC_MAX_PARAMETER
};

export const SVC_PARAMETERS_WITH_CREATE_DATE = {
	...SVC_PARAMETERS,
	createDateTime: CREATE_DATE_TIME
} as const;

export const FVC_ALL_PARAMETERS = {
	fvc: {
		label: PARAMETERS.fvc.label,
		getUserUnit: () => getUserUnit('fvc')
	},
	fev1: {
		label: PARAMETERS.fev1.label,
		getUserUnit: () => getUserUnit('fev1')
	},
	fev1Fvc: {
		label: PARAMETERS.tiffeneau.label,
		getUserUnit: () => getUserUnit('tiffeneau')
	},
	pef: {
		label: PARAMETERS.pef.label,
		getUserUnit: () => getUserUnit('pef')
	},
	fef25: {
		label: PARAMETERS.fef25.label,
		getUserUnit: () => getUserUnit('fef25')
	},
	fef50: {
		label: PARAMETERS.fef50.label,
		getUserUnit: () => getUserUnit('fef50')
	},
	fef75: {
		label: PARAMETERS.fef75.label,
		getUserUnit: () => getUserUnit('fef75')
	},
	fef25_75: {
		label: PARAMETERS.fef25_75.label,
		getUserUnit: () => getUserUnit('fef25_75')
	},
	fev6: {
		label: PARAMETERS.fev6.label,
		getUserUnit: () => getUserUnit('fev6')
	},
	vptefVe: {
		label: PARAMETERS.vtpefVe.label,
		getUserUnit: () => getUserUnit('vtpefVe')
	},
	fivc: {
		label: PARAMETERS.fivc.label,
		getUserUnit: () => getUserUnit('fivc')
	},
	pif: {
		label: PARAMETERS.pif.label,
		getUserUnit: () => getUserUnit('pif')
	},
	mif25: {
		label: PARAMETERS.mif25.label,
		getUserUnit: () => getUserUnit('mif25')
	},
	mif50: {
		label: PARAMETERS.mif50.label,
		getUserUnit: () => getUserUnit('mif50')
	},
	mif75: {
		label: PARAMETERS.mif75.label,
		getUserUnit: () => getUserUnit('mif75')
	},
	tptefTe: {
		label: PARAMETERS.tptefTe.label,
		getUserUnit: () => getUserUnit('tptefTe')
	},
	...ADDITIONAL_FVC_MEASUREMENTS_PARAMETERS,
	rt: {
		label: PARAMETERS.rt.label,
		getUserUnit: () => getUserUnit('rt')
	}
} as const;

export const SVC_ALL_PARAMETERS = {
	vc: {
		label: PARAMETERS.vc.label,
		getUserUnit: () => getUserUnit('vc')
	},
	ic: {
		label: PARAMETERS.ic.label,
		getUserUnit: () => getUserUnit('ic')
	},
	erv: {
		label: PARAMETERS.erv.label,
		getUserUnit: () => getUserUnit('erv')
	},
	vt: {
		label: PARAMETERS.vt.label,
		getUserUnit: () => getUserUnit('vt')
	},
	irv: {
		label: PARAMETERS.irv.label,
		getUserUnit: () => getUserUnit('irv')
	}
} as const;

export const PULSE_OXIMETRY_ALL_PARAMETERS = {
	spO2: {
		label: PARAMETERS.spO2.label,
		getUserUnit: () => getUserUnit('spO2')
	},
	hrAvg: {
		label: PARAMETERS.hrAvg.label,
		getUserUnit: () => getUserUnit('hrAvg')
	}
};

export const PEAK_FLOW_ALL_PARAMETERS = {
	pef1: {
		label: CHART_MEASUREMENT_ADDITIONAL_PARAMETERS.pef1.label,
		getUserUnit: () => getUserUnit('pef')
	},
	pef2: {
		label: CHART_MEASUREMENT_ADDITIONAL_PARAMETERS.pef2.label,
		getUserUnit: () => getUserUnit('pef')
	},
	pef3: {
		label: CHART_MEASUREMENT_ADDITIONAL_PARAMETERS.pef3.label,
		getUserUnit: () => getUserUnit('pef')
	},
	result: {
		label: 'Best result',
		getUserUnit: () => getUserUnit('pef')
	}
};
