import { ExaminationTypeSchema } from 'models/Examinations/common';
import { z } from 'zod';

export const ShareReportByRecipientEmailRequestSchema = z.object({
	patientId: z.number(),
	examinationType: ExaminationTypeSchema,
	examinationId: z.union([z.number(), z.string().uuid()]),
	recipientEmail: z.string().email('error_codes.invalid_email')
});

export type ShareReportByRecipientEmailRequestModel = z.infer<
	typeof ShareReportByRecipientEmailRequestSchema
>;

export const ShareReportByRecipientEmailResponseSchema = z.object({});
export type ShareReportByRecipientEmailResponseModel = z.infer<
	typeof ShareReportByRecipientEmailResponseSchema
>;
