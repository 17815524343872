import clsx from 'clsx';
import { ValueLabel as ValueLabelCmp } from 'components/elements/atoms/ExaminationsV2';
import { ValueLabelProps } from 'components/elements/atoms/ExaminationsV2/Typography/ValueLabel';
import React from 'react';

import { useStyles } from './styles';

export const ValueLabel = (props: ValueLabelProps) => {
	const classes = useStyles();
	return (
		<ValueLabelCmp
			{...props}
			className={clsx([props.className, classes.label])}
		/>
	);
};
