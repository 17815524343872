import { ReportParameterModel } from 'models/Report/common';
import { ForcedSpirometryBestParametersModel } from 'models/Report/ForcedSpirometry';
import { BronchodilatorReportModel } from 'models/Report/Reports';
import {
	ColumnDataImpl,
	ColumnValues
} from 'utilities/Examinations/ExaminationDetails/common';
import { FVC_BEST_PARAMETERS } from 'utilities/Examinations/ExaminationDetails/helpers';

export const convertBestResultsToColumnsData = (
	data?: ForcedSpirometryBestParametersModel,
	changePercentage?: BronchodilatorReportModel['changePercentage'],
	isChangePercentage = false
): ColumnValues<ReportParameterModel>[] => {
	if (!data) {
		return [];
	}
	let bestResultsData = data;

	if (isChangePercentage) {
		bestResultsData = Object.entries(data).reduce((acc, next) => {
			acc[next[0]] = {
				...next[1],
				changePercentage: changePercentage?.[next[0]]
			};
			return acc;
		}, {} as ForcedSpirometryBestParametersModel);
	}

	const fvcColumnData = new ColumnDataImpl<
		ForcedSpirometryBestParametersModel,
		ReportParameterModel
	>(FVC_BEST_PARAMETERS, bestResultsData);
	fvcColumnData.createColumnData();

	return fvcColumnData.columnData;
};
