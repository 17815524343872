import { Actions } from './Actions';
import { Chip } from './Chip';
import { City } from './City';
import { Date } from './Date';
import { NavLink } from './NavLink';

export const TableCellContent = {
	NavLink,
	City,
	Chip,
	Date,
	Actions
};
