import { MutationOptions, useMutation } from 'components/utilities';
import { DeleteClinicImageModel } from 'models/Clinic/Clinic';

import { ClinicRepository } from './repository';

export const useDeleteClinicImage = (
	options: MutationOptions<DeleteClinicImageModel, TMyError, void> = {}
) => {
	const mutation = useMutation<DeleteClinicImageModel, TMyError, void>(
		ClinicRepository.deleteClinicImage,
		options
	);
	return mutation;
};
