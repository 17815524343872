import { useExaminationsV2Context } from 'components/context/ExaminationsV2';
import { EXAMINATIONS_TYPES, IDLE } from 'helpers';
import { useDownloadExaminationReport } from 'queries';
import { useGeneratePDFReportV2 } from 'queries/Examinations/ReportsV2';
import React from 'react';
import theme from 'theme';
import { useParams } from 'utilities';

import { REPORT_FORMATS } from './helpers';

const REPORT_V2_SUPPORT = [
	EXAMINATIONS_TYPES.SPIROMETRY.type,
	EXAMINATIONS_TYPES.SVC.type,
	EXAMINATIONS_TYPES.COPD_FINAL.type,
	EXAMINATIONS_TYPES.COPD_TEST.type,
	EXAMINATIONS_TYPES.PULSE_OXIMETRY.type,
	EXAMINATIONS_TYPES.PEAK_FLOW.type
];

export const useExportReport = () => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [selectedFormats, setSelectedFormats] = React.useState([
		REPORT_FORMATS[0].label.toLowerCase()
	]);
	const [noDataMessage, setNoDataMessage] = React.useState('');
	const { store: activeExamination } = useExaminationsV2Context(
		(s) => s.activeExamination
	);

	const {
		mutateAsync: downloadReportV2,
		isLoading: downloadReportV2Loading,
		isError: downloadReportV2Error,
		reset: downloadReportV2Reset
	} = useGeneratePDFReportV2();

	const { patientId } = useParams();

	const handleOpen = (event) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => setAnchorEl(null);

	const handleSelectFormats = (format) => {
		if (selectedFormats.includes(format)) {
			const newSelectedFormats = selectedFormats.filter(
				(item) => item !== format
			);
			setSelectedFormats(newSelectedFormats);
		} else {
			setSelectedFormats((prev) => [...prev, format]);
		}
	};

	const getFormatColor = (format) =>
		selectedFormats.includes(format)
			? theme.palette.primary.main
			: theme.palette.black;

	const { mutateAsync, status: idle } = useDownloadExaminationReport();

	const isLoading = idle === IDLE.LOADING || downloadReportV2Loading;

	const handleDownloadReport = () => {
		downloadReportV2Reset();
		if (selectedFormats.length === 0)
			setNoDataMessage(
				'patients.examinations.sidebar.no_formats_selected'
			);
		else {
			const examinationName = Object.values(EXAMINATIONS_TYPES).find(
				({ type }) => type === activeExamination.type
			);
			const body = {
				examinations: [
					{
						type: examinationName.examination,
						examination_id: activeExamination.id
					}
				],
				report_format: [...selectedFormats]
			};

			setNoDataMessage('');
			REPORT_V2_SUPPORT.includes(activeExamination.type) &&
			selectedFormats.includes('pdf')
				? /* eslint-disable */
				  downloadReportV2({
						patientId,
						examinationId: activeExamination.id,
						examinationType: activeExamination.type
				  })
				: mutateAsync(body);
			/* eslint-enable */
		}
	};

	return {
		anchorEl,
		openMenu: handleOpen,
		closeMenu: handleClose,
		selectFormat: handleSelectFormats,
		getFormatColor,
		downloadReport: handleDownloadReport,
		isDownloading: isLoading,
		isError: downloadReportV2Error,
		noDataMessage
	};
};
