import { useMutation } from 'components/utilities';
import {
	QUERY_COUNTRIES,
	QUERY_PATIENT_DATA_AND_PERSONAL_BESTS
} from 'helpers';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { PatientRepository } from './repository';

/**
 * edit patient account
 * @param {object} options mutation options
 * @param {object} additionalData additional mutation data
 * @param {object} additionalData.fieldsToOmit list of fields names to omit
 * @param {object} convertPayloadCallback form data conversion callback
 * @returns edit patient account mutation
 */
const useEdit = (
	options = {},
	{ fieldsToOmit = [], isSuccessConversionEnabled = true } = {},
	convertPayloadCallback
) => {
	const { patientId } = useParams();
	const cache = useQueryClient();
	const patientQueryKey = [QUERY_PATIENT_DATA_AND_PERSONAL_BESTS, patientId];
	const { data: countries } = cache.getQueryData([QUERY_COUNTRIES]);
	const [, { patient: patientData }] = cache.getQueryData(patientQueryKey);

	const handleSuccess = ({ data }) => {
		/* eslint-disable */
		const convertedPayload = isSuccessConversionEnabled
			? options?.createInitialValues?.(data?.patient) ||
			  convertPayloadCallback({
					values: data?.patient,
					countries,
					fieldsToOmit: [],
					initialPatientEmail: patientData?.patientEmail
			  })
			: data?.patient;
		/* eslint-enable */

		cache.setQueryData(patientQueryKey, ([best]) => [
			best,
			{ patient: convertedPayload }
		]);

		options?.onSuccess?.(data, convertedPayload);
	};

	const handleSettled = (data) => {
		cache.invalidateQueries(patientQueryKey);

		options?.onSettled?.(data);
	};

	const mutation = useMutation(
		(values) => {
			const convertedPayload = convertPayloadCallback({
				values,
				countries,
				fieldsToOmit,
				initialPatientEmail: patientData?.patientEmail
			});
			return PatientRepository.edit(convertedPayload, patientId);
		},
		{ ...options, onSuccess: handleSuccess, onSettled: handleSettled }
	);

	return mutation;
};

export default useEdit;
