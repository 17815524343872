import { Box } from '@material-ui/core';
import { format } from 'date-fns';
import { bool, object, string } from 'prop-types';
import React from 'react';

import { useStyles } from './styles';

const DayHeader = ({ date, text, isMonthView }) => {
	const monthDayNumber = format(date, 'dd');

	const classes = useStyles({ isMonthView });

	return (
		<Box>
			{!isMonthView && (
				<Box className={classes.dayNumber}>{monthDayNumber}</Box>
			)}
			<Box className={classes.dayName}>{text}</Box>
		</Box>
	);
};

DayHeader.propTypes = {
	date: object.isRequired,
	text: string.isRequired,
	isMonthView: bool.isRequired
};

export default DayHeader;
