import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	filtersRoot: {
		display: 'flex',
		flexDirection: 'column',
		gap: 15,
		padding: '16px'
	},
	filtersContainer: {
		display: 'flex',
		gap: 15
	}
}));
