export const OBTURATION_LEVELS_LIST = [
	{
		label: 'soft',
		percentage: '>=70%'
	},
	{
		label: 'moderate',
		percentage: '60-69%'
	},
	{
		label: 'medium',
		percentage: '50-59%'
	},
	{
		label: 'heavy',
		percentage: '35-49%'
	},
	{
		label: 'very_heavy',
		percentage: '<35%'
	}
];
