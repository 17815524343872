import {
	ACTION_PLAN_ID_QUERY_PARAM_KEY,
	CLINIC_ID_QUERY_PARAM,
	DRUG_ID_QUERY_PARAM,
	EXAMINATION_TYPE_QUERY_PARAM,
	ID_QUERY_PARAM,
	PATIENT_ID_QUERY_PARAM_KEY,
	SETTING_ID_QUERY_PARAM_KEY,
	TOKEN_QUERY_PARAM_KEY
} from 'helpers';

type TAPI_ROUTE = string;
/**
 * replace ':id' key from given string
 * @param {TAPI_ROUTE} API_ROUTE given string with ':id' key
 * @param {IDParam} id value to replace :id key with
 * @returns {string} new string with given id
 */
export const replaceCommonIdQueryParam = (API_ROUTE: TAPI_ROUTE, id: IDParam) =>
	replaceQueryParamKey(API_ROUTE, ID_QUERY_PARAM, id);

/**
 * replace ':patientId' key from given string
 * @param {TAPI_ROUTE} API_ROUTE given string with ':patientId' key
 * @param {PatientIdParam} patientId value to replace :patientId key with
 * @returns {string} new string with given patientId
 */
export const replacePatientIdQueryParam = (
	API_ROUTE: TAPI_ROUTE,
	patientId: PatientIdParam
): string =>
	replaceQueryParamKey(API_ROUTE, PATIENT_ID_QUERY_PARAM_KEY, patientId);

/**
 * replace ':drugId' key from given string
 * @param {TAPI_ROUTE} API_ROUTE given string with ':drugId' key
 * @param {DrugIdParam} drugId value to replace :drugId key with
 * @returns {string} new string with given drugId
 */
export const replaceDrugIdQueryParam = (
	API_ROUTE: TAPI_ROUTE,
	drugId: DrugIdParam
) => replaceQueryParamKey(API_ROUTE, DRUG_ID_QUERY_PARAM, drugId);

/**
 * replace ':actionPlanId' key from given string
 * @param {TAPI_ROUTE} API_ROUTE given string with ':actionPlanId' key
 * @param {ActionPlanIdParam} actionPlanId value to replace :actionPlanId key with
 * @returns {string} new string with given actionPlanId
 */
export const replaceActionPlanIdQueryParam = (
	API_ROUTE: TAPI_ROUTE,
	actionPlanId: ActionPlanIdParam
) =>
	replaceQueryParamKey(
		API_ROUTE,
		ACTION_PLAN_ID_QUERY_PARAM_KEY,
		actionPlanId
	);

/**
 * replace ':clinicId' key from given string
 * @param {TAPI_ROUTE} API_ROUTE given string with ':clinicId' key
 * @param {ClinicIdParam} drugId value to replace :clinicId key with
 * @returns {string} new string with given clinicId
 */
export const replaceClinicIdQueryParam = (
	API_ROUTE: TAPI_ROUTE,
	clinicId: ClinicIdParam
) => replaceQueryParamKey(API_ROUTE, CLINIC_ID_QUERY_PARAM, clinicId);

/**
 * replace ':settingId' key from given string
 * @param {TAPI_ROUTE} API_ROUTE given string with ':settingId' key
 * @param {SettingIdParam} drugId value to replace :settingId key with
 * @returns {string} new string with given settingId
 */
export const replaceSettingIdQueryParam = (
	API_ROUTE: TAPI_ROUTE,
	settingId: SettingIdParam
) => replaceQueryParamKey(API_ROUTE, SETTING_ID_QUERY_PARAM_KEY, settingId);

/**
 * replace ':token' key from given string
 * @param {TAPI_ROUTE} API_ROUTE given string with ':token' key
 * @param {TokenParam} token value to replace :token key with
 * @returns {string} new string with given settingId
 */
export const replaceTokenQueryParam = (
	API_ROUTE: TAPI_ROUTE,
	token: TokenParam
) => replaceQueryParamKey(API_ROUTE, TOKEN_QUERY_PARAM_KEY, token);

export const replaceExaminationTypeQueryParam = (
	API_ROUTE: TAPI_ROUTE,
	token: ExaminationTypeParam
) => replaceQueryParamKey(API_ROUTE, EXAMINATION_TYPE_QUERY_PARAM, token);

function replaceQueryParamKey(
	API_ROUTE: string,
	QUERY_PARAM_KEY: string,
	id: string
): string {
	return API_ROUTE.replace(QUERY_PARAM_KEY, id);
}
