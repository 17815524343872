import { Box } from '@material-ui/core';
import TouchRipple from '@material-ui/core/ButtonBase/TouchRipple';
import clsx from 'clsx';
import { arrayOf, bool, func, node, oneOfType, string } from 'prop-types';
import React from 'react';

import { useStyles } from './styles';
import useRippleBox from './useRippleBox';

const RippleBox = ({
	className,
	isHoverEnable = true,
	onClick = () => {},
	children,
	...rest
}) => {
	const classes = useStyles();
	const { onRippleStart, onRippleStop, setHoverEffect, rippleRef } =
		useRippleBox({ classes });

	return (
		<Box
			data-testid="root"
			className={clsx([
				classes.root,
				setHoverEffect(isHoverEnable),
				className
			])}
			onMouseDown={(e) => onRippleStart(e, isHoverEnable, rippleRef)}
			onMouseUp={(e) => onRippleStop(e, isHoverEnable, rippleRef)}
			onMouseOut={(e) => onRippleStop(e, isHoverEnable, rippleRef)}
			onClick={onClick}
			{...rest}
		>
			{children}
			<TouchRipple ref={rippleRef} center={false} />
		</Box>
	);
};

RippleBox.propTypes = {
	className: string,
	isHoverEnable: bool,
	onClick: func,
	children: oneOfType([arrayOf(node), node])
};

export default RippleBox;
