import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	formControlWrapper: {
		display: 'flex',
		padding: '0 16px',
		[theme.breakpoints.down('xs')]: {
			margin: '5px 0'
		}
	},
	formControl: {
		flex: 1,
		width: 158,
		'& .MuiOutlinedInput-root': { background: theme.palette.white }
	}
}));
