import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	placeholderRoot: {
		height: '100%'
	},
	root: {
		overflow: 'initial',
		height: '100%',
		paddingBottom: '0px !important'
	}
}));
