import React from 'react';
import { ExaminationsParametersType } from 'types/Examinations/parameters';

import { ExaminationsTrendChartContextType } from '../ExaminationsTrendChartProvider';
import { useActions } from './useActions';
import { useInitialSelectedParameters } from './useInitialSelectedParameters';

type InitialState = {
	selectedParameters: ExaminationsTrendChartContextType['selectedParameters'];
} & ReturnType<typeof useActions>;
const useExaminationsTrendChartProviderInitialState = (): InitialState => {
	const { initialSelectedParameters } = useInitialSelectedParameters();
	const [selectedParameters, setSelectedParameters] = React.useState<
		ExaminationsParametersType[]
	>(initialSelectedParameters);

	const actions = useActions({ setSelectedParameters });

	return {
		selectedParameters,
		...actions
	};
};

export default useExaminationsTrendChartProviderInitialState;
