import { arrayOf, node, oneOfType, string } from 'prop-types';
import React, { createContext } from 'react';
import { useLocation } from 'react-router-dom';

import { getInitialState } from './helpers';
// Import reducer
import reducer from './store/reducer';
import useActions from './useActions';

export const MedicationsContext = createContext();

const MedicationsProvider = ({ children, section: sectionId }) => {
	const { pathname } = useLocation();
	const isEdit = pathname.includes('edit');
	const initialState = getInitialState(isEdit);
	const [state, dispatch] = React.useReducer(reducer, initialState);
	const [section, setSection] = React.useState(sectionId);

	const actions = useActions({ dispatch });

	const isMedicationIndex = state.medicationIndex !== null;

	const providerValues = {
		section,
		setSection,
		isMedicationIndex,
		...state,
		...actions
	};

	return (
		<MedicationsContext.Provider value={{ ...providerValues }}>
			{children}
		</MedicationsContext.Provider>
	);
};

MedicationsProvider.propTypes = {
	children: oneOfType([arrayOf(node), node]).isRequired,
	section: string
};

export default MedicationsProvider;
