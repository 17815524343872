import { makeStyles } from '@material-ui/styles';
import { Props } from 'components/elements/atoms/ExaminationsV2/Cells/StatusCell';
import { getStatusColor } from 'components/elements/atoms/ExaminationsV2/Cells/StatusCell/styles';
import theme from 'theme';
import palette from 'theme/palette';

export const useStyles = makeStyles<typeof theme, { value: Props['value'] }>(
	() => ({
		verificationResultContainer: {
			padding: 15,
			display: 'flex',
			alignItems: 'baseline',
			justifyContent: 'center',
			gap: 10
		},
		resultValue: {
			width: 'auto',
			padding: 5,
			minWidth: 160,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			borderRadius: 100,
			borderColor: ({ value }) =>
				getStatusColor({ value, defaultColor: palette.border.light })
		}
	})
);
