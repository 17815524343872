import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	cellButton: {
		padding: 0,
		'& > span': {
			display: 'flex',
			alignItems: 'flex-start',
			gap: 5
		},
		'&:hover': {
			backgroundColor: 'transparent',
			'& p:not(.errorMessage)': {
				fontWeight: 500,
				textDecoration: 'underline'
			}
		}
	},
	icon: {
		height: 13,
		width: 13
	},
	valueLabel: {
		color: ({ isLoading }) =>
			isLoading ? '#00000042' : theme.palette.primary.main,
		textDecoration: 'none'
	},
	errorMessage: {
		color: theme.palette.error.alert
	},
	loader: {
		width: 'auto'
	}
}));
