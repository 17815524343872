import { Box, BoxProps, IconButton } from '@material-ui/core';
import {
	CheckCircleIcon,
	RadioButtonUncheckedIcon
} from 'assets/icons/commonMUIIcons';
import React from 'react';

import { useStyles } from './styles';

export interface ICheckbox extends BoxProps {
	isSelected: boolean;
	className?: string;
	disabled?: boolean;
	onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}
export const Checkbox = ({
	isSelected,
	disabled = false,
	onClick = undefined,
	className = '',
	...rest
}: ICheckbox) => {
	const classes = useStyles({ isSelected });

	const handleClick = (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		if (onClick) {
			e.stopPropagation();
			onClick(e);
		}
	};
	return (
		<Box
			display={'flex'}
			alignItems="center"
			justifyContent={'center'}
			className={className}
			{...rest}
		>
			<IconButton
				className={classes.iconButton}
				onClick={handleClick}
				disabled={disabled}
			>
				{isSelected ? (
					<CheckCircleIcon
						className={classes.icon}
						fontSize="small"
					/>
				) : (
					<RadioButtonUncheckedIcon
						className={classes.icon}
						fontSize="small"
					/>
				)}
			</IconButton>
		</Box>
	);
};
