import { EXAMINATIONS_TYPES } from '../../../helpers/variables';

const { CALIBRATION_CHECK } = EXAMINATIONS_TYPES;

type TReportSection = 'note' | 'clinic' | 'patient';

const NOT_SUPPORTED_SECTION_FOR_EXAMINATION_TYPE: {
	[K in TReportSection]: Array<ExaminationTypeParam>;
} = {
	note: [CALIBRATION_CHECK.type],
	patient: [CALIBRATION_CHECK.type],
	clinic: []
};

export class ReportsV2Service {
	static disableSectionByExaminationType(
		examinationType: ExaminationTypeParam,
		section: TReportSection
	) {
		return NOT_SUPPORTED_SECTION_FOR_EXAMINATION_TYPE[section].includes(
			examinationType
		);
	}
}
