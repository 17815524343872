import { EmailSchema } from 'helpers/validators/ZOD/inputFields';
import { z } from 'zod';

export const FIELD_NAMES = {
	RECIPIENT_EMAIL: 'recipientEmail'
} as const;

export const ShareReportFormFieldValuesSchema = z.object({
	[FIELD_NAMES.RECIPIENT_EMAIL]: EmailSchema.required
});
export type ShareReportFormFieldValuesModel = z.infer<
	typeof ShareReportFormFieldValuesSchema
>;
