import { CHART_Y_AXIS } from 'helpers';

const CHART_COLORS = {
	WHITE: '#fff',
	LIGHT_GRAY: '#fafafa',
	DARK_GRAY: '#263238',
	PURPLE: '#4A4FE0'
};

export const CHART_CONFIG: Highcharts.Options['chart'] = {
	panning: { enabled: true },
	marginLeft: 20,
	marginTop: 38,
	animation: false,
	alignTicks: false,
	style: {
		fontFamily: 'Geomanist'
	}
};

export const NAVIGATOR_CONFIG: Highcharts.Options['navigator'] = {
	adaptToUpdatedData: false,
	series: {
		type: 'line'
	},
	xAxis: {
		margin: 150
	}
};

export const PLOT_OPTIONS_CONFIG: Highcharts.Options['plotOptions'] = {
	series: {
		states: {
			hover: {
				animation: false
			}
		},
		animation: false,
		showInNavigator: true,
		findNearestPointBy: 'xy',
		turboThreshold: 1365000,
		stickyTracking: false,
		point: {
			events: {}
		},
		cursor: 'pointer'
	}
};

export const SCROLLBAR_CONFIG: Highcharts.Options['scrollbar'] = {
	liveRedraw: true,
	margin: 160,
	barBackgroundColor: CHART_COLORS.PURPLE,
	barBorderRadius: 7,
	barBorderWidth: 0,
	buttonBackgroundColor: CHART_COLORS.PURPLE,
	buttonBorderWidth: 0,
	buttonBorderRadius: 7,
	trackBackgroundColor: 'none',
	trackBorderWidth: 1,
	trackBorderRadius: 8,
	buttonArrowColor: CHART_COLORS.WHITE,
	rifleColor: CHART_COLORS.WHITE,
	height: 0
};

export const X_AXIS_CONFIG: Highcharts.Options['xAxis'] = {
	ordinal: false,
	startOnTick: false,
	endOnTick: false,
	alignTicks: true,
	type: 'datetime',
	alternateGridColor: CHART_COLORS.LIGHT_GRAY,
	minRange: 3600 * 1000 * 24,
	events: {},
	labels: {
		step: 1
	}
};

export const HIGHCHARTS_CONFIG: Highcharts.Options = {
	chart: CHART_CONFIG,
	accessibility: {
		enabled: false
	},
	exporting: {
		enabled: false
	},
	rangeSelector: {
		enabled: false
	},
	legend: {
		enabled: false
	},
	navigator: NAVIGATOR_CONFIG,
	plotOptions: PLOT_OPTIONS_CONFIG,
	scrollbar: SCROLLBAR_CONFIG,
	yAxis: CHART_Y_AXIS as Highcharts.Options['yAxis'],
	xAxis: X_AXIS_CONFIG,
	tooltip: {
		enabled: false,
		snap: 2
	}
};
