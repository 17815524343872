import { Confirmation, LoaderSpinner } from 'components/elements';
import { useTranslations } from 'components/utilities';
import { IDLE } from 'helpers/variables';
import { useVerifyAccount } from 'queries';
import React from 'react';

import { useStyles } from './styles';

const VerificationStatus = () => {
	const { status: idle, apiError } = useVerifyAccount();
	const { t } = useTranslations();
	const classes = useStyles();

	switch (idle) {
		case IDLE.LOADING:
			return (
				<LoaderSpinner
					color="primary"
					size={60}
					text={t('doctor_account_verification.loader_text')}
					textSize={17}
				/>
			);
		case IDLE.SUCCESS:
			return (
				<Confirmation
					title="doctor_account_verification.title"
					description="doctor_account_verification.description"
				/>
			);
		case IDLE.ERROR:
			return (
				<Confirmation
					buttonText="btn.ok"
					errorClassName={classes.alert}
					errorMessage={apiError}
				/>
			);
		default:
			return (
				<LoaderSpinner
					color="primary"
					size={60}
					text={t('doctor_account_verification.loader_text')}
					textSize={17}
				/>
			);
	}
};

export default VerificationStatus;
