import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { ITooltip } from '../../classes/Tooltip';

type Props = {
	examinationTooltip: ITooltip;
};

export const useStyles = makeStyles<Theme, Props>(() => ({
	tooltip: {
		pointerEvents: 'none',
		padding: '20px 15px',
		position: 'absolute',
		transform: ({ examinationTooltip }) =>
			`translate(${examinationTooltip?.positionX}px, ${
				examinationTooltip?.positionY - 20
			}px)`,
		height: ({ examinationTooltip }) =>
			examinationTooltip?.dimensions?.height,
		width: ({ examinationTooltip }) =>
			examinationTooltip?.dimensions?.width,
		zIndex: 9999,
		borderRadius: '10px',
		boxShadow: '0px 3px 12px 0px #00000026',
		// transition: 'opacity 350ms ease-in-out',
		opacity: ({ examinationTooltip }) =>
			examinationTooltip.enabled || examinationTooltip.active ? 0.95 : 0
	},
	alertWrapper: {
		marginBottom: 0,
		width: '100%'
	},
	alert: {
		height: '100%',
		width: '100%',
		display: 'flex',
		alignItems: 'center'
	},
	loader: {
		height: '100%'
	}
}));
