import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2)
	},
	select: {
		width: 50,
		textTransform: 'uppercase',
		fontWeight: 600,
		'&.MuiInput-underline:before': {
			borderBottom: 0
		},
		'& .MuiSelect-select:focus': {
			background: 'transparent'
		}
	},
	menuItem: {
		textTransform: 'uppercase'
	}
}));
