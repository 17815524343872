import { HIGHCHARTS_TYPES } from 'helpers';
import HighchartsMore from 'highcharts/highcharts-more';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';

import { getChartsOptions } from './helpers';
import { HIGHCHARTS_CONFIG } from './helpers/chart-config';
import useHighCharts from './useHighCharts';

// Initialize chart
HighchartsMore(Highcharts);

// Load Highcharts modules
// eslint-disable-next-line
require('highcharts/modules/exporting')(Highcharts);

const { STOCK_CHART } = HIGHCHARTS_TYPES;

(function (H) {
	H.wrap(H.Axis.prototype, 'getClosest', function () {
		return 1000 * 60 * 60 * 24; // 2 closest data points
	});
})(Highcharts);

Highcharts.setOptions(getChartsOptions());

const HighCharts = () => {
	const { trendChartRef } = useHighCharts();

	return (
		<HighchartsReact
			ref={trendChartRef}
			constructorType={STOCK_CHART}
			highcharts={Highcharts}
			options={HIGHCHARTS_CONFIG}
		/>
	);
};

export default HighCharts;
