import clsx from 'clsx';
import { Title as TitleCmp } from 'components/elements/atoms/ExaminationsV2';
import { Props } from 'components/elements/atoms/ExaminationsV2/Typography/Title/Title';
import React from 'react';

import { useStyles } from './styles';

export const Title = (props: Props) => {
	const classes = useStyles();
	return (
		<TitleCmp
			{...props}
			className={clsx([props.className, classes.title])}
		/>
	);
};
