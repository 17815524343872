import React from 'react';

import { FullWidthTableRowCell } from '../../../atoms/Table/FullWidthTableRowCell/FullWidthTableRowCell';
import { Alert } from '../../Alerts';
import { useStyles } from './styles';

export interface ITableErrorAlertProps {
	numberOfColumns: number;
	/**
	 * apiError message threw by API. Usually taken as `apiError` field returned by react-query useQuery or useMutation hook.
	 */
	apiError: ApiErrorState;
}

export const TableErrorAlert = ({
	numberOfColumns,
	apiError
}: ITableErrorAlertProps) => {
	const classes = useStyles();
	return (
		<FullWidthTableRowCell numberOfColumns={numberOfColumns}>
			<Alert message={apiError} className={classes.alert} />
		</FullWidthTableRowCell>
	);
};
