import { produce } from 'immer';

import * as types from './type';

const reducer = (state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.TOGGLE_PRESCRIBED_DRUGS:
				draft.isRefetchPrescribedDrugs =
					!draft.isRefetchPrescribedDrugs;
				break;
			case types.TOGGLE_ARCHIVED_DRUGS:
				draft.isRefetchArchivedDrugs = !draft.isRefetchArchivedDrugs;
				break;

			default:
				return state;
		}
	});

export default reducer;
