import { Box, Typography } from '@material-ui/core';
import { PictureAsPdfIcon } from 'assets/icons/commonMUIIcons';
import clsx from 'clsx';
import { ButtonProgress } from 'components/elements';
import { ExaminationCopdTypeLabel } from 'components/elements/atoms/ExaminationsV2';
import { number, oneOfType, string } from 'prop-types';
import { useDownloadPDFReportByLink } from 'queries/Examinations/ReportsV2';
import React from 'react';
import { useParams } from 'utilities';

import { useItemWrapperContext } from '../../../ItemWrapper/context';
import { CreateTime, ExaminationType } from './components';
import { useStyles } from './styles';

const Header = ({
	title,
	createDateTime,
	pdfReport,
	id,
	type,
	examinationType
}) => {
	const classes = useStyles();
	const { patientId } = useParams();

	const { setWrapperHoverEffect } = useItemWrapperContext();

	const {
		mutateAsync: downloadPDFReportByLinkAsync,
		reset,
		apiError,
		isLoading
	} = useDownloadPDFReportByLink();

	const handleClick = React.useCallback(
		(e) => {
			e.stopPropagation();
			reset();
			downloadPDFReportByLinkAsync({
				patientId,
				fileKey: pdfReport,
				// disable @typescript-eslint/no-non-null-assertion
				// examination id and type is 100% defined inside examination details
				/* eslint-disable */
				examinationId: id,
				examinationType: type
				/*eslint-enable*/
			});
		},
		[pdfReport]
	);

	const renderDownloadPdfReport = () => (
		<Box display={'flex'} alignItems="center" flexDirection={'column'}>
			<ButtonProgress
				onMouseOver={setWrapperHoverEffect}
				onMouseOut={setWrapperHoverEffect}
				className={classes.cellButton}
				onClick={handleClick}
				variant="text"
				isLoading={isLoading}
				style={{ zIndex: 9999 }}
			>
				<PictureAsPdfIcon className={classes.pdfIcon} />
			</ButtonProgress>
			{apiError ? (
				<Typography
					className={clsx([classes.errorMessage, 'errorMessage'])}
				>
					{apiError}
				</Typography>
			) : null}
		</Box>
	);

	return (
		<Box display={'flex'} width="100%" justifyContent={'space-between'}>
			<Box className={classes.root}>
				<Box className={classes.type}>
					<ExaminationType title={title} />
					<ExaminationCopdTypeLabel
						examinationType={examinationType}
					/>
				</Box>
				<CreateTime createDateTime={createDateTime} />
			</Box>
			{pdfReport ? renderDownloadPdfReport() : null}
		</Box>
	);
};

Header.propTypes = {
	id: oneOfType([string, number]).isRequired,
	type: string.isRequired,
	examinationType: string,
	pdfReport: string,
	title: string.isRequired,
	createDateTime: string.isRequired
};

export default Header;
