import { string } from 'prop-types';
import React from 'react';

import { SelectField } from '../SelectField';
import useYearItems from './useYearItems';

const YearSelectField = ({ monthFieldName, dayFieldName, ...rest }) => {
	const { years, onYearChange } = useYearItems({
		monthFieldName,
		dayFieldName
	});

	return (
		<SelectField
			label="global.year_short"
			options={years}
			disabled={false}
			required
			onChange={onYearChange}
			{...rest}
		/>
	);
};

YearSelectField.propTypes = {
	monthFieldName: string.isRequired,
	dayFieldName: string.isRequired
};

export default YearSelectField;
