import { API_URL_PREFIX } from './helpers';

// Specializations
export const SPECIALIZATION_LANG_API_PARAM_KEY = ':lang';
export const SPECIALIZATIONS_API = `${API_URL_PREFIX}/dictionary/${SPECIALIZATION_LANG_API_PARAM_KEY}/specializations`;

export const QUERY_SPECIALIZATIONS = 'QUERY_SPECIALIZATIONS';

// Get Genders
export const GENDERS_API = `${API_URL_PREFIX}/dictionary/getGenders`;

export const QUERY_GENDERS = 'QUERY_GENDERS';

// Length units
export const LENGTH_UNITS_API = `${API_URL_PREFIX}/dictionary/getLengthUnit`;

export const QUERY_LENGTH_UNITS = 'QUERY_LENGTH_UNITS';

// Races units
export const RACES_API = `${API_URL_PREFIX}/dictionary/getRaces`;

export const QUERY_RACES = 'QUERY_RACES';

// Weight units
export const WEIGHT_UNITS_API = `${API_URL_PREFIX}/dictionary/getWeightUnit`;

export const QUERY_WEIGHT_UNITS = 'QUERY_WEIGHT_UNITS';

// COUNTRIES
export const COUNTRIES_API = `${API_URL_PREFIX}/countries`;

export const QUERY_COUNTRIES = 'QUERY_COUNTRIES';
