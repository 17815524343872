import { MenuItem } from '@material-ui/core';
import { useTranslations } from 'components/utilities';
import { array, bool, string } from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';

const GendersField = ({
	name,
	label,
	submitting,
	genders,
	disableUnknownGender = false,
	...rest
}) => {
	const { t } = useTranslations();

	const gendersList = React.useMemo(
		() => (disableUnknownGender ? genders.slice(0, 2) : genders),
		[JSON.stringify(genders)]
	);

	return (
		<Field
			{...rest}
			name={name}
			label={t(label)}
			fullWidth
			disabled={submitting}
			required
		>
			{gendersList.map((gender) => (
				<MenuItem key={gender} value={gender}>
					{t(`global.${gender.toLowerCase()}`)}
				</MenuItem>
			))}
		</Field>
	);
};

GendersField.propTypes = {
	name: string.isRequired,
	label: string.isRequired,
	submitting: bool.isRequired,
	genders: array.isRequired,
	disableUnknownGender: bool
};

export default GendersField;
