import { Radio, Typography as MuiTypography } from '@material-ui/core';
import { ReactComponent as InhalerIcon } from 'assets/icons/ic_rescue2.svg';
import { useTranslations } from 'components/utilities';
import { bool, string } from 'prop-types';
import React from 'react';

import { useStyles } from './styles';

const RadioField = ({ isChecked, label, value }) => {
	const { t } = useTranslations();
	const classes = useStyles({ isChecked, value });
	return (
		<>
			<Radio color="primary" checked={isChecked} size="small" />
			<MuiTypography
				variant="body1"
				component="p"
				className={classes.label}
			>
				{t(label)}
			</MuiTypography>
			<InhalerIcon className={classes.icon} />
		</>
	);
};

RadioField.propTypes = {
	isChecked: bool.isRequired,
	label: string.isRequired,
	value: string.isRequired
};

export default RadioField;
