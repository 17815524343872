import { Box, Button } from '@material-ui/core';
import { ButtonProgress } from 'components/elements';
import { useTranslations } from 'components/utilities';
import { bool, string } from 'prop-types';
import React from 'react';

import { useStyles } from './styles';
import useFormButton from './useFormButton';

const FormButton = ({ submitBtnText, isSubmitLoading }) => {
	const classes = useStyles();
	const { t } = useTranslations();
	const { onCancel } = useFormButton();

	return (
		<Box className={classes.buttonContainer}>
			<Button
				className={classes.btnWrapper}
				onClick={onCancel}
				fullWidth
				size="medium"
				type="button"
				variant="outlined"
				disabled={isSubmitLoading}
			>
				{t('btn.cancel')}
			</Button>
			<ButtonProgress
				classNameWrapper={classes.btnWrapper}
				color="primary"
				fullWidth
				size="medium"
				type="submit"
				variant="contained"
				isLoading={isSubmitLoading}
				disabled={isSubmitLoading}
			>
				{t(submitBtnText)}
			</ButtonProgress>
		</Box>
	);
};

FormButton.propTypes = {
	submitBtnText: string.isRequired,
	isSubmitLoading: bool.isRequired
};

export default FormButton;
