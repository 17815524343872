import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ButtonProgress, RouterLink } from 'components/elements';
import { useTranslations } from 'components/utilities';
import { bool, string } from 'prop-types';
import React from 'react';
import { useFormState } from 'react-final-form';

const useStyles = makeStyles(() => ({
	button: {
		width: '100%',
		textTransform: 'none',
		fontWeight: 600
	}
}));
const ActionButtons = ({ prevPath, isEdit }) => {
	const { t } = useTranslations();
	const classes = useStyles();
	const { submitting } = useFormState();

	return (
		<Grid container spacing={4}>
			<Grid item lg={6} xs={6}>
				<Button
					variant="outlined"
					color="primary"
					component={RouterLink}
					to={prevPath}
					className={classes.button}
					size="small"
				>
					{t('btn.cancel')}
				</Button>
			</Grid>
			<Grid item lg={6} xs={6}>
				<ButtonProgress
					type="submit"
					color="primary"
					variant="contained"
					isLoading={submitting}
					disabled={submitting}
					className={classes.button}
					size="small"
				>
					{isEdit ? t('btn.edit') : t('btn.add')}
				</ButtonProgress>
			</Grid>
		</Grid>
	);
};

ActionButtons.propTypes = {
	prevPath: string.isRequired,
	isEdit: bool.isRequired
};

export default ActionButtons;
