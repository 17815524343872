import { useForm as useUtilsForm } from 'components/utilities';
import { useParams } from 'react-router-dom';

import useItemForm from '../useItemForm';

const useForm = ({
	formSettings: {
		getApiUrl,
		addApiUrl,
		editApiUrl,
		queryKey,
		conditionType,
		createInitialValues,
		convertFormValues,
		setManualRefetch
	},
	initialValues
}) => {
	const { id } = useParams();
	const { fetchFormResAction, submitFormAction, url } = useItemForm({
		getApiUrl,
		addApiUrl,
		editApiUrl,
		convertFormValues
	});

	const {
		data,
		isResourcesError,
		isSubmitError,
		isSubmitLoading,
		isResourcesLoading,
		apiErrors,
		submitForm
	} = useUtilsForm({
		fetchFormResAction,
		submitFormAction,
		QUERY_KEY: [queryKey, id],
		goBackPath: url,
		queryOptions: { onSuccess: setManualRefetch }
	});

	const setInitialValues =
		data && Object.keys(data).length > 0
			? data[conditionType] && Object.keys(data[conditionType]).length > 0
				? createInitialValues(data[conditionType])
				: initialValues
			: initialValues;

	return {
		isResourcesError,
		isSubmitError,
		isSubmitLoading,
		isResourcesLoading,
		setInitialValues,
		submitItem: submitForm,
		apiErrors
	};
};

export default useForm;
