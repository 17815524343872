import { emailRegex } from 'helpers/validation';
export const basicInformationsValidation = ({
	values,
	isEmailAvailable = false,
	patientsList = []
}) => {
	let errors = {};

	const {
		patientEmail,
		patientDob,
		patientFirstName,
		patientLastName,
		patientPhoneNumber,
		patientPhonePrefix,
		patientIdNumber
	} = values;

	if (isEmailAvailable) {
		if (!patientEmail) {
			errors.patientEmail = 'patients.form.validation.email_required';
		} else if (!emailRegex.test(patientEmail)) {
			errors.patientEmail = 'patients.form.validation.email_invalid';
		}
	}

	if (!patientFirstName) {
		errors.patientFirstName =
			'patients.form.validation.first_name_required';
	}

	if (!patientLastName) {
		errors.patientLastName = 'patients.form.validation.last_name_required';
	}

	if (!patientDob) {
		errors.patientDob = 'patients.form.validation.birth_date_required';
	}

	if (patientPhonePrefix && !patientPhoneNumber) {
		errors.patientPhoneNumber =
			'patients.form.validation.phone_number_is_required';
	}

	if (patientPhoneNumber && !patientPhonePrefix) {
		errors.patientPhonePrefix =
			'patients.form.validation.phone_prefix_required';
	}

	function validatePatientIdNumberUniqueness() {
		const patient = patientsList.find(
			(p) => patientIdNumber && p.idNumber === patientIdNumber
		);

		if (!patient) {
			return false;
		}

		const doesIdNumberBelongsToCurrentPatient = patient.id !== values?.id;

		return doesIdNumberBelongsToCurrentPatient;
	}

	if (patientIdNumber && validatePatientIdNumberUniqueness()) {
		errors.patientIdNumber = 'patients.form.validation.id_number_unique';
	}

	return errors;
};
