import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import {
	CheckCircleIcon,
	RadioButtonUncheckedIcon} from 'assets/icons/commonMUIIcons';
import { string } from 'prop-types';
import React from 'react';

import { useStyles } from './styles';
import useParameter from './useParameter';

const Parameter = ({ label, subLabel, mainLabel, name }) => {
	const classes = useStyles();
	const { toggleParameterCheckbox, isParameterChecked } = useParameter({
		name
	});
	//eslint-disable-next-line
	const renderLabel = () =>
		subLabel ? (
			<Box className={classes.label}>
				{mainLabel || label}
				<sub>{subLabel}</sub>
			</Box>
		) : (
			label
		);

	return (
		<FormControlLabel
			className={classes.formControlLabel}
			label={renderLabel()}
			control={
				<Checkbox
					name={name}
					size="small"
					checked={isParameterChecked}
					icon={<RadioButtonUncheckedIcon />}
					checkedIcon={<CheckCircleIcon />}
					onChange={toggleParameterCheckbox}
				/>
			}
		/>
	);
};

Parameter.propTypes = {
	label: string.isRequired,
	name: string.isRequired,
	subLabel: string,
	mainLabel: string
};

export default Parameter;
