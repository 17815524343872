import { makeStyles } from '@material-ui/styles';
import theme from 'theme';

import { OrientationType } from './TestResult';

export const useStyles = makeStyles<
	typeof theme,
	{ orientation: OrientationType }
>(() => ({
	testResultContainer: {
		display: 'flex',
		flexDirection: ({ orientation }) => orientation,
		gap: 10,
		alignItems: 'center',
		flex: 1
	},
	testResultDeltaContainer: {
		display: 'flex',
		gap: 10,
		alignItems: 'center',
		justifyContent: 'center'
	},
	title: {
		marginBottom: ({ orientation }) => (orientation === 'row' ? 0 : 5)
	}
}));
