import { Typography } from '@material-ui/core';
import { string } from 'prop-types';
import React from 'react';

import { useStyles } from './styles';

const CreationTime = ({ createDateTime }) => {
	const classes = useStyles();

	return (
		<Typography className={classes.root} variant="body2" data-testid="root">
			{createDateTime}
		</Typography>
	);
};

CreationTime.propTypes = {
	createDateTime: string.isRequired
};

export default CreationTime;
