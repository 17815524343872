import { SlowVitalCapacitySVCReportModel } from 'models/Report/Reports';
import React from 'react';

import { useGetCachedExaminationDetailsData } from '../../hooks/useGetCachedExaminationDetailsData';

export const useSlowVitalCapacityData = () => {
	const data =
		useGetCachedExaminationDetailsData<SlowVitalCapacitySVCReportModel>();
	const memoizedData = React.useMemo(() => data, []);
	return memoizedData;
};
