import { AutocompleteControlledField } from 'components/elements/molecules/Inputs/Fields';
import { useTranslations } from 'components/utilities';
import React from 'react';
import { useFormState } from 'react-final-form';

const CountryAutocomplete = (fieldProps) => {
	const { t } = useTranslations();
	const {
		submitting,
		values: { countryCode }
	} = useFormState();

	const renderOptionSelected = (option, value) =>
		option.name === (value?.name || value);

	const initialValue = () => (countryCode ? { name: countryCode } : null);
	return (
		<AutocompleteControlledField
			autoComplete={'false'}
			initialValue={initialValue()}
			getOptionSelected={renderOptionSelected}
			getOptionLabel={(option) => option.name}
			disableClearable
			fullWidth
			required
			disabled={submitting}
			{...fieldProps}
			label={t(fieldProps.label)}
		/>
	);
};

export default CountryAutocomplete;
