import { CHART_Y_AXIS } from 'helpers';

import { LinkedSeriesFacade } from './LinkedSeriesFacade';
import { PersonalBestReference } from './PersonalBestReference';

// trend, scatter, navigator
const MIN_AMOUNT_OF_CHARTS = 3;
export const PERSONAL_BEST_SOFT_MAX_FACTOR = 0.85;
export const SOFT_MIN_FACTOR = 0.75;
export class YAxis {
	#yAxisFacade;
	constructor(yAxis) {
		this.#yAxisFacade = yAxis;
	}

	updateSoftMinSoftMax = (
		minMaxScatterValues,
		personalBestValue,
		parameterYAxisGroup
	) => {
		const softMax = this.getSoftMax(
			parameterYAxisGroup,
			personalBestValue,
			minMaxScatterValues.maxScatterValue
		);
		const softMin = this.getSoftMin(
			parameterYAxisGroup,
			softMax,
			minMaxScatterValues.minScatterValue
		);

		this.#yAxisFacade.update({
			softMax,
			softMin
		});
	};

	static mouseOver =
		(patientPersonalBests) =>
		({ target }) => {
			const selectedSerieName = this.#getSelectedSerieName(target);
			if (selectedSerieName && selectedSerieName !== target.name) return;

			const color = this.#getCorrelatedWithYAxisSerieColor(target);
			const personalBestReferenceLine = new PersonalBestReference(
				target.name,
				patientPersonalBests,
				color
			);

			const linkedSeriesFacade = new LinkedSeriesFacade(
				target?.linkedSeries
			);

			linkedSeriesFacade.setVisibility(true);
			target.yAxis.update(
				{
					visible: true,
					plotLines: personalBestReferenceLine.getReferenceLine(true)
				},
				false
			);
			target.update(
				{
					zIndex: 11
				},
				true
			);
		};

	static mouseOut =
		() =>
		({ target }) => {
			const selectedSerieName = this.#getSelectedSerieName(target);

			if (target.selected || selectedSerieName) return;
			const data = {
				visible: true
			};

			const areSeriesFromSingleYAxisGroup =
				this.getSeriesYAxisGroupIndex(target.chart.series) !== -1;
			if (target.yAxis.visible && !areSeriesFromSingleYAxisGroup) {
				data.visible = false;
				data.plotLines = [];
			}

			if (
				areSeriesFromSingleYAxisGroup &&
				target.chart.series.length > MIN_AMOUNT_OF_CHARTS
			) {
				data.plotLines = [];
			}

			const linkedSeriesFacade = new LinkedSeriesFacade(
				target?.linkedSeries
			);

			linkedSeriesFacade.setVisibility(false);
			target.yAxis.update(data, true);
		};

	setDefaultStyles = (visibility) => {
		this.#yAxisFacade.update(
			{
				visible: visibility,
				plotLines: []
			},
			false
		);
	};

	static getYAxisGroupInitialData =
		(patientPersonalBests) => (series, indexOfYAxisGroup) => {
			let data = { visible: false, plotLines: [] };

			if (indexOfYAxisGroup === -1) return { data };

			const isSingleChartSerieContext =
				series.length === MIN_AMOUNT_OF_CHARTS;
			data.visible = true;

			if (!isSingleChartSerieContext) return { data };

			const personalBestReferenceLine = new PersonalBestReference(
				series[indexOfYAxisGroup].name,
				patientPersonalBests,
				series[0].color
			);
			data.plotLines = personalBestReferenceLine.getReferenceLine();

			return { data };
		};

	getSoftMax = (parameterYAxisGroup, personalBestValue, maxScatterValue) => {
		let softMax = parameterYAxisGroup?.softMax;

		if (personalBestValue && (!softMax || softMax < personalBestValue)) {
			softMax =
				Number(personalBestValue?.toFixed(2)) *
				PERSONAL_BEST_SOFT_MAX_FACTOR;
		}

		if (!softMax || softMax < maxScatterValue) {
			softMax = maxScatterValue;
		}

		return softMax;
	};

	getSoftMin = (parameterYAxisGroup, softMax, minScatterValue) => {
		let softMin = parameterYAxisGroup?.softMin;

		if (!softMin || softMin > softMax) {
			softMin = softMax * SOFT_MIN_FACTOR;
		}

		if (softMin > minScatterValue) {
			softMin = minScatterValue;
		}

		return softMin;
	};

	static getSerieCorrespondingYAxisIndex = (parameterType) => {
		const serieCorrespondingYAxisUnitGroup = CHART_Y_AXIS.find(
			({ group }) =>
				group.names.findIndex(
					(name) =>
						name?.toUpperCase() === parameterType.toUpperCase()
				) !== -1
		);

		const yAxisIndex = CHART_Y_AXIS.indexOf(
			serieCorrespondingYAxisUnitGroup
		);

		return yAxisIndex;
	};

	static getSeriesYAxisGroupIndex = (series) => {
		const selectedAllSeriesFromSingleGroup = CHART_Y_AXIS.map(({ group }) =>
			series.every((serie) => {
				if (serie.name.includes('Navigator')) return true;
				return group.names.includes(serie.name);
			})
		);

		const indexOfYAxisGroup =
			selectedAllSeriesFromSingleGroup.indexOf(true);

		return indexOfYAxisGroup;
	};

	static #getCorrelatedWithYAxisSerieColor = (target) => {
		const yAxisIndex = this.#getIndexOfHoveredYAxis(target);
		return CHART_Y_AXIS[yAxisIndex].group.colors[target.name];
	};

	static #getIndexOfHoveredYAxis = (target) =>
		CHART_Y_AXIS.findIndex((yAxis) =>
			yAxis.group.names.includes(target.name)
		);

	static #getSelectedSerieName = (target) => {
		const selectedCharts = target.chart.getSelectedSeries();
		if (selectedCharts.length > 0) {
			return selectedCharts[0].name;
		}
		return null;
	};
}
