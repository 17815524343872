export default {
	'.rdrDateRangePickerWrapper': {
		fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
		display: 'flex'
	},
	'.rdrStaticRangeLabel': {
		fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
		lineHeight: '25px',
		fontSize: '14px'
	},
	'.rdrDayToday .rdrDayNumber span:after': {
		backgroundColor: 'lightgrey'
	},
	'.datePicker': {
		justifyContent: 'space-around'
	},
	'.rdrStaticRange': {
		border: 'none'
	},
	'.rdrCalendarWrapper, .rdrDefinedRangesWrapper': {
		flex: 1,
		border: 'none'
	},
	'@media (max-width: 600px)': {
		'.rdrDateRangePickerWrapper': {
			display: 'block'
		}
	}
};
