import { Box } from '@material-ui/core';
import { useFullscreen } from 'components/utilities';
import React from 'react';

import {
	Controllers,
	ExaminationDataTooltip,
	FullScreenButton,
	HighCharts
} from './components';
import { useStyles } from './styles';

const Chart = () => {
	const fullScreen = useFullscreen();
	const classes = useStyles({
		isFullscreenActive: fullScreen.isFullscreenActive
	});

	return (
		<Box
			{...{ ref: fullScreen.fullscreenRef }}
			className={classes.chartWrapper}
		>
			<Box
				className={classes.controllersContainer}
				display={'flex'}
				justifyContent="flex-end"
				style={{ backgroundColor: 'white' }}
			>
				{!fullScreen.isFullscreenActive && <Controllers />}
				{fullScreen.fullscreenEnabled && (
					<FullScreenButton
						ref={fullScreen.fullscreenRef}
						{...fullScreen}
					/>
				)}
			</Box>
			<ExaminationDataTooltip />
			<HighCharts />
		</Box>
	);
};

export default Chart;
