import React from 'react';
import { Redirect, Route,Switch } from 'react-router-dom';

// Import routes
import { SCHEDULE_ROUTES } from './helpers';
import { DASH_PATHS } from './paths';

const ScheduleRoutes = () => (
	<Switch>
		<Redirect exact from={DASH_PATHS.SCHEDULE} to={DASH_PATHS.SCHEDULE} />
		{SCHEDULE_ROUTES.map((route) => (
			<Route
				key={route.path}
				component={route.component}
				path={route.path}
			/>
		))}
		<Redirect to="/not-found" />
	</Switch>
);

export default ScheduleRoutes;
