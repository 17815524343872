import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	popoverWrapper: {
		height: 24,
		width: 31,
		padding: '6px 8px !important',
		margin: 0,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		background: 'transparent',
		backgroundColor: `${theme.palette.black}CC`,
		borderRadius: '5px'
	},
	circle: {
		cursor: 'pointer',
		position: 'absolute',
		right: ({ pointRightPosition }) => pointRightPosition,
		top: '50%',
		transform: 'translate(50%, -50%)',
		zIndex: 99
	}
}));
