import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	titleContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'baseline'
	},
	title: {
		fontSize: '12px'
	}
}));
