import { Container } from 'components/elements/atoms/ExaminationsV2';
import {
	Obstruction,
	TestResult
} from 'components/elements/organisms/ExaminationsV2';
import React from 'react';

import { PatientCard } from '../components/organisms';
import { ReportTemplate } from '../components/templates';
import { PageProps } from '../types';
import { PrePostGraphs } from './PrePostGraphs';
import { useStyles } from './styles';
import { useGetCachedBronchodilatorReportData } from './useGetCachedBronchodilatorReportData';

export const FirstPage = ({ title, pageCount, pages }: PageProps) => {
	const classes = useStyles();
	const { examination, patient } = useGetCachedBronchodilatorReportData();
	return (
		<ReportTemplate
			config={{
				title,
				footer: { pageCount, pages }
			}}
		>
			<PatientCard patientData={patient} />
			<PrePostGraphs />
			<Container className={classes.flex}>
				<TestResult examination={examination} />
				<Obstruction examination={examination} />
			</Container>
		</ReportTemplate>
	);
};
