import { DOSAGES, MEDICATION_TYPES } from 'helpers';
import _ from 'lodash';

export const DrugsServices = {
	convertFormRequestPayload: ({
		values,
		dosage,
		type,
		dosage_form,
		note
	}) => {
		const { drug } = values;
		const { until, dosage_schedule, taking } = dosage;
		const isWhenNeededDosageSchedule =
			dosage_schedule === DOSAGES.WHEN_NEEDED;
		const newDrug = _.omit(drug, 'take_until');
		const newType = dosage_form === MEDICATION_TYPES.INHALER ? type : null;
		const newUntil = isWhenNeededDosageSchedule ? null : until;
		const newTaking = isWhenNeededDosageSchedule ? [] : taking;

		return {
			drug: {
				...newDrug,
				...dosage,
				taking: newTaking,
				until: newUntil,
				dosage_form,
				note,
				type: newType
			}
		};
	}
};
