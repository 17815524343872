import { makeStyles } from '@material-ui/styles';
import theme from 'theme';

export const useStyles = makeStyles<
	typeof theme,
	{ valueWidth?: number | 'auto' }
>(() => ({
	value: {
		color: '#000000',
		fontWeight: 400,
		width: ({ valueWidth }) => valueWidth ?? 'auto'
	}
}));
