import { CHART_MEASUREMENT_PARAMETER_OPTION_NAMES } from 'helpers';
import {
	PulseOximetryPreviewModel,
	SpirometryPreviewModel,
	SvcPreviewModel
} from 'models/Examinations/allExaminationsTypes';
import { ExaminationsListConvertedItemModel } from 'models/Examinations/ExaminationsList';
import { PersonalBestModel } from 'models/Examinations/PersonalBest';
import { ExaminationDataRowType } from 'utilities/Examinations/Parameters/Builder/Spirometry/SpirometryParametersDirector';
import { IParameterTooltip } from 'utilities/Examinations/Tooltips';

import { getHrTooltipData, getSpO2TooltipData } from './pulseOximetry';
import {
	getFev1TooltipData,
	getFvcTooltipData,
	getPefTooltipData,
	getTiffeneauTooltipData
} from './spirometry';
import { getIcTooltipData, getVcTooltipData } from './svc';

const { fev1, fvc, pef, tiffeneau, ic, vc, hrAvg, spO2 } =
	CHART_MEASUREMENT_PARAMETER_OPTION_NAMES;

interface IExaminationTooltipFactory {
	createTooltip: (chartName: ExaminationChartNames) => void;
}

export interface ITooltipData extends IParameterTooltip {
	createDateTime: string;
}

export class ExaminationTooltipFactory implements IExaminationTooltipFactory {
	private data: ExaminationsListConvertedItemModel;
	private personalBest: PersonalBestModel;

	constructor(
		data: ExaminationsListConvertedItemModel,
		personalBest: PersonalBestModel
	) {
		this.data = data;
		this.personalBest = personalBest;
	}

	public createTooltip = (
		chartName: ExaminationChartNames
	): ITooltipData | null => {
		switch (chartName.toLowerCase()) {
			case fev1.name.toLowerCase():
				return getFev1TooltipData(
					this.data as SpirometryPreviewModel,
					this.personalBest
				);
			case fvc.name.toLowerCase():
				return getFvcTooltipData(
					this.data as SpirometryPreviewModel,
					this.personalBest
				);
			case pef.name.toLowerCase():
				return getPefTooltipData(
					this.data as SpirometryPreviewModel,
					this.personalBest,
					chartName as ExaminationDataRowType
				);
			case tiffeneau.name.toLowerCase():
				return getTiffeneauTooltipData(
					this.data as SpirometryPreviewModel,
					this.personalBest
				);
			case ic.name.toLowerCase():
				return getIcTooltipData(this.data as SvcPreviewModel);
			case vc.name.toLowerCase():
				return getVcTooltipData(this.data as SvcPreviewModel);
			case hrAvg.name.toLowerCase():
				return getHrTooltipData(this.data as PulseOximetryPreviewModel);
			case spO2.name.toLowerCase():
				return getSpO2TooltipData(
					this.data as PulseOximetryPreviewModel
				);
			default:
				return null;
		}
	};
}
