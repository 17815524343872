import { Typography } from '@material-ui/core';
import { useTranslations } from 'components/utilities';
import React from 'react';

import { FullWidthTableRowCell } from '../../../atoms/Table/FullWidthTableRowCell';
import { useStyles } from './styles';

export interface IEmptyDataTableRowProps {
	numberOfColumns: number;
}
export const EmptyDataTableRow = ({
	numberOfColumns
}: IEmptyDataTableRowProps) => {
	const { t } = useTranslations();
	const classes = useStyles();
	return (
		<FullWidthTableRowCell numberOfColumns={numberOfColumns}>
			<Typography className={classes.label}>
				{t('global.no_data')}
			</Typography>
		</FullWidthTableRowCell>
	);
};
