import {
	useExaminationsV2Context,
	useExaminationsV2RefsContext,
	useTooltipContext
} from 'components/context/ExaminationsV2';
import { ExaminationsListServicesV2 } from 'queries';

const usePlotMarkersEvents = () => {
	const { scrollToExaminationsListItem, resetExaminationsVirtualList } =
		useExaminationsV2RefsContext();

	const { getExaminationsV2ContextStore } = useExaminationsV2Context();

	const {
		activateTooltip,
		hideTooltip,
		showTooltip,
		getTooltipContextStore,
		setCorrespondingExaminationsListIndex
	} = useTooltipContext();

	const handleMouseClick = () => {
		const { examinationTooltip } = getTooltipContextStore();
		const examinationsListData =
			getExaminationsV2ContextStore().examinationsListData;

		const examinationListItemIndex =
			ExaminationsListServicesV2.getExaminationIndexFromList({
				examinationsList: examinationsListData,
				id: examinationTooltip.examinationId?.toString(),
				type: examinationTooltip.examinationType
			});
		if (examinationListItemIndex > -1) {
			const examination = examinationsListData[examinationListItemIndex];
			const isCopdFinalExamination =
				ExaminationsListServicesV2.isCopdPostExamination(examination);
			const index = isCopdFinalExamination
				? examinationListItemIndex + 2
				: examinationListItemIndex;

			setCorrespondingExaminationsListIndex(index);
			scrollToExaminationsListItem(index);
			resetExaminationsVirtualList();
		}
		activateTooltip();
	};

	const handleMouseOver = ({ target: tooltipData }) => {
		showTooltip(tooltipData);
	};

	const handleMouseOut = () => {
		hideTooltip();
	};

	const pointEvents = {
		mouseOver: handleMouseOver,
		mouseOut: handleMouseOut,
		click: handleMouseClick
	};

	return {
		pointEvents
	};
};

export default usePlotMarkersEvents;
