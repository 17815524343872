import {
	ContainerWithBorder,
	Title
} from 'components/elements/atoms/ExaminationsV2';
import { ParametersTable } from 'components/elements/organisms/ExaminationsV2';
import React from 'react';

import { useGetCachedCalibrationCheckReportData } from '../useGetCachedCalibrationCheckReportData';
import { columns } from './columns';
import { useStyles } from './styles';

export const VerificationHistory = () => {
	const classes = useStyles();
	const { examination } = useGetCachedCalibrationCheckReportData();

	return (
		<ContainerWithBorder>
			<Title title={'report.calibration_check.verification_history'} />
			<ParametersTable
				rowClassName={classes.row}
				columns={columns}
				data={examination?.verification.history || []}
			/>
		</ContainerWithBorder>
	);
};
