// -----------------  LANGUAGES -----------------
export const FETCH_LANGUAGES_LOADING = 'FETCH_LANGUAGES_LOADING';
export const FETCH_LANGUAGES_SUCCESS = 'FETCH_LANGUAGES_SUCCESS';
export const FETCH_LANGUAGES_ERROR = 'FETCH_LANGUAGES_ERROR';

export const FETCH_AUTH_LANGUAGES_LOADING = 'FETCH_AUTH_LANGUAGES_LOADING';
export const FETCH_AUTH_LANGUAGES_SUCCESS = 'FETCH_AUTH_LANGUAGES_SUCCESS';
export const FETCH_AUTH_LANGUAGES_ERROR = 'FETCH_AUTH_LANGUAGES_ERROR';

export const FETCH_AVAILABLE_LOCALES_LOADING =
	'FETCH_AVAILABLE_LOCALES_LOADING';
export const FETCH_AVAILABLE_LOCALES_SUCCESS =
	'FETCH_AVAILABLE_LOCALES_SUCCESS';
export const FETCH_AVAILABLE_LOCALES_ERROR = 'FETCH_AVAILABLE_LOCALES_ERROR';

export const SET_LANGUAGE = 'SET_LANGUAGE';
