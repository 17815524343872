import { useTranslationsReturnObj } from 'components/utilities/hooks/translations/types';
import { recalculateParameterValueUnit } from 'helpers';
import { ExaminationsParametersType } from 'types/Examinations/parameters';

import { PARAMETERS_TO_UNITS_RECALCULATIONS } from './helpers';

type Parameter = {
	label: string;
	getUserUnit: () => string;
	convertValue?: (v: unknown) => unknown;
};
type ParametersDictionary = {
	[K: string]: Parameter;
};

interface IColumnData<ParameterModel> {
	get columnData(): ColumnValues<ParameterModel>[];
	createColumnData(): ColumnValues<ParameterModel>[];
	addParameterColumnToColumnData(
		columnData: ParameterModel,
		parameter: Parameter,
		type: ExaminationsParametersType
	): ColumnValues<ParameterModel>;
	mergeWithSpecificFields<
		T extends { parameterTypeLabel: string; type: string }
	>(
		colData: T[],
		fieldNames: string[]
	): ColumnValues<ParameterModel>[];
}

export type ColumnValues<C> = C & {
	parameterTypeLabel: string;
	type: string;
};

export class ColumnDataImpl<ColumnsData, ParameterModel>
	implements IColumnData<ParameterModel>
{
	#data: ColumnsData | undefined;
	#PARAMETERS_DICTIONARY: ParametersDictionary;
	#columnData: ColumnValues<ParameterModel>[];
	#tNoParser?: useTranslationsReturnObj['tNoParser'];

	constructor(
		PARAMETERS_DICTIONARY: ParametersDictionary,
		data?: ColumnsData,
		tNoParser?: useTranslationsReturnObj['tNoParser']
	) {
		this.#PARAMETERS_DICTIONARY = PARAMETERS_DICTIONARY;
		this.#data = data;
		this.#tNoParser = tNoParser;
	}

	get columnData() {
		return this.#columnData;
	}

	createColumnDataFromAllMeasurements(): ColumnValues<ParameterModel>[] {
		const allAttempts = new Array(8).fill('');

		const measurementsColumns = Object.entries(
			this.#PARAMETERS_DICTIONARY
		).reduce((acc, [key, parameter]) => {
			if (Array.isArray(this.#data)) {
				allAttempts.forEach((_, i) => {
					const parameterValueByKey = this.#data?.[i]?.[key];
					const value =
						parameterValueByKey?.value === undefined
							? parameterValueByKey
							: recalculateParameterValueUnit(
									key,
									parameterValueByKey.value
							  );
					acc[key] = {
						...acc[key],
						[`meas_${i + 1}`]: parameter?.convertValue
							? parameter.convertValue(value)
							: Number.isNaN(value)
							? undefined
							: value
					};
				});
			}
			acc[key] = this.addParameterColumnToColumnData(
				acc[key],
				parameter,
				key
			);
			return acc;
		}, {} as { [K in keyof ParametersDictionary]: ColumnValues<ParameterModel> });

		this.#columnData = Object.values(measurementsColumns);

		return this.#columnData;
	}

	createColumnData(): ColumnValues<ParameterModel>[] {
		this.#columnData = Object.entries<{
			label: string;
			getUserUnit: () => string;
		}>(this.#PARAMETERS_DICTIONARY).reduce((acc, next) => {
			const parameterData: ParameterModel =
				{ ...this.#data?.[next[0]] } || ({} as ParameterModel);
			PARAMETERS_TO_UNITS_RECALCULATIONS.forEach((key) => {
				if (parameterData?.[key]) {
					parameterData[key] = recalculateParameterValueUnit(
						next[0],
						parameterData[key]
					);
				}
			});
			acc.push(
				this.addParameterColumnToColumnData(
					parameterData,
					next[1],
					next[0]
				)
			);

			return acc;
		}, [] as ColumnValues<ParameterModel>[]);

		return this.#columnData;
	}

	addParameterColumnToColumnData(
		parameterData: ParameterModel,
		parameterDictionaryItem: Parameter,
		type: string
	): ColumnValues<ParameterModel> {
		const label = this.#tNoParser
			? this.#tNoParser(parameterDictionaryItem.label)
			: parameterDictionaryItem.label;

		return {
			...parameterData,
			type,
			parameterTypeLabel:
				label +
				(parameterDictionaryItem.getUserUnit &&
				parameterDictionaryItem.getUserUnit()
					? ` [${parameterDictionaryItem.getUserUnit()}]`
					: '')
		};
	}

	mergeWithSpecificFields<
		T extends {
			type: string;
			parameterTypeLabel: string;
		}
	>(colData: T[], fieldNames: string[]): ColumnValues<ParameterModel>[] {
		return Object.values(this.#columnData).map((d) => {
			let val;
			colData.forEach((el) => {
				if (d.type === el.type) {
					fieldNames.forEach((name) => {
						d[name] = el[name];
					});
					val = d;
					return;
				}
			});
			return val;
		}) as ColumnValues<ParameterModel>[];
	}
}
