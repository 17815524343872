import * as types from './types';

export const setAppointmentsDateRange = (payload) => (dispatch) => {
	dispatch({ type: types.SET_APPOINTMENTS_DATE_RANGE, payload });
};

export const setSelectedDate = (payload) => (dispatch) => {
	dispatch({ type: types.SET_SELECTED_DATE, payload });
};

export const setCalendarView = (payload) => (dispatch) => {
	dispatch({ type: types.SET_CALENDAR_VIEW, payload });
};

export const setDayMaxEvents = (payload) => (dispatch) => {
	dispatch({ type: types.SET_DAY_MAX_EVENTS, payload });
};

export const setIsAppointmentReview = (payload) => (dispatch) => {
	dispatch({ type: types.SET_IS_APPOINTMENT_REVIEW, payload });
};

export const setCreateFromGrid = (payload) => (dispatch) => {
	dispatch({ type: types.SET_IS_CREATE_FROM_GRID, payload });
};
