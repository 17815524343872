import { makeStyles } from '@material-ui/styles';
import theme from 'theme';

import { OrientationType } from '../Obstruction';

export const useStyles = makeStyles<
	typeof theme,
	{ orientation: OrientationType }
>(() => ({
	prePostValuesContainer: {
		display: 'flex',
		justifyContent: 'center',
		gap: 10,
		padding: ({ orientation }) =>
			orientation === 'row' ? '0 0 15px 0' : '10px 0',
		minWidth: 300
	},
	obstructionValue: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	valueInBorder: {
		width: '100%'
	}
}));
