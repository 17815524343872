// Chart lines
// FEV1 #11A0F7, PEF #4AE0B2, AQI #4A4FE0, FVC #815745, PM 2.5 #DB5A35, PM 10 #D4D181, VOC #FC9DEE, FEV1/FVC #3DC638, FEV1/FVCmax #9bc638
import palette from 'theme/palette';

import { EXAMINATIONS_TYPES, UNITS_TYPES } from '../../variables';

export const CHART_EXAMINATION_PARAMETERS_COLORS = {
	FEV1: palette.parameters.chart.fev1,
	FVC: palette.parameters.chart.fvc,
	PEF: palette.parameters.chart.pef,
	TIFFENEAU: palette.parameters.chart.tiffeneau,
	FEV1_VC_MAX: palette.parameters.chart.tiffeneauMax,
	HRAVG: palette.parameters.chart.hrAvg,
	SPO2: palette.parameters.chart.spO2,
	AQI: palette.parameters.chart.aqi,
	VOC: palette.parameters.chart.voc,
	PM25: palette.parameters.chart.pm25,
	PM10: palette.parameters.chart.pm10,
	MEDICAL_NOTE: palette.parameters.chart.medicalNote,
	IC: palette.parameters.chart.ic,
	VC: palette.parameters.chart.vc
};

const { L_M, L_S, L, S, PB, BPM, PERCENTAGE } = UNITS_TYPES;

const { SPIROMETRY, PEAK_FLOW, PULSE_OXIMETRY } = EXAMINATIONS_TYPES;
export const CHART_MEASUREMENT_PARAMETER_OPTION_NAMES = {
	fvc: {
		name: 'fvc',
		label: 'FVC',
		code: SPIROMETRY.type,
		type: 'Spirometry',
		translationKey: 'global.examination_type.spirometry',
		color: CHART_EXAMINATION_PARAMETERS_COLORS.FVC,
		checked: false,
		defaultUnit: L.label
	},
	fev1: {
		name: 'fev1',
		label: 'FEV1',
		code: SPIROMETRY.type,
		type: 'Spirometry',
		translationKey: 'global.examination_type.spirometry',
		color: CHART_EXAMINATION_PARAMETERS_COLORS.FEV1,
		checked: true,
		defaultUnit: L.unit
	},
	tiffeneau: {
		name: 'tiffeneau',
		label: 'FEV1/FVC',
		code: SPIROMETRY.type,
		translationKey: 'global.examination_type.broncholidator',
		color: CHART_EXAMINATION_PARAMETERS_COLORS.TIFFENEAU,
		type: 'Bronchodilator',
		checked: false,
		defaultUnit: ''
	},
	fev1_vc_max: {
		name: 'fev1_vc_max',
		label: 'FEV1/VCmax',
		mainLabel: 'FEV1/VC',
		subLabel: 'max',
		translationKey: 'global.examination_type.broncholidator_max',
		color: CHART_EXAMINATION_PARAMETERS_COLORS.FEV1_VC_MAX,
		type: 'Bronchodilator',
		checked: false,
		defaultUnit: ''
	},
	pef: {
		name: 'pef',
		label: 'PEF',
		code: PEAK_FLOW.type,
		type: 'Peak flow',
		translationKey: 'global.examination_type.peakflow',
		color: CHART_EXAMINATION_PARAMETERS_COLORS.PEF,
		checked: false,
		defaultUnit: L_S.unit
	},
	ic: {
		name: 'ic',
		label: 'IC',
		secondaryLabel: 'IC<sub>avg</sub>',
		code: EXAMINATIONS_TYPES.SVC.type,
		type: 'IC',
		translationKey: 'global.examination_type.svc',
		color: CHART_EXAMINATION_PARAMETERS_COLORS.FVC,
		checked: false,
		defaultUnit: L.unit
	},
	vc: {
		name: 'vc',
		label: 'VC',
		code: EXAMINATIONS_TYPES.SVC.type,
		translationKey: 'global.examination_type.svc',
		type: 'VC',
		color: CHART_EXAMINATION_PARAMETERS_COLORS.FVC,
		checked: false,
		defaultUnit: L.unit
	},
	erv: {
		name: 'erv',
		label: 'ERV',
		code: EXAMINATIONS_TYPES.SPIROMETRY.type,
		type: 'ERV',
		color: CHART_EXAMINATION_PARAMETERS_COLORS.FVC,
		checked: false,
		defaultUnit: L.label
	},
	irv: {
		name: 'irv',
		label: 'IRV',
		code: EXAMINATIONS_TYPES.SPIROMETRY.type,
		type: 'IRV',
		color: CHART_EXAMINATION_PARAMETERS_COLORS.FVC,
		checked: false,
		defaultUnit: L.label
	},
	vt: {
		name: 'vt',
		label: 'VT',
		code: EXAMINATIONS_TYPES.SPIROMETRY.type,
		type: 'VT',
		color: CHART_EXAMINATION_PARAMETERS_COLORS.FVC,
		checked: false,
		defaultUnit: L.label
	},
	hrAvg: {
		name: 'hrAvg',
		label: 'HR',
		color: CHART_EXAMINATION_PARAMETERS_COLORS.HRAVG,
		translationKey: 'global.examination_type.pule_oximetry',
		checked: false,
		type: 'Pulse Oximetry',
		defaultUnit: BPM.unit
	},
	spO2: {
		name: 'spO2',
		label: 'SpO2',
		color: CHART_EXAMINATION_PARAMETERS_COLORS.SPO2,
		checked: false,
		type: 'Pulse Oximetry',
		defaultUnit: PB.unit
	},
	no2: {
		name: 'no2',
		label: 'NO2',
		color: CHART_EXAMINATION_PARAMETERS_COLORS.TIFFENEAU,
		checked: false,
		defaultUnit: ''
	},
	co: {
		name: 'co',
		label: 'Co',
		color: CHART_EXAMINATION_PARAMETERS_COLORS.TIFFENEAU,
		checked: false,
		defaultUnit: ''
	},
	so2: {
		name: 'so2',
		label: 'SO2',
		color: CHART_EXAMINATION_PARAMETERS_COLORS.TIFFENEAU,
		checked: false,
		defaultUnit: ''
	},
	o3: {
		name: 'o3',
		label: 'SO2',
		color: CHART_EXAMINATION_PARAMETERS_COLORS.TIFFENEAU,
		checked: false,
		defaultUnit: ''
	},
	benzene: {
		name: 'benzene',
		label: 'Benzene',
		color: CHART_EXAMINATION_PARAMETERS_COLORS.TIFFENEAU,
		checked: false,
		defaultUnit: ''
	},
	bodyTemperature: {
		name: 'bodyTemperature',
		label: 'Body Temperature',
		color: CHART_EXAMINATION_PARAMETERS_COLORS.TIFFENEAU,
		checked: false,
		defaultUnit: ''
	},
	bloodPressure: {
		name: 'bloodPressure',
		label: 'Blood Pressuree',
		color: CHART_EXAMINATION_PARAMETERS_COLORS.TIFFENEAU,
		checked: false,
		defaultUnit: ''
	},
	respiratoryRate: {
		name: 'respiratoryRate',
		label: 'Respiratory Rate',
		color: CHART_EXAMINATION_PARAMETERS_COLORS.TIFFENEAU,
		checked: false,
		defaultUnit: ''
	},
	vo2Max: {
		name: 'vo2Max',
		label: 'Vo2 Max',
		color: CHART_EXAMINATION_PARAMETERS_COLORS.TIFFENEAU,
		checked: false,
		defaultUnit: ''
	},
	weight: {
		name: 'weight',
		label: 'Weight',
		color: CHART_EXAMINATION_PARAMETERS_COLORS.TIFFENEAU,
		checked: false,
		defaultUnit: UNITS_TYPES.KG.unit
	},
	height: {
		name: 'height',
		label: 'Height',
		color: CHART_EXAMINATION_PARAMETERS_COLORS.TIFFENEAU,
		checked: false,
		defaultUnit: UNITS_TYPES.CM.unit
	},
	aqi: {
		name: 'aqi',
		label: 'AQI',
		code: PULSE_OXIMETRY.type,
		type: 'Pollution',
		color: CHART_EXAMINATION_PARAMETERS_COLORS.AQI,
		checked: false,
		defaultUnit: ''
	},
	pm25: {
		name: 'pm25',
		label: 'PM 2.5',
		type: 'Heart rate',
		color: CHART_EXAMINATION_PARAMETERS_COLORS.PM25,
		checked: false,
		defaultUnit: ''
	},
	pm10: {
		name: 'pm10',
		label: 'PM 10',
		type: 'Heart rate',
		color: CHART_EXAMINATION_PARAMETERS_COLORS.PM10,
		checked: false,
		defaultUnit: ''
	},
	voc: {
		name: 'voc',
		label: 'VOC',
		color: CHART_EXAMINATION_PARAMETERS_COLORS.VOC,
		type: 'Bronchodilator',
		checked: false,
		defaultUnit: ''
	},
	medicalNote: {
		name: 'medicalNote',
		label: 'Medical Note',
		translationKey: 'global.examination_type.medical_note',
		defaultUnit: ''
	},
	fef25: {
		name: 'fef25',
		label: 'FEF25',
		defaultUnit: L_S.unit
	},
	fef50: {
		name: 'fef50',
		label: 'FEF50',
		defaultUnit: L_S.unit
	},
	fef75: {
		name: 'fef75',
		label: 'FEF75',
		defaultUnit: L_S.unit
	},
	fef25_75: {
		name: 'fef25_75',
		label: 'FEF25-75',
		defaultUnit: L_S.unit
	},
	bev: {
		name: 'bev',
		label: 'BEV',
		defaultUnit: L.unit
	},
	fet: {
		name: 'fet',
		label: 'FET',
		defaultUnit: S.unit
	},
	tpef: {
		name: 'tpef',
		label: 'TPEF',
		defaultUnit: S.unit
	},
	fev6: {
		name: 'fev6',
		label: 'FEV6',
		defaultUnit: L.unit
	},
	vtpefVe: {
		name: 'vtpefVe',
		label: 'VTPEF VE',
		defaultUnit: PERCENTAGE.unit
	},
	fivc: {
		name: 'fivc',
		label: 'FIVC',
		defaultUnit: L.unit
	},
	pif: {
		name: 'pif',
		label: 'PIF',
		defaultUnit: L_M.unit
	},
	mif25: {
		name: 'mif25',
		label: 'FIF25',
		defaultUnit: L_S.unit
	},
	mif50: {
		name: 'mif50',
		label: 'FIF50',
		defaultUnit: L_S.unit
	},
	mif75: {
		name: 'mif75',
		label: 'FIF75',
		defaultUnit: L_S.unit
	},
	tptefTe: {
		name: 'tptefTe',
		label: 'TPTEF TE',
		defaultUnit: PERCENTAGE.unit
	},
	rt: {
		name: 'rt',
		label: 'RT',
		defaultUnit: S.unit
	}
} as const;

export const CHART_MEASUREMENT_ADDITIONAL_PARAMETERS = {
	best: {
		name: 'best',
		label: 'BEST',
		defaultUnit: L_M.unit
	},
	pef1: {
		name: 'pef1',
		label: 'PEF1',
		defaultUnit: L_M.unit
	},
	pef2: {
		name: 'pef2',
		label: 'PEF2',
		defaultUnit: L_M.unit
	},
	pef3: {
		name: 'pef3',
		label: 'PEF3',
		defaultUnit: L_M.unit
	}
};

export const SPIROMETRY_EXAMINATION_VALUE_KEYS = {
	best: { key: 'best', unit: null },
	PBPercentage: { key: 'PBPercentage', unit: '%' },
	predicted: { key: 'predicted', unit: '%' },
	bestPercentile: { key: 'bestPercentile', unit: '' }
};

export const PULSE_OXIMETRY_HEART_RATE_KEYS = {
	hrAvg: {
		key: CHART_MEASUREMENT_PARAMETER_OPTION_NAMES.hrAvg.name,
		unit: CHART_MEASUREMENT_PARAMETER_OPTION_NAMES.hrAvg.defaultUnit
	},
	hrMax: {
		key: 'hrMax',
		unit: CHART_MEASUREMENT_PARAMETER_OPTION_NAMES.hrAvg.defaultUnit
	}
};

export const PULSE_OXIMETRY_SATURATION_KEYS = {
	spO2: {
		key: CHART_MEASUREMENT_PARAMETER_OPTION_NAMES.spO2.name,
		unit: CHART_MEASUREMENT_PARAMETER_OPTION_NAMES.spO2.defaultUnit
	}
};

export const PULSE_OXIMETRY_VALUE_KEYS = {
	...PULSE_OXIMETRY_HEART_RATE_KEYS,
	...PULSE_OXIMETRY_SATURATION_KEYS
};
