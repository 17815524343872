import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
		marginTop: theme.spacing(3)
	},
	rootCardContent: {
		padding: '72px 33px 0px 33px',
		height: ({ isInfiniteScroll }) => (isInfiniteScroll ? '100%' : 'auto')
	},
	cardBox: {
		height: ({ isInfiniteScroll }) => (isInfiniteScroll ? '100%' : 'auto')
	},
	head: {
		whiteSpace: 'nowrap'
	},
	content: {
		padding: 0,
		height: ({ isInfiniteScroll }) =>
			isInfiniteScroll ? 'calc(100% - 45px)' : 'auto'
	},
	tableBody: {
		fontSize: '16px'
	},
	inner: {
		height: ({ isInfiniteScroll }) => (isInfiniteScroll ? '100%' : 'auto'),
		minHeight: 48 * 6 + 1
	},
	nameContainer: {
		display: 'flex',
		alignItems: 'center'
	},
	avatar: {
		marginRight: theme.spacing(2)
	},
	actions: ({ isCardTopBar }) => ({
		justifyContent: 'flex-end',
		paddingBottom: isCardTopBar ? 0 : '8px'
	}),
	alignRight: {
		textAlign: 'right'
	},
	alignLeft: {
		textAlign: 'left'
	}
}));
