import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		position: 'relative',
		maxWidth: ({ maxWidth }) => maxWidth || 'auto'
	},
	loaderContainer: {
		backgroundColor: ({ enableBackground }) =>
			enableBackground ? 'rgba(0,0,0,0.1)' : 'unset',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		position: 'absolute',
		top: 0,
		left: 0,
		height: '100%',
		width: '100%'
	},
	loader: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(50%, 50%)'
	}
}));
