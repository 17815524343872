export const INITIAL_STATE = {
	isFormOpen: false,
	medicationIndex: null,
	medication: { name: null, source: null, id: null },
	dosage: {
		taking: [],
		days: [],
		dosage_schedule: '',
		until: new Date()
	},
	dosage_form: '',
	type: null,
	note: ''
};

export const getInitialState = (isEdit) => ({
	isFormOpen: false,
	medicationIndex: null,
	medication: { name: null, source: null, id: null },
	dosage: {
		taking: [],
		days: [],
		dosage_schedule: isEdit ? '' : 'when_needed',
		until: new Date()
	},
	dosage_form: isEdit ? '' : 'unknown',
	type: null,
	note: ''
});
