import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		height: '20px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	date: {
		textAlign: 'center',
		fontSize: 13,
		lineHeight: '13px'
	}
}));
