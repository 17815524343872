import { Box } from '@material-ui/core';
import { PulseOximetryReportModel } from 'models/Report/Reports';
import React from 'react';

import {
	DeviceInformation,
	ExaminationNote,
	GeneratePDFReportActionButton,
	Header,
	ReportPDFHistory,
	TechnicalNote
} from '../components';
import {
	ExaminationDataProvider,
	ReportInterpretationNoteProvider
} from '../context';
import { useCommonStyles } from '../styles';
import { BestResultsTable } from './components';
import { usePulseOximetryData } from './usePulseOximetryData';

export const PulseOximetry = () => {
	const examinationData = usePulseOximetryData();
	const commonClasses = useCommonStyles();

	return (
		<ExaminationDataProvider<PulseOximetryReportModel | undefined>
			data={examinationData}
		>
			<Header
				title="patients.examinations_v2.details.pulse_oximetry.title"
				createdDateTime={examinationData?.examinationCreateDateTime}
				refValue={examinationData?.referenceStandard}
			/>
			<BestResultsTable />
			<ReportInterpretationNoteProvider>
				<Box className={commonClasses.notesContainer}>
					<TechnicalNote />
					<ExaminationNote />
				</Box>
				<ReportPDFHistory />
				<GeneratePDFReportActionButton />
			</ReportInterpretationNoteProvider>
			<DeviceInformation deviceData={examinationData?.device} />
		</ExaminationDataProvider>
	);
};
