export const SPIROMETRY_PB_PERCENTAGE_COLOR_RANGES = {
	GREEN: 80,
	YELLOW: 60
};

export const SPIROMETRY_PERSONAL_BEST_COLOR_RANGES = {
	GREEN: 80,
	YELLOW: 60
};

export const PULSE_OXIMETRY_SPO2_COLOR_RANGES = {
	GREEN: 93,
	YELLOW: 84
};

export const PULSE_OXIMETRY_HR_COLOR_RANGES = {
	GREEN: 67,
	YELLOW: 33
};
