import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslations } from 'components/utilities';
import { EMPTY_VALUE } from 'helpers/variables';
import React from 'react';

import { useStyles } from './styles';

type Value = AnyType;
export type RowPreviewProps = {
	component?:
		| ((props: Omit<RowPreviewProps, 'name'> & AnyType) => JSX.Element)
		| null;
	label?: (() => JSX.Element) | string;
	description?: string;
	name: string;
	rowClassName?: string;
	required?: boolean;
	value?: Value;
	valueConverter?: ((value: Value) => unknown) | null;
} & Record<string, AnyType>;

const RowPreview = (props: RowPreviewProps) => {
	const {
		rowClassName = '',
		description = '',
		label: LabelText = '',
		value = null,
		required = false,
		component: Component = null,
		valueConverter = () => {}
	} = props;
	const classes = useStyles();
	const { t } = useTranslations();

	const getValue = (value) => {
		const convertedValue = valueConverter?.(value) ?? value;
		return t(convertedValue) ?? convertedValue ?? EMPTY_VALUE;
	};

	const renderLabel = React.useCallback(() => {
		if (LabelText) {
			const isLabelString = typeof LabelText === 'string';
			return (
				<>
					{isLabelString ? t(LabelText) ?? LabelText : <LabelText />}
					{required ? ' *' : ''}
				</>
			);
		}
		return '';
	}, [required]);

	return (
		<Box
			className={clsx([rowClassName, classes.row, classes.root])}
			data-testid="RowPreview-root"
		>
			<Typography
				className={classes.label}
				data-testid="RowPreview-label"
			>
				{renderLabel()}
			</Typography>
			{Component ? (
				<Component {...props} />
			) : (
				<Typography
					className={classes.value}
					data-testid="RowPreview-value"
				>
					{getValue(value)}
				</Typography>
			)}
			{description && (
				<>
					<Box className={classes.breakBox} />
					<Typography variant="body2" className={classes.description}>
						{t(description)}
					</Typography>
				</>
			)}
		</Box>
	);
};

export default RowPreview;
