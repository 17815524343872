import { useMutation } from 'components/utilities';
import { CLINIC_DATA_QUERY_KEY } from 'helpers';
import { ClinicRequestModel } from 'models/Clinic/Clinic';
import { queryClient } from 'utilities';

import { ClinicRepository } from './repository';
import { ClinicService } from './service';

export const useCreateClinic = (options) => {
	const handleSubmit = async (payload: ClinicRequestModel) => {
		const convertedPayload =
			await ClinicService.convertPayloadToBackendValid(payload);
		const formDataPayload =
			ClinicService.convertPayloadToFormData(convertedPayload);
		return ClinicRepository.create({ payload: formDataPayload });
	};

	const handleSuccess = (
		data: Record<string, never>,
		variables: ClinicRequestModel,
		ctx
	) => {
		queryClient.invalidateQueries([CLINIC_DATA_QUERY_KEY]);
		options?.onSuccess?.(data, variables, ctx);
	};
	return useMutation<Record<string, never>, TMyError, ClinicRequestModel>(
		handleSubmit,
		{
			...options,
			onSuccess: handleSuccess
		}
	);
};
