import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	legendContainer: {
		display: 'flex',
		gap: 8
	},
	legendItem: {
		display: 'flex',
		alignItems: 'center',
		gap: 10,
		padding: '5px 15px 6px',
		borderRadius: 100
	},
	legendLine: {
		height: 1.5,
		width: 40
	}
}));
