import { useSocket } from 'components/utilities';
import { shape, string } from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useSearchParams } from 'utilities';

import {
	getSetConversationResources,
	INITIAL_STATE,
	SOCKET_EVENTS,
	sortMessagesByDate
} from './helpers';
import {
	receiveMessage,
	setAuth,
	setConversation,
	setConversationHistory,
	setConversationList
} from './store/actions';
// Import reducer
import reducer from './store/reducer';

const {
	AUTHORIZE,
	LOAD_CONVERSATIONS,
	LOAD_CONVERSATION_HISTORY,
	UPDATE_CONVERSATION_LIST,
	SEND_MESSAGE,
	RECEIVED_MESSAGE,
	MARK_CONVERSATION_AS_READ
} = SOCKET_EVENTS;

const useMessengerProvider = ({ sockets: { socketUrl, token } }) => {
	const [state, dispatch] = React.useReducer(reducer, INITIAL_STATE);
	const [isParamConversation, setIsParamConversation] = React.useState(false);
	const [socket] = useSocket(socketUrl);
	const { patientId } = useParams();
	const { getAllSearchParams } = useSearchParams();

	const { conversationList, conversationId } = state;

	// -------- Effects --------
	React.useEffect(() => {
		socket.connect();
		socketAuth();

		//eslint-disable-next-line
	}, []);

	React.useEffect(() => {
		const {
			conversationCondition,
			conversationParamCondition,
			patientParamConversation,
			newConversationId
		} = getSetConversationResources({
			conversationList,
			patientId,
			conversationId,
			isParamConversation
		});

		const conversationIdSearchParam = getAllSearchParams()?.conversationId;
		if (conversationIdSearchParam) {
			setNewConversationId(conversationIdSearchParam);
		}

		// Set first conversation to the messenger based on conversation list when the app is starting
		if (conversationCondition) {
			setNewConversationId(conversationId || newConversationId);
		}
		// Set first conversation to the messenger based on path param id when the app is starting
		else if (conversationParamCondition) {
			const { id } = patientParamConversation;
			setNewConversationId(id);
			setIsParamConversation(true);
		} else if (!patientId) {
			setIsParamConversation(true);
		}
		//eslint-disable-next-line
	}, [conversationList, conversationId]);

	// -------- Socket Events --------
	const socketAuth = () => {
		socket.emit(AUTHORIZE, { token }, (data) => {
			setAuth(data)(dispatch);
			socketLoadConversationList();
			socketUpdateConversationsList();
			socketReceiveMessage();
		});
	};

	const socketLoadConversationList = () => {
		socket.emit(LOAD_CONVERSATIONS, {}, ({ data }) => {
			data && setConversationList(data)(dispatch);
		});
	};

	const socketUpdateConversationsList = () => {
		socket.on(UPDATE_CONVERSATION_LIST, () => socketLoadConversationList());
	};

	const socketLoadConversationHistory = (props) => {
		const { id, startId = null, isNewConversation = false } = props;
		const options = {
			conversation_id: id,
			start_id: startId,
			limit: 40
		};

		socket.emit(LOAD_CONVERSATION_HISTORY, options, (props) => {
			handleSetConversationHistory({
				...props,
				converstaionId: id,
				isNewConversation
			});
		});
	};

	const handleSetConversationHistory = ({ status, data, ...rest }) => {
		if (status) {
			const messages = sortMessagesByDate(data);

			const options = {
				...rest,
				status,
				messages
			};

			setConversationHistory(options)(dispatch);
		}
	};

	const socketReceiveMessage = () =>
		socket.on(RECEIVED_MESSAGE, (data) => receiveMessage(data)(dispatch));
	// -------- Socket Events End --------

	// -------- Handlers --------
	const setNewConversation = (id) =>
		socketLoadConversationHistory({ id, isNewConversation: true });

	const setNewConversationId = (id) => setConversation(id)(dispatch);

	const sendMessage = (options) => socket.emit(SEND_MESSAGE, options);

	const markConversationAsRead = (options) => {
		socket.emit(MARK_CONVERSATION_AS_READ, options);
	};

	// -------- Handlers End --------

	return {
		...state,
		sendMessage,
		setNewConversation,
		markConversationAsRead,
		loadConversationHistory: socketLoadConversationHistory
	};
};

useMessengerProvider.propTypes = {
	socket: shape({ socketUrl: string.isRequired, token: string.isRequired })
		.isRequired
};

export default useMessengerProvider;
