import { Box } from '@material-ui/core';
import { ReactComponent as DeltaIcon } from 'assets/icons/triangle.svg';
import clsx from 'clsx';
import {
	ContainerWithBorder,
	Title
} from 'components/elements/atoms/ExaminationsV2';
import { capitalize } from 'helpers';
import { BronchodilatorReportModel } from 'models/Report/Reports';
import React from 'react';

import { useStyles } from './styles';
import Value from './Value/Value';
type Props = {
	deltaType: string;
	examination: BronchodilatorReportModel | undefined;
};
export const DeltaItem = ({ deltaType, examination }: Props) => {
	const classes = useStyles();

	return (
		<ContainerWithBorder className={classes.itemContainer}>
			<Box className={classes.flex}>
				<DeltaIcon />
				<Title disableTranslation title={`${deltaType}`} />
			</Box>
			<Box className={clsx([classes.flex, classes.gap])}>
				<Value
					unit="ML"
					value={
						examination?.testResult?.[
							`delta${capitalize(deltaType)}`
						]?.value?.toFixed(0) as number
					}
				/>
				<Value
					unit="%"
					value={
						examination?.testResult?.[
							`delta${capitalize(deltaType)}`
						]?.percentage?.toFixed(2) as number
					}
				/>
			</Box>
		</ContainerWithBorder>
	);
};
