import { EXAMINATION_TYPES_FILTER_OPTIONS } from 'queries/Examinations/ExaminationsList/helpers';

export const getRenderValue = (selectedValues: ExaminationTypeParam[], t) => {
	const isAllChecked =
		selectedValues.length === EXAMINATION_TYPES_FILTER_OPTIONS.length;

	if (isAllChecked) {
		return t('review.filters.all');
	}

	const selectedText = selectedValues.reduce((acc, next) => {
		const examinationTypeOption = EXAMINATION_TYPES_FILTER_OPTIONS.find(
			({ value }) => value === next
		);

		if (examinationTypeOption) {
			return (
				acc +
				(acc ? ', ' : '') +
				t(`${examinationTypeOption.label}_short`)
			);
		}
		return acc;
	}, '');

	return selectedText;
};
