import { EmployeeInvitation, HelmetTags } from 'components/elements';
import React from 'react';

const DoctorInvitation = () => (
	<>
		<HelmetTags title="pages.doctor_invitation.title" />
		<EmployeeInvitation />
	</>
);

export default DoctorInvitation;
