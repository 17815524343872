import { useDrugsContext, useMedicationsContext } from 'components/context';
import { PATIENTS_DETAILS_PATHS } from 'components/routes/paths';
// Import queries
import { DrugsRepository, DrugsServices } from 'queries';
import { replacePatientIdQueryParam } from 'queries/common';
import { useParams } from 'react-router-dom';

const {
	DRUGS: { ROOT }
} = PATIENTS_DETAILS_PATHS;

const useDrugForm = () => {
	const { patientId, id: drugId } = useParams();
	const context = useMedicationsContext();
	const { togglePrescribedDrugs } = useDrugsContext();

	const prevPath = replacePatientIdQueryParam(ROOT, patientId);

	const fetchResources = ({ isEdit, id: drugId, cancelToken }) =>
		isEdit ? DrugsRepository.get(drugId, cancelToken) : {};

	const submitResources = ({ values, isEdit, id: drugId, patientId }) => {
		const payload = DrugsServices.convertFormRequestPayload({
			...context,
			values
		});
		if (isEdit) {
			return DrugsRepository.edit(drugId, payload);
		}
		return DrugsRepository.create(patientId, payload);
	};

	const handleSuccess = async () => {
		togglePrescribedDrugs();
	};

	return {
		prevPath,
		drugId,
		fetchResources,
		submitResources,
		onSuccess: handleSuccess
	};
};

export default useDrugForm;
