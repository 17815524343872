import { TwoFactorAuthModel } from 'models/Auth/SignIn/signIn';
import { DoctorUserModel } from 'models/User/Doctor';
import { Credentials } from 'views/SignIn/context/SignInProvider';

import { ISignInStrategy } from './ISignInStrategy';

type StrategyType = ISignInStrategy<AnyType>;
type SuccessDataType = DoctorUserModel | TwoFactorAuthModel;

export interface ISignInContext {
	setStrategy: (strategy: StrategyType) => void;
	onSignInSuccess: (
		data: SuccessDataType,
		setCredentialsCallback: (data: Credentials) => void
	) => void;
}

export class SignInContext implements ISignInContext {
	private strategy: StrategyType | undefined;

	constructor() {}

	public setStrategy = (strategy: StrategyType) => {
		this.strategy = strategy;
	};

	public onSignInSuccess = (
		data: SuccessDataType,
		setCredentialsCallback: (data: Credentials) => void
	) => {
		if (!this.strategy) {
			return;
		}
		this.strategy.success(data, setCredentialsCallback);
	};

	public static isTwoFactorResponse = (data: SuccessDataType) =>
		Object.prototype.hasOwnProperty.call(data, 'twoFactorAuthenticationId');
}
