import { PropTypes } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslations } from 'components/utilities';
import React from 'react';

import { Typography } from '../Typography';
import { useStyles } from './styles';

export type Props = {
	title: string;
	disableTranslation?: boolean;
	variant?: 'primary' | 'secondary';
	className?: string;
	textAlign?: PropTypes.Alignment;
};
export const Title = ({
	title,
	disableTranslation = false,
	variant = 'primary',
	className = '',
	textAlign
}: Props) => {
	const { t } = useTranslations();
	const classes = useStyles({ variant, textAlign });

	return (
		<Typography className={clsx([classes.title, className])}>
			{disableTranslation ? title : t(title)}
		</Typography>
	);
};
