import { TableCellContent } from 'components/elements/molecules/Table';
import { PATIENTS_DETAILS_PATHS } from 'components/routes/paths';
import React from 'react';
import { generatePath } from 'react-router-dom';

import { Ctx } from '../types';
import { usePatientFullName } from '../usePatientFullName';

export const PatientNameCell = ({ ctx }: Ctx) => {
	const patientFullName = usePatientFullName({ ctx });
	return (
		<TableCellContent.NavLink
			label={patientFullName}
			path={generatePath(PATIENTS_DETAILS_PATHS.EXAMINATIONS.PREVIEW, {
				patientId: ctx.row.original.id
			})}
			pathBase={generatePath(PATIENTS_DETAILS_PATHS.EXAMINATIONS.ROOT, {
				patientId: ctx.row.original.id
			})}
		/>
	);
};
