import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	useMediaQuery
} from '@material-ui/core';
import { useSelectProps, useTranslations } from 'components/utilities';
import { func, string } from 'prop-types';
import React from 'react';

import { useStyles } from './styles';

const DOSAGE_FORM = ['puff', 'capsule', 'tablet', 'milliliter'];

const SelectField = ({ setApplyingWay, timeOfDay, value }) => {
	const matchesFields = useMediaQuery('(min-width:1430px)');
	const classes = useStyles({ matchesFields });
	const { t } = useTranslations();
	const selectProps = useSelectProps();

	const handleChange = (e) => {
		const {
			target: { value }
		} = e;
		setApplyingWay(value, timeOfDay);
	};

	return (
		<FormControl variant="outlined" margin="dense" className={classes.root}>
			<InputLabel id="label">
				{t('patients.action_plan.create.medications.applying_way')}
			</InputLabel>
			<Select
				labelId="label"
				value={value}
				onChange={handleChange}
				MenuProps={{ ...selectProps }}
				label={t(
					'patients.action_plan.create.medications.applying_way'
				)}
			>
				{DOSAGE_FORM.map((item) => (
					<MenuItem key={item} value={item}>
						{t(`patients.action_plan.create.medications.${item}`)}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

SelectField.propTypes = {
	timeOfDay: string.isRequired,
	value: string,
	setApplyingWay: func.isRequired
};

export default SelectField;
