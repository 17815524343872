import { Box, Typography as MuiTypography } from '@material-ui/core';
import { useProfile, useTranslations } from 'components/utilities';
import React from 'react';

import { useStyles } from './styles';

const Profile = () => {
	const classes = useStyles();
	const { t } = useTranslations();
	const { firstName, lastName, roleLabel } = useProfile();

	return (
		<Box className={classes.root}>
			<MuiTypography
				variant="h5"
				component="p"
				className={classes.doctorName}
			>
				{firstName} {lastName}
			</MuiTypography>
			<MuiTypography
				className={classes.role}
				variant="body2"
				component="p"
			>
				{t(roleLabel)}
			</MuiTypography>
		</Box>
	);
};

export default Profile;
