import { useMutation } from 'components/utilities';
import { useDispatch, useSelector } from 'react-redux';
import { updateSettings } from 'store/actions';
import { Settings } from 'utilities';

import { SettingsRepository } from './repository';

const useUpdateSettingValueByID = (options = {}, { settingId } = {}) => {
	const dispatch = useDispatch();
	const { settings: reduxSettings } = useSelector((store) => store.settings);

	const handleSuccess = (data) => {
		const settings = new Settings(reduxSettings);
		settings.replaceValueById({ settingId, ...data });
		updateSettings(settings.getSettings())(dispatch);
		options?.onSuccess?.({ settingId, ...data });
	};

	const mutation = useMutation(
		(newValue) =>
			SettingsRepository.updateValueByID({ settingId, newValue }),
		{ ...options, onSuccess: handleSuccess }
	);
	return mutation;
};

export default useUpdateSettingValueByID;
