import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		marginBottom: 10
	},
	type: {
		display: 'flex',
		flexDirection: 'column'
	},
	arrowIcon: {
		position: 'relative',
		alignSelf: 'flex-start',
		marginLeft: 10,
		fontSize: 16,
		fill: theme.palette.primary.main
	},
	errorMessage: {
		color: theme.palette.error.alert,
		fontSize: '10px',
		lineHeight: '10px'
	},
	loader: {
		width: 'auto'
	},
	cellButton: {
		padding: 3,
		width: '35px',
		minWidth: 'auto',
		alignItems: 'flex-start'
	},
	pdfIcon: {
		height: 25,
		width: 25
	},
	downloadIcon: {
		height: 10,
		width: 10
	}
}));
