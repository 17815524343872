import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { FirstPage } from './FirstPage';
import { SecondPage } from './SecondPage';

const PAGES = [FirstPage, SecondPage];

export const Bronchodilator = () => {
	return (
		<>
			{PAGES.map((Page, index) => (
				<Page
					title="report.type.bronchodilator"
					key={uuidv4()}
					pageCount={index + 1}
					pages={PAGES.length}
				/>
			))}
		</>
	);
};
