import { useScheduleCalendarContext } from 'components/context/Schedule';
import { useFormState } from 'react-final-form';

const useDateField = () => {
	const { submitting } = useFormState();
	const { isAppointmentReview, unselectTimeGrid } =
		useScheduleCalendarContext();

	const handleOnChange = () => {
		unselectTimeGrid();
	};

	return {
		isAppointmentReview,
		submitting,
		onChange: handleOnChange
	};
};

export default useDateField;
