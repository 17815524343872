export const INITIAL_STATE = {
	spanColumns: { xs: 12, md: 4 },
	calendarColumns: { xs: 12, md: 4 },
	sidebarColumns: { xs: 12, md: 4 }
};
export const STATE_GRID_OPEN_SIDEBAR = {
	spanColumns: { xs: 12, md: 3 },
	calendarColumns: { xs: 12, md: 4 },
	sidebarColumns: { xs: 12, md: 5 }
};
