import { makeStyles } from '@material-ui/styles';

const TOPBAR_HEIGHT = 65;
const TEXT_MESSAGE_FIELD_HEIGHT = 65;
export const useStyles = makeStyles(() => ({
	root: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		height: `calc(100% - ${TOPBAR_HEIGHT + TEXT_MESSAGE_FIELD_HEIGHT}px)`
	}
}));
