import { DATE_FORMAT } from 'helpers/variables';

const {
	PHP: { YEAR, YEAR_MONTH, YEAR_MONTH_DAY },
	CONDITIONS_TABLE: { MONTH_YEAR, DAY_MONTH_YEAR }
} = DATE_FORMAT;

export const getDateFormat = (format) => {
	switch (format) {
		case YEAR_MONTH_DAY:
			return DAY_MONTH_YEAR;
		case YEAR_MONTH:
			return MONTH_YEAR;
		default:
			return YEAR;
	}
};
