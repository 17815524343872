import { useTranslations } from 'components/utilities';
import { string } from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';

const HelmetTags = ({ title }) => {
	const { t } = useTranslations();
	return (
		<Helmet>
			<title>{t(title)}</title>
		</Helmet>
	);
};

HelmetTags.propTypes = {
	title: string.isRequired
};

export default HelmetTags;
