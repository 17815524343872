import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	iconWrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		width: '100%'
	},
	icon: {
		width: 20,
		'& path': {
			fill: ({ type }) =>
				theme.palette.medication[type] || theme.palette.primary.main
		}
	}
}));
