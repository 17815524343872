import { HelmetTags } from 'components/elements';
import React from 'react';
import { StaticContext } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import {
	ExaminationDetails,
	TrendChart
} from 'views/PatientsDetails/views/ExaminationsV2';

import { PATIENTS_DETAILS_PATHS } from '../paths';

// some workaround to below ts error:
// Type '{ history: History<unknown>; location: Location<unknown>; match: match<{ [x: string]: string | undefined; }>; staticContext?: StaticContext | undefined; }'
// has no properties in common with type 'IntrinsicAttributes'
type Cmp = (
	props: RouteComponentProps<
		{
			[x: string]: string | undefined;
		},
		StaticContext,
		unknown
	>
) => JSX.Element;

export const EXAMINATIONS_V2_ROUTES = [
	{
		path: PATIENTS_DETAILS_PATHS.EXAMINATIONS.TREND_CHART,
		pathBase: PATIENTS_DETAILS_PATHS.EXAMINATIONS.ROOT,
		component: TrendChart as Cmp,
		helmet: <HelmetTags title="pages.examinations.trend" />
	},
	{
		path: PATIENTS_DETAILS_PATHS.EXAMINATIONS.PREVIEW,
		pathBase: PATIENTS_DETAILS_PATHS.EXAMINATIONS.ROOT,
		component: ExaminationDetails as Cmp,
		helmet: <HelmetTags title="pages.examinations.title" />
	}
];
