import { default as auth } from './auth';
import { default as countryCode } from './countryCode';
import { default as jwt } from './jwt';
import { default as language } from './language';
import { releaseNote } from './releaseNote';
import { report } from './report';

export const Cookies = {
	jwt,
	language,
	auth,
	countryCode,
	report,
	releaseNote
};
