import { Card, CardContent } from '@material-ui/core';
import { arrayOf, node, oneOfType, string } from 'prop-types';
import React from 'react';

import { Form } from './Form';
import { useStyles } from './styles';
import Top from './Top';
import useItem from './useItem';

const Sidebar = ({ title, children, ...rest }) => {
	const classes = useStyles();

	const { handleCancel } = useItem();

	return (
		<Card className={classes.card}>
			<CardContent>
				<Top handleCancel={handleCancel} title={title} />
				<Form handleCancel={handleCancel} {...rest}>
					{children}
				</Form>
			</CardContent>
		</Card>
	);
};
Sidebar.propTypes = {
	title: string.isRequired,
	children: oneOfType([arrayOf(node), node]).isRequired
};

export default Sidebar;
