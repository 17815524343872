import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	typography: {
		fontSize: '16px',
		lineHeight: '16px',
		fontWeight: 500
	},
	divider: {
		margin: '15px 0'
	}
}));
