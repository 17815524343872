import { useSnackbar } from 'notistack';
import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { onFirebaseMessageListener } from 'utilities/firebase';
import {
	FIREBASE_MESSAGING_TYPES,
	FirebaseMessageModel
} from 'utilities/firebase/types';
import { PubSub } from 'utilities/PubSub';

import { notificationsDirector } from '../notificationsDirector';
import { useFirebaseMessagingInitialization } from './useFirebaseMessagingInitialization';

export const useFirebaseMessagingHandler = () => {
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();

	const unsubscribeMessageListener = useRef<() => void>();

	useFirebaseMessagingInitialization();

	onFirebaseMessageListener((message) => {
		switch (message.data.type) {
			case FIREBASE_MESSAGING_TYPES.CHAT_MESSAGE:
				notificationsDirector(enqueueSnackbar).chatMessage({
					firebaseMessage: message,
					onActionClick: () => {
						history.push(
							message.data.panelTargetUrl.split('com')[1]
						);
					}
				});
				break;
			case FIREBASE_MESSAGING_TYPES.SUBSCRIPTION_UPCOMING_EXPIRATION:
				notificationsDirector(enqueueSnackbar).subscriptionExpiration({
					firebaseMessage: message
				});
				break;

			case FIREBASE_MESSAGING_TYPES.INVITATION_ACCEPTED_BY_PATIENT:
			case FIREBASE_MESSAGING_TYPES.INVITATION_DECLINED_BY_PATIENT:
			case FIREBASE_MESSAGING_TYPES.SHARING_CANCELLED_BY_PATIENT:
				const messageData =
					message.data satisfies FirebaseMessageModel['data'];
				PubSub.firebaseMessaging.publish(message);
				notificationsDirector(enqueueSnackbar).patientInvitation({
					firebaseMessage: message,
					onActionClick: () => {
						history.push(
							messageData.panelTargetUrl.split('com')[1]
						);
					}
				});
				break;
		}
	}).then((unsubscribe) => {
		unsubscribeMessageListener.current = unsubscribe;
	});

	React.useEffect(() => {
		return () => {
			unsubscribeMessageListener.current &&
				unsubscribeMessageListener.current();
		};
	}, []);
};
