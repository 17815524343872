import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	card: {
		boxShadow: '0px 3px 6px #00000029'
	},
	container: {
		'& > div': {
			paddingBottom: 0,
			paddingTop: 0
		}
	}
}));
