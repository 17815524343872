import { DATEPICKER_VIEWS } from 'helpers';

const DATE_FORMATS = {
	DAY_MONTH_YEAR: 'dd MMM yyyy',
	MONTH_YEAR: 'MMM yyyy'
};

export const getDatepickerSettings = (isMonthView) => {
	const { YEAR, MONTH, DATE } = DATEPICKER_VIEWS;

	let openTo = DATE;
	let views = [DATE];
	let format = DATE_FORMATS.DAY_MONTH_YEAR;

	if (isMonthView) {
		openTo = MONTH;
		views = [YEAR, MONTH];
		format = DATE_FORMATS.MONTH_YEAR;
	}

	return { openTo, views, format };
};
