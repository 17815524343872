import { makeStyles } from '@material-ui/styles';
import theme from 'theme';
import palette from 'theme/palette';

import { Cursor } from './AttemptNumber';

export const useStyles = makeStyles<
	typeof theme,
	{
		isBest: boolean;
		isActive: boolean;
		cursor: Cursor;
		disableTransition: boolean;
		color: string;
	}
>(() => ({
	attemptNumber: {
		position: 'relative',
		borderRadius: '50%',
		height: 25,
		width: 25,
		border: ({ isBest, isActive }) => {
			if (isBest || isActive) return 'none';
			return `1px solid ${palette.border.light}`;
		},
		backgroundColor: ({ isBest, isActive, color }) => {
			if (isBest) return palette.report.green.main;
			if (isActive) return color ? color : palette.report.blue.dark;
			return 'transparent';
		},
		cursor: ({ cursor }) => cursor,
		transition: ({ disableTransition }) =>
			disableTransition ? '' : 'background-color 100ms ease-in-out',
		'&:hover': {
			backgroundColor: ({ isBest, isActive, color }) => {
				if (isBest) return palette.report.green.dark;
				if (isActive) return color ? color : palette.report.blue.dark;
				return palette.border.light;
			}
		}
	},
	label: ({ isBest, isActive }) => ({
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		color: isBest || isActive ? '#FFFFFF' : palette.gray.medium,
		paddingTop: 1,
		'-webkit-user-select': 'none',
		'-ms-user-select': 'none',
		'user-select': 'none'
	})
}));
