import { useTranslations } from 'components/utilities';
import { useCountries, useGenders } from 'queries';
import React from 'react';

import { isFieldQueryEnabled } from './helpers';

const useBasicInformation = ({
	fieldNames,
	basicFields,
	isGendersFiltered
}) => {
	const { COUNTRY_CODE, PREFIX, GENDER } = fieldNames;
	const { t } = useTranslations();

	const {
		isLoading: isCountriesLoading,
		isError: isCountriesError,
		data: countries,
		apiError: countryError
	} = useCountries({
		enabled: isFieldQueryEnabled(basicFields, fieldNames.COUNTRY_CODE)
	});

	const {
		isLoading: isGendersLoading,
		isError: isGendersError,
		data: genders,
		apiError: gendersError
	} = useGenders({}, { isGendersFiltered });

	const dataSets = React.useMemo(
		() => ({
			[PREFIX]: countries,
			[COUNTRY_CODE]: countries,
			[GENDER]: genders
		}),
		//eslint-disable-next-line
		[JSON.stringify(countries), JSON.stringify(genders)]
	);

	const isDataLoading = isCountriesLoading || isGendersLoading;
	const isDataError = isCountriesError || isGendersError;
	const errorMessage =
		countryError ||
		gendersError ||
		t('global.unknown_error_has_been_occurred');

	return {
		isDataLoading,
		isDataError,
		errorMessage,
		dataSets,
		locationInitialValues: {}
	};
};

export default useBasicInformation;
