import theme from 'theme';

export class Point {
	constructor(pointData, color) {
		this.point = pointData;
		this.color = color;
	}

	convertMarker = () => {
		const fillColor = this.point.marker.isAbovePersonalBestReferenceValue
			? this.color
			: theme.palette.parameters.results.error;
		this.point.marker.fillColor = fillColor;
	};
}
