import { Box } from '@material-ui/core';
import { arrayOf, node, object, oneOfType, string } from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import {
	generatePath,
	NavLink,
	useLocation,
	useParams
} from 'react-router-dom';
import { setPrevLocation } from 'store/actions/location';

// eslint-disable-next-line
const RouterNavLink = React.forwardRef(
	({ style, children, pathBase, ...rest }, ref) => {
		const params = useParams();
		const location = useLocation();
		const dispatch = useDispatch();

		return (
			<Box ref={ref} style={style} width={'100%'}>
				<NavLink
					{...rest}
					onClick={() => {
						setPrevLocation(
							pathBase
								? generatePath(pathBase, params)
								: location.pathname
						)(dispatch);
					}}
					to={{
						pathname: rest.to,
						state: {
							prevLocation: {
								...location,
								pathname: pathBase
									? generatePath(pathBase, params)
									: location.pathname
							}
						}
					}}
				>
					{children}
				</NavLink>
			</Box>
		);
	}
);

RouterNavLink.propTypes = {
	to: string,
	style: object,
	pathBase: string,
	children: oneOfType([arrayOf(node), node])
};

export default RouterNavLink;
