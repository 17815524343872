import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		maxWidth: 400,
		'& > div': {
			width: '100%'
		}
	}
}));
