import { PANEL_ROLES } from 'helpers/roles';
import jwt_decode from 'jwt-decode';
import React from 'react';
import { Cookies } from 'utilities';

const { doctor } = PANEL_ROLES;

const DEFAULT_AVAILABLE_USER_ROLES = [doctor];

type RolesArr = Array<ValuesOf<typeof PANEL_ROLES>>;

export const useUserRole = (
	availableRoles: RolesArr = DEFAULT_AVAILABLE_USER_ROLES
) => {
	const validateCurrentUserRole = React.useCallback<
		(roles?: RolesArr) => boolean
	>(
		(roles = availableRoles) => {
			const jwt = Cookies.jwt.get();
			const jwtDecodedData = jwt_decode<TJWTDecodedData>(jwt);

			return roles.includes(jwtDecodedData.user_type);
		},
		[Cookies.jwt.get()]
	);

	const validateRoleComponent = React.useCallback<
		(Component: () => JSX.Element) => JSX.Element
	>(
		(Component) => {
			if (validateCurrentUserRole()) {
				return <Component />;
			}
			return <></>;
		},
		[Cookies.jwt.get()]
	);

	return { validateCurrentUserRole, validateRoleComponent };
};
