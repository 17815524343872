import { makeStyles } from '@material-ui/styles';
import { validateColor } from 'helpers/examinations/colors';

const CELL_PADDING_RIGHT = 5;
export const useStyles = makeStyles((theme) => ({
	root: {
		flex: ({ fullWidth }) => fullWidth && 1
	},
	label: {
		color: '#000000CB',
		fontSize: 11,
		fontWeight: 400,
		lineHeight: '12.2px',
		textAlign: 'center'
	},
	content: ({ color, isScore, cellWidthMultiplier, isValueColoured }) => {
		const scoreBackground = isScore
			? 'white'
			: theme.palette.parameters.results[validateColor(color)];
		const valueBackground = isValueColoured
			? 'transparent'
			: scoreBackground;
		return {
			background: valueBackground,
			borderRadius: 10,
			border: isScore && `1px solid ${theme.palette.gray.disabled}`,
			minHeight: 32,
			width: cellWidth({ isScore, cellWidthMultiplier }),
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center'
		};
	},
	result: ({ color, isScore, isValueColoured }) => {
		const scoreColor = isScore ? theme.palette.black : theme.palette.white;
		const resultValueColor = isValueColoured
			? theme.palette.parameters.results[validateColor(color)]
			: scoreColor;

		return {
			textAlign: 'center',
			fontWeight: isScore ? 500 : 600,
			fontSize: '16px',
			lineHeight: '18px',
			color: resultValueColor
		};
	},
	unit: {
		paddingTop: 5
	},
	resultHeader: {
		marginBottom: 4
	}
}));

const cellWidth = ({ isScore, cellWidthMultiplier }) => {
	const defaultWidth = isScore ? 34 : 50;
	if (cellWidthMultiplier === 1) {
		return defaultWidth;
	}

	return (
		defaultWidth * cellWidthMultiplier +
		(cellWidthMultiplier - 1) * CELL_PADDING_RIGHT
	);
};
