import { useMutation } from 'components/utilities';
import { QUERY_APPOINTMENTS_LIST } from 'helpers';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { AppointmentsRepository } from './repository';
import { AppointmentsServices } from './services';

const useDeleteAppointment = (
	options = {},
	{ appointmentsDateRange, getCalendarApi } = {}
) => {
	const cache = useQueryClient();
	const { appointmentId } = useParams();

	const mutation = useMutation(AppointmentsRepository.delete, {
		...options,
		onSuccess: handleSuccess
	});

	function handleSuccess(data) {
		const convertedDateRanges = AppointmentsServices.convertRangeDates(
			appointmentsDateRange
		);

		AppointmentsServices.deleteAppointmentFromList(
			cache,
			appointmentId,
			convertedDateRanges,
			getCalendarApi
		);
		cache.invalidateQueries([QUERY_APPOINTMENTS_LIST, convertedDateRanges]);
		options?.onSuccess?.(data);
	}

	return mutation;
};

export default useDeleteAppointment;
