import { Grid, MenuItem } from '@material-ui/core';
import { useTranslations } from 'components/utilities';
import { array, bool, func, number, oneOfType, string } from 'prop-types';
import React from 'react';
import { Field, useForm, useFormState } from 'react-final-form';

import { Alert } from '../../Alerts';
import { LoaderSpinner } from '../../Loader';
import { RadioFields } from './components';
import { getOptions, getValue } from './helpers';
import { ADDITIONAL_FIELDS } from './helpers/fields';
import { getFieldRegex } from './helpers/regex';
import { useFetchDictionaries, usePatientHeightWeight } from './hooks';

const AdditionalInformations = ({
	submitError,
	isSubmitError,
	actionButton: ActionButtons,
	height = 559
}) => {
	const { t } = useTranslations();
	const { submitting, values } = useFormState();
	const { change } = useForm();

	const { patientSmoker, ...rest } = values;
	usePatientHeightWeight(rest);
	const { isLoading, resources } = useFetchDictionaries();

	const renderFields = ({
		/* eslint-disable */
		type,
		name,
		label,
		grid,
		disabled,
		onMenuItemClick,
		...rest
		/* eslint-enable */
	}) => {
		switch (type) {
			case 'select':
				const options = getOptions({
					name,
					resources
				});
				return (
					<Grid key={name} item {...grid}>
						<Field
							fullWidth
							type={type}
							label={t(label)}
							name={name}
							variant="outlined"
							disabled={disabled || submitting}
							{...rest}
						>
							{Object.values(options).map((item) => (
								<MenuItem
									onClick={() =>
										onMenuItemClick?.({
											changeFormValue: change,
											values,
											newUnit: item
										})
									}
									key={item}
									value={item}
								>
									{getValue({ item, name, t })}
								</MenuItem>
							))}
						</Field>
					</Grid>
				);
			default:
				return (
					<Grid key={name} item {...grid}>
						<Field
							fullWidth
							label={t(label)}
							type={type}
							name={name}
							variant="outlined"
							autoComplete="false"
							disabled={disabled || submitting}
							{...rest}
							regex={getFieldRegex(rest.regex, name, values)}
						/>
					</Grid>
				);
		}
	};

	if (isLoading)
		return (
			<LoaderSpinner
				rootSize="medium"
				size={40}
				minHeight={height}
				width={536}
			/>
		);

	return (
		<>
			{isSubmitError && (
				<Alert
					message={submitError}
					apiFieldErrorTranslationKeyPrefix={'patients.form'}
				/>
			)}
			{ADDITIONAL_FIELDS.map(renderFields)}
			<RadioFields submitting={submitting} smoker={patientSmoker} />
			<ActionButtons />
		</>
	);
};

AdditionalInformations.propTypes = {
	submitError: array,
	isSubmitError: bool,
	actionButton: func.isRequired,
	height: oneOfType([number, string])
};

export default AdditionalInformations;
