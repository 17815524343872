import { Box } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import { useStyles } from './styles';

export type ContainerProps = {
	children: CmpChildren;
	className?: string;
};

export const Container = ({ children, className = '' }: ContainerProps) => {
	const classes = useStyles();
	return <Box className={clsx([classes.root, className])}>{children}</Box>;
};
