import { Box } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import clsx from 'clsx';
import { useTranslations } from 'components/utilities';
import { bool, func, object, string } from 'prop-types';
import React from 'react';

import { useStyles } from './styles';
import { Validator } from './Validator';

const KeyboardDatePickerFieldAdapter = ({
	input,
	meta,
	classNameButton = '',
	classNameInput = '',
	helperText = '',
	onChange,
	labelProps = {},
	...rest
}) => {
	const classes = useStyles();
	const { t } = useTranslations();

	const date =
		typeof input.value === 'string' && input.value.includes('/')
			? convertInputDate(input.value)
			: input.value;

	const validator = new Validator(
		rest?.disablePast,
		rest?.disableFuture,
		date
	);
	const isError =
		meta.touched && (meta.error || meta.submitError || validator.isError())
			? true
			: false;

	const errorText = isError
		? t(meta.error) || meta.submitError || t(validator.getErrorMessage())
		: '';

	const convertInputDate = (value) => {
		const [day, month, year] = value.split('/');
		const newDate = new Date(year, month - 1, day);
		return newDate;
	};

	const handleOnChange = (e) => {
		input.onChange(e);
		onChange && onChange(e);
		return e;
	};

	return (
		<Box className={clsx([classes.root, classNameButton])}>
			<KeyboardDatePicker
				{...input}
				{...rest}
				{...labelProps}
				onChange={handleOnChange}
				value={date || null}
				format="dd/MM/yyyy"
				KeyboardButtonProps={{
					'aria-label': 'change date'
				}}
				InputProps={{
					className: clsx([classes.textField, classNameInput])
				}}
				error={isError}
				helperText={errorText || helperText}
			/>
		</Box>
	);
};

KeyboardDatePickerFieldAdapter.propTypes = {
	input: object.isRequired,
	meta: object.isRequired,
	classNameButton: string,
	classNameInput: string,
	helperText: string,
	isDisabled: bool,
	onChange: func,
	labelProps: object
};

export default KeyboardDatePickerFieldAdapter;
