import { Box, CircularProgress } from '@material-ui/core';
import { Alert } from 'components/elements';
import { array, bool, oneOfType, string } from 'prop-types';
import React from 'react';

import { useStyles } from './styles';

const TableIdle = ({
	isMutateError,
	isSuccess,
	mutateError = '',
	data = []
}) => {
	const classes = useStyles();
	return (
		<>
			{isMutateError && <Alert message={mutateError} />}

			{!isSuccess && !isMutateError && data?.length === 0 && (
				<Box className={classes.loader}>
					<CircularProgress size={70} color="primary" />
				</Box>
			)}
		</>
	);
};

TableIdle.propTypes = {
	isMutateError: bool.isRequired,
	isSuccess: bool.isRequired,
	mutateError: oneOfType([array, string]),
	data: array
};

export default TableIdle;
