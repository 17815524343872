import {
	DownloadReportRequestModel,
	ReportNoteSchema
} from 'models/Report/DownloadReport';

type Values = { reportNote: DownloadReportRequestModel['reportNote'] };

type Errors = { [K in keyof Values]: string };

export const validation = (values: Values) => {
	const errors: Errors = {} as Errors;

	try {
		ReportNoteSchema.parse(values.reportNote);
		return errors;
	} catch (err: AnyType) {
		return { reportNote: err.formErrors.formErrors[0] };
	}
};
