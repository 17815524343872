import {
	UserSettingModel,
	UserSettingName,
	UserSettingValue
} from 'models/Settings/Settings';

type ReplaceValueByIdArgs = {
	settingId: UserSettingModel['id'];
	value: UserSettingModel['value'];
};

export interface ISettings {
	getSettings: () => UserSettingModel[];
	getPanelLanguage: () => UserSettingValue;
	findByName: (settingName: UserSettingName) => UserSettingModel;
	findIndexById: (settingId: UserSettingModel['id']) => number;
	replaceValueById: (args: ReplaceValueByIdArgs) => void;
}

export class Settings implements ISettings {
	private settings: UserSettingModel[];

	constructor(settings: UserSettingModel[]) {
		this.settings = settings;
	}

	getSettings = () => this.settings;

	getPanelLanguage = () => this.findByName('language').value[0];

	findByName = (settingName: UserSettingName): UserSettingModel => {
		const setting = this.settings.find((setting) =>
			setting.name.includes(settingName)
		) as UserSettingModel;
		return setting;
	};

	findIndexById = (settingId: UserSettingModel['id']) =>
		this.settings.findIndex((setting) => setting.id === settingId);

	replaceValueById = ({ settingId, value }: ReplaceValueByIdArgs) => {
		const settingIdToBeReplaced = this.findIndexById(settingId);
		const newSettings = [...this.settings];
		newSettings[settingIdToBeReplaced] = {
			...newSettings[settingIdToBeReplaced],
			value
		};
		this.settings = newSettings;
	};
}
