import { useExaminationsV2Context } from 'components/context/ExaminationsV2';
import {
	BronchodilatorReportModel,
	CalibrationCheckReportModel,
	CommonReportFieldsModel,
	ForcedSpirometryReportModel,
	PeakFlowReportModel,
	PulseOximetryReportModel,
	SlowVitalCapacitySVCReportModel
} from 'models/Report/Reports';
import { useGetCachedExaminationReportData } from 'queries/Examinations/ReportsV2/getter';

export const useGetCachedExaminationDetailsData = <
	TData extends
		| ForcedSpirometryReportModel
		| SlowVitalCapacitySVCReportModel
		| PulseOximetryReportModel
		| PeakFlowReportModel
		| BronchodilatorReportModel
		| CalibrationCheckReportModel
		| CommonReportFieldsModel
>() => {
	const { store: activeExamination } = useExaminationsV2Context(
		(s) => s.activeExamination
	);
	const data = useGetCachedExaminationReportData<TData>({
		examinationId: activeExamination.id,
		examinationType: activeExamination.type
	});

	return data;
};
