import { ID_QUERY_PARAM } from '../../../helpers/api/queryParamKeys';
import { DASH_PATHS } from './dashboard_paths';

const { PATIENTS_DETAILS } = DASH_PATHS;

export const PATIENTS_DETAILS_PATHS = {
	BASIC_DATA: `${PATIENTS_DETAILS}/basic-data`,
	CONDITIONS: {
		ROOT: `${PATIENTS_DETAILS}/conditions`,
		CREATE_CONDITION: `${PATIENTS_DETAILS}/conditions/condition/create`,
		EDIT_CONDITION: `${PATIENTS_DETAILS}/conditions/condition/${ID_QUERY_PARAM}/edit`,
		CREATE_SURGERY: `${PATIENTS_DETAILS}/conditions/surgery/create`,
		EDIT_SURGERY: `${PATIENTS_DETAILS}/conditions/surgery/${ID_QUERY_PARAM}/edit`,
		CREATE_ALLERGY: `${PATIENTS_DETAILS}/conditions/allergy/create`,
		EDIT_ALLERGY: `${PATIENTS_DETAILS}/conditions/allergy/${ID_QUERY_PARAM}/edit`
	},
	FOLDERS: {
		ROOT: `${PATIENTS_DETAILS}/folders`,
		CREATE: `${PATIENTS_DETAILS}/folders/create`,
		EDIT: `${PATIENTS_DETAILS}/folders/${ID_QUERY_PARAM}/edit`
	},
	FILES: {
		ROOT: `${PATIENTS_DETAILS}/folders/:folderId/files`,
		CREATE: `${PATIENTS_DETAILS}/folders/:folderId/files/create`,
		EDIT: `${PATIENTS_DETAILS}/folders/:folderId/files/edit/${ID_QUERY_PARAM}`
	},
	RECOMMENDATIONS: {
		ROOT: `${PATIENTS_DETAILS}/recommendations`
	},
	ACTION_PLAN: {
		ROOT: `${PATIENTS_DETAILS}/action-plan`,
		CHANGE: `${PATIENTS_DETAILS}/action-plan/change`,
		MANAGE: `${PATIENTS_DETAILS}/action-plan/manage`,
		CREATE: `${PATIENTS_DETAILS}/action-plan/create`,
		EDIT: `${PATIENTS_DETAILS}/action-plan/edit/${ID_QUERY_PARAM}`
	},
	EXAMINATIONS: {
		ROOT: `${PATIENTS_DETAILS}/examinations`,
		TREND_CHART: `${PATIENTS_DETAILS}/examinations/trend`,
		PREVIEW: `${PATIENTS_DETAILS}/examinations/preview`
	},
	DRUGS: {
		ROOT: `${PATIENTS_DETAILS}/drugs`,
		ADD: `${PATIENTS_DETAILS}/drugs/add`,
		EDIT: `${PATIENTS_DETAILS}/drugs/edit/${ID_QUERY_PARAM}`
	}
};
