import { useTranslations } from 'components/utilities';
import { EMPTY_VALUE } from 'helpers';
import React from 'react';

import { Typography } from '../../Typography';
import { isValueInvalid } from './helpers';
import { useStyles } from './styles';

export interface Props {
	value: boolean | typeof EMPTY_VALUE | null | undefined;
}

export const StatusCell = ({ value }: Props) => {
	const classes = useStyles({ value });
	const { t } = useTranslations();
	const renderValue = () => {
		if (isValueInvalid(value)) {
			return EMPTY_VALUE;
		}
		return value
			? t('report.calibration_check.status.pass')
			: t('report.calibration_check.status.fail');
	};

	return (
		<Typography className={classes.statusValue}>{renderValue()}</Typography>
	);
};
