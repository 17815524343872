import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		padding: '0 30px',
		paddingTop: 30
	},
	button: {
		textTransform: 'none'
	}
}));
