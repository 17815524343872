import { makeStyles } from '@material-ui/styles';
import theme from 'theme';

type Props = {
	orientation: 'row' | 'column';
};
export const useStyles = makeStyles<typeof theme, Props>(() => ({
	root: {},
	label: {
		width: 70,
		margin: '0 5px 0 10px'
	},
	fieldsWrapper: {
		width: '100%',
		display: 'flex',
		flexDirection: ({ orientation }) => orientation,
		flexWrap: 'wrap',
		'& > div': {
			marginBottom: 2,
			flexGrow: 1,
			width: '33.33333%'
		},
		'& > :nth-child(3n+1)': {
			'& > :first-child': { marginLeft: 0 }
		},
		'& > :nth-child(3n+2)': {
			'& .parameterLabel': {
				width: 100
			}
		}
	}
}));
