import { signNotAuthOut } from 'store/actions';
import Store from 'store/store';

export const checkErrorStatus = (error) => {
	const { response } = error;
	if (response?.status === 401) {
		const { auth } = Store.getState();
		const authenticate = !!auth.isAuthenticated;
		// Dispatch an signNotAuthOut action in auth folder
		Store.dispatch(signNotAuthOut(authenticate));

		return Promise.reject(error);
	}
	return Promise.reject(error);
};

export const createAxiosInterceptors = (axios) => {
	axios?.interceptors?.response?.use(
		function (response) {
			return response;
		},
		function (error) {
			return checkErrorStatus(error);
		}
	);
	return axios;
};
