import { CALENDAR_VIEWS_VARIANTS } from 'helpers';
import { produce } from 'immer';

import * as types from './types';

const reducer = (state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.SET_APPOINTMENTS_DATE_RANGE:
				draft.appointmentsDateRange = action.payload;
				break;

			case types.SET_CALENDAR_VIEW:
				draft.isMonthView =
					action.payload === CALENDAR_VIEWS_VARIANTS.MONTH;
				draft.calendarView = action.payload;
				break;

			case types.SET_SELECTED_DATE:
				draft.selectedDate = action.payload;
				break;

			case types.SET_DAY_MAX_EVENTS:
				draft.dayMaxEvents = action.payload;
				break;

			case types.SET_IS_APPOINTMENT_REVIEW:
				draft.isAppointmentReview = action.payload;
				break;

			case types.SET_IS_CREATE_FROM_GRID:
				draft.isCreateFromGrid = action.payload;
				break;

			default:
				return state;
		}
	});

export default reducer;
