import { Box, FormControlLabel, Radio } from '@material-ui/core';
import { useTranslations } from 'components/utilities';
import { array, bool, func, string } from 'prop-types';
import React from 'react';
import { Field, useFormState } from 'react-final-form';

import { useStyles } from './styles';

const RadioFields = ({
	name,
	label,
	component,
	required,
	submitting,
	options
}) => {
	const { t } = useTranslations();
	const classes = useStyles();

	const { values } = useFormState();

	return (
		<Box className={classes.wrapper}>
			<Field
				name={name}
				label={label}
				component={component}
				color="primary"
				required={required}
				disabled={submitting}
				initialVal="F"
				currentValue={values[name]}
				row
			>
				{/* eslint-disable-next-line */}
				{options.map((gender) => (
					<FormControlLabel
						key={gender}
						value={gender}
						control={<Radio color="primary" />}
						label={t(`global.${gender?.toLowerCase()}`)}
					/>
				))}
			</Field>
		</Box>
	);
};

RadioFields.propTypes = {
	name: string.isRequired,
	component: func.isRequired,
	label: string.isRequired,
	submitting: bool.isRequired,
	required: bool.isRequired,
	options: array.isRequired
};

export default RadioFields;
