import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useAppVersion, useCopyright } from 'components/utilities';
import React from 'react';

import { useStyles } from './styles';

const Copyrights = () => {
	const { version } = useAppVersion();
	const { copyrightText } = useCopyright();
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			{version && (
				<Typography
					variant="body2"
					className={clsx([classes.typography, classes.version])}
				>
					{version}
				</Typography>
			)}
			{copyrightText && (
				<Typography variant="body2" className={classes.typography}>
					{copyrightText}
				</Typography>
			)}
		</Box>
	);
};

export default Copyrights;
