import {
	ContainerWithBorder,
	Title
} from 'components/elements/atoms/ExaminationsV2';
import { ParametersTable } from 'components/elements/organisms/ExaminationsV2';
import React from 'react';

import { useGetCachedSlowVitalCapacitySVCReportData } from '../../useGetCachedSlowVitalCapacitySVCReportData';
import { columns } from './columns';
import { convertBestResultsToColumnsData } from './helpers';

export const BestResults = () => {
	const { examination } = useGetCachedSlowVitalCapacitySVCReportData();
	return (
		<ContainerWithBorder>
			<Title title="report.best_results.title" />
			<ParametersTable
				columns={columns}
				data={convertBestResultsToColumnsData(
					examination?.bestParameters
				)}
			/>
		</ContainerWithBorder>
	);
};
