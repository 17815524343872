import {
	CHART_MEASUREMENT_PARAMETER_OPTION_NAMES,
	getUserUnit
} from 'helpers/charts';
import { ReportParameterModel } from 'models/Report/common';
import {
	SlowVitalCapacitySVCBestParametersModel,
	SlowVitalCapacitySVCMeasurementModel
} from 'models/Report/SlowVitalCapacitySVC';
import { ColumnDataImpl } from 'utilities/Examinations/ExaminationDetails/common';
import { ColumnValues } from 'utilities/Examinations/ExaminationDetails/helpers';

import { SVC_BEST_PARAMETERS_WITH_CREATE_DATE } from '../../helpers';

const PARAMETERS = {
	...SVC_BEST_PARAMETERS_WITH_CREATE_DATE,
	ic: {
		label: CHART_MEASUREMENT_PARAMETER_OPTION_NAMES.ic.label,
		getUserUnit: () => getUserUnit('ic')
	} as const
};
export const convertDataToColumns = (
	data: SlowVitalCapacitySVCMeasurementModel[],
	bestParametersData?: SlowVitalCapacitySVCBestParametersModel
): ColumnValues[] => {
	const columnData = new ColumnDataImpl<
		SlowVitalCapacitySVCMeasurementModel[],
		ColumnValues
	>(PARAMETERS, data);

	columnData.createColumnDataFromAllMeasurements();

	const columnDataFromBests = new ColumnDataImpl<
		SlowVitalCapacitySVCBestParametersModel,
		ReportParameterModel
	>(PARAMETERS, bestParametersData);
	columnDataFromBests.createColumnData();

	const svcWithBestsColumnData = columnData.mergeWithSpecificFields(
		columnDataFromBests.columnData,
		['value', 'predicted']
	);

	return svcWithBestsColumnData;
};
