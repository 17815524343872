import { calculateAge } from 'helpers';
import { numberInRangeRegex, numberRegex } from 'helpers/validation';
import {
	HEIGHT_RANGE,
	SMOKER_OPTIONS_VALUES,
	WEIGHT_RANGE
} from 'helpers/variables';

export const additionalInformationsValidation = (values) => {
	let errors = {};

	const {
		patientDob,
		patientRace,
		patientWeightUnit,
		patientWeight,
		patientHeightUnit,
		patientHeight,
		patientSmoker,
		patientSmokerCigarettes,
		patientSmokerYears,
		patientPassiveSmokerYears,
		exSmokerMonth,
		exSmokerYear
	} = values;

	const date = new Date();
	const currentMonth = date.getMonth() + 1;
	const currentYear = date.getFullYear();
	const patientAge = calculateAge(patientDob);

	if (!patientRace) {
		errors.patientRace = 'patients.form.validation.race';
	}

	if (!patientWeight) {
		errors.patientWeight = 'patients.form.validation.weight_required';
	}

	if (!patientWeightUnit) {
		errors.patientWeightUnit =
			'patients.form.validation.weight_unit_required';
	}

	const patientWeightValidationMessage = validatePatientBodyParameters(
		patientWeight,
		patientWeightUnit,
		WEIGHT_RANGE,
		'patients.form.validation.invalid_weight'
	);
	if (patientWeightValidationMessage) {
		errors.patientWeight = patientWeightValidationMessage;
	}

	if (!patientHeight) {
		errors.patientHeight = 'patients.form.validation.height_required';
	}

	if (!patientHeightUnit) {
		errors.patientHeightUnit =
			'patients.form.validation.height_unit_required';
	}

	const patientHeightValidationMessage = validatePatientBodyParameters(
		patientHeight,
		patientHeightUnit,
		HEIGHT_RANGE,
		'patients.form.validation.invalid_height'
	);
	if (patientHeightValidationMessage) {
		errors.patientHeight = patientHeightValidationMessage;
	}

	switch (patientSmoker) {
		case SMOKER_OPTIONS_VALUES.YES:
			if (!patientSmokerCigarettes) {
				errors.patientSmokerCigarettes =
					'patients.form.validation.smoker_cigarettes';
			} else if (
				!numberInRangeRegex(1, 5, 1).test(
					patientSmokerCigarettes.toString()
				)
			) {
				errors.patientSmokerCigarettes = {
					key: 'patients.form.validation.smoker_cigarettes.range_value',
					replaceKeys: { ['%from']: '1.0', ['%to']: '5.0' }
				};
			}
			if (!patientSmokerYears) {
				errors.patientSmokerYears =
					'patients.form.validation.smoker_years';
			} else if (patientSmokerYears <= 0) {
				errors.patientSmokerYears =
					'global.number.validation.equal_or_less_than_zero';
			} else if (!numberRegex.test(patientSmokerYears)) {
				errors.patientSmokerYears =
					'global.number.validation.should_be_integer';
			} else if (
				!numberInRangeRegex(0, patientAge, 3).test(patientSmokerYears)
			) {
				errors.patientSmokerYears = {
					key: 'patients.form.validation.smoker_years.range_value',
					replaceKeys: { ['%age']: patientAge }
				};
			}
			break;
		case SMOKER_OPTIONS_VALUES.PASSIVE_SMOKER:
			if (!patientPassiveSmokerYears) {
				errors.patientPassiveSmokerYears =
					'patients.form.validation.passive_smoker_years';
			} else if (patientPassiveSmokerYears <= 0) {
				errors.patientPassiveSmokerYears =
					'global.number.validation.equal_or_less_than_zero';
			} else if (!numberRegex.test(patientPassiveSmokerYears)) {
				errors.patientPassiveSmokerYears =
					'global.number.validation.should_be_integer';
			} else if (
				!numberInRangeRegex(0, patientAge, 3).test(
					patientPassiveSmokerYears
				)
			) {
				errors.patientPassiveSmokerYears = {
					key: 'patients.form.validation.passive_smoker_years.range_value',
					replaceKeys: { ['%age']: patientAge }
				};
			}
			break;
		case SMOKER_OPTIONS_VALUES.EX_SMOKER:
			if (!exSmokerYear) {
				errors.exSmokerYear = 'global.validation.year_required';
			}
			if (
				exSmokerMonth &&
				exSmokerYear &&
				Number(currentYear) === Number(exSmokerYear) &&
				Number(currentMonth) < Number(exSmokerMonth)
			) {
				errors.exSmokerMonth =
					'patients.form.validation.ex_smoker.wrong_moth';
			}
			break;
		default:
			break;
	}

	return errors;
};

function validatePatientBodyParameters(
	parameter,
	unit,
	PARAMETER_TYPE,
	errorLabel
) {
	if (!unit) {
		return '';
	}

	if (
		parameter < PARAMETER_TYPE[unit].min ||
		parameter > PARAMETER_TYPE[unit].max
	) {
		return {
			key: errorLabel,
			replaceKeys: {
				['%v1']: ` ${PARAMETER_TYPE[unit].min} ${unit}`,
				['%v2']: ` ${PARAMETER_TYPE[unit].max} ${unit}`
			}
		};
	}
}
