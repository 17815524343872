import { useTodayDate } from 'components/utilities';
import { useForm, useFormState } from 'react-final-form';

const useYearItems = ({ monthFieldName, dayFieldName }) => {
	const { values } = useFormState();
	const { change } = useForm();
	const { currentYear, currentMonth, currentDate } = useTodayDate();
	const years = new Array(51).fill('').map((_, index) => currentYear - index);

	const handleYearChange = ({ target: { value } }) => {
		// check if is current year and future month
		const isMonthFuture =
			value === currentYear &&
			Number(values[monthFieldName]) > currentMonth;

		// check if is current year, current month and future day
		const isDayFuture =
			value === currentYear &&
			Number(values[monthFieldName]) >= currentMonth &&
			Number(values[dayFieldName]) > currentDate;

		if (isDayFuture || isMonthFuture) {
			change(dayFieldName, null);
			isMonthFuture && change(monthFieldName, null);
		}
	};

	return { years, onYearChange: handleYearChange };
};

export default useYearItems;
