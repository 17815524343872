import {
	Box,
	IconButton,
	Typography as MuiTypography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { CloseIcon,KeyboardBackspaceIcon } from 'assets/icons/commonMUIIcons';
import { RouterLink } from 'components/elements';
import { useTranslations } from 'components/utilities';
import { bool, string } from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center'
	},
	title: {
		textTransform: 'none',
		flex: 1,
		marginLeft: theme.spacing(1)
	},
	iconExit: {
		color: 'black'
	}
}));

const Top = ({ isEdit, prevPath }) => {
	const classes = useStyles();
	const { t } = useTranslations();

	return (
		<Box className={classes.root}>
			<IconButton component={RouterLink} to={prevPath} size="small">
				<KeyboardBackspaceIcon
					color="primary"
					className={classes.iconBack}
				/>
			</IconButton>
			<MuiTypography component="p" variant="h4" className={classes.title}>
				{isEdit
					? t('patients.drugs.form.editing_medication')
					: t('patients.drugs.form.adding_medication')}
			</MuiTypography>
			<IconButton component={RouterLink} to={prevPath} size="small">
				<CloseIcon className={classes.iconExit} />
			</IconButton>
		</Box>
	);
};

Top.propTypes = {
	isEdit: bool.isRequired,
	prevPath: string.isRequired
};

export default Top;
