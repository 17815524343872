import { TableRow as MuiTableRow, TableRowProps } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import { useStyles } from './styles';

export interface ITableRowProps extends TableRowProps {
	children: React.ReactNode;
	className?: string;
}
export const TableRow = React.forwardRef(
	(
		{ children, className = '' }: ITableRowProps,
		ref: React.ForwardedRef<HTMLTableRowElement>
	) => {
		const classes = useStyles();
		return (
			<MuiTableRow
				className={clsx([className, classes.row])}
				{...{ ref }}
			>
				{children}
			</MuiTableRow>
		);
	}
);
