import { ID_QUERY_PARAM } from 'helpers/api/queryParamKeys';

import { DASH_PATHS } from './dashboard_paths';

const { SETTINGS } = DASH_PATHS;

export const SETTINGS_PATHS = {
	CLINIC: `${SETTINGS}/clinic`,
	CLINICS: `${SETTINGS}/clinics`,
	CLINICS_CREATE: `${SETTINGS}/clinics/create`,
	MANAGE_ACTION_PLANS: `${SETTINGS}/manage-action-plans`,
	ACTION_PLAN_TEMPLATE_CREATE: `${SETTINGS}/create-action-plan-template`,
	EDIT_ACTION_PLAN: `${SETTINGS}/action-plan/edit/${ID_QUERY_PARAM}`,
	EMPLOYEES: `${SETTINGS}/employees`,
	PREFERENCES: `${SETTINGS}/preferences`,
	MY_PROFILE: `${SETTINGS}/my-profile`,
	RELEASE_NOTES: `${SETTINGS}/release-notes`
};
