import { PATIENTS_DETAILS_PATHS } from 'components/routes/paths';
import { ActiveExaminationBase } from 'models/Examinations/common';
import { generatePath, useHistory } from 'react-router-dom';
import { SessionStorage, useParams } from 'utilities';
import { ACTIVE_EXAMINATIONS_SEARCH_PARAMS } from 'utilities/ReactRouterDomHooks/implementations/examinations/helpers';

import { useExaminationsV2Context } from '../ExaminationsV2Provider';
import { useExaminationsV2RefsContext } from '../ExaminationsV2RefsProvider';
import { useActiveExaminationActions } from './useActiveExaminationActions';

export const useExaminationItemEvents = () => {
	const history = useHistory();
	const params = useParams();

	const { resetExaminationsVirtualList } = useExaminationsV2RefsContext();
	const { store: dataWithoutLastActiveExamination } =
		useExaminationsV2Context((s) => s.examinationsListData);

	const { store: activeExamination } = useExaminationsV2Context(
		(s) => s.activeExamination
	);
	const { setExaminationsListData } = useExaminationsV2Context();

	const {
		removeActiveExaminationIndexFromList,
		addLastActiveExaminationToListData,
		setActiveExaminationWithListItemPositioning
	} = useActiveExaminationActions();

	const examinationItemOnClick = ({ id, type }: ActiveExaminationBase) => {
		const activeExaminationBase = { id, type };
		SessionStorage.examinations.setLastActiveExamination(
			params,
			activeExaminationBase
		);

		const dataWithLastActiveExamination =
			addLastActiveExaminationToListData(
				activeExamination,
				dataWithoutLastActiveExamination
			);
		const newActiveExamination =
			setActiveExaminationWithListItemPositioning({
				examinationsListData: dataWithLastActiveExamination,
				activeExamination: activeExaminationBase,
				scrollToItem: false
			});

		const listDataWithFilteredActiveExamination =
			removeActiveExaminationIndexFromList(
				dataWithLastActiveExamination,
				newActiveExamination
			);

		setExaminationsListData(listDataWithFilteredActiveExamination);
		resetExaminationsVirtualList();

		const path = generatePath(
			PATIENTS_DETAILS_PATHS.EXAMINATIONS.PREVIEW,
			params
		);

		history.push(
			`${path}?${ACTIVE_EXAMINATIONS_SEARCH_PARAMS.ID}=${activeExaminationBase.id}&${ACTIVE_EXAMINATIONS_SEARCH_PARAMS.TYPE}=${activeExaminationBase.type}`
		);
	};

	return { examinationItemOnClick };
};
