import { FormControlLabel, Radio } from '@material-ui/core';
import { RadioFieldAdapter, TextFieldAdapter } from 'components/elements';
import { useTranslations } from 'components/utilities';
import { FIELD_TYPES } from 'helpers';
import { array, string } from 'prop-types';
import React from 'react';
import { Field as FormField, useFormState } from 'react-final-form';

import DateField from './DateField';
import { useStyles } from './styles';

const { DATE, RADIO, DESCRIPTION } = FIELD_TYPES;

const Field = (props) => {
	const { label, type, name, options } = props;
	const { t } = useTranslations();

	const { submitting, values } = useFormState();

	const classes = useStyles();

	const { visible_on_patients_card } = values;

	// eslint-disable-next-line
	const renderField = () => {
		switch (type) {
			case DESCRIPTION:
				return (
					<FormField
						fullWidth
						multiline
						component={TextFieldAdapter}
						type={'text'}
						name={name}
						label={t(label)}
						margin="dense"
						variant="outlined"
						inputProps={{ minRows: 2 }}
						autoComplete="false"
						disabled={submitting}
					/>
				);
			case DATE:
				return <DateField label={label} />;
			case RADIO:
				return (
					<FormField
						component={RadioFieldAdapter}
						classNameLabel={classes.radioLabel}
						name={name}
						label={label}
						margin="dense"
						disabled={submitting}
						options={options}
						initialVal={visible_on_patients_card}
						currentValue={visible_on_patients_card}
						color="primary"
						row
						required
					>
						{options.map(({ value, label }) => (
							<FormControlLabel
								key={value}
								value={value}
								control={<Radio color="primary" />}
								label={t(label)}
							/>
						))}
					</FormField>
				);
			default:
				return (
					<FormField
						fullWidth
						component={TextFieldAdapter}
						type={type}
						name={name}
						label={t(label)}
						margin="dense"
						variant="outlined"
						autoComplete="false"
						disabled={submitting}
						required
					/>
				);
		}
	};

	return renderField();
};

Field.propTypes = {
	label: string,
	type: string.isRequired,
	name: string.isRequired,
	options: array
};

export default Field;
