import { Typography as MuiTypography } from '@material-ui/core';
import { useTranslations } from 'components/utilities';
import { string } from 'prop-types';
import React from 'react';

import { useStyles } from './styles';

const ExaminationType = ({ title = '' }) => {
	const { t } = useTranslations();
	const classes = useStyles();

	return (
		<MuiTypography
			className={classes.type}
			variant="h6"
			component="p"
			data-testid="root"
		>
			{t(title)}
		</MuiTypography>
	);
};

ExaminationType.propTypes = {
	title: string
};

export default ExaminationType;
