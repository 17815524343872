import { ClinicRequestModel } from 'models/Clinic/Clinic';

export const CLINIC_FORM_FIELD_NAMES = {
	IMAGE: 'image',
	NAME: 'name',
	STREET: 'street',
	POSTCODE: 'postcode',
	CITY: 'city',
	COUNTRY: 'country',
	COUNTRY_CODE: 'country',
	PHONE_PREFIX: 'phonePrefix',
	PHONE_NUMBER: 'phoneNumber',
	EMAIL: 'email'
} as const;

export const INITIAL_CLINIC_VALUES: ClinicRequestModel = {
	[CLINIC_FORM_FIELD_NAMES.NAME]: '',
	[CLINIC_FORM_FIELD_NAMES.IMAGE]: null,
	[CLINIC_FORM_FIELD_NAMES.STREET]: null,
	[CLINIC_FORM_FIELD_NAMES.POSTCODE]: null,
	[CLINIC_FORM_FIELD_NAMES.CITY]: null,
	[CLINIC_FORM_FIELD_NAMES.COUNTRY]: null,
	[CLINIC_FORM_FIELD_NAMES.PHONE_PREFIX]: null,
	[CLINIC_FORM_FIELD_NAMES.PHONE_NUMBER]: null,
	[CLINIC_FORM_FIELD_NAMES.EMAIL]: null
};
