import React from 'react';
import { useDispatch } from 'react-redux';
import { signOut } from 'store/actions';

const useToggleMenu = () => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const dispatch = useDispatch();

	const handleOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleSignOut = () => signOut()(dispatch);

	return {
		anchorEl,
		close: handleClose,
		open: handleOpen,
		signOut: handleSignOut
	};
};

export default useToggleMenu;
