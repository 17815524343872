import { ReactComponent as CapsuleIcon } from 'assets/icons/ic_capsule.svg';
import { ReactComponent as PillIcon } from 'assets/icons/ic_pill.svg';
import { ReactComponent as RelieverIcon } from 'assets/icons/ic_rescue2.svg';
import { ReactComponent as InjectionIcon } from 'assets/icons/ic_strzykawka.svg';
import { DRUG_TYPES } from 'helpers/variables';

const { CAPSULE, TABLET, INJECTION, INHALER } = DRUG_TYPES;

export const getDrugTypeIcon = (type) => {
	switch (type) {
		case CAPSULE:
			return CapsuleIcon;
		case TABLET:
			return PillIcon;
		case INJECTION:
			return InjectionIcon;
		case INHALER:
			return RelieverIcon;
		default:
			return null;
	}
};
