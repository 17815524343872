import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		textTransform: 'none',
		marginRight: theme.spacing(1),
		marginBottom: theme.spacing(1),
		border: 'none',
		boxShadow:
			'0px 3px 4px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)',
		color: ({ isChecked }) =>
			isChecked ? theme.palette.white : theme.palette.black,
		fontWeight: 400,
		'&:hover': {
			border: '0px !important'
		}
	},
	icon: {
		maxHeight: 22,
		opacity: ({ isChecked }) => (isChecked ? 1 : 0.6),
		'& path': {
			fill: ({ isChecked, fillIcon }) =>
				fillIcon &&
				(isChecked
					? theme.palette.white
					: fillIcon && theme.palette.black),
			stroke: ({ isChecked, fillIcon }) =>
				!fillIcon && isChecked && theme.palette.white
		}
	},
	outlined: {
		'&:hover': {
			border: 'none'
		}
	},
	wrapper: {
		boxShadow:
			'0px 3px 4px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)',
		borderRadius: '5px',
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: 'rgba(74, 79, 224, 0.04)'
		}
	}
}));
