import { Series, SeriesOptionsType } from 'highcharts';
import React from 'react';
import palette from 'theme/palette';

import { useCopdData } from '../useCopdData';
import { usePrePostContext } from './PrePostProvider';
import { PrePostType } from './types';

type Args = {
	type: PrePostType;
	activeMeasurementIndex: number;
};

type ReturnType = {
	setActiveGraphCurve: (
		newActiveIndex: number,
		prevActiveIndex: number
	) => void;
	showPreviewOfNewActiveGraphCurve: (newActiveIndex: number) => void;
	hidePreviewOfNewActiveGraphCurve: (newActiveIndex: number) => void;
};

export const usePrePostContextActions = ({
	type,
	activeMeasurementIndex
}: Args): ReturnType => {
	const { flowVolumeGraphRef, volumeTimeGraphRef } = usePrePostContext();
	const examinationData = useCopdData();

	const setActiveGraphCurve = (
		newActiveIndex: number,
		prevActiveIndex: number
	) => {
		if (newActiveIndex !== prevActiveIndex) {
			[flowVolumeGraphRef, volumeTimeGraphRef].forEach((graphRef) => {
				graphRef.current?.chart.series?.[
					indexBase + newActiveIndex
				].show();
				graphRef.current?.chart.series?.[
					indexBase + prevActiveIndex
				].hide();
			});
		}
	};

	const indexBase =
		type === 'post'
			? examinationData?.pre?.measurements?.length
				? examinationData?.pre?.measurements?.length
				: 0
			: 0;

	const validateMouseEnterLeave = (newActiveIndex: number): boolean => {
		if (activeMeasurementIndex !== newActiveIndex) {
			return true;
		}
		return false;
	};

	const previewOfNewActiveGraphCurve = (
		newActiveIndex: number,
		styles: SeriesOptionsType,
		callback?: (s: Series) => void
	) => {
		if (!validateMouseEnterLeave(newActiveIndex)) {
			return;
		}

		[flowVolumeGraphRef, volumeTimeGraphRef].forEach((graphRef) => {
			const serie =
				graphRef.current?.chart.series?.[indexBase + newActiveIndex];
			serie?.update(styles);

			if (serie && callback) callback(serie);
		});
	};

	const showPreviewOfNewActiveGraphCurve: ReturnType['showPreviewOfNewActiveGraphCurve'] =
		React.useCallback(
			(newActiveIndex) => {
				previewOfNewActiveGraphCurve(
					newActiveIndex,
					{
						type: 'spline',
						lineWidth: 1,
						color: palette.gray.dark
					},
					(serie) => {
						serie.show();
					}
				);
			},
			[activeMeasurementIndex]
		);

	const hidePreviewOfNewActiveGraphCurve: ReturnType['hidePreviewOfNewActiveGraphCurve'] =
		React.useCallback(
			(newActiveIndex) => {
				previewOfNewActiveGraphCurve(
					newActiveIndex,
					{
						type: 'spline',
						lineWidth: 2,
						color: palette.blue.main
					},
					(serie) => {
						serie.hide();
					}
				);
			},
			[activeMeasurementIndex]
		);

	return {
		setActiveGraphCurve,
		showPreviewOfNewActiveGraphCurve,
		hidePreviewOfNewActiveGraphCurve
	};
};
