import { Typography } from '@material-ui/core';
import { ChevronLeftIcon } from 'assets/icons/commonMUIIcons';
import clsx from 'clsx';
import React from 'react';

import useTranslations from '../../../utilities/hooks/translations/useTranslations';
import { useStyles } from './styles';
import { IChipProps } from './types';

export const CollapsedContent = React.forwardRef<
	HTMLDivElement,
	Pick<IChipProps, 'collapsedLabel' | 'backgroundColor' | 'size'>
>(({ collapsedLabel, size, backgroundColor }, ref) => {
	const classes = useStyles({ size, backgroundColor });
	const { t } = useTranslations();

	return (
		<div
			className={clsx(['collapsedChip', classes.collapsedChip])}
			ref={ref}
		>
			<ChevronLeftIcon className={classes.icon} />
			<Typography
				className={clsx([classes.font, classes.collapsedChipLabel])}
			>
				{t(collapsedLabel)}
			</Typography>
		</div>
	);
});
