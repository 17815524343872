import { useMutation } from 'components/utilities';
import _ from 'lodash';
import React from 'react';

import { PatientsRepository } from './repository';

const useValidatePatientEmail = (options = {}, { delay = 1000 } = {}) => {
	const [errorMessage, setErrorMessage] = React.useState('');
	const [isLoading, setIsLoading] = React.useState(false);

	const mutation = useMutation(PatientsRepository.validateEmail, {
		...options,
		onError: handleError,
		onSettled: handleSettled
	});

	const delayedMutate = React.useRef(
		_.debounce((value) => mutation.mutate(value), delay)
	).current;

	function handleError(error) {
		setErrorMessage(error);
		options?.onError?.(error);
	}

	function handleSettled(res) {
		setIsLoading(false);
		options?.onSettled?.(res);
	}

	function handleDelayedMutate(data) {
		setIsLoading(true);
		delayedMutate(data);
	}

	const isError =
		errorMessage &&
		Array.isArray(errorMessage) &&
		errorMessage.find(({ name }) => name === 'email') === -1;

	return {
		...mutation,
		delayedMutate: handleDelayedMutate,
		isDelayedLoading: isLoading,
		isError
	};
};

export default useValidatePatientEmail;
