import { Box, Typography as MuiTypography } from '@material-ui/core';
import { useTranslations } from 'components/utilities';
import React from 'react';

import { useStyles } from './styles';

type Props = {
	error: string;
};
export const ErrorLabel = ({ error }: Props) => {
	const classes = useStyles();
	const { t } = useTranslations();

	const errorText = t(
		`patients.examinations.details.spirometry.errors.${error?.toLowerCase()}`
	);

	if (!errorText) return <Box />;
	return (
		errorText && (
			<Box>
				<MuiTypography variant="body1" className={classes.typography}>
					{errorText}
				</MuiTypography>
			</Box>
		)
	);
};
