import React from 'react';

const useMenuRPC = () => {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	return {
		anchorEl,
		close: handleClose,
		open: handleClick
	};
};

export default useMenuRPC;
