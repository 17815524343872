import { Grid } from '@material-ui/core';
import { CardTopBar } from 'components/elements';
import { arrayOf, node, oneOfType } from 'prop-types';
import React from 'react';
import { AutoSizer } from 'react-virtualized';

import { useStyles } from '../../../styles';
import { useStyles as useLocalStyles } from './styles';

const AppointmentFormWrapper = ({ children }) => {
	const classes = useStyles();
	const localClasses = useLocalStyles();
	return (
		<Grid item xs={4} lg={3} className={localClasses.root}>
			<AutoSizer disableWidth>
				{({ height }) => (
					<CardTopBar
						className={classes.card}
						cardContentClassName={localClasses.cardContent}
						style={{ height }}
					>
						{children}
					</CardTopBar>
				)}
			</AutoSizer>
		</Grid>
	);
};

AppointmentFormWrapper.propTypes = {
	children: oneOfType([arrayOf(node), node])
};

export default AppointmentFormWrapper;
