import { CancelToken } from 'axios';
import { apiFormErrorsHandler } from 'helpers';
import React from 'react';
import {
	useMutation as useRqMutation,
	UseMutationOptions,
	UseMutationResult as RQUseMutationResult
} from 'react-query';

import { useTranslations } from '../translations';
import { getApiErrorMessage } from './helpers';
import useRequestCancellation, {
	RequestCancellation
} from './useRequestCancellation';

type TFetchResources<TData, TVariables> = (
	props: TVariables,
	token: CancelToken
) => Promise<TData>;

export type UseMutationResult<TData, TError, TVariables, TContext> =
	RQUseMutationResult<TData, TError, TVariables, TContext> & {
		apiError: ApiErrorState;
		sourceRef: RequestCancellation['sourceRef'];
	};

export type MutationOptions<
	TData = unknown,
	TError extends TMyError = TMyError,
	TVariables = void,
	TContext = TData
> = Omit<
	UseMutationOptions<TData, TError, TVariables, TContext>,
	'mutationKey' | 'mutationFn'
> & {
	onError?: (
		errorMessage: string | null | QueryApiError[],
		error: TError,
		variables: TVariables,
		context: TContext | undefined
	) => Promise<unknown> | void;
};
const useMutation = <
	TData = unknown,
	TError extends TMyError = TMyError,
	TVariables = void,
	TContext = TData
>(
	fetchResources: TFetchResources<TData, TVariables>,
	options?: MutationOptions<TData, TError, TVariables, TContext>
): UseMutationResult<TData, TError, TVariables, TContext> => {
	const [apiError, setApiError] = React.useState<ApiErrorState>(null);

	const { tNoParser } = useTranslations();

	const { token, sourceRef } = useRequestCancellation();

	const props = useRqMutation<TData, TError, TVariables, TContext>(
		(payload) => fetchResources(payload, token),
		{ ...options, onError: handleError, onMutate: handleMutate }
	);

	function handleMutate(
		variables: TVariables
	): TContext | Promise<TContext | undefined> | undefined {
		setApiError(null);
		if (options?.onMutate) {
			return options?.onMutate(variables);
		}
		return undefined;
	}

	function handleError(errors, newData, context) {
		const errorData = errors?.response?.data;
		let errorMessage;
		if (typeof errorData?.message === 'string') {
			errorMessage = getApiErrorMessage(errorData, tNoParser);
			setApiError(errorMessage);
		} else if (
			typeof errors?.code === 'string' &&
			!errorData?.validator?.errors
		) {
			errorMessage = getApiErrorMessage(errors, tNoParser);
			setApiError(errorMessage);
		} else {
			errorMessage = apiFormErrorsHandler(errors, setApiError);
		}
		options?.onError?.(errorMessage, errors, newData, context);
	}

	function resetMutationState() {
		setApiError(null);
		props?.reset?.();
	}

	return { ...props, apiError, sourceRef, reset: resetMutationState };
};

export default useMutation;
