import { useSnackbar } from 'notistack';
import React from 'react';
import { Cookies } from 'utilities';

import { notificationsDirector } from '../notificationsDirector';

export const useReleaseNoteSnackbar = () => {
	const { enqueueSnackbar } = useSnackbar();
	React.useEffect(() => {
		if (!Cookies.releaseNote.isAccepted()) {
			notificationsDirector(enqueueSnackbar).releaseNote();
		}
	}, []);
};
