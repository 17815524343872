import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		height: 25,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	label: {
		marginRight: 2
	}
}));
