import { createColumnHelper } from '@tanstack/react-table';
import { PatientListItemModel } from 'models/Patient/PatientListItem';
import React from 'react';

import {
	AccountLinkCell,
	ActionsCell,
	LastExaminationCell,
	PatientNameCell
} from './Cells';

export const COLUMNS_ACCESSORS = {
	LAST_NAME: 'lastName',
	FIRST_NAME: 'firstName',
	SINCE: 'patientSince',
	INVITATION: 'invitation',
	IMAGE: 'accountImage',
	SETTINGS: 'settings',
	PHONE: 'phone',
	AGE: 'age',
	CITY: 'city',
	ID_NUMBER: 'idNumber',
	ACCOUNT_LINK: 'linked',
	LAST_EXAMINATION: 'lastExamination',
	NUMBER_OF_EXAMINATIONS: 'numberOfExaminations',
	EMAIL: 'email',
	INVITATION_STATUS: 'invitationStatus'
} as const;

const columnHelper = createColumnHelper<PatientListItemModel>();

export const columns = [
	columnHelper.accessor(COLUMNS_ACCESSORS.LAST_NAME, {
		header: 'patients.table.name',
		meta: {
			textAlign: 'left'
		},
		size: 300,
		cell: (ctx) => <PatientNameCell ctx={ctx} />
	}),
	columnHelper.accessor(COLUMNS_ACCESSORS.EMAIL, {
		minSize: 220,
		header: 'patients.table.email'
	}),
	columnHelper.accessor(COLUMNS_ACCESSORS.AGE, {
		header: 'patients.table.age',
		minSize: 50
	}),
	columnHelper.accessor((row) => (row.idNumber ? row.idNumber : ''), {
		header: 'patients.table.id',
		minSize: 180,
		maxSize: 200
	}),
	columnHelper.accessor(COLUMNS_ACCESSORS.INVITATION_STATUS, {
		header: 'patients.table.account_link',
		cell: (ctx) => <AccountLinkCell ctx={ctx} />,
		minSize: 180
	}),
	columnHelper.accessor(COLUMNS_ACCESSORS.NUMBER_OF_EXAMINATIONS, {
		header: 'patients.table.number_of_examinations',
		sortingFn: (rowA, rowB, columnId) =>
			Number(rowA.getValue(columnId)) > Number(rowB.getValue(columnId))
				? 1
				: -1
	}),
	columnHelper.accessor(COLUMNS_ACCESSORS.LAST_EXAMINATION, {
		header: 'patients.table.last_examination',
		cell: (ctx) => <LastExaminationCell ctx={ctx} />,
		sortDescFirst: false,
		invertSorting: true
	}),
	columnHelper.accessor('id', {
		header: '',
		size: 50,
		enableSorting: false,
		enableGlobalFilter: false,
		cell: (ctx) => <ActionsCell ctx={ctx} />
	})
];
