import {
	EXAMINATION_TYPE_QUERY_PARAM,
	ID_QUERY_PARAM,
	PATIENT_ID_QUERY_PARAM_KEY
} from '../../../helpers/api/queryParamKeys';

export const DASH_PATHS = {
	REVIEWS: '/reviews',
	PATIENTS_DETAILS: `/patient/${PATIENT_ID_QUERY_PARAM_KEY}`,
	PATIENTS: '/patients',
	SETTINGS: '/settings',
	CHAT_PATIENT_CONVERSATION: `/chat/${PATIENT_ID_QUERY_PARAM_KEY}`,
	CHAT: '/chat',
	SCHEDULE: '/schedule',
	REPORT: `/report/${PATIENT_ID_QUERY_PARAM_KEY}/${EXAMINATION_TYPE_QUERY_PARAM}/${ID_QUERY_PARAM}`,
	CALIBRATION_CHECK_REPORT: `/report/${EXAMINATION_TYPE_QUERY_PARAM}/${ID_QUERY_PARAM}`,
	NOT_FOUND: '/not-found'
} as const;
