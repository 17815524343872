import { Container } from 'components/elements/atoms/ExaminationsV2';
import { LabeledValue } from 'components/elements/molecules/ExaminationsV2';
import React from 'react';

import { useGetCachedCalibrationCheckReportData } from '../useGetCachedCalibrationCheckReportData';
import { SUBHEADER_LABELS } from './helpers';
import { useStyles } from './styles';

export const SubHeader = () => {
	const classes = useStyles();
	const { examination } = useGetCachedCalibrationCheckReportData();
	const data = React.useMemo(
		() => ({
			examinationCreateDateTime: examination?.examinationCreateDateTime
		}),
		[examination?.examinationCreateDateTime]
	);

	return (
		<Container className={classes.root}>
			{SUBHEADER_LABELS.map(({ fieldName, label, valueConverter }) => (
				<LabeledValue
					key={fieldName}
					labelProps={{
						className: classes.label,
						label
					}}
					valueProps={{
						value: valueConverter(data?.[fieldName])
					}}
				/>
			))}
		</Container>
	);
};
