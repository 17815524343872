import { Box, Typography as MuiTypography } from '@material-ui/core';
import { useMetricSystem, useTranslations } from 'components/utilities';
import { func, object, string } from 'prop-types';
import React from 'react';

import { useStyles } from './styles';

const MeasurementResult = ({
	name,
	result,
	getUserUnit,
	icon: IconComponent,
	recalculateUnit
}) => {
	const classes = useStyles();
	const { t } = useTranslations();
	const { numberSeparator } = useMetricSystem();

	return (
		<Box className={classes.root}>
			<IconComponent className={classes.icon} />
			<Box>
				<MuiTypography
					className={classes.name}
					variant="overline"
					component="p"
				>
					{t(name)}
				</MuiTypography>
				<Box className={classes.measurementBox}>
					<MuiTypography
						variant="h3"
						component="p"
						className={classes.value}
					>
						{recalculateUnit
							? numberSeparator(recalculateUnit(result))
							: numberSeparator(result)}
					</MuiTypography>
					<MuiTypography
						className={classes.type}
						variant="h5"
						component="p"
					>
						{getUserUnit()}
					</MuiTypography>
				</Box>
			</Box>
		</Box>
	);
};

MeasurementResult.propTypes = {
	name: string.isRequired,
	getUserUnit: func.isRequired,
	result: string.isRequired,
	icon: object.isRequired,
	recalculateUnit: func
};

export default MeasurementResult;
