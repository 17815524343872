import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		'& .rdrDefinedRangesWrapper': {
			display: ({ staticRanges }) =>
				staticRanges.length === 0 ? 'none' : 'block'
		}
	}
}));
