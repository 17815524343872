import {
	useAppointmentFormContext,
	useScheduleCalendarContext
} from 'components/context';
import { useTranslations } from 'components/utilities';
import { getApiErrorMessage } from 'components/utilities/hooks/react-query/helpers';
import { ALERT_TYPES, DateFormatConversion } from 'helpers';
import { useAppointment } from 'queries';
import React from 'react';
import { useParams } from 'react-router-dom';

import { Appointment } from '../classes';
import { getShowEditControls } from './helpers';
import useAlert from './useAlert';

const useEditAppointment = () => {
	const { appointmentId } = useParams();
	const { tNoParser } = useTranslations();

	const {
		patient,
		duration: initialDuration,
		selectedDate,
		selectedAppointment,
		patientsList
	} = useAppointmentFormContext();

	const [showEditControls, setShowEditControls] = React.useState(
		getShowEditControls(selectedDate)
	);
	const [initialValues, setInitialValues] = React.useState(null);

	const { isAppointmentReview } = useScheduleCalendarContext();

	const { alert, setAlert, manualSetAlert } = useAlert();

	const { mutateAsync, apiError } = useAppointment();

	const appointmentExist = !!selectedAppointment;

	const handleSetInitialValues = async () => {
		const appointment = new Appointment(
			initialDuration,
			selectedAppointment?.alert_before,
			selectedAppointment?.notes,
			patient,
			selectedDate
		);

		if (!appointmentExist) {
			try {
				const data = await mutateAsync();
				const dates = {
					start: DateFormatConversion.laravelToISO(
						data.appointment_starts
					),
					end: DateFormatConversion.laravelToISO(
						data.appointment_ends
					)
				};
				appointment.setAppointmentData(
					data.alert_before,
					data.notes,
					dates,
					{
						patient_id: data.patient_id,
						patient_name: data.patientName
					}
				);
			} catch (err) {
				manualSetAlert({
					show: true,
					type: ALERT_TYPES.ERROR,
					content: {
						title: null,
						message: getApiErrorMessage(
							{ code: 'APPOINTMENT_NOT_EXIST' },
							tNoParser
						)
					}
				});
				return setInitialValues(null);
			}
		}

		const {
			alert_before,
			notes,
			date,
			duration,
			patient: { patient_id, patient_name }
		} = appointment;
		setAlert(date, duration, apiError);
		if (patient_id) {
			setShowEditControls(getShowEditControls(date));

			setInitialValues({
				patient_id: patient_id,
				patient_name: patient_name,
				start_date: date,
				start_time: date,
				duration,
				alert_before,
				notes
			});
		}
	};

	React.useEffect(() => {
		handleSetInitialValues();
		// eslint-disable-next-line
	}, [appointmentId, appointmentExist]);

	const formTitle = isAppointmentReview
		? 'schedule.form.title_add'
		: 'schedule.form.title_edit';

	const isPatientsLoaded = patientsList?.length;

	return {
		alert,
		showEditControls,
		initialValues,
		formTitle,
		apiError,
		isPatientsLoaded
	};
};

export default useEditAppointment;
