import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	listContainer: {
		position: 'relative',
		height: '100%'
	},
	alert: {
		width: 'auto',
		margin: '16px'
	},
	placeholderContainer: {
		padding: 20
	}
}));
