import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		flex: 1
	},
	content: {
		display: 'flex',
		gap: 15
	},
	flex: {
		flex: 1
	}
}));
