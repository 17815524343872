import { Title } from 'components/elements/atoms/ExaminationsV2';
import React from 'react';

import { ContainerWithBorder, ParametersTable } from '../../components';
import { useAttemptsContext } from '../../context';
import { useSlowVitalCapacityData } from '../useSlowVitalCapacityData';
import { columns } from './columns';
import {
	addBestValueToAllMeasurements,
	convertBestResultsToColumnsData
} from './helpers';
import { useStyles } from './styles';

export const AllMeasurements = () => {
	const classes = useStyles();
	const examinationData = useSlowVitalCapacityData();
	const { activeMeasurementIndex } = useAttemptsContext();
	return (
		<ContainerWithBorder>
			<Title
				title="patients.examinations_v2.details.spirometry.all_measurements.table.title"
				className={classes.font}
			/>
			<ParametersTable
				columns={columns}
				data={convertBestResultsToColumnsData(
					addBestValueToAllMeasurements(
						examinationData?.measurements ?? []
					)[activeMeasurementIndex]
				)}
			/>
		</ContainerWithBorder>
	);
};
