import { AppLanguage } from 'utilities';

const useLanguage = () => {
	const appLanguage = new AppLanguage();

	return {
		localLng: appLanguage.getLngLocale,
		getLng: appLanguage.getLngString
	};
};

export default useLanguage;
