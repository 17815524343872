import { differenceInMilliseconds } from 'date-fns';
import { COOKIES } from 'helpers/variables';
import Cookies from 'js-cookie';
import { refreshAuthToken } from 'store/actions';

export const convertExpirationDateToTimeout = (dispatch) => {
	//eslint-disable-next-line
	const defaultTimeout = (process.env.REACT_APP_TOKEN_REFRESH - 10) * 1000;
	// refresh one minute before expiration time
	const beforeExpirationThreshold = 10 * 1000;

	const now = new Date();
	const expirationDate = Cookies.get(COOKIES.COOKIE_JWT_EXPIRATION_DATE);

	const expirationTimeout = expirationDate
		? differenceInMilliseconds(new Date(expirationDate), now)
		: defaultTimeout;

	const timeout = expirationTimeout - beforeExpirationThreshold;

	if (expirationTimeout > beforeExpirationThreshold) {
		return timeout;
	}
	expirationDate && refreshAuthToken()(dispatch);

	//eslint-disable-next-line
	return defaultTimeout;
};
