import { Step, StepLabel, Stepper as MuiStepper } from '@material-ui/core';
import clsx from 'clsx';
import { array, number, string } from 'prop-types';
import React from 'react';

import { useStyles } from './styles';

const Stepper = ({ steps, activeStep, classNameRoot = '' }) => {
	const stepsAmount = steps.length;
	const classes = useStyles({ stepsAmount });
	return (
		<MuiStepper
			className={clsx(classes.stepper, classNameRoot)}
			activeStep={activeStep}
		>
			{steps.map((label, index) => (
				<Step
					key={`${label}-${index}`}
					className={
						activeStep + 1 === index
							? classes.disabledStep
							: classes.activeStep
					}
				>
					<StepLabel>{label}</StepLabel>
				</Step>
			))}
		</MuiStepper>
	);
};

Stepper.propTypes = {
	steps: array.isRequired,
	activeStep: number.isRequired,
	classNameRoot: string
};

export default Stepper;
