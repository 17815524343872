import { Title } from 'components/elements/atoms/ExaminationsV2';
import React from 'react';
import {
	addBestValueToAllMeasurements,
	convertBestResultsToColumnsData
} from 'utilities/Examinations/ExaminationDetails/ForcedSpirometryFVC';
import { FVC_ALL_PARAMETERS } from 'utilities/Examinations/ExaminationDetails/helpers';

import { ContainerWithBorder, ParametersTable } from '../../components';
import { useAttemptsContext } from '../../context';
import { useSpirometryFVCData } from '../useSpirometryFVCData';
import { columns } from './columns';
import { useStyles } from './styles';

export const AllMeasurements = () => {
	const classes = useStyles();
	const examinationData = useSpirometryFVCData();
	const { activeMeasurementIndex } = useAttemptsContext();
	return (
		<ContainerWithBorder>
			<Title
				title="patients.examinations_v2.details.spirometry.all_measurements.table.title"
				className={classes.font}
			/>
			<ParametersTable
				columns={columns}
				data={convertBestResultsToColumnsData(
					addBestValueToAllMeasurements(
						examinationData?.measurements ?? [],
						examinationData?.bestParameters
					)[activeMeasurementIndex],
					FVC_ALL_PARAMETERS
				)}
			/>
		</ContainerWithBorder>
	);
};
