import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
	form: {
		flexBasis: 700,
		[theme.breakpoints.down('sm')]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2)
		}
	},
	alert: {
		marginBottom: theme.spacing(3),
		fontWeight: 500,
		display: 'flex',
		alignItems: 'center',
		fontSize: 14
	},
	formTop: { marginBottom: theme.spacing(6) },
	titleWrapper: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: theme.spacing(3.5)
	},
	title: {
		fontSize: '2rem',
		marginLeft: theme.spacing(0.5)
	}
}));
