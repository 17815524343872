import { Box, MenuItem, Select } from '@material-ui/core';
import clsx from 'clsx';
import { useSelectProps } from 'components/utilities';
import { string } from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeUserDefaultLanguage, fetchLanguages } from 'store/actions';
import { AppLanguage } from 'utilities';

import { useStyles } from './styles';

const Language = ({ classNameRoot }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const selectProps = useSelectProps();

	const { lng: language } = useSelector((state) => state.language);
	const { authenticate } = useSelector((state) => state.auth);

	// Handlers
	const handleChangeLanguage = ({ target: { value } }) => {
		// Dispatch actions in auth
		// If user is auth, dispatch change user lng action
		authenticate && changeUserDefaultLanguage(value)(dispatch);
		// Dispatch actions in languages
		// Fetch all translations
		fetchLanguages({ lng: value, isAuthForm: true })(dispatch);
	};

	const availableLanguages = React.useMemo(
		() => new AppLanguage().availableLanguages,
		[]
	);

	return (
		<Box className={clsx(classes.root, classNameRoot)}>
			<Select
				className={classes.select}
				value={language}
				onChange={handleChangeLanguage}
				MenuProps={{ ...selectProps }}
			>
				{availableLanguages.map((value) => (
					<MenuItem
						key={value}
						className={classes.menuItem}
						value={value}
					>
						{value}
					</MenuItem>
				))}
			</Select>
		</Box>
	);
};

Language.propTypes = {
	classNameRoot: string
};

export default Language;
