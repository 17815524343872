import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	parameterLabel: {
		flexDirection: 'column',
		display: 'flex',
		justifyContent: 'space-between',
		width: 34,
		height: 'inherit',
		'& > div': {
			flex: 1,
			display: 'flex',
			alignItems: 'center'
		}
	}
}));
