import { object } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import NotAuthorized from 'views/NotAuthorized';

const RoleAuthorization = (allowedRoles) => (WrappedComponent) => {
	class WithAuthorization extends React.Component {
		render() {
			// Get the auth roles data
			const {
				auth: { authenticate }
			} = this.props;
			const {
				data: { roles }
			} = authenticate;

			// Check if user's role is included in allowedRole array
			const isAllowedAccess = roles.some((role) =>
				allowedRoles.includes(role)
			);

			if (isAllowedAccess) {
				return <WrappedComponent {...this.props} />;
			}
			return <NotAuthorized />;
		}
	}
	const mapStateToProps = ({ auth }) => {
		return { auth };
	};
	WithAuthorization.propTypes = {
		auth: object
	};
	return connect(mapStateToProps)(WithAuthorization);
};

export default RoleAuthorization;
