import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	cell: {
		display: 'flex',
		alignItems: 'center'
	},
	icon: {
		width: 15,
		height: 15,
		display: 'flex',
		alignItems: 'center',
		marginRight: theme.spacing(0.5)
	},
	wrapper: {
		margin: theme.spacing(1),
		position: 'relative'
	},
	progress: {
		color: theme.palette.primary.main,
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12
	},
	errorText: {
		color: theme.palette.error.main,
		textTransform: 'none'
	},
	contentLabel: {
		textAlign: 'center',
		fontSize: 18,
		lineHeight: '20px'
	}
}));
