import { Loader, RouteWithLayout } from 'components/elements';
import { DASH_PATHS } from 'components/routes/paths';
import { DashboardTemplate } from 'components/templates';
import { useRedirect } from 'components/utilities';
import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

// Import dash routes
import { DASH_ROUTES } from './helpers';

const { NOT_FOUND } = DASH_PATHS;
const DashboardRoutes = () => {
	const { redirect } = useRedirect();

	return (
		<DashboardTemplate>
			<React.Suspense fallback={<Loader />}>
				<Switch>
					<Redirect exact from="/" to={redirect} />
					{DASH_ROUTES.map((route) => (
						<RouteWithLayout key={route.path} {...route} />
					))}
					<Redirect to={NOT_FOUND} />
				</Switch>
			</React.Suspense>
		</DashboardTemplate>
	);
};

export default DashboardRoutes;
