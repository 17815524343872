import { PersonalBestReference } from 'components/context/ExaminationsV2/classes';
import { useMutation } from 'components/utilities';
import { format } from 'date-fns';
import { DATE_FORMAT, QUERY_PATIENT_DATA_AND_PERSONAL_BESTS } from 'helpers';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { ChartDataRepository } from './repository';
import { ChartDataServices } from './services';

export const EXAMINATION_CHART_DATA_QUERY_KEY =
	'EXAMINATION_CHART_DATA_QUERY_KEY';

/**
 * fetches chart data
 * @param {object} options mutation options
 * @returns fetch charts data mutation
 */
const useChartsDataMutation = (options = {}) => {
	const { patientId } = useParams();
	const cache = useQueryClient();

	/**
	 * function to fetch single ChartParameter
	 * @param {object} args mutation arguments
	 * @param {Date} args.chartStartDate chart data's start date range
	 * @param {Date} args.chartEndDate chart data's end date range
	 * @param {string} args.chartName chart type name
	 * @param {object} cancelToken request token cancellation
	 * @returns mutation function promise
	 */
	const fetchSingleChartParameter = async (
		{ chartStartDate, chartEndDate, chartName },
		cancelToken
	) => {
		//2022-11-23
		const startDate = format(chartStartDate, DATE_FORMAT.CHART_RANGE);
		const endDate = format(chartEndDate, DATE_FORMAT.CHART_RANGE);
		const cachedData = cache.getQueryData([
			EXAMINATION_CHART_DATA_QUERY_KEY,
			patientId,
			startDate,
			endDate,
			chartName
		]);
		const [patientPersonalBests] = cache.getQueryData([
			QUERY_PATIENT_DATA_AND_PERSONAL_BESTS,
			patientId
		]);
		const personalBestReferenceValue = new PersonalBestReference(
			chartName,
			patientPersonalBests?.best
		).getValue();

		const data = cachedData
			? { data: cachedData }
			: /*eslint-disable*/
			  await ChartDataRepository.getChartData({
					patientId,
					chartStartDate: startDate,
					chartEndDate: endDate,
					chartName,
					personalBestReferenceValue,
					cancelToken
			  });
		/*eslint-enable*/

		return {
			...data,
			chartName,
			chartStartDate: startDate,
			chartEndDate: endDate
		};
	};

	const fetchAllChartParameters = (
		{ chartParameters, ...rest },
		cancelToken
	) => {
		return Promise.all(
			chartParameters.map((chartName) =>
				fetchSingleChartParameter({ ...rest, chartName }, cancelToken)
			)
		);
	};

	const handleSuccess = (data, requestData) => {
		ChartDataServices.saveDataToQueryClientCache(cache, patientId, data);
		options?.onSuccess?.(data, requestData);
	};

	const singleChartParameterMutation = useMutation(
		fetchSingleChartParameter,
		{ ...options, onSuccess: handleSuccess }
	);
	const allChartParametersMutation = useMutation(fetchAllChartParameters, {
		...options,
		onSuccess: handleSuccess
	});

	return { singleChartParameterMutation, allChartParametersMutation };
};

export default useChartsDataMutation;
