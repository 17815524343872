import {
	replaceCommonIdQueryParam,
	replacePatientIdQueryParam
} from 'queries/common';
import services from 'services/services';

export const ConditionsRepository = {
	/**
	 * get condition data
	 * @param {object} args request args
	 * @param {object} args.cancelToken request cancel token
	 * @param {string} args.id condition ID to be fetched
	 * @param {string} args.apiUrl condition api route (recognize condition type)
	 * @returns Promise
	 */
	get: ({ cancelToken, id, apiUrl }) => {
		const apiRoute = replaceCommonIdQueryParam(apiUrl, id);
		return services.get(apiRoute, { cancelToken });
	},
	/**
	 * edit condition
	 * @param {object} args request args
	 * @param {string} args.apiUrl condition api route (recognize condition type)
	 * @param {object} args.payload request payload
	 * @param {string} args.id condition ID to be fetched
	 * @returns Promise
	 */
	edit: ({ apiUrl, payload, id }) => {
		const apiRoute = replaceCommonIdQueryParam(apiUrl, id);
		return services.put(apiRoute, payload);
	},
	/**
	 * create condition
	 * @param {object} args request args
	 * @param {string} args.apiUrl condition api route (recognize condition type)
	 * @param {object} args.payload request payload
	 * @param {string} args.patientId patient ID in which condition is created
	 * @returns Promise
	 */
	create: ({ apiUrl, payload, patientId }) => {
		const apiRoute = replacePatientIdQueryParam(apiUrl, patientId);
		return services.post(apiRoute, payload);
	},
	delete: (conditionId, deleteApiUrl) => {
		const apiUrl = replaceCommonIdQueryParam(deleteApiUrl, conditionId);
		return services.delete(apiUrl);
	}
};
