import { Box, Grid } from '@material-ui/core';
import { AddIcon } from 'assets/icons/commonMUIIcons';
import { Button, RouterLink } from 'components/elements';
import { SCHEDULE_PATHS } from 'components/routes/paths';
import { useTranslations } from 'components/utilities';
import React from 'react';

import { useStyles } from './style';
import { ViewSelector } from './ViewSelector';

const CalendarControls = () => {
	const classes = useStyles();
	const { t } = useTranslations();
	return (
		<Grid
			container
			spacing={0}
			alignItems="center"
			justifyContent="flex-end"
		>
			<Grid item xs={12} sm={12} md={12}>
				<Box className={classes.root}>
					<ViewSelector />
					<Button
						className={classes.button}
						component={RouterLink}
						to={SCHEDULE_PATHS.CREATE_APPOINTMENT}
						startIcon={<AddIcon />}
					>
						{t('schedule.btn.add_appointment')}
					</Button>
				</Box>
			</Grid>
		</Grid>
	);
};

export default CalendarControls;
