import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: ({ isListItem }) => ({
		borderRadius: 10,
		padding: '15px',
		width: '100%',
		display: 'flex',
		alignItems: 'flex-start',
		gap: 10,
		cursor: isListItem ? 'pointer' : 'initial',
		'&:hover': {
			backgroundColor: isListItem
				? `${theme.palette.primary.main}10`
				: '',
			background: isListItem
				? `linear-gradient(90deg, ${theme.palette.primary.main}10 0%, ${theme.palette.primary.main}10 100%)`
				: ''
		},
		transition: 'all 250ms ease-in-out'
	}),
	active: {
		'-webkit-box-shadow': `0px 0px 8px 1px ${theme.palette.primary.main}`,
		'-moz-box-shadow': `0px 0px 8px 1px ${theme.palette.primary.main}`,
		boxShadow: `0px 0px 8px 1px ${theme.palette.primary.main}`,
		background: `linear-gradient(90deg, rgba(255,255,255,1) 0%,  rgba(255,255,255,1) 30%, ${theme.palette.primary.main}25 100%)`,
		transition: 'all 250ms ease-in-out'
	},
	unactive: {
		border: `1px solid ${theme.palette.border.light}`
	}
}));
