import { useTodayDate } from 'components/utilities';
import { useForm, useFormState } from 'react-final-form';

import { FIELD_NAMES, getMonthsArray, getYearsArray } from './helpers';

const useExSmokerFields = () => {
	const { values } = useFormState();
	const { change } = useForm();
	const { currentMonth, currentYear } = useTodayDate();

	const dateOfBirth = new Date(values.patientDob);
	const yearOfBirth = dateOfBirth.getFullYear();

	const handleChangeYear = (e) => {
		const isInvalidMonthCurrentValue =
			(Number(e.target.value) === currentYear &&
				Number(values[FIELD_NAMES.exSmokerMonth]) > currentMonth - 1) ||
			(Number(e.target.value) === yearOfBirth &&
				Number(values[FIELD_NAMES.exSmokerMonth]) < currentMonth);
		if (isInvalidMonthCurrentValue) {
			change(FIELD_NAMES.exSmokerMonth, '');
		}
		return e;
	};

	const yearsArray = getYearsArray(dateOfBirth);
	const monthsArray = getMonthsArray(
		values[FIELD_NAMES.exSmokerYear],
		dateOfBirth
	);

	return { yearsArray, monthsArray, onYearChange: handleChangeYear };
};

export default useExSmokerFields;
