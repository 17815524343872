import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { ParameterValue } from 'components/elements';
import { array, bool, func, node, oneOfType, string } from 'prop-types';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useGlobalStyles } from '../../../../../globalStyles';
import { Row, Type } from './components';

const Parameter = ({
	values,
	name = null,
	showType = true,
	fullWidth = false,
	className = '',
	customRow: CustomRow = null
}) => {
	const classesGlobal = useGlobalStyles();

	const renderData = () => (
		<>
			{name && <Type showType={showType} name={name} />}
			<Box
				className={clsx([className, classesGlobal.parameters])}
				data-testid="valuesWrapper"
			>
				{values?.length > 0 &&
					values.map((props) => (
						<ParameterValue
							//eslint-disable-next-line
							key={uuidv4()}
							fullWidth={fullWidth}
							{...props}
						/>
					))}
			</Box>
		</>
	);

	return CustomRow ? (
		<CustomRow>{renderData()}</CustomRow>
	) : (
		<Row data-testid="row">{renderData()}</Row>
	);
};

Parameter.propTypes = {
	values: array.isRequired,
	name: string,
	isScore: bool,
	showType: bool,
	fullWidth: bool,
	className: string,
	customRow: oneOfType([node, func])
};

export default Parameter;
