import { AxisValueRatio, IGraphProps } from './Graph';

export const validateParameterValue = (param) =>
	param === undefined || param === null;

type GraphDimensionsWithMinMax = {
	height: number;
	width: number | '100%';
	y: {
		min: number;
		max: number;
	};
	x: {
		min: number;
		max: number;
	};
};

const MAX_GRAPH_HEIGHT = 210;
const MAX_GRAPH_WIDTH = 341;

const Y_AXIS_TITLE_AND_LABELS_WIDTH = 34;
const X_AXIS_TITLE_AND_LABELS_HEIGHT = 28;

export const getGraphDimensionsWithMinMax = (
	data: IGraphProps['data'],
	axisValuesRatio: AxisValueRatio,
	axisBoundaries: IGraphProps['axisBoundaries'],
	containerBoundaries: IGraphProps['containerBoundaries'],
	triangleMaxAxisValues: ReturnType<typeof getTriangleMaxValues>
): GraphDimensionsWithMinMax => {
	const containerWidthBoundary =
			(containerBoundaries?.width ?? MAX_GRAPH_WIDTH) -
			Y_AXIS_TITLE_AND_LABELS_WIDTH,
		containerHeightBoundary =
			containerBoundaries?.height ??
			MAX_GRAPH_HEIGHT - X_AXIS_TITLE_AND_LABELS_HEIGHT;
	const [yAxisRatio, xAxisRatio] = getRatio(axisValuesRatio);

	const { minY, maxY, minX, maxX } = getMinMax({
		data,
		defaults: {
			...axisBoundaries,
			MAX_X: triangleMaxAxisValues?.maxX ?? axisBoundaries.MAX_X,
			MAX_Y: triangleMaxAxisValues?.maxY ?? axisBoundaries.MAX_Y
		},
		yAxisScaleRatio: yAxisRatio,
		xAxisScaleRatio: xAxisRatio
	});

	const yAxisSegments =
		(Math.abs(scaleValue(minY, yAxisRatio)) +
			Math.abs(scaleValue(maxY, yAxisRatio))) /
		yAxisRatio;
	const xAxisSegments =
		(Math.abs(scaleValue(minX, xAxisRatio)) +
			Math.abs(scaleValue(maxX, xAxisRatio))) /
		xAxisRatio;

	let containerHeight = containerHeightBoundary;
	let containerWidth = (containerHeight * xAxisSegments) / yAxisSegments;

	if (containerWidth > containerWidthBoundary) {
		containerWidth = containerWidthBoundary;
		containerHeight =
			(containerWidthBoundary * yAxisSegments) / xAxisSegments;
	}

	return {
		height: containerHeight + X_AXIS_TITLE_AND_LABELS_HEIGHT,
		width: containerWidth + Y_AXIS_TITLE_AND_LABELS_WIDTH,
		y: {
			min: scaleValue(minY, yAxisRatio),
			max: scaleValue(maxY, yAxisRatio)
		},
		x: {
			min: scaleValue(minX, xAxisRatio),
			max: scaleValue(maxX, xAxisRatio)
		}
	};
};

export const getTriangleMaxValues = (
	triangleData: Highcharts.PointOptionsObject[],
	axisValuesRatio: AxisValueRatio
): { maxX?: number; maxY?: number } => {
	const [yAxisRatio, xAxisRatio] = getRatio(axisValuesRatio);

	return triangleData.reduce(
		(acc, { x, y }) => {
			if (x && x > acc.maxX) {
				acc.maxX = scaleValue(x, xAxisRatio);
			}
			if (y && y > acc.maxY) {
				acc.maxY = scaleValue(y, yAxisRatio);
			}
			return acc;
		},
		{ maxY: 0, maxX: 0 } as { maxY: number; maxX: number }
	);
};

function scaleValue(val, modulo: number) {
	function calc(int) {
		return int + (int % modulo);
	}

	if (val <= 0) {
		return calc(Math.floor(val));
	}

	return calc(Math.ceil(val));
}

function getRatio(axisValuesRatio: AxisValueRatio) {
	return axisValuesRatio.split(':').map((r) => Number(r));
}

function getMinMax({
	data,
	defaults,
	yAxisScaleRatio,
	xAxisScaleRatio
}: {
	data: IGraphProps['data'];
	defaults: IGraphProps['axisBoundaries'];
	yAxisScaleRatio: number;
	xAxisScaleRatio: number;
}) {
	let minY = 100,
		maxY = -100,
		minX = 100,
		maxX = -100;

	Object.values(data).forEach(({ data }) => {
		const { y, x } = data.reduce(
			(acc, next) => {
				typeof next.y === 'number' && acc.y.push(next.y);
				typeof next.x === 'number' && acc.x.push(next.x);
				return acc;
			},
			{ y: new Array<number>(), x: new Array<number>() }
		);

		const localMinY = scaleValue(Math.min(...y), yAxisScaleRatio);
		const localMaxY = scaleValue(Math.max(...y), yAxisScaleRatio);

		const localMinX = scaleValue(Math.min(...x), xAxisScaleRatio);
		const localMaxX = scaleValue(Math.max(...x), xAxisScaleRatio);

		minY = localMinY < minY ? localMinY : minY;
		maxY = localMaxY > maxY ? localMaxY : maxY;

		minX = localMinX < minX ? localMinX : minX;
		maxX = localMaxX > maxX ? localMaxX : maxX;
	});

	minY = minY < defaults.MIN_Y ? minY : defaults.MIN_Y;
	maxY = maxY > defaults.MAX_Y ? maxY : defaults.MAX_Y;
	minX = minX < defaults.MIN_X ? minX : defaults.MIN_X;
	maxX = maxX > defaults.MAX_X ? maxX : defaults.MAX_X;

	return { minY, maxY, minX, maxX };
}
