import {
	Box,
	BoxProps,
	Card,
	CardContent,
	CardProps,
	Divider,
	Typography
} from '@material-ui/core';
import clsx from 'clsx';
import { useTranslations } from 'components/utilities';
import React from 'react';

import { useStyles } from './styles';

type CardTopBarProps = {
	children?: React.ReactNode;
	classNameBox?: string;
	cardContentClassName?: string;
	className?: string;
	titleClassName?: string;
	isBox?: boolean;
	isCardTopBar?: boolean;
	isBorderTop?: boolean;
	title?: string;
	titleComponent?: (() => JSX.Element) | null;
	titleActionButtons?: (() => JSX.Element) | null;
};

export type TCardTopBar = CardTopBarProps & CardProps & BoxProps;

const CardTopBar = ({
	children,
	classNameBox,
	isBox = false,
	isCardTopBar = true,
	isBorderTop = true,
	cardContentClassName = '',
	className = '',
	titleClassName = '',
	title = '',
	titleComponent: TitleComponent = null,
	titleActionButtons: TitleActionButtons = null,
	...rest
}: TCardTopBar) => {
	const { t } = useTranslations();
	const classes = useStyles();

	const renderTitle = () => {
		let Component = () => (
			<Box className={clsx([classes.titleContainer, titleClassName])}>
				<Typography className={clsx([classes.mainTitle])} variant="h3">
					{t(title)}
				</Typography>
				{TitleActionButtons && <TitleActionButtons />}
			</Box>
		);

		if (TitleComponent !== null) {
			Component = () => <TitleComponent />;
		}

		return (
			<>
				<Component />
				<Divider />
			</>
		);
	};

	if (isBox) {
		return (
			<Box
				data-testid="CardTopBar-rootBox"
				{...rest}
				className={clsx([classes.rootDefault, classNameBox])}
			>
				{children}
			</Box>
		);
	}
	if (isCardTopBar) {
		const borderTopClass = isBorderTop ? classes.topBorder : '';
		return (
			<Card
				data-testid="CardTopBar-rootDefault"
				{...rest}
				className={clsx([
					classes.rootCard,
					classNameBox,
					borderTopClass
				])}
			>
				{(title || TitleComponent) && renderTitle()}
				<CardContent className={cardContentClassName}>
					{children}
				</CardContent>
			</Card>
		);
	}

	return (
		<Card
			data-testid="CardTopBar-rootCard"
			{...rest}
			className={clsx([classes.rootDefault, className])}
		>
			{children}
		</Card>
	);
};

export default CardTopBar;
