import { ROLES_LABELS } from 'helpers';
import { useSelector } from 'react-redux';

const useProfile = () => {
	const { user, roles } = useSelector(({ auth }) => auth.authenticate.data);
	const { first_name, last_name, gender } = user;
	const [role] = roles;

	return {
		firstName: first_name,
		lastName: last_name,
		gender,
		roleLabel: ROLES_LABELS[role]
	};
};

export default useProfile;
