const GENDERS_LIST = {
	FEMALE: 'F',
	MALE: 'M',
	NOT_SPECIFIED: 'NS'
};

const GENDERS_TO_BE_FILTERED = [GENDERS_LIST.NOT_SPECIFIED];

export const getFilteredGenders = (genders) => {
	const data = Object.values(genders?.data).filter(
		(gender) => !GENDERS_TO_BE_FILTERED.includes(gender)
	);
	return data;
};
