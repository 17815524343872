import { CancelToken } from 'axios';
import {
	DELETE_PATIENT_API,
	FIND_PATIENT_BY_EMAIL,
	LINK_PATIENT_WITH_DOCTOR_API,
	PATIENTS_LIST_API,
	UNLINK_PATIENT_WITH_DOCTOR_API
} from 'helpers';
import {
	PatientDeletionRequestSchema,
	PatientDeletionResponseSchema
} from 'models/Patient/PatientDeletion';
import {
	PatientLinkWithDoctorRequestModel,
	PatientLinkWithDoctorRequestSchema,
	PatientLinkWithDoctorResponseSchema,
	PatientUnlinkFromDoctorRequestModel,
	PatientUnlinkFromDoctorRequestSchema,
	PatientUnlinkFromDoctorResponseSchema
} from 'models/Patient/PatientLink';
import { generatePath } from 'react-router-dom';
import services from 'services/services';
import { servicesV2 } from 'services/servicesV2';
import { z } from 'zod';

export const PatientsRepository = {
	getAll,
	validateEmail,
	deletePatient,
	linkPatientAccountWithDoctor,
	unlinkPatientAccountFromDoctor
};

function unlinkPatientAccountFromDoctor(
	payload: PatientUnlinkFromDoctorRequestModel
) {
	return servicesV2.post(UNLINK_PATIENT_WITH_DOCTOR_API, payload, {
		requestSchema: PatientUnlinkFromDoctorRequestSchema,
		responseSchema: PatientUnlinkFromDoctorResponseSchema
	});
}

function linkPatientAccountWithDoctor(
	payload: PatientLinkWithDoctorRequestModel
) {
	return servicesV2.post(LINK_PATIENT_WITH_DOCTOR_API, payload, {
		requestSchema: PatientLinkWithDoctorRequestSchema,
		responseSchema: PatientLinkWithDoctorResponseSchema
	});
}

function deletePatient(
	{ patientId }: { patientId: string },
	cancelToken?: CancelToken
) {
	return servicesV2.delete(generatePath(DELETE_PATIENT_API, { patientId }), {
		cancelToken,
		requestSchema: PatientDeletionRequestSchema,
		responseSchema: PatientDeletionResponseSchema
	});
}

function getAll({ cancelToken }: { cancelToken: CancelToken }) {
	return servicesV2.post(
		PATIENTS_LIST_API,
		{},
		{
			cancelToken,
			requestSchema: z.object({}),
			responseSchema: z.any()
		}
	);
}

function validateEmail(value) {
	return services.post(FIND_PATIENT_BY_EMAIL, { email: value });
}
