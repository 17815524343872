import { EMPTY_VALUE } from '../../variables';
import { validateExaminationValue } from '../value';
import { RESULTS_COLORS } from './colors';

export const getBronchodilatorExaminationParameterColor = (status, value) => {
	const validatedIcValue = validateExaminationValue(value);
	return validatedIcValue === EMPTY_VALUE
		? RESULTS_COLORS[3]
		: RESULTS_COLORS[status - 1];
};
