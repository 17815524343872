import { useStepper } from '../useStepper';
import { UseStepWizardProps, useStepWizardReturnObj } from './types';

const useStepWizard = <TContentProps>({
	steps,
	stepsTitle,
	stepsContent
}: UseStepWizardProps<TContentProps>): useStepWizardReturnObj<
	TContentProps
> => {
	const stepper = useStepper({ steps });

	const getStepTitle = () =>
		stepsTitle?.length ? stepsTitle[stepper.stepIndex] : undefined;

	const getStepContent = () => stepsContent[stepper.stepIndex];

	return {
		title: getStepTitle(),
		Content: getStepContent(),
		...stepper
	};
};

export default useStepWizard;
