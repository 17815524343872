import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	table: {
		tableLayout: 'fixed',
		width: '100%',
		borderSpacing: 0
	},
	row: {
		textAlign: 'left',
		height: 18,
		'&:last-of-type': {
			'& > td': {
				borderBottom: 0,
				paddingBottom: 0
			}
		},
		'& > :first-child': {
			width: '100px',
			marginRight: 10
		}
	},
	headerCell: {
		textAlign: 'left',
		marginBottom: 4,
		textTransform: 'uppercase',
		fontSize: '9.1px'
	},
	tableHeader: {
		verticalAlign: 'bottom'
	},
	cell: {
		borderBottom: '1px solid #E2E2E2',
		padding: '3px 0'
	}
}));
