import { Box } from '@material-ui/core';
import { arrayOf, node, oneOfType } from 'prop-types';
import React from 'react';

import { useStyles } from './styles';

const Row = ({ children }) => {
	const classes = useStyles();
	return <Box className={classes.root}>{children}</Box>;
};

Row.propTypes = {
	children: oneOfType([arrayOf(node), node]).isRequired
};

export default Row;
