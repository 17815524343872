import { makeStyles } from '@material-ui/styles';
import theme from 'theme';
import palette from 'theme/palette';

export const useStyles = makeStyles<typeof theme, {rightArrowDisabled: boolean, leftArrowDisabled: boolean}>(() => ({
	root: {
		display: 'flex',
		width: '100%',
		justifyContent: 'center',
		gap: 10,
		alignItems: 'center'
	},
	leftArrow: {
		'& svg': {
			color: ({ leftArrowDisabled }) =>
				leftArrowDisabled
					? palette.gray.main
					: palette.primary.main
		}
	},
	rightArrow: {
		'& svg': {
			color: ({ rightArrowDisabled }) =>
				rightArrowDisabled
					? palette.gray.main
					: palette.primary.main
		}
	},
	label: {
		fontWeight: 500,
		color: 'black',
		fontSize: '14px',
		lineHeight: '14px'
	}
}));
