import { CancelToken } from 'axios';
import {
	CLINIC_DATA_API,
	CLINIC_DELETE_LOGO_API,
	CLINIC_INVITATION_DATA_API,
	LEAVE_CLINIC_API
} from 'helpers';
import {
	ClinicDataSchema,
	DeleteClinicImageSchema
} from 'models/Clinic/Clinic';
import {
	replaceClinicIdQueryParam,
	replaceCommonIdQueryParam
} from 'queries/common';
import services from 'services/services';
import { servicesV2 } from 'services/servicesV2';
import { z } from 'zod';

type GetDataArgs = {
	cancelToken: CancelToken;
};
type GetInvitationArgs = {
	id: IDParam;
	cancelToken: CancelToken;
};
export class ClinicRepository {
	static getData({ cancelToken }: GetDataArgs) {
		return servicesV2.get(CLINIC_DATA_API, {
			cancelToken,
			requestSchema: z.undefined(),
			responseSchema: ClinicDataSchema
		});
	}
	static edit({ payload }) {
		return servicesV2.post(
			CLINIC_DATA_API + '?_method=PUT',
			payload,
			// request payload is form data - zod doesn't handle that type
			{
				requestSchema: z.any(),
				responseSchema: z.object({}).strict(),
				headers: { 'Content-Type': 'multipart/form-data' }
			}
		);
	}
	static create({ payload }) {
		return servicesV2.post(CLINIC_DATA_API, payload, {
			// request payload is form data - zod doesn't handle that type
			requestSchema: z.any(),
			responseSchema: z.object({}).strict()
		});
	}
	static deleteClinicImage() {
		return servicesV2.delete(CLINIC_DELETE_LOGO_API, {
			requestSchema: z.undefined(),
			responseSchema: DeleteClinicImageSchema
		});
	}
	static getInvitations({ id, cancelToken }: GetInvitationArgs) {
		const apiUrl = replaceCommonIdQueryParam(
			CLINIC_INVITATION_DATA_API,
			id
		);
		return services.get(apiUrl, {
			cancelToken
		});
	}
	static leaveClinic(clinicId: ClinicIdParam) {
		const apiUrl = replaceClinicIdQueryParam(LEAVE_CLINIC_API, clinicId);
		return services.post(apiUrl);
	}
}
