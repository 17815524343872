import './styles.css';

import { Box } from '@material-ui/core';
import { LoaderSpinner } from 'components/elements';
import { EXAMINATIONS_TYPES } from 'helpers/variables';
import { useReportData } from 'queries/Examinations/ReportsV2';
import React from 'react';
import { useParams } from 'utilities';

import { Bronchodilator } from './Bronchodilator';
import { CalibrationCheck } from './CalibrationCheck/CalibrationCheck';
import { InvalidExaminationTypeRedirect } from './components/molecules/InvalidExaminationTypeRedirect';
import { useReportContext } from './context';
import { ForcedSpirometry } from './ForcedSpirometry';
import { PeakFlow } from './PeakFlow';
import { PulseOximetry } from './PulseOximetry';
import { SlowVitalCapacitySVC } from './SlowVitalCapacitySVC';

const {
	SPIROMETRY,
	SVC,
	PULSE_OXIMETRY,
	PEAK_FLOW,
	COPD_TEST,
	COPD_FINAL,
	CALIBRATION_CHECK
} = EXAMINATIONS_TYPES;

export const ReportWithContext = () => {
	const { examinationType } = useParams();
	const { setApiErrorMessage } = useReportContext();
	const { isLoading } = useReportData({
		onError: (mess) => {
			setApiErrorMessage(mess);
		}
	});

	const ReportComponent = (function () {
		switch (examinationType) {
			case SPIROMETRY.type:
				return ForcedSpirometry;
			case SVC.type:
				return SlowVitalCapacitySVC;
			case PULSE_OXIMETRY.type:
				return PulseOximetry;
			case PEAK_FLOW.type:
				return PeakFlow;
			case COPD_TEST.type:
			case COPD_FINAL.type:
				return Bronchodilator;
			case CALIBRATION_CHECK.type:
				return CalibrationCheck;
			default:
				return null;
		}
	})();

	return ReportComponent ? (
		isLoading ? (
			<LoaderSpinner rootSize="full" />
		) : (
			<Box id="report-content">
				<ReportComponent />
			</Box>
		)
	) : (
		<InvalidExaminationTypeRedirect />
	);
};
