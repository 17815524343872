// import VisibilityIcon from '@material-ui/icons/Visibility';
import { DashboardIcon, PersonIcon } from 'assets/icons/commonMUIIcons';
import { DASH_PATHS, PATIENTS_PATHS } from 'components/routes/paths';
import React from 'react';

import { GLOBAL_PAGE_ROLES, PATIENTS_PAGE_ROLES } from '../roles/roles';

const { CHAT } = DASH_PATHS;

export const NAV_LINKS = [
	// {
	// 	title: 'nav.review',
	// 	href: REVIEWS,
	// 	icon: <VisibilityIcon />,
	// 	role: GLOBAL_PAGE_ROLES
	// },
	{
		title: 'nav.patients',
		href: PATIENTS_PATHS.TABLE,
		icon: <PersonIcon />,
		role: PATIENTS_PAGE_ROLES
	},
	{
		title: 'nav.chat',
		href: CHAT,
		icon: <PersonIcon />,
		role: GLOBAL_PAGE_ROLES
	},
	{
		title: 'nav.schedule',
		href: '/schedule',
		icon: <DashboardIcon />,
		role: GLOBAL_PAGE_ROLES
	}
];
