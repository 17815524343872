import { CHART_MEASUREMENT_PARAMETER_OPTION_NAMES } from 'helpers/charts/measurement/parameters';
import { PulseOximetryPreviewModel } from 'models/Examinations/allExaminationsTypes';
import { PulseOximetryPreview } from 'utilities/Examinations/Previews/PulseOximetryPreview';

import { formatCreatedDateTime } from './helpers';

export const getPulseOximetryPreview = (
	data: PulseOximetryPreviewModel
): ExaminationPreview => {
	const pulseOximetry = new PulseOximetryPreview(data);
	const hr = pulseOximetry.getHrPreview();
	const spO2 = pulseOximetry.getSpO2Preview();

	return {
		id: data.id,
		type: data.type,
		createDateTime: formatCreatedDateTime(data.createDateTime),
		tab: data.type,
		title: CHART_MEASUREMENT_PARAMETER_OPTION_NAMES.hrAvg.translationKey,
		parameters: [spO2, hr],
		pdfReport: data.pdfReport || null
	};
};
