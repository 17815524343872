import { ConditionsRepository } from 'queries';

import useItem from './useItem';

const useItemForm = ({
	getApiUrl,
	addApiUrl,
	editApiUrl,
	convertFormValues
}) => {
	const fetchFormResAction = async ({ isEdit, id, cancelToken }) => {
		if (isEdit) {
			return ConditionsRepository.get({
				id,
				cancelToken,
				apiUrl: getApiUrl
			});
		}
		return {};
	};

	const submitFormAction = async ({ isEdit, values, patientId, id }) => {
		const payload = convertFormValues(values);

		if (isEdit) {
			return ConditionsRepository.edit({
				apiUrl: editApiUrl,
				payload,
				id
			});
		}
		return ConditionsRepository.create({
			apiUrl: addApiUrl,
			payload,
			patientId
		});
	};

	const { url } = useItem();

	return {
		fetchFormResAction,
		submitFormAction,
		url
	};
};

export default useItemForm;
