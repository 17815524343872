import { useQuery } from 'components/utilities';
import { UseQueryOptionsProps } from 'components/utilities/hooks/react-query/useQuery';
import { MY_PROFILE_USER_RESOURCES_QUERY_KEY } from 'helpers';
import { DoctorUserModel } from 'models/User/Doctor';

import { DoctorRepository } from './repository';

const useDoctorResources = (
	options: UseQueryOptionsProps<DoctorUserModel> = {}
) => {
	return useQuery<DoctorUserModel>(
		[MY_PROFILE_USER_RESOURCES_QUERY_KEY],
		(payload) => DoctorRepository.get(payload),
		options
	);
};

export default useDoctorResources;
