import { Box } from '@material-ui/core';
import { Search } from 'components/elements/molecules';
import React from 'react';

import { useStyles } from './styles';

export interface IToolbarProps {
	globalFilter: string;
	disableGlobalFilter: boolean;
	setGlobalFilter: React.Dispatch<React.SetStateAction<string>>;
	toolbarActions?:
		| null
		| ((props: { refreshData?: () => void }) => JSX.Element);
	refreshData: () => void;
}

export const Toolbar = React.forwardRef<HTMLDivElement, IToolbarProps>(
	(
		{
			globalFilter,
			setGlobalFilter,
			toolbarActions: ToolbarActions,
			disableGlobalFilter,
			refreshData
		},
		ref
	) => {
		const classes = useStyles();

		const [value, setValue] = React.useState(globalFilter);

		React.useEffect(() => {
			setValue(globalFilter);
		}, [globalFilter]);

		React.useEffect(() => {
			const timeout = setTimeout(() => {
				setGlobalFilter(value);
			}, 250);

			return () => clearTimeout(timeout);
		}, [value]);

		return (
			<Box className={classes.root} {...{ ref }}>
				{!disableGlobalFilter && (
					<Search
						query={value}
						setQuery={(e) => setValue(e.target.value)}
						clearQuery={() => {
							setGlobalFilter('');
						}}
						placeholder="table.search"
					/>
				)}
				<Box className={classes.spacer} />
				{ToolbarActions && <ToolbarActions refreshData={refreshData} />}
			</Box>
		);
	}
);
