import React from 'react';
import {
	ControllerFieldState,
	ControllerRenderProps,
	useFormContext
} from 'react-hook-form';

import { isError } from '../helpers';
import { ITextInputProps } from './TextInput';

export interface UseTextInputProps {
	onChange: ITextInputProps['onChange'];
	name: ITextInputProps['name'];
	regexValueChangeValidation: ITextInputProps['regexValueChangeValidation'];
}

export const useTextInput = ({
	onChange,
	name,
	regexValueChangeValidation
}: UseTextInputProps) => {
	const { trigger } = useFormContext();

	const handleChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		field: ControllerRenderProps,
		fieldState: ControllerFieldState
	) => {
		e?.persist?.();
		const { value } = e.target;

		async function changeInputValue() {
			field.onChange(e);
			onChange && onChange(e);
			isError(fieldState) && (await trigger(name));
		}

		if (!regexValueChangeValidation || value === '') {
			changeInputValue();
			return;
		}
		regexValueChangeValidation.test(e.target.value) && changeInputValue();
	};

	return { onChange: handleChange };
};
