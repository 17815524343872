import { Container } from 'components/elements/atoms/ExaminationsV2';
import {
	Correctness,
	Repeatability
} from 'components/elements/organisms/ExaminationsV2';
import { CHART_MEASUREMENT_PARAMETER_OPTION_NAMES as PARAMETERS } from 'helpers/charts';
import React from 'react';

import { ReportTemplate } from '../components/templates';
import { PageProps } from '../types';
import { AllMeasurementsGraph } from './AllMeasurementsGraph';
import { AllMeasurementsTable } from './AllMeasurementsTable';
import { useStyles } from './styles';
import { useGetCachedForcedSpirometryReportData } from './useGetCachedForcedSpirometryReportData';

export const SecondPage = ({ title, pages, pageCount }: PageProps) => {
	const classes = useStyles();

	const { examination } = useGetCachedForcedSpirometryReportData();

	return (
		<ReportTemplate
			config={{ title, footer: { pages, pageCount }, lastPage: true }}
		>
			<Container className={classes.correctnessContainer}>
				<Correctness
					examination={examination}
					upperStatusFieldName={'fvcStatus'}
					bottomStatusFieldName={'fev1Status'}
					parametersLabels={[
						PARAMETERS.fvc.label,
						PARAMETERS.fev1.label
					]}
				/>
				<Repeatability examination={examination} />
			</Container>
			<AllMeasurementsGraph />
			<AllMeasurementsTable />
		</ReportTemplate>
	);
};
