import {
	TextInputBase,
	TextInputBaseProps
} from 'components/elements/atoms/Inputs/TextInputBase/TextInputBase';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { isError } from '../helpers';
import { useCommonConfig } from '../useCommonConfig';
import { useTextInput } from './useTextInput';

export interface ITextInputProps extends TextInputBaseProps {
	/**
	 * Field form name
	 */
	name: string;
	/**
	 * Regex expression to validate each given new char
	 */
	regexValueChangeValidation?: RegExp;
	/**
	 * Error message
	 */
	helperText?: string;
}

export const TextInput = ({
	name,
	helperText,
	regexValueChangeValidation,
	onChange: onChangeProp,
	label,
	required,
	...rest
}: ITextInputProps) => {
	const { control } = useFormContext();
	const { onBlur, createHelperTextAsError } = useCommonConfig({
		helperText,
		name
	});

	const { onChange } = useTextInput({
		onChange: onChangeProp,
		name,
		regexValueChangeValidation
	});

	return (
		<Controller
			control={control}
			name={name}
			render={({ field, fieldState, formState }) => (
				<TextInputBase
					{...rest}
					{...field}
					onBlur={onBlur}
					required={required}
					label={label}
					disabled={formState.isSubmitting}
					error={isError(fieldState)}
					helperText={createHelperTextAsError(fieldState)}
					onChange={(e) => onChange(e, field, fieldState)}
				/>
			)}
		/>
	);
};
