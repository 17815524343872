import { Box, Button, List, ListItem } from '@material-ui/core';
import { InputIcon } from 'assets/icons/commonMUIIcons';
import clsx from 'clsx';
import { useTranslations } from 'components/utilities';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink as RouterLink } from 'react-router-dom';
import { signOut } from 'store/actions';

import { useStyles } from './styles';

const CustomRouterLink = React.forwardRef((props, ref) => (
	<div ref={ref} style={{ flexGrow: 1 }}>
		<RouterLink {...props} />
	</div>
));

const Navigation = ({ pages, className, ...rest }) => {
	const classes = useStyles();

	const { t } = useTranslations();

	const dispatch = useDispatch();

	const roles = useSelector(({ auth }) => auth.authenticate.data.roles);

	const handleSignOut = () => signOut()(dispatch);

	return (
		<List {...rest} className={clsx(classes.root, className)}>
			{pages.map(
				({ role, title, href, icon }) =>
					roles.some((userRole) => role.includes(userRole)) && (
						<ListItem
							className={classes.item}
							disableGutters
							key={title}
						>
							<Button
								activeClassName={classes.active}
								className={classes.button}
								component={CustomRouterLink}
								to={href}
							>
								<Box className={classes.icon}>{icon}</Box>
								{t(title)}
							</Button>
						</ListItem>
					)
			)}
			<ListItem className={classes.item} disableGutters>
				<Button className={classes.button} onClick={handleSignOut}>
					<Box className={classes.icon}>
						<InputIcon />
					</Box>
					{t('global.logout')}
				</Button>
			</ListItem>
		</List>
	);
};

Navigation.propTypes = {
	className: PropTypes.string,
	pages: PropTypes.array.isRequired
};

export default Navigation;
