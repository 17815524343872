import { faker } from '@faker-js/faker';
import { MarketingAgreementModel } from 'models/Settings/MarketingAgreement';
import { UserSettingModel } from 'models/Settings/Settings';

faker.seed(1);

const randomWordsArray = [faker.lorem.word(), faker.lorem.word()];

faker.seed(1);
export const fakeUserSetting = (): UserSettingModel => ({
	id: faker.lorem.word(),
	availableValues: randomWordsArray,
	name: faker.helpers.arrayElement(['setting.panel']),
	platform: 'all',
	type: 'select',
	validation: randomWordsArray,
	value: [randomWordsArray[0]]
});

export const fakeMarketingAgreement = (): MarketingAgreementModel => ({
	status: 'OK',
	data: {
		processingDoctor: false,
		processingMarketing: true,
		processingTechnical: false
	}
});
