import { CHART_MEASUREMENT_PARAMETER_OPTION_NAMES } from 'helpers';
import { getSvcExaminationParameterColor } from 'helpers/examinations/colors';

import {
	IParameterValuesData,
	ParameterValuesData
} from '../../ParameterValuesData';
import { ICommonParameterBuilder } from './CommonParametersDirector';

export class ICValueBuilder implements ICommonParameterBuilder {
	private commonParameterData: IParameterValuesData;

	constructor() {
		this.commonParameterData = new ParameterValuesData();
	}

	reset = () => {
		this.commonParameterData = new ParameterValuesData();
	};

	setValidator = () => {
		this.commonParameterData.setValidator((v) => v >= 0);
	};

	setValue = (v) => {
		const parsedValue = v ? v.toFixed(2) : v;
		this.commonParameterData.setValue(parsedValue);
	};

	setColor = () => {
		const color = getSvcExaminationParameterColor(
			CHART_MEASUREMENT_PARAMETER_OPTION_NAMES.ic.name,
			this.commonParameterData.value
		);
		this.commonParameterData.setColor(color);
	};

	setUnit = (u) => {
		this.commonParameterData.setUnit(u);
	};

	getValue = () => {
		return this.commonParameterData;
	};
}
