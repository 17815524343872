import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.white,
		display: 'flex',
		position: 'relative',
		flexDirection: 'column',
		justifyContent: 'center',
		minHeight: '100vh'
	},
	siedeBannerWrapper: {
		[theme.breakpoints.down('md')]: {
			display: 'none'
		},
		'& > div': {
			height: '100%',
			paddingTop: 'initial !important'
		}
	},
	image: {
		objectFit: 'cover !important'
	},
	contentContainer: {
		flex: 1,
		padding: 10,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
		position: 'relative'
	},
	content: {
		width: '100%',
		paddingTop: 50,
		flex: 1,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	children: {
		width: '100%',
		flexBasis: 533
	},
	lng: {
		position: 'absolute',
		top: 30,
		right: 30
	}
}));
