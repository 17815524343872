// import { Point } from 'highcharts';

import _ from 'lodash';
import { Point } from 'types/Examinations/trendChart';

import {
	IDimensions,
	TooltipDimensionsFactory
} from './TooltipDimensionsFactory/TooltipDimensionsFactory';

const OFFSET_X = 30;
const OFFSET_Y = 40;

type Data = {
	active?: boolean;
	positionX?: number;
	positionY?: number;
	examinationId?: number | string;
	isTooltipXInsideChartContainer?: boolean;
	isTooltipYInsideChartContainer?: boolean;
	dimensions?: IDimensions;
	examinationType?: ExaminationType;
	chartName?: ExaminationChartNames;
	listItemIndex?: number | null;
};

export interface ITooltip extends Required<Data> {
	active: boolean;
	enabled: boolean;
	enable: () => void;
	disable: () => void;
	setActive: (isActive?: boolean) => void;
	setExaminationType: (markerTarget: Point) => void;
	setId: (id: string) => void;
	setExaminationId: (markerTarget: Point) => void;
	setTooltipDimensions: (markerTarget: Point) => void;
	setAbsolutePosition: (markerTarget: Point, highChart: TODOType) => void;
	considerIfPositionWillBeBeyondChartContainer: (
		markerTarget: Point,
		highChart: TODOType
	) => void;
}

export class Tooltip implements ITooltip {
	#active = false;
	#enabled = false;
	#positionX;
	#positionY;
	#examinationId;
	#dimensions;
	#examinationType;
	#chartName;
	#isTooltipXInsideChartContainer;
	#isTooltipYInsideChartContainer;
	#listItemIndex: number | null = null;
	constructor(data?: Data) {
		this.#active = data?.active ?? false;
		this.#positionX = data?.positionX;
		this.#positionY = data?.positionY;
		this.#isTooltipXInsideChartContainer =
			data?.isTooltipXInsideChartContainer;
		this.#isTooltipYInsideChartContainer =
			data?.isTooltipYInsideChartContainer;
		this.#examinationId = data?.examinationId;
		this.#dimensions = data?.dimensions;
		this.#examinationType = data?.examinationType;
		this.#chartName = data?.chartName;
		this.#listItemIndex = data?.listItemIndex ?? null;
	}

	get active() {
		return this.#active;
	}
	get enabled() {
		return this.#enabled;
	}
	get positionX() {
		return this.#positionX;
	}
	get positionY() {
		return this.#positionY;
	}
	get examinationId() {
		return this.#examinationId;
	}
	get dimensions() {
		return this.#dimensions;
	}
	get examinationType() {
		return this.#examinationType;
	}
	get chartName() {
		return this.#chartName;
	}
	get isTooltipXInsideChartContainer() {
		return this.#isTooltipXInsideChartContainer;
	}
	get isTooltipYInsideChartContainer() {
		return this.#isTooltipYInsideChartContainer;
	}
	get listItemIndex() {
		return this.#listItemIndex;
	}

	enable() {
		this.#enabled = true;
	}
	disable() {
		this.#enabled = false;
	}
	setActive(isActive?: boolean) {
		this.#active = isActive ?? false;
	}
	setExaminationType(markerTarget: Point) {
		this.#examinationType = markerTarget.marker.type;
	}
	setExaminationId(markerTarget: Point) {
		this.#examinationId = markerTarget.marker.examinationId;
	}
	setId(id: string) {
		this.#examinationId = id;
	}
	setExaminationListItemIndex(index: number) {
		this.#listItemIndex = index;
	}
	setTooltipDimensions(markerTarget: Point) {
		this.#chartName = markerTarget.series.userOptions
			.name as ExaminationChartNames;
		this.#dimensions = new TooltipDimensionsFactory(
			this.#chartName,
			this.#examinationType
		).calculateDimensions();
	}
	setAbsolutePosition(markerTarget: Point, highChart: TODOType) {
		this.considerIfPositionWillBeBeyondChartContainer(
			markerTarget,
			highChart
		);
		this.#positionX = this.#isTooltipXInsideChartContainer
			? this.calculatePositionInsideContainer(
					markerTarget.plotX,
					OFFSET_X
			  )
			: this.calculatePositionOutsideContainer(
					markerTarget.plotX,
					this.#dimensions.width,
					OFFSET_X / 3
			  );

		this.#positionY = this.#isTooltipYInsideChartContainer
			? this.calculatePositionInsideContainer(
					markerTarget.plotY,
					OFFSET_Y
			  )
			: this.calculatePositionOutsideContainer(
					highChart.clientHeight,
					this.#dimensions.height,
					-OFFSET_Y
			  );
	}
	considerIfPositionWillBeBeyondChartContainer(
		markerTarget: Point,
		highChart: TODOType
	) {
		this.validateDimensions();
		const chartContainerHeight: number = highChart.clientHeight;
		const chartContainerWidth: number = highChart.clientWidth;
		if (
			markerTarget.plotX !== undefined &&
			_.isNumber(markerTarget.plotX)
		) {
			this.#isTooltipXInsideChartContainer =
				this.isTooltipInsideChartContainer(
					markerTarget.plotX,
					this.#dimensions.width,
					OFFSET_X,
					chartContainerWidth
				);
		}

		if (
			markerTarget.plotY !== undefined &&
			_.isNumber(markerTarget.plotY)
		) {
			this.#isTooltipYInsideChartContainer =
				this.isTooltipInsideChartContainer(
					markerTarget.plotY,
					this.#dimensions.height,
					OFFSET_Y,
					chartContainerHeight
				);
		}
	}

	private calculatePositionInsideContainer(
		position = 0,
		offset: number
	): number {
		return position + offset;
	}

	private calculatePositionOutsideContainer(
		referenceDimension = 0,
		tooltipDimension: number,
		offset: number
	): number {
		return referenceDimension - tooltipDimension + offset;
	}

	private isTooltipInsideChartContainer(
		position: number,
		tooltipDimension: number,
		offset: number,
		chartContainerDimension: number
	): boolean {
		const tooltipExtremumPosition = position + tooltipDimension + offset;
		return tooltipExtremumPosition < chartContainerDimension;
	}

	private validateDimensions() {
		if (!this.#dimensions) {
			throw new Error('Tooltip #dimensions are not defined');
		}
	}
}
