import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	headerContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		gap: 15,
		alignItems: 'baseline',
		position: 'sticky',
		backgroundColor: 'white',
		zIndex: 99999,
		top: 80,
		paddingTop: 15
	},
	titleContainer: {
		display: 'flex',
		gap: 15,
		justifyContent: 'space-between',
		alignItems: 'baseline',
		width: '100%'
	}
}));
