import { TableCell, TableRow } from '@material-ui/core';
import { func, object } from 'prop-types';
import React from 'react';

import { COLUMNS_ACCESSORS } from '../../columns';
import { useCommonStyles } from './styles';
import {
	DateTableCell,
	DosageTableCell,
	DrugTypeTableCell,
	SettingsTableCell
} from './tableCells';

const TableBodyRow = ({ row, getSettingsCellProps = () => {} }) => {
	const classes = useCommonStyles();

	const renderCell = (cell) => {
		switch (cell.column.id) {
			case COLUMNS_ACCESSORS.DOSAGE:
				return <DosageTableCell key={cell.column.id} cell={cell} />;
			case COLUMNS_ACCESSORS.TYPE:
				return <DrugTypeTableCell key={cell.column.id} cell={cell} />;
			case COLUMNS_ACCESSORS.UNTIL:
				return <DateTableCell key={cell.column.id} cell={cell} />;
			case COLUMNS_ACCESSORS.SETTINGS:
				return (
					<SettingsTableCell
						key={cell.column.id}
						cell={cell}
						{...getSettingsCellProps()}
					/>
				);

			default:
				return (
					<TableCell
						key={cell.column.id}
						{...cell.getCellProps()}
						className={classes.cell}
					>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow {...row.getRowProps()}>{row.cells.map(renderCell)}</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
	getSettingsCellProps: func.isRequired
};

export default TableBodyRow;
