import { Container, Graph } from 'components/elements/atoms/ExaminationsV2';
import { useTranslations } from 'components/utilities';
import React from 'react';

import { useGetCachedForcedSpirometryReportData } from '../useGetCachedForcedSpirometryReportData';
import { useStyles } from './styles';

export const BestMeasureGraphs = () => {
	const classes = useStyles();
	const { t } = useTranslations();
	const { examination } = useGetCachedForcedSpirometryReportData();
	return (
		<Container className={classes.root}>
			<Graph
				data={[
					{
						isBest: true,
						data:
							examination?.bestMeasurementChart
								?.flowVolumeChart || []
					}
				]}
				xAxisLabel={t('report.graph.volume.label')}
				yAxisLabel={t('report.graph.flow.label')}
				title={{ label: 'report.graph.best_flow.title' }}
				fvcPredicted={examination?.bestParameters.fvc.predicted}
				pefPredicted={examination?.bestParameters.pef.predicted}
				axisValuesRatio="2:1"
				axisBoundaries={{
					MIN_Y: -6,
					MAX_Y: 8,
					MIN_X: -1,
					MAX_X: 8
				}}
			/>
			<Graph
				data={[
					{
						isBest: true,
						data:
							examination?.bestMeasurementChart
								?.volumeTimeChart || []
					}
				]}
				xAxisLabel={t('report.graph.time.label')}
				yAxisLabel={t('report.graph.volume.label')}
				title={{ label: 'report.graph.best_volume.title' }}
				axisValuesRatio="1:1"
				axisBoundaries={{
					MIN_Y: 0,
					MAX_Y: 6,
					MIN_X: 0,
					MAX_X: 6
				}}
				disableTriangle
			/>
		</Container>
	);
};
