import { getYearsAmountDeclinationTranslationKey } from 'helpers/language/languageDeclination';

export class PassiveSmoker {
	constructor({ patientPassiveSmokerYears }, translations) {
		this.yearsAsPassiveSmoker = patientPassiveSmokerYears;
		this.translations = translations;
	}

	getLabel = () => {
		const passiveSmokerLabel =
			this.translations['patients.basic_data.field.smoker_passive'];
		const yearsLabel =
			this.translations[
				getYearsAmountDeclinationTranslationKey(
					this.yearsAsPassiveSmoker
				)
			];
		return `${passiveSmokerLabel} (${this.yearsAsPassiveSmoker} ${yearsLabel})`;
	};
}
