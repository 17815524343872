import { PDFHistoryModel } from 'models/Report/common';

export const convertReportsHistoryToColumnsData = (
	reportsHistory: PDFHistoryModel[]
) => {
	if (reportsHistory.length === 0) {
		return [];
	}

	return reportsHistory.map((element) => ({
		...element,
		createdBy: `${element.doctor.firstName} ${element.doctor.lastName}`
	}));
};
