import { makeStyles } from '@material-ui/styles';
import { CHART_EXAMINATION_PARAMETERS_COLORS } from 'helpers';

export const useStyles = makeStyles((theme) => ({
	selectedItem: {
		color: ({ name }) => {
			return CHART_EXAMINATION_PARAMETERS_COLORS[name?.toUpperCase()];
		}
	},
	comma: {
		color: theme.palette.black,
		margin: '0px 8px 0 3px'
	}
}));
