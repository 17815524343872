import { makeStyles } from '@material-ui/styles';
import theme from 'theme';

export const TIMINGS = {
	ENTER: 300,
	EXIT: 600
};
export const useStyles = makeStyles(() => ({
	enter: {
		opacity: 1,
		maxHeight: 1000,
		overflow: 'hidden',
		'&.disable-animation': {
			opacity: 1,
			transition: `none`
		}
	},
	enterActive: {
		opacity: 0.01,
		maxHeight: ({ maxHeight }) => maxHeight || 100,
		overflow: 'hidden',
		transition: `all ${TIMINGS.ENTER}ms ${theme.transitions.easing.easeOut}`,
		'&.disable-animation': {
			opacity: 1,
			transition: `none`
		}
	},
	exit: {
		opacity: 0.01,
		maxHeight: ({ maxHeight }) => maxHeight || 100,
		overflow: 'hidden',
		'& .disable-animation': {
			opacity: 1,
			transition: `none`
		}
	},
	exitActive: {
		opacity: 1,
		maxHeight: 1000,
		overflow: 'auto',
		transition: `all ${TIMINGS.EXIT}ms ${theme.transitions.easing.easeInOut}`,
		'& .disable-animation': {
			opacity: 1,
			transition: `none`
		}
	}
}));
