import { API_URL_PREFIX } from './helpers';
import { CLINIC_ID_QUERY_PARAM, ID_QUERY_PARAM } from './queryParamKeys';

export const CLINIC_DATA_API = `${API_URL_PREFIX}/me/clinic`;
export const CLINIC_DATA_QUERY_KEY = 'CLINIC_DATA_QUERY_KEY';

export const CLINIC_DELETE_LOGO_API = `${API_URL_PREFIX}/me/clinic/image`;
export const CLINIC_DELETE_LOGO_QUERY_KEY = 'CLINIC_DELETE_LOGO_QUERY_KEY';

export const CLINICS_LIST_API = `${API_URL_PREFIX}/clinics/list`;
export const CREATE_CLINIC_API = `${API_URL_PREFIX}/clinics/create`;
export const LEAVE_CLINIC_API = `${API_URL_PREFIX}/clinic/${CLINIC_ID_QUERY_PARAM}/leave`;

export const CLINIC_INVITATION_DATA_API = `${API_URL_PREFIX}/clinics/new-user/invitations/${ID_QUERY_PARAM}`;

// QUERIES
export const CLINICS_TABLE_QUERY_KEY = 'CLINICS_TABLE_QUERY_KEY';
