import { Typography as MuiTypography } from '@material-ui/core';
import { useTranslations } from 'components/utilities';
import { array, bool } from 'prop-types';
import React from 'react';

import { useStyles } from './styles';

const EmptyTableContent = ({ data = [], isSuccess, isMutateError }) => {
	const classes = useStyles();
	const { t } = useTranslations();

	return (
		data?.length === 0 && (
			<>
				{isSuccess && !isMutateError && (
					<MuiTypography
						className={classes.noData}
						variant="subtitle1"
					>
						{t('global.no_data')}
					</MuiTypography>
				)}
				{isMutateError && (
					<MuiTypography
						className={classes.noData}
						variant="subtitle1"
					>
						ERROR
					</MuiTypography>
				)}
			</>
		)
	);
};
EmptyTableContent.propTypes = {
	data: array,
	isSuccess: bool.isRequired,
	isMutateError: bool.isRequired
};

export default EmptyTableContent;
