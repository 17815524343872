import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		borderRadius: '50%',
		width: ({ size }) => size,
		height: ({ size }) => size,
		backgroundColor: ({ color }) => color
	}
}));
