import { SMOKER_OPTIONS_VALUES } from 'helpers/variables';

import Store from '../../../../store/store.js';
import { ActiveSmoker, ExSmoker, NotASmoker, PassiveSmoker } from '.';

export type SmokerType =
	| typeof SMOKER_OPTIONS_VALUES.NO
	| typeof SMOKER_OPTIONS_VALUES.YES
	| typeof SMOKER_OPTIONS_VALUES.EX_SMOKER
	| typeof SMOKER_OPTIONS_VALUES.PASSIVE_SMOKER;

interface ISmokerLabelFactory {
	createSmokerLabel: (type: SmokerType) => string;
}

export interface ISmokerLabelType {
	getLabel: () => string;
}

export class SmokerLabelFactory implements ISmokerLabelFactory {
	createSmokerLabel: ISmokerLabelFactory['createSmokerLabel'];

	constructor(data) {
		this.createSmokerLabel = function (type) {
			let smokerLabelType: ISmokerLabelType;
			const {
				language: { translations }
			} = Store.getState();

			switch (type) {
				case SMOKER_OPTIONS_VALUES.NO:
					smokerLabelType = new NotASmoker(translations);
					break;
				case SMOKER_OPTIONS_VALUES.YES:
					smokerLabelType = new ActiveSmoker(data, translations);
					break;
				case SMOKER_OPTIONS_VALUES.EX_SMOKER:
					smokerLabelType = new ExSmoker(data, translations);
					break;
				case SMOKER_OPTIONS_VALUES.PASSIVE_SMOKER:
					smokerLabelType = new PassiveSmoker(data, translations);
					break;
				default:
					smokerLabelType = new NotASmoker(translations);
					break;
			}

			return smokerLabelType.getLabel();
		};
	}
}
