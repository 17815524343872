import palette from 'theme/palette';

export const LEGEND_ITEMS = [
	{
		label: 'report.bronchodilator.pre',
		lineColor: palette.report.blue.dark
	},
	{
		label: 'report.bronchodilator.post',
		lineColor: palette.orange.secondary
	}
];
