import { useExaminationsV2Context } from 'components/context/ExaminationsV2';
import { LoaderSpinnerTransitioned } from 'components/elements';
import { GET_EXAMINATION_REPORT_DATA_QUERY_KEY } from 'helpers';
import React from 'react';
import { queryClient } from 'utilities';

import { useExaminationDataContext } from '../../context';
import { ContainerWithBorder } from '../ContainerWithBorder';
import { ParametersTable } from '../ParametersTable';
import { Title } from '../Typography';
import { createColumns } from './columns';
import { convertReportsHistoryToColumnsData } from './helpers';
import { useCellStyles, useStyles } from './styles';

export const ReportPDFHistory = () => {
	const classes = useStyles();
	const cellClasses = useCellStyles();

	const columns = React.useMemo(() => createColumns(cellClasses), []);

	const { store: activeExamination } = useExaminationsV2Context(
		(s) => s.activeExamination
	);
	const { examinationData } = useExaminationDataContext();

	const isExaminationFetching =
		queryClient.getQueryState(
			GET_EXAMINATION_REPORT_DATA_QUERY_KEY(
				/* eslint-disable */
				activeExamination.id!.toString(),
				activeExamination.type!
				/*eslint-enable*/
			)
		)?.isFetching || false;

	const history = examinationData?.reports?.history;

	if (history === undefined || history.length === 0) {
		return null;
	}

	return (
		<ContainerWithBorder className={classes.root}>
			<Title
				title="patients.examinations_v2.details.pdf_history.title"
				className={classes.font}
			/>
			<ParametersTable
				columns={columns}
				data={convertReportsHistoryToColumnsData(history || [])}
				rowClassName={classes.row}
			/>
			<LoaderSpinnerTransitioned
				isLoading={isExaminationFetching}
				classNameRoot={classes.loader}
			/>
		</ContainerWithBorder>
	);
};
