import { colors } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	list: {
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			justifyContent: 'space-around',
			flexWrap: 'wrap',
			paddingTop: 0,
			paddingBottom: 0
		}
	},
	item: {
		display: 'flex',
		paddingTop: 0,
		paddingBottom: 0,
		[theme.breakpoints.down('md')]: {
			width: 'auto'
		}
	},
	button: {
		color: colors.blueGrey[800],
		padding: '10px 8px',
		paddingLeft: 30,
		justifyContent: 'flex-start',
		textTransform: 'none',
		letterSpacing: 0,
		width: '100%',
		fontWeight: theme.typography.fontWeightMedium,
		fontSize: 16,
		'& svg': {
			height: 16,
			width: 16,
			marginBottom: 3
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: 10,
			padding: '10px 4px'
		}
	},
	icon: {
		color: theme.palette.icon,
		width: 24,
		height: 24,
		display: 'flex',
		alignItems: 'center',
		marginRight: theme.spacing(1)
	},
	active: {
		color: theme.palette.primary.main,
		'& path': {
			fill: theme.palette.primary.main
		},
		fontWeight: theme.typography.fontWeightMedium
	}
}));
