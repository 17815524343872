import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	noData: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		fontSize: '20px'
	}
}));
