import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		height: 49.625
	},
	wrapper: {
		marginTop: 10,
		width: '100%'
	},
	icon: { width: 16, height: 16 },
	iconWrapper: { cursor: 'pointer', marginLeft: 7 }
}));
