import React from 'react';

import ChartParameters from './ChartParameters';
import SelectedParameters from './SelectedParameters';

// eslint-disable-next-line
const MeasurementParameters = () => {
	return (
		<>
			<ChartParameters />
			<SelectedParameters />
		</>
	);
};

export default MeasurementParameters;
