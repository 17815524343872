import { Grid } from '@material-ui/core';
import { useTranslations } from 'components/utilities';
import { anyNumberWithDecimalRegex } from 'helpers/validation';
import { number } from 'prop-types';
import React from 'react';
import { Field, useFormState } from 'react-final-form';

import { TextFieldAdapter } from '../../../../../../Inputs';

const PassiveSmokerFields = ({ maxYearsAsSmoker }) => {
	const { t } = useTranslations();

	const { submitting } = useFormState();
	return (
		<Grid item lg={12} xs={12}>
			<Field
				fullWidth
				component={TextFieldAdapter}
				regex={anyNumberWithDecimalRegex(Infinity)}
				type="number"
				label={t('patients.form.patient_passive_smoker_years')}
				name={`patientPassiveSmokerYears`}
				margin="normal"
				variant="outlined"
				autoComplete="false"
				inputProps={{ min: 1, max: maxYearsAsSmoker }}
				disabled={submitting}
				required
			/>
		</Grid>
	);
};

PassiveSmokerFields.propTypes = {
	maxYearsAsSmoker: number.isRequired
};

export default PassiveSmokerFields;
