import { StatusCell } from 'components/elements/atoms/ExaminationsV2';
import { IColumn } from 'components/elements/organisms/ExaminationsV2/ParametersTable/ParametersTable';
import { TypeItemModel } from 'models/Report/CalibrationCheck';
import { ColumnValues } from 'utilities/Examinations/ExaminationDetails/common';

export type TypeItemModelWithRefValue = TypeItemModel & {
	refValue: string;
};

type Accessor = Leaves<ColumnValues<TypeItemModelWithRefValue>>;

export const columns: IColumn<Accessor>[] = [
	{ Header: '', accessor: 'parameterTypeLabel' },
	{ Header: 'report.best_results.value_with_l_unit', accessor: 'value' },
	{ Header: 'report.best_results.ref_value', accessor: 'refValue' },
	{ Header: 'report.best_results.accuracy', accessor: 'accuracy' },
	{ Header: 'report.best_results.deviation', accessor: 'deviation' },
	{
		Header: 'report.best_results.status',
		accessor: 'status',
		component: StatusCell
	}
];
