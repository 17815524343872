import { TableCell } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { PrintIcon } from 'assets/icons/commonMUIIcons';
import { object, string } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
	cell: {
		display: 'flex',
		alignItems: 'center'
	},
	icon: {
		width: 20,
		height: 20,
		display: 'flex',
		alignItems: 'center'
	}
}));

const PrintTableCell = ({ cell, printLinkPath }) => {
	const classes = useStyles();

	const { lng } = useSelector((store) => store.language);

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<Link
				to={`${printLinkPath}?lng=${lng}`}
				className={classes.cell}
				target="_blank"
			>
				<PrintIcon
					className={classes.icon}
					fontSize="small"
					color="primary"
				/>
			</Link>
		</TableCell>
	);
};

PrintTableCell.propTypes = {
	cell: object.isRequired,
	printLinkPath: string.isRequired
};

export default PrintTableCell;
