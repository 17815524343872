import { makeStyles } from '@material-ui/styles';
import theme from 'theme';

export const useStyles = makeStyles<typeof theme>(() => ({
	contentLabel: {
		textAlign: 'center',
		fontSize: 18,
		lineHeight: '20px'
	},
	contentContainer: {
		paddingTop: '50px !important',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center'
	}
}));
