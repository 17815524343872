import { API_URL_PREFIX } from '../helpers';

// MY PROFILE
export const MY_PROFILE_USER_PARAM_KEY = ':doctorId';
export const MY_PROFILE_USER_RESOURCES_API = `${API_URL_PREFIX}/workers/doctor/${MY_PROFILE_USER_PARAM_KEY}`;
export const MY_PROFILE_EDIT_USER_API = `${API_URL_PREFIX}/workers/doctor/${MY_PROFILE_USER_PARAM_KEY}`;
export const MY_PROFILE_EDIT_PASSWORD_API = `${API_URL_PREFIX}/workers/doctor/${MY_PROFILE_USER_PARAM_KEY}/password`;
export const FIREBASE_MESSAGING_TOKEN_API = `${API_URL_PREFIX}/device_token`;
// QUERY
export const MY_PROFILE_USER_RESOURCES_QUERY_KEY =
	'MY_PROFILE_USER_RESOURCES_QUERY_KEY';
