import { ActiveExaminationBase } from 'models/Examinations/common';
import { ExaminationsListConvertedItemModel } from 'models/Examinations/ExaminationsList';
import { ExaminationsListServicesV2 } from 'queries';

/**
 * Validate active examination by checking index of potentially new active examination. Returns its index in the list.
 * @param {ExaminationsListConvertedItemModel[]} examinationsListData - An array of examinations list data
 * @param {ActiveExaminationBase | null} activeExamination - The active examination to be validated
 * @returns {number} The index of the active examination in the list or -1 if it is not found
 */
export const validateActiveExamination = (
	examinationsListData: ExaminationsListConvertedItemModel[],
	activeExamination: ActiveExaminationBase | null
): number => {
	if (activeExamination?.id && activeExamination?.type) {
		const examinationListIndex =
			ExaminationsListServicesV2.getExaminationIndexFromList({
				examinationsList: examinationsListData,
				...activeExamination
			});
		return examinationListIndex;
	}
	return -1;
};
