import { makeStyles } from '@material-ui/styles';
import palette from 'theme/palette';

export const useStyles = makeStyles(() => ({
	text: {
		lineHeight: '15px'
	},
	labelWithSecondaryText: {
		width: '100%',
		height: '100%',
		display: 'flex',
		gap: 5,
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	secondaryText: {
		lineHeight: '12px',
		fontSize: '12px',
		color: palette.gray.dark,
		flex: 1,
		textAlign: 'end',
		minWidth: 50,
		textTransform: 'capitalize'
	},
	helperText: {
		fontSize: '11px',
		lineHeight: '12px',
		color: palette.primary.dark,
		fontWeight: 600
	}
}));
