import { TextField, Typography as MuiTypography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { useTranslations } from 'components/utilities';
import { bool, object, string } from 'prop-types';
import { oneOfType } from 'prop-types';
import { number } from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center'
	},
	label: {
		fontWeight: 'bold',
		marginRight: theme.spacing(2),

		display: 'inline-block'
	},
	input: {
		'& .MuiInputBase-root': {
			minWidth: ({ fieldWidth }) => fieldWidth
		}
	},
	disabledInput: {
		'& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline':
			{
				borderColor: 'transparent'
			},
		'& .MuiOutlinedInput-root': {
			border: '1px solid rgba(0, 0, 0, 0.23)'
		},
		'& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
			display: 'none'
		},
		'& .MuiInputBase-input.Mui-disabled': {
			color: '#263238'
		},
		'&::placeholder': {
			display: 'none'
		}
	},
	borderInput: {
		'& .MuiOutlinedInput-inputMarginDense': {
			padding: '4px'
		},
		'& .MuiOutlinedInput-root': {
			border: '1px solid transparent'
		},
		'& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
			display: 'none'
		}
	}
}));

const TextFieldLabelAdapter = ({
	input,
	meta,
	isDisabled = false,
	isBorder = true,
	labelWidth = '105px',
	fieldWidth = '300px',
	classNameLabel = '',
	outerLabel,
	...rest
}) => {
	const { t } = useTranslations();
	const classes = useStyles({ fieldWidth });

	const { touched, error, submitError } = meta;

	const isError = touched && (error || submitError) ? true : false;
	const errorText =
		touched && (error || submitError) ? t(error) || submitError : '';

	const inputDisabledClass = `${isDisabled ? classes.disabledInput : ''}`;
	const inputBorderClass = isBorder ? '' : classes.borderInput;

	return (
		<div className={classes.root}>
			<MuiTypography
				style={{ minWidth: labelWidth }}
				component="p"
				className={`${classes.label} ${classNameLabel}`}
			>
				{outerLabel} {rest.required && '*'}
			</MuiTypography>
			<TextField
				{...input}
				{...rest}
				className={clsx(
					inputDisabledClass,
					inputBorderClass,
					classes.input
				)}
				error={isError}
				helperText={errorText}
			/>
		</div>
	);
};

TextFieldLabelAdapter.propTypes = {
	input: object.isRequired,
	meta: object.isRequired,
	isDisabled: bool,
	isBorder: bool,
	labelWidth: oneOfType([string, number]),
	classNameLabel: string,
	fieldWidth: oneOfType([string, number]),
	outerLabel: string.isRequired
};

export default TextFieldLabelAdapter;
