import { Button, Grid } from '@material-ui/core';
import { ButtonProgress } from 'components/elements';
import { useTranslations } from 'components/utilities';
import { func } from 'prop-types';
import React from 'react';
import { useFormState } from 'react-final-form';

import { useStyles } from './styles';

const ActionButtons = ({ onCancel }) => {
	const classes = useStyles();
	const { t } = useTranslations();
	const { submitting } = useFormState();
	return (
		<Grid container spacing={2}>
			<Grid item xs={6}>
				<Button
					className={classes.btnWrapper}
					onClick={onCancel}
					fullWidth
					size="medium"
					type="button"
					variant="outlined"
					disabled={submitting}
				>
					{t('btn.cancel')}
				</Button>
			</Grid>

			<Grid item xs={6}>
				<ButtonProgress
					classNameWrapper={classes.btnWrapper}
					color="primary"
					fullWidth
					size="medium"
					type="submit"
					variant="contained"
					isLoading={submitting}
					disabled={submitting}
				>
					{t('btn.add')}
				</ButtonProgress>
			</Grid>
		</Grid>
	);
};

ActionButtons.propTypes = {
	onCancel: func.isRequired
};

export default ActionButtons;
