import React from 'react';
import ReactCountryFlag from 'react-country-flag';

type Props = {
	countryCode: Locale['code'];
	style?: React.CSSProperties;
	className?: string;
	margin?: 'normal' | null;
};

const CountryFlag = ({
	countryCode,
	style,
	className,
	margin = null
}: Props) => {
	if (!countryCode) {
		return null;
	}

	return (
		<ReactCountryFlag
			style={{
				position: 'absolute',
				top: margin === 'normal' ? 2 : 15,
				left: 7,
				zIndex: 9,
				fontSize: '1.5em',
				...style
			}}
			className={className}
			countryCode={countryCode}
			title={countryCode}
			aria-label={countryCode}
			alt={countryCode}
		/>
	);
};

export default CountryFlag;
