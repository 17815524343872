import { produce } from 'immer';
import * as types from 'store/types';

const INITIAL_STATE = {
	height: 178
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.SET_LAYOUT_BOX_BACKGROUND_HEIGHT:
				draft.height = action.payload;
				return;

			default:
				return state;
		}
	});
