import { z } from 'zod';

import { CommonReportFieldsSchema } from './common';
import { ForcedSpirometryReportSchema } from './ForcedSpirometry';

export const TestResultSchema = z.union([
	z.literal('negative'),
	z.literal('positive'),
	z.literal('undefined')
]);

export const DeltaSchema = z.object({
	value: z.number().nullable(),
	percentage: z.number().nullable()
});

export const ObstructionSchema = z.union([
	z.literal('undefined'),
	z.literal('irreversible'),
	z.literal('reversible')
]);

export const BronchodilatorReportSchema = CommonReportFieldsSchema.merge(
	z.object({
		pre: ForcedSpirometryReportSchema,
		post: ForcedSpirometryReportSchema,
		changePercentage: z.object({
			fvc: z.number().nullable(),
			fev1: z.number().nullable(),
			fev1Fvc: z.number().nullable(),
			pef: z.number().nullable(),
			fef25: z.number().nullable(),
			fef50: z.number().nullable(),
			fef75: z.number().nullable(),
			fef25_75: z.number().nullable(),
			bev: z.number().nullable(),
			fet: z.number().nullable(),
			tpef: z.number().nullable()
		}),
		testResult: z.object({
			result: TestResultSchema,
			deltaFvc: DeltaSchema,
			deltaFev1: DeltaSchema,
			obturation: ObstructionSchema
		})
	})
);
