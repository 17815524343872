import { Box } from '@material-ui/core';
import {
	ForcedSpirometryBestParametersModel,
	ForcedSpirometryMeasurementModel
} from 'models/Report/ForcedSpirometry';
import React from 'react';
import {
	addBestValueToAllMeasurements,
	convertBestResultsToColumnsData
} from 'utilities/Examinations/ExaminationDetails/ForcedSpirometryFVC';
import { FVC_ALL_PARAMETERS } from 'utilities/Examinations/ExaminationDetails/helpers';

import { ParametersTable, Title } from '../../../components';
import { columns } from './columns';

type Props = {
	measurements: ForcedSpirometryMeasurementModel[];
	bestParameters: ForcedSpirometryBestParametersModel | undefined;
	activeMeasurementIndex: number;
	type: 'pre' | 'post';
};
export const AllMeasurements = ({
	measurements,
	bestParameters,
	activeMeasurementIndex,
	type
}: Props) => {
	return (
		<Box>
			<Title
				title={`patients.examinations_v2.details.copd.all_measurements.table.${type}.title`}
			/>
			<ParametersTable
				columns={columns}
				data={convertBestResultsToColumnsData(
					addBestValueToAllMeasurements(measurements, bestParameters)[
						activeMeasurementIndex
					],
					FVC_ALL_PARAMETERS
				)}
			/>
		</Box>
	);
};
