import { IRequestConfirmationModalDialogProps } from 'components/elements/organisms/ModalDialog/RequestConfirmationModalDialog';

export const linkModalProps = (
	patientFullName: string
): Partial<IRequestConfirmationModalDialogProps> => ({
	modalTitle: 'patients.invitation.title',
	actionButtonsProps: {
		confirm: {
			label: 'btn.invite',
			color: 'primary'
		}
	},
	successProps: {
		textContent: {
			key: 'patient.invitation.success_message',
			replaceKeys: {
				['%1']: `<strong>${patientFullName}</strong>`
			}
		}
	},
	modalTextContent: {
		key: 'patients.invitation.invite_by_email_message',
		replaceKeys: {
			['%1']: `<strong>${patientFullName}</strong>`
		}
	},
	textAlign: 'left'
});

export const unlinkModalProps = (
	patientFullName: string
): Partial<IRequestConfirmationModalDialogProps> => ({
	modalTitle: 'patient.invitation.disconnect.title',
	actionButtonsProps: {
		confirm: {
			label: 'btn.disconnect',
			color: 'error'
		}
	},
	successProps: {
		textContent: {
			key: 'patient.invitation.disconnect.success',
			replaceKeys: {
				['%1']: `<strong>${patientFullName}</strong>`
			}
		}
	},
	modalTextContent: {
		key: 'patient.invitation.disconnect.message',
		replaceKeys: {
			['%1']: `<strong>${patientFullName}</strong>`
		}
	}
});
