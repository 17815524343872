import { Box } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/styles';
import clsx from 'clsx';
import {
	StackedParameters,
	Title
} from 'components/elements/atoms/ExaminationsV2';
import { CHART_MEASUREMENT_PARAMETER_OPTION_NAMES as PARAMETERS } from 'helpers/charts';
import { ForcedSpirometryReportModel } from 'models/Report/Reports';
import React from 'react';

import { Grade } from './Grade';
import { useStyles } from './styles';

export type LocalTheme = () => void | ClassNameMap<
	| 'font'
	| 'stackedParametersContainer'
	| 'stackedParametersLabel'
	| 'gradeContainer'
	| 'gradeLabel'
	| 'gradesContainer'
	| 'scores'
	| 'root'
>;

type Props = {
	examination: ForcedSpirometryReportModel | undefined;
	className?: string;
	useLocalTheme?: LocalTheme;
};
export const Repeatability = ({
	className,
	examination,
	useLocalTheme = () => {}
}: Props) => {
	const classes = useStyles();
	const localTheme = useLocalTheme();

	const grades = React.useMemo<
		| ForcedSpirometryReportModel['scoreFvc'][]
		| ForcedSpirometryReportModel['scoreFev1'][]
	>(() => ['A', 'B', 'C', 'D', 'E', 'U', 'F'], []);

	return (
		<Box flex={1} className={clsx([localTheme?.root, className])}>
			<Title
				variant="secondary"
				title="report.repeatability.title"
				className={localTheme?.font}
			/>
			<Box className={clsx([localTheme?.scores, classes.scores])}>
				<StackedParameters
					className={clsx([
						localTheme?.stackedParametersContainer,
						classes.stackedParameters
					])}
					parameters={[PARAMETERS.fvc.label, PARAMETERS.fev1.label]}
					parameterLabelClassName={localTheme?.stackedParametersLabel}
				/>
				<Box
					className={clsx([
						localTheme?.gradesContainer,
						classes.container
					])}
				>
					<Box className={classes.grades}>
						{grades.map((grade) => (
							<Grade
								key={grade}
								grade={grade}
								isActive={examination?.scoreFvc === grade}
								gradeContainerClassName={
									localTheme?.gradeContainer
								}
								gradeLabelClassName={localTheme?.gradeLabel}
							/>
						))}
					</Box>
					<Box className={classes.grades}>
						{grades.map((grade) => (
							<Grade
								key={grade}
								grade={grade}
								isActive={examination?.scoreFev1 === grade}
								gradeContainerClassName={
									localTheme?.gradeContainer
								}
								gradeLabelClassName={localTheme?.gradeLabel}
							/>
						))}
					</Box>
				</Box>
			</Box>
		</Box>
	);
};
