import { EXAMINATIONS_TYPES } from 'helpers/variables';
import { ExaminationCreationDate } from 'models/Examinations/ExaminationsList';

export const RESULT_EXAMINATIONS = {
	ITEM_DATE: 15,
	ITEM_DEFAULT: 110,
	ITEM_SPIROMETRY: 183,
	ITEM_PRE_BRONCHODILATOR_SPIROMETRY: 196,
	ITEM_FINAL_BRONCHODILATOR_SPIROMETRY: -10,
	ITEM_SPIROMETRY_MANUAL: 183,
	ITEM_BRONCHODILATOR: 181,
	ITEM_PULSE_OXIMETRY: 183,
	ITEM_PEAK_FLOW: 124,
	ITEM_MEDICAL_NOTE: 124
};
const {
	ITEM_DATE,
	ITEM_DEFAULT,
	ITEM_SPIROMETRY,
	ITEM_PRE_BRONCHODILATOR_SPIROMETRY,
	ITEM_FINAL_BRONCHODILATOR_SPIROMETRY,
	ITEM_BRONCHODILATOR,
	ITEM_PULSE_OXIMETRY,
	ITEM_PEAK_FLOW,
	ITEM_MEDICAL_NOTE
} = RESULT_EXAMINATIONS;

export const getExaminationCellHeight = (examination: {
	type: string;
	examinationType?: string;
}): number => {
	let height: number;
	switch (examination.type) {
		case EXAMINATIONS_TYPES.CREATION_DATE.type:
			height = ITEM_DATE;
			break;
		case EXAMINATIONS_TYPES.PEAK_FLOW.type:
			height = ITEM_PEAK_FLOW;
			break;
		case EXAMINATIONS_TYPES.PULSE_OXIMETRY.type:
			height = ITEM_PULSE_OXIMETRY;
			break;
		case EXAMINATIONS_TYPES.SPIROMETRY.type:
		case EXAMINATIONS_TYPES.SVC.type:
			switch (examination.examinationType) {
				case EXAMINATIONS_TYPES.COPD_TEST.type:
					height = ITEM_PRE_BRONCHODILATOR_SPIROMETRY;
					break;
				case EXAMINATIONS_TYPES.COPD_FINAL.type:
					height = ITEM_FINAL_BRONCHODILATOR_SPIROMETRY;
					break;
				default:
					height = ITEM_SPIROMETRY;
					break;
			}
			break;
		case EXAMINATIONS_TYPES.COPD_TEST.type:
			height = ITEM_BRONCHODILATOR;
			break;
		case EXAMINATIONS_TYPES.MEDICAL_NOTE.type:
			height = ITEM_MEDICAL_NOTE;
			break;
		default:
			height = ITEM_DEFAULT;
			break;
	}
	return height + 10;
};

export const createCreationDateListItem = (
	date: string
): ExaminationCreationDate => ({
	createDateTime: date,
	type: EXAMINATIONS_TYPES.CREATION_DATE.type,
	height: getExaminationCellHeight({
		type: EXAMINATIONS_TYPES.CREATION_DATE.type
	})
});
export const EXAMINATION_TYPES_FILTER_OPTIONS: Array<{
	value: ExaminationTypeParam;
	label: string;
}> = [
	{
		value: EXAMINATIONS_TYPES.SPIROMETRY.type,
		label: 'global.examination_type.spirometry'
	},
	{
		value: EXAMINATIONS_TYPES.SVC.type,
		label: 'global.examination_type.svc'
	},
	{
		value: EXAMINATIONS_TYPES.COPD_TEST.type,
		label: 'global.examination_type.broncholidator'
	},
	{
		value: EXAMINATIONS_TYPES.PULSE_OXIMETRY.type,
		label: 'global.examination_type.pule_oximetry'
	},
	{
		value: EXAMINATIONS_TYPES.PEAK_FLOW.type,
		label: 'global.examination_type.peakflow'
	}
];
