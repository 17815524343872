import { format } from 'date-fns';
import { DATE_FORMAT } from 'helpers/variables';

export const DoctorServices = {
	/**
	 * convert doctor data in signup doctor form
	 * @param {*} args
	 * @param {object} args.values form data
	 * @param {object} args.values.birth_date instance of Date class - doctor birth date
	 * @returns {object} form data accepted by backend
	 */
	convertDoctorFormsPayload: ({ values }) => {
		const { birth_date: birthDate } = values;

		const convertedBirthDate =
			birthDate instanceof Date
				? format(birthDate, DATE_FORMAT.KEYBOARD_DATE_PICKER_VALUE)
				: birthDate;
		return {
			...values,
			birth_date: convertedBirthDate
		};
	}
};
