import { Box } from '@material-ui/core';
import { ExpandMoreIcon } from 'assets/icons/commonMUIIcons';
import { useExaminationsV2Context } from 'components/context/ExaminationsV2';
import { MenuItemWithCheckBox, SelectField } from 'components/elements';
import { useTranslations } from 'components/utilities';
import { EXAMINATION_TYPES_FILTER_OPTIONS } from 'queries/Examinations/ExaminationsList/helpers';
import React from 'react';

import { useStyles as useCommonStyles } from '../styles';
import { useStyles } from './styles';
import useExaminationTypeFilter from './useExaminationTypeFilter';

export const ExaminationTypeFilter = () => {
	const classes = useStyles();
	const commonClasses = useCommonStyles();
	const { t } = useTranslations();
	const {
		selectedExaminationTypes,
		isDisabled,
		renderValue,
		toggleSelectedExaminationTypes,
		selectAllExaminationTypes,
		selectOneExaminationType,
		iValueValid
	} = useExaminationTypeFilter();

	const { store: availableExaminationTypesInList } = useExaminationsV2Context(
		(s) => s.availableExaminationTypesInList
	);

	const renderMenuItem = ({ label, value }, currentValue) => {
		return (
			<MenuItemWithCheckBox
				key={label}
				onClick={() => {
					toggleSelectedExaminationTypes(value);
				}}
				onSelectAll={() => {
					selectAllExaminationTypes();
				}}
				onSelectOne={() => {
					selectOneExaminationType(value);
				}}
				label={label}
				state={currentValue}
				isSelected={currentValue?.includes(value)}
				isOneSelected={currentValue.length === 1}
				disabled={!availableExaminationTypesInList.includes(value)}
				disableAllActions={availableExaminationTypesInList.length <= 1}
			/>
		);
	};

	return (
		<Box className={commonClasses.filtersContainer}>
			<SelectField<ExaminationTypeParam[]>
				initialValue={selectedExaminationTypes}
				multiple
				variant="outlined"
				margin="dense"
				renderValue={renderValue}
				IconComponent={ExpandMoreIcon}
				className={classes.root}
				label={t('global.examination_type.name')}
				isValid={iValueValid}
				value={selectedExaminationTypes}
				disabled={isDisabled}
			>
				{({ value }) =>
					EXAMINATION_TYPES_FILTER_OPTIONS.map((option) =>
						renderMenuItem(option, value)
					)
				}
			</SelectField>
			<Box className={classes.spacer} />
		</Box>
	);
};
