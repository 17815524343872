import { TextField, TextFieldProps } from '@material-ui/core';
import { ReactHMLParserType } from 'components/utilities/hooks/translations/types';
import useTranslations from 'components/utilities/hooks/translations/useTranslations';
import React from 'react';

import { EMPTY_INPUT_LABEL_TEXT } from '../../../molecules/Inputs/V2/common';

export type TextInputBaseProps = {
	/**
	 * Text input label
	 */
	label?: TranslationKey;
	/**
	 * Input form name
	 */
	name?: string;
	/**
	 * Determines if input is disabled for any edit
	 */
	disabled?: boolean;
	/**
	 * Determines if field is required. Renders * sign next to input label
	 */
	required?: boolean;
	/**
	 * Determines if input is in error mode
	 */
	error?: boolean;
	/**
	 * Text rendered below input field
	 */
	helperText?: string | ReactHMLParserType | null;
	/**
	 * Determines if input should contain all available width space
	 */
	fullWidth?: boolean;
} & Omit<TextFieldProps, 'label'>;

export const TextInputBase = React.forwardRef<
	HTMLDivElement,
	TextInputBaseProps
>(
	(
		{
			label,
			variant = 'outlined',
			autoComplete = 'off',
			type = 'text',
			helperText = '',
			name = '',
			fullWidth = true,
			required = false,
			disabled = false,
			error = false,
			...rest
		},
		ref
	) => {
		const { t } = useTranslations();
		return (
			<TextField
				ref={ref}
				{...rest}
				fullWidth={fullWidth}
				helperText={helperText}
				name={name}
				disabled={disabled}
				error={error}
				type={type}
				variant={variant}
				autoComplete={autoComplete}
				label={label ? t(label) : EMPTY_INPUT_LABEL_TEXT}
				required={required}
			/>
		);
	}
);
