import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	notes: {
		display: 'flex',
		alignItems: 'center',
		gap: 5,
		minHeight: ({ height }) => height
	},
	examinationNotesContainer: {
		height: '100%',
		flex: 1
	},
	reportInterpretationContainer: {
		flex: 2,
		position: 'relative',
		height: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	form: {
		height: '100%',
		'& div': {
			display: 'flex',
			flexDirection: 'column',
			flex: 1
		}
	},
	text: {
		fontSize: '10.5px',
		lineHeight: '13.4px',
		color: theme.palette.gray.medium,
		overflowWrap: 'anywhere',
		flex: 1
	}
}));
