import React from 'react';

import { ReportContextProvider } from './context';
import { ReportWithContext } from './ReportWithContext';

export const Report = () => {
	return (
		<ReportContextProvider>
			<ReportWithContext />
		</ReportContextProvider>
	);
};
