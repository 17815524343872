import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	wrapper: {
		display: 'flex',
		alignItems: 'center',
		flexFlow: 'row wrap',
		marginBottom: 8,
		'& > :first-child': {
			marginRight: 8
		}
	},
	button: {
		marginTop: theme.spacing(1)
	},
	typography: {
		paddingTop: 5
	}
}));
