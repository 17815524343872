import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	button: {
		textTransform: 'none',
		'& .MuiButton-startIcon': {
			marginBottom: 2
		}
	}
}));
