import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { AccountCircleIcon } from 'assets/icons/commonMUIIcons';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		minHeight: 'fit-content'
	},
	avatarBox: {
		width: 60,
		height: 60,
		borderRadius: 50,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	avatar: {
		width: 60,
		height: 60,
		color: theme.palette.primary.main
	},
	imgAvatar: {
		position: 'relative',
		left: '1px',
		objectFit: 'none',
		textIndent: '1px'
	},
	name: {
		marginTop: theme.spacing(1)
	},
	role: { textTransform: 'capitalize' }
}));

const Profile = ({ className, ...rest }) => {
	const { user, roles } = useSelector(({ auth }) => auth.authenticate.data);
	const { firstName, lastName } = user;
	const [role] = roles;

	const classes = useStyles();

	return (
		<Box {...rest} className={clsx(classes.root, className)}>
			<Box className={classes.avatarBox}>
				<AccountCircleIcon className={classes.avatar} />
			</Box>
			<Typography className={classes.name} variant="h4">
				{firstName} {lastName}
			</Typography>
			<Typography className={classes.role} variant="body2">
				{role}
			</Typography>
		</Box>
	);
};

Profile.propTypes = {
	className: PropTypes.string
};

export default Profile;
