import { useContext } from 'react';

// Import context
import { PatientFormContext } from './PatientFormProvider';

const usePatientContextProvider = () => {
	const context = useContext(PatientFormContext);

	if (!context) {
		throw new Error(
			'usePatientContextProvider must be used within a PatientFormProvider'
		);
	}

	return { ...context };
};
export default usePatientContextProvider;
