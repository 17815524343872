export default {
	h1: {
		textTransform: 'none'
	},
	h3: {
		textTransform: 'none'
	},
	h4: {
		textTransform: 'none'
	},
	h5: {
		textTransform: 'none'
	},
	h6: {
		textTransform: 'none'
	},
	subtitle1: {
		textTransform: 'none'
	},
	subtitle2: {
		textTransform: 'none'
	},
	caption: {
		textTransform: 'none'
	},
	overline: {
		textTransform: 'none'
	},
	gutterBottom: {
		marginBottom: 8
	}
};
