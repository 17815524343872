import { UNITS_TYPES } from 'helpers/variables';
import theme from 'theme';

// Import classes
import { createAxisWithGroup } from '../classes';
import { CHART_MEASUREMENT_PARAMETER_OPTION_NAMES as CHART_PARAMETERS_NAMES } from '../parameters';

const CHART_TYPES = {
	SPLINE: 'spline',
	COLUMN: 'column'
};
const { SPLINE } = CHART_TYPES;

const { PB, L, L_S, BPM } = UNITS_TYPES;
const pbLabel = `${PB.unit} ${PB.label}`;

const {
	fev1: { label: FEV1 },
	fvc: { label: FVC },
	tiffeneau: { name: TIFFENEAU },
	fev1_vc_max: { name: FEV1_VC_MAX },
	pef: { label: PEF },
	hrAvg: { name: HRAVG },
	spO2: { label: SPO2 },
	ic: { label: IC },
	vc: { label: VC }
} = CHART_PARAMETERS_NAMES;

export const DEFAULT_Y_AXIS_COLOR = theme.palette.gray.medium;

export const CHART_Y_AXIS = [
	createAxisWithGroup(L.unit, [FVC, FEV1, IC, VC]),
	createAxisWithGroup('', [TIFFENEAU, FEV1_VC_MAX]),
	createAxisWithGroup(L_S.unit, [PEF]),
	createAxisWithGroup(BPM.unit, [HRAVG]),
	createAxisWithGroup(PB.unit, [SPO2])
];

export const CHART_MEASUREMENT_PARAMETER_UNITS = {
	pef: [L_S.unit, pbLabel],
	fev: [L.unit, pbLabel]
};

export const PARAMETERS_CHART_TYPES = {
	FEV1: SPLINE,
	FVC: SPLINE,
	TIFFENEAU: SPLINE,
	PEF: SPLINE
};

export const MARKERS = {
	CORRECT: {
		fillColor: 'green',
		symbol: 'circle',
		enabled: true,
		radius: 3
	},
	IN_CORRECT: {
		fillColor: 'red',
		symbol: 'circle',
		enabled: true,
		radius: 3
	},
	BEYOND_BEST: {
		fillColor: 'orange',
		symbol: 'circle',
		enabled: true,
		radius: 3
	},
	DEFAULT: {
		fillColor: 'black',
		symbol: 'circle',
		enabled: false,
		radius: 0
	}
};
