import { Box } from '@material-ui/core';
import {
	ContainerWithBorder,
	Title
} from 'components/elements/atoms/ExaminationsV2';
import { ValueInBorder } from 'components/elements/molecules/ExaminationsV2';
import { BronchodilatorReportModel } from 'models/Report/Reports';
import React from 'react';

import { DeltaItem } from './DeltaItem';
import { DELTA_ITEMS } from './helpers';
import { useStyles } from './styles';

export type OrientationType = 'column' | 'row';

type Props = {
	examination: BronchodilatorReportModel | undefined;
	orientation?: OrientationType;
};
export const TestResult = ({ examination, orientation = 'column' }: Props) => {
	const classes = useStyles({ orientation });

	return (
		<ContainerWithBorder className={classes.testResultContainer}>
			<Title
				className={classes.title}
				title="report.bronchodilator.test_result"
			/>
			<ValueInBorder
				value={`report.bronchodilator.test_result.${examination?.testResult.result}`}
			/>
			<Box className={classes.testResultDeltaContainer}>
				{DELTA_ITEMS.map(({ deltaType }) => (
					<DeltaItem
						key={deltaType}
						deltaType={deltaType}
						examination={examination}
					/>
				))}
			</Box>
		</ContainerWithBorder>
	);
};
