import { useMediaQuery } from '@material-ui/core';
import { Container, Graph } from 'components/elements/atoms/ExaminationsV2';
import { useTranslations } from 'components/utilities';
import React from 'react';
import theme from 'theme';

import { useAttemptsContext } from '../../context';
import { prepareAllMeasurementsDataToRenderInGraph } from '../../helpers';
import { useGraphInitialState } from '../../hooks';
import { useCommonStyles, useGraphsStyles } from '../../styles';
import { useSlowVitalCapacityData } from '../useSlowVitalCapacityData';

export const Graphs = () => {
	const graphsClasses = useGraphsStyles();
	const commonClasses = useCommonStyles();
	const { t } = useTranslations();
	const examinationData = useSlowVitalCapacityData();
	const { flowVolumeGraphRef } = useAttemptsContext();

	useGraphInitialState();

	const up1400 = useMediaQuery('(min-width: 1401px)', { noSsr: true });
	const upLg = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });

	const maxWidth = () => {
		if (up1400) return 800;
		if (upLg) return 600;
		return 500;
	};

	return (
		<Container className={graphsClasses.root}>
			<Graph
				ref={flowVolumeGraphRef}
				className={graphsClasses.graphContainer}
				data={prepareAllMeasurementsDataToRenderInGraph(
					examinationData?.measurements ?? [],
					'volumeTimeChart'
				)}
				xAxisLabel={t('report.graph.time.label')}
				yAxisLabel={t('report.graph.volume.label')}
				title={{
					className: commonClasses.title,
					label: 'patients.examinations.details.spirometry.volume_time'
				}}
				axisValuesRatio="1:2"
				axisBoundaries={{
					MIN_Y: 0,
					MAX_Y: 5,
					MIN_X: -10,
					MAX_X: 10
				}}
				containerBoundaries={{
					height: 420,
					width: maxWidth()
				}}
				disableTriangle
			/>
		</Container>
	);
};
