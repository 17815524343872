import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		background: theme.palette.white,
		'& .MuiToolbar-regular': {
			minHeight: '80px'
		},
		'& .MuiToolbar-gutters': {
			padding: '0 40px'
		},
		boxShadow: '0px 3px 50px #00000029'
	},
	toolbar: {
		margin: '0 auto',
		width: '100%',
		maxWidth: '1920px'
	},
	logo: { width: 180 },
	flexGrow: {
		flexGrow: 1
	}
}));
