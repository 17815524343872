import React from 'react';

import { useReportContext } from '../../context';
import { PageSize } from '../atoms';
import { PageLayout } from '../layouts';
import { Notes } from '../organisms';
import { ReportTemplateConfig } from './config';

type Props = {
	children: CmpChildren;
	config: ReportTemplateConfig;
};
export const ReportTemplate = ({ config, children }: Props) => {
	const { isNoteInNextPage, notesRef } = useReportContext();

	const allPages = isNoteInNextPage
		? config.footer.pages + 1
		: config.footer.pages;

	return (
		<>
			<PageSize>
				<PageLayout
					refValue={config.refValue}
					title={config.title}
					footerProps={{ ...config.footer, pages: allPages }}
				>
					<>
						{children}
						{config.lastPage && !isNoteInNextPage ? (
							<Notes
								ref={notesRef}
								height={config?.note?.height}
							/>
						) : null}
					</>
				</PageLayout>
			</PageSize>
			{config.lastPage && isNoteInNextPage ? (
				<PageSize>
					<PageLayout
						refValue={config.refValue}
						title={config.title}
						footerProps={{ pageCount: allPages, pages: allPages }}
					>
						<Notes height={config.note?.height} />
					</PageLayout>
				</PageSize>
			) : null}
		</>
	);
};
