import { useMutation } from 'components/utilities';
import { TwoFactorAuthModel } from 'models/Auth/SignIn/signIn';

import { AuthRepository } from './repository';

type Options = {
	onSuccess?: (data: TwoFactorAuthModel) => void;
};
export type TVariables = {
	email: string;
	password: string;
};
const useResendTwoFactorAuthenticationToken = (options?: Options) => {
	const handleSuccess = ({ data }: AnyType) => {
		options?.onSuccess?.(data);
	};

	const mutation = useMutation<TwoFactorAuthModel, TMyError, TVariables>(
		AuthRepository.resend2FAVerificationToken,
		{ ...options, onSuccess: handleSuccess }
	);

	return mutation;
};

export default useResendTwoFactorAuthenticationToken;
