/**
 * set right labels as obturation result tale value
 * @param {Object} data - db data
 * @param {number} data.testStatusAfterPost test status to determine obturation tile message content
 * @param {number} data.reversibleObturationStatus reversibility flag
 * @param {number} data.obturationSeverity severity level
 * @returns object containing obturation result labels and information about displaying result test value
 */
export const getObstructionResultValue = ({
	testStatusAfterPost: testStatus,
	reversibleObturationStatus: reversibleStatus,
	obturationSeverity
}) => {
	let valueLabel,
		secondaryValueLabel = null;
	let disableCOPDTest = true;
	let disableObturationTooltip = false;

	switch (Number(testStatus)) {
		case 1:
			valueLabel = `patients.examinations.details.bronchodilator.obturation_reversible_status_${reversibleStatus}`;
			secondaryValueLabel = `patients.examinations.details.bronchodilator.copd_obturation_severity_${obturationSeverity}`;
			disableCOPDTest = false;
			break;
		case 2:
			valueLabel = `patients.examinations.details.bronchodilator.copd_no_obturation`;
			disableCOPDTest = false;
			break;
		case 3:
		case 4:
		case 5:
			valueLabel = `patients.examinations.details.bronchodilator.test_status_after_post_${testStatus}`;
			disableObturationTooltip = true;
			break;
		default:
			valueLabel =
				'patients.examinations.details.bronchodilator.unknown_state';
	}

	return {
		valueLabel,
		secondaryValueLabel,
		disableCOPDTest,
		disableObturationTooltip
	};
};
