import toRegexRange from 'to-regex-range';

// eslint-disable-next-line
export const phoneRegex = /^[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
export const phoneInputRegex = /^[0-9\s]+$/i; //use with disableMultipleNumberConnectors

export const passwordRegex =
	/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*(),.?/‘“:;[\]{}|<>+=\-_])(?=.{8,})/;

export const emailRegex =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

export const numberRegex = /^[0-9]+$/i;
export const numberWithDecimalRegex = /^[0-9]*(\.[0-9]{0,2})?$/;
export const anyNumberWithDecimalRegex = (decimal?) => {
	if (decimal && decimal === Infinity) {
		return new RegExp(`^[0-9]*((\\.|,)[0-9]*)?$`);
	}
	return new RegExp(
		`^[0-9]*((\\.|,)[0-9]${decimal ? `{0,${decimal}}` : ''})?$`
	);
};
export const numberInRangeRegex = (from: number, to: number, decimal = 1) => {
	if (from > to) {
		throw new RangeError(
			"'to' argument should be greater than 'from' argument"
		);
	}

	return new RegExp(
		`^(${toRegexRange(
			from.toString(),
			(to - 1).toString()
		)}((\\.|,)[0-9]{0,${decimal}})?)$|^(${toRegexRange(
			from.toString(),
			to.toString()
		)})$`
	);
};

export const numberGlobalRegex = /\D+/g;

export const lettersRegex = /^[a-zA-ZąęńóśćżźłĄĘŃÓŚĆŻŹŁ_ ]+$/i;

export const lettersAndSpecialCharacters =
	/^[a-zA-ZąęńóśćżźłĄĘŃÓŚĆŻŹŁ.-\s']+$/i;

// 11:33 AM
export const timeAmpmRegex = /(0[0-9]|1[0-2]):[0-5][0-9]\s([AP]M)/;

// 12:12
export const time24Regex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;

// date mm/dd/yyyy
export const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;

export const uuidRegex =
	/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/g;
