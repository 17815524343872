import { CHART_MEASUREMENT_PARAMETER_OPTION_NAMES } from 'helpers';
import { PulseOximetryPreviewModel } from 'models/Examinations/allExaminationsTypes';

import { PulseOximetry } from '../common/PulseOximetry';
import { IParameterValuesData } from '../Parameters/ParameterValuesData';
import { IParameterPreviewRow } from './common';

type ExtendedIParameterValuesData = IParameterValuesData & {
	isLabelDisabled: false;
};

type TPulseOximetryPreview = IParameterPreviewRow<ExtendedIParameterValuesData>;
interface IPulseOximetryPreview {
	getHrPreview: () => TPulseOximetryPreview;
	getSpO2Preview: () => TPulseOximetryPreview;
}
export class PulseOximetryPreview
	extends PulseOximetry
	implements IPulseOximetryPreview
{
	constructor(data: PulseOximetryPreviewModel) {
		super(data);
	}

	public getHrPreview = (): TPulseOximetryPreview => ({
		name: CHART_MEASUREMENT_PARAMETER_OPTION_NAMES.hrAvg.label.toUpperCase(),
		values: [{ isLabelDisabled: false, ...this.getHrValues() }]
	});

	public getSpO2Preview = (): TPulseOximetryPreview => ({
		name: CHART_MEASUREMENT_PARAMETER_OPTION_NAMES.spO2.label,
		values: [{ isLabelDisabled: false, ...this.getSpO2Values() }]
	});
}
