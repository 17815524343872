import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: 'fit-content',
		borderRadius: 100
	},
	value: {
		textTransform: 'capitalize'
	}
}));
