import { EXAMINATION_CHART_DATA_QUERY_KEY } from './useChartsDataMutation';

type ChartDataQueryKey = {
	patientId: number | string;
	chartStartDate: string;
	chartEndDate: string;
	chartName: ExaminationChartNames;
};
export const getChartDataQueryKey = ({
	patientId,
	chartStartDate,
	chartEndDate,
	chartName
}: ChartDataQueryKey) => [
	EXAMINATION_CHART_DATA_QUERY_KEY,
	patientId,
	chartStartDate,
	chartEndDate,
	chartName
];
