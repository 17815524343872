import { useLanguage, useTranslations } from 'components/utilities';
import { format, isDate } from 'date-fns';
import { stringToISO } from 'helpers';
import { EN, TIME_FORMAT } from 'helpers/variables';
import React from 'react';
import { useForm, useFormState } from 'react-final-form';

import { getErrorText, getIsError } from '../helpers';
import { convertISOtoStringTime, findTimeIndex, timeArray } from './helpers';
import { checkValidity } from './validation';

const { AMPM, DEFAULT } = TIME_FORMAT;

const useTimeFieldAdapter = ({
	inputOnChange,
	input,
	ampm,
	meta,
	minutesStep
}) => {
	const { t } = useTranslations();
	const ref = React.useRef(null);
	const { getLng } = useLanguage();
	// final form utilities
	const { change } = useForm();
	const { values } = useFormState();
	const timeFieldFormValue = values[input.name];

	// state
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [fieldWidth, setWidth] = React.useState(100);
	const [value, setValue] = React.useState(
		isDate(timeFieldFormValue)
			? convertISOtoStringTime(timeFieldFormValue, ampm)
			: timeFieldFormValue
	);
	const [error, setError] = React.useState(getIsError(meta));
	const [errorText, setErrorText] = React.useState(getErrorText(meta, t));

	const menuTimeArray = timeArray(minutesStep, ampm);
	const [menuItemSelectedIndex, setMenuItemSelectedIndex] = React.useState(
		findTimeIndex(timeFieldFormValue, menuTimeArray, ampm)
	);

	// effects
	React.useEffect(() => {
		// set menu width based on field
		setWidth(ref.current.getBoundingClientRect().width);

		// change default value from ISO format
		if (value && isDate(value)) {
			const timeFormat = ampm ? AMPM : DEFAULT;
			setValue(format(value, timeFormat));
		}
		//eslint-disable-next-line
	}, []);

	React.useEffect(() => {
		handleErrors();
		//eslint-disable-next-line
	}, [value]);

	const handleSetSelectedMenuItemIndex = (value) => {
		setMenuItemSelectedIndex(findTimeIndex(value, menuTimeArray, ampm));
	};
	const timeLength = getLng() === EN ? 8 : 5;
	React.useEffect(() => {
		if (
			isDate(timeFieldFormValue) &&
			value.length === timeLength &&
			value !== timeFieldFormValue
		) {
			const timeFormat = ampm ? AMPM : DEFAULT;
			setValue(format(timeFieldFormValue, timeFormat));
			handleSetSelectedMenuItemIndex(timeFieldFormValue);
		}
		//eslint-disable-next-line
	}, [timeFieldFormValue]);

	const handleChange = (e) => {
		input.onChange(e);
		inputOnChange && inputOnChange(e);
		setValue(e.target.value);
		change(input.name, stringToISO(e.target.value, ampm));
		return e;
	};

	const handleOpen = () => {
		setAnchorEl(ref.current);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	// menu item handler
	const handleChangeTime = (time) => {
		// create date based on time
		const ISOTime = stringToISO(time, ampm);
		handleSetSelectedMenuItemIndex(ISOTime);
		setValue(time);
		change(input.name, ISOTime);
		handleClose();
	};

	function handleErrors() {
		// check field time format validation
		const { isError, errorMessage } = checkValidity({ value, ampm });

		// get field errors
		const errorTextMeta = getErrorText(meta, t);
		const isErrorMeta = getIsError(meta);

		const errorText = errorTextMeta ? errorTextMeta : t(errorMessage);
		const error = isErrorMeta ? isErrorMeta : isError;

		setError(error);
		setErrorText(errorText);
	}

	return {
		fieldWidth,
		value,
		anchorEl,
		ref,
		error,
		errorText,
		menuTimeArray,
		menuItemSelectedIndex,
		change: handleChange,
		open: handleOpen,
		close: handleClose,
		setTime: handleChangeTime
	};
};

export default useTimeFieldAdapter;
