import { InformationSection } from 'components/elements/molecules/ExaminationsV2';
import { DeviceModel } from 'models/Device/Device';
import React from 'react';
import { DEVICE_FIELDS } from 'utilities/Examinations/ExaminationDetails/helpers';

import { ContainerWithBorder } from '../ContainerWithBorder';
import { useDeviceStyles } from './styles';
import { useStyles } from './styles';

type Props = {
	deviceData: DeviceModel | undefined;
};

export const DeviceInformation = ({ deviceData }: Props) => {
	const classes = useStyles();
	return (
		<ContainerWithBorder className={classes.root}>
			<InformationSection<DeviceModel>
				useLocalTheme={useDeviceStyles}
				orientation="row"
				title="report.device.title"
				data={deviceData}
				fields={DEVICE_FIELDS}
			/>
		</ContainerWithBorder>
	);
};
