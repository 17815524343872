import { getYear } from 'date-fns';
import { ExaminationTypeType } from 'models/Examinations/common';
import { ScatterPointModel } from 'models/Examinations/trendCharts';

import PreviewDates from '../classes/PreviewDates';
import { getChartDataQueryKey } from './helpers';

export const ChartDataServices = {
	findScatterPointIndex({
		scatterPointsData,
		id,
		type
	}: {
		scatterPointsData: ScatterPointModel[];
		id: number | string;
		type: ExaminationTypeType;
	}) {
		const pointIndex = scatterPointsData.findIndex(
			(point) =>
				point?.marker?.examinationId?.toString() === id &&
				point?.marker?.type === type
		);
		return pointIndex;
	},
	getDatesExtremes({
		firstExaminationDate,
		lastExaminationDate,
		startDate,
		endDate
	}) {
		const previewDates = new PreviewDates(
			lastExaminationDate,
			firstExaminationDate
		);

		const lastPreviewYear = previewDates.getLastPreviewYear();
		const lastChosenYear = getYear(endDate);

		if (lastChosenYear !== lastPreviewYear) {
			previewDates.setTwoYearsPeriod({
				startDate,
				endDate,
				firstExaminationDate,
				lastExaminationDate
			});
		}

		const chartStartDate = previewDates.getFirstPreviewDate();
		const chartEndDate = previewDates.getLastPreviewDate();

		return {
			chartStartDate,
			chartEndDate
		};
	},
	saveDataToQueryClientCache(cache, patientId, data) {
		function setCacheData(dataToCache) {
			const { chartStartDate, chartEndDate, chartName, data } =
				dataToCache;
			cache.setQueryData(
				getChartDataQueryKey({
					patientId,
					chartEndDate,
					chartName,
					chartStartDate
				}),
				data
			);
		}

		if (Array.isArray(data)) data.forEach((d) => setCacheData(d));
		else setCacheData(data);
	}
};
