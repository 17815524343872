export const AuthServices = {
	/**
	 * converts sign in response data and returns it
	 * @param {*} data sign in request's response data
	 * @returns redux auth data with roles, data to save to cookies, jwt token to save to secure cookie
	 */
	convertAuthData: (data) => {
		const { token, clinic_roles: roles } = data;

		const reduxData = { data: { ...data, roles } };
		const cookiesData = { isAuthenticated: true };

		return { reduxData, cookiesData, jwtToken: token };
	}
};
