import { Box, Divider, Typography } from '@material-ui/core';
import { ExpandMoreIcon } from 'assets/icons/commonMUIIcons';
import { ReactComponent as DownloadIcon } from 'assets/icons/ic_download_blue.svg';
import clsx from 'clsx';
import { ButtonProgress, InputErrorLabel } from 'components/elements';
import { useTranslations } from 'components/utilities';
import React from 'react';

import Menu from './Menu';
import { useStyles } from './styles';
import { useExportReport } from './useExportReport';

export const ExportReport = () => {
	const { t } = useTranslations();
	const classes = useStyles();
	const {
		openMenu,
		downloadReport,
		isDownloading,
		noDataMessage,
		isError,
		...rest
	} = useExportReport();

	return (
		<Box>
			<ButtonProgress
				variant="text"
				onClick={downloadReport}
				className={classes.button}
				startIcon={<DownloadIcon className={classes.modifier} />}
				endIcon={
					<Box
						component="span"
						size="small"
						className={clsx([
							classes.endIconWrapper,
							classes.modifier
						])}
						onClick={openMenu}
					>
						<ExpandMoreIcon />
					</Box>
				}
				classes={{
					endIcon: classes.endIcon
				}}
				isLoading={isDownloading}
				disabled={isDownloading}
			>
				{t('global.export_data')}
				<Divider
					orientation="vertical"
					className={clsx([classes.divider, classes.modifier])}
				/>
			</ButtonProgress>
			<Box className={classes.root}>
				{noDataMessage && (
					<Typography style={{ display: 'block' }} variant="body2">
						{t(noDataMessage)}
					</Typography>
				)}
				{isError && (
					<InputErrorLabel translatedError="error_codes.unknown_error_occurred" />
				)}
			</Box>
			<Menu {...rest} />
		</Box>
	);
};
