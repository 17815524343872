import { addDays, format, isSameDay } from 'date-fns';
import {
	concatDates,
	DateFormatConversion,
	getRoundedUpDateTime,
	QUERY_APPOINTMENTS_LIST
} from 'helpers';
import { DATE_FORMAT } from 'helpers/variables';
import { omit } from 'lodash';

import {
	CALENDAR_EXTENDED_PROPS,
	DATES_FIELDS_NAMES,
	VALUES_TO_OMIT
} from './helpersServices';

export const AppointmentsServices = {
	/**
	 * convert chosen date ranges
	 * @param {object} range date range
	 * @param {object} range.startDate instance of Date class
	 * @param {object} range.endDate instance of Date class
	 * @returns {object} converted date ranges to string
	 */
	convertRangeDates: (range) => {
		const { startDate, endDate } = range;
		const isStartEndTheSame = isSameDay(startDate, endDate);

		const endISODate = isStartEndTheSame ? addDays(endDate, 1) : endDate;
		const start = format(startDate, DATE_FORMAT.DEFAULT);
		const end = format(endISODate, DATE_FORMAT.DEFAULT);
		return { start, end };
	},
	/**
	 * convert returned from DB appointments list data to it to fullcalendar component
	 * @param {object} data appointments DB data
	 * @returns {object} converted DB data
	 */
	convertAppointmentsListData: (data) =>
		data.map((item, index) => {
			const convertedAppointment = convertAppointmentData(item, index);
			return convertedAppointment;
		}),
	/**
	 * create new appointment initial values data conversion
	 * @param {*} args
	 * @param {object} args.selectedDate instance of Date class, initial start appointment date
	 * @param {object} args.patientsList list of all patients - needed to find patient name and surname based on its ID
	 * @param {number} args.patientId patients ID
	 * @param {number} args.duration appointment duration
	 * @param {number} args.minutesPeriod determine to which minutes current hour should be rounded
	 * @param {object} args.initialValues initial values passed to form component
	 * @returns converted form initial values
	 */
	createAppointmentFormInitialValues: ({
		selectedDate,
		patientsList,
		patientId,
		duration,
		minutesPeriod,
		initialValues
	}) => {
		const values = { ...initialValues };
		const date = patientId ? new Date() : selectedDate;

		// Date
		const roundedUpDate = getRoundedUpDateTime(date, minutesPeriod);

		// Add selected calendar date as initial value for each date picker
		Object.values(DATES_FIELDS_NAMES).map(
			(name) => (values[name] = roundedUpDate)
		);

		// Patients
		const patient = patientsList.find(({ id }) => id === Number(patientId));

		const patientObj = {
			patient_id: patient?.id || null,
			patient_name: patient?.firstName + ' ' + patient?.lastName || null
		};

		return { ...values, ...patientObj, duration };
	},
	/**
	 * convert form data to object accepted by API
	 * @param {*} args
	 * @param {object} args.values form data
	 * @param {number} args.doctorID doctor ID
	 * @returns converted form data
	 */
	convertAppointmentFormPayload: ({ values, doctorId }) => {
		const { start_date, start_time, duration } = values;

		// concat dates from datePicker and timePicker
		const { start, end } = getStartEndDate({
			startDate: start_date,
			startTime: start_time,
			duration
		});

		// remove unused values
		const newValues = omit(values, VALUES_TO_OMIT);

		return {
			...newValues,
			appointment_starts: start,
			appointment_ends: end,
			doctor_id: doctorId
		};
	},
	/**
	 * update cached appointments list when edit/create appointment
	 * @param {boolean} isEdit determines if form is edit or create
	 * @param {object} cache react query cache instance
	 * @param {object} data appointment data (edited or created)
	 * @param {object} calendarDateRanges appointments dates ranges
	 * @param {string} calendarDateRanges.start appointments start date range
	 * @param {string} calendarDateRanges.end appointments end date range
	 * @param {object} getCalendarApi full calendar api getter
	 */
	updateAppointmentInList: (
		isEdit,
		cache,
		data,
		calendarDateRanges,
		getCalendarApi
	) => {
		const queryKey = [QUERY_APPOINTMENTS_LIST, calendarDateRanges];
		const appointmentsList = cache.getQueryData(queryKey);
		const convertedAppointment = convertAppointmentData(data, 1);

		/*eslint-disable*/
		const appointmentsData = isEdit
			? updateOnEdit(
					convertedAppointment,
					appointmentsList,
					getCalendarApi
			  )
			: updateOnCreate(
					convertedAppointment,
					appointmentsList,
					getCalendarApi
			  );
		/*eslint-enable*/

		cache.setQueryData(queryKey, appointmentsData);
	},
	/**
	 * update cached appointments list when delete appointment
	 * @param {object} cache react query cache instance
	 * @param {number} appointmentId appointment ID to be deleted
	 * @param {object} dateRanges appointments dates ranges
	 * @param {string} dateRanges.start appointments start date range
	 * @param {string} dateRanges.end appointments end date range
	 * @param {object} getCalendarApi full calendar api getter
	 */
	deleteAppointmentFromList: (
		cache,
		appointmentId,
		dateRanges,
		getCalendarApi
	) => {
		const queryKey = [QUERY_APPOINTMENTS_LIST, dateRanges];
		const appointmentsList = cache.getQueryData(queryKey);
		const convertedData = appointmentsList.filter(
			({ id }) => id !== appointmentId
		);
		cache.setQueryData(queryKey, convertedData);

		getCalendarApi().getEventById(appointmentId)?.remove();
	}
};

function updateOnEdit(data, appointmentsList, getCalendarApi) {
	const editedAppointmentIndex = appointmentsList.findIndex(
		({ id }) => data?.id === id
	);
	if (editedAppointmentIndex > -1) {
		appointmentsList[editedAppointmentIndex] = data;
		const calendarEvent = getCalendarApi().getEventById(data.id);
		CALENDAR_EXTENDED_PROPS.map((prop) =>
			calendarEvent.setExtendedProp(prop, data?.[prop])
		);
		calendarEvent.setDates(data.start, data.end);
	}
	return appointmentsList;
}

function updateOnCreate(data, appointmentsList, getCalendarApi) {
	getCalendarApi().addEvent(data);
	appointmentsList.push(data);
	return appointmentsList;
}

function convertAppointmentData(data, index) {
	const { appointment_starts, appointment_ends, id } = data;
	const start = DateFormatConversion.laravelToISO(appointment_starts);
	const end = DateFormatConversion.laravelToISO(appointment_ends);
	// set random color basic on index
	const colorNumber = index % 7;

	return {
		...data,
		appointment_id: id,
		appointment_starts: start,
		appointment_ends: end,
		start,
		end,
		colorNumber
	};
}

function getStartEndDate({ startDate, startTime, duration }) {
	const payload = { start: null, end: null };
	if (!startDate || !startTime || !duration) {
		return payload;
	}
	payload.start = concatDates(startDate, startTime);
	payload.end = concatDates(
		startDate,
		new Date(startTime.getTime() + duration * 60000)
	);

	return payload;
}
