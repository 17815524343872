import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslations } from 'components/utilities';
import React from 'react';

import { SnackbarProps } from '../../Snackbar';
import { useSnackBarStyles, useStyles as useCommonStyles } from '../../styles';
import { TopBar } from '../TopBar';

export interface ISnackbarMessageProps {
	onClose: SnackbarProps['onClose'];
	variant: SnackbarProps['variant'];
	titleProps: SnackbarProps['titleProps'];
	message: SnackbarProps['message'];
}

export const SnackbarMessage = ({
	onClose,
	variant,
	titleProps,
	message
}: ISnackbarMessageProps) => {
	const { t } = useTranslations();
	const commonClasses = useCommonStyles({ variant });
	const snackbarClasses = useSnackBarStyles({ variant });

	const getMessageProps = React.useCallback(
		() => ({
			className: snackbarClasses.font
		}),
		[]
	);

	return (
		<>
			<TopBar onClose={onClose} variant={variant} {...titleProps} />
			<Box paddingRight={'40px'}>
				{typeof message === 'string' ? (
					<Typography
						className={clsx([
							commonClasses.message,
							snackbarClasses.font
						])}
					>
						{t(message) ?? message}
					</Typography>
				) : (
					message?.({ getMessageProps })
				)}
			</Box>
		</>
	);
};
