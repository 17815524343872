import { Box, Typography } from '@material-ui/core';
import { AioCarePanelLogo, Alert, ButtonBack } from 'components/elements';
import { APP_PATHS } from 'components/routes/paths';
import { useTranslations } from 'components/utilities';
import { useResetPassword } from 'queries';
import React from 'react';
import { Form } from 'react-final-form';

import { ActionButtons, Fields } from './components';
import { useStyles } from './styles';
// Import validation
import validation from './validation';

const { SIGN_IN } = APP_PATHS;

const ResetUserPasswordForm = () => {
	const classes = useStyles();

	const { t } = useTranslations();

	const { isSuccess, isError, apiError, mutateAsync } = useResetPassword();

	return (
		<Form
			onSubmit={mutateAsync}
			validate={validation}
			render={({ handleSubmit, submitting }) => (
				<form
					className={classes.form}
					onSubmit={handleSubmit}
					noValidate
				>
					<Box className={classes.formTop}>
						<AioCarePanelLogo />
						<Box className={classes.titleWrapper}>
							<ButtonBack to={SIGN_IN} />
							<Typography className={classes.title} variant="h2">
								{t('reset_password.title')}
							</Typography>
						</Box>
					</Box>
					{isError && <Alert message={apiError} />}
					{isSuccess ? (
						<Alert
							type="success"
							title="reset_password.success"
							message={t(
								'reset_password.password_successfully_changed'
							)}
						/>
					) : (
						<Fields submitting={submitting} />
					)}
					<ActionButtons
						submitting={submitting}
						isSuccess={isSuccess}
					/>
				</form>
			)}
		/>
	);
};

export default ResetUserPasswordForm;
