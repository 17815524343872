export const RESULT_TYPES = {
	TEXT: 'text',
	HEART_RATE: 'heart_rate',
	EXAMINATION: 'examination',
	PEAK_FLOW: 'peak_flow'
};

export const getFirstLastMessage = (messages) => {
	const lastItem = messages.length - 1;
	const firstMessage = messages[0];
	const lastMessage = messages[lastItem];
	return { firstMessage, lastMessage };
};
