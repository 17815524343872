export const getInitialValues = ({ medication: { name } }) => ({
	drug: {
		name: name || '',
		type: null,
		add_to_action_plan: '',
		plan_type: '',
		source: null,
		id: null
	}
});
export const INITIAL_VALUES = {
	drug: {
		name: '',
		type: null,
		add_to_action_plan: '',
		plan_type: '',
		source: null,
		id: null
	}
};
