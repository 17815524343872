import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	adornment: {
		cursor: 'pointer'
	},
	box: {
		padding: 0
	},
	popover: { '& .MuiPopover-paper': { marginTop: 20 } },
	content: {
		display: 'flex',
		flexWrap: 'wrap',
		paddingLeft: 14
	},
	text: {
		position: 'relative',
		fontWeight: 400,
		fontSize: 11,
		lineHeight: '15px',
		paddingRight: 12,
		textTransform: 'lowercase',
		color: ({ isValid }) =>
			isValid ? theme.palette.green.main : theme.palette.error.dark
	},
	icon: {
		height: 8,
		width: 8,
		verticalAlign: 'middle'
	}
}));
