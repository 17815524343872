import {
	CHART_MEASUREMENT_PARAMETER_OPTION_NAMES,
	recalculateParameterValueUnit
} from 'helpers';

const PERSONAL_BEST_PERCENTAGE_REFERENCE = 80;

export class PersonalBestReference {
	constructor(chartName, personalBest, color) {
		const chartNameLowerCase = chartName?.toLowerCase();
		this.color = color;
		this.parameter =
			CHART_MEASUREMENT_PARAMETER_OPTION_NAMES[chartNameLowerCase];
		const personalBestValue = personalBest?.[chartNameLowerCase];
		this.value = personalBestValue ? Number(personalBestValue) : null;
	}

	getReferenceLine = (isVisible = true) => {
		if (this.#validateData()) return [];
		return [
			{
				value: this.getValue(),
				color: this.color,
				dashStyle: 'ShortDash',
				width: 2,
				zIndex: 1,
				label: this.#getLabel(),
				visible: isVisible
			}
		];
	};

	getValue = () =>
		this.value &&
		recalculateParameterValueUnit(
			this.parameter.name,
			(PERSONAL_BEST_PERCENTAGE_REFERENCE / 100) * this.value
		)?.toFixed(2);

	#getLabel = () => {
		const text = `80% best ${this.parameter?.label}`;

		return {
			text,
			align: 'right',
			style: {
				fontSize: '14px',
				fontWeight: 500,
				color: this.color
			},
			y: -6,
			x: -6
		};
	};

	#validateData = () => !this.value || !this.parameter;
}
