import { makeStyles } from '@material-ui/styles';

import { FONT } from '../../components/styles';

export const useStyles = makeStyles((theme) => ({
	label: {
		...FONT,
		fontSize: '12px',
		lineHeight: '12px',
		color: theme.palette.gray.medium,
		fontWeight: 400
	},
	title: {
		...FONT
	}
}));
