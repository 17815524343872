import { Obturation, TestResult } from 'components/elements';

import { RESULTS_COLORS } from '../colors/colors';
import { getColor } from './common';

/**
 * this method is called when examinationVersion = 1
 * method to create array of bronchodilator result objects (obturation & result test) inside bronchodilator tile
 * @param {array} values - array of props to render
 * @param {Object} obturation - determined obturation values based on api data and business rules
 * @param {bool} obturation.disableCOPDTest - determine if result test tile is disable or not
 * @param {Object} dbData - bronchodilator tile db data
 * @param {number} dbData.positiveResponseFlag  result test value - from API
 * @param {number} dbData.testStatusAfterPost test status to determine obturation tile message - from API
 * @returns array of objects to render
 */
export const setObturationValues = (
	values,
	{ disableCOPDTest, ...obturationResultValues },
	{ positiveResponseFlag, testStatusAfterPost }
) => {
	const mutateValues = [...values];

	const color = disableCOPDTest
		? RESULTS_COLORS[3]
		: getColor(testStatusAfterPost);
	mutateValues.push({
		color,
		isTranslationKey: true,
		component: Obturation.Result,
		...obturationResultValues
	});

	if (!disableCOPDTest) {
		mutateValues.push({
			component: TestResult,
			positiveResponseFlag,
			value: 'key'
		});
	}

	return mutateValues;
};

// UNUSED - old version (v0) of bronchodilator sidebar tale
/**
 * this method is called by default if examinationVersion = 0
 * method to create array of bronchodilator default result object (result test) inside bronchodilator tile
 * @param {array} values - array of props to render
 * @param {Object} data - data to create object inside values array
 * @param {number} data.reversibleObturationStatus - status of bronchodilator examination
 * @param {string} data.label - label rendered above result tile
 * @returns array of objects to be rendered
 */
export const setDefaultValues = (
	values,
	{ reversibleObturationStatus, label }
) => {
	const mutateValues = [...values];

	const colorIndex = reversibleObturationStatus === 1 ? 0 : 2;
	mutateValues.push({
		cellWidthMultiplier: 2,
		isTranslationKey: true,
		value: `patients.examinations.details.bronchodilator.obturation_reversible_status_${reversibleObturationStatus}`,
		color: RESULTS_COLORS[colorIndex],
		label
	});

	return mutateValues;
};
