import { Box } from '@material-ui/core';
import { Container } from 'components/elements/atoms/ExaminationsV2';
import React from 'react';

import { Graphs } from './Graphs';
import { Legend } from './Legend';
import { useStyles } from './styles';

export const PrePostGraphs = () => {
	const classes = useStyles();
	return (
		<Container className={classes.root}>
			<Box className={classes.graphsContainer}>
				<Graphs />
			</Box>
			<Legend />
		</Container>
	);
};
