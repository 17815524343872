import { ModalDialogContent } from '../components/ModalDialogContent';
import { ISuccessContentProps } from '../components/ModalDialogContent/SuccessContent';
import { IModalDialogProps, ModalDialog } from '../ModalDialog';

type ConditionalTitleProps =
	| {
			disableModalTitle: boolean extends true ? true : boolean;
			modalTitle?: never;
	  }
	| {
			disableModalTitle?: boolean extends false ? never : boolean;
			modalTitle?: TranslationKey;
	  };

/**
 * This component is designed to handle API call with additional 2 step confirmation.
 * Just inject action with IDLE states and apiError message and this modal will automagically render all states by you.
 */
export type IRequestConfirmationModalDialogProps = ConditionalTitleProps & {
	/**
	 * Determines if modal should be active (opened)
	 */
	open: boolean;
	/**
	 * API loading state - disabling all buttons and shows loader
	 */
	isLoading: boolean;
	/**
	 * Determines if success content should be rendered
	 */
	isSuccess: boolean;
	/**
	 * Method to close modal
	 */
	setClose: () => void;
	/**
	 * API call mutation
	 */
	requestConfirmation: (e?: AnyType) => void;
	/**
	 * API error message
	 */
	apiError: ApiErrorState;
	children?: CmpChildren;
	/**
	 * Text content e.g. Are you sure you want to...
	 */
	modalTextContent?: TranslationKey;
	/**
	 * Success content props
	 */
	successProps?: Partial<ISuccessContentProps>;
	/**
	 * Modal action buttons props
	 */
	actionButtonsProps?: IModalDialogProps<
		React.MouseEvent<HTMLButtonElement, MouseEvent>
	>['actionButtonsProps'];
	/**
	 * callback invokes when modal closing transition finished
	 */
	onExited?: () => void;
	/**
	 * Text content alignment
	 */
	textAlign?:
		| 'start'
		| 'end'
		| 'left'
		| 'right'
		| 'center'
		| 'justify'
		| 'initial'
		| 'match-parent';
};

export const RequestConfirmationModalDialog = ({
	setClose,
	requestConfirmation,
	open,
	isLoading,
	isSuccess,
	modalTextContent,
	apiError,
	actionButtonsProps = {},
	children,
	disableModalTitle = false,
	modalTitle,
	successProps = { textContent: '' } as ISuccessContentProps,
	onExited = () => {},
	textAlign = 'center'
}: IRequestConfirmationModalDialogProps) => {
	return (
		<ModalDialog
			disableBackdropClick
			maxWidth="sm"
			titleProps={{
				title: disableModalTitle ? '' : modalTitle,
				close: setClose,
				disableAction: isLoading
			}}
			isSuccess={isSuccess}
			successProps={{
				close: setClose,
				...successProps
			}}
			open={open}
			close={setClose}
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
			onExited={() => {
				onExited();
			}}
			actionButtonsProps={{
				disableActionButtons: false,
				confirm: {
					...actionButtonsProps.confirm,
					onClick: requestConfirmation,
					isLoading
				},
				cancel: {
					...actionButtonsProps.cancel,
					onClick: setClose,
					variant: 'outlined'
				}
			}}
		>
			{() => (
				<>
					<ModalDialogContent.RequestConfirmation
						errorMessage={apiError}
						label={modalTextContent}
						textAlign={textAlign}
					>
						{children}
					</ModalDialogContent.RequestConfirmation>
				</>
			)}
		</ModalDialog>
	);
};
