import { arrayOf, node, object, oneOfType } from 'prop-types';
import React from 'react';

import useMessengerProvider from './useMessengerProvider';

export const MessengerContext = React.createContext(null);

const MessengerProvider = ({ children, sockets }) => {
	const props = useMessengerProvider({ sockets });
	const providerValues = { ...props };

	return (
		<MessengerContext.Provider value={providerValues}>
			{children}
		</MessengerContext.Provider>
	);
};

MessengerProvider.propTypes = {
	children: oneOfType([arrayOf(node), node]).isRequired,
	sockets: object.isRequired
};

export default MessengerProvider;
