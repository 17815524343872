import { TableCellContent } from 'components/elements/molecules/Table';
import { PatientListItemModel } from 'models/Patient/PatientListItem';
import React from 'react';

import { COLUMNS_ACCESSORS } from '../../columns';
import { Ctx } from '../types';

export const LastExaminationCell = ({ ctx }: Ctx) => {
	return (
		<TableCellContent.Date
			date={ctx.getValue<
				PatientListItemModel[typeof COLUMNS_ACCESSORS.LAST_EXAMINATION]
			>()}
			withTime
		/>
	);
};
