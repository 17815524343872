import { FormControlLabel, Radio } from '@material-ui/core';
import { any, number, oneOfType, string } from 'prop-types';
import React from 'react';

import { useStyles } from './styles';

const RadioButton = ({
	value,
	label,
	size = 'small',
	minValueWidth = 100,
	...rest
}) => {
	const classes = useStyles({ minValueWidth });
	return (
		<FormControlLabel
			className={classes.formControlLabel}
			classes={{
				label: classes.label
			}}
			value={value}
			label={label}
			control={
				<Radio
					className={classes.radio}
					color="primary"
					size={size}
					{...rest}
				/>
			}
		/>
	);
};

RadioButton.propTypes = {
	value: oneOfType([string, number]).isRequired,
	label: any.isRequired,
	size: string,
	minValueWidth: number
};

export default RadioButton;
