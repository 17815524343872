import React from 'react';

import { ParametersContext } from './ParametersProvider';

const useParametersContext = () => {
	const context = React.useContext(ParametersContext);

	if (!context) {
		throw new Error(
			'useParametersContext must be used within a ParametersProvider'
		);
	}

	return context;
};

export default useParametersContext;
