import { alpha } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	eventBox: ({ colorNumber, isMirror, isPastAppointment }) => {
		const backgroundColor = theme.calendar[colorNumber];
		return {
			backgroundColor: !isMirror && alpha(backgroundColor, 0.25),
			filter: isPastAppointment && 'contrast(50%)',
			borderLeft: !isMirror && `5px solid ${backgroundColor}`,
			borderRadius: 5,
			width: '100%',
			height: '100%',
			padding: '3px 0 3px 3px',
			color: isPastAppointment
				? theme.palette.gray.disabled
				: theme.palette.text.primary,
			transition: 'background-color 0.3s ease-in-out',
			display: 'flex',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap'
		};
	},
	hours: {
		lineHeight: '18px',
		minWidth: 45,
		opacity: 0.8,
		paddingRight: 10
	},
	patient: {
		lineHeight: '18px',
		fontWeight: 500
	}
}));
