import { EventIcon } from 'assets/icons/commonMUIIcons';
import {
	useActiveExaminationActions,
	useExaminationsV2Context,
	useExaminationsV2RefsContext
} from 'components/context/ExaminationsV2';
import { HighCharts } from 'components/context/ExaminationsV2/classes';
import {
	DatePickerFieldAdapter,
	PickersUtilsProvider
} from 'components/elements';
import { PATIENTS_DETAILS_PATHS } from 'components/routes/paths';
import { useTranslations } from 'components/utilities';
import React from 'react';
import { Field } from 'react-final-form';
import { generatePath, useLocation } from 'react-router-dom';
import { useParams } from 'utilities';

import { useStyles } from './styles';

type Props = {
	label: string;
	name: string;
	disabled: boolean;
	minDate: Date;
	maxDate: Date;
	newDateRangeCreator: (date: Date) => { startDate: Date; endDate: Date };
};

export const DatePicker = ({
	disabled = false,
	label,
	name,
	minDate,
	maxDate,
	newDateRangeCreator
}: Props) => {
	const { t } = useTranslations();
	const classes = useStyles();
	const location = useLocation();
	const params = useParams();

	const { removeActiveExaminationIndexFromList } =
		useActiveExaminationActions();

	const { store: trendChartRef } = useExaminationsV2RefsContext(
		(s) => s.trendChartRef
	);
	const { resetExaminationsVirtualList } = useExaminationsV2RefsContext();

	const {
		setDateRange,
		setExaminationsListDataWithFilters,
		setExaminationsListData
	} = useExaminationsV2Context();

	const { store: activeExamination } = useExaminationsV2Context(
		(s) => s.activeExamination
	);

	const isTrendChartView = React.useMemo(
		() =>
			location.pathname ===
			generatePath(
				PATIENTS_DETAILS_PATHS.EXAMINATIONS.TREND_CHART,
				params
			),
		[location.pathname]
	);

	const handleChange = (date: Date) => {
		const dateRange = newDateRangeCreator(date);

		setDateRange(dateRange);
		if (isTrendChartView) {
			updateHighChartXAxisExtremes(dateRange);
		} else {
			const filteredData = setExaminationsListDataWithFilters({});
			const filteredDataWithoutActiveExamination =
				removeActiveExaminationIndexFromList(
					filteredData,
					activeExamination
				);
			setExaminationsListData(filteredDataWithoutActiveExamination);
			resetExaminationsVirtualList();
		}
	};

	function updateHighChartXAxisExtremes(dateExtremes: TDateRange) {
		const highChart = new HighCharts(trendChartRef);
		highChart.updateXAxisExtremes(dateExtremes);
	}

	return (
		<PickersUtilsProvider variant={'inline'}>
			{({ translationsProps }) => (
				<Field
					allowKeyboardControl={false}
					variant="inline"
					className={classes.datePickerField}
					component={DatePickerFieldAdapter}
					margin="dense"
					label={t(label)}
					name={name}
					minDate={minDate}
					maxDate={maxDate}
					onChange={handleChange}
					InputProps={{
						classes: {
							root: classes.inputBase,
							input: classes.inputBase
						},
						endAdornment: (
							<EventIcon
								fontSize="small"
								color={disabled ? 'disabled' : 'primary'}
							/>
						)
					}}
					inputVariant="outlined"
					disableFuture={true}
					autoOk
					disabled={disabled}
					{...translationsProps}
				/>
			)}
		</PickersUtilsProvider>
	);
};
