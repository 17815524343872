export const REPORT_FORMATS = [
	{
		label: 'XLS',
		isDefault: true
	},
	// {
	// 	label: 'HTML',
	// 	isDefault: false
	// },
	{
		label: 'CSV',
		isDefault: false
	},
	// {
	// 	label: 'JSON',
	// 	isDefault: false
	// },
	{
		label: 'GDT',
		isDefault: false
	}
];
