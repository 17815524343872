import { useControlledTableContextProvider } from 'components/context';
import { useQueryClient } from 'react-query';

const useTableProps = () => {
	const {
		columns,

		roles: userRolesData,
		options,
		isMutateError,
		mutateError,
		isSuccess,
		queryKey
	} = useControlledTableContextProvider();

	const queryClient = useQueryClient();

	const tableData = queryClient.getQueryData([queryKey, options]);
	const { data, pageCount: controlledPageCount } = tableData
		? tableData
		: { data: [], pageCount: 0 };

	const getEmptyDataContentProps = () => ({
		isSuccess,
		isMutateError,
		data
	});

	const getTableIdleProps = () => ({
		isMutateError,
		isSuccess,
		mutateError,
		data
	});

	return {
		getEmptyDataContentProps,
		getTableIdleProps,
		data,
		controlledPageCount,
		columns,
		userRolesData
	};
};

export default useTableProps;
