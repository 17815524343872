import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		[theme.breakpoints.down(388)]: {
			width: '100%',
			justifyContent: 'center'
		}
	},
	measurementBox: {
		display: 'flex',
		alignItems: 'center'
	},
	name: {
		color: theme.palette.gray.custom,
		marginBottom: 3,
		fontSize: 16,
		lineHeight: '16px'
	},
	value: { fontSize: 24, color: theme.palette.gray.custom },
	icon: {
		marginRight: 11,
		maxHeight: 42,
		width: 42
	},
	type: {
		fontSize: 12,
		alignSelf: 'flex-end',
		marginLeft: 5,
		textTransform: 'initial',
		color: theme.palette.black
	}
}));
