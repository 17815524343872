import { ReactComponent as LungsIcon } from 'assets/icons/ic_FEV1.svg';
import { ReactComponent as PeakflowIcon } from 'assets/icons/ic_pef.svg';
import { ReactComponent as LungsShapeIcon } from 'assets/icons/ic_pluca2.svg';
import { ReactComponent as LungsPercentageIcon } from 'assets/icons/ic_tiff.svg';
import { getUserUnit } from 'helpers';
import { recalculateParameterValueUnit } from 'helpers';

/**
 * @typedef MeasurementItem
 * @property {string} name translation key of measurement type label
 * @property {string} unit translation key of measurement type unit
 * @property {number} toFixedValue measurement type value decimal numbers amount
 * @property {object} icon measurement type icon
 * @property {string} type measurement type name
 */

/**
 * @type {MeasurementItem[]}
 */
export const MEASUREMENT_ITEMS = [
	{
		name: 'patients.topbar.fvc',
		getUserUnit: () => getUserUnit('fvc'),
		toFixedValue: 2,
		icon: LungsIcon,
		type: 'fvc'
	},
	{
		name: 'patients.topbar.fev1',
		getUserUnit: () => getUserUnit('fev1'),
		toFixedValue: 2,
		icon: LungsShapeIcon,
		type: 'fev1'
	},
	{
		name: 'patients.topbar.fev1_fvc',
		getUserUnit: () => getUserUnit('tiffeneau'),
		toFixedValue: 2,
		icon: LungsPercentageIcon,
		type: 'tiffeneau'
	},
	{
		name: 'patients.topbar.pef',
		getUserUnit: () => getUserUnit('pef'),
		recalculateUnit: (v) => recalculateParameterValueUnit('pef', v),
		toFixedValue: 2,
		icon: PeakflowIcon,
		type: 'pef'
	}
	// {
	// 	name: 'patients.topbar.correctness',
	// 	getUserUnit: () => '%',
	// 	toFixedValue: 0,
	// 	icon: SpiroIcon,
	// 	type: 'correctness'
	// }
];

/**
 * @param {{fev1: number, fvc: number, pef: number, tiffeneau: number, correctness: number}} best Patient personal best values
 * @returns {{name: string, result: string|number, unit: string, icon: object, result: number}} Personal best value component props
 */
export const getExaminationResults = ({ best }) => {
	return MEASUREMENT_ITEMS.map(({ type, toFixedValue, ...item }) => {
		const group = best[type];

		// Check if the group has value property. Type correctness do not have an object with values
		const result = group?.value ?? group;
		const resultValue = Number(result).toFixed(toFixedValue);

		return {
			...item,
			result: resultValue || ''
		};
	});
};
