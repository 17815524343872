import { useGTM } from 'components/utilities';
import { node } from 'prop-types';
import React from 'react';
import { MySentry } from 'utilities';

const AppTemplate = ({ children }) => {
	useGTM();
	React.useEffect(() => {
		MySentry.init();
	}, []);

	return children;
};

AppTemplate.propTypes = {
	children: node
};

export default AppTemplate;
