import { useTodayDate } from 'components/utilities';
import { useFormState } from 'react-final-form';

import { getCurrentMonthDays } from '../helpers';

const useDayItems = ({ monthFieldName, yearFieldName }) => {
	const { values } = useFormState();
	const today = useTodayDate();

	const monthValue = values[monthFieldName];
	const yearValue = values[yearFieldName];

	const days = monthValue
		? getCurrentMonthDays({ ...today, yearValue, monthValue })
		: [];

	const isDayDisabled = !monthValue;

	return { days, isDayDisabled };
};

export default useDayItems;
