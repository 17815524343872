import { API_URL_PREFIX } from 'helpers/api/helpers';

import {
	EXAMINATION_TYPE_QUERY_PARAM,
	ID_QUERY_PARAM,
	PATIENT_ID_QUERY_PARAM_KEY
} from '../../queryParamKeys';

export const DOWNLOAD_REPORT = `/v2/reports/export/pdf/examination`;

export const GET_CALIBRATION_CHECK_REPORT_DATA_API =
	`/v2/reports/${EXAMINATION_TYPE_QUERY_PARAM}/${ID_QUERY_PARAM}` as const;
export const GET_EXAMINATION_REPORT_DATA_API =
	`/v2/reports/${PATIENT_ID_QUERY_PARAM_KEY}/${EXAMINATION_TYPE_QUERY_PARAM}/${ID_QUERY_PARAM}` as const;
export const GET_EXAMINATION_REPORT_DATA_QUERY_KEY = (
	examinationId: IDParam,
	examinationType: ExaminationTypeParam
): [string, string] => [
	'GET_EXAMINATION_REPORT_DATA_QUERY_PARAM',
	`${examinationId} ${examinationType}`
];

export const GET_EXAMINATION_REPORT_NOTE_API =
	`${API_URL_PREFIX}/${EXAMINATION_TYPE_QUERY_PARAM}/${ID_QUERY_PARAM}/note` as const;
export const GET_EXAMINATION_REPORT_NOTE_QUERY_KEY = (
	examinationId: IDParam
): [string, string] => [
	'GET_EXAMINATION_REPORT_NOTE_QUERY_KEY',
	`${examinationId}`
];

export const DOWNLOAD_REPORT_PDF_BY_LINK =
	'/v2/reports/export/pdf/examination/url';

export const SHARE_REPORT_BY_RECIPIENT_EMAIL_API = `${API_URL_PREFIX}/patient/report/share`;
