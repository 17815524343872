import { createStyles, makeStyles } from '@material-ui/core';

import FullCalendar from '../overrides/FullCalendar';

const useStyles = makeStyles(() =>
	createStyles({
		'@global': {
			'*': {
				boxSizing: 'border-box',
				margin: 0,
				padding: 0
			},
			html: {
				'-webkit-font-smoothing': 'antialiased',
				'-moz-osx-font-smoothing': 'grayscale',
				height: '100%',
				width: '100%',
				'-webkit-scrollbar-track': {
					background: '#f1f1f1'
				},
				'-webkit-print-color-adjust': 'exact'
			},
			body: {
				backgroundColor: '#f4f6f8',
				height: '100%',
				width: '100%'
			},
			a: {
				textDecoration: 'none'
			},
			'#root': {
				height: '100%',
				width: '100%'
			},
			'& svg': {
				'& title': {
					pointerEvents: 'none !important'
				}
			},
			...FullCalendar
		}
	})
);

export const GlobalStyles = () => {
	useStyles();

	return null;
};
