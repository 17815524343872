import { MutationOptions, useMutation } from 'components/utilities';
import { ReportResponseModel } from 'models/Report/API';
import { DownloadReportRequestModel } from 'models/Report/DownloadReport';
import { FileDownloader, MyBlob } from 'utilities';

import { ExaminationReportV2Repository } from './repository';

export const useGeneratePDFReportV2 = (
	options: MutationOptions<
		ReportResponseModel,
		TMyError,
		DownloadReportRequestModel
	> = {}
) => {
	return useMutation<
		ReportResponseModel,
		TMyError,
		DownloadReportRequestModel
	>(ExaminationReportV2Repository.generatePDFReport, {
		...options,
		onSuccess: (data, variables, context) => {
			const url = window.URL.createObjectURL(
				new MyBlob(data, { type: 'application/pdf' }).blob
			);

			const fileDownloader = new FileDownloader(url, 'report.pdf');
			fileDownloader.download();
			options?.onSuccess?.(data, variables, context);
		}
	});
};
