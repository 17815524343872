import { Container } from 'components/elements/atoms/ExaminationsV2';
import React from 'react';

import { PatientCard, ZScore } from '../components/organisms';
import { ReportTemplate } from '../components/templates';
import { PageProps } from '../types';
import { BestMeasureGraphs } from './BestMeasureGraphs';
import { BestResults } from './BestResults';
import { useStyles } from './styles';
import { SVCGraph } from './SVCGraph';
import { useGetCachedForcedSpirometryReportData } from './useGetCachedForcedSpirometryReportData';

export const FirstPage = ({ title, pageCount, pages }: PageProps) => {
	const classes = useStyles();
	const { examination, patient } = useGetCachedForcedSpirometryReportData();

	return (
		<ReportTemplate config={{ title, footer: { pages, pageCount } }}>
			<PatientCard patientData={patient} />
			<BestMeasureGraphs />
			<Container className={classes.bottomSection}>
				<ZScore examination={examination} />
				<SVCGraph />
			</Container>
			<BestResults />
		</ReportTemplate>
	);
};
