import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	label: {
		fontWeight: 400,
		paddingBottom: 100,
		color: theme.palette.black
	},
	labelWrapper: {
		padding: '4px 4px 0 4px !important'
	}
}));
