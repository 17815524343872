import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		padding: '17px 15px',
		marginTop: 0
	},
	icon: {
		width: '14px',
		height: '14px',
		marginLeft: 6,
		cursor: 'pointer'
	},
	container: {
		minWidth: 226,
		position: 'absolute',
		top: 'calc(100% + 10px)',
		right: '-20px',
		boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.15)',
		padding: '18px 21px 15px 21px',
		zIndex: 9999,
		backgroundColor: theme.palette.white
	}
}));
