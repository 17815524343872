import { convertValueUnit, DEFAULT_UNITS } from 'helpers';
import { ADDITIONAL_FIELD_NAMES } from 'helpers/Forms/fieldNames/patient';

import { UserSettingName } from '../../../../models/Settings/Settings';

export const standardizeHeightWeightUnits = ({
	patientHeight,
	patientHeightUnit,
	patientWeight,
	patientWeightUnit
}) => ({
	patientHeight: convertValueUnit(
		patientHeight,
		patientHeightUnit,
		DEFAULT_UNITS.HEIGHT
	),
	patientWeight: convertValueUnit(
		patientWeight,
		patientWeightUnit,
		DEFAULT_UNITS.WEIGHT
	),
	patientHeightUnit: DEFAULT_UNITS.HEIGHT,
	patientWeightUnit: DEFAULT_UNITS.WEIGHT
});

export const SETTING_NAMES: Array<{
	field: string;
	settingName: UserSettingName;
}> = [
	{
		field: ADDITIONAL_FIELD_NAMES.WEIGHT_UNIT,
		settingName: 'weight_unit'
	},
	{
		field: ADDITIONAL_FIELD_NAMES.HEIGHT_UNIT,
		settingName: 'height_unit'
	}
];
