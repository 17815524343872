import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	repeatabilityContainer: {
		padding: '15px 0',
		borderBottom: `1px solid #E2E2E2`,
		marginBottom: 15
	},
	zScore: {
		paddingTop: 15
	}
}));
