import React from 'react';

const addPropsToReactElement = (element, props) => {
	if (React.isValidElement(element)) {
		return React.cloneElement(element, props);
	}
	return element;
};

export const addPropsToChildren = (children, props) => {
	if (!Array.isArray(children)) {
		return addPropsToReactElement(children, props);
	}
	return children.map((childElement) =>
		addPropsToReactElement(childElement, props)
	);
};
