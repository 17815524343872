import {
	CHART_EXAMINATION_PARAMETERS_COLORS as COLORS,
	DEFAULT_Y_AXIS_COLOR
} from 'helpers';

import { Point } from './Point';
import { YAxis } from './YAxis';

export const POINTS_TOWER_ROLE = 'points-tower';
export class ScatterLineChart {
	constructor(data, chartName) {
		this.type = 'scatter';
		this.role = POINTS_TOWER_ROLE;
		this.id = `${chartName}-${this.type}`;
		this.cursor = 'pointer';
		this.animation = false;
		this.zoneAxis = 'x';
		this.lineWidth = 2;
		this.dashStyle = 'Dot';
		this.showInNavigator = false;
		this.dataGrouping = {
			enabled: true
		};
		this.marker = {
			enabled: true,
			color: COLORS[chartName],
			radius: 4,
			fillColor: 'red',
			examinationId: 0,
			symbol: 'circle'
		};
		this.states = {
			inactive: {
				opacity: 1
			}
		};
		this.visible = false;
		this.linkedTo = chartName;
		this.name = chartName;
		this.color = DEFAULT_Y_AXIS_COLOR;
		this.data = this.#convertScatterLineData(data);
		this.yAxis = YAxis.getSerieCorrespondingYAxisIndex(chartName);
	}

	#convertScatterLineData = (data) => {
		return data.map((point) => {
			if (point === null) return null;
			const customPoint = new Point(point, this.marker.color);
			customPoint.convertMarker();
			return customPoint.point;
		});
	};

	static getMinMaxValueInExtremesRange = (target) => {
		const yScatterData = target.yData;
		const valuesWithoutNulls = yScatterData.filter((v) => v !== null);
		const minScatterValue = Math.min(...valuesWithoutNulls) * 0.8;
		const maxScatterValue = Math.max(...valuesWithoutNulls) * 1.1;
		return { minScatterValue, maxScatterValue };
	};
}
