import {
	Box,
	Button,
	Divider,
	Typography as MuiTypography
} from '@material-ui/core';
import { ExpandMoreIcon } from 'assets/icons/commonMUIIcons';
import { LoaderSpinnerTransitioned } from 'components/elements';
import { useProfile, useTranslations } from 'components/utilities';
import { useClinicData } from 'queries';
import React from 'react';

import { useStyles } from './styles';
import { ToggleMenu } from './ToggleMenu';
import useToggleMenu from './ToggleMenu/useToggleMenu';

const Profile = () => {
	const classes = useStyles();
	const { t } = useTranslations();

	const { firstName, lastName, gender, roleLabel } = useProfile();
	const { anchorEl, open, close, signOut } = useToggleMenu();

	const {
		data: clinicData,
		isSuccess: isClinicDataSuccess,
		isLoading: isClinicDataLoading
	} = useClinicData();

	return (
		<Box className={classes.container}>
			<Divider className={classes.divider} orientation="vertical" />
			<Button className={classes.root} onClick={open}>
				<Box className={classes.content}>
					<Box className={classes.nameWrapper}>
						<MuiTypography
							variant="h6"
							component="p"
							className={classes.name}
						>
							{firstName} {lastName}
						</MuiTypography>
						<MuiTypography
							className={classes.role}
							variant="body2"
							component="p"
						>
							{t(roleLabel)}
						</MuiTypography>
						<Box style={{ position: 'relative' }}>
							{isClinicDataSuccess && clinicData?.name ? (
								<MuiTypography
									className={classes.clinicName}
									variant="body1"
									component="p"
								>
									{clinicData?.name}
								</MuiTypography>
							) : null}
							<LoaderSpinnerTransitioned
								className={classes.clinicLoader}
								isLoading={isClinicDataLoading}
								size={15}
								rootSize="full"
							/>
						</Box>
					</Box>
				</Box>
				<ExpandMoreIcon className={classes.icon} />
			</Button>
			<ToggleMenu
				gender={gender}
				anchorEl={anchorEl}
				close={close}
				signOut={signOut}
			/>
		</Box>
	);
};

export default Profile;
