import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		paddingLeft: 16
	},
	cardContent: {
		height: '100%'
	}
}));
