import { Graph } from 'components/elements/atoms/ExaminationsV2';
import { useTranslations } from 'components/utilities';
import React from 'react';

import { useGetCachedSlowVitalCapacitySVCReportData } from '../../useGetCachedSlowVitalCapacitySVCReportData';
import { useStyles } from './styles';

export const BestVolumeTimeGraph = () => {
	const { t } = useTranslations();
	const classes = useStyles();
	const { examination } = useGetCachedSlowVitalCapacitySVCReportData();
	return (
		<Graph
			data={[
				{
					isBest: true,
					data:
						examination?.bestMeasurementChart?.volumeTimeChart || []
				}
			]}
			xAxisLabel={t('report.graph.time.label')}
			yAxisLabel={t('report.graph.volume.label')}
			title={{
				label: 'report.graph.best_svc_volume.title',
				className: classes.title
			}}
			axisValuesRatio="1:2"
			axisBoundaries={{
				MIN_Y: 0,
				MAX_Y: 5,
				MIN_X: -10,
				MAX_X: 10
			}}
			containerBoundaries={{ height: 220, width: 400 }}
		/>
	);
};
