import 'react-virtualized/styles.css';
import 'react-perfect-scrollbar/dist/css/styles.css';

import { ThemeProvider } from '@material-ui/styles';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { GlobalStyles } from 'theme/styles';
import { queryClient } from 'utilities';

import { Routes } from './components/routes';
import store from './store/store';
import theme from './theme';

const App = () => {
	return (
		<Provider store={store}>
			<QueryClientProvider client={queryClient}>
				<ThemeProvider theme={theme}>
					<GlobalStyles />
					<ReactQueryDevtools />
					<BrowserRouter>
						<Routes />
					</BrowserRouter>
				</ThemeProvider>
			</QueryClientProvider>
		</Provider>
	);
};

export default App;
