import { EN, LANGUAGES } from 'helpers/variables';
import { produce } from 'immer';
import * as types from 'store/types';

const INITIAL_STATE = {
	lng: EN,
	available: { data: LANGUAGES, isLoaded: false, isError: false },
	authorization: { isLoaded: false, isError: false },
	isLoaded: false,
	isError: false,
	translations: {}
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ------------ Translations ------------
			case types.FETCH_LANGUAGES_LOADING:
				draft.isLoaded = false;
				draft.isError = false;
				return;

			case types.FETCH_LANGUAGES_SUCCESS:
				draft.isLoaded = true;
				draft.translations = action.payload.translations;
				draft.lng = action.payload.lng;
				return;

			case types.FETCH_LANGUAGES_ERROR:
				draft.isLoaded = true;
				draft.isError = true;
				return;

			// ------------  Auth Translations ------------
			case types.FETCH_AUTH_LANGUAGES_LOADING:
				draft.authorization.isLoaded = false;
				draft.authorization.isError = false;
				return;

			case types.FETCH_AUTH_LANGUAGES_SUCCESS:
				draft.authorization.isLoaded = true;
				draft.translations = action.payload.translations;
				draft.lng = action.payload.lng;
				return;

			case types.FETCH_AUTH_LANGUAGES_ERROR:
				draft.authorization.isLoaded = true;
				draft.authorization.isError = true;
				return;

			// ------------ AVAILABLE LOCALES ------------
			case types.FETCH_AVAILABLE_LOCALES_LOADING:
				draft.available.isLoaded = false;
				draft.available.isError = false;
				return;

			case types.FETCH_AVAILABLE_LOCALES_SUCCESS:
				draft.available.data = action.payload;
				return;

			case types.FETCH_AVAILABLE_LOCALES_ERROR:
				draft.available.isLoaded = true;
				draft.available.isError = true;
				return;

			// ------------ Language ------------
			case types.SET_LANGUAGE:
				draft.lng = action.payload;
				return;

			default:
				return state;
		}
	});
