import { ActiveExaminationBase } from 'models/Examinations/common';

import { useSearchParams } from '../../useSearchParams';
import { ACTIVE_EXAMINATIONS_SEARCH_PARAMS } from './helpers';

export const useActiveExaminationSearchParams = () => {
	const { setSearchParam, clearSearchParams, getSearchParam } =
		useSearchParams();

	const setActiveExaminationSearchParam = (data: ActiveExaminationBase) => {
		setSearchParam(Object.values(ACTIVE_EXAMINATIONS_SEARCH_PARAMS), [
			data.id?.toString() || '',
			data.type?.toString() || ''
		]);
	};

	const deleteActiveExaminationSearchParam = () => {
		clearSearchParams(Object.values(ACTIVE_EXAMINATIONS_SEARCH_PARAMS));
	};

	const getIdSearchParam = () =>
		getSearchParam(
			ACTIVE_EXAMINATIONS_SEARCH_PARAMS.ID
		) as ActiveExaminationBase['id'];

	const getTypeSearchParam = () =>
		getSearchParam(
			ACTIVE_EXAMINATIONS_SEARCH_PARAMS.TYPE
		) as ActiveExaminationBase['type'];

	return {
		setActiveExaminationSearchParam,
		deleteActiveExaminationSearchParam,
		getIdSearchParam,
		getTypeSearchParam
	};
};
