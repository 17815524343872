import { EN } from 'helpers';
import { func } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import MaskedInput from 'react-text-mask';

const TimeInputMask = ({ inputRef, ...other }) => {
	const checkHours = (value) => {
		if (value[0] === '2') {
			return /[0-3]/;
		}
		return /[0-9]/;
	};
	const { lng } = useSelector((store) => store.language);
	const isAmPmFormat = lng === EN;
	const checkAMPM = () => (isAmPmFormat ? [' ', /[AP]/, 'M'] : []);

	return (
		<MaskedInput
			{...other}
			ref={(ref) => {
				inputRef(ref ? ref.inputElement : null);
			}}
			mask={[
				/[0-2]/,
				checkHours(other.value),
				':',
				/[0-5]/,
				/\d/,
				...checkAMPM()
			]}
			placeholder="HH:MM"
		/>
	);
};

TimeInputMask.propTypes = {
	inputRef: func.isRequired
};

export default TimeInputMask;
