import { CancelToken } from 'axios';
import {
	CREATE_PATIENT_API,
	GET_BASIC_DATA_API,
	GET_PATIENT_BEST_API,
	GET_PATIENT_BEST_API_PARAM_KEY
} from 'helpers';
import { PatientSchema } from 'models/Patient/PatientModel';
import services from 'services/services';
import { servicesV2 } from 'services/servicesV2';
import { z } from 'zod';

type GetData = {
	cancelToken: CancelToken;
	patientId: PatientIdParam;
};
export const PatientRepository = {
	/**
	 * create patient account
	 * @param {object} payload request payload
	 * @returns Promise
	 */
	create: (payload) => services.post(CREATE_PATIENT_API, payload),
	getData: async ({ cancelToken, patientId }: GetData) =>
		servicesV2.get(`${GET_BASIC_DATA_API}${patientId}`, {
			cancelToken,
			responseSchema: PatientSchema,
			requestSchema: z.undefined()
		}),
	/**
	 * get patient's personal bests
	 * @param {object} args request data
	 * @param {object.object} args.cancelToken request cancelToken
	 * @param {object.string} args.patientId patient ID to be fetched
	 * @returns Promise
	 */
	getPersonalBests: ({ cancelToken, patientId }) =>
		services.get(getApiUrl(GET_PATIENT_BEST_API, patientId), {
			cancelToken
		}),
	/**
	 * edit patient's data
	 * @param {object} payload request payload - patient new data
	 * @param {number} patientId patient Id to be edited
	 * @returns Promise
	 */
	edit: (payload, patientId) =>
		services.put(`${GET_BASIC_DATA_API}${patientId}`, payload)
};

function getApiUrl(API_ROUTE, patientId) {
	return API_ROUTE.replace(GET_PATIENT_BEST_API_PARAM_KEY, patientId);
}
