import { AutocompleteControlledField } from 'components/elements';
import { useTranslations } from 'components/utilities';
import { string } from 'prop-types';
import React from 'react';
import { useFormState } from 'react-final-form';

const PhonePrefix = ({ label, ...fieldProps }) => {
	const { t } = useTranslations();
	const {
		submitting,
		values: { phone_prefix }
	} = useFormState();

	const renderOptionSelected = React.useCallback(
		(option, value) => option.phonePrefix === (value?.phonePrefix || value),
		[]
	);

	const initialValue = React.useMemo(
		() => (phone_prefix ? { phonePrefix: phone_prefix } : null),
		//eslint-disable-next-line
		[]
	);

	return (
		<AutocompleteControlledField
			disabled={submitting}
			initialValue={initialValue}
			getOptionSelected={renderOptionSelected}
			getOptionLabel={(option) => option.phonePrefix}
			label={t(label)}
			fullWidth
			required
			{...fieldProps}
		/>
	);
};

PhonePrefix.propTypes = {
	label: string.isRequired
};

export default PhonePrefix;
