import { PATIENTS_DETAILS_PATHS } from 'components/routes/paths';

export const getFormRoutes = () => {
	const {
		CREATE_CONDITION,
		EDIT_CONDITION,
		CREATE_SURGERY,
		EDIT_SURGERY,
		CREATE_ALLERGY,
		EDIT_ALLERGY
	} = PATIENTS_DETAILS_PATHS.CONDITIONS;

	const ROUTES_CONDITIONS_FORM = [CREATE_CONDITION, EDIT_CONDITION];
	const ROUTES_SURGERIES_FORM = [CREATE_SURGERY, EDIT_SURGERY];
	const ROUTES_ALLERGIES_FORM = [CREATE_ALLERGY, EDIT_ALLERGY];

	return {
		ROUTES_CONDITIONS_FORM,
		ROUTES_SURGERIES_FORM,
		ROUTES_ALLERGIES_FORM
	};
};
