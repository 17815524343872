import { Box, Button } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslations } from 'components/utilities';
import { array, bool, func, object, oneOfType, string } from 'prop-types';
import React from 'react';

import { useStyles } from './styles';

const OptionButton = ({
	text,
	icon: Icon,
	fillIcon = true,
	onClick,
	variant,
	value,
	state,
	isButton = true,
	className,
	classNameIcon,
	children
}) => {
	const isChecked = !!state?.includes(value);
	const classes = useStyles({ isChecked, fillIcon });
	const { t } = useTranslations();
	return isButton ? (
		<Button
			className={clsx([classes.root, className])}
			variant={variant}
			onClick={onClick}
			color="primary"
			classes={{ outlinedPrimary: classes.outlined }}
		>
			{text && t(text)}
			{Icon && <Icon className={clsx([classes.icon, classNameIcon])} />}
		</Button>
	) : (
		<Box className={clsx([classes.wrapper, className])} onClick={onClick}>
			{children({ isChecked })}
		</Box>
	);
};

OptionButton.propTypes = {
	text: string,
	icon: object,
	onClick: func.isRequired,
	variant: string,
	value: string.isRequired,
	className: string,
	classNameIcon: string,
	isButton: bool,
	fillIcon: bool,
	state: oneOfType([string, array]),
	children: func
};

export default OptionButton;
