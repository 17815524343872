import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { Popover } from 'components/elements/molecules';
import { func, string } from 'prop-types';
import React from 'react';

export const useStyles = makeStyles(() => ({
	cursor: { cursor: 'pointer' },
	popover: { marginTop: 8 }
}));

const PopoverRPC = ({ children }) => {
	const classes = useStyles();

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const handlePopoverOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const renderPopoverComponent = ({
		// eslint-disable-next-line
		children,
		// eslint-disable-next-line
		classNamePopover,
		...rest
	}) => (
		<Popover
			className={clsx([classNamePopover, classes.popover])}
			open={open}
			anchorEl={anchorEl}
			onClose={handlePopoverClose}
			{...rest}
		>
			{children}
		</Popover>
	);

	const getAnchorProps = () => ({
		onMouseEnter: handlePopoverOpen,
		onMouseLeave: handlePopoverClose
	});

	return children({
		isPopoverOpen: open,
		getAnchorProps,
		onMouseEnter: handlePopoverOpen,
		onMouseLeave: handlePopoverClose,
		popoverWrapper: renderPopoverComponent,
		cursorClassName: classes.cursor
	});
};

PopoverRPC.propTypes = {
	children: func,
	classNamePopover: string
};

export default PopoverRPC;
