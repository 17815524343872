import { deleteApp, initializeApp } from 'firebase/app';
import {
	getMessaging as _getMessaging,
	getToken,
	isSupported,
	onMessage
} from 'firebase/messaging';
import PubSub from 'pubsub-js';

import { FirebaseMessageModel } from './types';

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

export const isMessagingSupported = async () => {
	return await isSupported();
};

const getMessaging = async () => {
	if (!(await isMessagingSupported())) {
		return null;
	}
	try {
		return _getMessaging(firebaseApp);
	} catch (e) {
		return null;
	}
};

export const getMessagingToken = async () => {
	const messaging = await getMessaging();
	if (!messaging) return null;
	const token = await getToken(messaging, {
		vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY
	});
	return token;
};

export const onFirebaseMessageListener = async (
	callback: (message: FirebaseMessageModel) => void
) => {
	const messaging = await getMessaging();

	if (!messaging) return () => {};
	return onMessage(messaging, (message) => {
		callback(message as unknown as FirebaseMessageModel);
	});
};

export const publishFirebaseMessage = (message: FirebaseMessageModel) => {
	PubSub.publish('FIREBASE_MESSAGING', message);
};

export const subscribeToFirebaseMessaging = (
	callback: (message: FirebaseMessageModel) => void
) => {
	PubSub.subscribe('FIREBASE_MESSAGING', (message, data) => {
		callback(data as unknown as FirebaseMessageModel);
	});
};

export const disconnectWithFirebase = async () => {
	await deleteApp(firebaseApp);
};

export const unregisterMessagingServiceWorker = () => {
	navigator.serviceWorker
		.getRegistration(`${process.env.PUBLIC_URL}/firebase-messaging-sw.js`)
		.then((registration) => {
			if (registration && registration.unregister) {
				registration.unregister();
			}
		});
};
export const registerMessagingServiceWorker = async () => {
	navigator.serviceWorker.register(
		`${process.env.PUBLIC_URL}/firebase-messaging-sw.js`
	);
};

export default firebaseApp;
