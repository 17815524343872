import {
	ContainerWithBorder,
	StatusCell,
	Title
} from 'components/elements/atoms/ExaminationsV2';
import React from 'react';

import { useGetCachedCalibrationCheckReportData } from '../useGetCachedCalibrationCheckReportData';
import { useStyles } from './styles';

export const VerificationResult = () => {
	const { examination } = useGetCachedCalibrationCheckReportData();
	const classes = useStyles({ value: examination?.verification.result });

	return (
		<ContainerWithBorder className={classes.verificationResultContainer}>
			<Title title="report.calibration_check.verification_result" />
			<ContainerWithBorder className={classes.resultValue}>
				<StatusCell value={examination?.verification.result} />
			</ContainerWithBorder>
		</ContainerWithBorder>
	);
};
