import { numberGlobalRegex, numberRegex } from './validation';

export const convertDecimalNumber = (num: number, decimalNum = 2): number =>
	num && Number(Number(num).toFixed(decimalNum));

export const checkIfIsDecimal = (number) => number - Math.floor(number) === 0;

// ------ CONVERT INPUT FORM VALUES ------
export const convertFormInputValueToNumbers = ({ target: { value } }) =>
	value.replace(numberGlobalRegex, '');

/**
 * Disable multiplied number connectors: 600---123..23
 *
 * @param {object} e input event
 * @returns {object} event with changed value: 600-123.23
 */
export const disableMultipleNumberConnectors = (e) =>
	e.target.value
		.split('')
		.filter(
			(currentValue, index, filteredArray) =>
				currentValue !== filteredArray[index + 1] ||
				numberRegex.test(currentValue)
		)
		.join('');
