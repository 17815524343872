import { TableCell, TableRow, TableSortLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslations } from 'components/utilities';
import { bool, object } from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
	cell: {
		color: theme.palette.customBlack.main,
		fontWeight: 500,
		border: 'none',
		height: 56
	},
	tableRow: {
		'& > :first-child': {
			borderTopLeftRadius: '5px'
		},
		'& > :last-child': {
			borderTopRightRadius: '5px',
			position: 'sticky',
			right: 0
		},
		border: 'none',
		width: 'calc(100% + 1px)'
	}
}));

const TableHeadRow = ({ headerGroup }) => {
	const { t } = useTranslations();
	const classes = useStyles();

	return (
		<TableRow
			{...headerGroup.getHeaderGroupProps()}
			className={classes.tableRow}
		>
			{headerGroup.headers.map(
				(column) =>
					!column.isHidden && (
						<TableCell
							className={classes.cell}
							key={column.id}
							{...column.getHeaderProps(
								column.getSortByToggleProps()
							)}
						>
							{t(column.render('Header'))}
							<>
								{!column.disableSortBy &&
								column.id !== 'selection' ? (
									<TableSortLabel
										active={column.isSorted}
										// react-table has a unsorted state which is not treated here
										direction={
											column.isSortedDesc ? 'desc' : 'asc'
										}
									/>
								) : null}
							</>
						</TableCell>
					)
			)}
		</TableRow>
	);
};

TableHeadRow.propTypes = {
	headerGroup: object.isRequired,
	isSortable: bool
};

export default TableHeadRow;
