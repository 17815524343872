import { AccessTimeIcon } from 'assets/icons/commonMUIIcons';
import { useScheduleCalendarContext } from 'components/context';
import { TimeFieldAdapter } from 'components/elements';
import { useTranslations } from 'components/utilities';
import { EN } from 'helpers';
import React from 'react';
import { Field, useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';

import { useStyles } from './styles';

const TimeField = () => {
	const { t } = useTranslations();
	const classes = useStyles();
	const { submitting } = useFormState();
	const { isAppointmentReview } = useScheduleCalendarContext();
	const { lng } = useSelector((store) => store.language);
	const isAmPmFormat = lng === EN;

	return (
		<Field
			name="start_time"
			classNameTextFieldInput={classes.buttonAdornment}
			component={TimeFieldAdapter}
			disabled={submitting || isAppointmentReview}
			margin="dense"
			variant="outlined"
			label={t('schedule.form.start_time')}
			required={!isAppointmentReview}
			ampm={isAmPmFormat}
			minutesStep={15}
			icon={<AccessTimeIcon fontSize="small" color="primary" />}
		/>
	);
};

export default TimeField;
