import { TableCell, TableRow } from '@material-ui/core';
import { SettingsTableCell } from 'components/elements';
import { func, object, string } from 'prop-types';
import { ConditionsRepository } from 'queries';
import React from 'react';
import { useParams } from 'react-router-dom';

import { COLUMNS_ACCESSORS } from '../../helpers/tables_data';
import { NameTableCell } from './TableCell';
import { DescriptionTableCell } from './TableCell';
import { DateTableCell } from './TableCell';
import { useStyles } from './TableCell/styles';

const TableBodyRow = ({
	row,
	editPathname,
	removeApiUrl,
	deleteModalDisableTitle,
	deleteModalTextContent
}) => {
	const classes = useStyles({ minWidth: 200 });
	const { patientId } = useParams();
	const editPath = editPathname.replace(':patientId', patientId);

	const renderCell = (cell) => {
		switch (cell.column.id) {
			case COLUMNS_ACCESSORS.NAME:
				return <NameTableCell key={cell.column.id} cell={cell} />;

			case COLUMNS_ACCESSORS.DESCRIPTION:
				return (
					<DescriptionTableCell key={cell.column.id} cell={cell} />
				);

			case COLUMNS_ACCESSORS.DATE:
				return <DateTableCell key={cell.column.id} cell={cell} />;

			case COLUMNS_ACCESSORS.CREATED_AT:
				return <DateTableCell key={cell.column.id} cell={cell} />;

			case COLUMNS_ACCESSORS.SETTINGS:
				return (
					<SettingsTableCell
						key={cell.column.id}
						cell={cell}
						deletePromise={(id) =>
							ConditionsRepository.delete(id, removeApiUrl)
						}
						deleteModalDisableTitle={deleteModalDisableTitle}
						deleteModalTextContent={
							deleteModalTextContent
								? deleteModalTextContent(cell)
								: ''
						}
						editRoutePath={editPath}
					/>
				);

			default:
				return (
					<TableCell
						className={classes.tableCell}
						key={cell.column.id}
						{...cell.getCellProps()}
					>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow {...row.getRowProps()}>{row.cells.map(renderCell)}</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
	editPathname: string.isRequired,
	removeApiUrl: string.isRequired,
	deleteModalTextContent: func,
	deleteModalDisableTitle: string
};

export default TableBodyRow;
