const useFullScreenButton = ({
	isFullscreenActive,
	enterFullscreen,
	exitFullscreen
}) => {
	const handleFullScreenAction = () =>
		isFullscreenActive ? exitFullscreen() : enterFullscreen();

	return {
		onFullScreen: handleFullScreenAction,
		isFullscreenActive
	};
};

export default useFullScreenButton;
