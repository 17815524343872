import {
	CREATE_INVITED_PATIENT_ACCOUNT_API,
	INVITED_PATIENT_DATA_API,
	PATIENT_LINK_INVITATION_API
} from 'helpers';
import {
	PatientLinkInvitationAcceptanceRequestSchema,
	PatientLinkInvitationAcceptanceResponseSchema
} from 'models/Patient/PatientLink';
import { replaceTokenQueryParam } from 'queries/common';
import services from 'services/services';
import { servicesV2 } from 'services/servicesV2';

export const PatientRepository = {
	getResources: ({ cancelToken, token }) =>
		services.get(`${INVITED_PATIENT_DATA_API}/${token}`, { cancelToken }),
	createAccount: (payload) =>
		services.post(CREATE_INVITED_PATIENT_ACCOUNT_API, payload),
	acceptLinkInvitation: ({ token }: { token: string }) =>
		servicesV2.get(
			replaceTokenQueryParam(PATIENT_LINK_INVITATION_API, token),
			{
				requestSchema: PatientLinkInvitationAcceptanceRequestSchema,
				responseSchema: PatientLinkInvitationAcceptanceResponseSchema
			}
		)
};
