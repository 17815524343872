import { string } from 'prop-types';
import React from 'react';

import { SelectField } from '../SelectField';
import useDayItems from './useDayItems';

const DaySelectField = ({ monthFieldName, ...rest }) => {
	const { days, isDayDisabled } = useDayItems({ monthFieldName, ...rest });

	return (
		<SelectField
			label="global.day_short"
			options={days}
			required={false}
			disabled={isDayDisabled}
			{...rest}
		/>
	);
};

DaySelectField.propTypes = {
	monthFieldName: string.isRequired
};

export default DaySelectField;
