import {
	Container,
	ContainerWithBorder,
	Title
} from 'components/elements/atoms/ExaminationsV2';
import { ParametersTable } from 'components/elements/organisms/ExaminationsV2';
import React from 'react';
import {
	columns,
	convertBestResultsToColumnsData,
	convertSVCBestResultToColumnData
} from 'utilities/Examinations/ExaminationDetails/ForcedSpirometryFVC';

import { useGetCachedForcedSpirometryReportData } from '../useGetCachedForcedSpirometryReportData';
import { useStyles } from './styles';

export const BestResults = () => {
	const { examination } = useGetCachedForcedSpirometryReportData();
	const classes = useStyles({ isSvc: !!examination?.svc });

	return (
		<Container className={classes.bestResults}>
			<Title
				className={classes.title}
				title="report.best_results.title"
			/>

			<ContainerWithBorder>
				<Title title="report.best_results.fvc.title" />
				<ParametersTable
					columns={columns}
					data={convertBestResultsToColumnsData(
						examination?.bestParameters
					)}
				/>
			</ContainerWithBorder>
			{examination?.svc ? (
				<ContainerWithBorder>
					<Title title="report.best_results.svc.title" />
					<ParametersTable
						columns={columns.slice(0, columns.length - 1)}
						data={convertSVCBestResultToColumnData(
							examination?.svc?.bestParameters
						)}
					/>
				</ContainerWithBorder>
			) : (
				<></>
			)}
		</Container>
	);
};
