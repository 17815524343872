import { ReactHMLParserType } from 'components/utilities/hooks/translations/types';
import { SeriesOptionsType } from 'highcharts';
import palette from 'theme/palette';

import { IGraphData, IGraphProps } from './Graph';

interface Args extends Omit<IGraphProps, 'title' | 'axisBoundaries'> {
	yAxis: {
		min: number;
		max: number;
		label: ReactHMLParserType | string;
		tickInterval: number;
	};
	xAxis: {
		min: number;
		max: number;
		label: ReactHMLParserType | string;
		tickInterval: number;
	};
}

export const getOptions = ({
	data,
	yAxis,
	xAxis,
	triangleData
}: Args): Highcharts.Options => ({
	accessibility: {
		enabled: false
	},
	scrollbar: {
		enabled: false
	},
	exporting: {
		enabled: false
	},
	rangeSelector: {
		enabled: false
	},
	legend: {
		enabled: false
	},
	navigator: {
		enabled: false
	},
	credits: {
		enabled: false
	},
	title: {
		text: '',
		style: {
			display: 'none'
		}
	},
	chart: {
		backgroundColor: '#FFFFFF',
		spacing: [3, 3, 0, 0],
		marginLeft: 30,
		marginRight: 4,
		animation: false,
		style: {
			fontFamily: 'Regola'
		}
	},
	plotOptions: {
		series: {
			turboThreshold: 4065000,
			states: {
				hover: {
					enabled: false
				}
			},
			animation: false,
			pointStart: 0,
			marker: {
				enabled: false,
				radius: 0
			}
		}
	},
	xAxis: {
		allowDecimals: false,
		tickPixelInterval: 22,
		startOnTick: true,
		endOnTick: true,
		min: xAxis.min,
		max: xAxis.max,
		tickInterval: xAxis.tickInterval,
		tickLength: 0,
		gridLineWidth: 0.5,
		gridLineColor: '#DDE4EA',

		labels: {
			autoRotationLimit: 10,
			y: 10,
			align: 'center',
			style: {
				color: '#707070',
				fontSize: '8px',
				top: '10px'
			}
		},
		title: {
			margin: 0,
			y: 0,
			text: xAxis.label as unknown as string,
			style: {
				color: '#707070',
				fontSize: '10px',
				lineHeight: '10px'
			}
		},
		plotLines: [
			{
				color: '#707070',
				value: 0,
				width: 1
			}
		]
	},
	// showLastLabel accepts only strings - should be boolean
	// highcharts issue: https://github.com/highcharts/highcharts/issues/17911
	//eslint-disable-next-line
	//@ts-ignore
	yAxis: {
		tickPixelInterval: 1,
		gridLineWidth: 0.5,
		gridLineColor: '#DDE4EA',
		startOnTick: true,
		endOnTick: true,
		tickLength: 0,
		min: yAxis.min,
		max: yAxis.max,
		showLastLabel: true,
		tickInterval: yAxis.tickInterval,
		allowDecimals: false,
		labels: {
			distance: 0,
			x: -5,
			align: 'center',
			style: {
				color: '#707070',
				fontSize: '8px'
			}
		},
		title: {
			margin: 5,
			text: yAxis.label as unknown as string,
			style: {
				color: '#707070',
				fontSize: '10px'
			}
		},
		plotLines: [
			{
				color: '#707070',
				value: 0,
				width: 1
			}
		]
	},
	tooltip: {
		enabled: false
	},
	series: [
		...constructData(data),
		{
			enableMouseTracking: false,
			type: 'scatter',
			data: triangleData,
			lineWidth: 1,
			dashStyle: 'LongDash',
			color: '#707070',
			marker: {
				enabled: true,
				radius: 2,
				symbol: 'circle',
				lineWidth: 1,
				fillColor: '#FFFFFF',
				lineColor: '#707070'
			}
		}
	]
});

function constructData(data: IGraphData[]): SeriesOptionsType[] {
	return data.map(({ data, isBest, color, lineWidth, name = '' }) => {
		return {
			crisp: false,
			marker: {
				enabled: false
			},
			enableMouseTracking: false,
			data,
			zIndex: isBest ? 10 : 5,
			name: name,
			type: 'line' as const,
			color:
				color ||
				(isBest ? palette.report.green.main : palette.report.blue.dark),
			lineWidth: lineWidth || (isBest ? 2 : 1)
		};
	});
}
