import { endOfDay, startOfDay } from 'date-fns';
import { ActiveExamination } from 'models/Examinations/common';
import { ExaminationsListConvertedItemModel } from 'models/Examinations/ExaminationsList';
import {
	ExaminationsListServicesV2,
	useExaminationsListQueryKey
} from 'queries';
import React from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'utilities';
import { ReactContext } from 'utilities/ReactContext';
import { useActiveExaminationSearchParams } from 'utilities/ReactRouterDomHooks/implementations/examinations/useActiveExaminationSearchParams';

import { useTooltipContext } from '../ExaminationsTrendChart';
import { DEFAULT_ACTIVE_EXAMINATION_STATE } from './helpers';
import useInitialExaminationsDateRange from './hooks/useInitialExaminationsDateRange';
export interface IExaminationsV2Context {
	examinationsDateRange: Nullable<TDateRange>;
	examinationDateExtremes: Nullable<TDateRange>;
	activeExamination: ActiveExamination;
	examinationsListData: ExaminationsListConvertedItemModel[];
	listDataWithoutActiveExamination: ExaminationsListConvertedItemModel[];
	isExaminationsListDataSuccess: boolean;
	selectedExaminationTypes: ExaminationTypeParam[];
	availableExaminationTypesInList: ExaminationTypeParam[];
	initialActiveExaminationIndex: number | null;
}

type Props = {
	children: CmpChildren;
};

const { Provider, useStore } =
	ReactContext.createPubSubContext<IExaminationsV2Context>();

export const ExaminationsV2Provider = ({ children }: Props) => {
	const examinationsDateRange = useInitialExaminationsDateRange();

	return (
		<Provider
			value={{
				examinationDateExtremes: { startDate: null, endDate: null },
				examinationsDateRange,
				activeExamination: DEFAULT_ACTIVE_EXAMINATION_STATE,
				examinationsListData: [],
				listDataWithoutActiveExamination: [],
				isExaminationsListDataSuccess: false,
				selectedExaminationTypes: [],
				availableExaminationTypesInList: [],
				initialActiveExaminationIndex: null
			}}
		>
			{children}
		</Provider>
	);
};

export const useExaminationsV2Context = <Selector,>(
	selector: (s: IExaminationsV2Context) => Selector = () => null as Selector
) => {
	const params = useParams();
	const queryClient = useQueryClient();
	const { store, setStore, getStore } = useStore(selector);

	const {
		setActiveExaminationSearchParam,
		deleteActiveExaminationSearchParam
	} = useActiveExaminationSearchParams();

	const { deactivateTooltip, hideTooltip } = useTooltipContext();

	const examinationsListQueryKey = useExaminationsListQueryKey();

	const getExaminationsV2ContextStore = () => getStore();

	const setInitialActiveExaminationIndex = React.useCallback(
		(initialActiveExaminationIndex: number) => {
			setStore({ initialActiveExaminationIndex });
		},
		[]
	);

	const setDateRange = React.useCallback(
		(dateRange: TDateRange) => {
			setStore({ examinationsDateRange: dateRange });
			hideTooltip();
			deactivateTooltip();
		},
		[params.patientId]
	);

	const setActiveExamination = (
		data: ActiveExamination,
		forceUpdateSearchParams = false
	) => {
		setStore({ activeExamination: data });
		forceUpdateSearchParams && setActiveExaminationSearchParam(data);
	};

	const setActiveExaminationIndex = (index: number) => {
		setStore((prev) => ({
			activeExamination: { ...prev.activeExamination, index }
		}));
	};

	const setListDataWithoutActiveExamination = React.useCallback(
		(
			listDataWithoutActiveExamination: ExaminationsListConvertedItemModel[]
		) => {
			setStore({ listDataWithoutActiveExamination });
		},
		[]
	);

	const setExaminationsListData = React.useCallback(
		(data: ExaminationsListConvertedItemModel[]) => {
			setStore({ examinationsListData: data });
		},
		[]
	);

	const setExaminationsListDataWithFilters = React.useCallback(
		({
			examinationsListData = queryClient.getQueryData<
				ExaminationsListConvertedItemModel[]
			>(examinationsListQueryKey),
			examinationsDateRange,
			selectedExaminationTypes
		}: {
			examinationsListData?:
				| IExaminationsV2Context['examinationsListData']
				| null;
			examinationsDateRange?: IExaminationsV2Context['examinationsDateRange'];
			selectedExaminationTypes?: IExaminationsV2Context['selectedExaminationTypes'];
		}): ExaminationsListConvertedItemModel[] => {
			let filteredData: ExaminationsListConvertedItemModel[] = [];
			let availableExaminationTypes: ExaminationTypeParam[] = [];
			let selectedTypes: ExaminationTypeParam[] = [];
			if (!examinationsListData || examinationsListData?.length === 0) {
				setStore({ examinationsListData: [] });
				return filteredData;
			}

			setStore((prevState) => {
				const dateRange =
					examinationsDateRange ?? prevState.examinationsDateRange;
				let examinationsListDataFilteredByDateRange =
					examinationsListData;
				if (
					dateRange.endDate !== null &&
					dateRange.startDate !== null
				) {
					examinationsListDataFilteredByDateRange =
						ExaminationsListServicesV2.filterExaminationByDateRange(
							examinationsListData,
							dateRange as TDateRange // fields cannot be nulls
						);
				}
				if (examinationsListDataFilteredByDateRange.length === 0) {
					return { examinationsListData: [] };
				}

				availableExaminationTypes =
					ExaminationsListServicesV2.findAvailableExaminationTypesInExaminationsList(
						examinationsListDataFilteredByDateRange
					);

				selectedTypes =
					selectedExaminationTypes ??
					prevState.selectedExaminationTypes;
				selectedTypes = selectedTypes.filter((t) =>
					availableExaminationTypes.includes(t)
				);

				const filteredExaminationsList =
					ExaminationsListServicesV2.filterExaminationsByExaminationTypes(
						examinationsListDataFilteredByDateRange,
						selectedTypes.length === 0
							? availableExaminationTypes
							: selectedTypes
					);
				// if in one day there is none of the selected examination type two creation dates items are next to each other.
				// them should be also filtered
				const filteredCreatedDateTimes =
					ExaminationsListServicesV2.deleteAllAdjacentCreationDateItems(
						filteredExaminationsList
					);
				filteredData = filteredCreatedDateTimes;

				return { examinationsListData: filteredData };
			});

			setStore({
				selectedExaminationTypes:
					selectedTypes.length === 0
						? availableExaminationTypes
						: selectedTypes
			});
			setStore({
				availableExaminationTypesInList: availableExaminationTypes
			});

			return filteredData;
		},
		[examinationsListQueryKey]
	);

	const deleteActiveExamination = React.useCallback(() => {
		setStore({ activeExamination: DEFAULT_ACTIVE_EXAMINATION_STATE });
		setStore({ initialActiveExaminationIndex: null });
		setExaminationsListDataWithFilters({});
		deleteActiveExaminationSearchParam();
	}, []);

	const setExaminationsDateExtremes = React.useCallback(
		(data: TDateRange) => {
			setStore({
				examinationDateExtremes: {
					startDate: startOfDay(data.startDate),
					endDate: endOfDay(data.endDate)
				}
			});
		},
		[]
	);

	const setSelectedExaminationTypes = React.useCallback(
		(
			examinationTypes: IExaminationsV2Context['selectedExaminationTypes']
		) => {
			setStore({ selectedExaminationTypes: examinationTypes });
		},
		[]
	);

	const toggleSelectedExaminationTypes = React.useCallback(
		(examinationType: ExaminationTypeParam) => {
			let newSelectedExaminationTypesState: ExaminationTypeParam[] = [];
			setStore((store) => {
				newSelectedExaminationTypesState = [
					...store.selectedExaminationTypes,
					examinationType
				];
				if (store.selectedExaminationTypes.includes(examinationType)) {
					newSelectedExaminationTypesState =
						store.selectedExaminationTypes.filter(
							(t) => t !== examinationType
						);
				}

				return {
					selectedExaminationTypes: newSelectedExaminationTypesState
				};
			});
			return newSelectedExaminationTypesState;
		},
		[]
	);

	const selectAllExaminationTypes = React.useCallback(
		(
			allExaminationTypes: ExaminationTypeParam[]
		): ExaminationTypeParam[] => {
			setStore({ selectedExaminationTypes: allExaminationTypes });
			return allExaminationTypes;
		},
		[]
	);

	const selectOneExaminationType = React.useCallback(
		(examinationType: ExaminationType): ExaminationTypeParam[] => {
			setStore({ selectedExaminationTypes: [examinationType] });
			return [examinationType];
		},
		[]
	);

	const setExaminationsListDataSuccess = React.useCallback(() => {
		setStore({ isExaminationsListDataSuccess: true });
	}, []);

	const setAvailableExaminationsTypes = React.useCallback(
		(availableExaminationTypes?: ExaminationTypeParam[]) => {
			if (availableExaminationTypes) {
				setStore({
					availableExaminationTypesInList: availableExaminationTypes
				});
				return;
			}

			setStore((store) => {
				const availableExaminationTypes =
					ExaminationsListServicesV2.findAvailableExaminationTypesInExaminationsList(
						store.examinationsListData
					);
				return {
					availableExaminationTypesInList: availableExaminationTypes,
					selectedExaminationTypes:
						store.selectedExaminationTypes.filter((t) =>
							availableExaminationTypes.includes(t)
						)
				};
			});
		},
		[]
	);

	return {
		store,
		setExaminationsListDataSuccess,
		setDateRange,
		setExaminationsDateExtremes,
		setActiveExamination,
		setActiveExaminationIndex,
		deleteActiveExamination,
		setExaminationsListData,
		setExaminationsListDataWithFilters,
		setSelectedExaminationTypes,
		toggleSelectedExaminationTypes,
		selectAllExaminationTypes,
		selectOneExaminationType,
		setAvailableExaminationsTypes,
		setInitialActiveExaminationIndex,
		setListDataWithoutActiveExamination,
		getExaminationsV2ContextStore
	};
};
