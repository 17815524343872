import { Typography } from '@material-ui/core';
import { getParameterTypeLabel } from 'helpers';
import React from 'react';

import { useStyles } from './styles';

type Props = {
	chartName: ExaminationChartNames;
};
const ExaminationTypeLabel = ({ chartName }: Props) => {
	const classes = useStyles();
	const label = getParameterTypeLabel({ chartName });

	return <Typography className={classes.label}>{label}</Typography>;
};

export default ExaminationTypeLabel;
