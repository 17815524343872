// Import patientsPaths
import { SCHEDULE_PATHS_PARAMS_KEYS } from 'components/routes/paths';

export const INITIAL_SCHEDULE_FORM_VALUES = {
	patient_id: '',
	patient_name: '',
	start_date: '',
	start_time: '',
	alert_before: 5,
	doctor_id: '',
	notes: '',
	duration: 15
};

const { PATIENT_ID } = SCHEDULE_PATHS_PARAMS_KEYS;
export const getSchedulePatientParamId = (params) => {
	const pathPatientId = PATIENT_ID.replace(':', '');
	return params[pathPatientId] || null;
};
