import clsx from 'clsx';
import { EMPTY_VALUE, EXAMINATION_CRITERIA } from 'helpers';
import React from 'react';

import { ContainerWithBorder, Label, Title } from '../../../components';
import { ErrorLabel } from '../../../components/TechnicalErrors/ErrorLabel';
import { filterErrors } from '../../../components/TechnicalErrors/helpers';
import { PrePostColumnProps } from '../PrePostColumn';
import { useStyles } from './styles';

type Props = {
	measurements: PrePostColumnProps['measurements'];
	activeMeasurementIndex: PrePostColumnProps['activeMeasurementIndex'];
	criteria?: ValuesOf<typeof EXAMINATION_CRITERIA>;
	className?: string;
};

export const TechnicalError = ({
	measurements,
	activeMeasurementIndex,
	criteria = EXAMINATION_CRITERIA.ATS2019,
	className = ''
}: Props) => {
	const classes = useStyles();
	const errorsToMap = filterErrors(
		measurements?.[activeMeasurementIndex]?.atsError || null,
		criteria
	);

	return (
		<ContainerWithBorder className={clsx([classes.root, className])}>
			<Title title="patients.examinations.details.spirometry.errors" />
			<>
				{!errorsToMap?.length || errorsToMap.length === 0 ? (
					<Label label={EMPTY_VALUE} />
				) : null}
				{errorsToMap.map((error) => (
					<ErrorLabel key={error} error={error} />
				))}
			</>
		</ContainerWithBorder>
	);
};
