import { CancelToken } from 'axios';
import {
	ACCOUNT_DELETION_AVAILABILITY_API,
	ACCOUNT_DELETION_CONFIRMATION,
	ACCOUNT_DELETION_CREDENTIALS_CONFIRMATiON_API,
	ACCOUNT_DELETION_DELETE,
	FIREBASE_MESSAGING_TOKEN_API
} from 'helpers';
import {
	RegisterFirebaseMessagingTokenRequestModel,
	RegisterFirebaseMessagingTokenRequestSchema,
	RegisterFirebaseMessagingTokenResponseSchema,
	UnregisterFirebaseMessagingTokenRequestModel,
	UnregisterFirebaseMessagingTokenRequestSchema,
	UnregisterFirebaseMessagingTokenResponseSchema
} from 'models/User/User';
import { replaceTokenQueryParam } from 'queries/common';
import services from 'services/services';
import { servicesV2 } from 'services/servicesV2';

export class AccountRepository {
	static deleteAccount({
		cancelToken
	}: {
		cancelToken: CancelToken;
	}): Promise<void> {
		return services.get(ACCOUNT_DELETION_AVAILABILITY_API, {
			cancelToken
		});
	}

	static confirmDeletionWithCredentials(
		payload: ConfirmDeletionWithCredentialsPayload,
		cancelToken: CancelToken
	): Promise<void> {
		return services.post(
			ACCOUNT_DELETION_CREDENTIALS_CONFIRMATiON_API,
			payload,
			{ cancelToken }
		);
	}

	static confirmDeletion(_: void, cancelToken: CancelToken): Promise<void> {
		return services.post(
			ACCOUNT_DELETION_CONFIRMATION,
			{},
			{ cancelToken }
		);
	}

	static finalDeleteAccount(token: string): Promise<void> {
		return services.delete(
			replaceTokenQueryParam(ACCOUNT_DELETION_DELETE, token)
		);
	}

	static registerFirebaseMessagingToken(
		payload: RegisterFirebaseMessagingTokenRequestModel
	) {
		return servicesV2.post(FIREBASE_MESSAGING_TOKEN_API, payload, {
			requestSchema: RegisterFirebaseMessagingTokenRequestSchema,
			responseSchema: RegisterFirebaseMessagingTokenResponseSchema
		});
	}

	static unregisterFirebaseMessagingToken(
		payload: UnregisterFirebaseMessagingTokenRequestModel
	) {
		return servicesV2.delete(
			FIREBASE_MESSAGING_TOKEN_API + '?fir_token=' + payload.fir_token,
			{
				requestSchema: UnregisterFirebaseMessagingTokenRequestSchema,
				responseSchema: UnregisterFirebaseMessagingTokenResponseSchema
			},
			payload
		);
	}
}
