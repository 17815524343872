import { Alert, LoaderSpinner } from 'components/elements';
import React from 'react';

import AppointmentFormWrapper from '../AppointmentFormWrapper';
import ScheduleForm from '../ScheduleForm';
import TopBar from '../TopBar';
import useEditAppointment from './useEditAppointment';

const EditAppointment = () => {
	const {
		initialValues,
		formTitle,
		showEditControls,
		alert,
		apiError,
		isPatientsLoaded
	} = useEditAppointment();

	return (
		<AppointmentFormWrapper>
			<TopBar formTitle={formTitle} showEditControls={showEditControls} />
			{(alert.show || apiError) && (
				<Alert
					title={alert.content.title || 'global.error'}
					message={alert.content.message || apiError}
					type={alert.type}
				/>
			)}
			{initialValues && isPatientsLoaded ? (
				<ScheduleForm
					submitBtnText={'schedule.form.btn_edit'}
					initialValues={initialValues}
				/>
			) : (
				!apiError && !alert.show && <LoaderSpinner minHeight={'100%'} />
			)}
		</AppointmentFormWrapper>
	);
};

export default EditAppointment;
