import { convertValueUnit, DEFAULT_UNITS } from 'helpers';
import { ADDITIONAL_FIELD_NAMES } from 'helpers/Forms/fieldNames/patient';
import { PatientServices, useSettingsServices } from 'queries';
import { SETTING_NAMES } from 'queries/Users/Patient/helpers/common';

type WeightHeightUnitsData = {
	patientWeight: number | null;
	patientHeight: number | null;
};

export const usePatientDataConversionInExaminationContext = () => {
	const SettingServices = useSettingsServices();

	const convertPatientDataInExaminationContext = (
		patientUnitsData: WeightHeightUnitsData
	) => {
		const data = {
			...patientUnitsData,
			[ADDITIONAL_FIELD_NAMES.WEIGHT_UNIT]: '',
			[ADDITIONAL_FIELD_NAMES.HEIGHT_UNIT]: ''
		};

		const dataWithSettings = Object.entries(data).reduce(
			(acc, [fieldName, initValue]) => {
				const indexOfSettingName = SETTING_NAMES.findIndex(
					({ field }) => field === fieldName
				);
				if (indexOfSettingName !== -1) {
					const userSettingValue = SettingServices.findByName(
						SETTING_NAMES[indexOfSettingName].settingName
					).value[0];
					acc[fieldName] = userSettingValue;
				} else {
					acc[fieldName] = initValue;
				}
				return acc;
			},
			{} as WeightHeightUnitsData & {
				patientWeightUnit: string;
				patientHeightUnit: string;
			}
		);

		const convertedPatientHeight = convertValueUnit(
			dataWithSettings.patientHeight ?? 1,
			DEFAULT_UNITS.HEIGHT,
			dataWithSettings.patientHeightUnit
		);
		const convertedPatientWeight = convertValueUnit(
			dataWithSettings.patientWeight ?? 1,
			DEFAULT_UNITS.WEIGHT,
			dataWithSettings.patientWeightUnit
		);

		const patientBmi = PatientServices.addBmiToPatientData({
			...dataWithSettings,
			patientHeight: convertedPatientHeight,
			patientWeight: convertedPatientWeight
		});

		return {
			...dataWithSettings,
			patientBmi: patientBmi?.patientBmi ?? 0,
			patientHeight: convertedPatientHeight,
			patientWeight: convertedPatientWeight
		};
	};

	return { convertPatientDataInExaminationContext };
};
