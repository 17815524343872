import { MARKERS } from './variables';

export const convertResults = (
	{
		above80,
		dateCreated,
		exist,
		isCorrect,
		value,
		examinationId: examId,
		id,
		type,
		note
	},
	showMarkers
) => {
	const [date] = dateCreated.split(' ');
	const examinationId = examId || id;

	const convertedDate = new Date(`${date}T12:00:00`).getTime();
	const options = { examinationId, type };

	if (!showMarkers) {
		return {
			y: value,
			x: convertedDate
		};
	} else if (exist && type && !note) {
		if (!above80) {
			return {
				y: value,
				x: convertedDate,
				marker: { ...MARKERS.BEYOND_BEST, ...options }
			};
		} else if (isCorrect) {
			return {
				y: value,
				x: convertedDate,
				marker: { ...MARKERS.CORRECT, ...options }
			};
		}
		return {
			y: value,
			x: convertedDate,
			marker: { ...MARKERS.IN_CORRECT, ...options }
		};
	}
	return {
		y: value,
		x: convertedDate,
		marker: { ...MARKERS.DEFAULT, ...options }
	};
};
