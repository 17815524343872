import { Typography } from '@material-ui/core';
import { bool, string } from 'prop-types';
import React from 'react';

import { useStyles } from './styles';

const ParameterLabel = ({ label, name, isLastElement }) => {
	const classes = useStyles({ name });

	return (
		<Typography
			className={classes.selectedItem}
			variant="subtitle1"
			component="p"
		>
			{label}
			{!isLastElement && (
				<Typography component="span" className={classes.comma}>
					,
				</Typography>
			)}
		</Typography>
	);
};

ParameterLabel.propTypes = {
	label: string.isRequired,
	name: string.isRequired,
	isLastElement: bool.isRequired
};

export default ParameterLabel;
