import PubSubJS from 'pubsub-js';
import { FirebaseMessageModel } from 'utilities/firebase/types';

const PubSubKeys = {
	FIREBASE_MESSAGING: 'FIREBASE_MESSAGING'
};

export const PubSub = {
	firebaseMessaging: {
		publish(message: FirebaseMessageModel) {
			PubSubJS.publish(PubSubKeys.FIREBASE_MESSAGING, message);
		},
		subscribe(callback: (message: FirebaseMessageModel) => void) {
			return PubSubJS.subscribe(
				PubSubKeys.FIREBASE_MESSAGING,
				(key, data) => {
					callback(data as unknown as FirebaseMessageModel);
				}
			);
		},
		unsubscribe(token: string) {
			PubSubJS.unsubscribe(token);
		}
	}
};
