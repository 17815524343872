import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import {
	FieldValues,
	FormProvider,
	SubmitHandler,
	useForm,
	UseFormProps,
	UseFormReturn
} from 'react-hook-form';
import { z } from 'zod';

import { Alert } from '../../Alerts';

export interface IFormRHFProps<TFieldValues extends FieldValues> {
	config?: UseFormProps<TFieldValues>;
	defaultValues: UseFormProps<TFieldValues>['defaultValues'];
	onSubmit: SubmitHandler<TFieldValues>;
	//eslint-disable-next-line
	validationSchema?: z.ZodType<any, any, any>;
	//eslint-disable-next-line
	children: (methods: UseFormReturn<TFieldValues, any>) => React.ReactNode;
	apiError: ApiErrorState;
	apiFieldErrorTranslationKeyPrefix?: string;
}

export const FormRHF = <TFieldValues extends FieldValues>({
	children,
	validationSchema,
	config,
	defaultValues,
	onSubmit,
	apiError,
	apiFieldErrorTranslationKeyPrefix
}: IFormRHFProps<TFieldValues>) => {
	const methods = useForm<TFieldValues>({
		...config,
		defaultValues: config?.defaultValues ?? defaultValues,
		resolver: validationSchema && zodResolver(validationSchema)
	});

	return (
		<FormProvider {...methods}>
			<form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
				<Alert
					message={apiError}
					apiFieldErrorTranslationKeyPrefix={
						apiFieldErrorTranslationKeyPrefix
					}
				/>
				{children(methods)}
			</form>
		</FormProvider>
	);
};
