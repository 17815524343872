import { InfoOutlinedIcon } from 'assets/icons/commonMUIIcons';
import React from 'react';

import { Tooltip as MyTooltip } from '../../../../../Tooltips';
import { TooltipContent } from './components';
import { useStyles } from './styles';

const Tooltip = () => {
	const classes = useStyles();

	return (
		<MyTooltip tooltipContent={TooltipContent}>
			<InfoOutlinedIcon color="primary" className={classes.icon} />
		</MyTooltip>
	);
};

export default Tooltip;
