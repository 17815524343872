import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		margin: '0 8px',
		padding: '18px 25px',
		background: ({ type }) => theme.palette.alerts[type],
		borderRadius: 5
	},
	content: {
		display: 'flex'
	},
	icon: { width: 50 },
	message: { marginLeft: 16 },
	error: {
		color: theme.palette.error.main
	}
}));
