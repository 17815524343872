import { Box, Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/styles';
import { array, string } from 'prop-types';
import React from 'react';

export const useStyles = makeStyles((theme) => ({
	item: {
		marginBottom: theme.spacing(1)
	},
	label: { textTransform: 'capitalize' }
}));

const ErrorsAlertMessage = ({ errorMessages, className }) => {
	const classes = useStyles();
	return (
		<Grid item xs={12}>
			<Alert className={className} severity="error">
				{errorMessages.map(({ description, name }) => (
					<Box className={classes.item} component="p" key={name}>
						<Box component="span" className={classes.label}>
							<strong>{name}</strong>
						</Box>
						: {description}
					</Box>
				))}
			</Alert>
		</Grid>
	);
};

ErrorsAlertMessage.propTypes = {
	errorMessages: array.isRequired,
	className: string
};

export default ErrorsAlertMessage;
