import { useLanguage } from 'components/utilities';
import { useTranslations } from 'components/utilities';
import React from 'react';

import { defaultStaticRanges, getStaticRanges } from './helpers';

const useDateRangePicker = ({
	firstDate,
	lastDate,
	initialDate,
	generateStaticRanges,
	onChange,
	close
}) => {
	const { localLng } = useLanguage();
	const [currentSelectedDate, setCurrentSelectedDate] = React.useState([
		{
			...initialDate,
			key: 'selection'
		}
	]);
	const { t } = useTranslations();

	const staticRangesGenerator = generateStaticRanges || defaultStaticRanges;

	const staticRanges = getStaticRanges(
		{ t, firstDate, lastDate },
		staticRangesGenerator
	);

	const handleChangeCurrentSelectedDate = (item) =>
		setCurrentSelectedDate([item.selection]);

	const handleSubmitChanges = () => {
		onChange(currentSelectedDate);
		close();
	};

	return {
		currentSelectedDate,
		localLng: localLng(),
		staticRanges,
		onChangeCurrentSelectedDate: handleChangeCurrentSelectedDate,
		submit: handleSubmitChanges
	};
};

export default useDateRangePicker;
