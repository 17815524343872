import z from 'zod';

export const FIREBASE_MESSAGING_TYPES = {
	INVITATION_ACCEPTED_BY_PATIENT: 'INVITATION_ACCEPTED_BY_PATIENT',
	INVITATION_DECLINED_BY_PATIENT: 'INVITATION_DECLINED_BY_PATIENT',
	SHARING_CANCELLED_BY_PATIENT: 'SHARING_CANCELLED_BY_PATIENT',
	SUBSCRIPTION_UPCOMING_EXPIRATION: 'SUBSCRIPTION_UPCOMING_EXPIRATION',
	CHAT_MESSAGE: 'NEW_MESSAGE'
} as const;

export const FirebaseMessageSchema = z.object({
	notification: z.object({
		body: z.string(),
		title: z.string()
	}),
	data: z.union([
		z.object({
			id: z.string(),
			panelTargetUrl: z.string(),
			type: z.literal(FIREBASE_MESSAGING_TYPES.CHAT_MESSAGE)
		}),
		z.object({
			id: z.string(),
			patientId: z.string().optional(),
			panelTargetUrl: z.string(),
			type: z.union([
				z.literal(
					FIREBASE_MESSAGING_TYPES.INVITATION_ACCEPTED_BY_PATIENT
				),
				z.literal(
					FIREBASE_MESSAGING_TYPES.INVITATION_DECLINED_BY_PATIENT
				),
				z.literal(
					FIREBASE_MESSAGING_TYPES.SHARING_CANCELLED_BY_PATIENT
				),
				z.literal(
					FIREBASE_MESSAGING_TYPES.SUBSCRIPTION_UPCOMING_EXPIRATION
				)
			])
		})
	])
});

export type FirebaseMessageModel = z.infer<typeof FirebaseMessageSchema>;
