import { makeStyles } from '@material-ui/styles';
import theme from 'theme';
import palette from 'theme/palette';

import { ITableHeaderCellProps } from './TableHeaderCell';

export const useStyles = makeStyles<
	typeof theme,
	{ textAlign: ITableHeaderCellProps['textAlign'] }
>(() => ({
	cell: {
		padding: '5px 0',
		marginLeft: ({ textAlign }) => (textAlign === 'right' ? 10 : 0),
		marginRight: ({ textAlign }) => (textAlign === 'right' ? 0 : 10),
		display: 'flex',
		alignItems: 'center',
		flexDirection: ({ textAlign }) =>
			textAlign === 'right' ? 'row-reverse' : 'row'
	},
	sortChevron: {
		zIndex: 1,
		verticalAlign: 'middle',
		transition: 'transform 500ms ease-in-out',
		color: palette.gray.disabled,
		transform: 'rotate(0deg)'
	},
	sortChevronDesc: {
		transform: 'rotate(270deg)',
		color: palette.primary.main
	},
	sortChevronAsc: {
		transform: 'rotate(90deg)',
		color: palette.primary.main
	}
}));
