import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	flex: {
		display: 'flex',
		gap: 1,
		alignItems: 'baseline'
	},
	unit: {
		fontSize: '6.5px',
		lineHeight: '6.5px'
	}
}));
