import { Button,Input, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { CloseIcon,SearchIcon } from 'assets/icons/commonMUIIcons';
import clsx from 'clsx';
import { func, object,string } from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
	root: {
		borderRadius: '4px',
		alignItems: 'center',
		padding: theme.spacing(1),
		display: 'flex',
		flexBasis: 420
	},
	icon: {
		marginRight: theme.spacing(1),
		color: theme.palette.text.secondary
	},
	iconClose: {
		color: theme.palette.text.secondary
	},
	input: {
		flexGrow: 1,
		fontSize: '14px',
		lineHeight: '16px',
		letterSpacing: '-0.05px'
	}
}));

const SearchInput = (props) => {
	const {
		className,
		style,
		setGlobalFilter,
		clearGlobalFilters,
		globalFilter,
		...rest
	} = props;

	const classes = useStyles();

	return (
		<Paper
			{...rest}
			className={clsx(classes.root, className)}
			style={style}
		>
			<SearchIcon className={classes.icon} />
			<Input
				{...rest}
				value={globalFilter || ''}
				className={classes.input}
				disableUnderline
				onChange={setGlobalFilter}
			/>
			{globalFilter && (
				<Button>
					<CloseIcon
						onClick={clearGlobalFilters}
						className={classes.iconClose}
					/>
				</Button>
			)}
		</Paper>
	);
};

SearchInput.propTypes = {
	className: string,
	onChange: func,
	style: object,
	setGlobalFilter: func.isRequired,
	clearGlobalFilters: func.isRequired,
	globalFilter: string.isRequired
};

export default SearchInput;
