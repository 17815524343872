import { Typography } from '@material-ui/core';
import React from 'react';
import { useParams } from 'utilities';

export const InvalidExaminationTypeRedirect = () => {
	const { examinationType } = useParams();
	return (
		<Typography variant="h4">
			Examination type{' '}
			<strong>{examinationType?.toString()?.toUpperCase()}</strong> not
			found
		</Typography>
	);
};
