import { useInitialLanguageTranslations } from 'components/utilities';
import { areBooleansTruthy } from 'helpers';
import { useFinalDeleteAccount } from 'queries';
import { useParams } from 'utilities';

const useAccountDeletion = () => {
	const { token } = useParams();

	const {
		isLoading: isFinalDeleteAccountLoading,
		apiError
	} = useFinalDeleteAccount();

	const { isLoaded: isLanguageLoaded } = useInitialLanguageTranslations();

	const isLoading = areBooleansTruthy(
		'some',
		isFinalDeleteAccountLoading,
		isLanguageLoaded
	);
	return { token, isLoading, apiError };
};

export default useAccountDeletion;
