import { isDate, isValid } from 'date-fns';

export const validateDateIsRequired = (date) => {
	if (!date) {
		throw new Error(`Date cannot be null or undefined, given: ${date}`);
	}
};

export const validateDateFormat = (date) => {
	if (!isValid(date) || !isDate(date)) {
		throw new Error(
			`Invalid date format. Argument should be instance of Date class. Given: ${date}`
		);
	}
};
