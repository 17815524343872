import { IconButton } from '@material-ui/core';
import { MoreVertIcon } from 'assets/icons/commonMUIIcons';
import { MenuRPC } from 'components/utilities';
import React from 'react';

import { useStyles } from './styles';

export interface IActionsTableCellContentProps {
	children: (args: { close: () => void }) => CmpChildren;
}

export const Actions = ({ children }: IActionsTableCellContentProps) => {
	const classes = useStyles();
	return (
		<MenuRPC
			togglerComponent={({ open }) => (
				<IconButton onClick={open} size="small">
					<MoreVertIcon className={classes.icon} />
				</IconButton>
			)}
		>
			{({ close }) => <div>{children({ close })}</div>}
		</MenuRPC>
	);
};
