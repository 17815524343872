import { Box } from '@material-ui/core';
import {
	Alert,
	ErrorBoundary,
	LoaderSpinnerTransitioned,
	Placeholder
} from 'components/elements';
import { useFadeTransitionStyles } from 'components/elements/atoms/Transitions/styles';
import { EXAMINATIONS_TYPES, TRANSITIONS } from 'helpers';
import React from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import { createExaminationId } from '../helpers';
import {
	Copd,
	MedicalNote,
	PeakFlow,
	PulseOximetry,
	SlowVitalCapacity,
	SpirometryFVC
} from './detailsComponents';
import { useExaminationDetails } from './hooks/useExaminationDetails';
import { useStyles } from './styles';

export const ExaminationDetails = () => {
	const transitionClasses = useFadeTransitionStyles();
	const ref = React.useRef(null);

	const {
		isLoading,
		apiError,
		isError,
		examinationType,
		examinationsListQueryData,
		activeExamination
	} = useExaminationDetails();

	const classes = useStyles({ isLoader: isLoading });

	const renderExaminationDetails = (): JSX.Element => {
		if (isError) return <Alert message={apiError} />;

		switch (examinationType) {
			case EXAMINATIONS_TYPES.SPIROMETRY.type:
				return <SpirometryFVC />;
			case EXAMINATIONS_TYPES.SVC.type:
				return <SlowVitalCapacity />;
			case EXAMINATIONS_TYPES.COPD_TEST.type:
				return <Copd />;
			case EXAMINATIONS_TYPES.PULSE_OXIMETRY.type:
				return <PulseOximetry />;
			case EXAMINATIONS_TYPES.PEAK_FLOW.type:
				return <PeakFlow />;
			case EXAMINATIONS_TYPES.MEDICAL_NOTE.type:
				return <MedicalNote />;
			default:
				return (
					<Alert message={'Unavailable examination type provided'} />
				);
		}
	};

	return (
		<Box className={classes.examinationDetailsContainer}>
			<ErrorBoundary>
				<SwitchTransition>
					<CSSTransition
						nodeRef={ref}
						key={createExaminationId(activeExamination)}
						timeout={TRANSITIONS.DURATIONS.enteringScreen}
						classNames={{
							enter: transitionClasses.enter,
							exit: transitionClasses.exit,
							enterActive: transitionClasses.enterActive,
							exitActive: transitionClasses.exitActive
						}}
						unmountOnExit
					>
						<div ref={ref} className={classes.detailsContainer}>
							<LoaderSpinnerTransitioned
								isLoading={isLoading}
								className={classes.loader}
								rootSize="full"
							/>
							<Placeholder
								classes={{
									root: classes.detailsContainer,
									labelContainer: classes.detailsContainer
								}}
								active={
									!isLoading &&
									!activeExamination.id &&
									examinationsListQueryData?.length === 0
								}
								label={
									'patients.examinations_v2.no_examinations_data.long'
								}
								prefixMarker=""
								suffixMarker=""
								backgroundColor="inherit"
							>
								{!isLoading && activeExamination.id ? (
									renderExaminationDetails()
								) : (
									<></>
								)}
							</Placeholder>
						</div>
					</CSSTransition>
				</SwitchTransition>
			</ErrorBoundary>
		</Box>
	);
};
