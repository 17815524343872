import { useContext } from 'react';

// Import context
import { ItemWrapperContext } from './ItemWrapperProvider';

const useItemWrapperContext = () => {
	const context = useContext(ItemWrapperContext);

	if (!context) {
		throw new Error(
			'useItemWrapperContext must be used within a ItemWrapperProvider'
		);
	}
	return context;
};

export default useItemWrapperContext;
