import * as Sentry from '@sentry/browser';
import { useSnackbar } from 'notistack';
import { useRegisterUserFirebaseMessagingToken } from 'queries';
import { useUnregisterUserFirebaseMessagingToken } from 'queries/Users/useUnregisterUserFirebaseMessagingToken';
import React from 'react';
import { getMessagingToken, isMessagingSupported } from 'utilities/firebase';

import { notificationsDirector } from '../notificationsDirector';

export const useFirebaseMessagingInitialization = () => {
	const tokenRef = React.useRef<string>('');

	const { enqueueSnackbar } = useSnackbar();

	const { mutate: registerFirebaseMessagingToken } =
		useRegisterUserFirebaseMessagingToken();
	const { mutate } = useUnregisterUserFirebaseMessagingToken();

	async function getFirebaseMessagingToken() {
		if (!(await isMessagingSupported())) return;

		if (Notification?.permission !== 'granted') return;

		try {
			const token = await getMessagingToken();
			if (token) {
				tokenRef.current = token ?? '';
				registerFirebaseMessagingToken({ fir_token: tokenRef.current });
			}
		} catch (error) {
			Sentry.captureException(error, {
				extra: {
					by: 'getFirebaseMessagingToken'
				}
			});
			notificationsDirector(enqueueSnackbar).somethingWentWrong();
		}
	}

	React.useEffect(() => {
		(async () => await getFirebaseMessagingToken())();
		return () => {
			if (tokenRef.current) {
				mutate({ fir_token: tokenRef.current });
			}
		};
	}, []);
};
