import { useMetricSystem } from 'components/utilities';

const useParameterValue = ({ value } = {}) => {
	const { numberSeparator } = useMetricSystem();
	const resultValue =
		!value || Number(value) <= 0 ? value : numberSeparator(value);

	return { resultValue };
};

export default useParameterValue;
