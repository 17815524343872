import { TableCell } from '@material-ui/core';
import { format } from 'date-fns';
import { DATE_FORMAT } from 'helpers/variables';
import { number, object } from 'prop-types';
import React from 'react';

import { getDateFormat } from './helpers';
import { useStyles } from './styles';

const { DEFAULT } = DATE_FORMAT;

const DateTableCell = ({ cell, minWidth = 100 }) => {
	const classes = useStyles({ minWidth });
	const { date = null, date_format } = cell.row.original;

	const dateISO = date && date !== '0000-00-00' ? new Date(date) : new Date();

	const dateFormatted = date_format
		? format(dateISO, getDateFormat(date_format))
		: format(dateISO, DEFAULT);

	return (
		<TableCell
			className={classes.tableCell}
			key={cell.column.id}
			{...cell.getCellProps()}
		>
			{dateFormatted}
		</TableCell>
	);
};

DateTableCell.propTypes = {
	cell: object.isRequired,
	minWidth: number
};

export default DateTableCell;
