import { Box, Typography as MuiTypography } from '@material-ui/core';
import { useTranslations } from 'components/utilities';
import { DATE_FORMAT } from 'helpers';
import { number, object, shape, string } from 'prop-types';
import { LocaleDate } from 'utilities';

import { Controls } from './Controls';
import { useStyles } from './styles';
import useProfile from './useProfile';

const Profile = ({ countries, patient }) => {
	const classes = useStyles();
	const { t } = useTranslations();

	const { patientFirstName, patientLastName, patientCity, age, created_at } =
		patient.patient;

	const { patientCountry, lineDivider, commaDivider } = useProfile({
		patient,
		countries
	});

	return (
		<Box className={classes.root}>
			<Box
				display={'flex'}
				flexWrap="wrap"
				alignItems={'center'}
				marginBottom="5px"
			>
				<MuiTypography
					className={classes.name}
					variant="subtitle1"
					component="p"
				>
					{patientFirstName} {patientLastName}
				</MuiTypography>
				<Controls {...patient.patient} />
			</Box>
			<MuiTypography
				className={classes.desc}
				variant="body2"
				component="p"
			>
				{age} {t('patients.basic_data.basic_info_years')} {lineDivider}
				{patientCity && patientCity}
				{patientCountry && `${commaDivider} ${patientCountry}`}
			</MuiTypography>
			<MuiTypography
				className={classes.desc}
				variant="body2"
				component="p"
			>
				{t('patients.basic_data.profile.your_patient_since')}{' '}
				<strong>
					{new LocaleDate(created_at).getStringFormatDate(
						DATE_FORMAT.PROFILE
					)}
				</strong>
			</MuiTypography>
		</Box>
	);
};

Profile.propTypes = {
	patient: shape({
		patient: shape({
			patientFirstName: string,
			patientLastName: string,
			patientCity: string,
			patientCountryCode: string,
			age: number,
			patientGender: string
		})
	}),
	countries: object.isRequired
};

export default Profile;
