import { DASH_PATHS } from 'components/routes/paths';
import { RECEPTIONIST } from 'helpers';
import { useSelector } from 'react-redux';

const { PATIENTS } = DASH_PATHS;

const useRedirect = () => {
	const authenticate = useSelector(({ auth }) => auth.authenticate);

	if (authenticate?.data?.roles) {
		const roles = authenticate.data.roles;
		const redirect = roles.includes(RECEPTIONIST[0]) ? PATIENTS : PATIENTS;
		return { redirect };
	}
	return { redirect: PATIENTS };
};

export default useRedirect;
