import { TooltipHeader } from 'components/elements/molecules';
import React from 'react';

import { LegendItem } from './components';
import { POINT_LEGEND } from './helpers';

export const TooltipContent = () => {
	return (
		<>
			<TooltipHeader title="patients.examinations.chart.legend.title" />
			{POINT_LEGEND.map((legendItem) => (
				<LegendItem key={legendItem.description} {...legendItem} />
			))}
		</>
	);
};
