import { Box, Typography as MuiTypography } from '@material-ui/core';
import { useTranslations } from 'components/utilities';
import React from 'react';

import { Parameters, ParametersGroups } from './components';
import { ParametersProvider } from './context';
import { useStyles } from './styles';

const ChartParameters = () => {
	const classes = useStyles();
	const { t } = useTranslations();
	return (
		<Box className={classes.root}>
			<MuiTypography className={classes.title} variant="h6" component="p">
				{t('patients.examinations.parameters.title')}:
			</MuiTypography>
			<ParametersProvider>
				<ParametersGroups />
				<Parameters />
			</ParametersProvider>
		</Box>
	);
};

export default ChartParameters;
