import { Box, IconButton } from '@material-ui/core';
import { ChevronLeftIcon, ChevronRightIcon } from 'assets/icons/commonMUIIcons';
import clsx from 'clsx';
import { useTranslations } from 'components/utilities';
import React from 'react';

import { AttemptsNumbers, AttemptsNumbersProps } from '../AttemptsNumbers';
import { Label } from '../Typography';
import { useStyles } from './styles';

type Props = AttemptsNumbersProps & {
	attemptComponent?: AttemptsNumbersProps['component'];
	onClickLeft: () => void;
	onClickRight: () => void;
	onMouseEnterRight: () => void;
	onMouseLeaveRight: () => void;
	onMouseEnterLeft: () => void;
	onMouseLeaveLeft: () => void;
	leftArrowDisabled: boolean;
	rightArrowDisabled: boolean;
	attemptDateTime: string;
	activeMeasurementIndex: number;
	classNameRoot?: string;
};

export const AttemptsArrowSelector = ({
	leftArrowDisabled,
	rightArrowDisabled,
	onClickLeft,
	onClickRight,
	onMouseEnterRight,
	onMouseLeaveRight,
	onMouseEnterLeft,
	onMouseLeaveLeft,
	attemptDateTime,
	activeMeasurementIndex,
	attemptsArray,
	isBestIndex,
	attemptComponent,
	classNameRoot
}: Props) => {
	const { t } = useTranslations();

	const classes = useStyles({ leftArrowDisabled, rightArrowDisabled });

	return (
		<Box className={clsx([classes.root, classNameRoot])}>
			<IconButton
				size="small"
				className={classes.leftArrow}
				onClick={onClickLeft}
				onMouseEnter={onMouseEnterLeft}
				onMouseLeave={onMouseLeaveLeft}
				disabled={leftArrowDisabled}
			>
				<ChevronLeftIcon color="primary" />
			</IconButton>
			<AttemptsNumbers
				activeMeasurementIndex={activeMeasurementIndex}
				attemptsArray={attemptsArray}
				isBestIndex={isBestIndex}
				component={attemptComponent}
			/>
			<Label
				className={classes.label}
				label={t(
					'patients.examinations_v2.details.spirometry.all_measurements.measurement_label'
				)}
			/>
			<Label label={attemptDateTime} />
			<IconButton
				size="small"
				className={classes.rightArrow}
				onClick={onClickRight}
				onMouseEnter={onMouseEnterRight}
				onMouseLeave={onMouseLeaveRight}
				disabled={rightArrowDisabled}
			>
				<ChevronRightIcon color="primary" />
			</IconButton>
		</Box>
	);
};
