import { ActiveExaminationBase } from 'models/Examinations/common';
import { ExaminationsParametersType } from 'types/Examinations/parameters';

import {
	ActiveExaminationKeyData,
	createActiveExaminationKey,
	createExaminationsDateRangeKey,
	createSelectedTrendChartParametersKey
} from './keys';
import {
	ActiveExaminationSessionStorage,
	ExaminationsDateRangeSessionStorage,
	SelectedTrendChartParametersSessionStorage
} from './types';

const activeExaminationSessionStorage: ActiveExaminationSessionStorage = {
	setLastActiveExamination: (
		{ patientId }: ActiveExaminationKeyData,
		data: ActiveExaminationBase
	) => {
		sessionStorage.setItem(
			createActiveExaminationKey({ patientId }),
			JSON.stringify(data)
		);
	},
	getLastActiveExamination: ({ patientId }: ActiveExaminationKeyData) => {
		const sessionData = sessionStorage.getItem(
			createActiveExaminationKey({ patientId })
		);
		if (sessionData) {
			return JSON.parse(sessionData) as ActiveExaminationBase;
		}
		return null;
	},
	createActiveExaminationKey
};

const examinationsDateRangeSessionStorage: ExaminationsDateRangeSessionStorage =
	{
		setExaminationsDateRange(keyData, data) {
			sessionStorage.setItem(
				createExaminationsDateRangeKey({
					patientId: keyData.patientId
				}),
				JSON.stringify(data)
			);
		},
		getExaminationsDateRange(keyData) {
			const sessionData = sessionStorage.getItem(
				createExaminationsDateRangeKey({ patientId: keyData.patientId })
			);
			if (sessionData) {
				const dateRange = JSON.parse(sessionData) as TDateRange;
				return {
					startDate: new Date(dateRange.startDate),
					endDate: new Date(dateRange.endDate)
				};
			}
			return null;
		},
		createExaminationsDateRangeKey
	};

const selectedTrendChartParameters: SelectedTrendChartParametersSessionStorage =
	{
		setSelectedTrendChartParameters(keyData, data) {
			sessionStorage.setItem(
				createSelectedTrendChartParametersKey({
					patientId: keyData.patientId
				}),
				JSON.stringify(data)
			);
		},
		getSelectedTrendChartParameters(keyData) {
			const sessionData = sessionStorage.getItem(
				createSelectedTrendChartParametersKey({
					patientId: keyData.patientId
				})
			);
			if (sessionData) {
				const selectedTrendChartParameters = JSON.parse(
					sessionData
				) as ExaminationsParametersType[];

				return selectedTrendChartParameters;
			}
			return null;
		},
		createSelectedTrendChartParametersKey
	};

type ExaminationsSessionStorage = ActiveExaminationSessionStorage &
	ExaminationsDateRangeSessionStorage &
	SelectedTrendChartParametersSessionStorage;

export const examinations: ExaminationsSessionStorage = {
	...activeExaminationSessionStorage,
	...examinationsDateRangeSessionStorage,
	...selectedTrendChartParameters
};
