import { QUERY_PATIENT_BASIC_DATA } from 'helpers';
import { PatientModel } from 'models/Patient/PatientModel';
import { useQueryClient } from 'react-query';
import { useParams } from 'utilities';

type Props = {
	patientId?: PatientIdParam;
};
export const useGetCachedPatientData = ({ patientId }: Props = {}) => {
	const { patientId: paramsPatientId } = useParams();

	const query = useQueryClient();

	const id = paramsPatientId || (patientId as PatientIdParam);
	const patientData = query.getQueryData<PatientModel['patient']>([
		QUERY_PATIENT_BASIC_DATA,
		id
	]);

	return patientData;
};
