import {
	useExaminationsV2Context,
	useExaminationsV2RefsContext
} from 'components/context/ExaminationsV2';
import { HighCharts } from 'components/context/ExaminationsV2/classes';
import _ from 'lodash';
import { ExaminationsListConvertedItemModel } from 'models/Examinations/ExaminationsList';
import { useExaminationsListQueryKey } from 'queries';
import React from 'react';
import { useQueryClient } from 'react-query';
import { LocaleDate } from 'utilities';

const useXAxisEvents = () => {
	const queryClient = useQueryClient();
	const { setDateRange, setExaminationsListDataWithFilters } =
		useExaminationsV2Context();

	const { store: trendChartRef } = useExaminationsV2RefsContext(
		(store) => store.trendChartRef
	);
	const { resetExaminationsVirtualList } = useExaminationsV2RefsContext();
	const examinationsListQueryKey = useExaminationsListQueryKey();

	const delayedSetExtremesCallbacks = React.useRef(
		_.debounce((newDateRange: TDateRange) => {
			const examinationsListQueryData = queryClient.getQueryData<
				ExaminationsListConvertedItemModel[]
			>(examinationsListQueryKey);

			setExaminationsListDataWithFilters({
				examinationsDateRange: newDateRange,
				examinationsListData: examinationsListQueryData
			});
			setDateRange(newDateRange);
			resetExaminationsVirtualList();
		}, 100)
	).current;

	function handleSetExtremes(e) {
		const highChart = new HighCharts(trendChartRef);
		const startDate = new LocaleDate(e.min).getDate();
		const endDate = new LocaleDate(e.max).getDate();

		highChart.updateNavigatorColouredZone(e.min, e.max);
		delayedSetExtremesCallbacks({ startDate, endDate });
	}

	return {
		setExtremes: handleSetExtremes
	};
};

export default useXAxisEvents;
