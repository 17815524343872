import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		paddingLeft: 70,
		display: 'flex',
		'& > :first-child': {
			flex: 'unset',
			'& p': {
				right: 'unset',
				left: 0
			}
		},
		'& > :last-child': {
			'& p': {
				right: 0
			}
		},
		height: 12
	},
	labelWrapper: {
		flex: 1,
		position: 'relative'
	},
	label: {
		fontSize: '12px',
		lineHeight: '12px',
		fontWeight: 400,
		color: theme.palette.gray.medium,
		position: 'absolute',
		top: 0,
		right: -4
	}
}));
