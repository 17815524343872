import { DialogActions } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslations } from 'components/utilities';
import React from 'react';

import { Button, ButtonProgress } from '../../../../../../atoms';
import { IButton } from '../../../../../../atoms/Buttons/Button';
import { useStyles } from './styles';

type ButtonProps<T> = {
	onClick?: ((props: T) => void) | null;
	label?: string;
	isLoading?: boolean;
	isHide?: boolean;
} & Omit<IButton, 'children'>;

export interface IActionButtons<T> {
	className?: string;

	disableActionButtons?: boolean;
	confirm?: { variant?: ButtonVariant } & ButtonProps<T>;
	cancel?: { variant?: ButtonVariant } & ButtonProps<T>;
}

const ActionButtons = <T extends object>({
	className = '',
	cancel = { isHide: false },
	confirm = { isHide: false }
}: IActionButtons<T>) => {
	const classes = useStyles();
	const { t } = useTranslations();

	return (
		<DialogActions
			className={clsx([className, classes.root])}
			data-testid="dialogActions-root"
		>
			{(confirm?.onClick || confirm?.type === 'submit') &&
				!confirm.isHide && (
					<ButtonProgress
						role="confirm-button"
						classNameWrapper={classes.button}
						onClick={confirm?.onClick}
						variant={confirm?.variant || 'contained'}
						size="large"
						color={confirm?.color || 'primary'}
						isLoading={confirm?.isLoading || false}
						type={confirm?.type || 'button'}
					>
						{t(confirm?.label || 'btn.ok')}
					</ButtonProgress>
				)}
			{cancel?.onClick && !cancel.isHide && (
				<Button
					role="cancel-button"
					className={classes.button}
					onClick={cancel?.onClick}
					variant={cancel?.variant || 'outlined'}
					size="large"
					color={cancel?.color || 'primary'}
					disabled={confirm?.isLoading || false}
				>
					{t(cancel?.label || 'btn.cancel')}
				</Button>
			)}
		</DialogActions>
	);
};

export default ActionButtons;
