import { calculateBmi, QUERY_PATIENT_DATA_AND_PERSONAL_BESTS } from 'helpers';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { PatientServices } from './services';
import useEdit from './useEdit';

/**
 * edit patient's additional informations
 * @param {object} options mutation options
 * @param {object} additionalData mutation additional data e.g services arguments
 * @returns edit patient account mutation
 */
const useEditAdditionalInformations = (options = {}, additionalData = {}) => {
	const cache = useQueryClient();
	const { patientId } = useParams();
	const patientQueryKey = [QUERY_PATIENT_DATA_AND_PERSONAL_BESTS, patientId];

	const handleSuccess = (d, data) => {
		const patientBmi = calculateBmi({
			weight: data.patientWeight,
			height: data.patientHeight,
			weightUnit: data.patientWeightUnit,
			heightUnit: data.patientHeightUnit
		});

		cache.setQueryData(patientQueryKey, ([best]) => [
			best,
			{ patient: { ...data, patientBmi } }
		]);

		options?.onSuccess?.(d);
	};

	return useEdit(
		{ ...options, onSuccess: handleSuccess },
		{ ...additionalData, isSuccessConversionEnabled: false },
		PatientServices.editAdditionalInformationsPayloadConversion
	);
};

export default useEditAdditionalInformations;
