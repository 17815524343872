import { Typography } from '@material-ui/core';
import { Alert } from 'components/elements/molecules';
import { IAlertProps } from 'components/elements/molecules/Alerts/Alert/Alert';
import { useTranslations } from 'components/utilities';

import { useStyles } from './styles';

export type IRequestConfirmationContentProps = {
	errorMessage: IAlertProps['message'];
	textAlign?:
		| undefined
		| 'start'
		| 'end'
		| 'left'
		| 'right'
		| 'center'
		| 'justify'
		| 'initial'
		| 'match-parent';
	label?: TranslationKey;
	children?: CmpChildren;
};

export const RequestConfirmationContent = ({
	errorMessage,
	label,
	children,
	textAlign = 'center'
}: IRequestConfirmationContentProps) => {
	const { t } = useTranslations();
	const classes = useStyles({ textAlign });
	return (
		<>
			<Alert message={errorMessage} className={classes.alert} />
			{label && (
				<Typography
					className={classes.contentLabel}
					data-testid="confirmation-label"
				>
					{t(label)}
				</Typography>
			)}
			{children}
		</>
	);
};
