import { string } from 'prop-types';
import React from 'react';

const VALIDATE_INFO = {
	lowercase: {
		isValid: false,
		text: 'sign_up.doctor.password.validation_lowercase'
	},
	uppercase: {
		isValid: false,
		text: 'sign_up.doctor.password.validation_uppercase'
	},
	numeric: {
		isValid: false,
		text: 'sign_up.doctor.password.validation_numeric'
	},
	specialCharacter: {
		isValid: false,
		text: 'sign_up.doctor.password.validation_special_character'
	},
	charactersLength: {
		isValid: false,
		text: 'sign_up.doctor.password.validation_length'
	}
};

const usePasswordChecker = ({ password }) => {
	const [validateInfo, setValidateInfo] = React.useState(VALIDATE_INFO);
	const isPasswordCheckerNotEmpty = password?.length > 0 ?? false;

	const handleSetValidation = () => {
		const {
			lowercase,
			uppercase,
			numeric,
			charactersLength,
			specialCharacter
		} = {
			...validateInfo
		};

		if (isPasswordCheckerNotEmpty) {
			lowercase.isValid = /[a-z]/g.test(password);
			uppercase.isValid = /[A-Z]/g.test(password);
			numeric.isValid = /\d+/g.test(password);
			specialCharacter.isValid =
				/[!@#$%^&*(),.?/'":;[\]{}|<>+=\-_]/g.test(password);
			charactersLength.isValid = password?.length > 7 ?? false;

			setValidateInfo({
				lowercase,
				uppercase,
				numeric,
				specialCharacter,
				charactersLength
			});
		} else {
			setValidateInfo(VALIDATE_INFO);
		}
	};

	React.useEffect(() => {
		handleSetValidation();
		// eslint-disable-next-line
	}, [password]);

	const validateInfoArray = Object.values(validateInfo);
	const isPasswordValid = validateInfoArray.every(
		({ isValid }) => isValid === true
	);

	return {
		validateInfo: validateInfoArray,
		isPasswordValid,
		isPasswordCheckerNotEmpty
	};
};

usePasswordChecker.propTypes = {
	password: string.isRequired
};

export default usePasswordChecker;
