import { Typography } from '@material-ui/core';
import {
	Alert,
	Button,
	ModalDialog,
	ModalDialogActionButtonsV2
} from 'components/elements';
import { DASH_PATHS } from 'components/routes/paths';
import { useToggle } from 'components/utilities';
import { useTranslations } from 'components/utilities';
import { useDeletePatient } from 'queries';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'utilities';

import { useStyles } from './styles';

interface IPatientDeletionProps {
	patientName: string;
}

export const PatientDeletion = ({ patientName }: IPatientDeletionProps) => {
	const { patientId } = useParams();
	const { toggle, on } = useToggle();
	const { t } = useTranslations();
	const classes = useStyles();
	const history = useHistory();

	const { mutate, reset, isSuccess, isLoading, apiError, isError } =
		useDeletePatient();

	const handleSuccess = React.useCallback(() => {
		history.push(DASH_PATHS.PATIENTS);
		toggle();
	}, []);

	const handleDeletePatient = () => {
		mutate({ patientId });
	};

	return (
		<>
			<Button onClick={toggle} color="error">
				{t('patients.basic_data.delete_patient.button')}
			</Button>
			<ModalDialog
				disableBackdropClick
				maxWidth="sm"
				actionButtonsProps={{
					disableActionButtons: true
				}}
				onExited={reset}
				titleProps={{
					title: 'patients.basic_data.delete_patient.title',
					close: toggle,
					disableAction: isLoading
				}}
				isSuccess={isSuccess}
				successProps={{
					textContent: {
						key: 'patients.basic_data.patient_has_been_deleted',
						replaceKeys: {
							['%x1']: `<strong>${patientName}</strong>`,
							['%X1']: `<strong>${patientName}</strong>`
						}
					},
					close: handleSuccess
				}}
				open={on}
				close={toggle}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
				{() => (
					<>
						<Typography className={classes.contentLabel}>
							{t({
								key: 'patients.basic_data.delete_patient.confirmation',
								replaceKeys: {
									['%1']: `<strong>${patientName}</strong>`
								}
							})}
						</Typography>
						{isError && <Alert message={apiError} />}
						<ModalDialogActionButtonsV2
							confirm={{
								onClick: handleDeletePatient,
								label: 'btn.delete',
								color: 'error',
								isLoading
							}}
							cancel={{
								onClick: toggle,
								variant: 'outlined'
							}}
						/>
					</>
				)}
			</ModalDialog>
		</>
	);
};
