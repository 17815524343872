import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	card: {
		overflow: 'unset'
	},
	content: {
		padding: '22px 32px 17px 21px'
	}
}));
