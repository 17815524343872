import { Box } from '@material-ui/core';
import {
	Alert,
	LoaderSpinner,
	SwitchComponentWithTransition
} from 'components/elements';
import { PatientsDetailsRoutes } from 'components/routes';
import React from 'react';

import { useStyles } from './styles';
import TopBar from './TopBar';
import usePatientsDetails from './usePatientsDetails';

const PatientsDetails = () => {
	const classes = useStyles();

	const { isSuccess, isError, isLoading, apiError, ...rest } =
		usePatientsDetails();

	const renderContent = () => {
		if (isSuccess)
			return () => (
				<Box className={classes.root}>
					<TopBar {...rest} />
					<Box className={classes.routesContainer}>
						<PatientsDetailsRoutes />
					</Box>
				</Box>
			);
		if (isError) return () => <Alert message={apiError} />;
	};

	return (
		<SwitchComponentWithTransition
			state={isLoading}
			initialComponent={LoaderSpinner}
			newComponent={renderContent()}
		/>
	);
};

export default PatientsDetails;
