import { Row } from '@tanstack/react-table';

export const defaultFilter = <TData>(
	row: Row<TData>,
	columnId: string,
	filterValue: string
) => {
	const rowValue = row.getValue(columnId)?.toString()?.toLowerCase();
	const v = filterValue?.toString()?.toLowerCase();
	return rowValue ? rowValue.includes(v) : false;
};
