import { Box, CardHeader, IconButton } from '@material-ui/core';
import { CloseIcon, KeyboardBackspaceIcon } from 'assets/icons/commonMUIIcons';
import { useTranslations } from 'components/utilities';
import { func,string } from 'prop-types';
import React from 'react';

import { useStyles } from './styles';

const Top = ({ handleCancel, title }) => {
	const classes = useStyles();
	const { t } = useTranslations();

	return (
		<CardHeader
			className={classes.header}
			action={
				<IconButton
					className={classes.iconButton}
					onClick={handleCancel}
					aria-label="cancel"
				>
					<CloseIcon className={classes.iconClose} />
				</IconButton>
			}
			title={
				<Box className={classes.title}>
					<Box className={classes.iconBackspaceCell}>
						<IconButton
							className={classes.iconButton}
							onClick={handleCancel}
							aria-label="cancel"
						>
							<KeyboardBackspaceIcon
								className={classes.iconBackspace}
							/>
						</IconButton>
					</Box>
					<Box className={classes.titleCell}>{t(title)}</Box>
				</Box>
			}
		/>
	);
};

Top.propTypes = {
	handleCancel: func.isRequired,
	title: string.isRequired
};

export default Top;
