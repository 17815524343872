import { VirtualTable } from 'components/elements/organisms/Tables/VirtualTable';
import { useTranslations } from 'components/utilities';
import { PatientListItemModel } from 'models/Patient/PatientListItem';
import React from 'react';
import { QueryStatus } from 'react-query';

import { columns, COLUMNS_ACCESSORS } from './columns';
import { globalFilterFn } from './helpers';
import { useStyles } from './styles';
import { ToolbarActions } from './ToolbarActions';

export interface IPatientsTableTemplateProps {
	toggleCreationPatientForm: () => void;
	data: PatientListItemModel[];
	apiError: ApiErrorState;
	status: QueryStatus;
	isFetching: boolean;
	refreshData: () => void;
}
export const PatientsTableTemplate = ({
	toggleCreationPatientForm,
	data,
	apiError,
	status,
	isFetching,
	refreshData
}: IPatientsTableTemplateProps) => {
	const classes = useStyles();
	const { tNoParser } = useTranslations();

	return (
		<VirtualTable<PatientListItemModel>
			idle={status}
			isFetching={isFetching}
			refreshData={refreshData}
			apiError={apiError}
			data={data}
			columns={columns}
			classes={{
				tableBody: classes.stickyColumn,
				tableHead: classes.stickyColumn
			}}
			toolbarActions={() => (
				<ToolbarActions
					openPatientFormModal={toggleCreationPatientForm}
				/>
			)}
			middleware={{ globalFilterFn: globalFilterFn(tNoParser) }}
			defaultSortBy={{
				id: COLUMNS_ACCESSORS.LAST_EXAMINATION,
				desc: false
			}}
		/>
	);
};
