import { addYears } from 'date-fns';
import { COOKIES } from 'helpers';
import Cookie from 'js-cookie';

import { COOKIE_CONFIG } from './helpers';

function accept() {
	const config = { ...COOKIE_CONFIG, expires: addYears(new Date(), 1) };
	Cookie.set(COOKIES.COOKIE_RELEASE_NOTE.ACCEPTED, true, config);
}

function isAccepted(): () => undefined | 'string' {
	return Cookie.get(COOKIES.COOKIE_RELEASE_NOTE.ACCEPTED);
}

export const releaseNote = { accept, isAccepted };
