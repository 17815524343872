import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { ParameterLabel } from 'components/elements/atoms/ExaminationsV2';
import React from 'react';

import { useStyles } from './styles';

export type LegendItemProps = {
	icon: React.FC<React.SVGProps<SVGSVGElement>>;
	label: string;
	className?: string;
};
export const LegendItem = ({
	icon: Icon,
	label,
	className = ''
}: LegendItemProps) => {
	const classes = useStyles();
	return (
		<Box className={classes.legendItem}>
			<Icon />
			<ParameterLabel
				className={clsx([className, classes.label])}
				label={label}
			/>
		</Box>
	);
};
