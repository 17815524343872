import { replaceAll } from 'helpers';
import ReactHtmlParser from 'react-html-parser';
import { useSelector } from 'react-redux';

import { useTranslationsReturnObj } from './types';

const useTranslations = (): useTranslationsReturnObj => {
	const translations = useSelector<
		{
			language: { translations };
		},
		Record<string, string>
	>(({ language }) => language.translations);

	const basicTranslation = (key: TranslationKey): string => {
		if (!key) {
			return '';
		}

		if (typeof key === 'string') {
			return translations[key];
		}

		if (translations?.[key.key]) {
			return Object.values(key?.replaceKeys || {})?.length > 0
				? replaceAll(translations[key.key], key.replaceKeys)
				: translations[key.key];
		}
		return '';
	};

	return {
		t: (key) => {
			if (!key) {
				return null;
			}
			const translation = basicTranslation(key);
			return translation ? ReactHtmlParser(translation) : null;
		},

		tNoParser: (key) => {
			if (!key) {
				return null;
			}
			const translation = basicTranslation(key);
			return translation ?? null;
		}
	};
};

export default useTranslations;
