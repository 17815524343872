import { useControlledTableContextProvider } from 'components/context';
import { useMutation } from 'components/utilities';

const useArchiveSetting = ({ promise, close, id, setRefetch }) => {
	const { mutateAsync, options, inputColumns, filters } =
		useControlledTableContextProvider();

	const { mutate: archiveMutation } = useMutation(() => promise(id), {
		onSuccess: handleSuccess
	});

	function handleSuccess() {
		mutateAsync({
			options,
			inputColumns,
			filters
		});
		setRefetch();
	}

	const handleArchive = () => {
		archiveMutation();
		close();
	};
	return { archiveMutation: handleArchive };
};

export default useArchiveSetting;
