import { useRedirect } from 'components/utilities';
import { func, object, oneOfType } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const ProtectedUserLogged = ({ component: Component }) => {
	const { redirect } = useRedirect();
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

	return (
		<Route
			render={(props) =>
				isAuthenticated ? (
					<Redirect to={redirect} />
				) : (
					<Component {...props} />
				)
			}
		/>
	);
};

ProtectedUserLogged.propTypes = {
	component: oneOfType([object, func]).isRequired
};

export default ProtectedUserLogged;
