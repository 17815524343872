import { TextInputBase } from 'components/elements';
import { useTranslations } from 'components/utilities';
import React from 'react';

export interface IEmailTextInputProps {
	value: string;
	onChange: (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => void;
	isValidEmail: boolean;
	isDirty: boolean;
	setEmailInputAsDirty: () => void;
}

export const EmailTextInput = ({
	value,
	onChange,
	isValidEmail,
	setEmailInputAsDirty,
	isDirty
}: IEmailTextInputProps) => {
	const { t } = useTranslations();
	return (
		<TextInputBase
			label="global.email"
			value={value}
			onChange={onChange}
			onBlur={() => {
				setEmailInputAsDirty();
			}}
			error={isDirty && !isValidEmail}
			helperText={
				isDirty
					? isValidEmail
						? ''
						: t('error_codes.invalid_email')
					: ''
			}
			required
		/>
	);
};
