import {
	convertDecimalNumber,
	ETHNICITY_LABELS,
	FIELD_NAMES,
	getSmokerLabel
} from 'helpers';
import ReactHtmlParser from 'react-html-parser';

const {
	PATIENT: { PATIENT_SMOKER_FIELD_NAMES, ADDITIONAL_FIELD_NAMES }
} = FIELD_NAMES;
const { SMOKER } = PATIENT_SMOKER_FIELD_NAMES;
const { BMI, RACE, WEIGHT, HEIGHT } = ADDITIONAL_FIELD_NAMES;

export const ADDITIONAL_INFO_SMOKER_FIELDS = [
	{
		label: 'patients.basic_data.field.smoker',
		type: 'text',
		name: SMOKER
	}
];

export const additionalInformationsFields = (patientData) => [
	{
		label: 'patients.basic_data.field.race',
		name: RACE,
		valueConverter: (race) => ETHNICITY_LABELS[race]
	},
	{
		label: 'patients.basic_data.field.weight',
		name: WEIGHT,
		valueConverter: (weight) =>
			`${convertDecimalNumber(weight, 0)} ${
				patientData.patientWeightUnit
			}`
	},
	{
		label: 'patients.basic_data.field.height',
		name: HEIGHT,
		valueConverter: (height) =>
			`${convertDecimalNumber(height, 2)} ${
				patientData.patientHeightUnit
			}`
	},
	{
		label: 'patients.basic_data.field.bmi',
		name: BMI,
		valueConverter: (value) =>
			ReactHtmlParser(
				`${convertDecimalNumber(value, 2)} kg/m<sup>2</sup>`
			)
	},
	{
		label: 'patients.basic_data.field.smoker',
		name: SMOKER,
		valueConverter: () => getSmokerLabel(patientData)
	}
];
