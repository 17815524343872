import { makeStyles } from '@material-ui/styles';

type Props = {
	timeout?: number;
};
export const useOpacityTransitionStyles = makeStyles<unknown, Props>(() => ({
	enter: { opacity: 0 },
	enterActive: {
		opacity: 1,
		transition: ({ timeout = 250 }) => `opacity ${timeout}ms ease-in-out`
	},
	exit: { opacity: 1 },
	exitActive: {
		opacity: 0,
		transition: ({ timeout }) => `opacity ${timeout}ms ease-in-out`
	}
}));
