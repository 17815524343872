import { EXAMINATIONS_TYPES } from 'helpers/variables';
import {
	CopdPreviewModel,
	PeakFlowPreviewModel,
	PulseOximetryPreviewModel,
	SpirometryPreviewModel,
	SvcPreviewModel
} from 'models/Examinations/allExaminationsTypes';
import { ExaminationsListConvertedItemModel } from 'models/Examinations/ExaminationsList';

import { getCopdPreview } from './copd';
import { getPeakFlowPreview } from './peakFlow';
import { getPulseOximetryPreview } from './pulseOximetry';
import { getSpirometryPreview } from './spirometry';
import { getSvcPreview } from './svc';

const { SPIROMETRY, COPD_TEST, PULSE_OXIMETRY, PEAK_FLOW, SVC } =
	EXAMINATIONS_TYPES;

export class ExaminationListItemFactory {
	private data: ExaminationsListConvertedItemModel;
	private personalBest;
	constructor(data: ExaminationsListConvertedItemModel, personalBest) {
		this.data = data;
		this.personalBest = personalBest;
	}

	createItem = (): ExaminationPreview => {
		switch (this.data.type) {
			case SPIROMETRY.type:
				return getSpirometryPreview(
					this.data as SpirometryPreviewModel,
					this.personalBest
				);
			case PULSE_OXIMETRY.type:
				return getPulseOximetryPreview(
					this.data as PulseOximetryPreviewModel
				);
			case PEAK_FLOW.type:
				return getPeakFlowPreview(
					this.data as PeakFlowPreviewModel,
					this.personalBest
				);
			case SVC.type:
				return getSvcPreview(this.data as SvcPreviewModel);
			case COPD_TEST.type:
				return getCopdPreview(this.data as CopdPreviewModel);
			// case MEDICAL_NOTE.type:
			// 	return getMedicalNotePreview(
			// 		this.data as MedicalNotePreviewModel
			// 	);
			default:
				return {} as ExaminationPreview;
		}
	};
}
