import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { arrayOf, func, number, string } from 'prop-types';
import React from 'react';

import { ZScorePoint } from '../ZScorePoint';
import { useStyles } from './styles';

const PictogramRow = ({ title, value, scaleLines, getValuePosition }) => {
	const classes = useStyles({ getValuePosition });

	return (
		<Box className={clsx([classes.root, 'z-score--row'])}>
			<Typography
				type="body1"
				className={clsx([classes.title, 'z-score--param'])}
			>
				{title}
			</Typography>
			<Box className={clsx([classes.pictogram, 'z-score--row'])}>
				<ZScorePoint
					value={value}
					getValuePosition={getValuePosition}
				/>
				{scaleLines.map((val) => (
					<Box key={val} className={classes.scaleLine} />
				))}
			</Box>
		</Box>
	);
};

PictogramRow.propTypes = {
	title: string.isRequired,
	value: number.isRequired,
	scaleLines: arrayOf(number).isRequired,
	getValuePosition: func.isRequired
};

export default PictogramRow;
