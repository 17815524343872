import { makeStyles } from '@material-ui/styles';
import theme from 'theme';

import { Props } from './SwitchComponentWithTransition';

type StylesProps = { timeout: Props['timeout'] };
export const useStyles = makeStyles<typeof theme, StylesProps>(() => ({
	root: { height: '100%' },
	enter: {
		opacity: 0
	},
	exit: {
		opacity: 1
	},
	enterActive: {
		opacity: 1,
		transition: ({ timeout }) => `opacity ${timeout?.enter}ms ease-in-out`
	},
	exitActive: {
		opacity: 0,
		transition: ({ timeout }) => `opacity ${timeout?.exit}ms ease-in-out`
	}
}));
