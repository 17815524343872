import { CHART_EXAMINATION_PARAMETERS_COLORS as CHART_COLORS } from '../parameters';

export function generateAxisGroup(axisNames) {
	const names = getAxisNames(axisNames);
	const colors = getAxisGroupColor(names);
	return {
		group: {
			names,
			colors
		}
	};
}

function getAxisNames(axisNames) {
	return axisNames.map((axisName) => axisName.toUpperCase());
}

function getAxisGroupColor(axisNames) {
	return axisNames.reduce((acc, next) => {
		acc[next] = CHART_COLORS[next];
		return acc;
	}, {});
}
