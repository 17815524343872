import { convertValueUnit } from 'helpers/person/units';
import { HEIGHT_RANGE, UNITS_TYPES, WEIGHT_RANGE } from 'helpers/variables';
import { UserSettingName } from 'models/Settings/Settings';
import { UserUnits } from 'store/actions';
import Store from 'store/store';
import { ExaminationsParametersType } from 'types/Examinations/parameters';

import { CHART_MEASUREMENT_PARAMETER_OPTION_NAMES } from './parameters';

const [kg, lbs] = Object.keys(WEIGHT_RANGE);
const [cm] = Object.keys(HEIGHT_RANGE);

type DefinedParametersUnits = 'pef' | 'pif' | 'weight' | 'height';

export type Unit = {
	settingName: UserSettingName;
	label: string;
	labelConverter?: (value: string) => string | null;
	integerUnits?: string[];
};

export const USER_SETTINGS_UNITS: {
	[k in DefinedParametersUnits]: Unit;
} = {
	weight: {
		settingName: 'weight_unit',
		label: 'Weight',
		integerUnits: [kg, lbs]
	},
	height: {
		settingName: 'height_unit',
		label: 'Height',
		labelConverter: heightLabelConverter,
		integerUnits: [cm]
	},
	pef: {
		settingName: 'pef_unit',
		label: 'PEF',
		integerUnits: [UNITS_TYPES.L_M.unit]
	},
	pif: {
		settingName: 'pif_unit',
		label: 'PIF',
		integerUnits: [UNITS_TYPES.L_M.unit]
	}
};

export const DEFINED_USER_SETTINGS_UNITS_PARAMETERS =
	Object.keys(USER_SETTINGS_UNITS);

export const getUserUnit = (parameter: ExaminationsParametersType): string => {
	return Store.getState().settings.units?.[parameter]?.unit ?? '';
};

export const validateValueUnitDecimals = (
	parameters: ExaminationsParametersType[]
) => {
	const units: UserUnits = Store.getState().settings.units;
	return parameters.reduce((acc, param) => {
		const unit = units?.[param];
		if (!unit) {
			return acc;
		}
		if (unit.isInteger) {
			acc.push(param?.toLowerCase() as ExaminationsParametersType);
		}
		return acc;
	}, [] as ExaminationsParametersType[]);
};

export const mapParameterNameToSupportedUserUnitsSettings = (
	parameter: string
): ExaminationsParametersType => {
	switch (parameter) {
		case 'pef1':
		case 'pef2':
		case 'pef3':
		case 'result':
			return 'pef' as const;
		default:
			return parameter as ExaminationsParametersType;
	}
};

export const recalculateParameterValueUnit = (
	parameter: string,
	value: number | null,
	currentUnit?: string
): number | null => {
	if (value === null) {
		return null;
	}
	const parameterKey =
		mapParameterNameToSupportedUserUnitsSettings(parameter);
	if (!DEFINED_USER_SETTINGS_UNITS_PARAMETERS.includes(parameterKey)) {
		return value;
	}

	const parameterUnit = getUserUnit(parameterKey);
	const defaultParameterUnit =
		currentUnit ??
		CHART_MEASUREMENT_PARAMETER_OPTION_NAMES[parameterKey].defaultUnit;
	return convertValueUnit(value, defaultParameterUnit, parameterUnit);
};

function heightLabelConverter(value: string): string | null {
	return value?.toLowerCase() ?? null;
}
