import {
	useAppointmentFormContext,
	useScheduleCalendarContext
} from 'components/context';
import { addMinutes, format, isDate } from 'date-fns';
import { EN, TIME_FORMAT } from 'helpers/variables';
import React from 'react';
import { useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';

const { DEFAULT, AMPM } = TIME_FORMAT;

const useDurationField = () => {
	const { values, errors } = useFormState();
	const { lng } = useSelector((store) => store.language);
	const { duration } = useAppointmentFormContext();
	const { isAppointmentReview } = useScheduleCalendarContext();

	const isEnglish = lng === EN;
	const [endTimeHelperText, setEndTimeHelperText] = React.useState(
		getEndTime()
	);

	React.useEffect(() => {
		(isDate(values.start_time) || !errors.start_time) && handleGetEndTime();
		//eslint-disable-next-line
	}, [values.start_time, values.id]);

	function getEndTime(val) {
		if (errors.start_time) {
			return;
		}
		const { start_time, duration: initialDuration } = values;

		// get time format based on language
		const timeFormat = isEnglish ? AMPM : DEFAULT;

		// get time duration value if some exists, if not - calculate it using getInitialDuration func
		const duration = val || initialDuration;

		// return end time by adding duration to start_time
		return format(addMinutes(start_time, duration), timeFormat);
	}

	const handleGetEndTime = (e) => {
		const value = e?.target?.value;
		setEndTimeHelperText(getEndTime(value));
	};

	return {
		endTimeHelperText,
		duration,
		isAppointmentReview,
		getEndTime: handleGetEndTime
	};
};

export default useDurationField;
