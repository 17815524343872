import { useTranslations } from 'components/utilities';
import { bool, func, object, string } from 'prop-types';
import React from 'react';
import { useFormState } from 'react-final-form';

import { AutocompleteControlledField, PhoneOption } from '../../../../Inputs';
import CountryAutocompleteOption from './CountryAutocompleteOption';

const AutocompleteField = ({
	dataset,
	type,
	component,
	label,
	submitting,
	required,
	name,
	locationInitialValues,
	isPrefix
}) => {
	const { t } = useTranslations();
	const {
		values: {
			patientPhonePrefix,
			patientCountryCode,
			phone_prefix: initPhonePrefix,
			country
		}
	} = useFormState();

	const renderOption = isPrefix ? PhoneOption : CountryAutocompleteOption;
	const renderLabel = (option) =>
		isPrefix ? option?.phonePrefix : option?.name;

	const renderOptionSelected = (option, value) => {
		if (isPrefix) {
			return option.phonePrefix === (value?.phonePrefix || value);
		}
		return option.name === (value?.name || value);
	};

	const initialValue = () => {
		const { phone_prefix, name: initialCountryCode } =
			locationInitialValues;
		if (isPrefix) {
			const prefix =
				patientPhonePrefix || initPhonePrefix || phone_prefix;
			return prefix ? { phonePrefix: prefix } : null;
		}
		const countryCode = patientCountryCode || country || initialCountryCode;
		return countryCode ? { name: countryCode } : null;
	};

	return (
		<AutocompleteControlledField
			fullWidth
			initialValue={initialValue()}
			options={dataset?.data}
			getOptionSelected={renderOptionSelected}
			getOptionLabel={renderLabel}
			type={type}
			name={name}
			component={component}
			label={t(label)}
			variant="outlined"
			autoComplete="false"
			disabled={submitting || !dataset?.data}
			renderOption={renderOption}
			required={required}
		/>
	);
};

AutocompleteField.propTypes = {
	dataset: object.isRequired,
	type: string.isRequired,
	component: func.isRequired,
	label: string.isRequired,
	name: string.isRequired,
	submitting: bool.isRequired,
	required: bool.isRequired,
	isPrefix: bool.isRequired,
	locationInitialValues: object
};

export default AutocompleteField;
