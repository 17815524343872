import { createAxiosInterceptors } from './helpers';
import myAxios from './myAxiosFactory';

const servicesFactory = (myAxios) => ({
	post: (url, payload = {}, options = {}) => {
		let axios = myAxios(options);

		axios = createAxiosInterceptors(axios);
		return axios.post(url, payload);
	},
	put: (url, payload = {}, options = {}) => {
		let axios = myAxios(options);

		axios = createAxiosInterceptors(axios);
		return axios.put(url, payload);
	},
	patch: (url, payload = {}, options = {}) => {
		let axios = myAxios(options);

		axios = createAxiosInterceptors(axios);
		return axios.patch(url, payload);
	},
	delete: (url, payload = {}, options = {}) => {
		let axios = myAxios(options);

		axios = createAxiosInterceptors(axios);
		return axios.delete(url, payload);
	},
	get: (url, options = {}) => {
		let axios = myAxios(options);

		axios = createAxiosInterceptors(axios);
		return axios.get(url);
	}
});

const services = servicesFactory(myAxios);
export default services;
