import { concatDates } from '../time';
import { validateDateFormat,validateDateIsRequired } from '../validators/date';

// Calendar views variants
export const CALENDAR_VIEWS_VARIANTS = {
	MONTH: 'dayGridMonth',
	WEEK: 'timeGridWeek',
	DAY: 'timeGridDay'
};

export const getStartEndDate = ({ startDate, startTime, duration }) => {
	const payload = { start: null, end: null };
	if (!startDate || !startTime || !duration) {
		return payload;
	}
	payload.start = concatDates(startDate, startTime);
	payload.end = concatDates(
		startDate,
		new Date(startTime.getTime() + duration * 60000)
	);

	return payload;
};

/**
 * round up time of given dateTime to closest given minutes period e.g:
 * date = Wed Dec 29 2021 14:34:00 GMT+0100
 * minutesPeriod = 10
 * return Wed Dec 29 2021 14:40:00 GMT+0100
 *
 * @param {any} date date to convert
 * @param {number} minutesPeriod=0 a period in minutes for the nearest possible rounded up time
 * @returns {any} rounded up date time
 */
export const getRoundedUpDateTime = (date, minutesPeriod = 60) => {
	validateDateIsRequired(date);
	validateDateFormat(date);

	const roundedTime = 1000 * 60 * minutesPeriod;
	const roundedUpDateTime = new Date(
		Math.ceil(date.getTime() / roundedTime) * roundedTime
	);

	return roundedUpDateTime;
};
