import { useScheduleCalendarContext } from 'components/context';
import { DASH_PATHS } from 'components/routes/paths';
import { AppointmentsRepository, AppointmentsServices } from 'queries';
import React from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

const useScheduleForm = () => {
	const { appointmentId } = useParams();
	const { pathname } = useLocation();
	const history = useHistory();

	const { id: doctorId } = useSelector(
		({ auth }) => auth.authenticate.data.user
	);
	const {
		appointmentsDateRange,
		getCalendarApi,
		isAppointmentReview,
		unselectTimeGrid,
		setIsAppointmentReview
	} = useScheduleCalendarContext();

	const cache = useQueryClient();

	React.useEffect(() => {
		return () => {
			unselectTimeGrid();
		};
		//eslint-disable-next-line
	}, []);

	React.useEffect(() => {
		setIsAppointmentReview();
		// eslint-disable-next-line
	}, [pathname]);

	const handleSubmitScheduleForm = ({ isEdit, values }) => {
		const data = AppointmentsServices.convertAppointmentFormPayload({
			values,
			doctorId
		});
		if (isEdit) {
			return AppointmentsRepository.edit({
				appointmentId,
				payload: data
			});
		}
		return AppointmentsRepository.create(data);
	};

	const handleSuccess = (data, isEdit) => {
		const convertedDateRanges = AppointmentsServices.convertRangeDates(
			appointmentsDateRange
		);
		AppointmentsServices.updateAppointmentInList(
			isEdit,
			cache,
			data?.data,
			convertedDateRanges,
			getCalendarApi
		);
		history.push(DASH_PATHS.SCHEDULE);
		// cache.invalidateQueries(QUERY_APPOINTMENTS_LIST);
		unselectTimeGrid();
	};

	return {
		onSubmitForm: handleSubmitScheduleForm,
		onSuccess: handleSuccess,
		isAppointmentReview
	};
};

export default useScheduleForm;
