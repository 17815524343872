import { addMinutes, format } from 'date-fns';
import { stringToISO } from 'helpers';
import { TIME_FORMAT } from 'helpers/variables';

const { AMPM, DEFAULT } = TIME_FORMAT;

export const timeArray = (minutesStep, ampm) => {
	const midnight = new Date();
	midnight.setHours(0, 0, 0, 0);

	const arrayLength = Math.ceil((24 * 60) / minutesStep);
	const AMPM = ampm ? 'hh:mm a' : 'HH:mm';

	return new Array(arrayLength).fill('').map((_, index) => {
		return format(addMinutes(midnight, 15 * index), AMPM);
	});
};

export const convertISOtoStringTime = (ISOTime, ampm) => {
	const timeFormat = ampm ? AMPM : DEFAULT;
	return format(ISOTime, timeFormat);
};

export const findTimeIndex = (expectedValue, timeArray, ampm) => {
	let element = timeArray.find(
		(val) =>
			format(stringToISO(val, ampm), 'HH:mm') ===
				format(new Date(expectedValue), 'HH:mm') ||
			val === expectedValue
	);

	if (!element) {
		element = format(new Date(expectedValue), 'HH') + ':00';
	}

	return timeArray.indexOf(element);
};
