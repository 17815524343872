import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	buttonContainer: {
		marginTop: '2rem',
		position: 'relative',
		display: 'flex'
	},
	buttonProgress: {
		color: theme.palette.primary.main,
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12
	},
	btnWrapper: {
		flex: 1,
		margin: '0 10px'
	}
}));
