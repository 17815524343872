import Autocomplete from '@material-ui/lab/Autocomplete';
import { array, bool, func, object, oneOfType, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Field } from 'react-final-form';

import ListboxComponent from './ListboxComponent';
import { useStyles } from './styles';

const AutocompleteControlledField = ({
	options,
	getOptionLabel,
	renderOption,
	disabled,
	renderInput,
	defaultValue = null,
	initialValue = null,
	provideListbox = false,
	disableListWrap = false,
	disableClearable = false,
	getOptionSelected,
	onInputChange,
	onChange,
	onClose,
	...rest
}) => {
	const classes = useStyles();
	const [settings, setSettings] = useState({});
	const [value, setValue] = React.useState(initialValue);
	const [inputValue, setInputValue] = React.useState('');

	const input = (params) => (
		<Field {...params} {...rest} disabled={disabled} options={options} />
	);

	const handleSetSettings = (newSettings) =>
		setSettings((prev) => ({ ...prev, ...newSettings }));

	useEffect(() => {
		provideListbox && handleSetSettings({ ListboxComponent });
		onChange && handleSetSettings({ onChange });
		// eslint-disable-next-line
	}, [provideListbox, onChange]);

	const handleChange = (event, newValue) => {
		onInputChange && onInputChange(newValue);
		setValue(newValue);
	};

	const handleInputChange = (event, newInputValue) => {
		setInputValue(newInputValue);
	};

	const handleOptionSelected = (option, value) =>
		getOptionSelected(option, value);

	return (
		<Autocomplete
			fullWidth
			name={rest.name}
			options={options}
			disabled={disabled}
			getOptionLabel={getOptionLabel}
			renderOption={renderOption || getOptionLabel}
			renderInput={renderInput || input}
			disableListWrap={disableListWrap}
			defaultValue={defaultValue}
			value={value}
			onChange={handleChange}
			inputValue={inputValue}
			onInputChange={handleInputChange}
			getOptionSelected={handleOptionSelected}
			ListboxProps={{
				className: classes.root
			}}
			onClose={onClose}
			disableClearable={disableClearable}
			{...settings}
		/>
	);
};

AutocompleteControlledField.propTypes = {
	getOptionLabel: func.isRequired,
	renderOption: func,
	options: array.isRequired,
	onChange: func,
	onClose: func,
	onInputChange: func,
	defaultValue: oneOfType([object, string]),
	provideListbox: bool,
	disableListWrap: bool,
	disableClearable: bool,
	disabled: bool,
	renderInput: func,
	getOptionSelected: func.isRequired,
	initialValue: object
};

export default AutocompleteControlledField;
