import { Typography as MuiTypography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { string } from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
	root: { color: theme.palette.black, opacity: 0.32 }
}));

const PageHeading = ({ title, variant = 'h1', className = '' }) => {
	const classes = useStyles();
	return (
		<MuiTypography
			className={clsx(classes.root, className)}
			variant={variant}
		>
			{title}
		</MuiTypography>
	);
};

PageHeading.propTypes = {
	title: string.isRequired,
	variant: string,
	className: string
};

export default PageHeading;
