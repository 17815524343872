import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { arrayOf, node, oneOfType, string } from 'prop-types';
import React from 'react';

import { useStyles } from './styles';

const ScaleLinesWrapper = ({ className, children }) => {
	const classes = useStyles();

	return <Box className={clsx([classes.root, className])}>{children}</Box>;
};

ScaleLinesWrapper.propTypes = {
	className: string,
	children: oneOfType([arrayOf(node), node]).isRequired
};

export default ScaleLinesWrapper;
