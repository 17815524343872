import { getPulseOximetryExaminationParameterColors } from 'helpers/examinations/colors';

import {
	IParameterValuesData,
	ParameterValuesData
} from '../../ParameterValuesData';
import { ICommonParameterBuilder } from './CommonParametersDirector';

export class HrValueBuilder implements ICommonParameterBuilder {
	private commonParameterData: IParameterValuesData;

	constructor() {
		this.commonParameterData = new ParameterValuesData();
	}

	reset = () => {
		this.commonParameterData = new ParameterValuesData();
	};

	setValidator = () => {
		this.commonParameterData.setValidator((v) => v >= 0);
	};

	setValue = (v) => {
		this.commonParameterData.setValue(v?.toFixed(0) ?? null);
	};

	setColor = (c) => {
		const color = getPulseOximetryExaminationParameterColors({
			status: { hr: c },
			examinationValues: { hr: this.commonParameterData.value },
			EXAMINATION_VALUES_KEYS: { hr: { key: 'hr' } },
			valueAdditionalValidation: (v) => v >= 0
		});
		this.commonParameterData.setColor(color.hr);
	};

	setUnit = (u) => {
		this.commonParameterData.setUnit(u);
	};

	getValue = () => {
		return this.commonParameterData;
	};
}
