import { MenuItem } from '@material-ui/core';
import { useTranslations } from 'components/utilities';
import React from 'react';

import { RouterLink } from '../../../atoms';

export interface ILinkMenuItemProps {
	path: string;
	label: TranslationKey;
	closeMenu: () => void;
}

export const LinkMenuItem = ({
	path,
	label,
	closeMenu
}: ILinkMenuItemProps) => {
	const { t } = useTranslations();
	return (
		<MenuItem onClick={closeMenu} component={RouterLink} to={path}>
			{t(label)}
		</MenuItem>
	);
};
