import { CancelToken } from 'axios';
import { getConvertedApiFormErrors, IDLE } from 'helpers';
import React from 'react';
import { QueryKey, useQuery as useRqQuery, UseQueryOptions } from 'react-query';

import { useTranslations } from '../translations';
import { getApiErrorMessage } from './helpers';
import useRequestCancellation, {
	CANCEL_REQUEST_MESSAGE
} from './useRequestCancellation';

type QueryOptions<
	TQueryFnData,
	TError extends TMyError,
	TData,
	TQueryKey extends QueryKey = QueryKey
> = Omit<
	UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
	'queryKey' | 'queryFn'
>;

type TFetchResources<TQueryFnData> = ({
	cancelToken
}: {
	cancelToken: CancelToken;
}) => TQueryFnData | Promise<TQueryFnData>;

export type UseQueryOptionsProps<
	TQueryFnData = unknown,
	TError extends TMyError = TMyError,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey
> = {
	[O in keyof QueryOptions<
		TQueryFnData,
		TError,
		TData,
		TQueryKey
	>]: QueryOptions<TQueryFnData, TError, TData, TQueryKey>[O];
};

const useQuery = <
	TQueryFnData = unknown,
	TError extends TMyError = TMyError,
	TData = TQueryFnData,
	TQueryKey extends QueryKey = QueryKey
>(
	queryKey: TQueryKey,
	fetchResources: TFetchResources<TQueryFnData>,
	options?: UseQueryOptionsProps<TQueryFnData, TError, TData, TQueryKey>
) => {
	const [apiError, setApiError] = React.useState<ApiErrorState>(null);

	const { tNoParser } = useTranslations();

	const { token } = useRequestCancellation();

	const props = useRqQuery<TQueryFnData, TError, TData, TQueryKey>(
		queryKey,
		(props) =>
			fetchResources({
				cancelToken: token,
				...props
			}),
		{
			...options,
			onError: handleError
		}
	);

	function handleError(errors): void {
		const errorData = errors?.response?.data;
		let errorMessage;
		if (typeof errorData?.message === 'string') {
			errorMessage = getApiErrorMessage(errorData, tNoParser);
		} else if (typeof errors?.code === 'string') {
			errorMessage = getApiErrorMessage(errors, tNoParser);
			setApiError(errorMessage);
		} else {
			errorMessage = getConvertedApiFormErrors(errors);
		}
		setApiError(errorMessage);
		options?.onError && options.onError(errorMessage);
	}

	const { error } = props;

	const isCancelRequestMessage = error?.message === CANCEL_REQUEST_MESSAGE;
	props.error = error?.message
		? isCancelRequestMessage
			? null
			: error
		: error;

	props.status = isCancelRequestMessage ? IDLE.SUCCESS : props.status;

	props.isError = !!(props.isError && !isCancelRequestMessage);

	return { ...props, apiError };
};

export default useQuery;
