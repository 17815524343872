import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import { RouterNavLink } from '../../../../atoms';
import { useStyles } from './styles';

export interface INavLinkProps {
	/**
	 * Link label
	 */
	label: string;
	/**
	 * React router route path
	 */
	path: string;
	/**
	 * React router route path to be save in location history. Helps with routing animations.
	 */
	pathBase?: string;
	/**
	 * Typography CSS className
	 */
	className?: string;
}

export const NavLink = ({
	label,
	path,
	pathBase = '',
	className = ''
}: INavLinkProps) => {
	const classes = useStyles();
	return (
		<Typography
			variant="h5"
			className={clsx([classes.typography, className])}
		>
			{/*  plain JS component conflicts with ts file
                 eslint-disable-next-line
                 @ts-ignore */}
			<RouterNavLink
				to={path}
				pathBase={pathBase}
				className={classes.link}
			>
				<>{ReactHtmlParser(label)}</>
			</RouterNavLink>
		</Typography>
	);
};
