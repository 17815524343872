import { makeStyles } from '@material-ui/styles';
import palette from 'theme/palette';

export const useStyles = makeStyles(() => ({
	label: {
		fontSize: '13px',
		lineHeight: '13px',
		color: palette.gray.medium,
		paddingBottom: 2.5,
		marginTop: -2.5
	}
}));
