export const PANEL_ROLES = {
	admin: 'admin',
	clinic_admin: 'clinic_admin',
	doctor: 'doctor',
	receptionist: 'receptionist',
	spirometry_technican: 'spirometry_technican',
	patient: 'patient'
} as const;

const {
	admin,
	clinic_admin,
	doctor,
	spirometry_technican,
	receptionist
} = PANEL_ROLES;

export const ROLES_LABELS = {
	[admin]: 'global.roles.admin',
	[clinic_admin]: 'global.roles.clinic_admin',
	[doctor]: 'global.roles.doctor',
	[receptionist]: 'global.roles.receptionist',
	[spirometry_technican]: 'global.roles.spirometry_technician'
};

export const ADMIN = [admin];
export const CLINIC_ADMIN = [clinic_admin];
export const DOCTOR = [doctor];
export const RECEPTIONIST = [receptionist];
export const SPIROMETRY_TECHNICAN = [spirometry_technican];

export const GLOBAL_PAGE_ROLES = [
	admin,
	clinic_admin,
	doctor,
	spirometry_technican,
	receptionist
];

export const REVIEW_PAGE_ROLES = [
	admin,
	clinic_admin,
	doctor,
	spirometry_technican
];
export const PATIENTS_PAGE_ROLES = [doctor];

// Settings
const CLINIC_PROFILE_ROLES = [clinic_admin];
const CLINIC_EMPLOYEES_ROLES = [clinic_admin];
const STATISTICS_ROLES = [clinic_admin];
const MY_PROFILE_ROLES = [doctor, spirometry_technican, receptionist];
const CLINIC_LIST_ROLES = [admin];
const MY_CLINICS_ROLES = [spirometry_technican, receptionist];
const NOTIFICATIONS_ROLES = [
	clinic_admin,
	doctor,
	spirometry_technican,
	receptionist
];
const UNIT_SYSTEMS_ROLES = [admin, doctor, spirometry_technican, receptionist];
const PREFERENCES_ROLES = [
	admin,
	clinic_admin,
	doctor,
	spirometry_technican,
	receptionist
];
const FAQ_ROLES = [admin, doctor, spirometry_technican, receptionist];
const MANAGE_ACTION_PLANS_ROLES = [
	admin,
	doctor,
	spirometry_technican,
	receptionist
];
const TERMS_CONDITIONS_ROLES = [
	admin,
	doctor,
	spirometry_technican,
	receptionist
];

export const SETTINGS_ROLES = {
	PREFERENCES_ROLES: [
		clinic_admin,
		doctor,
		spirometry_technican,
		receptionist
	],
	GENERAL_SETTINGS: [admin],
	DISEASE: [admin],
	ICD: [admin],
	MEDICATIONS: [admin],
	PHYSICAL_VALUES: [admin],
	RECOMMENDATIONS: [admin],
	MANAGE_ACTION_PLANS_ROLES: [doctor, spirometry_technican, receptionist]
};

export const SETTINGS_PAGE = {
	CLINIC_PROFILE_ROLES,
	CLINIC_EMPLOYEES_ROLES,
	STATISTICS_ROLES,
	MY_PROFILE_ROLES,
	CLINIC_LIST_ROLES,
	MY_CLINICS_ROLES,
	NOTIFICATIONS_ROLES,
	UNIT_SYSTEMS_ROLES,
	PREFERENCES_ROLES,
	FAQ_ROLES,
	TERMS_CONDITIONS_ROLES,
	MANAGE_ACTION_PLANS_ROLES
};
