import { Box, Typography as MuiTypography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { EMPTY_VALUE } from 'helpers';
import { number, oneOfType, string } from 'prop-types';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: 5
	},
	name: { textTransform: 'uppercase', width: 40, textAlign: 'right' },
	unit: { fontSize: 10, width: 20 },
	content: {
		margin: '0 8px',
		background: ({ color }) => theme.palette.parameters.results[color],
		borderRadius: 6,
		height: 25,
		width: 44,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	result: {
		lineHeight: '7px',
		color: theme.palette.white
	},
	resultEmpty: {
		color: theme.palette.black
	}
}));

const Parameter = ({ color, unit, value, label }) => {
	const classes = useStyles({ color });
	return (
		<Box className={classes.root}>
			<MuiTypography className={classes.name} variant="h6" component="p">
				{label}
			</MuiTypography>
			<Box className={classes.content}>
				<MuiTypography
					variant="h6"
					className={clsx(
						classes.result,
						!value && classes.resultEmpty
					)}
					component="p"
				>
					{value || EMPTY_VALUE}
				</MuiTypography>
			</Box>
			<MuiTypography
				className={classes.unit}
				variant="h6"
				component="span"
			>
				{unit}
			</MuiTypography>
		</Box>
	);
};

Parameter.propTypes = {
	color: string,
	unit: string.isRequired,
	value: oneOfType([number, string]),
	label: string.isRequired
};

export default Parameter;
