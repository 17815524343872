import { useQuery } from 'components/utilities';
import { QUERY_SPECIALIZATIONS } from 'helpers';

import { SpecializationsRepository } from './repository';

/**
 * fetch doctor specializations list
 * @param {object} options useQuery options
 * @returns specializations query data
 */
const useFetch = (options = {}) => {
	return useQuery(
		QUERY_SPECIALIZATIONS,
		(payload) => SpecializationsRepository.getAll(payload),
		{ cacheTime: 8 * 60 * 60 * 1000, ...options }
	);
};

export default useFetch;
