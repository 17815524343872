import { addMinutes, isAfter, isBefore, isDate,isValid } from 'date-fns';

export const checkAppointmentStatus = (start, duration) => {
	const end = addMinutes(start, duration);
	const now = new Date();

	const isOngoing = isAfter(now, start) && isBefore(now, end);
	const isEnded = isAfter(now, end);

	return { isOngoing, isEnded };
};

export const getShowEditControls = (date) => {
	let ISODate = date;
	if (!isDate(date) || !isValid(date)) {
		ISODate = new Date(date);
	}

	return isBefore(new Date(), ISODate);
};
