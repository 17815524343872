import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	legend: {
		flex: 1,
		width: 220,
		display: 'flex',
		alignItems: 'center',
		gap: 5,
		paddingTop: 10
	},
	legendItem: {
		padding: '1px 4px',
		border: '1px solid #E2E2E2',
		borderRadius: 8,
		display: 'flex',
		gap: 2,
		justifyContent: 'space-between',
		alignItems: 'center',
		height: '16px'
	},
	label: {
		fontSize: '10px'
	},
	spacer: {
		minWidth: 34
	}
}));
