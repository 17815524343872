import { ClassNameMap } from '@material-ui/styles';
import clsx from 'clsx';
import {
	ParameterLabel,
	ValueLabel
} from 'components/elements/atoms/ExaminationsV2';
import { EMPTY_VALUE } from 'helpers/variables';
import React from 'react';
import { useTable } from 'react-table';
import { ExaminationsParametersType } from 'types/Examinations/parameters';

import { determineIfCellIsInteger, formatValue } from './helpers';
import { useStyles } from './styles';

export interface IColumn<Accessors = unknown> {
	Header: string;
	accessor: Accessors;
	isInteger?: boolean | { rows?: ExaminationsParametersType[] };
	component?: ({ value }) => JSX.Element;
	disableTranslation?: boolean;
}

export type ParametersTableProps<TData, ColumnsAccessors> = {
	rowClassName?: string;
	headerCellClassName?: string;
	tableBodyClassName?: string;
	columns: IColumn<ColumnsAccessors>[];
	data: TData[];
	useLocalTheme?: LocalTheme;
};

export type LocalTheme = () => void | ClassNameMap<
	'valueLabel' | 'headerLabel' | 'row'
>;

export const ParametersTable = <TData, ColumnsAccessors>({
	columns,
	data,
	rowClassName = '',
	tableBodyClassName = '',
	headerCellClassName = '',
	useLocalTheme = () => {}
}: ParametersTableProps<TData, ColumnsAccessors>) => {
	const classes = useStyles();
	const localThemeClasses = useLocalTheme();
	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useTable({
			columns,
			data
		});
	const renderCell = (cell, index) => {
		const isInteger = determineIfCellIsInteger({
			isInteger: cell.column?.isInteger,
			parameterType: cell.row.original.type
		});

		if (cell.column?.component) {
			return (
				<cell.column.component
					className={localThemeClasses?.valueLabel}
					value={formatValue(cell.value, isInteger)}
				/>
			);
		}

		if (index === 0) {
			return (
				<ParameterLabel
					className={localThemeClasses?.valueLabel}
					disableTranslation={
						'disableTranslation' in cell.column
							? cell.column.disableTranslation
							: true
					}
					label={cell.value || EMPTY_VALUE}
				/>
			);
		}

		return (
			<ValueLabel
				className={localThemeClasses?.valueLabel}
				value={
					cell.row.original.validator
						? cell.row.original.validator(cell.value, isInteger)
						: formatValue(cell.value, isInteger)
				}
			/>
		);
	};

	return (
		<table {...getTableProps()} className={classes.table}>
			<thead>
				{headerGroups.map((headerGroup) => (
					<tr
						{...headerGroup.getHeaderGroupProps()}
						className={clsx([
							rowClassName,
							localThemeClasses?.row,
							classes.row
						])}
					>
						{headerGroup.headers.map((column) => (
							<th
								{...column.getHeaderProps()}
								className={classes.tableHeader}
							>
								<ParameterLabel
									className={clsx([
										headerCellClassName,
										localThemeClasses?.headerLabel,
										classes.headerCell
									])}
									label={column.render('Header')}
								/>
							</th>
						))}
					</tr>
				))}
			</thead>
			<tbody {...getTableBodyProps()} className={tableBodyClassName}>
				{rows.map((row) => {
					prepareRow(row);
					return (
						<tr
							{...row.getRowProps()}
							className={clsx([
								classes.row,
								localThemeClasses?.row,
								rowClassName
							])}
						>
							{row.cells.map((cell, i) => {
								return (
									<td
										{...cell.getCellProps()}
										className={classes.cell}
									>
										{renderCell(cell, i)}
									</td>
								);
							})}
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};
