import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		flexDirection: 'column',
		justifyContent: 'center',
		width: '50%'
	},
	zScore: {
		paddingBottom: 0,
		'& .z-score--row': {
			height: '10.4px',
			borderRadius: 10
		},
		'& .z-score--point': {
			height: 5,
			width: 5
		},
		'& .z-score--param': {
			fontSize: '10.4px',
			lineHeight: '10.4px',
			color: theme.palette.gray.medium,
			fontFamily: 'Regola'
		},
		'& .z-score--ref-line--label': {
			fontSize: 9,
			lineHeight: '9px',
			color: theme.palette.primary.dark,
			fontFamily: 'Regola'
		},
		'& .z-score--ref-line--wrapper': {
			height: '100%',
			top: 3
		},
		'& .z-score--x-axis-label': {
			fontSize: 9,
			lineHeight: '9px',
			color: theme.palette.gray.medium,
			fontFamily: 'Regola'
		}
	}
}));
