import { useQuery } from 'components/utilities';
import { UseQueryOptionsProps } from 'components/utilities/hooks/react-query/useQuery';
import { GET_EXAMINATION_REPORT_DATA_QUERY_KEY } from 'helpers';
import {
	BronchodilatorReportModel,
	CalibrationCheckReportModel,
	ForcedSpirometryReportModel,
	PeakFlowReportModel,
	PulseOximetryReportModel,
	SlowVitalCapacitySVCReportModel
} from 'models/Report/Reports';
import { useParams } from 'utilities';

import { ExaminationReportV2Repository } from './repository';

export type AdditionalData = {
	examinationId?: IDParam;
	examinationType?: ExaminationTypeParam;
	patientId?: PatientIdParam;
};

export const useExaminationReportData = (
	options: UseQueryOptionsProps<
		| ForcedSpirometryReportModel
		| SlowVitalCapacitySVCReportModel
		| PulseOximetryReportModel
		| PeakFlowReportModel
		| BronchodilatorReportModel
		| CalibrationCheckReportModel
	> = {},
	{ examinationId, examinationType, patientId }: AdditionalData = {}
) => {
	const {
		id: examinationIdParam,
		examinationType: examinationTypeParam,
		patientId: patientIdParam
	} = useParams();

	const id = examinationId || examinationIdParam;
	const type = examinationType || examinationTypeParam;
	const patient = patientId || patientIdParam;
	return useQuery(
		GET_EXAMINATION_REPORT_DATA_QUERY_KEY(
			id,
			examinationType || examinationTypeParam
		),
		({ cancelToken }) =>
			ExaminationReportV2Repository.getReportData({
				cancelToken,
				examinationId: id,
				examinationType: type,
				patientId: patient
			}),
		options
	);
};
