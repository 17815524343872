import { addMonths } from 'date-fns';
import { COOKIES } from 'helpers/variables';
import Cookie from 'js-cookie';

import { COOKIE_CONFIG } from './helpers';

function save(data) {
	const now = new Date();
	const expirationDate = addMonths(now, 1);

	const config = {
		...COOKIE_CONFIG,
		expires: expirationDate
	};
	Cookie.set(COOKIES.COOKIE_AUTH, data, config);
}

function get() {
	return Cookie.get(COOKIES.COOKIE_AUTH);
}

function remove() {
	return Cookie.remove(COOKIES.COOKIE_AUTH);
}

export default { save, get, remove };
