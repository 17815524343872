import {
	Box,
	Grid,
	Typography as MuiTypography,
	useMediaQuery
} from '@material-ui/core';
import { useTranslations } from 'components/utilities';
import { object } from 'prop-types';
import React from 'react';

import { MeasurementResult } from '../components';
import { getExaminationResults } from '../helpers';
import { useStyles } from './styles';

const PersonalBest = ({ best }) => {
	const { t } = useTranslations();
	const matchMediaQuery = useMediaQuery('(min-width:1430px)');
	const lgLabel = matchMediaQuery ? 'auto' : 12;
	const lgMeasurements = matchMediaQuery ? true : 12;
	const classes = useStyles({ matchMediaQuery });

	const measurementResults = getExaminationResults(best);

	return (
		<Grid
			className={classes.grid}
			alignItems="center"
			container
			spacing={0}
		>
			<Grid lg={lgLabel} md={12} xs={12} item>
				<MuiTypography
					className={classes.title}
					variant="h6"
					component="p"
				>
					{t('patients.topbar.personal_best')}:
				</MuiTypography>
			</Grid>
			<Grid lg={lgMeasurements} md={12} xs={12} item>
				<Box className={classes.resultsBox}>
					{measurementResults.map((props) => (
						// eslint-disable-next-line
						<MeasurementResult key={props.name} {...props} />
					))}
				</Box>
			</Grid>
		</Grid>
	);
};

PersonalBest.propTypes = {
	best: object.isRequired
};

export default PersonalBest;
