import { arrayOf, node, oneOfType } from 'prop-types';
import { usePatientsList } from 'queries';
import React from 'react';

import { INITIAL_STATE } from './helpers';
import reducer from './store/reducer';
// Import hooks
import useActions from './useActions';

export const AppointmentFormContext = React.createContext();

const AppointmentFormProvider = ({ children }) => {
	const [state, dispatch] = React.useReducer(reducer, INITIAL_STATE);

	const { patientsList, isPatientsListLoading } = usePatientsList();

	const formActions = useActions({ dispatch, ...state });
	const values = {
		...state,
		patientsList: patientsList?.data,
		isPatientsListLoading,
		...formActions
	};

	return (
		<AppointmentFormContext.Provider value={{ ...values }}>
			{children}
		</AppointmentFormContext.Provider>
	);
};

AppointmentFormProvider.propTypes = {
	children: oneOfType([arrayOf(node), node]).isRequired
};

export default AppointmentFormProvider;
