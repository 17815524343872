import { makeStyles } from '@material-ui/styles';
import theme from 'theme';

export const useStyles = makeStyles<typeof theme>(() => ({
	title: {
		color: 'white'
	},
	link: {
		textDecoration: 'underline',
		fontWeight: 600,
		textTransform: 'lowercase'
	}
}));
