import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	loader: {
		position: 'absolute',
		top: 0,
		left: 0,
		height: '100%',
		width: '100%',
		zIndex: 9999,
		background: 'rgba(0, 0, 0, 0.03)',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	}
}));
