import { Box } from '@material-ui/core';
import clsx from 'clsx';
import {
	Correctness,
	Repeatability
} from 'components/elements/organisms/ExaminationsV2';
import { CHART_MEASUREMENT_PARAMETER_OPTION_NAMES as PARAMETERS } from 'helpers/charts';
import { ForcedSpirometryReportModel } from 'models/Report/Reports';
import React from 'react';

import { ContainerWithBorder, ZScore } from '../../../components';
import { useCommonStyles, useCorrectnessStyles } from '../../../styles';
import { useStyles } from './styles';
type Props = {
	examinationData: ForcedSpirometryReportModel | undefined;
};
export const ExaminationScores = ({ examinationData }: Props) => {
	const commonClasses = useCommonStyles();
	const classes = useStyles();
	return (
		<Box className={clsx([commonClasses.flexContainer, classes.root])}>
			<ZScore examinationData={examinationData} />
			<ContainerWithBorder
				className={commonClasses.containerWithBorderMinWidth}
			>
				<Correctness
					examination={examinationData}
					upperStatusFieldName={'fvcStatus'}
					bottomStatusFieldName={'fev1Status'}
					parametersLabels={[
						PARAMETERS.fvc.label,
						PARAMETERS.fev1.label
					]}
					useLocalTheme={useCorrectnessStyles}
				/>
			</ContainerWithBorder>
			<ContainerWithBorder
				className={commonClasses.containerWithBorderMinWidth}
			>
				<Repeatability
					examination={examinationData}
					useLocalTheme={useCorrectnessStyles}
				/>
			</ContainerWithBorder>
		</Box>
	);
};
