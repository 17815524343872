import { useMutation } from 'components/utilities';
import { MY_PROFILE_USER_RESOURCES_QUERY_KEY } from 'helpers';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { changeUserFirstLastName } from 'store/actions';

import { DoctorRepository } from './repository';
import { DoctorServices } from './services';

const queryKey = [MY_PROFILE_USER_RESOURCES_QUERY_KEY];

const useEdit = ({ onMutate, onError, onSettled, onSuccess } = {}) => {
	const cache = useQueryClient();
	const {
		id: userId,
		firstName,
		lastName
	} = useSelector((state) => state.auth.authenticate.data.user);
	const dispatch = useDispatch();

	const handleMutation = (newData) => {
		// cancel query request
		cache.cancelQueries(queryKey);

		// snapshot of prev cache state
		const previousCacheData = cache.getQueryData(queryKey);

		// optimistic update data
		cache.setQueryData(queryKey, (prev) => ({
			...prev,
			data: { ...newData }
		}));

		onMutate && onMutate(newData);

		return { previousCacheData, newData };
	};

	const handleError = (_, err, newData, context) => {
		// set prev cache state if error occurred
		cache.setQueryData(queryKey, context.previousCacheData);

		onError && onError(_, err, newData, context);
	};

	const handleSettled = (data) => {
		// if error or success occurred -> background refetch data and replace cache with database data
		cache.invalidateQueries(queryKey);

		onSettled && onSettled(data);
	};

	// change first and last name
	const handleSuccess = ({ data }) => {
		const { first_name, last_name } = data;
		const isFirstNameChanged = firstName !== first_name;
		const isLastNameChanged = lastName !== last_name;
		(isLastNameChanged || isFirstNameChanged) &&
			changeUserFirstLastName(data)(dispatch);

		onSuccess && onSuccess(data);
	};

	const mutation = useMutation(
		(payload) => {
			const data = DoctorServices.convertDoctorFormsPayload({
				values: payload
			});
			return DoctorRepository.edit(data, userId);
		},
		{
			onMutate: handleMutation,
			onError: handleError,
			onSettled: handleSettled,
			onSuccess: handleSuccess
		}
	);

	return mutation;
};

export default useEdit;
