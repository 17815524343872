import { ReactComponent as HeartIcon } from 'assets/icons/ic_heartrate.svg';
import { ReactComponent as LungIcon } from 'assets/icons/ic_lung.svg';
import { CHART_EXAMINATION_PARAMETER_TYPES } from 'helpers';

export const PARAMETERS_GROUPS = [
	{
		type: CHART_EXAMINATION_PARAMETER_TYPES.LUNG,
		label: 'patients.examinations.parameters.lung',
		icon: LungIcon
	},
	{
		type: CHART_EXAMINATION_PARAMETER_TYPES.HEART,
		label: 'patients.examinations.parameters.heart',
		icon: HeartIcon
	}
];
