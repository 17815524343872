import { QueryCache, QueryClient, QueryClientConfig } from 'react-query';

const queryCache = new QueryCache();

const overrides: QueryClientConfig = {
	queryCache,
	defaultOptions: {
		queries: {
			cacheTime: 24 * 60 * 60 * 1000, //24h - Infinity can cause some unhandled errors
			retry: 0,
			refetchOnWindowFocus: false
		}
	}
};

export const queryClient = new QueryClient(overrides);
