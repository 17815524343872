import { Box, Typography as MuiTypography } from '@material-ui/core';
import { useLanguage } from 'components/utilities';
import format from 'date-fns/format';
import { string } from 'prop-types';
import React from 'react';

import { useStyles } from './styles';

const ExaminationCreationDate = ({ dateCreated }) => {
	const classes = useStyles();
	const { localLng } = useLanguage();
	const date = dateCreated.replace(' ', 'T') ?? '';
	const formatedDate = format(new Date(date), 'dd LLL yyyy', {
		locale: localLng()
	});

	return (
		<Box className={classes.root}>
			<MuiTypography className={classes.date} variant="h6" component="p">
				{formatedDate}
			</MuiTypography>
		</Box>
	);
};

ExaminationCreationDate.propTypes = { dateCreated: string.isRequired };

export default ExaminationCreationDate;
