import { makeStyles } from '@material-ui/styles';
import palette from 'theme/palette';

export const useStyles = makeStyles(() => ({
	typography: {
		fontWeight: 400
	},
	link: {
		display: 'flex',
		alignItems: 'center',
		color: palette.primary.main
	}
}));
