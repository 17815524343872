import { HelmetTags } from 'components/elements';
import { LoginTemplate } from 'components/templates';
import React from 'react';

import VerificationStatus from './VerificationStatus';

const DoctorAccountVerification = () => {
	return (
		<LoginTemplate>
			<HelmetTags title="pages.account_verification.title" />
			<VerificationStatus />
		</LoginTemplate>
	);
};

export default DoctorAccountVerification;
