import { format } from 'date-fns';
import {
	calculateAge,
	CHART_MEASUREMENT_PARAMETER_OPTION_NAMES,
	convertDecimalNumber,
	convertPhoneNumber,
	ETHNICITY_LABELS,
	getSmokerLabel,
	getUserUnit,
	recalculateParameterValueUnit
} from 'helpers';
import { DATE_FORMAT } from 'helpers/variables';
import { ClinicDataModelWithDoctor } from 'models/Clinic/Clinic';
import { PatientModel } from 'models/Patient/PatientModel';
import ReactHtmlParser from 'react-html-parser';

import { InformationSectionField } from '../../../../../../../../components/elements/molecules/ExaminationsV2/InformationSection';

export const CLINIC_FIELDS: InformationSectionField<ClinicDataModelWithDoctor>[] =
	[
		{
			fieldName: 'doctorName',
			label: 'report.clinic.doctor',
			valueConverter: (v) => v ?? undefined
		},
		{
			fieldName: 'name',
			label: 'report.clinic.clinic_name',
			valueConverter: (v) => v
		},
		{
			fieldName: 'email',
			label: 'report.clinic.clinic_email',
			valueConverter: (v) => v ?? undefined
		},
		null,
		{
			fieldName: 'street',
			label: 'report.clinic.clinic_address',
			valueConverter: (v) => v ?? undefined
		},
		{
			fieldName: 'phoneNumber',
			label: 'report.clinic.clinic_phone_number',
			valueConverter: (v, data) =>
				v && data?.['phonePrefix']
					? convertPhoneNumber(v, data?.['phonePrefix'])
					: undefined
		}
	];

export const createDoctorName = (firstName, lastName) =>
	`${firstName} ${lastName}`;

export const PATIENT_FIELDS: InformationSectionField<
	PatientModel['patient']
>[] = [
	{
		fieldName: 'patientFirstName',
		label: 'report.patient.full_name',
		valueConverter: (v, data) =>
			v && data?.['patientLastName']
				? `${v} ${data?.['patientLastName']}`
				: undefined
	},
	{
		fieldName: 'patientGender',
		label: 'report.patient.birth_gender',
		valueConverter: (v) => (v ? `global.${v?.toLowerCase()}` : undefined)
	},
	{
		fieldName: 'patientHeight',
		label: 'report.patient.height',
		valueConverter: (v, data) => {
			if (!v) {
				return v;
			}
			const heightUnitSetting = getUserUnit('height');
			const value = recalculateParameterValueUnit(
				'height_unit',
				Number(v),
				data?.['patientHeightUnit'] ??
					CHART_MEASUREMENT_PARAMETER_OPTION_NAMES.height.defaultUnit
			);
			return `${value} ${heightUnitSetting}`;
		}
	},
	null,
	{
		fieldName: 'patientDob',
		label: 'report.patient.birth_date',
		valueConverter: (v) => {
			if (!v) return v;
			return {
				key: 'settings.preferences.value_age',
				replaceKeys: {
					['%v1']: format(new Date(v), DATE_FORMAT.DEFAULT),
					['%v2']: calculateAge(v)
				}
			};
		}
	},
	{
		fieldName: 'patientWeight',
		label: 'report.patient.weight',
		valueConverter: (v, data) => {
			if (!v) {
				return v;
			}
			const weightUnitSetting = getUserUnit('weight');
			const value = recalculateParameterValueUnit(
				'height_unit',
				Number(v),
				data?.['patientWeightUnit'] ??
					CHART_MEASUREMENT_PARAMETER_OPTION_NAMES.weight.defaultUnit
			);
			return `${value} ${weightUnitSetting}`;
		}
	},
	null,
	{
		fieldName: 'patientRace',
		label: 'report.patient.ethnicity',
		valueConverter: (v) => (v ? ETHNICITY_LABELS[v] : v)
	},
	{
		fieldName: 'patientSmoker',
		label: 'report.patient.smoker',
		valueConverter: (v, data) => (data ? getSmokerLabel(data) : ''),
		valueWidth: 140
	},
	{
		fieldName: 'patientIdNumber',
		label: 'report.patient.id_number',
		valueConverter: (v) => (v ? `${v}` : '-')
	},
	{
		fieldName: 'patientBmi',
		label: 'report.patient.bmi',
		valueConverter: (v) =>
			v
				? ReactHtmlParser(
						`${convertDecimalNumber(v, 2)} kg/m<sup>2</sup>`
				  )
				: undefined
	},
	null
];
