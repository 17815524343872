import { DASH_PATHS } from 'components/routes/paths';

const { SCHEDULE } = DASH_PATHS;

export const getGridColumns = (pathname) => {
	const calendarColumns = { xs: 8, lg: 9 };

	if (pathname === SCHEDULE) {
		calendarColumns.lg = 12;
		calendarColumns.xs = 12;
	}

	return { calendarColumns };
};
