import { makeStyles } from '@material-ui/styles';
import theme from 'theme';
import palette from 'theme/palette';

import { IWithInfoTipTooltipProps, PLACEMENT } from './WithInfoTipTooltip';

export const useStyles = makeStyles<
	typeof theme,
	{ placement: IWithInfoTipTooltipProps['placement'] }
>(() => ({
	tooltip: ({ placement }) =>
		placement === PLACEMENT.RIGHT
			? {
					borderBottomLeftRadius: 0
			  }
			: {
					borderBottomRightRadius: 0
			  },
	withInfoTipTooltipRoot: {
		position: 'relative',
		padding: ({ placement }) =>
			placement === PLACEMENT.RIGHT ? '0 15px 0 0' : '0 0 0 15px'
	},
	icon: {
		cursor: 'pointer',
		position: 'absolute',
		top: 0,
		right: ({ placement }) => (placement === PLACEMENT.RIGHT ? 0 : 'unset'),
		left: ({ placement }) => (placement === PLACEMENT.LEFT ? 0 : 'unset'),
		'& path': {
			fill: palette.gray.medium
		},
		transform: 'translateY(-50%)'
	}
}));
