import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	dots: {
		height: 44,
		width: 22,
		borderRadius: 10,
		border: '0.5px solid #E2E2E2',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '2px 0'
	},
	dot: {
		border: '0.5px solid #E2E2E2',
		borderRadius: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: 16,
		height: 16
	}
}));
