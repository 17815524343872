import {
	Box,
	TextField,
	Typography as MuiTypography,
	useMediaQuery
} from '@material-ui/core';
import { useTranslations } from 'components/utilities';
import { bool, func, object, string } from 'prop-types';
import React from 'react';

import OptionButton from '../OptionButton';
import { getTimeOfDay, TIME_OF_DAY_FIELDS } from './helpers';
import SelectField from './SelectField';
import { useStyles } from './styles';
import useTimeOfDay from './useTimeOfDay';

const TimeOfDay = ({
	dosage,
	setTaking,
	submitFailed,
	errors,
	classNameRoot = ''
}) => {
	const matchesTimeOfDay = useMediaQuery('(max-width:1500px)');
	const matchDoseField = useMediaQuery('(max-width:1884px)');

	const classes = useStyles({ matchesTimeOfDay, matchDoseField });
	const { t } = useTranslations();
	const { taking } = dosage;

	const {
		setTakingArray,
		setVariant,
		setAmount,
		setApplyingWay,
		isTimeOfDayActive
	} = useTimeOfDay({
		dosage,
		setTaking
	});

	const handleAmountChange =
		({ value }) =>
		(e) => {
			const {
				target: { value: amountValue }
			} = e;
			setAmount({ amountValue, value });
		};

	return (
		<Box className={classNameRoot}>
			<MuiTypography
				variant="body2"
				component="p"
				className={classes.typography}
			>
				{t('patients.action_plan.create.medications.time_of_day')}
			</MuiTypography>
			{submitFailed && (errors.taking || errors.drug?.taking) && (
				<MuiTypography variant="body2" component="p" color="error">
					{t(errors.taking || errors.drug?.taking)}
				</MuiTypography>
			)}
			<Box className={classes.container}>
				{TIME_OF_DAY_FIELDS.map(({ value, text }, index) => (
					<Box key={value} className={classes.dosageFormContainer}>
						<OptionButton
							text={text}
							value={value}
							state={isTimeOfDayActive(value)?.time_of_day}
							onClick={() => setTakingArray(value)}
							variant={setVariant(value)}
							className={classes.button}
						/>
						{isTimeOfDayActive(value) && (
							<Box className={classes.dosageForm}>
								<TextField
									className={classes.numberField}
									type="number"
									label={t(
										'patients.action_plan.create.medications.dose'
									)}
									variant="outlined"
									margin="dense"
									value={getTimeOfDay(taking, value).amount}
									onChange={handleAmountChange({ value })}
									inputProps={{ min: 1 }}
								/>
								<SelectField
									index={index}
									amount={getTimeOfDay(taking, value).amount}
									setApplyingWay={setApplyingWay}
									value={
										getTimeOfDay(taking, value).applying_way
									}
									timeOfDay={value}
								/>
							</Box>
						)}
					</Box>
				))}
			</Box>
		</Box>
	);
};

TimeOfDay.propTypes = {
	dosage: object.isRequired,
	setTaking: func.isRequired,
	errors: object,
	type: string,
	classNameRoot: string,
	submitFailed: bool
};

export default TimeOfDay;
