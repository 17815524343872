import { PointOptionsObject } from 'highcharts';

/**
 *  top-left vertex:
 *  x = 0.1 * predicted from FVC
 *  y = predicted from  PEF.  // currently returned in L/s unit -> unnecessary to divide by 60
 *
 *  down-right vertex:
 *  x = predicted from FVC
 *  y = 0
 */
export const getGraphTriangleData = (
	fvcPredicted: number,
	pefPredicted: number
): PointOptionsObject[] => [
	{
		x: 0,
		y: 0
	},
	{
		x: 0.1 * fvcPredicted,
		y: pefPredicted
	},
	{
		x: fvcPredicted,
		y: 0
	},
	{
		x: 0,
		y: 0
	}
];
