import { makeStyles } from '@material-ui/styles';

const REFERENCE_LINE_WITH_LABEL_WIDTH = 100;

export const useStyles = makeStyles((theme) => ({
	root: {
		position: 'absolute',
		top: 0,
		left: 0,
		height: '100%',
		width: '100%',
		display: 'flex'
	},
	container: {
		height: 'inherit',
		width: 'inherit',
		position: 'relative',
		display: 'block'
	},
	referenceLine: {
		position: 'absolute',
		zIndex: 2,
		top: 0,
		flexDirection: 'column',
		height: 'calc(100% + 2px)',
		alignItems: 'center',
		width: REFERENCE_LINE_WITH_LABEL_WIDTH,
		transform: 'translateX(50%)'
	},
	label: {
		fontSize: '12px',
		lineHeight: '12px',
		fontWeight: 400,
		color: theme.palette.customBlack.main
	},
	line: {
		'-webkit-print-color-adjust': 'exact',
		width: '1px',
		backgroundColor: theme.palette.gray.medium,
		height: 'inherit'
	},
	spacer: {
		minWidth: 70,
		height: '100%'
	}
}));

export const getReferenceLinePosition = (value, getValuePosition) => {
	const position = getValuePosition(value);
	return `${position - 0.1}%`;
};

export const getReferenceLineDisplay = ({
	value,
	minXAxisValue,
	maxXAxisValue
}) => {
	return minXAxisValue > value || maxXAxisValue < value ? 'none' : 'flex';
};
