import { CustomContentProps, SnackbarContent, useSnackbar } from 'notistack';
import React from 'react';

import { Container } from '../components';
import {
	ISnackbarMessageProps,
	SnackbarMessage
} from '../components/SnackbarMessage/SnackbarMessage';

export interface INotistackSnackbarProps
	extends Omit<CustomContentProps, 'message' | 'variant'>,
		Omit<ISnackbarMessageProps, 'onClose' | 'variant'> {
	onClose?: ISnackbarMessageProps['onClose'];
	contentVariant: ISnackbarMessageProps['variant'];
	children?: CmpChildren;
}

export const NotistackSnackbar = React.forwardRef<
	HTMLDivElement,
	INotistackSnackbarProps
>(({ id, contentVariant, message, onClose, titleProps, children }, ref) => {
	const { closeSnackbar } = useSnackbar();

	return (
		<SnackbarContent ref={ref}>
			<Container variant={contentVariant}>
				<>
					<SnackbarMessage
						variant={contentVariant}
						message={message}
						onClose={(e) => {
							if (onClose) {
								onClose(e, 'close');
							}
							closeSnackbar(id);
						}}
						titleProps={titleProps}
					/>
					{children}
				</>
			</Container>
		</SnackbarContent>
	);
});
