import {
	CHART_MEASUREMENT_PARAMETER_OPTION_NAMES as OPTION_NAMES,
	isValueValid,
	RESULTS_COLORS,
	validateExaminationValue
} from 'helpers';

export const getExaminationProps = ({ examinations }) => {
	const { fev1, fev1Status, fvc, fvcStatus, created_at } = examinations[0];
	const fev1Resources = OPTION_NAMES.fev1;
	const fvcResources = OPTION_NAMES.fvc;
	const fev1Result = Number(fev1)?.toFixed(2);
	const fvcResult = Number(fvc)?.toFixed(2);

	const results = [
		{
			color: isValueValid(fvcResult)
				? RESULTS_COLORS[fvcStatus - 1]
				: RESULTS_COLORS[3],
			value: validateExaminationValue(fvcResult),
			label: fvcResources.label,
			unit: fvcResources.defaultUnit
		},
		{
			color: isValueValid(fev1Result)
				? RESULTS_COLORS[fev1Status - 1]
				: RESULTS_COLORS[3],
			value: validateExaminationValue(fev1Result),
			label: fev1Resources.label,
			unit: fev1Resources.defaultUnit
		}
	];
	return {
		createdAt: created_at,
		name: fev1Resources.type,
		results
	};
};

export const getHeartRateProps = ({ heart_rates }) => {
	const { hrAvg, spO2, status, spO2Color, created_at } = heart_rates[0];

	const hr = OPTION_NAMES.hrAvg;
	const spO2Option = OPTION_NAMES.spO2;
	const results = [
		{
			color: isValueValid(hrAvg)
				? RESULTS_COLORS[status - 1]
				: RESULTS_COLORS[3],
			label: hr.label,
			value: validateExaminationValue(hrAvg),
			unit: hr.defaultUnit
		},
		{
			color: isValueValid(spO2)
				? RESULTS_COLORS[spO2Color - 1]
				: RESULTS_COLORS[3],
			label: spO2Option.label,
			value: validateExaminationValue(spO2),
			unit: spO2Option.defaultUnit
		}
	];
	return { name: hr.type, results, createdAt: created_at };
};

export const getPefProps = ({ peak_flows }) => {
	const { result, pef1, pef2, pef3, created_at } = peak_flows[0];
	const { status1, status2, status3 } = peak_flows[0];

	const pefArray = [pef1, pef2, pef3];
	const statusArray = [status1, status2, status3];

	const index = pefArray.indexOf(result);
	const status = statusArray[index] - 1;

	const { type: name, defaultUnit, label } = OPTION_NAMES.pef;

	const results = [
		{
			color: isValueValid(result)
				? RESULTS_COLORS[status - 1]
				: RESULTS_COLORS[3],
			label,
			value: validateExaminationValue(result),
			unit: defaultUnit
		}
	];

	return { name, results, createdAt: created_at };
};
