import { AppBar, Box, Hidden, IconButton, Toolbar } from '@material-ui/core';
import { ReactComponent as AioCareLogoTm } from 'assets/icons/aiocare-logo-tm.svg';
import { MenuIcon } from 'assets/icons/commonMUIIcons';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Navigation from './Navigation';
import Profile from './Profile';
import { useStyles } from './styles';

const Topbar = ({ className, onSidebarOpen, ...rest }) => {
	const classes = useStyles();

	return (
		<AppBar {...rest} className={clsx(classes.root, className)}>
			<Toolbar className={classes.toolbar}>
				<RouterLink to="/">
					<AioCareLogoTm className={classes.logo} />
				</RouterLink>
				<Box className={classes.flexGrow} />
				<Hidden only={['xs', 'sm']}>
					<Navigation />
				</Hidden>
				<Hidden only={['xs', 'sm']}>
					<Profile />
				</Hidden>

				<Hidden mdUp>
					<IconButton color="primary" onClick={onSidebarOpen}>
						<MenuIcon />
					</IconButton>
				</Hidden>
			</Toolbar>
		</AppBar>
	);
};

Topbar.propTypes = {
	className: PropTypes.string,
	onSidebarOpen: PropTypes.func
};

export default Topbar;
