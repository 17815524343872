import { Box } from '@material-ui/core';
import { ParameterValue } from 'components/elements/molecules/ExaminationsV2';
import React from 'react';
import { IParameterValuesData } from 'utilities/Examinations/Parameters';

import { useStyles } from './styles';

type Props = {
	restValues: IParameterValuesData[];
};
const AdditionalParameters = ({ restValues }: Props) => {
	const classes = useStyles();

	return (
		<Box data-testid={'AdditionalParameters-root'} className={classes.root}>
			{restValues.map((data) => (
				<ParameterValue key={JSON.stringify(data)} {...data} />
			))}
		</Box>
	);
};

export default AdditionalParameters;
