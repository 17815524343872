import { Button } from '@material-ui/core';
import {
	FullscreenExitIcon,
	FullscreenIcon} from 'assets/icons/commonMUIIcons';
import { bool } from 'prop-types';
import React from 'react';

import { useStyles } from './styles';
import useFullScreenButton from './useFullScreenButton';

const FullScreenButton = React.forwardRef((props, fullScreenRef) => {
	const classes = useStyles();
	const { onFullScreen } = useFullScreenButton(props);

	const Icon = props.isFullscreenActive ? FullscreenExitIcon : FullscreenIcon;

	return (
		<Button
			onClick={() => onFullScreen(fullScreenRef)}
			variant="contained"
			className={classes.root}
			size="small"
		>
			<Icon className={classes.icon} />
		</Button>
	);
});

FullScreenButton.propTypes = {
	isFullscreenActive: bool.isRequired
};

export default FullScreenButton;
