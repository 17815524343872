import { Container, Graph } from 'components/elements/atoms/ExaminationsV2';
import { useTranslations } from 'components/utilities';
import React from 'react';
import { prepareAllMeasurementsDataToRender } from 'utilities/Examinations/ExaminationDetails/common';

import { useGetCachedForcedSpirometryReportData } from '../useGetCachedForcedSpirometryReportData';
import { useStyles } from './styles';

export const AllMeasurementsGraph = () => {
	const classes = useStyles();
	const { t } = useTranslations();

	const { examination } = useGetCachedForcedSpirometryReportData();

	return (
		<Container className={classes.root}>
			<Graph
				data={prepareAllMeasurementsDataToRender(
					examination?.measurements ?? [],
					'flowVolumeChart'
				)}
				xAxisLabel={t('report.graph.volume.label')}
				yAxisLabel={t('report.graph.flow.label')}
				title={{
					label: 'report.all_measurements_graph.all_flow.title'
				}}
				axisValuesRatio="2:1"
				fvcPredicted={examination?.bestParameters.fvc.predicted}
				pefPredicted={examination?.bestParameters.pef.predicted}
				axisBoundaries={{
					MIN_Y: -6,
					MAX_Y: 8,
					MIN_X: -1,
					MAX_X: 8
				}}
			/>
			<Graph
				data={prepareAllMeasurementsDataToRender(
					examination?.measurements ?? [],
					'volumeTimeChart'
				)}
				xAxisLabel={t('report.graph.time.label')}
				yAxisLabel={t('report.graph.volume.label')}
				title={{
					label: 'report.all_measurements_graph.all_volume.title'
				}}
				disableTriangle
				axisValuesRatio="1:1"
				axisBoundaries={{
					MIN_Y: 0,
					MAX_Y: 6,
					MIN_X: 0,
					MAX_X: 8
				}}
			/>
		</Container>
	);
};
