import { HelmetTags } from 'components/elements';
import RoleAuthorization from 'components/hoc/RoleAuthorization';
import { lazyLoadDelay } from 'helpers';
import { SETTINGS_PAGE } from 'helpers';
import React from 'react';

import { SETTINGS_PATHS } from '../paths';

// const ClinicsView = React.lazy(() =>
// 	lazyLoadDelay(import('views/Settings/views/Clinics'))
// );
// const ClinicFormView = React.lazy(() =>
// 	lazyLoadDelay(import('views/Settings/views/Clinics/views/ClinicForm'))
// );
// const ManageActionPlansView = React.lazy(() =>
// 	lazyLoadDelay(import('views/Settings/views/ManageActionPlans'))
// );
// const ActionPlanFormView = React.lazy(() =>
// 	lazyLoadDelay(
// 		import('views/PatientsDetails/views/ActionPlan/ActionPlanForm')
// 	)
// );
const PreferencesView = React.lazy(() =>
	lazyLoadDelay(import('views/Settings/views/Preferences'))
);
const MyProfileView = React.lazy(() =>
	lazyLoadDelay(import('views/Settings/views/MyProfile'))
);
const ClinicView = React.lazy(() =>
	lazyLoadDelay(import('views/Settings/views/Clinic'))
);
const ReleaseNotesView = React.lazy(() =>
	lazyLoadDelay(import('views/Settings/views/ReleaseNotes'))
);

const {
	CLINIC,
	// CLINICS,
	// CLINICS_CREATE,
	// MANAGE_ACTION_PLANS,
	// ACTION_PLAN_TEMPLATE_CREATE,
	// EDIT_ACTION_PLAN,
	// EMPLOYEES,
	PREFERENCES,
	MY_PROFILE,
	RELEASE_NOTES
} = SETTINGS_PATHS;

// Define roles
// const ClinicRoles = RoleAuthorization(SETTINGS_PAGE.CLINIC_PROFILE_ROLES);
// // const ActionPlanRoles = RoleAuthorization(
// // 	SETTINGS_PAGE.MANAGE_ACTION_PLANS_ROLES
// // );
const PreferencesRoles = RoleAuthorization(SETTINGS_PAGE.PREFERENCES_ROLES);
const MyProfileRoles = RoleAuthorization(SETTINGS_PAGE.MY_PROFILE_ROLES);

export const SETTINGS_ROUTES = [
	// {
	// 	path: CLINICS_CREATE,
	// 	component: ClinicRoles(ClinicFormView),
	// 	helmet: <HelmetTags title="pages.settings.nav.my_clinics_add.title" />
	// },
	// {
	// 	path: CLINICS,
	// 	component: ClinicRoles(ClinicsView),
	// 	helmet: <HelmetTags title="pages.settings.nav.my_clinics" />
	// },
	// {
	// 	path: EMPLOYEES,
	// 	component: ClinicRoles(EmployeesView),
	// 	helmet: <HelmetTags title="pages.settings.nav.employees.title" />
	// },
	// {
	// 	path: ACTION_PLAN_TEMPLATE_CREATE,
	// 	component: ActionPlanRoles(ActionPlanFormView),
	// 	helmet: (
	// 		<HelmetTags title="pages.settings.nav.manage_action_plans_add_new.title" />
	// 	)
	// },
	// {
	// 	path: EDIT_ACTION_PLAN,
	// 	component: ActionPlanRoles(ActionPlanFormView),
	// 	helmet: (
	// 		<HelmetTags title="pages.settings.nav.manage_action_plans_edit.title" />
	// 	)
	// },
	// {
	// 	path: MANAGE_ACTION_PLANS,
	// 	component: ActionPlanRoles(ManageActionPlansView),
	// 	helmet: (
	// 		<HelmetTags title="pages.settings.nav.manage_action_plans.title" />
	// 	)
	// },
	{
		path: PREFERENCES,
		component: PreferencesRoles(PreferencesView),
		helmet: <HelmetTags title="pages.settings.preferences.title" />
	},
	{
		path: CLINIC,
		component: PreferencesRoles(ClinicView),
		helmet: <HelmetTags title="pages.settings.clinic.title" />
	},
	{
		path: MY_PROFILE,
		component: MyProfileRoles(MyProfileView),
		helmet: <HelmetTags title="pages.settings.my_profile.title" />
	},
	{
		path: RELEASE_NOTES,
		component: MyProfileRoles(ReleaseNotesView),
		helmet: <HelmetTags title="pages.settings.release_notes.title" />
	}
];
