import { Container, Graph } from 'components/elements/atoms/ExaminationsV2';
import { useTranslations } from 'components/utilities';
import React from 'react';

import { useAttemptsContext } from '../../context';
import { prepareAllMeasurementsDataToRenderInGraph } from '../../helpers';
import { useGraphInitialState } from '../../hooks';
import { useCommonStyles, useGraphsStyles } from '../../styles';
import { useSpirometryFVCData } from '../useSpirometryFVCData';

export const Graphs = () => {
	const graphsClasses = useGraphsStyles();
	const commonClasses = useCommonStyles();
	const { t } = useTranslations();
	const examinationData = useSpirometryFVCData();
	const { flowVolumeGraphRef, volumeTimeGraphRef } = useAttemptsContext();

	useGraphInitialState();

	return (
		<Container className={graphsClasses.root}>
			<Graph
				ref={flowVolumeGraphRef}
				className={graphsClasses.graphContainer}
				data={prepareAllMeasurementsDataToRenderInGraph(
					examinationData?.measurements ?? [],
					'flowVolumeChart'
				)}
				xAxisLabel={t('report.graph.volume.label')}
				yAxisLabel={t('report.graph.flow.label')}
				title={{
					className: commonClasses.title,
					label: 'patients.examinations.details.spirometry.flow_volume'
				}}
				axisValuesRatio="2:1"
				fvcPredicted={examinationData?.bestParameters.fvc.predicted}
				pefPredicted={examinationData?.bestParameters.pef.predicted}
				axisBoundaries={{
					MIN_Y: -6,
					MAX_Y: 8,
					MIN_X: -1,
					MAX_X: 8
				}}
				containerBoundaries={{
					height: 320,
					width: 380
				}}
			/>
			<Graph
				ref={volumeTimeGraphRef}
				className={graphsClasses.graphContainer}
				data={prepareAllMeasurementsDataToRenderInGraph(
					examinationData?.measurements ?? [],
					'volumeTimeChart'
				)}
				xAxisLabel={t('report.graph.time.label')}
				yAxisLabel={t('report.graph.volume.label')}
				title={{
					className: commonClasses.title,
					label: 'patients.examinations.details.spirometry.volume_time'
				}}
				disableTriangle
				axisValuesRatio="1:1"
				axisBoundaries={{
					MIN_Y: 0,
					MAX_Y: 6,
					MIN_X: 0,
					MAX_X: 6
				}}
				containerBoundaries={{
					height: 320,
					width: 380
				}}
			/>
		</Container>
	);
};
