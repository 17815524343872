import { produce } from 'immer';

import * as types from './types';

const reducer = (state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.SET_PATIENT:
				draft.patient.patient_id = action.payload.patient_id;
				draft.patient.patient_name = action.payload.patient_name;
				break;

			case types.SET_SELECTED_DATE:
				draft.selectedDate = action.payload;
				break;

			case types.SET_DURATION:
				draft.duration = action.payload;
				break;

			case types.SET_SELECTED_APPOINTMENT:
				draft.selectedAppointment = action.payload;
				break;

			default:
				return state;
		}
	});

export default reducer;
