import { makeStyles } from '@material-ui/styles';
import { TRANSITIONS } from 'helpers';
import theme from 'theme';

export const useFadeTransitionStyles = makeStyles(() => ({
	enter: {
		opacity: 0
	},
	exit: {
		opacity: 1
	},
	enterActive: {
		opacity: 1,
		transition: `opacity ${TRANSITIONS.DURATIONS.enteringScreen}ms ease-in-out`
	},
	exitActive: {
		opacity: 0,
		transition: `opacity ${TRANSITIONS.DURATIONS.leavingScreen}ms ease-in-out`
	}
}));

export const useHeightTransitionStyles = makeStyles<
	typeof theme,
	{ transition?: number }
>(() => ({
	transitionEnter: {
		maxHeight: 0,
		opacity: 0
	},
	transitionEnterActive: {
		maxHeight: '1000px',
		transition: ({ transition }) =>
			`all ${transition ?? 500}ms ease-in-out`,

		opacity: 1
	},
	transitionExit: {
		maxHeight: '1000px',
		transition: ({ transition }) =>
			`all ${transition ?? 500}ms ease-in-out`,
		opacity: 1
	},
	transitionExitActive: {
		maxHeight: 0,
		opacity: 0
	}
}));
