import { func } from 'prop-types';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { Fields, Sidebar } from '../components';
// Import settings
import { FORM_SETTINGS } from '../helpers/form';
import { CONDITION_INITIAL_VALUES, CONDITIONS_FIELDS } from './helpers';

const ConditionForm = ({ setManualRefetch }) => {
	const { pathname } = useLocation();

	const isEdit = pathname.includes('edit');

	const title = isEdit
		? 'patients.conditions.form.title_edit'
		: 'patients.conditions.form.title';

	return (
		<Sidebar
			initialValues={CONDITION_INITIAL_VALUES}
			title={title}
			formSettings={{
				...FORM_SETTINGS.CONDITION,
				setManualRefetch: setManualRefetch
			}}
		>
			<Fields fieldsSet={CONDITIONS_FIELDS} />
		</Sidebar>
	);
};

ConditionForm.propTypes = {
	setManualRefetch: func.isRequired
};

export default ConditionForm;
