import { func } from 'prop-types';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { Fields, Sidebar } from '../components';
// Import settings
import { FORM_SETTINGS } from '../helpers/form';
import { SURGERY_FIELDS, SURGERY_INITIAL_VALUES } from './helpers';

const SurgeryForm = ({ setManualRefetch }) => {
	const { pathname } = useLocation();

	const isEdit = pathname.includes('edit');

	const title = isEdit
		? 'patients.surgeries.form.title_edit'
		: 'patients.surgeries.form.title';

	return (
		<Sidebar
			initialValues={SURGERY_INITIAL_VALUES}
			title={title}
			formSettings={{
				...FORM_SETTINGS.SURGERY,
				setManualRefetch: setManualRefetch
			}}
		>
			<Fields fieldsSet={SURGERY_FIELDS} />
		</Sidebar>
	);
};

SurgeryForm.propTypes = {
	setManualRefetch: func.isRequired
};

export default SurgeryForm;
