import { Box } from '@material-ui/core';
import { ReactComponent as AioCareLogoTm } from 'assets/icons/aiocare-logo-tm.svg';
import clsx from 'clsx';
import { string } from 'prop-types';
import React from 'react';

import { useStyles } from './styles';

type Props = {
	classNameRoot?: string;
	icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
};

const DefaultIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<AioCareLogoTm {...props} />
);
const Loader = ({ classNameRoot, icon: Icon = DefaultIcon }: Props) => {
	const classes = useStyles();
	return (
		<Box
			aria-label="loading..."
			className={clsx(classes.root, classNameRoot)}
		>
			<Box className={classes.box}>
				<Icon className={classes.logo} />
			</Box>
		</Box>
	);
};

Loader.propTypes = {
	classNameRoot: string
};

export default Loader;
