import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		gap: 10
	},
	graphsContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%'
	}
}));
