import { Box, IconButton, MenuItem, TableCell } from '@material-ui/core';
import { MoreVertIcon } from 'assets/icons/commonMUIIcons';
import { RouterLink } from 'components/elements';
import { MenuRPC, useTranslations } from 'components/utilities';
import { bool, func, object, oneOfType, string } from 'prop-types';
import { replaceCommonIdQueryParam } from 'queries/common';
import React from 'react';

import { DeleteMenuItem } from './components';
import { useStyles } from './styles';

const SettingsTableCell = ({
	cell,
	editRoutePath,
	isEdit = true,
	isDelete = true,
	isTableCell = true,
	deletePromise,
	deleteBtnText,
	deleteModalTextContent,
	deleteModalDisableTitle,
	isDeleteModalDisableTitle,
	successProps = {},
	children
}) => {
	const classes = useStyles();
	const { t } = useTranslations();

	const { id } = cell?.row?.original ?? cell?.rowData ?? {};
	const editPath = isEdit
		? typeof editRoutePath === 'function'
			? editRoutePath({ id })
			: replaceCommonIdQueryParam(editRoutePath, id)
		: '';

	const deleteProps = {
		deleteBtnText,
		deleteModalDisableTitle,
		itemId: id,
		deletePromise,
		deleteModalTextContent:
			deleteModalTextContent ?? 'table.delete.information',
		isDeleteModalDisableTitle: isDeleteModalDisableTitle
	};

	const renderContent = () => (
		<MenuRPC
			togglerComponent={({ open }) => (
				<IconButton onClick={open} size="small">
					<MoreVertIcon className={classes.icon} />
				</IconButton>
			)}
		>
			{({ close }) => (
				<Box>
					{isDelete && (
						<DeleteMenuItem
							successProps={successProps}
							{...deleteProps}
							closeMenu={close}
							id={id}
						/>
					)}
					{isEdit && (
						<MenuItem
							onClick={close}
							component={RouterLink}
							to={editPath}
						>
							{t('btn.edit')}
						</MenuItem>
					)}
					{children && <Box>{children({ close })}</Box>}
				</Box>
			)}
		</MenuRPC>
	);

	return isTableCell ? (
		<TableCell
			key={cell?.column?.id || cell?.columnIndex}
			{...cell.getCellProps()}
			className={classes.cell}
			component="th"
			scope="row"
		>
			{renderContent()}
		</TableCell>
	) : (
		renderContent()
	);
};

SettingsTableCell.propTypes = {
	cell: object.isRequired,
	deletePromise: func,
	editRoutePath: oneOfType([string, func]),
	children: func,
	isEdit: bool,
	isDelete: bool,
	isTableCell: bool,
	deleteModalDisableTitle: string,
	deleteBtnText: string,
	isDeleteModalDisableTitle: bool,
	deleteModalTextContent: oneOfType([string, object]),
	successProps: object
};

export default SettingsTableCell;
