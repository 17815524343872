import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	typography: {
		margin: 0,
		padding: 0,
		fontSize: '11px',
		lineHeight: '11px',
		fontWeight: 500,
		color: theme.palette.white
	}
}));
