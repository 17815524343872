import { makeStyles } from '@material-ui/styles';
import { ForcedSpirometryMeasurementModel } from 'models/Report/ForcedSpirometry';
import theme from 'theme';

type StylesProps = {
	isBest: ForcedSpirometryMeasurementModel['isBest'];
	color?: string;
};
export const useStyles = makeStyles<typeof theme, StylesProps>(() => ({
	dotBackground: {
		'-webkit-print-color-adjust': 'exact',
		backgroundColor: ({ isBest, color }) =>
			isBest ? (color ? color : '#40B246') : '#200D84',
		border: 'none'
	}
}));
