import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	popoverWrapper: {
		boxShadow: '0px 3px 12px 0px #00000026',
		borderRadius: 10,
		backgroundColor: theme.palette.white,
		padding: '20px !important'
	},
	tooltipContentLabel: {
		maxWidth: 150,
		fontSize: '11px',
		lineHeight: '12px',
		fontWeight: 400
	}
}));
