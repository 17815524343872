import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		maxHeight: 204,
		overflowY: 'scroll',
		'& li': { borderBottom: `solid 1px ${theme.palette.border.light}` },
		'& :last-child': { borderBottom: 'none' }
	}
}));
