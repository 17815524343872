import theme from 'theme';

export const APP_NODE_ENV_TYPES = {
	PRODUCTION: 'frp1',
	TESTING: 'TESTING',
	DEVELOPMENT: 'frd1'
} as const;

// Cookies
export const COOKIE_EXPIRES = 3650;
export const COOKIES = {
	COOKIE_COUNTRY_CODE: 'country',
	COOKIE_CITY_NAME: 'city',
	COOKIE_JWT: 'jwt',
	COOKIE_JWT_EXPIRATION_DATE: 'jwt_expiration_date',
	COOKIE_LANGUAGE: 'lng',
	COOKIE_AUTH: 'auth',
	COOKIE_REPORT: {
		REPORT_NOTE: 'reportNote',
		CREATION_DATE_TIME: 'reportCreateDateTime',
		REPORT_LANGUAGE: 'reportLanguage'
	},
	COOKIE_RELEASE_NOTE: {
		ACCEPTED: 'releaseNoteAccepted'
	}
};

// LANGUAGES
export const LANGUAGES = ['en', 'pl', 'sv'] as const;

export const EN = LANGUAGES[0];
export const PL = LANGUAGES[1];

// GOOGLE TASK MANAGER
export const GOOGLE_TASK_MANAGER = {
	PAGE_VIEW: 'pageview'
};

// METRICS
export const DECIMAL_SEPARATORS = {
	COMA: ',',
	DOT: '.'
};

// NAVIGATION
export const NAVIGATION_DIRECTIONS = {
	PREV: 'prev',
	NEXT: 'next'
};

// VALUES
export const EMPTY_VALUE = '-' as const;

// SIZES
export const SIZES = {
	SMALL: 'small',
	NORMAL: 'normal',
	MEDIUM: 'medium'
};

// IDLE STATUS
export const IDLE = {
	LOADING: 'loading',
	SUCCESS: 'success',
	ERROR: 'error'
} as const;

// DATE
export const DATE_FORMAT = {
	DEFAULT: 'dd.MM.yyyy',
	MONTH_YEAR: 'MMM yyyy',
	CHART_RANGE: 'yyyy-MM-dd',
	CONDITIONS: 'yyyy-MM-dd',
	REVIEW: 'yyyy-MM-dd',
	KEYBOARD_DATE_PICKER_VALUE: 'yyyy-MM-dd',
	KEYBOARD_DATE_PICKER: 'dd/MM/yyyy',
	BASIC_DATA: 'dd MM yyyy',
	PROFILE: 'dd MMMM yyyy',
	MEASUREMENTS_START_DATE: 'd MMM',
	MEASUREMENTS_END_DATE: 'd MMM yyy',
	FULL_DATE_WITH_WEEK_DAY: 'eeee, dd MMM yyyy',
	PHP: {
		YEAR: 'Y',
		YEAR_MONTH: 'Y-m',
		YEAR_MONTH_DAY: 'Y-m-d'
	} as const,
	CONDITIONS_TABLE: {
		MONTH_YEAR: 'MM.yyyy',
		DAY_MONTH_YEAR: 'dd.MM.yyyy'
	}
} as const;

// TIME
export const TIME_FORMAT = {
	LONG: 'p',
	AMPM: 'hh:mm a',
	DEFAULT: 'HH:mm',
	DEFAULT_WITH_SECONDS: 'HH:mm:ss',
	PM: 'PM',
	AM: 'AM'
} as const;

// DIRECTIONS
export const DIRECTIONS = {
	LTR: 'LTR',
	RTL: 'RTL'
};

// UNKNOWN
export const UNKNOWN = 'unknown';

export const FORM_TITLES = {
	MR: 'Mr',
	MRS: 'Mrs'
};

// CHARTS
export const HIGHCHARTS_TYPES = {
	STOCK_CHART: 'stockChart'
};

// TABLE
export const TABLE_OPTIONS = {
	PAGE_SIZE: 5,
	PAGE_INDEX: 0,
	ROWS_PER_PAGE: [5, 10, 20, 40]
};

export const TABLE_ACTIONS_ACCESSORS = {
	EDIT: 'edit',
	DELETE: 'delete',
	PRINT: 'print'
};

// LAYOUT
export const LAYOUT_BOX_BACKGROUND = {
	HEIGHT: {
		DEFAULT: 48,
		PATIENTS: 192
	}
};

// CHART
export const CHART_DAYS_STATUS_AVAILABLE = 32;

// DRUGS
export const DRUG_TYPES = {
	CAPSULE: 'capsule',
	RELIEVER: 'reliever',
	PREVENTER: 'preventer',
	TABLET: 'tablet',
	INJECTION: 'injection',
	INHALER: 'inhaler'
};

// UNITS
export const SUPPORTED_UNITS = {
	KG: 'kg',
	LBS: 'lbs',
	M: 'm',
	CM: 'cm',
	INCH: 'inch',
	FT: 'ft'
};

export const DEFAULT_UNITS = {
	WEIGHT: SUPPORTED_UNITS.KG,
	HEIGHT: SUPPORTED_UNITS.CM
};

export const WEIGHT_RANGE = {
	[SUPPORTED_UNITS.KG]: {
		min: 10,
		max: 300
	},
	[SUPPORTED_UNITS.LBS]: {
		min: 22,
		max: 662
	}
};

export const HEIGHT_RANGE = {
	[SUPPORTED_UNITS.M]: {
		min: 0.3,
		max: 2.5
	},
	[SUPPORTED_UNITS.CM]: {
		min: 30,
		max: 250
	},
	[SUPPORTED_UNITS.INCH]: {
		min: 11,
		max: 99
	},
	[SUPPORTED_UNITS.FT]: {
		min: 0.97,
		max: 8.21
	}
};

// EXAMINATIONS TYPES
export const EXAMINATIONS_TYPES = {
	CREATION_DATE: { type: 'date' },
	SPIROMETRY: { type: 'SPIROMETRY', examination: 'spirometry' },
	MANUAL_SPIROMETRY: { type: 'SPIROMETRY', examination: 'manual' },
	DAILY_CHECK: { type: 'DAILY_CHECK', examination: 'spirometry' },
	PULSE_OXIMETRY: { type: 'PULSE_OXIMETRY', examination: 'heart_rate' },
	PEAK_FLOW: { type: 'PEAK_FLOW', examination: 'peak_flow' },
	COPD_TEST: {
		type: 'COPD_TEST',
		examination: 'copd',
		label: 'BRONCHODILATOR_TEST'
	},
	COPD_FINAL: {
		type: 'COPD_FINAL',
		examination: 'copd',
		label: 'BRONCHODILATOR_TEST'
	},
	MEDICAL_NOTE: { type: 'MEDICAL_NOTE', examination: 'medical_note' },
	SVC: { type: 'SVC_TEST', examination: 'svc' },
	CALIBRATION_CHECK: { type: 'CALIBRATION_CHECK' }
} as const;

// ALERT TYPES
export const ALERT_TYPES = {
	ERROR: 'error',
	WARNING: 'warning',
	INFO: 'info',
	SUCCESS: 'success'
};

// MATERIAL UI DATEPICKER CALENDAR VIEWS
export const DATEPICKER_VIEWS = {
	YEAR: 'year',
	MONTH: 'month',
	DATE: 'date'
};

// GENDERS TYPES
export const GENDERS_TYPES = {
	MALE: 'M',
	FEMALE: 'F'
};

export const GENDERS_TYPES_SHORT = {
	M: 'global.male',
	F: 'global.female'
};

// USERS TYPES
export const USERS_TYPES = {
	PATIENT: 'patient',
	DOCTOR: 'doctor'
};

// Smoker
export const SMOKER_OPTIONS_VALUES = {
	NO: 'no',
	YES: 'yes',
	PASSIVE_SMOKER: 'passive',
	EX_SMOKER: 'ex_smoker'
} as const;

// FIELD TYPES
export const FIELD_TYPES = {
	CALENDAR: 'calendar',
	SELECT: 'select',
	CHECKBOX: 'checkbox',
	DATE: 'date',
	RADIO: 'radio',
	DESCRIPTION: 'description'
};

export const UNITS_TYPES = {
	L: {
		label: 'L',
		unit: 'L'
	},
	L_M: {
		label: 'L/min',
		unit: 'L/min'
	},
	L_S: { label: 'L/s', unit: 'L/s' },
	S: { label: 's', unit: 's' },
	PB: {
		label: 'of PB',
		labelLong: 'Of Personal Best',
		unit: '%'
	},
	PERCENTAGE: {
		unit: '%'
	},
	BPM: {
		label: 'bpm',
		unit: 'bpm'
	},
	PRED: {
		label: 'Pred.',
		unit: '%'
	},
	PERCENTILE: {
		label: 'Percentile',
		unit: ''
	},
	POST: {
		label: 'Post',
		unit: ''
	},
	PRE: {
		label: 'Pre',
		unit: ''
	},
	RESULT: {
		label: 'Result',
		unit: ''
	},
	M: {
		label: 'M',
		unit: 'M'
	},
	CM: {
		label: 'cm',
		unit: 'cm'
	},
	KG: {
		label: 'kg',
		unit: 'kg'
	},
	LBS: {
		label: 'lbs',
		unit: 'lbs'
	},
	INCH: { label: 'inch', unit: 'inch' },
	FT: { label: 'ft', unit: 'ft' }
} as const;

// TRANSITIONS
export const TRANSITIONS = {
	DURATIONS: {
		leavingScreen: theme.transitions.duration.leavingScreen,
		enteringScreen: theme.transitions.duration.enteringScreen
	},
	EASING: {
		easeOut: theme.transitions.easing.easeOut,
		easeInOut: theme.transitions.easing.easeInOut
	}
};
