import { ToggleRPC, useTranslations } from 'components/utilities';
import { bool, string } from 'prop-types';
import React from 'react';
import { Field, useFormState } from 'react-final-form';

import { PasswordChecker } from '../Adornments';
import InputAdornment from './InputAdornment';

const FormField = ({ isPasswordChecker = false, ...fieldProps }) => {
	const { submitting, values } = useFormState();
	const { t } = useTranslations();

	return (
		<>
			<ToggleRPC>
				{(props) => (
					<Field
						{...fieldProps}
						fullWidth
						//eslint-disable-next-line
						type={props.on ? 'text' : 'password'}
						label={t(fieldProps.label)}
						autoComplete="false"
						variant="outlined"
						disabled={submitting}
						required
						InputProps={{
							endAdornment: <InputAdornment {...props} />
						}}
					/>
				)}
			</ToggleRPC>
			{isPasswordChecker && (
				<PasswordChecker password={values[fieldProps?.name] || ''} />
			)}
		</>
	);
};

FormField.propTypes = {
	password: string,
	isPasswordChecker: bool
};

export default FormField;
