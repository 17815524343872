import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ReactContext } from 'utilities/ReactContext';

import { APP_PATHS } from '../../../components/routes/paths/app_paths';

export interface Credentials {
	email?: string;
	password?: string;
	twoFactorAuthenticationId?: string;
}

type Props = {
	children: JSX.Element | JSX.Element[];
};
interface ISignInContext {
	credentials: Credentials;
	setCredentials: (data: Credentials) => void;
}

const [useCtx, Ctx] = ReactContext.createContext<ISignInContext>();

export const INITIAL_STATE: Credentials = {
	email: '',
	password: '',
	twoFactorAuthenticationId: ''
};

const SignInProvider = ({ children }: Props) => {
	const history = useHistory();
	const location = useLocation();
	const [credentials, setCredentials] =
		React.useState<Credentials>(INITIAL_STATE);

	const handleSetCredentials = (data: Credentials) =>
		setCredentials((prev) => ({
			email: data?.email || prev.email,
			password: data?.password || prev.password,
			twoFactorAuthenticationId:
				data?.twoFactorAuthenticationId ||
				prev.twoFactorAuthenticationId
		}));

	React.useEffect(() => {
		if (!credentials?.email && location.pathname !== APP_PATHS.SIGN_IN) {
			history.push(APP_PATHS.SIGN_IN);
		}
	}, [credentials.email]);

	return (
		<Ctx.Provider
			value={{ credentials, setCredentials: handleSetCredentials }}
		>
			{children}
		</Ctx.Provider>
	);
};

export default SignInProvider;
export const useSignInContext = useCtx;
export const SignInContext = Ctx;
