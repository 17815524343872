import {
	AVAILABLE_LOCALES_API_URL,
	LANGUAGE_API_URL,
	LANGUAGES_GROUPS_PARAMS_API
} from 'helpers';
import services from 'services/services';

export const LanguagesRepository = {
	getLocales() {
		return services.get(AVAILABLE_LOCALES_API_URL);
	},
	getTranslations: (
		language,
		translationGroup = LANGUAGES_GROUPS_PARAMS_API
	) => {
		return services.get(
			`${LANGUAGE_API_URL}?${translationGroup}&locale=${language}`
		);
	}
};
