import { DATE_FORMAT, TIME_FORMAT } from 'helpers';
import { LocaleDate } from 'utilities';

import { IDateProps } from './Date';

export const getDateWithTimeCellValue = (
	date: IDateProps['date'],
	dateFormat: DeepValuesOf<typeof DATE_FORMAT> = DATE_FORMAT.DEFAULT,
	timeFormat: DeepValuesOf<typeof TIME_FORMAT> = TIME_FORMAT.DEFAULT
) =>
	new LocaleDate(date)
		.getDateWithTimeLabel(dateFormat, timeFormat)
		.dateFirst(' |');
