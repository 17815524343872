import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { FirstPage } from './FirstPage';
import { SecondPage } from './SecondPage';
import { useGetCachedForcedSpirometryReportData } from './useGetCachedForcedSpirometryReportData';

const PAGES = [FirstPage, SecondPage];
export const ForcedSpirometry = () => {
	const { examination } = useGetCachedForcedSpirometryReportData();
	const title = examination?.svc
		? 'report.type.spirometry_with_svc'
		: 'report.type.spirometry_without_svc';
	return (
		<>
			{PAGES.map((Page, index) => (
				<Page
					key={uuidv4()}
					title={title}
					pages={PAGES.length}
					pageCount={index + 1}
				/>
			))}
		</>
	);
};
