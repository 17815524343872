import { Series, SeriesOptionsType } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { ForcedSpirometryMeasurementModel } from 'models/Report/ForcedSpirometry';
import { SlowVitalCapacitySVCMeasurementModel } from 'models/Report/SlowVitalCapacitySVC';
import React from 'react';
import palette from 'theme/palette';
import { ReactContext } from 'utilities';

type Options = { disableHide: boolean };
interface IAttemptsProvider {
	flowVolumeGraphRef: React.RefObject<HighchartsReact.RefObject>;
	volumeTimeGraphRef: React.RefObject<HighchartsReact.RefObject>;
	activeMeasurementIndex: number;
	attemptsArray: { isBest: boolean }[];
	isBestIndex: number;
	measurements:
		| undefined
		| ForcedSpirometryMeasurementModel[]
		| SlowVitalCapacitySVCMeasurementModel[];
	setActiveMeasurementIndex: React.Dispatch<React.SetStateAction<number>>;
	setActiveGraphCurve: (
		newIndex: number,
		prevIndex: number,
		options?: Options
	) => void;
	showPreviewOfNewActiveGraphCurve: (newActiveIndex: number) => void;
	hidePreviewOfNewActiveGraphCurve: (newActiveIndex: number) => void;
}

type Props = {
	measurements: IAttemptsProvider['measurements'];
	children: CmpChildren;
};
const [useCtx, Ctx] = ReactContext.createContext<IAttemptsProvider>();

export const AttemptsProvider = ({ measurements, children }: Props) => {
	const [activeMeasurementIndex, setActiveMeasurementIndex] =
		React.useState<IAttemptsProvider['activeMeasurementIndex']>(0);
	const flowVolumeGraphRef = React.useRef<HighchartsReact.RefObject>(null);
	const volumeTimeGraphRef = React.useRef<HighchartsReact.RefObject>(null);

	const handleSetActiveGraphCurve: IAttemptsProvider['setActiveGraphCurve'] =
		(newActiveIndex, prevActiveIndex, options) => {
			if (newActiveIndex !== prevActiveIndex) {
				[flowVolumeGraphRef, volumeTimeGraphRef].forEach((graphRef) => {
					graphRef.current?.chart.series?.[newActiveIndex].show();
					!options?.disableHide &&
						graphRef.current?.chart.series?.[
							prevActiveIndex
						].hide();
				});
			}
		};

	const validateMouseEnterLeave = (newActiveIndex: number): boolean => {
		const measurement = measurements?.[newActiveIndex];
		if (
			measurement &&
			activeMeasurementIndex !== newActiveIndex &&
			!measurement?.isBest
		) {
			return true;
		}
		return false;
	};

	const previewOfNewActiveGraphCurve = (
		newActiveIndex: number,
		styles: SeriesOptionsType,
		callback?: (s: Series) => void
	) => {
		if (!validateMouseEnterLeave(newActiveIndex)) {
			return;
		}

		[flowVolumeGraphRef, volumeTimeGraphRef].forEach((graphRef) => {
			const serie = graphRef.current?.chart.series?.[newActiveIndex];
			serie?.update(styles);

			if (serie && callback) callback(serie);
		});
	};

	const showPreviewOfNewActiveGraphCurve: IAttemptsProvider['showPreviewOfNewActiveGraphCurve'] =
		React.useCallback(
			(newActiveIndex) => {
				previewOfNewActiveGraphCurve(
					newActiveIndex,
					{
						type: 'spline',
						lineWidth: 1,
						color: palette.gray.dark
					},
					(serie) => {
						serie.show();
					}
				);
			},
			[activeMeasurementIndex]
		);

	const hidePreviewOfNewActiveGraphCurve: IAttemptsProvider['hidePreviewOfNewActiveGraphCurve'] =
		React.useCallback(
			(newActiveIndex) => {
				previewOfNewActiveGraphCurve(
					newActiveIndex,
					{
						type: 'spline',
						lineWidth: 2,
						color: palette.blue.main
					},
					(serie) => {
						serie.hide();
					}
				);
			},
			[activeMeasurementIndex]
		);

	const attemptsArray: IAttemptsProvider['attemptsArray'] = React.useMemo(
		() =>
			new Array(measurements?.length).fill('').map((_, i) => ({
				isBest: measurements?.[i].isBest || false
			})),
		[]
	);

	const isBestIndex: IAttemptsProvider['isBestIndex'] = React.useMemo(
		() => attemptsArray.findIndex((v) => v.isBest === true),
		[]
	);

	const providerValues: IAttemptsProvider = {
		flowVolumeGraphRef,
		volumeTimeGraphRef,
		activeMeasurementIndex,
		attemptsArray,
		isBestIndex,
		measurements,
		setActiveMeasurementIndex,
		setActiveGraphCurve: handleSetActiveGraphCurve,
		hidePreviewOfNewActiveGraphCurve,
		showPreviewOfNewActiveGraphCurve
	};
	return <Ctx.Provider value={providerValues}>{children}</Ctx.Provider>;
};

export const useAttemptsContext = useCtx;
export const AttemptsContext = Ctx;
