import { PatientServices, useSettingsServices } from 'queries';
import { SETTING_NAMES } from 'queries/Users/Patient/helpers/common';

const useInitialValues = (patient) => {
	const SettingServices = useSettingsServices();

	const initialValues = Object.entries(patient).reduce(
		(acc, [fieldName, initValue]) => {
			const indexOfSettingName = SETTING_NAMES.findIndex(
				({ field }) => field === fieldName
			);
			if (indexOfSettingName !== -1) {
				const userSettingValue = SettingServices.findByName(
					SETTING_NAMES[indexOfSettingName].settingName
				).value[0];
				acc[fieldName] = userSettingValue;
			} else {
				acc[fieldName] = initValue;
			}
			return acc;
		},
		{}
	);

	return PatientServices.convertAdditionalInformationsInitialValues(
		initialValues
	);
};

export default useInitialValues;
