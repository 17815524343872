import {
	AUTH_CHANGE_USER_LANGUAGE_API_URL,
	CREATE_DOCTOR_ACCOUNT_API,
	MY_PROFILE_EDIT_USER_API,
	MY_PROFILE_USER_PARAM_KEY,
	MY_PROFILE_USER_RESOURCES_API
} from 'helpers';
import services from 'services/services';

export const DoctorRepository = {
	/**
	 * creates account with the role of doctor
	 * @param {*} payload request payload
	 * @returns Promise
	 */
	createAccount: (payload) =>
		services.post(CREATE_DOCTOR_ACCOUNT_API, payload),
	/**
	 * gets provided userID data
	 * @param {object} args request arguments
	 * @param {object} args.cancelToken request cancellation token
	 * @param {string} args.userId user ID
	 * @returns Promise
	 */
	get: ({ cancelToken }) =>
		services.get(MY_PROFILE_USER_RESOURCES_API, {
			cancelToken
		}),
	/**
	 * edits given userID data
	 * @param {object} payload request payload
	 * @param {string} userId edited user id
	 * @returns Promise
	 */
	edit: (payload, userId) =>
		services.put(getApiUrl(MY_PROFILE_EDIT_USER_API, userId), payload),
	/**
	 * changes current panel language and save it next to signed in user data
	 * @param {object} payload request payload
	 * @returns Promise
	 */
	changePanelLanguage: (payload) =>
		services.post(AUTH_CHANGE_USER_LANGUAGE_API_URL, payload)
};

function getApiUrl(API_ROUTE, userId) {
	return API_ROUTE.replace(MY_PROFILE_USER_PARAM_KEY, userId);
}
