import { Box } from '@material-ui/core';
import {
	ExaminationsTrendChartProvider,
	ExaminationsV2Provider,
	ExaminationsV2RefsProvider,
	TooltipProvider
} from 'components/context/ExaminationsV2';
import { ExaminationsV2Routes } from 'components/routes';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { ExaminationsList } from './ExaminationsList';
import { useStyles } from './styles';

const LIST_ORIENTATION_QUERY_PARAM = 'listOrientation';

const ExaminationsV2 = React.memo(() => {
	const location = useLocation();
	const classes = useStyles();
	const listOrientation = React.useMemo(
		() =>
			new URLSearchParams(location.search).get(
				LIST_ORIENTATION_QUERY_PARAM
			),
		[location.search]
	);

	const renderContent = React.useCallback(() => {
		switch (listOrientation) {
			case 'left':
				return (
					<>
						<ExaminationsList />
						<ExaminationsV2Routes />
					</>
				);
			case 'right':
				return (
					<>
						<ExaminationsV2Routes />
						<ExaminationsList />
					</>
				);
			default:
				return (
					<>
						<ExaminationsV2Routes />
						<ExaminationsList />
					</>
				);
		}
	}, [listOrientation]);

	return (
		<Box
			className={classes.flexContainer}
			position={'relative'}
			minHeight={100}
		>
			<ExaminationsV2RefsProvider>
				<TooltipProvider>
					<ExaminationsV2Provider>
						<ExaminationsTrendChartProvider>
							{renderContent()}
						</ExaminationsTrendChartProvider>
					</ExaminationsV2Provider>
				</TooltipProvider>
			</ExaminationsV2RefsProvider>
		</Box>
	);
});

export default ExaminationsV2;
