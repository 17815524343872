import { Box, Typography } from '@material-ui/core';
import { useTranslations } from 'components/utilities';
import { func, string } from 'prop-types';
import React from 'react';

import { useStyles } from './styles';

export const LegendItem = ({ shape: Shape, description }) => {
	const classes = useStyles();
	const { t } = useTranslations();

	return (
		<Box className={classes.legendElement} role="legendItem">
			<Box className={classes.shapeWrapper}>
				<Shape size={8} />
			</Box>
			<Typography className={classes.typography} component="span">
				{t(description)}
			</Typography>
		</Box>
	);
};

LegendItem.propTypes = {
	shape: func.isRequired,
	description: string.isRequired
};
