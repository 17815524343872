import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		padding: 0,
		paddingTop: 35,
		width: '100%',
		display: 'flex',
		'& > :last-child': {
			marginLeft: 10
		},
		'& > :first-child': {
			marginRight: 10
		}
	},
	button: {
		flex: 1
	}
}));
