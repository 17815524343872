import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%'
	},
	icon: {
		color: theme.palette.gray.medium
	}
}));
