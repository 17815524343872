import { alpha,makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
	root: ({ colorNumber, isMirror }) => ({
		backgroundColor: isMirror
			? alpha(theme.palette.primary.light, 0.2)
			: theme.palette.white,
		width: '100%',
		height: '100%',
		borderRadius: '5px',
		border: isMirror
			? `2px solid ${theme.palette.primary.main}`
			: 'initial',
		paddingLeft: isMirror ? 5 : 0,
		cursor: 'pointer',
		'&:hover': {
			'& > div': !isMirror && {
				backgroundColor: alpha(theme.calendar[colorNumber], 0.4)
			}
		}
	})
}));
