import { apiFormErrorsHandler } from 'helpers';
import { usePatientEditBasicInformations } from 'queries';

import { BASIC_FIELD_NAMES } from './helpers';

const useBasicInfo = () => {
	const { mutateAsync, reset, apiError } = usePatientEditBasicInformations(
		{},
		{ fieldsToOmit: [BASIC_FIELD_NAMES.EMAIL] }
	);

	const handleOnSubmit = (toggleEdit) => async (values) => {
		try {
			await mutateAsync(values);
			toggleEdit();
		} catch (error) {
			return apiFormErrorsHandler(error);
		}
	};

	return {
		submit: handleOnSubmit,
		reset,
		apiError
	};
};

export default useBasicInfo;
