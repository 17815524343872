import clsx from 'clsx';
import { ButtonProgress } from 'components/elements';
import React from 'react';

import { ValueLabel } from '../../../../Typography';
import { useStyles } from './styles';

type Props = {
	onClick: () => void;
	label: string;
	isLoading?: boolean;
	icon?: ({ className }: { className: string }) => JSX.Element;
	apiError?: ApiErrorState;
	className?: string;
};

export const ButtonCell = ({
	onClick,
	label,
	isLoading = false,
	icon: Icon,
	apiError = '',
	className = ''
}: Props) => {
	const classes = useStyles();
	return (
		<ButtonProgress
			className={clsx([className, classes.cellButton])}
			onClick={(e) => {
				e.stopPropagation();
				onClick();
			}}
			variant="text"
			isLoading={isLoading}
			loaderSize={15}
		>
			<ValueLabel value={label} className={classes.valueLabel} />
			{Icon ? <Icon className={classes.icon} /> : null}
			{apiError ? (
				<ValueLabel
					value={apiError as string}
					className={clsx([classes.errorMessage, 'errorMessage'])}
				/>
			) : null}
		</ButtonProgress>
	);
};
