import { addMonths } from 'date-fns';
import { COOKIES } from 'helpers/variables';
import Cookies from 'js-cookie';

import { COOKIE_CONFIG } from './helpers';

const get = () => Cookies.get(COOKIES.COOKIE_LANGUAGE);

const set = (lng) => {
	const now = new Date();
	const expirationDate = addMonths(now, 1);

	const config = { ...COOKIE_CONFIG, expires: expirationDate };

	Cookies.set(COOKIES.COOKIE_LANGUAGE, lng, config);
};

export default { get, set };
