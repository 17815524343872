import { useTranslations } from 'components/utilities';

import useTodayDate from './useTodayDate';

const CURRENT_YEAR_KEY = '%x';

const useCopyright = () => {
	const { t } = useTranslations();
	const { currentYear } = useTodayDate();
	const copyrightText = t({
		key: 'global.copyright_aiocare',
		replaceKeys: {
			[CURRENT_YEAR_KEY]: `${currentYear}`
		}
	});

	return { copyrightText };
};

export default useCopyright;
