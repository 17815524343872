import { useQuery } from 'components/utilities';
import { UseQueryOptionsProps } from 'components/utilities/hooks/react-query/useQuery';
import { CLINIC_DATA_QUERY_KEY } from 'helpers';
import { ClinicDataModel } from 'models/Clinic/Clinic';

import { ClinicRepository } from './repository';

export const useClinicData = (
	options: UseQueryOptionsProps<ClinicDataModel> = {}
) => {
	return useQuery(
		[CLINIC_DATA_QUERY_KEY],
		({ cancelToken }) => ClinicRepository.getData({ cancelToken }),
		options
	);
};
