import {
	useExaminationsV2Context,
	useExaminationsV2RefsContext
} from 'components/context/ExaminationsV2';
import { format } from 'date-fns';
import { DATE_FORMAT, EXAMINATIONS_TYPES } from 'helpers';
import _ from 'lodash';
import { ActiveExamination } from 'models/Examinations/common';
import {
	ExaminationsListConvertedItemModel,
	ExaminationsListItemModel
} from 'models/Examinations/ExaminationsList';
import { ExaminationsListServicesV2 } from 'queries';
import { createCreationDateListItem } from 'queries/Examinations/ExaminationsList/helpers';
import React from 'react';
import { getCreationDateFromExaminationListItem } from 'utilities/Examinations/common/common';

import {
	addElementToExaminationsListInIndex,
	isElementBeforeInTheSameDay,
	isLastActiveExaminationInDatesRange,
	isNextElementInTheSameDay
} from './useActiveExaminationActions.helpers';

export const useActiveExaminationActions = () => {
	const {
		store: examinationsListRef,
		scrollToExaminationsListItem,
		resetExaminationsVirtualList
	} = useExaminationsV2RefsContext((s) => s.examinationsListRef);

	const {
		store: contextExaminationsListData,
		setActiveExamination,
		setActiveExaminationIndex
	} = useExaminationsV2Context((s) => s.examinationsListData);

	const { store: examinationsDateRange } = useExaminationsV2Context(
		(s) => s.examinationsDateRange
	);
	const getNewActiveExamination = React.useCallback(
		(
			examinationsListData: ExaminationsListConvertedItemModel[],
			activeExamination: ActiveExamination
		): ActiveExamination => {
			const clonedActiveExamination = _.cloneDeep(activeExamination);

			if (clonedActiveExamination?.index === undefined) {
				const examinationListItemIndex =
					ExaminationsListServicesV2.getExaminationIndexFromList({
						examinationsList: examinationsListData,
						..._.omit(activeExamination, 'positionInList')
					});
				clonedActiveExamination.index = examinationListItemIndex;
			}
			if (examinationsListData.length > 0) {
				clonedActiveExamination.data = examinationsListData[
					clonedActiveExamination.index
				] as ExaminationsListItemModel;
			}
			return clonedActiveExamination;
		},
		[]
	);

	/**
	 * set active examination state and scroll to this examination in examinations list.
	 * @param activeExamination active examination object
	 * @param examinationsListData [examinationsListData=contextExaminationsListData] examinations list data
	 * @param forceUpdateSearchParams [forceUpdateSearchParams=false] forces to update search params with current active examination
	 * @param scrollToItem [scrollToItem=true] determine if examinations list should scroll to this examination
	 */
	const setActiveExaminationWithListItemPositioning = ({
		examinationsListData = contextExaminationsListData,
		activeExamination,
		forceUpdateSearchParams = false,
		scrollToItem = true
	}: {
		examinationsListData?: ExaminationsListConvertedItemModel[];
		activeExamination: ActiveExamination;
		forceUpdateSearchParams?: boolean;
		scrollToItem?: boolean;
	}): ActiveExamination => {
		const newActiveExamination = getNewActiveExamination(
			examinationsListData,
			activeExamination
		) as Required<ActiveExamination>;

		if (
			scrollToItem &&
			examinationsListRef.current &&
			examinationsListRef.current?.props?.height && Number(examinationsListRef.current.props.height) <
				newActiveExamination?.positionInList +
					newActiveExamination?.data?.height
		) {
			scrollToExaminationsListItem(newActiveExamination.index);
			resetExaminationsVirtualList();
		}

		setActiveExamination(
			{ ...newActiveExamination },
			forceUpdateSearchParams
		);
		return newActiveExamination;
	};

	/**
	 * This function removes the active examination from the examination list.
	 * @param {ExaminationsListConvertedItemModel[]} examinationsListData - The list of all examinations.
	 * @param {ActiveExamination & {index: number}} activeExamination - The active examination to be removed.
	 * @returns {ExaminationsListConvertedItemModel[]} The updated list of examinations after removing the active examination.
	 */
	const removeActiveExaminationIndexFromList = (
		examinationsListData: ExaminationsListConvertedItemModel[],
		activeExamination: ActiveExamination
	) => {
		const data = [...examinationsListData];
		if (
			activeExamination.index === undefined ||
			activeExamination.index === -1
		) {
			return data;
		}

		const activeExaminationIndex =
			ExaminationsListServicesV2.getExaminationIndexFromList({
				examinationsList: data,
				...activeExamination
			});

		if (activeExaminationIndex === -1) {
			return data;
		}

		setActiveExaminationIndex(activeExaminationIndex);

		// remove active element by its index
		data.splice(activeExaminationIndex, 1);

		// is only one examination in this day
		const isDateCreatedItemBefore =
			data?.[activeExaminationIndex - 1]?.type ===
			EXAMINATIONS_TYPES.CREATION_DATE.type;
		const isDateCreatedItemAfter =
			data?.[activeExaminationIndex]?.type ===
			EXAMINATIONS_TYPES.CREATION_DATE.type;
		if (isDateCreatedItemBefore && isDateCreatedItemAfter) {
			data.splice(activeExaminationIndex - 1, 1);
			return data;
		}

		// deleted examination was last item in the list
		// is last element creation date label
		const lastListElement = data?.[data.length - 1];
		const isLastElementCreationDateLabel =
			lastListElement.type === EXAMINATIONS_TYPES.CREATION_DATE.type;
		if (isLastElementCreationDateLabel) {
			data.pop();
			return data;
		}

		return data;
	};

	const addLastActiveExaminationToListData = (
		lastActiveExamination: ActiveExamination,
		examinationsListData: ExaminationsListConvertedItemModel[]
	): ExaminationsListConvertedItemModel[] => {
		let data = [...examinationsListData];

		// last active examination not defined;
		if (!lastActiveExamination.data || !lastActiveExamination.index) {
			return data;
		}

		const activeExaminationCreationDate =
			getCreationDateFromExaminationListItem({
				...lastActiveExamination.data
			});

		if (
			!isLastActiveExaminationInDatesRange(
				examinationsDateRange,
				activeExaminationCreationDate
			)
		) {
			return data;
		}

		const isNextTheSameDay = isNextElementInTheSameDay(
			data,
			lastActiveExamination.index,
			activeExaminationCreationDate
		);

		// determine if adding element is for new date section (element after and before has different days)
		// if yes - subtract 1 from index placement -> need to place element before different date section (before create date label)
		// if no - add element as it was exactly in the list.
		const indexPositioner = isNextTheSameDay ? 0 : 1;

		const isBeforeTheSameDay = isElementBeforeInTheSameDay(
			data,
			lastActiveExamination.index - indexPositioner,
			activeExaminationCreationDate
		);

		data = addElementToExaminationsListInIndex(
			data,
			lastActiveExamination.index - indexPositioner,
			lastActiveExamination.data
		);

		const isFirstItemInTheList = lastActiveExamination.index === 1;
		if (
			(isFirstItemInTheList && !isBeforeTheSameDay) ||
			(!isBeforeTheSameDay && !isNextTheSameDay)
		) {
			const createdDateListItem = createCreationDateListItem(
				format(activeExaminationCreationDate, DATE_FORMAT.CHART_RANGE)
			);
			data = addElementToExaminationsListInIndex(
				data,
				lastActiveExamination.index - indexPositioner,
				createdDateListItem
			);
		}
		return data;
	};

	return {
		setActiveExaminationWithListItemPositioning,
		removeActiveExaminationIndexFromList,
		addLastActiveExaminationToListData
	};
};
