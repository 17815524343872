import { makeStyles } from '@material-ui/styles';

import { FONT } from '../components/styles';

export const useCommonStyles = makeStyles(() => ({
	flexContainer: {
		display: 'flex',
		gap: 10,
		justifyContent: 'center',
		flexWrap: 'wrap'
	},
	flexColumnContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: 10,
		alignItems: 'center'
	},
	containerWithBorderMinWidth: {
		minWidth: 370
	},
	fullWidth: {
		width: '100%'
	},
	measurementsTitleContainer: {
		alignSelf: 'flex-start',
		paddingBottom: 10
	},
	title: {
		alignSelf: 'flex-start',
		...FONT
	},
	notesContainer: {
		display: 'flex',
		gap: 10,
		width: '100%',
		'& > :first-child': {
			flex: 1,
			height: 'auto',
			alignSelf: 'stretch'
		},
		'& > :last-child': {
			flex: 2
		}
	}
}));
