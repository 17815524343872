import { useTranslations } from 'components/utilities';
import { isBefore } from 'date-fns';
import { ALERT_TYPES } from 'helpers';
import React from 'react';

import { checkAppointmentStatus } from './helpers';

const DEFAULT_ALERT_TEXT = { title: '', message: '' };

const { ERROR, INFO } = ALERT_TYPES;

const INITIAL_ALERT_STATE = {
	show: false,
	type: ERROR,
	content: DEFAULT_ALERT_TEXT
};

const useAlert = () => {
	const [alert, setAlert] = React.useState(INITIAL_ALERT_STATE);
	const { tNoParser } = useTranslations();

	const handleAlertState = (start, duration, apiError) => {
		const { isOngoing, isEnded } = checkAppointmentStatus(start, duration);
		const title = 'schedule.appointment.cannot_edit';
		let message = '';

		if (apiError) {
			message = apiError;
		} else if (isOngoing) {
			message = tNoParser('schedule.appointment.is_ongoing');
		} else if (isEnded) {
			message = tNoParser('schedule.appointment.is_ended');
		}

		const show = apiError || isBefore(start, new Date());
		const type = show && !apiError ? INFO : ERROR;

		setAlert({
			show,
			type,
			content: {
				title,
				message
			}
		});
	};

	return { alert, setAlert: handleAlertState, manualSetAlert: setAlert };
};

export default useAlert;
