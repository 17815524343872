import { Steps } from './types';

export const isNextStepValid = (newStepIndex: number, steps: Steps): boolean =>
	newStepIndex < steps.length;

export const isPrevStepValid = (newStepIndex: number, steps: Steps): boolean =>
	newStepIndex < steps.length && newStepIndex >= 0;

export const isCustomStepValid = (stepIndex: number, steps: Steps): boolean =>
	stepIndex >= 0 && stepIndex < steps.length;
