import { addSeconds } from 'date-fns';
import { COOKIES } from 'helpers/variables';
import Cookie from 'js-cookie';

import { COOKIE_CONFIG } from './helpers';

function save(token) {
	const now = new Date();
	//eslint-disable-next-line
	const expirationDate = addSeconds(now, process.env.REACT_APP_TOKEN_REFRESH);

	const config = { ...COOKIE_CONFIG, expires: expirationDate };
	Cookie.set(COOKIES.COOKIE_JWT, token, config);
	Cookie.set(COOKIES.COOKIE_JWT_EXPIRATION_DATE, expirationDate, config);
}

function remove() {
	Cookie.remove(COOKIES.COOKIE_JWT);
	Cookie.remove(COOKIES.COOKIE_JWT_EXPIRATION_DATE);
}

function get() {
	return Cookie.get(COOKIES.COOKIE_JWT);
}

export default { save, get, remove };
