import { Box, Typography } from '@material-ui/core';
import { ReactComponent as ErrorIcon } from 'assets/icons/error_circle.svg';
import { ReactComponent as CheckedIcon } from 'assets/icons/ic_checked.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/ic_info.svg';
import { APP_PATHS } from 'components/routes/paths';
import { useTranslations } from 'components/utilities';
import { PANEL_ROLES } from 'helpers';
import { array, bool, object, oneOfType, string } from 'prop-types';

import { Alert } from '../Alerts';
import { DoctorContextActionButtons } from './DoctorContextActionButtons';
import { PatientContextActionButtons } from './PatientContextActionButtons';
import { useStyles } from './styles';

const Confirmation = ({
	title,
	description,
	secondaryDescription,
	path = APP_PATHS.SIGN_IN,
	buttonText = 'btn.done',
	errorMessage,
	errorClassName,
	role,
	isError = false,
	isInfo = false
}) => {
	const { t } = useTranslations();
	const classes = useStyles({ secondaryDescription });

	const renderContent = () => {
		if (errorMessage) {
			return (
				<Alert
					title=""
					message={errorMessage}
					severity={'error'}
					className={errorClassName}
				/>
			);
		}

		return (
			<>
				{isError ? (
					<ErrorIcon />
				) : isInfo ? (
					<InfoIcon style={{ width: 53, height: 53 }} />
				) : (
					<CheckedIcon />
				)}
				{title && (
					<Typography className={classes.title} variant="h5">
						{t(title)}
					</Typography>
				)}

				<Typography className={classes.desc} variant="body1">
					{t(description)}
				</Typography>
				{secondaryDescription && (
					<Typography
						className={classes.descSecondary}
						variant="body1"
					>
						{t(secondaryDescription)}
					</Typography>
				)}
			</>
		);
	};

	return (
		<Box className={classes.wrapper}>
			{renderContent()}
			{role &&
				(role === PANEL_ROLES.doctor ? (
					<DoctorContextActionButtons
						path={path}
						buttonText={buttonText}
					/>
				) : (
					<PatientContextActionButtons />
				))}
		</Box>
	);
};

Confirmation.propTypes = {
	role: string,
	title: string,
	description: oneOfType([string, object]),
	secondaryDescription: string,
	buttonText: string,
	errorClassName: string,
	errorMessage: oneOfType([string, array]),
	path: string,
	isError: bool,
	isInfo: bool
};

export default Confirmation;
