import React from 'react';

import { ComponentFadeInOut, Dialog } from '../../atoms';
import {
	ChildrenRenderProps,
	ComponentFadeInOutProps
} from '../../atoms/Transitions/ComponentFadeInOut/types';
import {
	ContentLayout,
	ModalDialogContent
} from './components/ModalDialogContent';
import { IActionButtons } from './components/ModalDialogContent/ContentLayout/ActionButtons/ActionButtons';
import { ITitleProps } from './components/ModalDialogContent/ContentLayout/Title';
import { ISuccessContentProps } from './components/ModalDialogContent/SuccessContent';
import { useStyles } from './styles';

export interface IModalDialogProps<T> {
	/**
	 * Determines if modal should be active (opened)
	 */
	open: boolean;
	children: (props: ChildrenRenderProps) => CmpChildren;
	/**
	 * Method to close modal
	 */
	close: () => void;
	/**
	 * callback invokes when modal closing transition finished
	 */
	onExited?: () => void;
	/**
	 * Content container class name
	 */
	classNameContent?: string;
	/**
	 * Modal root element max width
	 */
	maxWidth?: MuiWidthSize | number;
	/**
	 * Determines if backdrop event handler should be active.
	 * Backdrop click invokes modal close() function
	 */
	disableBackdropClick?: boolean;
	fullWidth?: boolean;
	/**
	 * Modal title props
	 */
	titleProps?: ITitleProps;
	/**
	 * Modal action buttons props
	 */
	actionButtonsProps?: IActionButtons<T>;
	/**
	 * Dialog content fade props
	 */
	fadeInOutProps?: Omit<ComponentFadeInOutProps, 'children'>;
	/**
	 * Determines if success content should be rendered
	 */
	isSuccess?: boolean;
	/**
	 * Success content props
	 */
	successProps?: ISuccessContentProps;
	/**
	 * Determines if dialog should render in full screen
	 */
	fullScreen?: boolean;
}

export const ModalDialog = React.forwardRef(
	<T extends object>(
		{
			open,
			close,
			children,
			onExited = () => {},
			classNameContent = '',
			maxWidth = 'md',
			disableBackdropClick = false,
			fullWidth = true,
			titleProps = {},
			actionButtonsProps = {},
			isSuccess = false,
			successProps = {} as ISuccessContentProps,
			fadeInOutProps,
			...rest
		}: IModalDialogProps<T>,
		ref
	) => {
		const classes = useStyles({ maxWidth });

		return (
			<Dialog
				keepMounted={false}
				ref={ref}
				maxWidth={typeof maxWidth === 'number' ? false : maxWidth}
				fullWidth={fullWidth}
				open={open}
				onClose={close}
				onExited={onExited}
				disableBackdropClick={disableBackdropClick}
				PaperProps={{
					classes: {
						root: classes.paperRoot
					}
				}}
				BackdropProps={{
					classes: {
						root: classes.backdrop
					}
				}}
				{...rest}
			>
				<ComponentFadeInOut {...fadeInOutProps}>
					{(props) =>
						isSuccess ? (
							<ModalDialogContent.Success
								{...successProps}
								close={() => {
									if (successProps.close) {
										successProps.close();
									}
									close();
								}}
							/>
						) : (
							<ContentLayout
								close={close}
								classNameContent={classNameContent}
								titleProps={titleProps}
								actionButtonsProps={{
									...actionButtonsProps,
									disableActionButtons:
										actionButtonsProps.disableActionButtons ??
										true
								}}
							>
								{children(props)}
							</ContentLayout>
						)
					}
				</ComponentFadeInOut>
			</Dialog>
		);
	}
);
