import { Typography as MuiTypography } from '@material-ui/core';
import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslations } from 'components/utilities';
import { DOSAGES } from 'helpers';
import { array, bool, object, string } from 'prop-types';
import React from 'react';

import { TimeOfDay } from '../components';
import { useStyles } from './styles';

const Dosage = ({
	dosageSchedule,
	days = [],
	taking,
	isTakeLabel = true,
	isDefaultStyle = false,
	component: Component = MuiTypography
}) => {
	const classes = useStyles();
	const { t } = useTranslations();

	const renderDays = () => {
		return days.map(
			(day) =>
				' ' +
				t(
					`patients.action_plan.create.medications.${day?.toLowerCase()}`
				)
		);
	};

	const takeLabel = isTakeLabel
		? t('patients.action_plan.create.medications.take')
		: '';

	const dosage = t(
		`patients.action_plan.create.medications.${dosageSchedule}`
	);
	const dosageLabel = isTakeLabel ? dosage?.toLowerCase() : dosage;

	const typographyClasses = isDefaultStyle
		? ['']
		: [classes.typography, classes.marginNone];

	const isDays = days.length !== 0 && dosageSchedule !== DOSAGES.EVERYDAY;
	return (
		<Box>
			<Component
				variant="body2"
				component="p"
				className={clsx(typographyClasses)}
			>
				{`${takeLabel} ${dosageLabel}`}
				{isDays && ':'}
				{isDays && renderDays()}
			</Component>
			<TimeOfDay
				taking={taking}
				className={clsx(typographyClasses)}
				component={Component}
			/>
		</Box>
	);
};

Dosage.propTypes = {
	dosageSchedule: string.isRequired,
	days: array.isRequired,
	taking: array.isRequired,
	isTakeLabel: bool,
	isDefaultStyle: bool,
	component: object
};

export default Dosage;
