import { getParameters } from './common';

export const getSelectedParameters = (options) => {
	const checkedOptions = getParameters(options);
	if (checkedOptions.length !== 0) {
		return checkedOptions
			.map(({ name }) => `parameters[]=${name}`)
			.join('&');
	}
	return 'parameters[]=0';
};
