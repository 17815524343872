import { ReactComponent as AioCareLogoTm } from 'assets/icons/aiocare-logo-tm.svg';
import clsx from 'clsx';
import React from 'react';

import { useStyles } from './styles';

type Props = {
	className?: string;
} & Record<string, unknown>;
const AioCarePanelLogo = ({ className, ...rest }: Props) => {
	const classes = useStyles();
	return (
		<AioCareLogoTm className={clsx([classes.logo, className])} {...rest} />
	);
};

export default AioCarePanelLogo;
