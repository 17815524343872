import { format } from 'date-fns';
import { capitalizeString } from 'helpers';
import { AppLanguage } from 'utilities';

export const HIGHCHARTS_CONTEXT_MENU_NAME = 'contextMenu';

const MONTH_YEARS_DATE = [
	'01/01/2020',
	'02/01/2020',
	'03/01/2020',
	'04/01/2020',
	'05/01/2020',
	'06/01/2020',
	'07/01/2020',
	'08/01/2020',
	'09/01/2020',
	'10/01/2020',
	'11/01/2020',
	'12/01/2020'
];

export const getChartsOptions = () => ({
	lang: {
		shortMonths: getDateLabelsTranslations()
	}
});

export const getDateLabelsTranslations = () => {
	const locale = new AppLanguage().getLocale();
	return MONTH_YEARS_DATE.map((item) =>
		capitalizeString(
			format(new Date(item), 'MMM', {
				locale
			})
		)
	);
};
