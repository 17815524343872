import { AuthRepository, DoctorRepository } from 'queries';
import * as types from 'store/types';
import { Cookies, queryClient } from 'utilities';

// ******************** SIGN IN ********************
export const saveSignInDataToRedux = (data) => (dispatch) => {
	dispatch({
		type: types.AUTH_USER_SUCCESS,
		payload: data
	});
};

export const setUserProfile = (data) => {
	return {
		type: types.AUTH_USER_PROFILE_SUCCESS,
		payload: data
	};
};

export const changeUserDefaultLanguage = (lng) => async (dispatch) => {
	try {
		dispatch({
			type: types.AUTH_SET_USER_LANGUAGE_LOADING
		});

		await DoctorRepository.changePanelLanguage({ language_code: lng });
		dispatch({
			type: types.AUTH_SET_USER_LANGUAGE,
			payload: lng
		});
	} catch (error) {
		const errorData = error?.response?.data;
		dispatch({
			type: types.AUTH_USER_LANGUAGE_ERROR,
			payload: errorData
		});
	}
	// Get response
};

// ************* CHANGE USER FIST NAME AND LAST NAME *************
export const changeUserFirstLastName = (payload) => async (dispatch) => {
	const { first_name: firstName, last_name: lastName } = payload;

	dispatch({
		type: types.AUTH_SET_USER_FIRST_LAST_NAME,
		payload: { firstName, lastName }
	});
};

// ******************** REFRESH TOKEN ********************
export const refreshAuthToken = () => async (dispatch) => {
	// Get response
	try {
		const {
			data: { token }
		} = await AuthRepository.refreshToken();

		Cookies.jwt.save(token);

		dispatch({
			type: types.AUTH_USER_REFRESH_TOKEN_SUCCESS,
			payload: token
		});
	} catch (error) {
		signOut()(dispatch);
	}
};

// ******************** SIGN OUT ********************
export const signOut = () => async (dispatch) => {
	try {
		// Dispatch an loading action
		dispatch({
			type: types.AUTH_SIGN_OUT_LOADING,
			payload: true
		});
		await AuthRepository.signOut();

		Cookies.auth.remove();
		Cookies.jwt.remove();

		queryClient.removeQueries();

		dispatch({
			type: types.AUTH_SIGN_OUT_SUCCESS,
			payload: true
		});
	} catch (error) {
		dispatch({
			type: types.AUTH_SIGN_OUT_ERROR,
			payload: true
		});
	}
};

// ******************** SIGN OUT ********************
export const signNotAuthOut = (authenticate) => async (dispatch) => {
	// Remove user data from cookies
	Cookies.auth.remove();
	Cookies.jwt.remove();

	authenticate &&
		dispatch({
			type: types.AUTH_SIGN_OUT_SUCCESS,
			payload: true
		});
};
