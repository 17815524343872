import { QUERY_SIDE_LIST_EXAMINATIONS_DATA } from 'helpers';

import useParams from '../../../utilities/ReactRouterDomHooks/useParams';

const useExaminationsListQueryKey = () => {
	const { patientId } = useParams();

	const queryKey: [typeof QUERY_SIDE_LIST_EXAMINATIONS_DATA, PatientIdParam] =
		[QUERY_SIDE_LIST_EXAMINATIONS_DATA, patientId];

	return queryKey;
};

export default useExaminationsListQueryKey;
