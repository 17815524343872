import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	header: {
		padding: '8px 0'
	},
	titleCell: {
		lineHeight: 1,
		paddingTop: '4px'
	},
	title: {
		display: 'flex',
		alignItems: 'center',
		marginTop: '-8px'
	},
	iconBackspaceCell: {
		padding: '0 8px 0 0',
		marginLeft: '-8px'
	},
	iconBackspace: {
		color: theme.palette.primary.main
	},
	iconButton: {
		padding: '7px'
	},
	iconClose: {
		fontSize: 24,
		color: theme.palette.black
	}
}));
