import { makeStyles } from '@material-ui/styles';
import theme from 'theme';

import { Props } from './Title';

const Variant = {
	primary: {
		textTransform: 'uppercase',
		fontWeight: 600,
		fontSize: '10.5px',
		lineHeight: '10.5px',
		color: theme.palette.primary.dark
	},
	secondary: {
		color: '#707070',
		fontWeight: 400
	}
};
export const useStyles = makeStyles<typeof theme, Partial<Props>>(() => ({
	title: ({ variant, textAlign }) => {
		let styles;
		switch (variant) {
			case 'secondary':
				styles = Variant.secondary;
				break;
			default:
				styles = Variant.primary;
		}
		return {
			...styles,
			marginBottom: 5,
			textAlign: textAlign ? textAlign : 'start'
		};
	}
}));
