import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { Title } from 'components/elements/atoms/ExaminationsV2';
import {
	Correctness,
	Repeatability
} from 'components/elements/organisms/ExaminationsV2';
import { CHART_MEASUREMENT_PARAMETER_OPTION_NAMES as PARAMETERS } from 'helpers/charts';
import { ForcedSpirometryMeasurementModel } from 'models/Report/ForcedSpirometry';
import { ForcedSpirometryReportModel } from 'models/Report/Reports';
import React from 'react';

import {
	ContainerWithBorder,
	DeviceInformation,
	GeneratePDFReportActionButton,
	Header,
	Questions,
	ReportPDFHistory,
	TechnicalErrors,
	TechnicalNote,
	ZScore
} from '../components';
import {
	AttemptsContext,
	AttemptsProvider,
	ExaminationDataProvider,
	ReportInterpretationNoteProvider
} from '../context';
import { useCommonStyles } from '../styles/useCommonStyles';
import { useCorrectnessStyles } from '../styles/useCorrectnessStyles';
import { AllMeasurements } from './AllMeasurements';
import { AttemptsArrowSelector } from './AttemptsArrowSelector';
import { ExaminationNote } from './ExaminationNote';
import { Graphs } from './Graphs';
import { QUESTIONS } from './helpers';
import { useStyles } from './styles';
import { useSpirometryFVCData } from './useSpirometryFVCData';

export const SpirometryFVC = () => {
	const commonClasses = useCommonStyles();
	const classes = useStyles();
	const examinationData = useSpirometryFVCData();

	return (
		<ExaminationDataProvider<ForcedSpirometryReportModel | undefined>
			data={examinationData}
		>
			<Header
				title="patients.examinations_v2.details.spirometry.title"
				createdDateTime={examinationData?.examinationCreateDateTime}
				refValue={examinationData?.referenceStandard}
			/>
			<Box
				className={clsx([
					commonClasses.flexContainer,
					classes.justify,
					commonClasses.fullWidth
				])}
			>
				<ZScore examinationData={examinationData} />
				<ContainerWithBorder
					className={commonClasses.containerWithBorderMinWidth}
				>
					<Correctness
						examination={examinationData}
						upperStatusFieldName={'fvcStatus'}
						bottomStatusFieldName={'fev1Status'}
						parametersLabels={[
							PARAMETERS.fvc.label,
							PARAMETERS.fev1.label
						]}
						useLocalTheme={useCorrectnessStyles}
					/>
				</ContainerWithBorder>
				<ContainerWithBorder
					className={commonClasses.containerWithBorderMinWidth}
				>
					<Repeatability
						examination={examinationData}
						useLocalTheme={useCorrectnessStyles}
					/>
				</ContainerWithBorder>
			</Box>
			<AttemptsProvider
				measurements={examinationData?.measurements || []}
			>
				<ContainerWithBorder
					className={clsx([
						commonClasses.flexColumnContainer,
						commonClasses.fullWidth
					])}
				>
					<Box className={commonClasses.measurementsTitleContainer}>
						<Title
							title="patients.examinations_v2.details.spirometry.all_measurements.title"
							className={commonClasses.title}
						/>
					</Box>
					<Graphs />
					<AttemptsArrowSelector />
					<AllMeasurements />
					<TechnicalErrors />
					<AttemptsContext.Consumer>
						{(context) => (
							<Questions
								questions={QUESTIONS}
								answers={
									(
										context?.measurements?.[
											context.activeMeasurementIndex
										] as ForcedSpirometryMeasurementModel
									)?.additionalInfo
								}
							/>
						)}
					</AttemptsContext.Consumer>
				</ContainerWithBorder>
			</AttemptsProvider>
			<ReportInterpretationNoteProvider>
				<Box className={commonClasses.notesContainer}>
					<TechnicalNote />
					<ExaminationNote />
				</Box>
				<ReportPDFHistory />
				<GeneratePDFReportActionButton />
			</ReportInterpretationNoteProvider>
			<DeviceInformation deviceData={examinationData?.device} />
		</ExaminationDataProvider>
	);
};
