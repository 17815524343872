import {
	useAppointmentFormContext,
	useScheduleCalendarContext
} from 'components/context';
import { DASH_PATHS, SCHEDULE_PATHS } from 'components/routes/paths';
import { differenceInMinutes } from 'date-fns';
import { CALENDAR_VIEWS_VARIANTS } from 'helpers';
import { useHistory } from 'react-router-dom';

const { MONTH } = CALENDAR_VIEWS_VARIANTS;
const { CREATE_APPOINTMENT } = SCHEDULE_PATHS;
const { SCHEDULE } = DASH_PATHS;

const useCalendarCell = ({ calendarView }) => {
	const history = useHistory();

	const {
		setSelectedDate,
		setDuration,
		setPatient,
		setSelectedAppointment,
		patientsList
	} = useAppointmentFormContext();
	const { setCreateFromGrid, unselectTimeGrid } =
		useScheduleCalendarContext();

	const handleAppointmentClick = ({ event: { extendedProps } }) => {
		const {
			appointment_id,
			patient_id,
			appointment_starts,
			appointment_ends
		} = extendedProps;
		const patient = patientsList.find(
			({ id: patientId }) => patientId === patient_id
		);
		const patientName = `${patient.firstName} ${patient.lastName}`;
		unselectTimeGrid();
		setCreateFromGrid(false);
		setPatient({ patient_id, patient_name: patientName });
		setSelectedDate(appointment_starts);
		setDuration(differenceInMinutes(appointment_ends, appointment_starts));
		setSelectedAppointment(extendedProps);
		history.push(`${SCHEDULE}/appointment/${appointment_id}/review`);
	};

	const handleSelect = (props) => {
		const { start, end } = props;
		// only for month view to prevent set time based on clicked area
		if (calendarView === MONTH) {
			// get selected Date as string
			const selectedDate = new Date(start).toDateString();
			// get today time to calculate nearest 15min time
			const todayTime = new Date().toLocaleTimeString();
			// create iso time based on selected date and today current time
			const date = new Date(selectedDate + ' ' + todayTime);
			// calculate nearest 15min time
			const roundedTime = 1000 * 60 * 15;
			const roundedUpDate = new Date(
				Math.ceil(date.getTime() / roundedTime) * roundedTime
			);
			// set state
			setSelectedDate(roundedUpDate);
			setCreateFromGrid(false);
		} else {
			setSelectedDate(start);
			setCreateFromGrid(true);
			const duration = Math.abs((end - start) / 60000);
			setDuration(duration);
		}
		setPatient({ patient_id: null, patient_name: null });
		history.push(CREATE_APPOINTMENT);
	};

	const handleAllowSelect = ({ start, end }) => {
		const dif = differenceInMinutes(end, start);
		return calendarView === MONTH || dif <= 60;
	};

	return {
		onSelect: handleSelect,
		onAllowSelect: handleAllowSelect,
		onAppointmentClick: handleAppointmentClick
	};
};

export default useCalendarCell;
