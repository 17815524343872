import { CHART_EXAMINATION_PARAMETERS_COLORS as COLORS } from 'helpers';

import { YAxis } from './YAxis';

export const TREND_CHART_ROLE = 'trend-chart';
export class SplineChart {
	constructor(data, chartName) {
		this.type = 'line';
		this.role = TREND_CHART_ROLE;
		this.animationLimit = Infinity;
		this.animation = {
			duration: 500
		};
		this.zoneAxis = 'x';
		this.showInNavigator = true;
		this.dataGrouping = {
			enabled: true
		};
		this.marker = {
			enabled: false,
			states: {
				hover: {
					enabled: false
				}
			}
		};
		this.id = chartName;
		this.name = chartName;
		this.color = COLORS[chartName];
		this.data = data;
		this.yAxis = YAxis.getSerieCorrespondingYAxisIndex(chartName);
		this.crosshair = false;
	}
}
