import { Box } from '@material-ui/core';
import _ from 'lodash';
import {
	arrayOf,
	bool,
	node,
	number,
	oneOfType,
	shape,
	string
} from 'prop-types';
import React from 'react';

import { Header, Scores } from './components';
import { useStyles } from './styles';

const Content = ({
	title,
	createDateTime,
	scores = null,
	isContentFlexEnabled = false,
	pdfReport,
	id,
	type,
	examinationType,
	children
}) => {
	const classes = useStyles();

	const flexClassName = isContentFlexEnabled ? classes.flex : '';
	return (
		<>
			<Box className={classes.root}>
				<Header
					title={title}
					createDateTime={createDateTime}
					pdfReport={pdfReport}
					id={id}
					type={type}
					examinationType={examinationType}
				/>
				<Box
					className={classes.content}
					data-testid="contentScoresWrapper"
				>
					<Box
						className={flexClassName}
						data-testid="contentChildrenWrapper"
					>
						{children}
					</Box>
					{scores && _.isObject(scores) && <Scores scores={scores} />}
				</Box>
			</Box>
		</>
	);
};

Content.propTypes = {
	id: oneOfType([string, number]),
	type: string.isRequired,
	examinationType: string,
	pdfReport: string,
	title: string.isRequired,
	createDateTime: string.isRequired,
	scores: shape({ fev1: string, fvc: string }),
	isContentFlexEnabled: bool,
	children: oneOfType([arrayOf(node), node]).isRequired
};

export default Content;
