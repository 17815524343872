import { useQuery } from 'components/utilities';
import { UseQueryOptionsProps } from 'components/utilities/hooks/react-query/useQuery';
import { GET_EXAMINATION_REPORT_NOTE_QUERY_KEY, uuidRegex } from 'helpers';
import { ExaminationNoteModel } from 'models/Examinations/ExaminationNote';
import { useParams } from 'utilities';

import { ExaminationsRepository } from '../repository';
import { ExaminationReportV2Repository } from './repository';

export interface IAdditionalData {
	examinationId?: IDParam;
	examinationType?: ExaminationTypeParam;
}
export const useExaminationNote = (
	options: UseQueryOptionsProps<ExaminationNoteModel> = {},
	{ examinationId, examinationType }: IAdditionalData = {}
) => {
	const { id: examinationIdParam, examinationType: examinationTypeParam } =
		useParams();

	const id = examinationId || examinationIdParam;
	const type = examinationType || examinationTypeParam;
	const query = useQuery(
		GET_EXAMINATION_REPORT_NOTE_QUERY_KEY(id),
		async () => {
			let translatedIdData = { signStr: id };
			if (!uuidRegex.test(id)) {
				translatedIdData =
					await ExaminationsRepository.translateIdToSignStr(id, type);
			}

			return ExaminationReportV2Repository.getNote({
				examinationId: translatedIdData.signStr,
				examinationType: type
			});
		},
		options
	);
	return query;
};
