export const DRUG_PURPOSE_FIELDS = [
	{
		value: 'reliever',
		label: 'patients.action_plan.create.medications.reliever'
	},
	{
		value: 'preventer',
		label: 'patients.action_plan.create.medications.preventer'
	}
];
