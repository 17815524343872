import { Confirmation, LoaderSpinner } from 'components/elements';
import { useTranslations } from 'components/utilities';
import { IDLE } from 'helpers/variables';
import { useAcceptLinkInvitation } from 'queries/Invitations/Patient';

export const AcceptanceStatus = () => {
	const { status: idle, error } = useAcceptLinkInvitation();
	const { t } = useTranslations();

	switch (idle) {
		case IDLE.LOADING:
			return (
				<LoaderSpinner
					color="primary"
					size={60}
					text={
						t(
							'patient.link.invitation.acceptance.loader_text'
						) as unknown as string
					}
					textSize={17}
				/>
			);
		case IDLE.SUCCESS:
			return (
				<Confirmation
					title="global.success"
					description="patient.link.invitation.acceptance.success.description"
				/>
			);
		case IDLE.ERROR:
			const message = {
				title: 'error_codes.something_went_wrong',
				description:
					'patient.link.invitation.acceptance.error.description',
				isError: true,
				isInfo: false
			};

			const is404Status = error.response
				? //eslint-disable-next-line
				  //@ts-ignore
				  error.response?.status === 404
				: false;

			if (is404Status) {
				message.title =
					'patient.link.invitation.acceptance.not_found.info_title';
				message.description =
					'patient.link.invitation.acceptance.not_found.description';
				message.isError = false;
				message.isInfo = true;
			}

			return (
				<Confirmation
					title={message.title}
					description={
						{
							key: message.description,
							replaceKeys: {
								['%1']: `<a
						target="_blank"
						rel="noopener noreferrer"
						href="mailto: support@aiocare.com"
						>support@aiocare.com</a>`
							}
						} as unknown as string
					}
					isError={message.isError}
					isInfo={message.isInfo}
				/>
			);
		default:
			return (
				<LoaderSpinner
					color="primary"
					size={60}
					text={
						t(
							'doctor_account_verification.loader_text'
						) as unknown as string
					}
					textSize={17}
				/>
			);
	}
};
