import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { ZScorePictogram } from 'components/elements';
import {
	ContainerWithBorder,
	Title
} from 'components/elements/atoms/ExaminationsV2';
import { ForcedSpirometryReportModel } from 'models/Report/Reports';
import React from 'react';

import { createZScoreObject } from './helpers';
import { useStyles } from './styles';

type Props = {
	examination: ForcedSpirometryReportModel | undefined;
	className?: string;
	disableBorderContainer?: boolean;
};
export const ZScore = ({
	className = '',
	examination,
	disableBorderContainer = false
}: Props) => {
	const classes = useStyles();
	const renderZScore = () => (
		<Box className={className}>
			<Title title="report.z_score.title" />
			{examination?.bestParameters ? (
				<ZScorePictogram
					//eslint-disable-next-line
					//@ts-ignore
					classNameContainer={classes.zScore}
					spirometryData={createZScoreObject(
						examination?.bestParameters
					)}
				/>
			) : (
				<></>
			)}
		</Box>
	);
	return disableBorderContainer ? (
		renderZScore()
	) : (
		<ContainerWithBorder className={clsx([className, classes.root])}>
			{renderZScore()}
		</ContainerWithBorder>
	);
};
