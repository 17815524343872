import { MenuItem } from '@material-ui/core';
import { useTranslations } from 'components/utilities';
import { FIELD_TYPES } from 'helpers';
import { array, bool, func, string } from 'prop-types';
import React from 'react';
import { Field, useFormState } from 'react-final-form';

import { SelectFieldAdapter } from '../../../../molecules';
import useSelectField from './useSelectField';

const SelectField = ({
	name,
	margin,
	label,
	options,
	required,
	disabled,
	onChange,
	yearFieldName
}) => {
	const { t } = useTranslations();
	const { submitting } = useFormState();
	const { newOptions } = useSelectField({ options, name, yearFieldName });

	return (
		<Field
			fullWidth
			component={SelectFieldAdapter}
			type={FIELD_TYPES.SELECT}
			label={t(label)}
			name={name}
			margin={margin}
			variant="outlined"
			autoComplete="false"
			disabled={submitting || disabled}
			required={required}
			inputOnChange={onChange}
		>
			{newOptions.map(({ item, value }) => (
				<MenuItem key={item} value={value}>
					{item}
				</MenuItem>
			))}
		</Field>
	);
};

SelectField.propTypes = {
	name: string.isRequired,
	yearFieldName: string,
	margin: string.isRequired,
	label: string.isRequired,
	options: array.isRequired,
	required: bool.isRequired,
	disabled: bool.isRequired,
	onChange: func
};

export default SelectField;
