import { HelmetTags } from 'components/elements';
import { lazyLoadDelay } from 'helpers';
import React from 'react';

import { PATIENTS_PATHS } from '../paths';

const PatientsTableView = React.lazy(() =>
	lazyLoadDelay(import('views/Patients/views/PatientsTable'))
);

export const PATIENTS_ROUTES = [
	{
		path: PATIENTS_PATHS.TABLE,
		component: PatientsTableView,
		helmet: <HelmetTags title="pages.patients.title" />
	}
];
