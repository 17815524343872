import { makeStyles } from '@material-ui/styles';
import theme from 'theme';
import palette from 'theme/palette';

export const useStyles = makeStyles<typeof theme, {disabled?: boolean, active?: boolean}>(() => ({
	typography:  ({ disabled, active }) => ({
		fontSize: '16px',
		lineHeight: '17.6px',
		letterSpacing: '0.15px',
		fontWeight: 600,
		cursor: disabled || !active ? 'auto' : 'pointer',
		'&:hover': {
			textDecoration: 
				disabled || !active ? 'auto' : 'underline'
		},
		opacity: ({ active }) => (active ? 1 : 0.5)
	}),
	disabled: {
		color: ({ disabled }) => disabled ? palette.gray.disabled : ""
	},
	error: {
		color: palette.error.darker
	},
	primary: {
		color: palette.primary.main
	}
}));
