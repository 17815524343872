import { Button, DialogActions } from '@material-ui/core';
import { useTranslations } from 'components/utilities';
import { bool, func, string } from 'prop-types';
import React from 'react';
import { useFormState } from 'react-final-form';

import { ButtonProgress } from '../../../../atoms';
import { useStyles } from './styles';

const Actions = ({
	close,
	submitText = 'btn.submit',
	loaderColor,
	submitting: isLoading,
	...rest
}) => {
	const { t } = useTranslations();
	const classes = useStyles();
	const { submitting } = useFormState();

	return (
		<DialogActions className={classes.root} {...rest}>
			<Button
				className={classes.button}
				disabled={submitting}
				type="button"
				variant="outlined"
				onClick={close}
				color="primary"
			>
				{t('btn.cancel')}
			</Button>
			<ButtonProgress
				loaderColor={loaderColor}
				classNameWrapper={classes.button}
				isLoading={isLoading || submitting}
				disabled={isLoading || submitting}
				type="submit"
				color="primary"
				variant="contained"
			>
				{t(submitText)}
			</ButtonProgress>
		</DialogActions>
	);
};

Actions.propTypes = {
	close: func.isRequired,
	submitText: string,
	loaderColor: string,
	submitting: bool
};

export default Actions;
