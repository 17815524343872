import React from 'react';

const useSelect = ({ initialValue, onChange, isValid }) => {
	const [value, setValue] = React.useState(initialValue);

	const handleChange = (event) => {
		const {
			target: { value }
		} = event;
		if (isValid ? isValid(value) : true) {
			setValue(value);
			onChange && onChange(event);
		}
	};

	return { value, changeValue: handleChange };
};

export default useSelect;
