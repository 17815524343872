import { SpirometryPreviewModel } from 'models/Examinations/allExaminationsTypes';
import { PersonalBestModel } from 'models/Examinations/PersonalBest';
import { ExaminationDataRowType } from 'utilities/Examinations/Parameters/Builder/Spirometry/SpirometryParametersDirector';
import { SpirometryTooltip } from 'utilities/Examinations/Tooltips';

import { convertCreateDateTime } from './common';
import { ITooltipData } from './ExaminationTooltipFactory';

type ReturnObj = ITooltipData & {
	examinationType: SpirometryPreviewModel['examinationType'];
};
export const getFev1TooltipData = (
	examinationData: SpirometryPreviewModel,
	personalBest: PersonalBestModel
): ReturnObj => {
	const spirometryTooltip = new SpirometryTooltip(
		examinationData,
		personalBest
	);

	const fev1TooltipData = spirometryTooltip.getFev1Tooltip();
	return {
		createDateTime: convertCreateDateTime(examinationData.createDateTime),
		examinationType: examinationData.examinationType,
		...fev1TooltipData
	};
};

export const getFvcTooltipData = (
	examinationData: SpirometryPreviewModel,
	personalBest: PersonalBestModel
): ReturnObj => {
	const spirometryTooltip = new SpirometryTooltip(
		examinationData,
		personalBest
	);

	const fvcTooltipData = spirometryTooltip.getFvcTooltip();
	return {
		createDateTime: convertCreateDateTime(examinationData.createDateTime),
		examinationType: examinationData.examinationType,
		...fvcTooltipData
	};
};

export const getPefTooltipData = (
	examinationData: SpirometryPreviewModel,
	personalBest: PersonalBestModel,
	chartName: ExaminationDataRowType
): ReturnObj => {
	const spirometryTooltip = new SpirometryTooltip(
		examinationData,
		personalBest,
		chartName
	);

	const pefTooltipData = spirometryTooltip.getPefTooltip();
	return {
		createDateTime: convertCreateDateTime(examinationData.createDateTime),
		examinationType: examinationData.examinationType,
		...pefTooltipData
	};
};

export const getTiffeneauTooltipData = (
	examinationData: SpirometryPreviewModel,
	personalBest: PersonalBestModel
): ReturnObj => {
	const spirometryTooltip = new SpirometryTooltip(
		examinationData,
		personalBest
	);

	const tiffeneauTooltipData = spirometryTooltip.getTiffeneauTooltip();
	return {
		createDateTime: convertCreateDateTime(examinationData.createDateTime),
		examinationType: examinationData.examinationType,
		...tiffeneauTooltipData
	};
};
