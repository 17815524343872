import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		width: '100vw',
		height: '100vh',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	content: {
		maxWidth: 540,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center'
	},
	logo: {
		marginBottom: 55
	},
	message: {
		textAlign: 'center',
		fontSize: '19px',
		lineHeight: '24.7px',
		fontWeight: 400,
		marginTop: 30
	}
}));
