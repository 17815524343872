import clsx from 'clsx';
import { Title } from 'components/elements/atoms/ExaminationsV2';
import { EMPTY_VALUE, EXAMINATION_CRITERIA } from 'helpers';
import React from 'react';

import { useAttemptsContext } from '../../context';
import { ContainerWithBorder } from '../ContainerWithBorder';
import { Label } from '../Typography';
import { ErrorLabel } from './ErrorLabel';
import { filterErrors } from './helpers';
import { useStyles } from './styles';

export type Criteria = ValuesOf<typeof EXAMINATION_CRITERIA>;

type Props = {
	criteria?: Criteria;
	className?: string;
};
export const TechnicalErrors = ({
	criteria = EXAMINATION_CRITERIA.ATS2019,
	className = ''
}: Props) => {
	const classes = useStyles();
	const { activeMeasurementIndex, measurements } = useAttemptsContext();

	const errorsToMap = filterErrors(
		measurements?.[activeMeasurementIndex]?.atsError || null,
		criteria
	);

	return (
		<ContainerWithBorder className={clsx([classes.root, className])}>
			<Title
				className={classes.font}
				title="patients.examinations.details.spirometry.errors"
			/>
			<>
				{!errorsToMap?.length || errorsToMap.length === 0 ? (
					<Label label={EMPTY_VALUE} />
				) : null}
				{errorsToMap.map((error) => (
					<ErrorLabel key={error} error={error} />
				))}
			</>
		</ContainerWithBorder>
	);
};
