import clsx from 'clsx';
import { TextFieldAdapter } from 'components/elements';
import { Placeholder } from 'components/elements';
import _ from 'lodash';
import { REPORT_NOTE_MAX_LENGTH } from 'models/Report/DownloadReport';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { useReportInterpretationNote } from 'utilities/Examinations/ExaminationDetails/common';

import { useReportInterpretationNoteContext } from '../../context';
import { ContainerWithBorder } from '../ContainerWithBorder';
import { Title } from '../Typography';
import { INITIAL_VALUES } from './helpers';
import { useStyles } from './styles';
import { validation } from './validation';

type Props = {
	fev1PredictedPercent?: number | null;
	percentileFev1Fvc?: number | null;
	className?: string;
};
export const ExaminationNote = ({
	fev1PredictedPercent = null,
	percentileFev1Fvc = null,
	className = ''
}: Props) => {
	const classes = useStyles();
	const {
		reportInterpretationNoteInputRef,
		isInterpretationNoteActive,
		focusInterpretationNoteInput,
		setValid,
		isValid
	} = useReportInterpretationNoteContext();
	const { reportInterpretationNote } = useReportInterpretationNote({
		percentileFev1Fvc,
		fev1PredictedPercent
	});
	const noteInitialValue = React.useMemo(() => {
		if (fev1PredictedPercent === null || percentileFev1Fvc === null)
			return '';
		return reportInterpretationNote + '\r\n';
	}, []);

	const handleChange = () => {
		const valueLength =
			reportInterpretationNoteInputRef?.current?.value?.length;

		if (_.isNumber(valueLength) && valueLength <= REPORT_NOTE_MAX_LENGTH) {
			if (!isValid) setValid(true);
		} else {
			setValid(false);
		}
	};

	return (
		<ContainerWithBorder className={clsx([classes.root, className])}>
			<Title title="report.report_interpretation.title" />
			<Placeholder
				active={!isInterpretationNoteActive}
				label="report.report_interpretation.placeholder"
				onClick={focusInterpretationNoteInput}
				classes={{
					root: classes.placeholderRoot,
					label: classes.placeholderText
				}}
			>
				<Form
					onSubmit={() => {}}
					initialValues={{
						...INITIAL_VALUES,
						reportNote: noteInitialValue
					}}
					validate={validation}
					render={({ handleSubmit }) => (
						<form onSubmit={handleSubmit} noValidate>
							<Field
								parse={(v) => v}
								inputOnChange={handleChange}
								className={classes.input}
								fullWidth
								component={TextFieldAdapter}
								type="textarea"
								multiline
								minRows={8}
								maxRows={10}
								maxLength={501}
								name="reportNote"
								variant="outlined"
								autoComplete="false"
								required
								inputRef={reportInterpretationNoteInputRef}
								InputProps={{
									onClick: (e: Event) => {
										e.stopPropagation();
									},
									classes: {
										input: classes.input
									}
								}}
							/>
						</form>
					)}
				/>
			</Placeholder>
		</ContainerWithBorder>
	);
};
