import {
	AdditionalInformations,
	additionalInformationsValidation,
	CardDataPreview,
	FormDialog
} from 'components/elements';
import { ToggleRPC } from 'components/utilities';
import { object } from 'prop-types';
import React from 'react';

import { ActionButtons } from '../components';
import { additionalInformationsFields } from './helpers';
import { useStyles } from './styles';
import useAdditionalInfo from './useAdditionalInfo';
import useInitialValues from './useInitialValues';

const AdditionalInfo = ({ patient }) => {
	const classes = useStyles();

	const { submit, reset, apiError } = useAdditionalInfo({
		patient
	});

	const initialValues = useInitialValues(patient);

	return (
		<ToggleRPC>
			{({ on, toggle }) => (
				<>
					<FormDialog
						fullWidth={false}
						keepMounted={false}
						disableBackdropClick
						disableActions
						title={'patients.basic_data.additional_info'}
						open={on}
						close={toggle}
						resetMutationState={reset}
						initialValues={initialValues}
						onSubmit={submit(toggle)}
						validation={additionalInformationsValidation}
						maxWidth="sm"
						apiError={apiError}
						contentSpacing={2}
						classes={classes}
						alignChildren={'flex-start'}
					>
						{() => (
							<AdditionalInformations
								actionButton={ActionButtons}
								height={546}
							/>
						)}
					</FormDialog>
					<CardDataPreview
						isBorderTop={false}
						data={initialValues}
						fields={additionalInformationsFields(initialValues)}
						title={'patients.basic_data.additional_info'}
						editButtonLabel="setting.preferences.edit_data.button"
						onEditButtonClick={toggle}
					/>
				</>
			)}
		</ToggleRPC>
	);
};

AdditionalInfo.propTypes = {
	patient: object.isRequired
};

export default AdditionalInfo;
