import { makeStyles } from '@material-ui/styles';

export const useBaseLayoutStyles = makeStyles(() => ({
	root: {
		padding: '24px 35px',
		position: 'relative'
	},
	content: {
		paddingTop: 14,
		position: 'relative',
		zIndex: 10,
		maxWidth: '1920px',
		margin: '0 auto',
		width: '100%'
	},
	portViewFullHeight: {
		height: 'calc(100vh - 142px)'
	}
}));
