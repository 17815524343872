import { EmployeeInvitation, HelmetTags } from 'components/elements';
import { DoctorServices } from 'queries';
import React from 'react';

import {
	DISABLED_FIELDS,
	FIELDS_SETTINGS,
	INITIAL_DOCTOR_SIGNUP_STATE
} from './helpers';

const DoctorSignUp = () => (
	<>
		<HelmetTags title="pages.create_doctor_account.title" />
		<EmployeeInvitation
			fieldSettings={FIELDS_SETTINGS}
			createAccountService={DoctorServices.convertDoctorFormsPayload}
			initialFormState={INITIAL_DOCTOR_SIGNUP_STATE}
			disabledFields={DISABLED_FIELDS}
			translationKeyBase="sign_up.doctor"
		/>
	</>
);

export default DoctorSignUp;
