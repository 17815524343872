import { Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import {
	arrayOf,
	bool,
	node,
	number,
	oneOfType,
	shape,
	string
} from 'prop-types';
import React from 'react';

import LoaderSpinner from '../Loader/LoaderSpinner';

const useStyles = makeStyles(() => ({
	cardContent: {
		position: 'relative'
	},
	loader: {
		position: 'absolute',
		top: 0,
		left: 0,
		height: '100%',
		background: 'rgb(255 255 255 / 81%)'
	}
}));

const CardSpinner = ({
	children,
	isLoading = false,
	showLoadingContent = true,
	loaderSize = 52,
	className: { classContent = '', classLoader = '', classCard = '' } = {},
	...rest
}) => {
	const classes = useStyles();

	const renderContent = () => {
		if (showLoadingContent) {
			return (
				<>
					{isLoading && (
						<LoaderSpinner
							size={loaderSize}
							classNameRoot={clsx(classes.loader, classLoader)}
						/>
					)}
					{children}
				</>
			);
		}
		return isLoading ? (
			<LoaderSpinner
				size={loaderSize}
				classNameRoot={clsx(classes.loader, classLoader)}
			/>
		) : (
			children
		);
	};

	return (
		<Card className={classCard}>
			<CardContent
				className={clsx([classContent, classes.cardContent])}
				{...rest}
			>
				{renderContent()}
			</CardContent>
		</Card>
	);
};

CardSpinner.propTypes = {
	children: oneOfType([arrayOf(node), node]).isRequired,
	className: shape({
		classContent: string,
		classLoader: string,
		classCard: string
	}),
	isLoading: bool.isRequired,
	showLoadingContent: bool,
	loaderSize: number
};

export default CardSpinner;
