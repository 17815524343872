import { lastDayOfMonth, startOfMonth } from 'date-fns';
import { CALENDAR_VIEWS_VARIANTS } from 'helpers/schedule/calendar';

const today = new Date();

export const INITIAL_STATE = {
	appointmentsDateRange: {
		startDate: startOfMonth(today),
		endDate: lastDayOfMonth(today)
	},
	selectedDate: today,
	calendarView: CALENDAR_VIEWS_VARIANTS.MONTH,
	isMonthView: true,
	dayMaxEvents: 3,
	isAppointmentReview: false,
	isCreateFromGrid: false
};
