import { makeStyles } from '@material-ui/styles';

import { FONT } from '../components/styles';

export const useCorrectnessStyles = makeStyles(() => ({
	root: {},
	font: {
		...FONT
	},
	stackedParametersContainer: {},
	stackedParametersLabel: {
		...FONT
	},
	dotsContainer: {
		flex: 'none'
	},
	dotsWrapper: {
		height: 55,
		width: 26,
		borderRadius: 100
	},
	dotClassName: {
		width: 20,
		height: 20
	},
	gradeContainer: {
		height: 20,
		width: 35,
		paddingTop: 7
	},
	gradeLabel: {
		...FONT
	},
	gradesContainer: {},
	scores: {
		justifyContent: 'center'
	},
	legendContainer: {
		justifyContent: 'center'
	}
}));
