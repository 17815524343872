import { useContext } from 'react';

// Import context
import { MessengerContext } from './MessengerProvider';

const useMessengerContext = () => {
	const context = useContext(MessengerContext);
	if (!context) {
		throw new Error(
			'useMessengerContext must be used within a MessengerProvider'
		);
	}
	return context;
};

export default useMessengerContext;
