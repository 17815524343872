import { Box, Menu as MuiMenu, MenuItem, Typography } from '@material-ui/core';
import { useTranslations } from 'components/utilities';
import { func, object } from 'prop-types';
import React from 'react';

import { REPORT_FORMATS } from './helpers';
import { useStyles } from './styles';

const Menu = ({ anchorEl, closeMenu, selectFormat, getFormatColor }) => {
	const classes = useStyles();
	const { t } = useTranslations();

	const renderDefault = () => (
		<Typography variant="body1" className={classes.defaultFormat}>
			{t('global.default')}
		</Typography>
	);

	return (
		<MuiMenu
			className={classes.menu}
			id="customized-menu"
			anchorEl={anchorEl}
			keepMounted
			open={Boolean(anchorEl)}
			onClose={closeMenu}
			getContentAnchorEl={null}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right'
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right'
			}}
		>
			{REPORT_FORMATS.map(({ label, isDefault }) => (
				<MenuItem
					key={label}
					className={classes.menuItem}
					onClick={() => selectFormat(label.toLowerCase())}
				>
					<Box
						className={classes.valueWrapper}
						style={{
							color: getFormatColor(label.toLowerCase())
						}}
					>
						{label} {isDefault && renderDefault()}
					</Box>
				</MenuItem>
			))}
		</MuiMenu>
	);
};

Menu.propTypes = {
	anchorEl: object,
	closeMenu: func.isRequired,
	selectFormat: func.isRequired,
	getFormatColor: func.isRequired
};
export default Menu;
