import { IconButton } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
	KeyboardArrowLeftIcon,
	KeyboardArrowRightIcon
} from 'assets/icons/commonMUIIcons';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginLeft: theme.spacing(2.5)
	}
}));

const TablePaginationActions = (props) => {
	const classes = useStyles();
	const theme = useTheme();
	const { page, onChangePage } = props;

	const handleBackButtonClick = (event) => {
		onChangePage(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onChangePage(event, page + 1);
	};

	const Icon =
		theme.direction === 'rtl'
			? KeyboardArrowRightIcon
			: KeyboardArrowLeftIcon;

	return (
		<div className={classes.root}>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				<Icon />
			</IconButton>
			<IconButton onClick={handleNextButtonClick} aria-label="next page">
				<Icon />
			</IconButton>
		</div>
	);
};

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onChangePage: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired
};

export default TablePaginationActions;
