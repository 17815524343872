import { Box, Switch as MuiSwitch } from '@material-ui/core';
import React from 'react';

type Props = {
	className?: string;
	checked: boolean;
	disabled?: boolean;
	onChange: (
		e: React.ChangeEvent<HTMLInputElement>,
		checked: boolean
	) => void;
};
const Switch = ({ className = '', checked, disabled, onChange }: Props) => {
	return (
		<Box className={className}>
			<MuiSwitch
				checked={checked}
				onChange={onChange}
				disabled={disabled}
			/>
		</Box>
	);
};

export default Switch;
