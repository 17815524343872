import React from 'react';

import { usePrePostContextActions } from '../../context/usePrePostContextActions';
import { AttemptNumberProps } from './AttemptNumber';

type Args = {
	activeMeasurementIndex: AttemptNumberProps['activeMeasurementIndex'];
	prePostIndexSetter: AttemptNumberProps['prePostIndexSetter'];
	type: AttemptNumberProps['type'];
	attemptNumber: AttemptNumberProps['attemptNumber'];
};

const useAttemptNumber = ({
	activeMeasurementIndex,
	prePostIndexSetter,
	type,
	attemptNumber
}: Args) => {
	const {
		setActiveGraphCurve,
		showPreviewOfNewActiveGraphCurve,
		hidePreviewOfNewActiveGraphCurve
	} = usePrePostContextActions({
		type,
		activeMeasurementIndex
	});

	const handleSetActive = () => {
		setActiveGraphCurve(attemptNumber, activeMeasurementIndex);
		prePostIndexSetter(attemptNumber);
	};

	const handleMouseEnter = React.useCallback(() => {
		showPreviewOfNewActiveGraphCurve(attemptNumber);
	}, []);

	const handleMouseLeave = React.useCallback(() => {
		hidePreviewOfNewActiveGraphCurve(attemptNumber);
	}, []);

	const isAttemptActive = React.useMemo(
		() => activeMeasurementIndex === attemptNumber,
		[activeMeasurementIndex]
	);

	return {
		setActive: handleSetActive,
		onMouseEnter: handleMouseEnter,
		onMouseLeave: handleMouseLeave,
		isAttemptActive
	};
};

export default useAttemptNumber;
