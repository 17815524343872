import { Box, TextField } from '@material-ui/core';
import { useTranslations } from 'components/utilities';
import { bool, func, string } from 'prop-types';
import React from 'react';

const Note = ({ classNameRoot, submitting, note = '', setNote }) => {
	const { tNoParser } = useTranslations();

	return (
		<Box className={classNameRoot}>
			<TextField
				fullWidth
				disabled={submitting}
				type="text"
				variant="outlined"
				margin="dense"
				label={tNoParser(
					'patients.action_plan.create.medications.notes'
				)}
				placeholder={tNoParser(
					'patients.action_plan.create.medications.notes_placeholder'
				)}
				value={note ?? ''}
				onChange={({ target: { value } }) => setNote(value)}
				multiline
				minRows={2}
				maxRows={6}
			/>
		</Box>
	);
};

Note.propTypes = {
	classNameRoot: string,
	submitting: bool,
	setNote: func.isRequired,
	note: string
};

export default Note;
