import { apiFormErrorsHandler } from 'helpers';
import { usePatientEditAdditionalInformations } from 'queries';

import { BASIC_FIELD_NAMES } from '../BasicInfo/helpers';

const useAdditionalInfo = () => {
	const { mutateAsync, reset, apiError } =
		usePatientEditAdditionalInformations(
			{},
			{ fieldsToOmit: [BASIC_FIELD_NAMES.EMAIL] }
		);

	const handleOnSubmit = (toggleEdit) => async (values) => {
		try {
			await mutateAsync(values);
			toggleEdit();
		} catch (error) {
			return apiFormErrorsHandler(error);
		}
	};

	return {
		submit: handleOnSubmit,
		reset: reset,
		apiError
	};
};

export default useAdditionalInfo;
