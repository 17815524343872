import { Box, IconButton } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import {
	NavigateBeforeIcon,
	NavigateNextIcon
} from 'assets/icons/commonMUIIcons';
import { PickersUtilsProvider } from 'components/elements';
import { NAVIGATION_DIRECTIONS } from 'helpers';
import React from 'react';

import { useStyles } from './styles';
import useDateControls from './useDateControls';

const { PREV, NEXT } = NAVIGATION_DIRECTIONS;

const DateControls = () => {
	const classes = useStyles();

	const {
		selectedDate,
		setSelectedDate,
		stepDateChange,
		openTo,
		views,
		format
	} = useDateControls();

	return (
		<Box className={classes.root}>
			<IconButton
				onClick={stepDateChange(PREV)}
				color="primary"
				className={classes.button}
			>
				<NavigateBeforeIcon />
			</IconButton>
			<PickersUtilsProvider>
				{() => (
					<DatePicker
						className={classes.calendar}
						autoOk
						variant="inline"
						openTo={openTo}
						views={views}
						format={format}
						value={selectedDate}
						onChange={setSelectedDate}
					/>
				)}
			</PickersUtilsProvider>
			<IconButton
				onClick={stepDateChange(NEXT)}
				color="primary"
				className={classes.button}
			>
				<NavigateNextIcon />
			</IconButton>
		</Box>
	);
};

export default DateControls;
