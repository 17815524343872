import { IDimensions } from './TooltipDimensionsFactory';

export class CopdSpirometryTooltipDimensions implements IDimensions {
	width;
	height;
	constructor() {
		this.width = 327;
		this.height = 175;
	}
}
