import { IDimensions } from './TooltipDimensionsFactory';

export class SpirometryTooltipDimensions implements IDimensions {
	width;
	height;
	constructor() {
		this.width = 327;
		this.height = 150;
	}
}
