import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	routesContainer: {
		flex: 1
	},
	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		gap: 20
	}
}));
