import { getObstructionResultValue } from './helpers';

const resultsVersions = {
	0: defaultResult,
	1: obturationResult
};

/**
 * get method of displaying bronchodilator tile based on examinationVersion
 * @param {Object} data - data containing examination version and values setters callbacks
 * @param {number} data.version - version of bronchodilator examination renderer
 * @param {Object} data.valuesSetterCallbacks - contains array of objects to be rendered
 * @returns the object containing values to be rendered and fullWidth flag
 *
 */
export const getBronchodilatorResults = ({
	version = 0,
	valuesSetterCallbacks,
	...rest
}) => resultsVersions[Number(version)](rest, valuesSetterCallbacks);

/**
 * create values if examinationVersion = 1
 * @param {Object} data - db data
 * @param {number} data.testStatusAfterPost - the status of the test after the POST test
 * @param {number} data.reversibleObturationStatus - reversibility flag
 * @param {number} data.obturationSeverity - severity level
 * @param {number} data.positiveResponseFlag - positive/negative bronchodilator test result
 * @param {Object} valuesSettersCallbacks - object containing methods to push into array values right object
 * @param {Object} valuesSettersCallbacks.setObturationValues - create array of obturation results objects
 * @returns the object containing values to be rendered and fullWidth flag
 */
function obturationResult(data, { setObturationValues }) {
	const {
		testStatusAfterPost,
		reversibleObturationStatus,
		obturationSeverity,
		positiveResponseFlag
	} = data;
	const { values, fullWidth } = resultRow(true);

	const obturation = getObstructionResultValue({
		testStatusAfterPost,
		reversibleObturationStatus,
		obturationSeverity
	});

	const obturationValues = setObturationValues(values, obturation, {
		testStatusAfterPost,
		positiveResponseFlag
	});

	return {
		fullWidth,
		values: obturationValues
	};
}

/**
 * create values if examinationVersion = 0
 * @param {Object} data - db data
 * @param {Object} valuesSettersCallbacks - object containing methods to push into array values right object
 * @param {Object} valuesSettersCallbacks.setDefaultValues - create array of test result object
 * @returns the object containing values to be rendered and fullWidth flag
 */
function defaultResult(data, { setObturationValues }) {
	const {
		reversibleObturationStatus,
		obturationSeverity,
		positiveResponseFlag
	} = data;

	const { values, fullWidth } = resultRow(true);

	const obturation = getObstructionResultValue({
		testStatusAfterPost: 1,
		reversibleObturationStatus: Number(reversibleObturationStatus),
		obturationSeverity
	});

	const obturationValues = setObturationValues(values, obturation, {
		testStatusAfterPost: 1,
		positiveResponseFlag
	});

	return {
		fullWidth,
		values: obturationValues
	};
}

/**
 * create default object containing values to be rendered and fullWidth flag
 * @param {bool} _fullWidth - determine if tile is fullWidth or not
 * @returns default object containing values to be rendered and fullWidth flag
 */
function resultRow(_fullWidth = false) {
	return {
		values: [],
		fullWidth: _fullWidth
	};
}
