import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { Container } from 'components/elements/atoms/ExaminationsV2';
import {
	Obstruction,
	TestResult
} from 'components/elements/organisms/ExaminationsV2';
import { BronchodilatorReportModel } from 'models/Report/Reports';
import React from 'react';
import palette from 'theme/palette';

import {
	DeviceInformation,
	ExaminationNote,
	GeneratePDFReportActionButton,
	Header,
	Questions,
	ReportPDFHistory,
	TechnicalNote
} from '../components';
import {
	ExaminationDataProvider,
	ReportInterpretationNoteProvider
} from '../context';
import { useCommonStyles } from '../styles';
import {
	ExaminationScores,
	Graphs,
	PrePostColumn,
	TypeColumnContainer
} from './components';
import { PrePostContext, PrePostProvider } from './context/PrePostProvider';
import { QUESTIONS } from './helpers';
import { useStyles } from './styles';
import { useCopdData } from './useCopdData';

export const Copd = () => {
	const classes = useStyles();
	const commonClasses = useCommonStyles();
	const examinationData = useCopdData();

	const answersData = examinationData?.post.survey?.response.reduce(
		(acc, next, i) => {
			acc[`copd_post_question_${i}`] = next.answers[0].value_id;
			return acc;
		},
		{}
	);

	return (
		<ExaminationDataProvider<BronchodilatorReportModel | undefined>
			data={examinationData}
		>
			<Header
				title="patients.examinations_v2.details.copd.title"
				createdDateTime={examinationData?.examinationCreateDateTime}
				refValue={examinationData?.referenceStandard}
			/>
			<Container
				className={clsx([commonClasses.flexContainer, classes.root])}
			>
				<TypeColumnContainer
					title="patients.examinations_v2.details.copd.pre_examination"
					isBorder
				>
					<ExaminationScores examinationData={examinationData?.pre} />
				</TypeColumnContainer>
				<TypeColumnContainer title="patients.examinations_v2.details.copd.post_examination">
					<ExaminationScores
						examinationData={examinationData?.post}
					/>
				</TypeColumnContainer>
			</Container>
			<PrePostProvider>
				<Graphs />
				<PrePostContext.Consumer>
					{(ctx) => (
						<Container
							className={clsx([
								commonClasses.flexContainer,
								classes.root
							])}
						>
							<PrePostColumn
								isBorder
								type="pre"
								/* eslint-disable */
								prePostIndexSetter={ctx?.setPreActiveIndex!}
								activeMeasurementIndex={ctx?.preActiveIndex!}
								measurements={
									examinationData?.pre.measurements!
								}
								bestParameters={
									examinationData?.pre?.bestParameters
								}
								/* eslint-enable */
							/>
							<PrePostColumn
								color={palette.orange.secondary}
								type="post"
								/* eslint-disable */
								prePostIndexSetter={ctx?.setPostActiveIndex!}
								activeMeasurementIndex={ctx?.postActiveIndex!}
								measurements={
									examinationData?.post.measurements!
								}
								bestParameters={
									examinationData?.post?.bestParameters
								}

								/* eslint-enable */
							/>
						</Container>
					)}
				</PrePostContext.Consumer>
			</PrePostProvider>
			<Questions questions={QUESTIONS} answers={answersData} />
			<Container className={classes.obstructionContainer}>
				<TestResult examination={examinationData} orientation="row" />
				<Obstruction examination={examinationData} orientation="row" />
			</Container>
			<ReportInterpretationNoteProvider>
				<Box className={commonClasses.notesContainer}>
					<TechnicalNote />
					<ExaminationNote />
				</Box>
				<ReportPDFHistory />
				<GeneratePDFReportActionButton />
			</ReportInterpretationNoteProvider>
			<DeviceInformation deviceData={examinationData?.device} />
		</ExaminationDataProvider>
	);
};
