import { useTodayDate } from 'components/utilities';
import { useForm, useFormState } from 'react-final-form';

import { getCurrentMonthDays } from '../helpers';

const useMonthItems = ({ yearFieldName, dayFieldName }) => {
	const { values } = useFormState();
	const { change } = useForm();
	const today = useTodayDate();
	const { currentMonth, currentYear } = today;

	const yearValue = values[yearFieldName];
	const dayValue = values[dayFieldName];
	const allMonths = new Array(12)
		.fill()
		.map((_, index) => ('0' + (index + 1)).slice(-2));

	const months =
		currentYear === yearValue
			? allMonths.slice(0, currentMonth)
			: allMonths;

	const handleMonthChange = ({ target: { value } }) => {
		const monthDays = getCurrentMonthDays({
			yearValue,
			monthValue: value,
			...today
		});

		const dayExists = dayValue && monthDays.includes(dayValue);

		if (!dayExists || value === '') {
			change(dayFieldName, '');
		}
	};

	const isMonthDisabled = !values[yearFieldName];

	return { months, isMonthDisabled, onMonthChange: handleMonthChange };
};

export default useMonthItems;
