import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslations } from 'components/utilities';
import { EXAMINATIONS_TYPES } from 'helpers';
import { SpirometryPreviewModel } from 'models/Examinations/allExaminationsTypes';
import React from 'react';

import { useStyles } from './styles';

interface IExaminationCopdTypeLabelProps {
	examinationType?: SpirometryPreviewModel['examinationType'];
	className?: string;
}

export const validateExaminationType = (
	examinationType: IExaminationCopdTypeLabelProps['examinationType']
) =>
	examinationType === EXAMINATIONS_TYPES.COPD_TEST.type ||
	examinationType === EXAMINATIONS_TYPES.COPD_FINAL.type;

const getExaminationTypeTranslationKey = (
	examinationType: IExaminationCopdTypeLabelProps['examinationType']
) => {
	switch (examinationType) {
		case EXAMINATIONS_TYPES.COPD_TEST.type:
			return 'global.examination_type.pre_bronchodilator_spirometry';
		case EXAMINATIONS_TYPES.COPD_FINAL.type:
			return 'global.examination_type.post_bronchodilator_spirometry';
	}
};

export const ExaminationCopdTypeLabel = ({
	examinationType,
	className = ''
}: IExaminationCopdTypeLabelProps) => {
	if (!validateExaminationType(examinationType)) {
		return null;
	}
	const { t } = useTranslations();
	const classes = useStyles();

	return (
		<Typography className={clsx([className, classes.label])}>
			{t(getExaminationTypeTranslationKey(examinationType))}
		</Typography>
	);
};
