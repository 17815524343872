import { Box } from '@material-ui/core';
import { Button } from 'components/elements';
import { useTranslations } from 'components/utilities';
import React from 'react';

import { PatientDeletion } from './PatientDeletion';
import { useStyles } from './styles';
type Props = { openEditForm: () => void; patientName: string };

const DataPreviewActionButtons = ({ openEditForm, patientName }: Props) => {
	const classes = useStyles();
	const { t } = useTranslations();

	return (
		<Box className={classes.root}>
			<Button onClick={openEditForm} className={classes.button}>
				{t('setting.preferences.edit_data.button')}
			</Button>
			<PatientDeletion patientName={patientName} />
		</Box>
	);
};

export default DataPreviewActionButtons;
