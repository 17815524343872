import clsx from 'clsx';
import { useTranslations } from 'components/utilities';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';

import { Typography } from '../Typography';
import { ParameterLabelProps } from '.';
import { useStyles } from './styles';

export const ParameterLabel = ({
	disableTranslation = false,
	children,
	label,
	className,
	textAlign = 'left'
}: ParameterLabelProps) => {
	const classes = useStyles({ textAlign });
	const { t } = useTranslations();
	return (
		<Typography
			className={clsx([classes.label, className, 'parameterLabel'])}
		>
			<>
				{disableTranslation ? ReactHtmlParser(label) : t(label)}
				{children}
			</>
		</Typography>
	);
};
