import { makeStyles } from '@material-ui/styles';

export const useGraphsStyles = makeStyles(() => ({
	root: {
		padding: 0,
		gap: 40,
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap'
	},
	graphContainer: {
		width: 'auto'
	}
}));
