const useMedicationTypes = ({ dosageForm, setDosageForm }) => {
	const handleSetMedicationType = (value) => {
		if (dosageForm !== value) {
			setDosageForm(value);
		}
	};

	const handleSetVariant = (value) =>
		dosageForm === value ? 'contained' : 'outlined';

	return {
		setMedicationType: handleSetMedicationType,
		setVariant: handleSetVariant
	};
};

export default useMedicationTypes;
