import React from 'react';
import { ReactContext } from 'utilities';

interface IReportInterpretationNoteProvider {
	reportInterpretationNoteInputRef: React.MutableRefObject<
		HTMLInputElement | undefined
	>;
	isInterpretationNoteActive: boolean;
	setInterpretationNoteActive: () => void;
	focusInterpretationNoteInput: () => void;
	isValid: boolean;
	setValid: React.Dispatch<React.SetStateAction<boolean>>;
}

const [useCtx, Ctx] =
	ReactContext.createContext<IReportInterpretationNoteProvider>();

type Props = {
	children: CmpChildren;
};

export const ReportInterpretationNoteProvider = ({ children }: Props) => {
	const reportInterpretationNoteInputRef = React.useRef<HTMLInputElement>();
	const [isInterpretationNoteActive, setInterpretationNoteActive] =
		React.useState<boolean>(false);
	const [isValid, setValid] = React.useState<boolean>(true);
	const handleSetInterpretationNoteActive = React.useCallback(() => {
		setInterpretationNoteActive(true);
	}, []);

	const focusInterpretationNoteInput = React.useCallback(() => {
		if (reportInterpretationNoteInputRef?.current) {
			handleSetInterpretationNoteActive();
			const inputContentLength =
				reportInterpretationNoteInputRef?.current.value.length;
			reportInterpretationNoteInputRef?.current.setSelectionRange(
				inputContentLength,
				inputContentLength
			);
			reportInterpretationNoteInputRef.current.focus();
		}
	}, []);

	return (
		<Ctx.Provider
			value={{
				reportInterpretationNoteInputRef,
				isInterpretationNoteActive,
				setInterpretationNoteActive: handleSetInterpretationNoteActive,
				focusInterpretationNoteInput,
				setValid,
				isValid
			}}
		>
			{children}
		</Ctx.Provider>
	);
};

export const useReportInterpretationNoteContext = useCtx;
