import { CancelToken } from 'axios';
import { MutationOptions, useMutation } from 'components/utilities';
import { PatientDeletionModel } from 'models/Patient/PatientDeletion';

import { PatientsRepository } from './repository';

export const useDeletePatient = (
	options: MutationOptions<
		PatientDeletionModel,
		TMyError,
		{ patientId: string }
	> = {}
) => {
	const mutation = useMutation(
		(args: { patientId: string }, cancelToken: CancelToken) =>
			PatientsRepository.deletePatient(args, cancelToken),
		options
	);
	return mutation;
};
