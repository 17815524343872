// -----------------  GET USER -----------------
export const AUTH_USER_LOADING = 'AUTH_USER_LOADING';
export const AUTH_USER_SUCCESS = 'AUTH_USER_SUCCESS';
export const AUTH_USER_ERROR = 'AUTH_USER_ERROR';

// -----------------  GET USER PROFILE -----------------
export const AUTH_USER_PROFILE_SUCCESS = 'AUTH_USER_PROFILE_SUCCESS';

// -----------------  SET USER LANGUAGE -----------------
export const AUTH_SET_USER_LANGUAGE = 'AUTH_SET_USER_LANGUAGE';
export const AUTH_USER_LANGUAGE_ERROR = 'AUTH_USER_LANGUAGE_ERROR';
export const AUTH_SET_USER_LANGUAGE_LOADING = 'AUTH_SET_USER_LANGUAGE_LOADING';

// -----------------  SET USER FIRST LAST NAME -----------------
export const AUTH_SET_USER_FIRST_LAST_NAME = 'AUTH_SET_USER_FIRST_LAST_NAME';

// -----------------  REFRESH TOKEN -----------------
export const AUTH_USER_REFRESH_TOKEN_SUCCESS =
	'AUTH_USER_REFRESH_TOKEN_SUCCESS';

// -----------------  CLEAR AUTHORIZATION -----------------
export const AUTH_USER_CLEAR = 'AUTH_USER_CLEAR';

// -----------------  UNAUTHORIZE - TOKEN INVALID -----------------
export const AUTH_SIGN_OUT_LOADING = 'AUTH_SIGN_OUT_LOADING';
export const AUTH_SIGN_OUT_SUCCESS = 'AUTH_SIGN_OUT_SUCCESS';
export const AUTH_SIGN_OUT_ERROR = 'AUTH_SIGN_OUT_ERROR';

export const SIGNOUT_REDIRECT = 'SIGNOUT_REDIRECT';
