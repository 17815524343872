import { ReactComponent as CapsuleIcon } from 'assets/icons/ic_capsule.svg';
import { ReactComponent as PillIcon } from 'assets/icons/ic_pill.svg';
import { ReactComponent as InhalerIcon } from 'assets/icons/ic_rescue.svg';
import { ReactComponent as InjectionIcon } from 'assets/icons/ic_strzykawka.svg';
import { ReactComponent as NoneIcon } from 'assets/icons/ic_x.svg';

export const MEDICATION_TYPES = {
	INHALER: 'inhaler',
	CAPSULE: 'capsule',
	INJECTION: 'injection',
	TABLET: 'tablet',
	UNKNOWN: 'unknown'
};

export const MEDICATION_TYPES_FIELDS = [
	{
		value: MEDICATION_TYPES.INHALER,
		icon: InhalerIcon
	},
	{
		value: MEDICATION_TYPES.CAPSULE,
		icon: CapsuleIcon
	},
	{
		value: MEDICATION_TYPES.INJECTION,
		icon: InjectionIcon
	},
	{
		value: MEDICATION_TYPES.TABLET,
		icon: PillIcon
	},
	{
		value: MEDICATION_TYPES.UNKNOWN,
		icon: NoneIcon
	}
];

export const DOSAGES = {
	WHEN_NEEDED: 'when_needed',
	EVERYDAY: 'everyday',
	SPECIFIC_DAYS: 'specific_days',
	EMERGENCY: 'emergency'
};

export const DOSAGE_FIELDS = [
	{
		text: 'patients.action_plan.create.medications.when_needed',
		value: DOSAGES.WHEN_NEEDED
	},
	{
		text: 'patients.action_plan.create.medications.everyday',
		value: DOSAGES.EVERYDAY
	},
	{
		text: 'patients.action_plan.create.medications.specific_days',
		value: DOSAGES.SPECIFIC_DAYS
	}
];

export const DOSAGE_FIELDS_BLOCKERS = [DOSAGES.WHEN_NEEDED, DOSAGES.EMERGENCY];
