import { useMutation } from 'components/utilities';
import { useCountries } from 'queries/Countries';

import { PatientRepository } from './repository';
import { PatientServices } from './services';

/**
 * create patient account
 * @param {object} options mutation options
 * @returns create patient account mutation
 */
const useCreate = (options = {}, { fieldsToOmit }) => {
	const { data: countries } = useCountries();

	const mutation = useMutation((values) => {
		const payload = PatientServices.createNewPatientPayloadConversion({
			values,
			countries: countries?.data || [],
			fieldsToOmit
		});
		return PatientRepository.create(payload);
	}, options);

	return mutation;
};

export default useCreate;
