export const WEEK_DAYS_FIELDS = [
	{
		text: 'patients.action_plan.create.medications.mon',
		value: 'Mon'
	},
	{
		text: 'patients.action_plan.create.medications.tue',
		value: 'Tues'
	},
	{
		text: 'patients.action_plan.create.medications.wed',
		value: 'Wed'
	},
	{
		text: 'patients.action_plan.create.medications.thu',
		value: 'Thu'
	},
	{
		text: 'patients.action_plan.create.medications.fri',
		value: 'Fri'
	},
	{
		text: 'patients.action_plan.create.medications.sat',
		value: 'Sat'
	},
	{
		text: 'patients.action_plan.create.medications.sun',
		value: 'Sun'
	}
];
