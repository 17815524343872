import { SvcPreviewModel } from 'models/Examinations/allExaminationsTypes';
import { SvcTooltip } from 'utilities/Examinations/Tooltips';

import { convertCreateDateTime } from './common';
import { ITooltipData } from './ExaminationTooltipFactory';

export const getIcTooltipData = (
	examinationData: SvcPreviewModel
): ITooltipData => {
	const svcTooltip = new SvcTooltip(examinationData);

	const icTooltipData = svcTooltip.getIcTooltip();

	return {
		createDateTime: convertCreateDateTime(examinationData.createDateTime),
		...icTooltipData
	};
};

export const getVcTooltipData = (
	examinationData: SvcPreviewModel
): ITooltipData => {
	const svcTooltip = new SvcTooltip(examinationData);

	const vcTooltipData = svcTooltip.getVcTooltip();

	return {
		createDateTime: convertCreateDateTime(examinationData.createDateTime),
		...vcTooltipData
	};
};
