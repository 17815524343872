import { passwordRegex } from 'helpers';

export default ({ password, password_confirmation }) => {
	const errors = {};

	if (!password) {
		errors.password = 'global.validation.password_required';
	} else if (password && !passwordRegex.test(password)) {
		errors.password = ' ';
	}

	if (!password_confirmation) {
		errors.password_confirmation =
			'global.validation.password_confirmation_required';
	}

	if (
		password &&
		password_confirmation &&
		password !== password_confirmation
	) {
		errors.password =
			'settings.clinics.form.validation.admin.passwords_are_not_same';
		errors.password_confirmation =
			'settings.clinics.form.validation.admin.passwords_are_not_same';
	}
	return errors;
};
