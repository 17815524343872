import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	cell: {
		width: 40,
		minWidth: 40,
		position: 'sticky',
		right: 0,
		background: 'white'
	},
	icon: {
		height: 20
	}
}));
