import { EXAMINATIONS_TYPES } from '../../../helpers/variables';

const {
	SPIROMETRY,
	COPD_FINAL,
	COPD_TEST,
	SVC,
	PEAK_FLOW,
	PULSE_OXIMETRY
} = EXAMINATIONS_TYPES;

export const UNCOMMON_EXAMINATION_TYPE = {
	[SPIROMETRY.type]: 'examinations',
	[COPD_FINAL.type]: 'copd-tests',
	[COPD_TEST.type]: 'copd-tests',
	[PULSE_OXIMETRY.type]: 'heart-rates',
	[PEAK_FLOW.type]: 'peak-flows',
	[SVC.type]: 'svc-tests'
} as const;
