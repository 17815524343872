import { APP_PATHS } from 'components/routes/paths';
import { History } from 'history';
import { TwoFactorAuthModel } from 'models/Auth/SignIn/signIn';

import { ISignInStrategy } from './ISignInStrategy';

export class TwoFactorAuth implements ISignInStrategy<TwoFactorAuthModel> {
	private history: History;
	constructor(history: History) {
		this.history = history;
	}

	public success = async (data: TwoFactorAuthModel, setCredentials) => {
		await setCredentials(data);
		this.history.push(APP_PATHS.SIGN_IN_2FA);
	};
}
