import React from 'react';

import { AttemptsArrowSelector as AttemptsArrowSelectorCmp } from '../../components';
import useAttemptsArrowSelector from '../../context/useAttemptsArrowSelector';
import { useStyles } from './styles';

export const AttemptsArrowSelector = () => {
	const classes = useStyles();
	const { getSelectorActions } = useAttemptsArrowSelector();

	return (
		<AttemptsArrowSelectorCmp
			{...getSelectorActions()}
			classNameRoot={classes.root}
		/>
	);
};
