import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between'
	},
	copdTypeLabel: {
		marginTop: 0,
		position: 'absolute',
		top: 0,
		left: 0,
		transform: 'translateY(-100%)'
	}
}));
