import { ForcedSpirometryReportModel } from 'models/Report/Reports';
import palette from 'theme/palette';

import { ExaminationDetailsProps } from './ExaminationDetails/ExaminationDetails';

export const getSecondPagePrePostData = (
	pre?: ForcedSpirometryReportModel,
	post?: ForcedSpirometryReportModel
): ExaminationDetailsProps[] => [
	{
		isChangePercentage: false,
		examination: pre || undefined,
		color: palette.report.blue.main,
		title: 'report.bronchodilator.pre_examination'
	},
	{
		isChangePercentage: true,
		examination: post || undefined,
		color: palette.report.green.main,
		title: 'report.bronchodilator.post_examination'
	}
];
