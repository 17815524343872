import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	bottomSection: {
		gap: 15,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		paddingTop: 0,
		paddingBottom: 0
	},
	correctnessContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		borderTop: '1px solid #E2E2E2'
	}
}));
