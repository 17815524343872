import { Box } from '@material-ui/core';
import { Typography as MuiTypography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
// import { DoneAllIcon, DoneIcon } from 'assets/icons/commonMUIIcons';
import clsx from 'clsx';
import { format } from 'date-fns';
import { TIME_FORMAT } from 'helpers';
import { arrayOf, bool, node, number, oneOfType, string } from 'prop-types';

const useStyles = makeStyles(({ palette }) => ({
	root: {
		alignSelf: ({ isUser }) => (isUser ? 'flex-end' : 'flex-start'),
		marginBottom: ({ isTimeLabelVisible, isNextUser, isUser }) =>
			!isNextUser && isUser ? 10 : isTimeLabelVisible ? 10 : 1,
		maxWidth: 300,
		padding: '6px 8px',
		borderRadius: 12,
		borderTopRightRadius: ({ isUser }) => (isUser ? 3 : 12),
		borderTopLeftRadius: ({ isUser }) => (!isUser ? 3 : 12),
		borderBottomRightRadius: ({
			isTimeLabelVisible,
			isUser,
			isNextUser
		}) => {
			if (!isUser || !isNextUser) {
				return 12;
			}
			return isTimeLabelVisible ? 12 : 3;
		},
		borderBottomLeftRadius: ({ isTimeLabelVisible, isUser }) => {
			if (isUser) {
				return 12;
			}
			return isTimeLabelVisible ? 12 : 3;
		},
		backgroundColor: ({ isUser }) =>
			isUser ? palette.primary.main : palette.chat.messageReceived
	},
	icon: {
		height: 12,
		width: 12,
		color: ({ isUser }) => (isUser ? palette.white : palette.primary.main),
		verticalAlign: 'middle'
	},
	timeParagraph: {
		color: ({ isUser }) => (isUser ? palette.white : palette.primary.main),
		paddingRight: 5
	},
	iconWrapper: {
		textAlign: 'end'
	}
}));

const ItemWrapper = ({
	// viewed,
	children,
	classNameItem = '',
	isUser,
	isNextUser,
	messageTimestamp,
	nextMessageTimestamp,
	timestampColorClassName = ''
}) => {
	const timeDifference = nextMessageTimestamp - messageTimestamp;
	const isCurrentUserAndNextPatient = isUser && !isNextUser;
	const isCurrentPatientAndNextUser = !isUser && isNextUser;
	// If time difference is bigger than 1 minutes, show time label
	const isTimeLabelVisible =
		isCurrentUserAndNextPatient || isCurrentPatientAndNextUser
			? true
			: timeDifference > 1 * 1000 * 60;

	const classes = useStyles({ isUser, isTimeLabelVisible, isNextUser });

	// const Icon = viewed ? DoneAllIcon : DoneIcon;

	return (
		<Box className={clsx([classNameItem, classes.root])}>
			{children}
			<Box className={classes.iconWrapper}>
				{isTimeLabelVisible && (
					<>
						<MuiTypography
							variant="caption"
							component="span"
							className={clsx([
								timestampColorClassName,
								classes.timeParagraph
							])}
						>
							{format(
								new Date(messageTimestamp),
								TIME_FORMAT.DEFAULT
							)}
						</MuiTypography>
						{/* <Icon
							className={clsx([
								timestampColorClassName,
								classes.icon
							])}
						/> */}
					</>
				)}
			</Box>
		</Box>
	);
};

ItemWrapper.propTypes = {
	classNameItem: string,
	timestampColorClassName: string,
	// viewed: bool.isRequired,
	isUser: bool.isRequired,
	isNextUser: bool.isRequired,
	children: oneOfType([arrayOf(node), node]).isRequired,
	messageTimestamp: number.isRequired,
	nextMessageTimestamp: number.isRequired
};

export default ItemWrapper;
