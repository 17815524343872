import { makeStyles } from '@material-ui/styles';
import theme from 'theme';
import palette from 'theme/palette';

export const useStyles = makeStyles<typeof theme, { isSelected: boolean }>(
	() => ({
		icon: {
			color: ({ isSelected }) =>
				isSelected ? palette.primary.main : 'unset'
		},
		iconButton: {
			padding: 6
		}
	})
);
