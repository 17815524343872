import { LoaderSpinner } from 'components/elements';
import React from 'react';

import AppointmentFormWrapper from '../AppointmentFormWrapper';
import ScheduleForm from '../ScheduleForm';
import TopBar from '../TopBar';
import useCreateAppointment from './useCreateAppointment';

const CreateAppointment = () => {
	const { initialValues, isPatientsLoaded } = useCreateAppointment();

	return (
		<AppointmentFormWrapper>
			<TopBar formTitle={'schedule.form.title_add'} />

			{initialValues && isPatientsLoaded ? (
				<ScheduleForm
					submitBtnText={'btn.save'}
					initialValues={initialValues}
				/>
			) : (
				<LoaderSpinner minHeight={'100%'} />
			)}
		</AppointmentFormWrapper>
	);
};

export default CreateAppointment;
