import { DEFAULT_Y_AXIS_COLOR } from '../graph/variables';
import { generateAxisGroup } from './helpers';

class Axis {
	constructor(label, axisNames) {
		this.axisNames = axisNames;
		this.title = {
			text: label,
			align: 'high',
			textAlign: 'left',
			rotation: '0',
			x: 10,
			y: -30,
			style: {
				color: DEFAULT_Y_AXIS_COLOR
			}
		};
		this.labels = {
			format: '{value:.2f}',
			align: 'left',
			x: 0,
			y: -6,
			style: {
				color: DEFAULT_Y_AXIS_COLOR
			}
		};
		this.showLastLabel = true;
		this.visible = false;
		this.allowDecimals = true;
		this.opposite = false;
	}

	getAxisWithGroup() {
		return {
			...this,
			...generateAxisGroup(this.axisNames)
		};
	}
}

export function createAxisWithGroup(label, axisNames) {
	return new Axis(label, axisNames).getAxisWithGroup();
}
