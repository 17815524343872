import { MutationOptions, useMutation } from 'components/utilities';
import {
	PatientLinkWithDoctorModel,
	PatientLinkWithDoctorRequestModel
} from 'models/Patient/PatientLink';

import { PatientsRepository } from './repository';

export const useLinkPatientWithDoctorMutation = (
	options: MutationOptions<
		PatientLinkWithDoctorModel,
		TMyError,
		PatientLinkWithDoctorRequestModel
	> = {}
) => {
	const mutation = useMutation(
		(payload: PatientLinkWithDoctorRequestModel) =>
			PatientsRepository.linkPatientAccountWithDoctor(payload),
		options
	);
	return mutation;
};
