import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	wrapper: {
		minWidth: 234,
		width: '100%',
		display: 'flex',
		padding: '8px 25px 8px 25px',
		fontWeight: 400,

		'& svg': {
			width: '15px',
			height: '15px'
		}
	},
	iconWrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		paddingRight: 15
	}
}));
