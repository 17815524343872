export class MyBlob {
	constructor(data, options = {}) {
		this.blob = new Blob([data], {
			type: 'application/octet-stream',
			...options
		});
	}
	static parse = (data, options) => {
		const blob = new Blob([data], {
			type: 'application/json'
		});
		const fileReader = new FileReader();
		fileReader.onload = (e) => {
			const data = JSON.parse(e?.target?.result);
			options?.onLoad?.(data, e);
		};
		fileReader.readAsText(blob);
	};
}
