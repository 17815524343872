import { makeStyles } from '@material-ui/styles';
import theme from 'theme';

export const useStyles = makeStyles<
	typeof theme,
	{ toolbarContainerHeight: number }
>(() => ({
	root: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		height: '100%'
	},
	table: {
		height: '100%'
	},
	tableRow: { textAlign: 'right' },
	tableHead: {
		'& tr > :first-child': {
			zIndex: 20
		}
	},
	container: {
		height: '100%',
		overflow: 'auto',
		contain: 'strict',
		borderRadius: 5
	},
	refreshDataLoader: {
		height: ({ toolbarContainerHeight }) =>
			`calc(100% - ${toolbarContainerHeight}px)`,
		top: ({ toolbarContainerHeight }) => toolbarContainerHeight,
		left: 0,
		bottom: 0,
		transform: 'initial'
	}
}));
