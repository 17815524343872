import { time24Regex, timeAmpmRegex } from 'helpers';

export const checkValidity = ({ value, ampm }) => {
	let isError = '';
	let errorMessage = '';

	if (ampm) {
		if (!timeAmpmRegex.test(value)) {
			isError = true;
			errorMessage = 'global.validation.hh_mm_am_format_required';
		} else {
			isError = false;
			errorMessage = '';
		}
	} else {
		if (!time24Regex.test(value)) {
			isError = true;
			errorMessage = 'global.validation.hh_mm_format_required';
		} else {
			isError = false;
			errorMessage = '';
		}
	}

	return { isError, errorMessage };
};
