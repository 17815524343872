import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	stepper: {
		padding: 0,
		background: 'transparent',
		width: ({ stepsAmount }) => stepsAmount * 25,
		'& .MuiStepLabel-iconContainer': {
			paddingRight: 0,
			height: 20,
			width: 20
		},
		'& .MuiSvgIcon-root': {
			width: 18,
			height: 18
		}
	},
	disabledStep: {
		padding: 0,
		'& .MuiStepLabel-iconContainer': {
			border: `1px solid ${theme.palette.border.dark}`,
			borderRadius: '50%'
		},
		'& .MuiSvgIcon-root': {
			'& > circle': {
				fill: 'transparent'
			}
		},
		'& .MuiStepIcon-text': {
			fill: theme.palette.text.gray
		}
	},
	activeStep: {
		padding: 0,
		'& .MuiStepLabel-iconContainer': {
			border: 'none',
			fill: theme.palette.primary.main
		},
		'& .MuiSvgIcon-root': {
			width: 20,
			height: 20,
			'& > circle': {}
		},
		'& .MuiStepIcon-text': {
			fill: theme.palette.white
		}
	}
}));
