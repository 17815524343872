import {
	CircularProgress,
	FormControl,
	FormHelperText,
	InputAdornment,
	InputLabel,
	Select as MuiSelect,
	SelectProps as MuiSelectProps
} from '@material-ui/core';
import clsx from 'clsx';
import { useSelectProps, useTranslations } from 'components/utilities';
import { ReactHMLParserType } from 'components/utilities/hooks/translations/types';
import React from 'react';

import { useStyles } from './styles';
import useSelect from './useSelect';

export interface SelectProps<Value> extends Omit<MuiSelectProps, 'children'> {
	initialValue: Value;
	children:
		| React.ReactNode
		| (({ value }: { value: Value }) => React.ReactNode);
	label?: ReactHMLParserType | null | string;
	className?: string;
	classNameSelect?: string;
	variant?: 'standard' | 'outlined' | 'filled';
	onChange?: (
		e: React.ChangeEvent<{ name?: string; value: unknown }>
	) => void;
	error?: boolean;
	isLoading?: boolean;
	disabled?: boolean;
	errorText?: string;
	helperText?: string;
	classes?: MuiSelectProps['classes'] & {
		selectLabel?: string;
		selectField?: string;
		helperText?: string;
	};
	minWidth?: number;
	isValid?: (value: Value) => boolean;
}

const Select = <Value,>({
	className = '',
	classNameSelect = '',
	variant = 'outlined',
	initialValue,
	onChange,
	children,
	error = false,
	isLoading = false,
	disabled = false,
	errorText = '',
	helperText = '',
	classes = {},
	minWidth = 150,
	isValid = undefined,
	value: customStateValue = null,
	...rest
}: SelectProps<Value>) => {
	const { t } = useTranslations();
	const localClasses = useStyles();
	const selectProps = useSelectProps();
	const { value, changeValue } = useSelect({
		initialValue,
		onChange,
		isValid
	});

	return (
		<FormControl
			variant={variant}
			error={error}
			className={clsx([localClasses.root, className])}
			style={{ minWidth: minWidth }}
		>
			{rest?.label && (
				<InputLabel
					disabled={disabled}
					id="select-field-label"
					className={classes?.selectLabel}
				>
					{rest.label}
				</InputLabel>
			)}
			<MuiSelect
				{...rest}
				labelId="select-field-label"
				id="select-field"
				value={customStateValue ?? value}
				onChange={changeValue}
				className={clsx([classNameSelect, classes?.selectField])}
				disabled={isLoading || disabled}
				MenuProps={{ ...rest?.MenuProps, ...selectProps }}
				endAdornment={
					isLoading && (
						<InputAdornment position="end">
							<CircularProgress size={20} />
						</InputAdornment>
					)
				}
			>
				{typeof children === 'function'
					? children({ value: customStateValue ?? value })
					: children}
			</MuiSelect>
			{(helperText || errorText) && (
				<FormHelperText className={classes?.helperText}>
					{t(errorText) || t(helperText) || errorText || helperText}
				</FormHelperText>
			)}
		</FormControl>
	);
};

export default Select;
