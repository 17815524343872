import { Box, Typography as MuiTypography } from '@material-ui/core';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/ic_arrow_down_parameter.svg';
import clsx from 'clsx';
import { useTranslations } from 'components/utilities';
import { object,string } from 'prop-types';
import React from 'react';

import { useStyles } from './styles';
import useGroup from './useGroup';

const Group = ({ label, icon: IconComponent, type }) => {
	const { isGroupActive, setActiveParameterGroup } = useGroup({
		type
	});
	const classes = useStyles({ isGroupActive });
	const { t } = useTranslations();

	return (
		<Box
			onClick={setActiveParameterGroup}
			component="li"
			className={classes.root}
		>
			<Box className={classes.wrapper}>
				<IconComponent
					data-testid="Group-icon"
					className={clsx(
						classes.icon,
						isGroupActive && classes.iconActive
					)}
				/>
				<MuiTypography
					className={classes.name}
					variant="overline"
					component="p"
				>
					{t(label)}
				</MuiTypography>
				{isGroupActive && (
					<ArrowDownIcon
						data-testid="Group-arrow-icon"
						className={classes.arrowDownIcon}
					/>
				)}
			</Box>
		</Box>
	);
};

Group.propTypes = {
	label: string.isRequired,
	icon: object.isRequired,
	type: string.isRequired
};

export default Group;
