import { COOKIES } from 'helpers';
import Cookies from 'js-cookie';

function getReportNote() {
	const reportNote = Cookies.get(COOKIES.COOKIE_REPORT.REPORT_NOTE);
	return reportNote ? reportNote : null;
}

function getCreationDateTime(): string | null {
	const reportDateTime = Cookies.get(
		COOKIES.COOKIE_REPORT.CREATION_DATE_TIME
	);
	return reportDateTime ? reportDateTime : null;
}

function getReportLanguage(): string | null {
	const reportLanguage = Cookies.get(COOKIES.COOKIE_REPORT.REPORT_LANGUAGE);
	return reportLanguage ? reportLanguage : null;
}

export const report = { getReportNote, getCreationDateTime, getReportLanguage };
