import { isValueValid } from '../value';

export const RESULTS_COLORS = ['success', 'warning', 'error', 'default'];

export const getExaminationStatusColor = (
	{
		status,
		parametersWithCustomColor = [],
		valueAdditionalValidation,
		...rest
	},
	determineCustomColor
) => {
	const { EXAMINATION_VALUES_KEYS, examinationValues } = rest;
	const colors = Object.values(EXAMINATION_VALUES_KEYS).reduce(
		(acc, { key }) => {
			if (
				!isValueValid(examinationValues[key], valueAdditionalValidation)
			) {
				acc[key] = null;
				return acc;
			}
			const colorStatus = getColorStatus(status, key);
			let color;
			if (
				colorStatus &&
				colorStatus > 0 &&
				!parametersWithCustomColor.includes(key)
			) {
				color = RESULTS_COLORS[colorStatus - 1];
			} else {
				color = determineCustomColor({ ...rest, key });
			}
			acc[key] = color;
			return acc;
		},
		{}
	);
	return colors;
};

function getColorStatus(status, key) {
	if (status === null || status === undefined) {
		return null;
	}

	if (typeof status === 'number') {
		return status;
	}

	if (typeof status === 'object' && !Array.isArray(status)) {
		return status[key];
	}

	throw new Error(`Wrong status type. Given: ${status}`);
}
