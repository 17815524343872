import { MenuItem, Typography } from '@material-ui/core';
import {
	Alert,
	ModalDialog,
	ModalDialogActionButtonsV2
} from 'components/elements';
import { useTranslations } from 'components/utilities';
import { IDLE } from 'helpers/variables';
import { bool, func, number, object, oneOfType, string } from 'prop-types';
import React from 'react';

import { useStyles } from './styles';
import useDeleteMenuItem from './useDeleteMenuItem';

const DeleteMenuItem = React.forwardRef(
	(
		{
			closeMenu,
			itemId,
			deletePromise,
			deleteBtnText = 'table.delete',
			deleteModalTextContent = 'table.delete.information',
			isDeleteModalDisableTitle = false,
			deleteModalDisableTitle = 'table.delete',
			successProps = {}
		},
		ref
	) => {
		const classes = useStyles();
		const { t } = useTranslations();

		const { idle, deleteItem, open, setOpen, setClose, onSuccess, error } =
			useDeleteMenuItem({
				deletePromise,
				closeMenu,
				itemId
			});

		const isLoading = idle === IDLE.LOADING;
		const isSuccess = idle === IDLE.SUCCESS;

		return (
			<>
				<MenuItem onClick={setOpen}>{t(deleteBtnText)}</MenuItem>
				<ModalDialog
					disableBackdropClick
					maxWidth="sm"
					titleProps={{
						title: isDeleteModalDisableTitle
							? ''
							: deleteModalDisableTitle,
						close: setClose,
						disableAction: isLoading
					}}
					successProps={{
						...successProps,
						isSuccess: successProps.isSuccess ?? isSuccess,
						close: onSuccess
					}}
					ref={ref}
					open={open}
					close={setClose}
					isSuccess={isSuccess}
					aria-labelledby="alert-dialog-slide-title"
					aria-describedby="alert-dialog-slide-description"
				>
					{() => (
						<>
							<Typography className={classes.contentLabel}>
								{t(deleteModalTextContent)}
							</Typography>
							{error && <Alert message={error} />}
							<ModalDialogActionButtonsV2
								confirm={{
									onClick: deleteItem,
									label: 'btn.delete',
									color: 'error',
									isLoading
								}}
								cancel={{
									onClick: setClose,
									variant: 'outlined'
								}}
							/>
						</>
					)}
				</ModalDialog>
			</>
		);
	}
);

DeleteMenuItem.propTypes = {
	closeMenu: func.isRequired,
	deletePromise: func.isRequired,
	itemId: number.isRequired,
	isDeleteModalDisableTitle: bool,
	deleteModalDisableTitle: string,
	deleteBtnText: string,
	deleteModalTextContent: oneOfType([string, object]),
	successProps: object
};

export default DeleteMenuItem;
