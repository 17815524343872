import { AutocompleteControlledField } from 'components/elements/molecules';
import { EMPLOYEES_INVITATION } from 'helpers/Forms/fieldNames/employeesInvitation';
import { array } from 'prop-types';
import React from 'react';
import { useForm, useFormState } from 'react-final-form';

const Specialization = ({ options, ...rest }) => {
	const {
		values: { specialization },
		submitting
	} = useFormState();

	const { change } = useForm();

	const renderOptionSelected = (option, value) => {
		return option.name === (value?.name || value);
	};

	const initialValue = () =>
		specialization ? { name: specialization } : null;

	const onInputChange = (value) => {
		change(EMPLOYEES_INVITATION.SPECIALIZATION_ID, value?.id);
		change(EMPLOYEES_INVITATION.SPECIALIZATION_NAME, value?.name);
	};

	const handleClose = (e, option) => {
		const isBlur = option === 'blur';
		if (specialization && isBlur) {
			const specializationId = options.find(
				(spec) => spec.name === specialization
			)?.id;
			change(EMPLOYEES_INVITATION.SPECIALIZATION_ID, specializationId);
		}
		if (!specialization && isBlur) {
			change(EMPLOYEES_INVITATION.SPECIALIZATION_NAME, '');
			change(EMPLOYEES_INVITATION.SPECIALIZATION_ID, '');
		}
	};

	return (
		<AutocompleteControlledField
			onInputChange={onInputChange}
			onClose={handleClose}
			initialValue={initialValue()}
			getOptionLabel={({ name }) => name}
			renderOption={({ name }) => name}
			getOptionSelected={renderOptionSelected}
			options={options}
			disabled={submitting}
			{...rest}
		/>
	);
};

Specialization.propTypes = {
	options: array.isRequired
};

export default Specialization;
