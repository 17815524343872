import { makeStyles } from '@material-ui/styles';
import theme from 'theme';

type UseStylesProps = {
	sticky?: boolean;
};

export const useStyles = makeStyles<typeof theme, UseStylesProps>(() => ({
	tableHead: {
		zIndex: 10,
		position: ({ sticky }) => (sticky ? 'sticky' : 'initial'),
		top: 0
	}
}));
