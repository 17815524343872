import { useLocation } from 'react-router-dom';

const useLocationQueryParams = () => {
	const { search } = useLocation();
	const queryParams = new URLSearchParams(search);

	const handleGetQueryParams = (query) => queryParams.get(query);

	return { queryParams, getQueryParam: handleGetQueryParams };
};

export default useLocationQueryParams;
