import { Box } from '@material-ui/core';
import {
	useExaminationItemEvents,
	useExaminationsV2Context,
	useTooltipContext
} from 'components/context/ExaminationsV2';
import { Button } from 'components/elements';
import { useTranslations } from 'components/utilities';
import { EXAMINATIONS_TYPES } from 'helpers';
import {
	CopdPreviewModel,
	SpirometryPreviewModel
} from 'models/Examinations/allExaminationsTypes';
import { ExaminationsListItemModel } from 'models/Examinations/ExaminationsList';
import { ExaminationsListServicesV2 } from 'queries';
import React from 'react';

import { useStyles } from './styles';

export const ShowMoreButton = ({
	active,
	examinationType
}: {
	active: boolean;
	examinationType: SpirometryPreviewModel['examinationType'];
}) => {
	const { t } = useTranslations();
	const classes = useStyles();
	const { examinationItemOnClick } = useExaminationItemEvents();
	const { store: examinationsListData } = useExaminationsV2Context(
		(s) => s.examinationsListData
	);
	const { store: examinationTooltip } = useTooltipContext(
		(s) => s.examinationTooltip
	);
	const handleClick = () => {
		if (examinationTooltip.listItemIndex) {
			const examination = examinationsListData[
				examinationTooltip.listItemIndex
			] as ExaminationsListItemModel;

			examinationItemOnClick({
				id: examination.id?.toString(),
				type: examination.type
			});
			return;
		}

		let activePointId = examinationTooltip.examinationId?.toString();
		let activePointType = examinationTooltip.examinationType;
		if (examinationType === EXAMINATIONS_TYPES.COPD_FINAL.type) {
			const index =
				ExaminationsListServicesV2.getExaminationIndexFromList({
					examinationsList: examinationsListData,
					id: examinationTooltip.examinationId?.toString(),
					type: examinationTooltip.examinationType
				});

			const copdListItem = examinationsListData[
				index + 2
			] as CopdPreviewModel;
			activePointId = `${copdListItem.id}`;
			activePointType = copdListItem.type;
		}

		if (activePointId && activePointType) {
			examinationItemOnClick({
				id: activePointId,
				type: activePointType
			});
		}
	};

	return (
		<Box width={'100%'} textAlign="end">
			<Button
				disabled={!active}
				variant="text"
				color="primary"
				size="small"
				className={classes.button}
				onClick={handleClick}
			>
				{t('global.see_more')}
			</Button>
		</Box>
	);
};
