import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	chartWrapper: ({ isFullscreenActive }) => ({
		position: 'relative',
		height: isFullscreenActive ? 'calc(100% - 35px)' : 550,
		'& > :last-child': {
			height: 'calc(100% - 35px)'
		}
	}),
	controllersContainer: ({ isFullscreenActive }) => ({
		display: 'flex',
		gap: 15,
		justifyContent: 'flex-end',
		backgroundColor: isFullscreenActive ? 'white' : 'inherit',
		paddingTop: isFullscreenActive ? 10 : 0,
		paddingRight: isFullscreenActive ? 10 : 0
	})
}));
