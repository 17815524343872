import {
	SPECIALIZATION_LANG_API_PARAM_KEY,
	SPECIALIZATIONS_API} from 'helpers';
import services from 'services/services';
import { Cookies } from 'utilities';

export const SpecializationsRepository = {
	/**
	 * gets every specializations in list
	 * @param {object} args function args
	 * @param {object.object} args.cancelToken request cancel token
	 * @returns Promise
	 */
	getAll: ({ cancelToken }) =>
		services.get(getApiUrl(SPECIALIZATIONS_API), { cancelToken })
};

function getApiUrl(API_ROUTE) {
	const lng = Cookies.language.get();
	return API_ROUTE.replace(SPECIALIZATION_LANG_API_PARAM_KEY, lng);
}
