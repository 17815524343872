import { TableCell } from '@material-ui/core';
import { EMPTY_VALUE } from 'helpers';
import { object } from 'prop-types';
import React from 'react';

import { useStyles } from './styles';

/**
 *
 * @param {any} cell Table cell data;
 * @returns {object} Table cell value if it exists;
 */

const DescriptionTableCell = ({ cell }) => {
	const classes = useStyles({ width: 300 });
	const value = cell.row.original.description;

	return (
		<TableCell
			className={classes.tableCell}
			key={cell.column.id}
			{...cell.getCellProps()}
		>
			{value || EMPTY_VALUE}
		</TableCell>
	);
};

DescriptionTableCell.propTypes = {
	cell: object.isRequired
};

export default DescriptionTableCell;
