import ProtectedDashboard from 'components/hoc/ProtectedDashboard';
import ProtectedUserLogged from 'components/hoc/ProtectedUserLogged';
import WithAnalyticServices from 'components/hoc/WithAnalyticServices';
import { lazyLoadDelay } from 'helpers';
import React from 'react';
import { Route } from 'react-router-dom';
import {
	AccountDeletionLandingPage as AccountDeletionLandingPageView,
	DoctorAccountVerification as DoctorAccountVerificationView,
	DoctorInvitation as DoctorInvitationView,
	DoctorSignUp as DoctorSignUpView,
	PatientInvitation as PatientInvitationView,
	PatientLinkInvitationAcceptance as PatientLinkInvitationAcceptanceView,
	ResetPasswordRequest as ResetPasswordRequestView,
	ResetUserPassword as ResetUserPasswordView
} from 'views';
import { Report } from 'views/PatientsDetails/views/ExaminationsV2';

import { ProtectedReport } from '../../hoc/ProtectedReport';
import DashboardRoutes from '../DashRoutes';
import { APP_PATHS, DASH_PATHS } from '../paths';

const SignInView = React.lazy(() => lazyLoadDelay(import('views/SignIn')));

const {
	SIGN_IN,
	SIGN_UP,
	RESET_PASSWORD,
	RESET_PASSWORD_LINK,
	NEW_DOCTOR,
	DOCTOR_ACCOUNT_VERIFICATION,
	PATIENT_INVITATION_ACCOUNT,
	ACCOUNT_DELETE,
	PATIENT_LINK_INVITATION_ACCEPT
} = APP_PATHS;

type RouteListItem = {
	routeRenderer:
		| typeof ProtectedUserLogged
		| typeof ProtectedDashboard
		| typeof Route;
	path: string | string[];
	component:
		| (() => JSX.Element)
		| React.LazyExoticComponent<React.ComponentType<() => JSX.Element>>;
};

export const ROUTES: RouteListItem[] = [
	{
		routeRenderer: Route,
		path: [DASH_PATHS.REPORT, DASH_PATHS.CALIBRATION_CHECK_REPORT],
		component: ProtectedReport({ component: WithAnalyticServices(Report) })
	},
	{
		routeRenderer: Route,
		path: ACCOUNT_DELETE,
		component: WithAnalyticServices(AccountDeletionLandingPageView)
	},
	{
		routeRenderer: Route,
		path: DOCTOR_ACCOUNT_VERIFICATION,
		component: WithAnalyticServices(DoctorAccountVerificationView)
	},
	{
		routeRenderer: Route,
		path: PATIENT_LINK_INVITATION_ACCEPT,
		component: WithAnalyticServices(PatientLinkInvitationAcceptanceView)
	},
	{
		routeRenderer: Route,
		path: PATIENT_INVITATION_ACCOUNT,
		component: WithAnalyticServices(PatientInvitationView)
	},
	{
		routeRenderer: ProtectedUserLogged,
		path: SIGN_IN,
		component: WithAnalyticServices(SignInView)
	},
	{
		routeRenderer: ProtectedUserLogged,
		path: RESET_PASSWORD,
		component: WithAnalyticServices(ResetUserPasswordView)
	},
	{
		routeRenderer: ProtectedUserLogged,
		path: RESET_PASSWORD_LINK,
		component: WithAnalyticServices(ResetPasswordRequestView)
	},
	{
		routeRenderer: ProtectedUserLogged,
		path: NEW_DOCTOR,
		component: WithAnalyticServices(DoctorInvitationView)
	},
	{
		routeRenderer: ProtectedUserLogged,
		path: SIGN_UP,
		component: WithAnalyticServices(DoctorSignUpView)
	},
	{
		routeRenderer: ProtectedDashboard,
		path: '',
		component: WithAnalyticServices(DashboardRoutes)
	}
];
