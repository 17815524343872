import {
	Button,
	DialogActions,
	DialogTitle,
	IconButton,
	Slide} from '@material-ui/core';
import { DeleteIcon } from 'assets/icons/commonMUIIcons';
import { ButtonProgress, Dialog } from 'components/elements';
import { ToggleRPC, useTranslations } from 'components/utilities';
import React from 'react';

import useDeleteAppointment from './useDeleteAppointment';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteAppointment = () => {
	const { t } = useTranslations();

	const { deleteAppointment, isLoading } = useDeleteAppointment();

	return (
		<ToggleRPC>
			{({ on, toggle }) => {
				return (
					<>
						<IconButton color="primary" onClick={toggle}>
							<DeleteIcon fontSize="small" />
						</IconButton>

						<Dialog
							disableBackdropClick={true}
							open={on}
							TransitionComponent={Transition}
							keepMounted
							onClose={toggle}
							aria-labelledby="alert-dialog-slide-title"
							aria-describedby="alert-dialog-slide-description"
						>
							<DialogTitle id="alert-dialog-slide-title">
								{t('schedule.modal.delete')}
							</DialogTitle>
							<DialogActions>
								<Button
									disabled={isLoading}
									onClick={toggle}
									color="primary"
								>
									{t('btn.cancel')}
								</Button>

								<ButtonProgress
									onClick={() => deleteAppointment(toggle)}
									color="primary"
									isLoading={isLoading}
									disabled={isLoading}
								>
									{t('btn.delete')}
								</ButtonProgress>
							</DialogActions>
						</Dialog>
					</>
				);
			}}
		</ToggleRPC>
	);
};

export default DeleteAppointment;
