import { convertCountryCodeToName, convertCountryNameToCode } from 'helpers';
import _ from 'lodash';
import { ClinicDataModel, ClinicRequestModel } from 'models/Clinic/Clinic';
import { getCachedCountryData } from 'queries/Countries/getters';
import { z } from 'zod';

import { INITIAL_CLINIC_VALUES } from '../../helpers/Forms/fieldNames/clinic';
import { CreatedClinicDataSchema } from '../../models/Clinic/Clinic';
export class ClinicService {
	constructor() {}

	static convertPayloadToFormData(payload: ClinicRequestModel) {
		const formData = new FormData();
		Object.entries(payload).forEach(([key, val]) => {
			if (val !== null && val !== undefined) {
				formData.append(key, val);
			}
		});
		return formData;
	}

	static async convertPayloadToBackendValid(
		payload: ClinicRequestModel
	): Promise<ClinicRequestModel> {
		let requestData = { ...payload };

		if (requestData.country !== null) {
			const countries = getCachedCountryData();
			const countryCode: string = convertCountryNameToCode(
				requestData.country,
				countries?.data
			);
			requestData.country = countryCode;
		}

		if (payload.image === '' || payload.image === null) {
			requestData = _.omit(requestData, 'image') as ClinicRequestModel;
		}

		return requestData;
	}

	static convertDataToFormInitial(
		clinicData: ClinicDataModel | undefined
	): ClinicRequestModel {
		if (clinicData === undefined || Object.keys(clinicData).length === 0) {
			return INITIAL_CLINIC_VALUES;
		}

		const existingClinicData = { ...clinicData } as z.infer<
			typeof CreatedClinicDataSchema
		>;

		const initialFormData = Object.entries(INITIAL_CLINIC_VALUES).reduce(
			(acc, [fieldName, initDefault]) => {
				acc[fieldName] = clinicData?.[fieldName] ?? initDefault;
				return acc;
			},
			{} as {
				[K in keyof typeof INITIAL_CLINIC_VALUES]: ClinicRequestModel[K];
			}
		);

		if (existingClinicData.country) {
			const countries = getCachedCountryData();
			initialFormData.country = convertCountryCodeToName(
				existingClinicData.country,
				countries?.data
			);
		}

		return initialFormData;
	}
}
