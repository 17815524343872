import { Box, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTranslations } from 'components/utilities';
import { object } from 'prop-types';
import React from 'react';
import { useForm } from 'react-final-form';

import { CountryFlag } from '../../atoms';

const useStyles = makeStyles(() => ({
	root: {
		position: 'relative',
		'& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child':
			{
				padding: ({ margin }) =>
					margin !== 'dense'
						? '9.5px 4px 9.5px 25px !important'
						: '5.5px 4px 3.5px 25px !important'
			}
	}
}));

const PhoneFieldAdapter = ({ input, meta, flagStyles, ...rest }) => {
	const { t } = useTranslations();
	const [countryCode, setCountryCode] = React.useState('');
	const classes = useStyles({ margin: rest.margin });
	const { change } = useForm();

	const {
		inputProps: { value },
		options
	} = rest;

	const error =
		meta.touched && (meta.error || meta.submitError) ? true : false;

	const errorText =
		meta.touched && (meta.error || meta.submitError)
			? t(meta.error) || meta.submitError
			: '';

	React.useEffect(() => {
		const countryData = options.find(
			({ phonePrefix }) => phonePrefix === value
		);
		countryData
			? setCountryCode(countryData.countryCode)
			: setCountryCode('');
		// Change input value
		change(input.name, value || null);
		// eslint-disable-next-line
	}, [value]);

	return (
		<Box className={classes.root}>
			<CountryFlag countryCode={countryCode} style={flagStyles} />
			<TextField
				{...input}
				{...rest}
				error={error}
				helperText={errorText}
			/>
		</Box>
	);
};

PhoneFieldAdapter.propTypes = {
	input: object.isRequired,
	meta: object.isRequired,
	flagStyles: object
};

export default PhoneFieldAdapter;
