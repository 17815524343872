import { Grid } from '@material-ui/core';
import { bool } from 'prop-types';
import React from 'react';
import { useFormState } from 'react-final-form';

import { TextFieldAdapter } from '../Inputs';
import FormField from './FormField';

const Password = () => {
	const {
		values: { password }
	} = useFormState();

	return (
		<>
			<Grid item sm={12} xs={12}>
				<FormField
					isPasswordChecker
					password={password}
					label={'sign_up.doctor.password'}
					name="password"
					component={TextFieldAdapter}
				/>
			</Grid>
			<Grid item sm={12} xs={12}>
				<FormField
					label={'sign_up.doctor.password_confirmation'}
					name="password_confirmation"
					component={TextFieldAdapter}
				/>
			</Grid>
		</>
	);
};

Password.propTypes = {
	submitting: bool.isRequired
};

export default Password;
