import { Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useMedicationsContext } from 'components/context';
import { DrugFields, DrugsField } from 'components/elements';
import { bool } from 'prop-types';
import React from 'react';
import { useFormState } from 'react-final-form';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	divider: {
		margin: `${theme.spacing(1)}px 0`
	}
}));

const Fields = ({ isEdit }) => {
	const classes = useStyles();
	const { id: drugId } = useParams();
	const { submitting, errors, submitFailed, initialValues } = useFormState();
	const { drug } = initialValues;
	const context = useMedicationsContext();
	const { setInitialFormData } = context;

	React.useEffect(() => {
		if (isEdit) {
			const amountArray = drug.taking.filter(({ amount }) => amount);
			setInitialFormData({ ...drug, taking: amountArray });
		}
		//eslint-disable-next-line
	}, [drugId]);

	return (
		<Grid container spacing={1}>
			<Grid item lg={12} xs={12}>
				<DrugsField name="drug.name" />
			</Grid>
			<Grid item lg={12} xs={12}>
				<Divider className={classes.divider} />
			</Grid>
			<Grid item lg={12} xs={12}>
				<DrugFields
					{...context}
					errors={errors}
					submitFailed={submitFailed}
					submitting={submitting}
				/>
			</Grid>
		</Grid>
	);
};

Fields.propTypes = {
	isEdit: bool.isRequired
};

export default Fields;
