import palette from 'theme/palette';

export default {
	root: {
		backgroundColor: palette.background.tableHeader,

		'& .MuiTableCell-stickyHeader': {
			backgroundColor: palette.background.tableHeader
		}
	}
};
