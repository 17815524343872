import { useControlledTableContextProvider } from 'components/context';
import React from 'react';

const useTable = ({
	tableProps,
	toolbarComponent,
	setManualRefetch,
	manualRefetch
}) => {
	// ----- VARIABLES ------ //
	const {
		getTableProps,
		getTableBodyProps,
		prepareRow,
		setGlobalFilter,
		headerGroups,
		page,
		state: { pageIndex, pageSize, sortBy, globalFilter }
	} = tableProps;

	// ----- GETTERS ------ //
	const getToolbarProps = () => ({
		toolbarComponent,
		setGlobalFilter,
		globalFilter
	});

	const getMutateOptions = () => ({
		pageIndex,
		pageSize,
		sortBy,
		globalFilter
	});

	const getPaginationProps = () => {
		return {
			gotoPage: tableProps.gotoPage,
			setPageSize: tableProps.setPageSize,
			nextPage: tableProps.nextPage,
			previousPage: tableProps.previousPage,
			pageOptions: tableProps.pageOptions,
			canPreviousPage: tableProps.canPreviousPage,
			canNextPage: tableProps.canNextPage,
			pageCount: tableProps.pageCount
		};
	};

	// ----- CONTEXT ------ //
	const { inputColumns, fetchData, filters, mutateAsync } =
		useControlledTableContextProvider();

	// Listen for changes in pagination etc and use the state to fetch our new data
	const fetchTableDataResources = () => {
		mutateAsync({
			options: {
				...getMutateOptions()
			},
			inputColumns,
			filters
		});
	};

	// ----- EFFECTS ------ //

	React.useEffect(() => {
		fetchTableDataResources();
		// eslint-disable-next-line
	}, [fetchData, pageIndex, pageSize, sortBy, globalFilter, filters]);

	React.useEffect(() => {
		if (manualRefetch) {
			setManualRefetch();
			fetchTableDataResources();
		}
		// eslint-disable-next-line
	}, [manualRefetch]);

	return {
		getPaginationProps,
		getToolbarProps,
		getMutateOptions,
		getTableProps,
		getTableBodyProps,
		prepareRow,
		headerGroups,
		page,
		pageIndex,
		pageSize
	};
};

export default useTable;
