import { produce } from 'immer';

import * as types from './types';

const reducer = (state, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case types.SET_OPTIONS:
				draft.options = action.payload;
				break;

			case types.SET_FILTERS:
				draft.filters = action.payload;
				break;

			case types.SET_TABLE_COLUMNS:
				draft.tableColumns = action.payload;
				break;

			case types.SET_INPUT_COLUMNS:
				draft.inputColumns = action.payload;
				break;

			default:
				return state;
		}
	});

export default reducer;
