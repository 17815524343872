import { Box, Typography as MuiTypography } from '@material-ui/core';
import { useTranslations } from 'components/utilities';
import { DRUG_TYPES, SIZES } from 'helpers/variables';
import { arrayOf, bool, node, oneOfType, string } from 'prop-types';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { Actions } from './components';
import { useStyles } from './styles';

const { RELIEVER, PREVENTER } = DRUG_TYPES;

const MedicationWrapper = ({
	children,
	name: medicationName,
	type,
	disableBorder,
	size = SIZES.SMALL,
	...rest
}) => {
	const convertedType = type?.toLowerCase();
	const classes = useStyles({ type: convertedType, disableBorder, size });
	const { t } = useTranslations();
	const { pathname } = useLocation();
	const isInhaler = RELIEVER === convertedType || PREVENTER === convertedType;
	const isEdit = !!String(pathname).match(/edit|create/);

	return (
		<Box className={classes.root}>
			<Box className={classes.flex}>
				<Box className={classes.container}>
					<MuiTypography
						variant="h5"
						component="span"
						className={classes.typography}
					>
						{medicationName}
					</MuiTypography>
					{isInhaler && (
						<Box className={classes.chip}>
							<MuiTypography className={classes.chipText}>
								{t(
									`patients.action_plan.create.medications.${convertedType}`
								)}
							</MuiTypography>
						</Box>
					)}
				</Box>
				{children}
			</Box>
			{isEdit && <Actions {...rest} />}
		</Box>
	);
};

MedicationWrapper.propTypes = {
	children: oneOfType([arrayOf(node), node]).isRequired,
	name: string,
	type: string,
	size: string,
	disableBorder: bool
};

export default MedicationWrapper;
