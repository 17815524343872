import { Link,Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(4)
	},
	link: {
		margin: '0 6px'
	}
}));

const Footer = ({ className, ...rest }) => {
	const classes = useStyles();

	return (
		<div {...rest} className={clsx(classes.root, className)}>
			<Typography variant="body1">
				&copy; Copyright:
				<Link
					component="a"
					className={classes.link}
					href="https://proexe.co/"
					target="_blank"
				>
					Proexe
				</Link>
				2020
			</Typography>
		</div>
	);
};

Footer.propTypes = {
	className: PropTypes.string
};

export default Footer;
