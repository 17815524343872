import { ExaminationsListConvertedItemModel } from 'models/Examinations/ExaminationsList';
import { useQueryClient } from 'react-query';
import { QueryState } from 'react-query/types/core/query';

import useExaminationsListQueryKey from './useExaminationsListQueryKey';

type TQueryState = QueryState<ExaminationsListConvertedItemModel[]> | undefined;
interface ReturnObj {
	examinationsListData: ExaminationsListConvertedItemModel[] | null;
	queryState: TQueryState;
}

const useGetExaminationsListFromCache = (): ReturnObj => {
	const queryClient = useQueryClient();
	const queryKey = useExaminationsListQueryKey();

	const examinationsListData: ExaminationsListConvertedItemModel[] | null =
		queryClient.getQueryData(queryKey) ?? null;

	const queryState: TQueryState = queryClient.getQueryState(queryKey);

	return { examinationsListData, queryState };
};

export default useGetExaminationsListFromCache;
