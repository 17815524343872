import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	actionButtonsContainer: {
		paddingTop: 10,
		display: 'flex',
		gap: 10,
		justifyContent: 'flex-end',
		alignItems: 'flex-end'
	},
	button: {
		cursor: 'pointer'
	},
	secondaryButton: {
		padding: '0px 10px'
	}
}));
