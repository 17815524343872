import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	wrapper: {
		display: 'flex',
		alignItems: 'center',
		position: 'relative'
	},
	edit: {
		position: 'absolute',
		right: 0
	}
}));
