import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { useTranslations } from 'components/utilities';
import { bool, func, string } from 'prop-types';
import React from 'react';

import { ButtonProgress } from '../../atoms';

const useStyles = makeStyles((theme) => ({
	wrapper: {
		width: '100%',
		paddingTop: theme.spacing(6)
	},
	span: {
		padding: '15px 0'
	},
	button: {
		textTransform: 'none'
	},
	loader: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	spacing: {
		marginBottom: 20
	}
}));

const ActionControllers = ({
	close,
	btnSubmit = 'btn.confirm',
	btnClose = 'btn.cancel',
	submitting
}) => {
	const classes = useStyles();
	const { t } = useTranslations();

	return (
		<Box className={classes.wrapper}>
			<ButtonProgress
				type="submit"
				color="primary"
				variant="contained"
				fullWidth
				disabled={submitting}
				className={clsx(classes.button, classes.spacing)}
				isLoading={submitting}
			>
				{t(btnSubmit)}
			</ButtonProgress>
			<Button
				color="primary"
				variant="outlined"
				fullWidth
				disabled={submitting}
				className={classes.button}
				onClick={() => close()}
			>
				{t(btnClose)}
			</Button>
		</Box>
	);
};

ActionControllers.propTypes = {
	close: func.isRequired,
	submitting: bool.isRequired,
	btnSubmit: string,
	btnClose: string
};

export default ActionControllers;
