import { makeStyles } from '@material-ui/styles';
import theme from 'theme';
import palette from 'theme/palette';

import { IChipProps } from './types';

interface IStylesProps {
	backgroundColor: IChipProps['backgroundColor'];
	size: IChipProps['size'];
	minWidth?: number;
}

export const useStyles = makeStyles<typeof theme, IStylesProps>(() => ({
	relative: { position: 'relative' },
	icon: {
		fill: 'white',
		marginBottom: 1
	},
	chipHover: ({ size, minWidth = 0 }) => ({
		overflow: 'hidden',
		cursor: 'pointer',
		minWidth: minWidth + getSizeVariant(size),
		'&:hover': {
			'& .collapsedChip': {
				width: `calc(100% + ${getSizeVariant(size)}px)`,
				right: 0,
				transform: `translateX(0)`
			},
			'& svg': {
				transform: 'translateX(-100%)'
			}
		}
	}),
	collapsedChip: {
		overflow: 'hidden',
		transition: 'all 250ms ease-in-out',
		height: '100%',
		width: '100%',
		transform: 'translateX(100%)',
		right: ({ size }) => getSizeVariant(size),
		backgroundColor: theme.palette.primary.main,
		position: 'absolute',
		top: 0,
		borderRadius: 50,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		'& svg': {
			opacity: 1,
			transition: 'all 350ms ease'
		}
	},
	collapsedChipLabel: {
		flex: 1,
		color: 'white'
	},
	chip: ({ size, backgroundColor }) => {
		const boundingBoxSize = getSizeVariant(size);
		return {
			height: boundingBoxSize,
			borderRadius: 50,
			backgroundColor: backgroundColor,
			minWidth: 0,
			minHeight: 0,
			paddingRight: getSizeVariant(size) - 5,
			'& svg': {
				height: boundingBoxSize,
				width: boundingBoxSize
			},
			'&:focus': {
				backgroundColor: backgroundColor
			}
		};
	},
	label: {
		marginTop: 2,
		color: palette.gray.medium
	},
	font: ({ size }) => {
		let fontSize = '16px';
		switch (size) {
			case 'small':
				fontSize = '14px';
				break;
			case 'medium':
				fontSize = '16px';
				break;
		}
		return {
			fontSize: fontSize,
			lineHeight: '16px',
			fontWeight: 400
		};
	}
}));

export function getSizeVariant(size: IChipProps['size'] = 'medium') {
	switch (size) {
		case 'small':
			return 20;
		case 'medium':
			return 25;
	}
}
