import { FIELD_NAMES } from 'helpers';
import React from 'react';
import { useForm, useFormState } from 'react-final-form';

const useFields = () => {
	const {
		submitting,
		values: { password, privacy_policy: privacyPolicy }
	} = useFormState();
	const { change } = useForm();

	React.useEffect(() => {
		change(
			FIELD_NAMES.EMPLOYEES_INVITATION.CONSENT_PERSONAL_DATA,
			privacyPolicy
		);
		//eslint-disable-next-line
	}, [privacyPolicy]);

	return { submitting, password };
};

export default useFields;
