import { EMPTY_VALUE } from 'helpers';
import React from 'react';
import { v4 as uuidV4 } from 'uuid';

import { TableCell, TableRow } from '../../../atoms';
import { ITableCellProps } from '../../../atoms/Table/TableCell/TableCell';

export interface ITabelBodyRows<TRow, TCell> {
	rows: (TRow & {
		index: number;
		key: number | string;
		measureElement?: (node: HTMLTableRowElement | null) => void;
	})[];
	getRowData: (index: number) => (TCell & { id?: string | number })[];
	cellValueGetter: (cell: TCell) => unknown;
	cellAlignGetter?: (cell: TCell) => ITableCellProps['textAlign'];
	cellValueRenderer: (cell: TCell) => React.ReactNode | JSX.Element;
}

export const TableBodyRows = <TRow, TCell>({
	rows,
	getRowData,
	cellValueRenderer,
	cellAlignGetter = () => 'initial',
	cellValueGetter = () => null
}: ITabelBodyRows<TRow, TCell>) => {
	return (
		<>
			{rows.map((row, index) => {
				const rowData = getRowData(row?.index ?? index);
				return (
					<TableRow
						key={row.key ?? uuidV4()}
						data-index={row?.index ?? index}
						ref={row?.measureElement}
					>
						{rowData.map((cell) => {
							const value = cellValueGetter(cell);
							const isValueAllowed =
								value !== null &&
								value !== undefined &&
								value !== '';
							return (
								<TableCell
									key={cell?.id ?? uuidV4()}
									textAlign={cellAlignGetter(cell)}
								>
									{isValueAllowed
										? cellValueRenderer(cell)
										: EMPTY_VALUE}
								</TableCell>
							);
						})}
					</TableRow>
				);
			})}
		</>
	);
};
