import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	result: {
		textTransform: 'capitalize'
	},
	iconWrapper: {
		borderLeft: `1px solid ${theme.palette.border.light}`,
		width: 32,
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	icon: {
		width: 20,
		height: 20
	}
}));
