import { ClassNameMap } from '@material-ui/styles';
import clsx from 'clsx';
import {
	ContainerWithBorder,
	Title,
	Typography
} from 'components/elements/atoms/ExaminationsV2';
import { useTranslations } from 'components/utilities';
import { useGetCachedExaminationNote } from 'queries/Examinations/ReportsV2/getter';
import React from 'react';

import { NotesProps } from './Notes';
import { useStyles } from './styles';

export type LocalTheme = () => void | ClassNameMap<'font' | 'title'>;

type Props = {
	examinationNote?: NotesProps['examinationNote'];
	examinationId?: string;
	className?: string;
	useLocalTheme?: LocalTheme;
};

export const TechnicalNote = ({
	examinationNote,
	className = '',
	examinationId = '',
	useLocalTheme = () => {}
}: Props) => {
	const classes = useStyles();
	const localTheme = useLocalTheme();
	const { t } = useTranslations();
	const cachedExaminationNote = useGetCachedExaminationNote({
		examinationId
	});

	return (
		<ContainerWithBorder
			className={clsx([className, classes.examinationNotesContainer])}
		>
			<Title
				className={localTheme?.title}
				title="report.examination_note.title"
			/>
			<Typography className={clsx([localTheme?.font, classes.text])}>
				{examinationNote ??
					cachedExaminationNote?.note ??
					t('report.examination_note.default_note')}
			</Typography>
		</ContainerWithBorder>
	);
};
