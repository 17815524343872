export class StringConverter {
	static truncate = (str, n, isWordBoundary = false) => {
		this.validateRequiredValue(str, 'str');
		this.validateRequiredValue(n, 'n');
		if (str.length <= n) {
			return str;
		}
		// truncate string to given length
		let subString = str.substr(0, n);

		if (isWordBoundary) {
			subString = subString.substr(0, subString.lastIndexOf(' '));
		}

		return subString + '...';
	};

	static encrypt = (
		str: string,
		startIndex: number,
		endIndex: number
	): string =>
		str
			.split('')
			.map((char, index) => {
				if (index < startIndex || index > endIndex) return char;
				return '*';
			})
			.join('');

	private static validateRequiredValue = (value, varName) => {
		if (!value) {
			throw Error(`Variable {${varName}} is required, given: {${value}}`);
		}
	};
}
