import { useQuery } from 'components/utilities';
import { QUERY_PATIENTS_LIST } from 'helpers';

import { PatientsRepository } from './repository';

export const usePatientsList = (options = {}) => {
	const {
		data: patientsList,
		isError: patientsListError,
		isLoading: isPatientsListLoading,
		apiError,
		...rest
	} = useQuery(QUERY_PATIENTS_LIST, PatientsRepository.getAll, options);

	return {
		patientsList,
		patientsListError,
		isPatientsListLoading,
		apiError,
		...rest
	};
};
