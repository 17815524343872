import { ClassNameMap } from '@material-ui/styles';
import clsx from 'clsx';
import {
	ContainerWithBorder,
	Title,
	Typography
} from 'components/elements/atoms/ExaminationsV2';
import { useUserRole } from 'components/utilities';
import { EMPTY_VALUE } from 'helpers';
import React from 'react';

import { NotesProps } from './Notes';
import { Signature } from './Signature';
import { useStyles } from './styles';

export type LocalTheme = () => void | ClassNameMap<'font' | 'title'>;

type Props = {
	disableSignature?: boolean;
	reportInterpretationNote: NotesProps['reportInterpretationNote'];
	className?: string;
	useLocalTheme?: LocalTheme;
};

export const ExaminationNote = ({
	reportInterpretationNote,
	disableSignature = false,
	className = '',
	useLocalTheme = () => {}
}: Props) => {
	const classes = useStyles();
	const localTheme = useLocalTheme();
	const { validateRoleComponent } = useUserRole();

	return (
		<ContainerWithBorder
			className={clsx([className, classes.reportInterpretationContainer])}
		>
			<Title
				className={localTheme?.title}
				title="report.report_interpretation.title"
			/>
			<Typography className={clsx([localTheme?.font, classes.text])}>
				{reportInterpretationNote ?? EMPTY_VALUE}
			</Typography>
			{!disableSignature ? (
				validateRoleComponent(() => <Signature />)
			) : (
				<></>
			)}
		</ContainerWithBorder>
	);
};
