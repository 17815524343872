import { COOKIES } from 'helpers/variables';
import Cookie from 'js-cookie';
// Import redux
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
// Import redux thunk
import reduxThunk from 'redux-thunk';

// Import reducers
import reducers from './reducers';

const { COOKIE_COUNTRY_CODE, COOKIE_AUTH } = COOKIES;

const isAuthenticated = Cookie.get(COOKIE_AUTH);
const code = Cookie.get(COOKIE_COUNTRY_CODE);

// Initial state
export const INITIAL_STATE = {
	auth: {
		authenticate: {
			data: { roles: null, user: { isAuthenticated: !!isAuthenticated } }
		},
		isError: false,
		isAuthenticated: !!isAuthenticated,
		signOut: {
			error: false,
			loading: false
		}
	},
	geoLocation: { country: code ?? null }
};

// Create middleware with redux thunk
const createStoreWithMiddleware = applyMiddleware(reduxThunk)(createStore);

let store;

// eslint-disable-next-line
if (process.env.NODE_ENV === 'production') {
	// Create store
	store = createStoreWithMiddleware(reducers, INITIAL_STATE);
} else {
	// Create store
	store = createStoreWithMiddleware(
		reducers,
		INITIAL_STATE,
		composeWithDevTools()
	);
}

export default store;
