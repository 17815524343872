import { EXAMINATIONS_TYPES } from 'helpers/variables';
import {
	ExaminationsListConvertedItemModel,
	ExaminationsListItemModel
} from 'models/Examinations/ExaminationsList';

import {
	createCreationDateListItem,
	getExaminationCellHeight
} from './helpers';

export const ExaminationsListServices = {
	/**
	 * convert returned data from DB
	 * convert date (returned as object key) to object
	 * date of examination standardization to Safari
	 */
	convertExaminationsListData: (examinationsList: {
		[key: string]: ExaminationsListItemModel[];
	}): ExaminationsListConvertedItemModel[] =>
		Object.entries(examinationsList).reduce(
			(
				acc: ExaminationsListConvertedItemModel[],
				[date, examinations]: [string, ExaminationsListItemModel[]]
			) => {
				if (
					examinations.every(
						(e) => e.type === EXAMINATIONS_TYPES.MEDICAL_NOTE.type
					)
				) {
					return acc;
				}

				acc.push(createCreationDateListItem(date));

				acc.push(
					...examinations.reduce((acc, examination) => {
						const isMedicalNote =
							examination.type ===
							EXAMINATIONS_TYPES.MEDICAL_NOTE.type;

						if (isMedicalNote) {
							return acc;
						}
						acc.push({
							...examination,
							height: getExaminationCellHeight(examination)
						});
						return acc;
					}, [] as ExaminationsListItemModel[])
				);
				return acc;
			},
			[]
		)
};
