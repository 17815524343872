import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		alignSelf: 'flex-start',
		width: '100%'
	},
	typography: {
		fontSize: '12px',
		lineHeight: '13.4px',
		fontWeight: 400,
		color: theme.palette.gray.medium
	},
	font: {
		fontFamily: ['Geomanist', 'Open Sans'].join(','),
		fontSize: '14px',
		lineHeight: '14px'
	}
}));
