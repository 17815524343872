import { MutationOptions, useMutation } from 'components/utilities';
import {
	RegisterFirebaseMessagingTokenRequestModel,
	RegisterFirebaseMessagingTokenResponseModel
} from 'models/User/User';

import { AccountRepository } from './repository';

export const useRegisterUserFirebaseMessagingToken = (
	options: MutationOptions<
		RegisterFirebaseMessagingTokenResponseModel,
		TMyError,
		RegisterFirebaseMessagingTokenRequestModel
	> = {}
) => {
	const mutation = useMutation(
		AccountRepository.registerFirebaseMessagingToken,
		options
	);
	return mutation;
};
