import { faker } from '@faker-js/faker';
import { DoctorUserModel } from 'models/User/Doctor';
import {
	RegisterFirebaseMessagingTokenRequestModel,
	RegisterFirebaseMessagingTokenResponseModel,
	UnregisterFirebaseMessagingTokenRequestModel,
	UnregisterFirebaseMessagingTokenResponseModel
} from 'models/User/User';

import { fakeEmail } from './common';

faker.seed(1);

export const fakeDoctorUser = (): DoctorUserModel => ({
	id: faker.number.int(),
	email: fakeEmail,
	first_name: faker.person.fullName(),
	last_name: faker.person.lastName(),
	token: faker.string.uuid(),
	ttl: faker.number.int(),
	language: 'EN',
	type: 'doctor'
});

export const fakeRegisterUserFirebaseMessagingTokenRequestPayload =
	(): RegisterFirebaseMessagingTokenRequestModel => ({
		fir_token: faker.string.uuid()
	});
export const fakeRegisterUserFirebaseMessagingTokenResponseModel =
	(): RegisterFirebaseMessagingTokenResponseModel => ({ status: 'OK' });

export const fakeUnregisterUserFirebaseMessagingTokenRequestPayload =
	(): UnregisterFirebaseMessagingTokenRequestModel => ({
		fir_token: faker.string.uuid()
	});
export const fakeUnregisterUserFirebaseMessagingTokenResponseModel =
	(): UnregisterFirebaseMessagingTokenResponseModel => ({ status: 'OK' });
