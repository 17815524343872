import {
	CreateDateTimeCell,
	StatusCell
} from 'components/elements/atoms/ExaminationsV2';
import { IColumn } from 'components/elements/organisms/ExaminationsV2/ParametersTable/ParametersTable';
import {
	TypeItemModel,
	VerificationHistoryItemModel
} from 'models/Report/CalibrationCheck';

export type TypeItemModelWithRefValue = TypeItemModel & {
	refValue: string;
};

type Accessor = Leaves<VerificationHistoryItemModel>;

export const columns: IColumn<Accessor>[] = [
	{ Header: '', accessor: 'createDateTime', component: CreateDateTimeCell },
	{
		Header: 'report.calibration_check.verification_history.device_serial',
		accessor: 'device.deviceSerialNumber'
	},
	{
		Header: 'report.calibration_check.verification_history.tube_serial',
		accessor: 'device.tubeSerialNumber'
	},
	{
		Header: 'report.calibration_check.verification_history.app_version',
		accessor: 'device.appVersion'
	},
	{
		Header: 'report.calibration_check.status',
		accessor: 'status',
		component: StatusCell
	}
];
