import {
	DialogTitle,
	IconButton,
	PropTypes,
	Typography
} from '@material-ui/core';
import { CloseIcon } from 'assets/icons/commonMUIIcons';
import clsx from 'clsx';
import { useTranslations } from 'components/utilities';
import React from 'react';

import { useCommonStyles } from '../../../../commonStyles';
import { useStyles } from './styles';
export interface ITitleProps {
	title?: TranslationKey;
	close?: () => void;
	align?: PropTypes.Alignment;
	disableAction?: boolean;
}

export const Title = ({
	title,
	close,
	align = 'left',
	disableAction = false
}: ITitleProps) => {
	const classes = useStyles();
	const commonClasses = useCommonStyles();

	const { t } = useTranslations();

	return (
		<DialogTitle
			className={clsx([classes.root, commonClasses.containerPadding])}
			disableTypography
		>
			{title && (
				<Typography
					className={classes.title}
					variant="h3"
					align={align}
				>
					{t(title)}
				</Typography>
			)}
			{close && title && (
				<IconButton
					data-testid={'title-icon-button'}
					disabled={disableAction}
					color="primary"
					aria-label="close"
					onClick={close}
					className={clsx([classes.iconButton, 'disable-animation'])}
				>
					<CloseIcon />
				</IconButton>
			)}
		</DialogTitle>
	);
};
