import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		alignSelf: 'flex-start',
		display: 'flex',
		height: '100%',
		alignItems: 'center',
		gap: 8
	}
}));
