import { SessionStorage, useParams } from 'utilities';

import { IExaminationsV2Context } from '../ExaminationsV2Provider';

const useInitialExaminationsDateRange =
	(): IExaminationsV2Context['examinationsDateRange'] => {
		const params = useParams();

		const examinationsDateRangeSessionStorageData =
			SessionStorage.examinations.getExaminationsDateRange(params);

		if (examinationsDateRangeSessionStorageData) {
			return examinationsDateRangeSessionStorageData;
		}

		return { startDate: null, endDate: null };
	};

export default useInitialExaminationsDateRange;
