import { SPIROMETRY_EXAMINATION_VALUE_KEYS } from '../../charts/measurement/parameters';
import { isValueValid } from '../value';
import { getExaminationStatusColor } from './colors';
import { getColor } from './helpers';
import {
	SPIROMETRY_PB_PERCENTAGE_COLOR_RANGES,
	SPIROMETRY_PERSONAL_BEST_COLOR_RANGES
} from './ranges';

const parametersWithCustomColor = [
	SPIROMETRY_EXAMINATION_VALUE_KEYS.PBPercentage.key
];
export const getSpirometryExaminationParameterColors = (args) =>
	getExaminationStatusColor(
		{ ...args, parametersWithCustomColor },
		customSpirometryColors
	);

const customSpirometryColors = ({
	EXAMINATION_VALUES_KEYS,
	key,
	examinationValues,
	personalBestExaminationValue
}) => {
	if (key === EXAMINATION_VALUES_KEYS.PBPercentage.key) {
		return getPBPercentageColor(examinationValues.PBPercentage);
	}
	return getColorBasedOnPersonalBestValue({
		personalBestExaminationValue,
		examinationValues
	});
};

const getPBPercentageColor = (PBValue) => {
	if (!isValueValid(PBValue)) return null;

	return getColor(
		PBValue,
		SPIROMETRY_PB_PERCENTAGE_COLOR_RANGES.GREEN,
		SPIROMETRY_PB_PERCENTAGE_COLOR_RANGES.YELLOW
	);
};

const getColorBasedOnPersonalBestValue = ({
	personalBestExaminationValue,
	examinationValues
}) => {
	const bestExaminationValue = examinationValues.best;
	const valueFactor =
		(bestExaminationValue / personalBestExaminationValue) * 100;

	return getColor(
		valueFactor,
		SPIROMETRY_PERSONAL_BEST_COLOR_RANGES.GREEN,
		SPIROMETRY_PERSONAL_BEST_COLOR_RANGES.YELLOW
	);
};
