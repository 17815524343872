import { IconButton } from '@material-ui/core';
import { CloseIcon } from 'assets/icons/commonMUIIcons';
import clsx from 'clsx';
import React from 'react';

import { useStyles } from './styles';

type Props = {
	className?: string;
	onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	disabled?: boolean;
} & DataTestId;

export const CloseButton = ({
	className = '',
	onClick,
	disabled = false,
	['data-testid']: dataTestId = ''
}: Props) => {
	const classes = useStyles();
	return (
		<IconButton
			data-testid={dataTestId}
			disabled={disabled}
			color="primary"
			aria-label="close"
			onClick={onClick}
			className={clsx([className, classes.iconButton])}
		>
			<CloseIcon />
		</IconButton>
	);
};
