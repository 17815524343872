import { AddIcon } from 'assets/icons/commonMUIIcons';
import { Button } from 'components/elements';
import { useTranslations } from 'components/utilities';
import React from 'react';

import { useStyles } from './styles';

export interface IToolbarActionsProps {
	openPatientFormModal: () => void;
}

export const ToolbarActions = ({
	openPatientFormModal
}: IToolbarActionsProps) => {
	const classes = useStyles();
	const { t } = useTranslations();
	return (
		<Button
			className={classes.button}
			startIcon={<AddIcon />}
			onClick={openPatientFormModal}
			color="primary"
			variant="contained"
		>
			{t('patients.add_new')}
		</Button>
	);
};
