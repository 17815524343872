import { TableHead as MuiTableHead, TableHeadProps } from '@material-ui/core';
import clsx from 'clsx';

import { useStyles } from './styles';

export interface ITableHeadProps extends TableHeadProps {
	sticky?: boolean;
}

export const TableHead = ({
	sticky = true,
	children,
	className,
	...props
}: ITableHeadProps) => {
	const classes = useStyles({ sticky });
	return (
		<MuiTableHead
			className={clsx([className, classes.tableHead])}
			{...props}
		>
			{children}
		</MuiTableHead>
	);
};
