import { useMutation } from 'components/utilities';
import { useLocation } from 'react-router-dom';

import { AuthRepository } from './repository';

/**
 * reset user password
 * @param {object} options useQuery options
 * @returns reset user password mutation
 */
const useResetPassword = () => {
	const { pathname } = useLocation();
	const [, , , token] = pathname.split('/');

	return useMutation((payload) =>
		AuthRepository.resetPassword({ ...payload, token })
	);
};

export default useResetPassword;
