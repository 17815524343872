import { Typography as MuiTypography } from '@material-ui/core';
import clsx from 'clsx';
import { ReactHMLParserType } from 'components/utilities/hooks/translations/types';
import React from 'react';

import { useStyles } from './styles';

type Props = {
	children: CmpChildren | string | number | ReactHMLParserType | null;
	className?: string;
};
export const Typography = ({ children, className = '' }: Props) => {
	const classes = useStyles();
	return (
		<MuiTypography className={clsx([classes.typography, className])}>
			{children}
		</MuiTypography>
	);
};
