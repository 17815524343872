import { ButtonProgress } from 'components/elements/atoms';
import {
	Alert,
	Confirmation,
	LoaderSpinner
} from 'components/elements/molecules';
import { useTranslations } from 'components/utilities';
import { PANEL_ROLES } from 'helpers';
import { array, bool, func, string } from 'prop-types';
import React from 'react';
import { Form } from 'react-final-form';

import { Fields } from './Fields';
import { roleFilter } from './helpers';
import { useStyles } from './styles';
import TopHeader from './TopHeader';
import useEmployeeInvitationForm from './useEmployeeInvitationForm';
import defaultValidation from './validation';

const EmployeeInvitationForm = (props) => {
	const {
		paramId = '',
		role,
		disabledFields = [],
		translationKeyBase,
		disableUnknownGender,
		fieldSettings,
		validation
	} = props;
	const { t } = useTranslations();

	const invitationProps = useEmployeeInvitationForm(props);

	const {
		isResourcesLoading,
		isAccountCreated,
		submit,
		data,
		createInitialValues
	} = invitationProps;

	const { isResourcesError, errorMessages } = invitationProps;

	const classes = useStyles();

	const checkComponentRole = roleFilter(role);
	const isInvitation = !!paramId;
	const invitation = isInvitation ? data[data.length - 1] : {};

	if (isAccountCreated) {
		const translationPrefix =
			role === PANEL_ROLES.doctor ? 'sign_up.doctor' : 'sign_up.patient';
		return (
			<Confirmation
				role={role}
				title={`${translationPrefix}.confirmation.account_created`}
				description={`${translationPrefix}.confirmation.desc`}
				secondaryDescription={`${translationPrefix}.confirmation.desc_secondary`}
			/>
		);
	}
	if (isResourcesLoading) return <LoaderSpinner />;
	if (isResourcesError) return <Alert message={errorMessages} />;

	return (
		<Form
			onSubmit={submit}
			validate={validation ?? defaultValidation}
			initialValues={createInitialValues()}
			render={({ handleSubmit, submitting }) => (
				<form
					className={classes.form}
					onSubmit={handleSubmit}
					noValidate
				>
					<TopHeader
						isInvitation={!!paramId}
						invitation={invitation}
						role={role}
					/>
					<Alert
						apiFieldErrorTranslationKeyPrefix={translationKeyBase}
						className={classes.error}
						message={errorMessages}
					/>

					<Fields
						data={data}
						disabledFields={disabledFields}
						checkComponentRole={checkComponentRole}
						translationKeyBase={translationKeyBase}
						disableUnknownGender={disableUnknownGender}
						fieldSettings={fieldSettings}
					/>

					<ButtonProgress
						classNameWrapper={classes.wrapper}
						className={classes.buttonProgress}
						color="primary"
						disabled={submitting}
						isLoading={submitting}
						fullWidth
						size="large"
						type="submit"
						variant="contained"
					>
						{t(`${translationKeyBase}.create_account_btn`)}
					</ButtonProgress>
				</form>
			)}
		/>
	);
};

EmployeeInvitationForm.propTypes = {
	paramId: string,
	disabledFields: array,
	fieldSettings: array,
	disableUnknownGender: bool,
	role: string.isRequired,
	translationKeyBase: string.isRequired,
	validation: func
};

export default EmployeeInvitationForm;
