import { Box } from '@material-ui/core';
import React from 'react';
import palette from 'theme/palette';

import { Label } from '../../Typography';
import { AttemptNumberProps } from './AttemptNumber';
import { useStyles } from './styles';

type Props = {
	number: AttemptNumberProps['number'];
	isBest?: AttemptNumberProps['isBest'];
	isActive?: AttemptNumberProps['isActive'];
	cursor?: AttemptNumberProps['cursor'];
	disableTransition?: AttemptNumberProps['disableTransition'];
	color?: string;
	getEvents?: () => {
		onClick?: () => void;
		onMouseLeave?: () => void;
		onMouseEnter?: () => void;
	};
};

export const CircledNumber = ({
	number,
	color = palette.report.blue.dark,
	isBest = false,
	isActive = true,
	cursor = 'pointer',
	disableTransition = false,
	getEvents = () => ({
		onClick: () => {},
		onMouseLeave: () => {},
		onMouseEnter: () => {}
	})
}: Props) => {
	const classes = useStyles({
		isBest,
		isActive,
		cursor,
		disableTransition,
		color
	});

	return (
		<Box className={classes.attemptNumber} {...getEvents()}>
			<Label label={(number + 1)?.toString()} className={classes.label} />
		</Box>
	);
};
