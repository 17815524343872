export class MyFile {
	static toDataUrl(fileUrl: string) {
		return fetch(fileUrl, {
			method: 'GET'
		})
			.then((res) => res.blob())
			.then(
				(blob) =>
					new Promise<string>((resolve, reject) => {
						const reader = new FileReader();
						reader.onloadend = () =>
							resolve(reader.result as string);
						reader.onerror = reject;
						reader.readAsDataURL(blob);
					})
			);
	}

	static dataUrlToFile(dataBase64Url: string, fileName: string) {
		const arr = dataBase64Url.split(',');
		const mime = arr[0].match(/:(.*?);/)?.[1];
		const bstr = atob(arr[1]);
		let n = bstr.length;
		const u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new File([u8arr], fileName, { type: mime });
	}

	static openInNewWindowTab(url: string) {
		window.open(url, '_blank')?.focus();
	}
}
