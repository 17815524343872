import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	iconButton: {
		height: 30,
		width: 30,
		transform: 'translate(-50%, 50%)',
		position: 'absolute',
		top: 0,
		right: 0
	}
}));
