import React from 'react';

import { useAttemptsContext } from '../../../context';
import { AttemptNumberProps } from './AttemptNumber';

type Props = {
	number: AttemptNumberProps['number'];
	isBestIndex: AttemptNumberProps['isBestIndex'];
};
const useAttemptNumber = ({ number, isBestIndex }: Props) => {
	const {
		activeMeasurementIndex,
		setActiveMeasurementIndex,
		setActiveGraphCurve,
		hidePreviewOfNewActiveGraphCurve,
		showPreviewOfNewActiveGraphCurve
	} = useAttemptsContext();

	const handleSetActive = React.useCallback(() => {
		setActiveGraphCurve(number, activeMeasurementIndex, {
			disableHide: activeMeasurementIndex === isBestIndex
		});

		setActiveMeasurementIndex(number);
	}, [activeMeasurementIndex]);

	const handleMouseEnter = React.useCallback(() => {
		showPreviewOfNewActiveGraphCurve(number);
	}, []);

	const handleMouseLeave = React.useCallback(() => {
		hidePreviewOfNewActiveGraphCurve(number);
	}, []);

	return {
		onMouseEnter: handleMouseEnter,
		onMouseLeave: handleMouseLeave,
		setActive: handleSetActive
	};
};

export default useAttemptNumber;
