import { node, object } from 'prop-types';
import React from 'react';

// Import hooks
import { useActions,useGenerator } from './hooks';

export const ZScoreContext = React.createContext();

const ZScoreProvider = ({ data, children }) => {
	const zScoreGenerator = useGenerator({ data });
	const actions = useActions(zScoreGenerator);

	return (
		<ZScoreContext.Provider value={{ ...zScoreGenerator, ...actions }}>
			{children}
		</ZScoreContext.Provider>
	);
};

ZScoreProvider.propTypes = {
	children: node,
	data: object
};

export default ZScoreProvider;
