import { IDimensions } from './TooltipDimensionsFactory';

export class SvcTooltipDimensions implements IDimensions {
	width;
	height;
	constructor() {
		this.width = 149;
		this.height = 140;
	}
}
