import { Box } from '@material-ui/core';
import { useExaminationsV2Context } from 'components/context/ExaminationsV2';
import {
	Divider,
	LoaderSpinnerTransitioned,
	Placeholder
} from 'components/elements';
import { useHeightTransitionStyles } from 'components/elements/atoms/Transitions/styles';
import { PATIENTS_DETAILS_PATHS } from 'components/routes/paths';
import { useGetExaminationsListFromCache } from 'queries';
import React from 'react';
import { generatePath } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { useParams } from 'utilities';

import { Filters } from './Filters';
import { List } from './List';
import { PinnedExaminationListItem } from './List/PinnedExaminationListItem';
import { useStyles } from './styles';

export const ExaminationsList = () => {
	const params = useParams();
	const classes = useStyles();
	const heightTransitionClassNames = useHeightTransitionStyles({
		transition: 500
	});

	const isExaminationPreviewView = React.useMemo(
		() =>
			location.pathname ===
			generatePath(PATIENTS_DETAILS_PATHS.EXAMINATIONS.PREVIEW, params),
		[location.pathname]
	);

	const { store: isExaminationsListDataSuccess } = useExaminationsV2Context(
		(s) => s.isExaminationsListDataSuccess
	);

	const { examinationsListData: examinationsListQueryData } =
		useGetExaminationsListFromCache();

	return (
		<Box className={classes.examinationsListContent}>
			<LoaderSpinnerTransitioned
				isLoading={!isExaminationsListDataSuccess}
				isCover
			/>

			<Placeholder
				active={
					examinationsListQueryData?.length === 0 &&
					isExaminationsListDataSuccess
				}
				label={'patients.examinations_v2.no_examinations_data'}
				prefixMarker=""
				suffixMarker=""
				backgroundColor="inherit"
				classes={{ root: classes.root }}
			>
				<Filters />
				<Divider className={classes.divider} />
				<CSSTransition
					mountOnEnter
					unmountOnExit
					in={isExaminationPreviewView}
					timeout={500}
					classNames={heightTransitionClassNames}
				>
					<PinnedExaminationListItem />
				</CSSTransition>
				<Divider className={classes.divider} />
				<List />
			</Placeholder>
		</Box>
	);
};
