import { areBooleansTruthy } from 'helpers';
import { useCountries, usePatientDataAndPersonalBests } from 'queries';
import { useFetchData } from 'queries/Users/Patient/useFetchData';

const usePatientsDetails = () => {
	const {
		isError: isPatientDataError,
		apiError: patientDataApiError,
		isLoading: isPatientDataLoading,
		isSuccess: isPatientDataSuccess,
		data
	} = usePatientDataAndPersonalBests();

	const {
		isError: isCountriesError,
		apiError: countriesApiError,
		isLoading: isCountriesLoading,
		isSuccess: isCountriesSuccess,
		data: countries
	} = useCountries();

	const {
		isError: isPatientPersonalDataError,
		apiError: PatientPersonalDataApiError,
		isLoading: isPatientPersonalDataLoading,
		isSuccess: isPatientPersonalDataSuccess
	} = useFetchData();

	const isLoading = areBooleansTruthy(
		'some',
		isPatientDataLoading,
		isCountriesLoading,
		isPatientPersonalDataLoading
	);
	const isSuccess = areBooleansTruthy(
		'every',
		isPatientDataSuccess,
		isCountriesSuccess,
		isPatientPersonalDataSuccess
	);
	const isError = areBooleansTruthy(
		'some',
		isPatientDataError,
		isCountriesError,
		isPatientPersonalDataError
	);
	const apiError = areBooleansTruthy(
		'some',
		countriesApiError,
		patientDataApiError,
		PatientPersonalDataApiError
	);

	return {
		resources: data ?? null,
		isSuccess,
		isError,
		isLoading,
		apiError,
		countries
	};
};

export default usePatientsDetails;
