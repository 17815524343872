import { Table as MuiTable, TableProps } from '@material-ui/core';

/**
 * Table Root component
 */
export interface ITableProps extends Omit<TableProps, 'stickyHeader'> {
	/**
	 * define padding inherited by table cells
	 */
	padding?: TableProps['padding'];
	/**
	 * define size inherited by table cells
	 */
	size?: TableProps['size'];
}

export const Table = ({
	children,
	padding = 'normal',
	size = 'medium',
	...props
}: ITableProps) => {
	return (
		<MuiTable {...props} padding={padding} size={size}>
			{children}
		</MuiTable>
	);
};
