import { DASH_PATHS } from 'components/routes/paths';
import { useHistory } from 'react-router-dom';

const useFormButton = () => {
	const history = useHistory();

	const handleCancel = () => {
		history.push(DASH_PATHS.SCHEDULE);
	};

	return { onCancel: handleCancel };
};

export default useFormButton;
