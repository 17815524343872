import React from 'react';

import { CountryFlag } from '../../../atoms';

type Props = {
	countryCode: string;
	phonePrefix: string;
};
const PhoneOption = ({ countryCode, phonePrefix }: Props) => {
	return (
		<React.Fragment>
			{/* Inline style because this is an option / prop renderOption to material ui's autocomplete component. It could be only a pure fucntion with jsx */}
			<CountryFlag
				margin="normal"
				style={{ left: -7, position: 'relative' }}
				countryCode={countryCode}
			/>
			{phonePrefix}
		</React.Fragment>
	);
};

export default PhoneOption;
