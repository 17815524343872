import { Avatar as MuiAvatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ReactComponent as ManIcon } from 'assets/icons/ic_man_avatar.svg';
import { ReactComponent as WomanIcon } from 'assets/icons/ic_woman_avatar.svg';
import clsx from 'clsx';
import { GENDERS_TYPES } from 'helpers/variables';
import { string } from 'prop-types';
import React from 'react';

const { FEMALE, MALE } = GENDERS_TYPES;

const useStyles = makeStyles((theme) => ({
	avatar: () => ({
		backgroundColor: theme.palette.white
	})
}));

const Avatar = ({ gender = MALE, className = '', alt = '', src = '' }) => {
	const classes = useStyles();
	const isFemale = gender === FEMALE;

	return (
		<MuiAvatar
			className={clsx(classes.avatar, className)}
			alt={alt}
			src={src}
		>
			{isFemale ? <WomanIcon /> : <ManIcon />}
		</MuiAvatar>
	);
};

Avatar.propTypes = {
	gender: string,
	className: string,
	alt: string,
	src: string
};

export default Avatar;
