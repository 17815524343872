import React from 'react';
import { ReactContext } from 'utilities';

interface Context {
	examinationData: AnyType;
	setExaminationData: (data: Partial<AnyType> | undefined) => void;
}

const [useCtx, Ctx] = ReactContext.createContext<Context>();

type Props<TData> = {
	children: CmpChildren;
	data: TData;
};
export const ExaminationDataProvider = <TData,>({
	children,
	data
}: Props<TData>) => {
	const [examinationData, setExaminationData] = React.useState<TData>(data);
	const handleSetExaminationData = (d: Partial<TData> | undefined) => {
		setExaminationData((prev) => ({
			...prev,
			...(d as TData)
		}));
	};

	return (
		<Ctx.Provider
			value={{
				examinationData,
				setExaminationData: handleSetExaminationData
			}}
		>
			{children}
		</Ctx.Provider>
	);
};

export const useExaminationDataContext = useCtx;
export const ExaminationContext = Ctx;
