import { COOKIES } from 'helpers/variables';
import Cookie from 'js-cookie';
import React from 'react';
import { useSelector } from 'react-redux';

const { COOKIE_COUNTRY_CODE } = COOKIES;
const useCountryCode = () => {
	const { country } = useSelector(({ geoLocation }) => geoLocation);
	const code = Cookie.get(COOKIE_COUNTRY_CODE);

	const [countryCode] = React.useState(code || country);

	return { countryCode };
};

export default useCountryCode;
