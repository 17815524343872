import {
	BasicInformations,
	basicInformationsValidation,
	CardDataPreview,
	FormDialog
} from 'components/elements';
import { ToggleRPC } from 'components/utilities';
import { QUERY_COUNTRIES } from 'helpers';
import { object } from 'prop-types';
import { PatientServices, usePatientsList } from 'queries';
import React from 'react';
import { useQueryClient } from 'react-query';

import { ActionButtons } from '../components';
import { DataPreviewActionButtons } from './DataPreviewActionButtons';
import { BASIC_FIELD_NAMES, basicInformationsFields } from './helpers';
import { useStyles } from './styles';
import useBasicInfo from './useBasicInfo';

const BasicInfo = ({ patient }) => {
	const classes = useStyles();
	const cache = useQueryClient();
	const { submit, reset, apiError } = useBasicInfo();
	const countries = cache.getQueryData([QUERY_COUNTRIES]);
	const { patientsList } = usePatientsList();
	return (
		<ToggleRPC>
			{({ on, toggle }) => (
				<>
					<FormDialog
						fullWidth={false}
						keepMounted={false}
						disableBackdropClick
						disableActions
						title={'patients.basic_data.basic_info'}
						open={on}
						close={toggle}
						resetMutationState={reset}
						initialValues={PatientServices.convertBasicInformationsInitialValues(
							patient,
							countries
						)}
						onSubmit={submit(toggle)}
						validation={(values) =>
							basicInformationsValidation({
								values,
								patientsList: patientsList?.data ?? []
							})
						}
						maxWidth="sm"
						alignChildren="flex-start"
						apiError={apiError}
						contentSpacing={2}
						classes={classes}
						apiFieldErrorTranslationKeyPrefix="patients.form"
					>
						{() => (
							<BasicInformations
								actionButton={ActionButtons}
								isEmailEditable={!patient?.patientEmail}
								height={480}
								fieldNames={BASIC_FIELD_NAMES}
								isGendersFiltered
							/>
						)}
					</FormDialog>
					<CardDataPreview
						isBorderTop={false}
						data={patient}
						fields={basicInformationsFields(
							patient,
							countries,
							classes
						)}
						title={'patients.basic_data.basic_info'}
					>
						<DataPreviewActionButtons
							openEditForm={toggle}
							patientName={`${patient.patientFirstName} ${patient.patientLastName}`}
						/>
					</CardDataPreview>
				</>
			)}
		</ToggleRPC>
	);
};

BasicInfo.propTypes = {
	patient: object.isRequired
};

export default BasicInfo;
