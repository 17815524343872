import { getExaminationStatusColor } from './colors';
import { getColor } from './helpers';
import {
	PULSE_OXIMETRY_HR_COLOR_RANGES,
	PULSE_OXIMETRY_SPO2_COLOR_RANGES
} from './ranges';

export const getPulseOximetryExaminationParameterColors = (args) =>
	getExaminationStatusColor(args, customPulseOximetryColors);

const customPulseOximetryColors = ({
	EXAMINATION_VALUES_KEYS,
	key,
	examinationValues
}) => {
	const value = examinationValues[key];

	switch (key) {
		case EXAMINATION_VALUES_KEYS?.spO2?.key:
			return getColor(
				value,
				PULSE_OXIMETRY_SPO2_COLOR_RANGES.GREEN,
				PULSE_OXIMETRY_SPO2_COLOR_RANGES.YELLOW
			);
		default:
			return getColor(
				value,
				PULSE_OXIMETRY_HR_COLOR_RANGES.GREEN,
				PULSE_OXIMETRY_HR_COLOR_RANGES.YELLOW
			);
	}
};
