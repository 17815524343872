import clsx from 'clsx';
import React from 'react';
import { CSSTransition } from 'react-transition-group';

import LoaderSpinner from './LoaderSpinner';
import { useStyles } from './styles';

type Props = {
	isLoading: boolean;
	className?: string;
	isCover?: boolean;
} & Record<string, unknown>;

const LoaderSpinnerTransitioned = ({
	className,
	isLoading,
	isCover = false,
	...rest
}: Props) => {
	const classes = useStyles();

	const absoluteClassName = isCover ? classes.absolute : '';
	return (
		<CSSTransition
			in={isLoading}
			timeout={150}
			mountOnEnter
			unmountOnExit
			classNames="loader-spinner"
		>
			<LoaderSpinner
				classNameRoot={clsx([
					classes.loaderTransitioned,
					absoluteClassName,
					className
				])}
				{...rest}
			/>
		</CSSTransition>
	);
};

export default LoaderSpinnerTransitioned;
