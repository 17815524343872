import { PATIENTS_DETAILS_PATHS } from 'components/routes/paths';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const useItem = () => {
	const history = useHistory();

	const { patientId } = useParams();

	const url = PATIENTS_DETAILS_PATHS.CONDITIONS.ROOT.replace(
		':patientId',
		patientId
	);

	const handleCancel = () => {
		history.push(url);
	};

	return { handleCancel, url };
};

export default useItem;
