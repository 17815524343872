import { Box } from '@material-ui/core';
import { useTranslations } from 'components/utilities';
import React from 'react';

import { Button } from '../../../atoms';
import { CardTopBar, TCardTopBar } from '../../Card';
import Row, { TRowPreview } from './Row';
import { useStyles } from './styles';

type Props = {
	rowClassName?: string;
	fields?: TRowPreview[];
	title?: string;
	data?: Record<TRowPreview['name'], unknown>;
	renderAlert?: (() => JSX.Element) | null;
	editButtonLabel?: string;
	onEditButtonClick?: (event: React.MouseEvent<HTMLElement>) => void;
	children?: JSX.Element | JSX.Element[];
};
const CardDataPreview = ({
	rowClassName = '',
	fields = [],
	title = '',
	data = {},
	renderAlert = null,
	editButtonLabel = '',
	onEditButtonClick = () => {},
	children,
	...rest
}: Props & TCardTopBar) => {
	const classes = useStyles();
	const { t } = useTranslations();
	return (
		<CardTopBar
			data-testid="CardDataPreview-root"
			title={title}
			cardContentClassName={classes.root}
			{...rest}
		>
			<>
				{renderAlert?.()}
				<Box>
					{fields.map((field) => (
						<Row
							key={field.name}
							rowClassName={rowClassName}
							value={data?.[field.name]}
							{...field}
						/>
					))}
				</Box>
				{children}
				{editButtonLabel && (
					<Button
						onClick={onEditButtonClick}
						className={classes.button}
					>
						{t(editButtonLabel)}
					</Button>
				)}
			</>
		</CardTopBar>
	);
};

export default CardDataPreview;
