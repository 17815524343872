import { ZScorePictogram } from 'components/elements';
import { Title } from 'components/elements/atoms/ExaminationsV2';
import { ForcedSpirometryReportModel } from 'models/Report/Reports';
import React from 'react';

import { ContainerWithBorder } from '..';
import { createZScoreObject } from './helpers';
import { useStyles } from './styles';

type Props = {
	examinationData?: ForcedSpirometryReportModel;
};
export const ZScore = ({ examinationData }: Props) => {
	const classes = useStyles();

	return (
		<ContainerWithBorder className={classes.zScoreRoot}>
			<Title title="report.z_score.title" className={classes.title} />
			{examinationData?.bestParameters ? (
				<ZScorePictogram
					//eslint-disable-next-line
					//@ts-ignore
					classNameContainer={classes.zScoreContainer}
					spirometryData={createZScoreObject(
						examinationData?.bestParameters
					)}
				/>
			) : (
				<></>
			)}
		</ContainerWithBorder>
	);
};
