import { PL } from 'helpers/variables';

import { UserSettingModel } from '../../models/Settings/Settings';
import { fakeUserSetting } from './fakeData';

export const settingsInitialState = {
	settings: {
		settings: [
			{
				id: '1',
				availableValues: ['pl'],
				name: 'setting.language',
				platform: 'all',
				type: 'select',
				validation: ['required'],
				value: [PL]
			} as UserSettingModel,
			fakeUserSetting(),
			fakeUserSetting(),
			fakeUserSetting(),
			fakeUserSetting()
		]
	}
};

export const metricsInitialState = { metrics: { metrics: { separator: ',' } } };
