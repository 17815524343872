import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	card: {
		fontFamily: 'Geomanist',
		boxShadow: '0px 3px 6px #00000029'
	},
	cardContent: {
		height: '100%'
	}
}));
