import { useUserRole } from 'components/utilities';
import {
	areBooleansTruthy,
	CLINIC_DATA_QUERY_KEY,
	GET_EXAMINATION_REPORT_DATA_QUERY_KEY,
	QUERY_PATIENT_BASIC_DATA
} from 'helpers';
import {
	useClinicData,
	useDoctorResources,
	useFetchSettings,
	usePatientData
} from 'queries';
import {
	useExaminationNote,
	useExaminationReportData
} from 'queries/Examinations/ReportsV2';
import { useFetchLanguages } from 'queries/Languages/useFetchLanguages';
import React from 'react';
import { queryClient, useParams } from 'utilities';

import { ReportsV2Service } from './services';

const REPORT_TRANSLATION_GROUPS =
	'groups[]=frontend_report&groups[]=frontend_global&groups[]=frontend_settings&groups[]=frontend_patientssmoker&groups[]=frontend_errorcodes&groups[]=frontend_pages';

type OptionsProp = {
	onError: (message: AnyType) => void;
};

export const useReportData = (options: OptionsProp = { onError: () => {} }) => {
	const { patientId, id: examinationId, examinationType } = useParams();
	const { validateCurrentUserRole } = useUserRole();

	React.useEffect(() => {
		return () => {
			queryClient.removeQueries([QUERY_PATIENT_BASIC_DATA, patientId]);
			queryClient.removeQueries(CLINIC_DATA_QUERY_KEY);
			queryClient.removeQueries(
				GET_EXAMINATION_REPORT_DATA_QUERY_KEY(
					examinationId,
					examinationType
				)
			);
		};
	}, []);

	const settings = useFetchSettings({ onError: options?.onError });

	const lngs = useFetchLanguages(
		{
			enabled: settings?.isSuccess,
			onError: options?.onError
		},
		{
			translationGroup: REPORT_TRANSLATION_GROUPS
		}
	);

	const doctorUser = useDoctorResources();

	const patient = usePatientData({
		enabled:
			settings?.isSuccess &&
			!ReportsV2Service.disableSectionByExaminationType(
				examinationType,
				'patient'
			),
		onError: options?.onError
	});

	const clinic = useClinicData({
		enabled: validateCurrentUserRole(),
		onError: options?.onError
	});

	const examinationReport = useExaminationReportData({
		onError: options?.onError
	});

	const examinationNote = useExaminationNote(
		{
			enabled: !ReportsV2Service.disableSectionByExaminationType(
				examinationType,
				'note'
			)
		},
		{ examinationType }
	);

	const isLoading = areBooleansTruthy(
		'some',
		patient.isLoading,
		clinic.isLoading,
		examinationReport.isLoading,
		settings.isLoading,
		lngs.isLoading,
		doctorUser.isLoading,
		examinationNote.isLoading
	);
	const isError = areBooleansTruthy(
		'some',
		patient.isError,
		clinic.isError,
		examinationReport.isError,
		settings.isError,
		lngs.isError,
		doctorUser.isError
	);
	const apiError =
		patient.apiError ||
		clinic.apiError ||
		examinationReport.apiError ||
		settings.apiError ||
		lngs.apiError ||
		doctorUser.apiError;

	return { isLoading, isError, apiError };
};
