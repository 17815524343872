import { Box, FormControlLabel, Grid, Radio } from '@material-ui/core';
import { useTranslations } from 'components/utilities';
import { SMOKER_OPTIONS_VALUES } from 'helpers/variables';
import { bool, oneOfType, string } from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';

import { RadioFieldAdapter } from '../../../../Inputs';
import { OPTIONS } from './helpers';
import { useStyles } from './styles';
import useRadioFields from './useRadioFields';

const RadioFields = ({ submitting, smoker }) => {
	const { t } = useTranslations();
	const classes = useStyles();

	const { renderFields } = useRadioFields({ smoker });

	return (
		<Grid item lg={12} xs={12}>
			<Box className={classes.wrapper}>
				<Field
					name={`patientSmoker`}
					label={'patients.form.patient_smoker'}
					component={RadioFieldAdapter}
					required={true}
					disabled={submitting}
					initialVal={SMOKER_OPTIONS_VALUES.NO}
					currentValue={smoker ? smoker : SMOKER_OPTIONS_VALUES.NO}
					row
				>
					<Grid container spacing={1}>
						{OPTIONS.map(({ value, label, grid }) => (
							<Grid item {...grid} key={value}>
								<FormControlLabel
									key={value}
									value={value}
									control={<Radio color="primary" />}
									label={t(label)}
								/>
							</Grid>
						))}
					</Grid>
				</Field>
				<Grid container spacing={2}>
					{renderFields()}
				</Grid>
			</Box>
		</Grid>
	);
};

RadioFields.propTypes = {
	submitting: bool.isRequired,
	smoker: oneOfType([string, bool]).isRequired
};

export default RadioFields;
