import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		border: `1px solid ${theme.palette.gray.main}`,
		borderRadius: '5px',
		display: 'flex',
		flexDirection: 'column',
		padding: '0 20px',
		alignItems: 'center',
		boxShadow:
			'0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
		marginTop: 30
	},
	collapse: {
		width: '100%'
	},
	marginBottom: {
		marginBottom: 20
	},
	table: {
		marginTop: 0
	}
}));
