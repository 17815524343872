// Import context
import { ScheduleCalendarContext } from 'components/context';
import { CardTopBar } from 'components/elements';
import React from 'react';
import { AutoSizer } from 'react-virtualized';

import FullCalendar from './components/FullCalendar';
import TopBar from './components/TopBar';
import { useStyles } from './styles';

const Calendar = () => {
	const classes = useStyles();

	return (
		<AutoSizer disableWidth>
			{({ height }) => (
				<CardTopBar
					className={classes.card}
					cardContentClassName={classes.cardContent}
					style={{ height }}
				>
					<ScheduleCalendarContext.Consumer>
						{({ calendarRef, ...rest }) => (
							<>
								<TopBar />

								<FullCalendar ref={calendarRef} {...rest} />
							</>
						)}
					</ScheduleCalendarContext.Consumer>
				</CardTopBar>
			)}
		</AutoSizer>
	);
};

export default Calendar;
