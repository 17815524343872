import { MutationOptions, useMutation } from 'components/utilities';
import {
	UnregisterFirebaseMessagingTokenRequestModel,
	UnregisterFirebaseMessagingTokenResponseModel
} from 'models/User/User';

import { AccountRepository } from './repository';

export const useUnregisterUserFirebaseMessagingToken = (
	options: MutationOptions<
		UnregisterFirebaseMessagingTokenResponseModel,
		TMyError,
		UnregisterFirebaseMessagingTokenRequestModel
	> = {}
) => {
	const mutation = useMutation(
		AccountRepository.unregisterFirebaseMessagingToken,
		options
	);
	return mutation;
};
