import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		justifyContent: 'flex-end',
		gap: 24,
		alignItems: 'center'
	}
}));
