import { ForcedSpirometryBestParametersModel } from 'models/Report/ForcedSpirometry';
import React from 'react';

import { ContainerWithBorder, Questions } from '../../../components';
import { QUESTIONS } from '../../../SpirometryFVC/helpers';
import { AllMeasurements } from '../AllMeasurements';
import { ArrowSelectors } from '../ArrowSelectors';
import { UseArrowSelectorsProps } from '../ArrowSelectors/useArrowSelectors';
import { TechnicalError } from '../TechnicalError';
import { TypeColumnContainer } from '../TypeColumnContainer';
import { useStyles } from './styles';

export interface PrePostColumnProps extends UseArrowSelectorsProps {
	isBorder?: boolean;
	type: 'pre' | 'post';
	color?: string;
	bestParameters: ForcedSpirometryBestParametersModel | undefined;
}

export const PrePostColumn = ({
	color = '',
	isBorder = false,
	type,
	prePostIndexSetter,
	activeMeasurementIndex,
	measurements,
	bestParameters
}: PrePostColumnProps) => {
	const classes = useStyles();

	return (
		<TypeColumnContainer isBorder={isBorder}>
			<ArrowSelectors
				color={color}
				type={type}
				prePostIndexSetter={prePostIndexSetter}
				activeMeasurementIndex={activeMeasurementIndex}
				measurements={measurements}
			/>
			<ContainerWithBorder className={classes.container}>
				<AllMeasurements
					type={type}
					measurements={measurements}
					bestParameters={bestParameters}
					activeMeasurementIndex={activeMeasurementIndex}
				/>
				<TechnicalError
					measurements={measurements}
					activeMeasurementIndex={activeMeasurementIndex}
				/>
				<Questions
					questions={QUESTIONS}
					answers={
						measurements?.[activeMeasurementIndex]?.additionalInfo
					}
				/>
			</ContainerWithBorder>
		</TypeColumnContainer>
	);
};
