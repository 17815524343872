import { Box, Typography } from '@material-ui/core';
import { AioCareLogo } from 'components/elements/atoms';
import { Alert, Loader } from 'components/elements/molecules';
import { useTranslations } from 'components/utilities';
import React from 'react';

import { useStyles } from './styles';
import useAccountDeletion from './useAccountDeletion';

const AccountDeletionLandingPage = () => {
	const classes = useStyles();
	const { t } = useTranslations();

	const { isLoading, apiError } = useAccountDeletion();

	return (
		<Box className={classes.root}>
			{!isLoading ? (
				<Loader icon={AioCareLogo} />
			) : (
				<Box className={classes.content}>
					<AioCareLogo className={classes.logo} />
					<Alert message={apiError} />
					<Typography variant="h2">
						{t('landing_pages.processing_account_deletion.title')}
					</Typography>
					<Typography className={classes.message}>
						{t('landing_pages.processing_account_deletion.message')}
					</Typography>
				</Box>
			)}
		</Box>
	);
};

export default AccountDeletionLandingPage;
