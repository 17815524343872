import clsx from 'clsx';
import { TRANSITIONS } from 'helpers/variables';
import React from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import { useStyles } from './styles';

export interface Props {
	className?: string;
	state: boolean;
	initialComponent: () => JSX.Element;
	newComponent: () => JSX.Element;
	timeout?: {
		enter?: number;
		exit?: number;
	};
}

export const SwitchComponentWithTransition = ({
	className = '',
	state,
	initialComponent: InitialComponent,
	newComponent: NewComponent,
	timeout = {
		enter: TRANSITIONS.DURATIONS.enteringScreen,
		exit: TRANSITIONS.DURATIONS.leavingScreen
	}
}: Props) => {
	const classes = useStyles({ timeout });
	const initialComponentRef = React.useRef<HTMLDivElement>(null);
	const newComponentRef = React.useRef<HTMLDivElement>(null);
	const nodeRef = state ? initialComponentRef : newComponentRef;

	const renderComponent = React.useCallback(
		() => (state ? <InitialComponent /> : <NewComponent />),
		[state]
	);
	return (
		<SwitchTransition mode="out-in">
			<CSSTransition
				key={state ? `initialComponentRef` : `newComponentRef`}
				nodeRef={nodeRef}
				addEndListener={(done) =>
					nodeRef?.current?.addEventListener(
						'transitionend',
						done,
						false
					)
				}
				classNames={{
					enter: classes.enter,
					exit: classes.exit,
					enterActive: classes.enterActive,
					exitActive: classes.exitActive
				}}
			>
				<div ref={nodeRef} className={clsx([className, classes.root])}>
					{renderComponent()}
				</div>
			</CSSTransition>
		</SwitchTransition>
	);
};
