import { Grid } from '@material-ui/core';
import { ButtonProgress } from 'components/elements';
import { useTranslations } from 'components/utilities';
import React from 'react';
import { useFormState } from 'react-final-form';

import { useStyles } from './styles';

const ActionButtons = () => {
	const { submitting } = useFormState();
	const { t } = useTranslations();
	const classes = useStyles();

	return (
		<Grid item lg={12} xs={12}>
			<ButtonProgress
				color="primary"
				fullWidth
				size="large"
				type="submit"
				variant="contained"
				disabled={submitting}
				className={classes.button}
				classNameWrapper={classes.wrapper}
				isLoading={submitting}
			>
				{t('btn.save')}
			</ButtonProgress>
		</Grid>
	);
};

export default ActionButtons;
