import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		pointerEvents: 'none'
	},
	popover: {
		padding: theme.spacing(1)
	}
}));
