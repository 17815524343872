import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslations } from 'components/utilities';
import React from 'react';

import { CloseButton } from '../../../../atoms';
import { SnackbarProps } from '../../Snackbar';
import { useSnackBarStyles } from '../../styles';
import { useStyles } from './styles';

export interface TitleProps {
	onClose: SnackbarProps['onClose'];
	variant: SnackbarProps['variant'];
	title?: string;
	classes?: {
		root?: string;
		title?: string;
	};
}

export const TopBar = ({
	onClose,
	variant,
	title = '',
	classes = { title: '', root: '' }
}: TitleProps) => {
	const classesStyles = useStyles({ isTitle: !!title, variant });
	const snackbarClasses = useSnackBarStyles({ variant });
	const { t } = useTranslations();

	return (
		<Box className={clsx([classes?.root, classesStyles.topBar])}>
			{title && (
				<Typography
					className={clsx([
						classes.title,
						classesStyles.title,
						snackbarClasses.font
					])}
				>
					{t(title) ?? title}
				</Typography>
			)}
			<CloseButton
				onClick={(e) => {
					onClose(e, 'close');
				}}
				className={classesStyles.icon}
			/>
		</Box>
	);
};
