import { makeStyles } from '@material-ui/styles';
import theme from 'theme';

import { getRootContainerHeight } from '../ExaminationsList/styles';

export const useStyles = makeStyles<typeof theme, { isLoader: boolean }>(
	() => ({
		detailsContainer: {
			display: 'flex',
			flexDirection: 'column',
			gap: 10,
			alignItems: 'center',
			width: '100%'
		},
		loader: {
			backgroundColor: 'white',
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%'
		},
		examinationDetailsContainer: {
			flex: 1,
			position: 'sticky',
			top: 100,
			display: 'flex',
			justifyContent: 'center',
			height: '100%',
			width: '100%',
			minHeight: getRootContainerHeight(),
			borderRightColor: 'red',
			padding: 20,
			borderRadius: 10,
			boxShadow: ({ isLoader }) =>
				isLoader ? 'none' : '0px 0px 8px 1px rgba(74,79,224)'
		}
	})
);
