import { useParametersContext } from '../../../context';

const useGroup = ({ type }) => {
	const { setActiveParameterGroup, activeParameterGroup } =
		useParametersContext();

	const handleSetActiveParameterGroup = () => {
		setActiveParameterGroup(type);
	};

	const isGroupActive = type === activeParameterGroup.type;

	return {
		isGroupActive,
		setActiveParameterGroup: handleSetActiveParameterGroup
	};
};

export default useGroup;
