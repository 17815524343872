import { TextField } from '@material-ui/core';
import { useSelectProps, useTranslations } from 'components/utilities';
import { bool, func, node, object, string } from 'prop-types';
import React from 'react';

const SelectFieldAdapter = ({
	input,
	meta,
	children,
	error,
	helperText = '',
	inputOnChange,
	...rest
}) => {
	const { t } = useTranslations();
	const selectProps = useSelectProps();
	const handleOnChange = (e) => {
		const onChangeInput = inputOnChange?.(e) ?? e;
		input.onChange(onChangeInput);
	};

	return (
		<TextField
			select
			SelectProps={{
				MenuProps: {
					...selectProps
				}
			}}
			{...input}
			{...rest}
			onChange={handleOnChange}
			error={
				error || (meta.touched && (meta.error || meta.submitError))
					? true
					: false
			}
			helperText={
				(meta.touched && (meta.error || meta.submitError)
					? t(meta.error) || meta.submitError
					: '') || helperText
			}
		>
			{children}
		</TextField>
	);
};

SelectFieldAdapter.propTypes = {
	input: object.isRequired,
	meta: object.isRequired,
	children: node.isRequired,
	inputOnChange: func,
	error: bool,
	helperText: string
};

export default SelectFieldAdapter;
