import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import { Align, VariableSizeList } from 'react-window';
import { ReactContext } from 'utilities/ReactContext';

interface IExaminationsV2RefState {
	examinationsListRef: React.RefObject<VariableSizeList>;
	pinnedExaminationContainerRef: React.RefObject<HTMLDivElement>;
	trendChartRef: React.RefObject<HighchartsReact.RefObject>;
}
const { Provider, useStore } =
	ReactContext.createPubSubContext<IExaminationsV2RefState>();

type Props = {
	children: CmpChildren;
};

export const ExaminationsV2RefsProvider = ({ children }: Props) => {
	const examinationsListRef = React.useRef<VariableSizeList>(null);
	const pinnedExaminationContainerRef = React.useRef<HTMLDivElement>(null);
	const trendChartRef = React.useRef<HighchartsReact.RefObject>(null);

	return (
		<Provider
			value={{
				examinationsListRef,
				pinnedExaminationContainerRef,
				trendChartRef
			}}
		>
			{children}
		</Provider>
	);
};

export const useExaminationsV2RefsContext = <Selector,>(
	selector: (s: IExaminationsV2RefState) => Selector = () => null as Selector
) => {
	const { store } = useStore(selector);
	const { store: examinationsListRef } = useStore(
		(s) => s.examinationsListRef
	);

	const resetExaminationsVirtualList = React.useCallback(() => {
		examinationsListRef.current?.resetAfterIndex(0);
	}, []);

	const scrollToExaminationsListItem = React.useCallback(
		(index: number, align: Align = 'start') => {
			if (!examinationsListRef.current) return;
			examinationsListRef.current?.scrollToItem(index, align);
		},
		[]
	);

	return {
		store,
		resetExaminationsVirtualList,
		scrollToExaminationsListItem
	};
};

export default ExaminationsV2RefsProvider;
