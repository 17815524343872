import { Box, Typography } from '@material-ui/core';
import { Divider } from 'components/elements/atoms';
import { useTranslations } from 'components/utilities';
import { isLastArrayIndex } from 'helpers';
import React from 'react';

import { ParameterLabel } from './components';
import { useStyles } from './styles';
import useSelectedParameters from './useSelectedParameters';

const SelectedParameters = () => {
	const classes = useStyles();
	const { t } = useTranslations();

	const parametersLabels = useSelectedParameters();

	return (
		<Box className={classes.root}>
			<Typography className={classes.title} variant="h6" component="p">
				{t('patients.examinations.selected_chart')}
			</Typography>
			<Divider className={classes.divider} orientation="vertical" />
			{parametersLabels.map((parameter, index) => (
				<ParameterLabel
					key={parameter.name}
					{...parameter}
					isLastElement={isLastArrayIndex(index, parametersLabels)}
				/>
			))}
		</Box>
	);
};

export default SelectedParameters;
