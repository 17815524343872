import { Typography as MuiTypography } from '@material-ui/core';
// Import icon
import { ReactComponent as IconCorrect } from 'assets/icons/ic_correct.svg';
import { ReactComponent as IconIncorrect } from 'assets/icons/ic_incorrect.svg';
import { useTranslations } from 'components/utilities';
import { bool, string } from 'prop-types';
import React from 'react';

import { useStyles } from './styles';

const CheckerInfo = ({ text, isValid }) => {
	const classes = useStyles({ isValid });
	const { t } = useTranslations();

	return (
		<MuiTypography key={text} className={classes.text} variant="body1">
			{isValid ? (
				<IconCorrect className={classes.icon} />
			) : (
				<IconIncorrect className={classes.icon} />
			)}{' '}
			{t(text)}
		</MuiTypography>
	);
};

CheckerInfo.propTypes = {
	text: string.isRequired,
	isValid: bool.isRequired
};

export default CheckerInfo;
