import { Box } from '@material-ui/core';
import {
	ParameterLabel,
	Title as TitleAtom
} from 'components/elements/atoms/ExaminationsV2';
import { DATE_FORMAT, TIME_FORMAT } from 'helpers/variables';
import React from 'react';
import { LocaleDate } from 'utilities';

import { useStyles } from './styles';

type Props = {
	title: string;
	createDateTime?: string | null;
};
export const Title = ({ title, createDateTime }: Props) => {
	const classes = useStyles();
	return (
		<Box className={classes.titleContainer}>
			<TitleAtom className={classes.title} title={title} />
			<ParameterLabel
				disableTranslation
				label={
					createDateTime
						? new LocaleDate(createDateTime)
								.getDateWithTimeLabel(
									DATE_FORMAT.DEFAULT,
									TIME_FORMAT.DEFAULT_WITH_SECONDS
								)
								.timeFirst(' | ')
						: ''
				}
			/>
		</Box>
	);
};
