import { produce } from 'immer';
import * as types from 'store/types';

const INITIAL_STATE = {
	authenticate: null,
	isError: false,
	error: '',
	isAuthenticated: false,
	signOut: {
		error: false,
		loading: false
	}
};

export default (state = INITIAL_STATE, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			// ------------ Authenticate user ------------
			case types.AUTH_USER_LOADING:
				return { ...INITIAL_STATE };

			case types.AUTH_USER_SUCCESS:
				draft.authenticate = {
					...action.payload,
					...draft.authenticate
				};
				draft.isError = false;
				draft.isAuthenticated = true;
				return;

			case types.AUTH_USER_ERROR:
				draft.error = action.payload;
				draft.isError = true;
				return;

			// ------------ Refresh token ------------
			case types.AUTH_USER_REFRESH_TOKEN_SUCCESS:
				draft.authenticate.data.token = action.payload;
				return;

			// ------------ Set user profile ------------
			case types.AUTH_USER_PROFILE_SUCCESS:
				draft.authenticate.data.roles = action.payload.clinic_roles;
				draft.authenticate.data.user = {
					...draft.authenticate.data.user,
					...action.payload
				};
				return;

			// ------------ Set user language ------------
			case types.AUTH_SET_USER_LANGUAGE_LOADING:
				draft.authenticate.isLanguageLoading = true;
				draft.authenticate.error = null;
				return;

			case types.AUTH_SET_USER_LANGUAGE:
				draft.authenticate.data.user.language = action.payload;
				draft.authenticate.isLanguageLoading = false;
				draft.authenticate.error = null;
				return;

			case types.AUTH_USER_LANGUAGE_ERROR:
				draft.authenticate.isLanguageLoading = false;
				draft.authenticate.error = action.payload;
				return;

			// ------------ Set user first and last name ------------
			case types.AUTH_SET_USER_FIRST_LAST_NAME:
				const { firstName, lastName } = action.payload;
				draft.authenticate.data.user.first_name = firstName;
				draft.authenticate.data.user.last_name = lastName;
				return;

			// ------------ Sign out ------------
			case types.AUTH_SIGN_OUT_LOADING:
				draft.signOut.loading = true;
				return;

			case types.AUTH_SIGN_OUT_SUCCESS:
				return { ...INITIAL_STATE };

			case types.AUTH_SIGN_OUT_ERROR:
				draft.signOut.loading = false;
				draft.signOut.error = true;
				return;

			default:
				return state;
		}
	});
