import { Box } from '@material-ui/core';
import React from 'react';

import { AddAppointment, EventBox } from './components';
import { useStyles } from './styles';
import useAppointment from './useAppointment';

const Appointment = (props) => {
	const { getEventBoxProps, stylesProps, isMirror } = useAppointment(props);
	const classes = useStyles(stylesProps);

	return (
		<Box className={classes.root}>
			{isMirror ? (
				<AddAppointment />
			) : (
				<EventBox {...getEventBoxProps()} stylesProps={stylesProps} />
			)}
		</Box>
	);
};

export default Appointment;
