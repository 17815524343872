import { AccountLinkCollapsedChip } from 'components/templates/Patients/AccountLinkCollapsedChip';
import React from 'react';

import { Ctx } from '../types';
import { usePatientFullName } from '../usePatientFullName';

export const AccountLinkCell = ({ ctx }: Ctx) => {
	const patientFullName = usePatientFullName({ ctx });
	return (
		<AccountLinkCollapsedChip
			initialEmail={ctx.row.original.email ?? ''}
			invitationStatus={ctx.row.original.invitationStatus}
			patientFullName={patientFullName}
			patientId={ctx.row.original.id}
			variant="tableCell"
		/>
	);
};
