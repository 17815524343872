import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: 12.5,
		borderTop: `1px solid ${theme.palette.border.light}`,
		borderBottom: `1px solid ${theme.palette.border.light}`
	},
	titleWrapper: {
		paddingBottom: 12.5,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	title: {
		marginRight: 79
	}
}));
