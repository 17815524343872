import { Box, Typography as MuiTypography } from '@material-ui/core';
import { Alert as MaterialAlert, AlertTitle } from '@material-ui/lab';
import clsx from 'clsx';
import { useTranslations } from 'components/utilities';
import React from 'react';
import { CSSTransition } from 'react-transition-group';

import { TRANSITION_DURATION, useStyles } from './styles';

export interface IAlertProps {
	message: AlertMessage | undefined;
	type?: 'success' | 'info' | 'warning' | 'error';
	title?: string;
	apiFieldErrorTranslationKeyPrefix?: string;
	className?: string;
	classNameAlert?: string;
	disableTransition?: boolean;
}

const Alert = ({
	type = 'error',
	title = 'global.error',
	message,
	apiFieldErrorTranslationKeyPrefix = '',
	className = '',
	classNameAlert = '',
	disableTransition = false
}: IAlertProps) => {
	const classes = useStyles();
	const { t } = useTranslations();

	const [errorMessage, setErrorMessage] = React.useState<
		AlertMessage | undefined
	>(message);

	const renderMessage = ():
		| JSX.Element[]
		| React.ReactElement[]
		| AlertMessage => {
		if (!errorMessage) {
			return '';
		}

		if (typeof errorMessage === 'string') {
			return t(errorMessage) || errorMessage;
		}

		if (errorMessage[0]?.name) {
			return errorMessage.map(({ description, name }) => {
				const translatedName: React.ReactElement[] | null = t(
					`${apiFieldErrorTranslationKeyPrefix}.${name}`
				);

				return (
					<MuiTypography
						className={classes.error}
						key={name}
						variant="body1"
						component="p"
					>
						<strong style={{ textTransform: 'none' }}>
							{translatedName ? translatedName : name}
						</strong>
						{': '}
						{description}
					</MuiTypography>
				);
			});
		}

		return errorMessage;
	};

	const renderAlert = () => (
		<Box className={clsx(classes.root, className)}>
			<MaterialAlert
				className={classNameAlert}
				severity={type}
				data-testid="alertMessage"
			>
				<>
					<AlertTitle>{t(title)}</AlertTitle>
					{renderMessage()}
				</>
			</MaterialAlert>
		</Box>
	);

	return disableTransition ? (
		renderAlert()
	) : (
		<CSSTransition
			in={!!message}
			timeout={TRANSITION_DURATION}
			mountOnEnter
			unmountOnExit
			onEnter={() => {
				setErrorMessage(message);
			}}
			onExited={() => {
				setErrorMessage(message);
			}}
			classNames={{
				enter: classes.transitionEnter,
				enterActive: classes.transitionEnterActive,
				exit: classes.transitionExit,
				exitActive: classes.transitionExitActive
			}}
		>
			{renderAlert()}
		</CSSTransition>
	);
};

export default Alert;
