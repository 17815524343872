import { Container } from 'components/elements/atoms/ExaminationsV2';
import { CommonReportFieldsModel } from 'models/Report/Reports';
import { useGetCachedReportData } from 'queries/Examinations/ReportsV2/getter';
import React from 'react';

import { LabeledValue } from '../../../../../../../../components/elements/molecules/ExaminationsV2/LabeledValue';
import { ReportTemplateConfig } from '../../templates/config';
import { getSubheaderLabels } from './helpers';
import { useStyles } from './styles';

type Props = {
	refValue?: ReportTemplateConfig['refValue'];
};

export const SubHeader = ({ refValue }: Props) => {
	const classes = useStyles();
	const { examination } = useGetCachedReportData<CommonReportFieldsModel>();

	const data = React.useMemo(
		() => ({
			examinationCreateDateTime: examination?.examinationCreateDateTime,
			referenceStandard: examination?.referenceStandard
		}),
		[examination?.examinationCreateDateTime, examination?.referenceStandard]
	);

	return (
		<Container className={classes.root}>
			{getSubheaderLabels({ refValue }).map((subheader) =>
				subheader.disabled ? null : (
					<LabeledValue
						key={subheader?.fieldName}
						labelProps={{
							className: classes.label,
							label: subheader?.label || ''
						}}
						valueProps={{
							value:
								data?.[subheader?.fieldName] &&
								subheader?.valueConverter(
									data?.[subheader?.fieldName]
								)
						}}
					/>
				)
			)}
		</Container>
	);
};
