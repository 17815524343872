import React from 'react';

import { TableRow } from '../TableRow';

export interface IFullWidthTableRowCellProps {
	numberOfColumns: number;
	children: CmpChildren;
}

export const FullWidthTableRowCell = ({
	numberOfColumns,
	children
}: IFullWidthTableRowCellProps) => {
	return (
		<TableRow>
			<td colSpan={numberOfColumns}>{children}</td>
		</TableRow>
	);
};
