import { makeStyles } from '@material-ui/styles';
import theme from 'theme';

import { Props } from './Grade';

export const useStyles = makeStyles<typeof theme, Partial<Props>>((theme) => ({
	valueContainer: ({ isActive }) => {
		const classes = isActive
			? {
					'-webkit-print-color-adjust': 'exact',
					backgroundColor: theme.palette.primary.dark,
					border: `1px solid ${theme.palette.primary.dark}`,

					'& p': {
						color: '#FFFFFF',
						lineHeight: '8px'
					}
					//eslint-disable-next-line
			  }
			: {
					border: '1px solid #E2E2E2',
					'& p': {
						color: '#000000',
						lineHeight: '8px'
					}
					//eslint-disable-next-line
			  };
		return {
			...classes,
			borderRadius: 50,
			padding: '3px 8px',
			paddingBottom: 4,
			width: '26px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center'
		};
	}
}));
