import { Box } from '@material-ui/core';
import React from 'react';

import { ILabel, Label } from './Label';
import { useStyles } from './styles';

export interface ILabelWithSecondaryText {
	label: ILabel['labelKey'];
	secondaryText: ILabel['labelKey'];
	helperText?: ILabel['labelKey'];
}

export const LabelWithSecondaryText = ({
	label,
	secondaryText,
	helperText
}: ILabelWithSecondaryText) => {
	const classes = useStyles();
	return (
		<Box className={classes.labelWithSecondaryText}>
			<Box>
				<Label labelKey={label} />
				{helperText && (
					<Label
						className={classes.helperText}
						labelKey={helperText}
					/>
				)}
			</Box>
			<Label labelKey={secondaryText} className={classes.secondaryText} />
		</Box>
	);
};
