import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { Search } from 'components/elements';
import _ from 'lodash';
import { func, string } from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
	root: {
		paddingBottom: 18
	},
	card: {
		'& .MuiCardContent-root:last-child': {
			paddingBottom: 16
		}
	},
	spacer: {
		flexGrow: 1
	},
	importButton: {
		marginRight: theme.spacing(1)
	},
	exportButton: {
		marginRight: theme.spacing(1)
	},
	searchInput: {
		marginRight: theme.spacing(1)
	}
}));

const ToolBarFilters = () => null;

const Toolbar = ({
	className,
	setGlobalFilter,
	globalFilter,
	toolbarComponent: ToolbarComponent = ToolBarFilters,
	...rest
}) => {
	const classes = useStyles();
	const [query, setQuery] = React.useState(globalFilter);

	const delayedQuery = React.useRef(
		_.debounce((value) => setGlobalFilter(value), 400)
	).current;

	const handleOnChangeQuery = (e) => {
		e?.persist?.();
		const value = e.target.value;
		setQuery(value);
		delayedQuery(value);
	};

	const handleClearQuery = () => {
		setQuery('');
		setGlobalFilter('');
	};

	return (
		<Grid
			container
			{...rest}
			justifyContent="flex-start"
			className={clsx([className, classes.root])}
		>
			<Search
				setQuery={handleOnChangeQuery}
				clearQuery={handleClearQuery}
				query={query}
				placeholder={'table.search'}
			/>
			<Box className={classes.spacer} />
			<ToolbarComponent />
		</Grid>
	);
};
Toolbar.propTypes = {
	className: string,
	setGlobalFilter: func.isRequired,
	toolbarComponent: func,
	globalFilter: string
};

export default Toolbar;
