import clsx from 'clsx';
import { Circle } from 'components/elements/atoms';
import { func, number } from 'prop-types';
import React from 'react';
import theme from 'theme';

import { Tooltip } from '../../../Tooltips';
import { useStyles } from './styles';
import { TooltipContent } from './TooltipContent';

const ZScorePoint = ({ value, getValuePosition }) => {
	const pointRightPosition = `${getValuePosition(value)}%`;
	const classes = useStyles({ pointRightPosition });

	return (
		<Tooltip
			classNamePopoverWrapper={classes.popoverWrapper}
			anchorOrigin={{
				vertical: 'center',
				horizontal: 'right'
			}}
			transformOrigin={{
				vertical: 'bottom',
				horizontal: 'left'
			}}
			tooltipContent={() => <TooltipContent value={value} />}
		>
			<Circle
				size={8}
				color={theme.palette.gray.medium}
				className={clsx([classes.circle, 'z-score--point'])}
			/>
		</Tooltip>
	);
};

ZScorePoint.propTypes = {
	value: number.isRequired,
	getValuePosition: func.isRequired
};

export default ZScorePoint;
