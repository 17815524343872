import { Box, IconButton, Typography } from '@material-ui/core';
import { ExpandLessIcon, ExpandMoreIcon } from 'assets/icons/commonMUIIcons';
import { useTranslations } from 'components/utilities';
import { bool, func } from 'prop-types';
import React from 'react';

import { useStyles } from './styles';

const Title = ({ toggleCollapse, isOpen }) => {
	const { t } = useTranslations();
	const classes = useStyles();

	const CollapseIcon = isOpen ? ExpandLessIcon : ExpandMoreIcon;

	return (
		<Box className={classes.container}>
			<Typography
				variant="h4"
				component="span"
				className={classes.typography}
			>
				{t('patients.drugs.table.archived_drugs')}
			</Typography>
			<IconButton
				size="small"
				onClick={toggleCollapse}
				className={classes.button}
			>
				<CollapseIcon color="primary" className={classes.icon} />
			</IconButton>
		</Box>
	);
};

Title.propTypes = {
	toggleCollapse: func.isRequired,
	isOpen: bool.isRequired
};

export default Title;
