import { Box, MenuItem, useMediaQuery } from '@material-ui/core';
import React from 'react';
import theme from 'theme';

import { Checkbox } from '../common';
import { LabelWithSecondaryText } from '../common/Label';
import { ILabel } from '../common/Label/Label';
import { useCommonStyles } from '../common/styles';
import { useStyles } from './styles';

type Props = {
	onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	onSelectOne?: () => void;
	onSelectAll?: () => void;
	disabled?: boolean;
	disableAllActions?: boolean;
	isSelected: boolean;
	isOneSelected: boolean;
	label: ILabel['labelKey'];
	state: AnyType[] | AnyType;
};

export const MenuItemWithCheckBox = React.forwardRef<HTMLLIElement, Props>(
	(props, ref) => {
		const commonClasses = useCommonStyles();
		const classes = useStyles();

		const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
			defaultMatches: true
		});
		const [secondaryText, setSecondaryText] = React.useState<string>('');

		const handleToggle = React.useCallback(
			(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
				e?.stopPropagation?.();
				if (props.disableAllActions || props.disabled) {
					return;
				}
				if (
					props.isSelected &&
					props.isOneSelected &&
					props.onSelectAll
				) {
					props.onSelectAll();
					setSecondaryText('global.toggle');
					return;
				}
				props?.onClick?.(e);
			},
			[props.isSelected, props.isOneSelected]
		);

		const makeActionWithSecondaryText = React.useCallback(
			(
				toggle?: () => void,
				selectOne?: () => void,
				selectAll?: () => void
			) => {
				if (props.disableAllActions || props.disabled) {
					return;
				}

				if (toggle && !selectOne && !selectAll) {
					setSecondaryText('global.toggle');
					toggle();
					return;
				}

				if (props.isSelected && props.isOneSelected && selectAll) {
					setSecondaryText('global.only');
					selectAll();
					return;
				}

				if (selectOne) {
					setSecondaryText('global.all');
					selectOne();
					return;
				}
			},
			[props.isOneSelected, props.isSelected]
		);

		const handleMenuItemClick = () => {
			makeActionWithSecondaryText(
				props?.onClick,
				props.onSelectOne,
				props.onSelectAll
			);
		};

		const handleMouseOver = () => {
			makeActionWithSecondaryText(
				() => {
					setSecondaryText('global.toggle');
				},
				() => {
					setSecondaryText('global.only');
				},
				() => {
					setSecondaryText('global.all');
				}
			);
		};

		return (
			<Box className={commonClasses.rootContainer}>
				<Checkbox
					disabled={props?.disabled ?? false}
					className={classes.checkBox}
					onMouseOver={() => {
						if (props.disabled || props.disableAllActions) {
							return;
						}
						if (
							props.isSelected &&
							props.isOneSelected &&
							props.onSelectAll
						) {
							setSecondaryText('global.all');
							return;
						}
						setSecondaryText('global.toggle');
					}}
					onMouseLeave={() => {
						setSecondaryText('');
					}}
					isSelected={props.isSelected}
					onClick={handleToggle}
				/>
				<MenuItem
					disabled={props?.disabled ?? false}
					className={classes.menuItem}
					ref={ref}
					onClick={handleMenuItemClick}
					onMouseOver={handleMouseOver}
					onMouseLeave={() => {
						setSecondaryText('');
					}}
				>
					<LabelWithSecondaryText
						helperText={
							props.disabled
								? 'patients.examinations_v2.no_examinations_in_type'
								: ''
						}
						label={props.label}
						secondaryText={isDesktop ? secondaryText : ''}
					/>
				</MenuItem>
			</Box>
		);
	}
);
