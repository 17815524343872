import palette from '../palette';

export default {
	root: {
		textAlign: 'left',
		fontSize: '16px',
		fontWeight: '400',
		lineHeight: '17.6px',
		letterSpacing: '0.15px',
		height: 48,
		padding: '0px 16px',
		borderBottom: 'inherit',
		paddingTop: '4px'
	},
	body: {
		color: palette.gray.medium
	}
};
