import { NotistackSnackbar } from 'components/elements';
import { ISnackbarMessageProps } from 'components/elements/molecules/Snackbar/components';
import { CustomContentProps } from 'notistack';
import React from 'react';

export interface IErrorNotificationProps
	extends Omit<CustomContentProps, 'variant' | 'message'> {
	contentMessage: ISnackbarMessageProps['message'];
	title?: string;
}

export const ErrorNotification = React.forwardRef<
	HTMLDivElement,
	IErrorNotificationProps
>(({ title, contentMessage, ...rest }, ref) => {
	return (
		<NotistackSnackbar
			{...rest}
			contentVariant={'error'}
			ref={ref}
			titleProps={{ title: title }}
			message={contentMessage}
		/>
	);
});
