import React from 'react';

import { Stepper, Steps } from './types';
import {
	isCustomStepValid,
	isNextStepValid,
	isPrevStepValid
} from './validators';

type Props = {
	steps: Steps;
};

const useStepper = ({ steps }: Props): Stepper => {
	const [stepIndex, setStepIndex] = React.useState(0);

	const handleNextStep = () => {
		setStepIndex((prevStepIndex) => {
			const newStepIndex = prevStepIndex + 1;
			if (!isNextStepValid(newStepIndex, steps)) {
				return 0;
			}
			return newStepIndex;
		});
	};
	const handlePrevStep = () => {
		setStepIndex((prevStepIndex) => {
			const newStepIndex = prevStepIndex - 1;
			if (!isPrevStepValid(newStepIndex, steps)) {
				return steps.length - 1;
			}
			return newStepIndex;
		});
	};

	const handleSetStep = (newStep?: number) => {
		if (newStep === undefined || newStep === null) {
			return;
		}
		const newStepIndex = steps.indexOf(newStep);
		if (isCustomStepValid(newStepIndex, steps)) {
			setStepIndex(newStepIndex);
		}
	};

	const handleResetSteps = () => {
		setStepIndex(0);
	};

	return {
		stepIndex,
		availableSteps: steps,
		actions: {
			nextStep: handleNextStep,
			prevStep: handlePrevStep,
			setStep: handleSetStep,
			resetSteps: handleResetSteps
		}
	};
};

export default useStepper;
