import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column'
	},
	textField: { paddingRight: 0 }
}));
