import { Grid } from '@material-ui/core';
import React from 'react';

import { DateField } from './DateField';
import { DurationField } from './DurationField';
import TimeField from './TimeField';

const DateFields = () => {
	return (
		<Grid item xs={12}>
			<Grid container spacing={1}>
				<Grid item xs={6} sm={4} md={6} lg={6} xl={4}>
					<DateField />
				</Grid>
				<Grid item xs={6} sm={4} md={6} lg={6} xl={4}>
					<TimeField />
				</Grid>
				<Grid item xs={6} sm={4} md={6} lg={6} xl={4}>
					<DurationField />
				</Grid>
			</Grid>
		</Grid>
	);
};

export default DateFields;
