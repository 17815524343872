import { useExaminationsTrendChartContext } from 'components/context/ExaminationsV2';
import { CardSpinner, Placeholder } from 'components/elements';
import React from 'react';

import { Chart } from './Chart';
import { useStyles } from './styles';

const ExaminationsCharts = () => {
	const { isLoading, selectedParameters } =
		useExaminationsTrendChartContext();

	const classes = useStyles();
	return (
		<Placeholder
			classes={{
				root: classes.placeholderRoot
			}}
			active={
				selectedParameters === null || selectedParameters?.length === 0
			}
			label={'patients.examinations.no_parameters_has_been_selected'}
		>
			<CardSpinner
				showLoadingContent={true}
				className={{
					classCard: classes.root,
					classContent: classes.root
				}}
				isLoading={isLoading}
			>
				<Chart />
			</CardSpinner>
		</Placeholder>
	);
};

export default ExaminationsCharts;
