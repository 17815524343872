import { ParametersTable as ParametersTableCmp } from 'components/elements/organisms/ExaminationsV2';
import { ParametersTableProps } from 'components/elements/organisms/ExaminationsV2/ParametersTable/ParametersTable';
import React from 'react';

import { useLocalTheme } from './styles';

export const ParametersTable = <TData, ColumnsAccessors>(
	props: ParametersTableProps<TData, ColumnsAccessors>
) => {
	return <ParametersTableCmp {...props} useLocalTheme={useLocalTheme} />;
};
