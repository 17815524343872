import {
	Box,
	Typography as MuiTypography,
	useMediaQuery
} from '@material-ui/core';
import { useTranslations } from 'components/utilities';
import { DOSAGE_FIELDS, DOSAGES } from 'helpers/drugs/drugs';
import { bool, func, object, string } from 'prop-types';
import React from 'react';

import OptionButton from '../OptionButton';
import { WEEK_DAYS_FIELDS } from './helpers';
import { useStyles } from './styles';
import useDosageFields from './useDosageFields';

const DosageFields = ({
	dosage,
	setDosageType,
	setDosageDays,
	errors,
	submitFailed,
	classNameRoot = ''
}) => {
	const matches = useMediaQuery('(max-width:1500px)');
	const classes = useStyles();
	const { t } = useTranslations();
	const { setDosage, dosage_schedule, setWeekDays, weekDays, setVariant } =
		useDosageFields({
			dosage,
			setDosageType,
			setDosageDays
		});

	return (
		<Box className={classNameRoot}>
			<MuiTypography
				variant="body2"
				component="p"
				className={classes.typography}
			>
				{t('patients.action_plan.create.medications.dosage')}
			</MuiTypography>
			{submitFailed && (errors.days || errors.drug?.days) && (
				<MuiTypography variant="body2" component="p" color="error">
					{t(errors.days || errors.drug?.days)}
				</MuiTypography>
			)}
			<Box className={matches ? classes.dosageWrapper : ''}>
				{DOSAGE_FIELDS.map(({ value, text }) => (
					<OptionButton
						key={value}
						text={text}
						value={value}
						state={dosage_schedule}
						onClick={() => setDosage(value)}
						variant={setVariant(value, dosage_schedule)}
					/>
				))}
			</Box>
			{dosage_schedule === DOSAGES.SPECIFIC_DAYS && (
				<Box className={classes.days}>
					{WEEK_DAYS_FIELDS.map(({ value, text }) => (
						<OptionButton
							key={value}
							text={text}
							value={value}
							state={weekDays}
							onClick={() => setWeekDays(value)}
							variant={setVariant(value, weekDays)}
							className={classes.button}
						/>
					))}
				</Box>
			)}
		</Box>
	);
};

DosageFields.propTypes = {
	dosage: object.isRequired,
	setDosageType: func.isRequired,
	setDosageDays: func.isRequired,
	errors: object,
	submitFailed: bool,
	classNameRoot: string
};

export default DosageFields;
