import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	button: {
		minWidth: 40,
		width: 40,
		height: 40,
		padding: 0
	},
	typography: {
		marginBottom: 5
	},
	wrapper: {
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
		marginTop: theme.spacing(1)
	}
}));
