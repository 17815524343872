import { makeStyles } from '@material-ui/styles';
import theme from 'theme';

export const useStyles = makeStyles<typeof theme, { isSvc: boolean }>(() => ({
	bestResults: {
		display: 'flex',
		flexDirection: 'column',
		gap: 5,
		paddingTop: 0,
		paddingBottom: 0,
		marginTop: ({ isSvc }) => (isSvc ? 0 : -10)
	},
	title: { marginBottom: 0 }
}));
