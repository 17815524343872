import { Grid } from '@material-ui/core';
import { string } from 'prop-types';
import React from 'react';

import {
	DaySelectField,
	MonthSelectField,
	YearSelectField
} from '../../Inputs';

const DatePicker = ({
	margin = 'dense',
	yearFieldName = 'year',
	monthFieldName = 'month',
	dayFieldName = 'day'
}) => {
	const fieldNames = { yearFieldName, monthFieldName, dayFieldName };
	return (
		<Grid container spacing={1}>
			<Grid item lg={3} xs={3}>
				<YearSelectField
					{...fieldNames}
					name={yearFieldName}
					margin={margin}
				/>
			</Grid>
			<Grid item lg={3} xs={3}>
				<MonthSelectField
					{...fieldNames}
					name={monthFieldName}
					margin={margin}
				/>
			</Grid>
			<Grid item lg={3} xs={3}>
				<DaySelectField
					{...fieldNames}
					name={dayFieldName}
					margin={margin}
				/>
			</Grid>
		</Grid>
	);
};

DatePicker.propTypes = {
	yearFieldName: string,
	monthFieldName: string,
	dayFieldName: string,
	margin: string
};

export default DatePicker;
