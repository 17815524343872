import { IColumn } from 'components/elements/organisms/ExaminationsV2/ParametersTable/ParametersTable';
import { ReportParameterModel } from 'models/Report/common';
import { ColumnValues } from 'utilities/Examinations/ExaminationDetails/common';
import { columns as commonParametersColumns } from 'utilities/Examinations/ExaminationDetails/ForcedSpirometryFVC';

type Accessor = Leaves<ColumnValues<ReportParameterModel & { best: number }>>;

const createColumns = (): IColumn<Accessor>[] => {
	const columnsCopy = [...commonParametersColumns] as IColumn<Accessor>[];
	columnsCopy.splice(1, 0, {
		Header: 'patients.examinations_v2.details.spirometry.all_measurements.best_column',
		accessor: 'best'
	});
	return columnsCopy;
};

export const columns = createColumns();
