export const popoverSettings = {
	anchorOrigin: {
		vertical: 'center',
		horizontal: 'center'
	},
	transformOrigin: {
		vertical: 'top',
		horizontal: 'right'
	}
};
