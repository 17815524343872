import { z } from 'zod';

export const INVITATION_STATUS = {
	LINKED: 'LINKED',
	UNLINKED: 'UNLINKED',
	PENDING: 'PENDING'
} as const;

export const InvitationStatusSchema = z.union([
	z.literal(INVITATION_STATUS.LINKED),
	z.literal(INVITATION_STATUS.UNLINKED),
	z.literal(INVITATION_STATUS.PENDING)
]);
export type InvitationStatus = z.infer<typeof InvitationStatusSchema>;

export const PatientSchema = z.object({
	patient: z.object({
		invitationStatus: InvitationStatusSchema,
		id: z.union([z.number().int(), z.string().uuid()]),
		created_at: z.string(),
		patientFirstName: z.string(),
		patientLastName: z.string(),
		patientDob: z.string(),
		age: z.number(),
		patientEmail: z.string().nullable(),
		patientPhonePrefix: z.string().nullable(),
		patientPhoneNumber: z.string().nullable(),
		patientIdNumber: z.union([z.string(), z.number().int()]).nullable(),
		patientDrugs: z.boolean(),
		patientSmoker: z.union([
			z.boolean(),
			z.literal('no'),
			z.literal('yes'),
			z.literal('passive'),
			z.literal('ex_smoker')
		]),
		patientSmokerCigarettes: z.number().nullable(),
		patientSmokerYears: z.number().nullable(),
		patientRace: z.string(),
		patientGender: z.string(),
		patientWeight: z.string(),
		patientWeightUnit: z.string(),
		patientHeight: z.string(),
		patientHeightUnit: z.string(),
		patientCity: z.string().nullable(),
		patientNationality: z.string().nullable(),
		patientCountryCode: z.string().nullable(),
		patientDescription: z.string().nullable(),
		patientPassiveSmoker: z.boolean(),
		patientPassiveSmokerYears: z.number().nullable(),
		patientTags: z.string().nullable(),
		patientExSmoker: z.boolean(),
		patientExSmokerSince: z.union([z.number(), z.string()]).nullable(),
		patientBmi: z.number().optional()
	})
});

export type PatientModel = z.infer<typeof PatientSchema>;
