import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	button: {
		textTransform: 'none'
	},
	wrapper: {
		width: '100%',
		marginTop: theme.spacing(3)
	}
}));
