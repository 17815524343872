import { DOSAGES, MEDICATION_TYPES_FIELDS } from 'helpers';

export const validation =
	({ dosage, dosage_form, type }) =>
	(values) => {
		const errors = { drug: {} };

		const { drug } = values;
		const {
			dosage_schedule,
			days,
			taking,
			add_to_action_plan,
			plan_type,
			name
		} = { ...drug, ...dosage };

		const isTimeOfDayFilled =
			taking.length !== 0
				? taking
						.map(({ applying_way, amount }) => {
							if (amount === '' || applying_way === '')
								return true;
							return false;
						})
						.includes(true)
				: false;

		if (!dosage_form) {
			errors.drug.dosage_form = 'patients.drugs.form.validation.type';
		} else if (dosage_form === MEDICATION_TYPES_FIELDS[0].value && !type) {
			errors.drug.type = 'patients.drugs.form.validation.inhaler_type';
		}

		//TODO:
		// if (!add_to_action_plan) {
		// 	errors.drug.add_to_action_plan =
		// 		'patients.drugs.form.validation.add_to_action_plan';
		// }

		if (!name) {
			errors.drug.name = 'patients.drugs.form.validation.medication_name';
		}

		if (add_to_action_plan === 'yes' && !plan_type) {
			errors.drug.plan_type = 'patients.drugs.form.validation.plan_type';
		}

		if (!dosage_schedule) {
			errors.drug.dosage_schedule =
				'patients.drugs.form.validation.dosage_type';
		}

		if (dosage_schedule === DOSAGES.SPECIFIC_DAYS && days.length === 0) {
			errors.drug.days = 'patients.drugs.form.validation.days';
		}

		if (dosage_schedule !== DOSAGES.WHEN_NEEDED) {
			if (taking.length === 0) {
				errors.drug.taking = 'patients.drugs.form.validation.taking';
			} else if (isTimeOfDayFilled) {
				errors.drug.taking =
					'patients.drugs.form.validation.all_fields_required';
			}
		}

		return errors;
	};
