import { numberRegex } from 'helpers';
import { useShareReportByRecipientEmail } from 'queries/Examinations/ReportsV2';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'utilities';
import { ACTIVE_EXAMINATIONS_SEARCH_PARAMS } from 'utilities/ReactRouterDomHooks/implementations/examinations/helpers';

import { FIELD_NAMES, ShareReportFormFieldValuesModel } from './helpers';

type Selector = {
	auth: { authenticate: { data: { user: { email: string } } } };
};

export const useShareReportCell = () => {
	const userEmail = useSelector<Selector, string>(
		(store) => store.auth.authenticate.data.user.email
	);

	const { getSearchParam } = useSearchParams();
	const { patientId } = useParams();

	const { mutateAsync, reset, apiError, isSuccess } =
		useShareReportByRecipientEmail();

	const handleSubmit = async (data: ShareReportFormFieldValuesModel) => {
		const examinationId = getSearchParam(
			ACTIVE_EXAMINATIONS_SEARCH_PARAMS.ID
		);
		const parsedExaminationId =
			examinationId && numberRegex.test(examinationId)
				? Number(examinationId)
				: examinationId ?? '';

		await mutateAsync({
			patientId: Number(patientId),
			examinationId: parsedExaminationId,
			examinationType: getSearchParam(
				ACTIVE_EXAMINATIONS_SEARCH_PARAMS.TYPE
			) as ExaminationType,
			recipientEmail: data.recipientEmail
		});
	};

	const initialValues = { [FIELD_NAMES.RECIPIENT_EMAIL]: userEmail };

	return {
		onSubmit: handleSubmit,
		initialValues,
		apiError,
		isSuccess,
		resetMutation: reset
	};
};
