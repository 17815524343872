import { Popover as MuiPopover } from '@material-ui/core';
import clsx from 'clsx';
import { bool, func, node, object, string } from 'prop-types';
import React from 'react';

import { popoverSettings } from './helpers';
import { useStyles } from './styles';

const Popover = ({
	children,
	anchorEl = null,
	open,
	onClose = () => {},
	className = '',
	...rest
}) => {
	const classes = useStyles();

	return (
		<MuiPopover
			className={classes.root}
			classes={{ paper: clsx([className, classes.popover]) }}
			anchorEl={anchorEl}
			open={open}
			onClose={onClose}
			disableRestoreFocus
			{...popoverSettings}
			{...rest}
		>
			{children}
		</MuiPopover>
	);
};

Popover.propTypes = {
	children: node.isRequired,
	anchorEl: object,
	open: bool.isRequired,
	onClose: func,
	className: string
};

export default Popover;
