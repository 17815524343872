import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		margin: '0 30px',
		height: 100
	},
	role: {
		fontWeight: 400,
		fontSize: 14
	},
	doctorName: {
		fontSize: 18,
		fontWeight: 500
	}
}));
