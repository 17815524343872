export const getXAxisLabels = ({
	scaleValuesQuantity,
	xAxisNonPositiveValues
}) => {
	const xAxisLabels = new Array(scaleValuesQuantity)
		.fill('')
		.map((_, index) => index - xAxisNonPositiveValues);

	return xAxisLabels;
};
