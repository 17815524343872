const useDosageFields = ({ dosage, setDosageType, setDosageDays }) => {
	const { days, dosage_schedule } = dosage;

	const handleWeekDays = (value) => {
		!days.includes(value)
			? setDosageDays([value, ...days])
			: removeWeekDay(value);
	};

	const handleSetDosage = (value) => {
		if (dosage !== value) {
			setDosageType(value);
		}
	};

	const setVariant = (value, type) =>
		type.includes(value) ? 'contained' : 'outlined';

	const removeWeekDay = (value) => {
		const newWeekDays = days.filter((day) => day !== value);
		setDosageDays(newWeekDays);
	};

	return {
		setDosage: handleSetDosage,
		dosage_schedule,
		setWeekDays: handleWeekDays,
		weekDays: days,
		setVariant
	};
};

export default useDosageFields;
