import { Box } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import { ParameterLabel } from '../Typography';
import { useStyles } from './styles';

type Props = {
	parameters: string[];
	className?: string;
	parameterLabelClassName?: string;
};

export const StackedParameters = ({
	parameters,
	className,
	parameterLabelClassName
}: Props) => {
	const classes = useStyles();
	return (
		<Box className={clsx([classes.parameterLabel, className])}>
			{parameters.map((parameterLabel) => (
				<div key={parameterLabel}>
					<ParameterLabel
						className={parameterLabelClassName}
						disableTranslation
						label={parameterLabel + ':'}
					/>
				</div>
			))}
		</Box>
	);
};
