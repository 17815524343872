import { LinkMenuItem } from 'components/elements';
import { TableCellContent } from 'components/elements/molecules/Table';
import { PATIENTS_DETAILS_PATHS } from 'components/routes/paths';
import React from 'react';
import { generatePath } from 'react-router-dom';

import { Ctx } from '../types';
import { DeletionModalItem } from './DeletionModalItem';
import { PatientInvitationMenuItem } from './PatientInvitationMenuItem/PatientInvitationMenuItem';

export const ActionsCell = ({ ctx }: Ctx) => {
	return (
		<TableCellContent.Actions>
			{({ close }) => (
				<>
					<PatientInvitationMenuItem ctx={ctx} close={close} />
					<LinkMenuItem
						path={generatePath(PATIENTS_DETAILS_PATHS.BASIC_DATA, {
							patientId: ctx.row.original.id
						})}
						label={'btn.edit'}
						closeMenu={close}
					/>
					<DeletionModalItem ctx={ctx} close={close} />
				</>
			)}
		</TableCellContent.Actions>
	);
};
