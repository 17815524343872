import { differenceInMinutes } from 'date-fns';

export class Appointment {
	constructor(duration, alert, note, patient, date) {
		this.duration = duration;
		this.alert_before = alert;
		this.notes = note;
		this.patient = patient;
		this.date = date;
	}

	setAppointmentData(alert, note, dates, patient) {
		this.duration = getDurationValue(dates);
		this.date = dates.start;
		this.alert_before = alert;
		this.notes = note;
		this.patient = patient;
	}
}

function getDurationValue(dates) {
	const { start, end } = dates;
	return differenceInMinutes(new Date(end), start);
}
