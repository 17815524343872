import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import { addPropsToChildren, PopoverRPC } from 'components/utilities';
import { useTranslations } from 'components/utilities';
import { arrayOf, func, node, object, oneOfType, string } from 'prop-types';
import React from 'react';

import { useStyles } from './styles';

const Tooltip = ({
	tooltipContent: TooltipContent,
	classNamePopoverWrapper = '',
	children,
	label,
	...rest
}) => {
	const classes = useStyles();
	const { t } = useTranslations();

	const renderChildren = (getAnchorProps) => {
		if (typeof children === 'function') {
			return children({ getAnchorProps });
		}
		return addPropsToChildren(children, {
			...getAnchorProps()
		});
	};

	return (
		<PopoverRPC>
			{({ getAnchorProps, popoverWrapper: PopoverWrapper }) => (
				<>
					<PopoverWrapper
						classNamePopover={clsx([
							classNamePopoverWrapper,
							classes.popoverWrapper
						])}
						anchorOrigin={{
							vertical: 'center',
							horizontal: 'center'
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'left'
						}}
						{...rest}
					>
						{label ? (
							<Typography className={classes.tooltipContentLabel}>
								{t(label)}
							</Typography>
						) : (
							<TooltipContent />
						)}
					</PopoverWrapper>
					{renderChildren(getAnchorProps)}
				</>
			)}
		</PopoverRPC>
	);
};

Tooltip.propTypes = {
	label: oneOfType([string, object]),
	classNamePopoverWrapper: string,
	tooltipContent: func.isRequired,
	children: oneOfType([arrayOf(node), node, func]).isRequired
};

export default Tooltip;
