import { useMutation } from 'components/utilities';
import { TwoFactorAuthModel } from 'models/Auth/SignIn/signIn';
import { DoctorUserModel } from 'models/User/Doctor';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSignInContext } from 'views/SignIn/context';

import {
	SignInContext,
	SignInWithout2FA,
	TwoFactorAuth
} from './classes/signIn';
import { AuthRepository } from './repository';

const useSignInUser = (options = {}) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { setCredentials } = useSignInContext();

	const handleSuccess = ({ data }: AnyType) => {
		const signInContext = new SignInContext();

		if (SignInContext.isTwoFactorResponse(data)) {
			signInContext.setStrategy(new TwoFactorAuth(history));
		} else {
			signInContext.setStrategy(new SignInWithout2FA(dispatch));
		}

		signInContext.onSignInSuccess(data, setCredentials);
	};

	const mutation = useMutation<
		DoctorUserModel | TwoFactorAuthModel,
		TMyError,
		SignInPayload
	>(AuthRepository.signIn, {
		...options,
		onSuccess: handleSuccess
	});

	const mutateHandler = (
		values: SignInPayload,
		callback: () => void | Promise<DoctorUserModel | TwoFactorAuthModel>
	) => {
		setCredentials(values);
		return callback();
	};

	const handleMutateAsync = async (values: SignInPayload) =>
		mutateHandler(values, () => mutation.mutateAsync(values));
	const handleMutate = (values: SignInPayload) =>
		mutateHandler(values, () => mutation.mutate(values));

	return {
		...mutation,
		mutateAsync: handleMutateAsync,
		mutate: handleMutate
	};
};

export default useSignInUser;
