import { PointOptionsObject } from 'highcharts';
import { ForcedSpirometryMeasurementModel } from 'models/Report/ForcedSpirometry';

export const prepareAllMeasurementsDataToRender = (
	data: ForcedSpirometryMeasurementModel[],
	dataType: 'flowVolumeChart' | 'volumeTimeChart'
): {
	data: PointOptionsObject[];
	isBest: boolean;
}[] =>
	data.map((d) => ({
		isBest: d.isBest ?? false,
		//^?
		data: d[dataType] ?? []
	}));
