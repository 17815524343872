import { getDateWithTimeCellValue } from 'components/elements/molecules/Table/TableCellContent/Date/helpers';
import { useTranslations } from 'components/utilities';
import { TGlobalFilterMiddlewareFn } from 'components/utilities/hooks/Table/types';
import { PatientListItemModel } from 'models/Patient/PatientListItem';

import { getLinkedDisplayValue } from '../AccountLinkCollapsedChip/helpers';
import { COLUMNS_ACCESSORS } from './columns';

export const globalFilterFn: (
	tNoParser: ReturnType<typeof useTranslations>['tNoParser']
) => TGlobalFilterMiddlewareFn<PatientListItemModel> =
	(tNoParser) =>
	(...args) => {
		const [row, columnId, value, , next] = args;
		const rowValue = row.getValue(columnId)?.toString()?.toLowerCase();

		if (!rowValue) return false;

		const v = value?.toString()?.toLowerCase();

		switch (columnId) {
			case COLUMNS_ACCESSORS.LAST_EXAMINATION:
				const dateLabelWithTime = getDateWithTimeCellValue(rowValue);
				return dateLabelWithTime.includes(v);
			case COLUMNS_ACCESSORS.ACCOUNT_LINK:
				const linkRowValue = getLinkedDisplayValue(
					row.getValue(columnId)
				);
				return !!tNoParser(linkRowValue)?.toLowerCase()?.startsWith(v);
			default:
				return next();
		}
	};
