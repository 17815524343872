import { EnqueueSnackbar, SnackbarKey } from 'notistack';
import {
	isMessagingSupported,
	registerMessagingServiceWorker
} from 'utilities/firebase';
import { FirebaseMessageModel } from 'utilities/firebase/types';

export const notificationsDirector = (enqueueSnackbar: EnqueueSnackbar) => ({
	chatMessage({
		firebaseMessage,
		onActionClick
	}: {
		firebaseMessage: FirebaseMessageModel;
		onActionClick: () => void;
	}) {
		enqueueSnackbar({
			persist: true,
			variant: 'action',
			title: firebaseMessage.notification.title,
			contentMessage: '',
			actionLabel: 'global.go_to_chat',

			onActionClick
		});
	},
	notificationsPermissionRequest() {
		enqueueSnackbar({
			actionLabel: 'global.yes',
			secondaryLabel: 'global.no',
			persist: true,
			variant: 'permissionRequest',
			onActionClick: async () => await callPermissionsRequest(),
			onSecondaryActionClick: async () => await callPermissionsRequest()
		});
	},
	somethingWentWrong() {
		enqueueSnackbar({
			persist: true,
			variant: 'error',
			title: 'global.error',
			contentMessage: 'error_codes.something_went_wrong'
		});
	},
	subscriptionExpiration({ firebaseMessage }) {
		enqueueSnackbar({
			persist: true,
			variant: 'action',
			title: firebaseMessage.notification.title,
			contentMessage: firebaseMessage.notification.body
		});
	},
	patientInvitation({
		firebaseMessage,
		onActionClick
	}: {
		firebaseMessage: FirebaseMessageModel;
		onActionClick: (id: SnackbarKey) => void;
	}) {
		enqueueSnackbar({
			persist: true,
			variant: 'action',
			contentMessage: firebaseMessage.notification.body,
			title: firebaseMessage.notification.title,
			actionLabel: 'global.open_patient_card',
			onActionClick
		});
	},
	releaseNote() {
		enqueueSnackbar({
			persist: true,
			variant: 'releaseNote'
		});
	}
});

export async function callPermissionsRequest() {
	if (await isMessagingSupported()) {
		Notification.requestPermission().then((permission) => {
			if (permission === 'granted') {
				registerMessagingServiceWorker();
			}
		});
	}
}
