import React from 'react';
import { ExaminationsParametersType } from 'types/Examinations/parameters';
import { SessionStorage, useParams } from 'utilities';

import { SelectedParameters } from '../classes/SelectedParameters';

type Props = {
	setSelectedParameters: React.Dispatch<
		React.SetStateAction<ExaminationsParametersType[]>
	>;
};

export const useActions = ({ setSelectedParameters }: Props) => {
	const { patientId } = useParams();

	const getNewSelectedExaminationParameters = (
		selectedParameters,
		parameter
	) => {
		const newSelectedParameters: ExaminationsParametersType[] =
			new SelectedParameters(selectedParameters)
				.toggleParameter(parameter)
				.getSelectedParameters();
		return newSelectedParameters;
	};

	const handleToggleExaminationParameters = (
		parameter: ExaminationsParametersType
	) => {
		setSelectedParameters((prev) => {
			const newSelectedExaminationParameters =
				getNewSelectedExaminationParameters(prev, parameter);
			SessionStorage.examinations.setSelectedTrendChartParameters(
				{ patientId },
				newSelectedExaminationParameters
			);
			return newSelectedExaminationParameters;
		});
	};

	return {
		toggleExaminationParameters: handleToggleExaminationParameters,
		getNewSelectedExaminationParameters
	};
};
