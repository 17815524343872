import React from 'react';

import { FullWidthTableRowCell } from '../../../atoms/Table/FullWidthTableRowCell/FullWidthTableRowCell';
import { LoaderSpinner } from '../../Loader';

export interface ITableBodyConteLoaderProps {
	numberOfColumns: number;
}
export const TableBodyContentLoader = ({
	numberOfColumns
}: ITableBodyConteLoaderProps) => {
	return (
		<FullWidthTableRowCell numberOfColumns={numberOfColumns}>
			<LoaderSpinner rootSize="medium" />
		</FullWidthTableRowCell>
	);
};
