import { Box } from '@material-ui/core';
import { useTranslations } from 'components/utilities';
import React from 'react';

import { ContainerWithBorder, Label } from '../../../../components';
import { LEGEND_ITEMS } from './helpers';
import { useStyles } from './styles';

export const Legend = () => {
	const classes = useStyles();
	const { t } = useTranslations();
	return (
		<Box className={classes.legendContainer}>
			<Label label={t('report.bronchodilator.legend')} />
			{/* <Typography>{t('report.bronchodilator.legend')}</Typography> */}
			{LEGEND_ITEMS.map(({ label, lineColor }) => (
				<ContainerWithBorder key={label} className={classes.legendItem}>
					<Label label={t(label)} />
					<Box className={classes.legendLine} bgcolor={lineColor} />
				</ContainerWithBorder>
			))}
		</Box>
	);
};
