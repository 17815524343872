import { SCHEDULE_SUB_PATH } from 'components/routes/paths';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { INITIAL_STATE, STATE_GRID_OPEN_SIDEBAR } from './helpers';

const useTopBar = () => {
	const { pathname } = useLocation();
	const [grid, setGrid] = React.useState(INITIAL_STATE);

	React.useEffect(() => {
		if (pathname.includes(SCHEDULE_SUB_PATH)) {
			setGrid(STATE_GRID_OPEN_SIDEBAR);
		} else {
			setGrid(INITIAL_STATE);
		}
	}, [pathname]);

	return { grid };
};

export default useTopBar;
