import { Box } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import { ReportTemplate } from '../components/templates';
import { PageProps } from '../types';
import { ExaminationDetails } from './ExaminationDetails';
import { getSecondPagePrePostData } from './helpers';
import { useStyles } from './styles';
import { useGetCachedBronchodilatorReportData } from './useGetCachedBronchodilatorReportData';

export const SecondPage = ({ title, pageCount, pages }: PageProps) => {
	const { examination } = useGetCachedBronchodilatorReportData();
	const classes = useStyles();
	return (
		<ReportTemplate
			config={{
				title,
				footer: { pageCount, pages },
				lastPage: true
			}}
		>
			<Box className={clsx([classes.flex])}>
				{getSecondPagePrePostData(
					examination?.pre,
					examination?.post
				).map((examinationData) => (
					<ExaminationDetails
						key={examinationData.title}
						{...examinationData}
					/>
				))}
			</Box>
		</ReportTemplate>
	);
};
