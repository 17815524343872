import {
	DOWNLOAD_EXAMINATIONS_API,
	TRANSLATE_ID_TO_SIGN_STR_API
} from 'helpers';
import {
	TranslateIdToSignStrRequestSchema,
	TranslateIdToSignStrResponseSchema
} from 'models/Examinations/translations';
import {
	replaceCommonIdQueryParam,
	replaceExaminationTypeQueryParam,
	replacePatientIdQueryParam
} from 'queries/common';
import services from 'services/services';
import { servicesV2 } from 'services/servicesV2';

export class ExaminationsRepository {
	static translateIdToSignStr(
		id: IDParam,
		examinationType: ExaminationTypeParam
	) {
		const apiUrl = replaceExaminationTypeQueryParam(
			replaceCommonIdQueryParam(TRANSLATE_ID_TO_SIGN_STR_API, id),
			examinationType
		);
		return servicesV2.get(apiUrl, {
			requestSchema: TranslateIdToSignStrRequestSchema,
			responseSchema: TranslateIdToSignStrResponseSchema
		});
	}
	/**
	 * fetch examination report
	 * @param {object} args
	 * @param {number} args.patientId patient ID
	 * @param {object} args.payload examination request payload
	 * @param {array} args.payload.examinations array of selected examinations
	 * @param {array} args.payload.report_format array of chosen report formats
	 * @returns Promise
	 */
	static getReport({ patientId, payload }) {
		return services.post(
			replacePatientIdQueryParam(DOWNLOAD_EXAMINATIONS_API, patientId),
			payload,
			{ responseType: 'blob' }
		);
	}
}
