import { Box, Typography as MuiTypography } from '@material-ui/core';
import { useTranslations } from 'components/utilities';
import { bool, func, object, string } from 'prop-types';
import React from 'react';

import OptionButton from '../OptionButton';
import { DRUG_PURPOSE_FIELDS } from './helpers';
import RadioField from './RadioField';
import { useStyles } from './styles';

const DrugPurpose = ({
	classNameRoot,
	submitFailed,
	errors,
	type,
	setInhalerPurpose
}) => {
	const classes = useStyles();
	const { t } = useTranslations();

	return (
		<Box className={classNameRoot}>
			<MuiTypography
				variant="body2"
				component="p"
				className={classes.typography}
			>
				{t('patients.drugs.form.use_as')}
			</MuiTypography>
			{submitFailed && (errors.type || errors.drug?.type) && (
				<MuiTypography variant="body2" component="p" color="error">
					{t(errors.type || errors.drug?.type)}
				</MuiTypography>
			)}
			<Box className={classes.wrapper}>
				{DRUG_PURPOSE_FIELDS.map(({ value, label }) => (
					<OptionButton
						key={value}
						isButton={false}
						value={value}
						state={type?.toLowerCase()}
						onClick={() => setInhalerPurpose(value)}
						className={classes.button}
					>
						{({ isChecked }) => (
							<RadioField
								isChecked={isChecked}
								label={label}
								value={value}
							/>
						)}
					</OptionButton>
				))}
			</Box>
		</Box>
	);
};

DrugPurpose.propTypes = {
	type: string,
	setInhalerPurpose: func.isRequired,
	errors: object,
	submitFailed: bool,
	classNameRoot: string
};

export default DrugPurpose;
