import { GET_CHARTS_DATA_V2_API } from 'helpers';
import services from 'services/services';

export class ChartDataRepository {
	constructor() {}

	/**
	 * fetch preview chart (small chart below main charts) data
	 * @param {object} args request arguments to api route query params
	 * @returns Promise
	 */
	static getChartData({
		patientId,
		chartStartDate,
		chartEndDate,
		chartName,
		personalBestReferenceValue = null,
		cancelToken
	}) {
		let url = `${GET_CHARTS_DATA_V2_API}?patientId=${patientId}&from=${chartStartDate}&to=${chartEndDate}&parameter=${chartName}`;
		url += personalBestReferenceValue
			? `&personalBestReferenceValue=${personalBestReferenceValue}`
			: '';
		return services.get(url, { cancelToken });
	}
}
