import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { Correctness } from 'components/elements/organisms/ExaminationsV2';
import { CHART_MEASUREMENT_PARAMETER_OPTION_NAMES as PARAMETERS } from 'helpers/charts';
import { SlowVitalCapacitySVCReportModel } from 'models/Report/Reports';
import React from 'react';

import {
	ContainerWithBorder,
	DeviceInformation,
	ExaminationNote,
	GeneratePDFReportActionButton,
	Header,
	ReportPDFHistory,
	TechnicalErrors,
	TechnicalNote,
	Title
} from '../components';
import {
	AttemptsProvider,
	ExaminationDataProvider,
	ReportInterpretationNoteProvider
} from '../context';
import { useCommonStyles, useCorrectnessStyles } from '../styles';
import { AllMeasurements } from './AllMeasurements';
import { AttemptsArrowSelector } from './AttemptsArrowSelector';
import { Graphs } from './Graphs';
import { useSlowVitalCapacityData } from './useSlowVitalCapacityData';

export const SlowVitalCapacity = () => {
	const commonClasses = useCommonStyles();

	const examinationData = useSlowVitalCapacityData();

	return (
		<ExaminationDataProvider<SlowVitalCapacitySVCReportModel | undefined>
			data={examinationData}
		>
			<Header
				title="patients.examinations_v2.details.svc.title"
				createdDateTime={examinationData?.examinationCreateDateTime}
				refValue={examinationData?.referenceStandard}
			/>
			<Box className={commonClasses.flexContainer}>
				<ContainerWithBorder
					className={commonClasses.containerWithBorderMinWidth}
				>
					<Correctness
						examination={examinationData}
						upperStatusFieldName={'vcStatus'}
						bottomStatusFieldName={'icStatus'}
						parametersLabels={[
							PARAMETERS.vc.label,
							PARAMETERS.ic.label
						]}
						useLocalTheme={useCorrectnessStyles}
					/>
				</ContainerWithBorder>
			</Box>
			<AttemptsProvider
				measurements={examinationData?.measurements || []}
			>
				<ContainerWithBorder
					className={clsx([
						commonClasses.flexColumnContainer,
						commonClasses.fullWidth
					])}
				>
					<Box className={commonClasses.measurementsTitleContainer}>
						<Title
							title="patients.examinations_v2.details.spirometry.all_measurements.title"
							className={commonClasses.title}
						/>
					</Box>
					<Graphs />
					<AttemptsArrowSelector />
					<AllMeasurements />
					<TechnicalErrors />
				</ContainerWithBorder>
			</AttemptsProvider>
			<ReportInterpretationNoteProvider>
				<Box className={commonClasses.notesContainer}>
					<TechnicalNote />
					<ExaminationNote />
				</Box>
				<ReportPDFHistory />
				<GeneratePDFReportActionButton />
			</ReportInterpretationNoteProvider>
			<DeviceInformation deviceData={examinationData?.device} />
		</ExaminationDataProvider>
	);
};
