import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	buttonAdornment: {
		'& button': {
			padding: 6
		},
		'& .MuiFormHelperText-contained': {
			marginRight: 0,
			marginLeft: 7
		}
	}
}));
