import { makeStyles } from '@material-ui/styles';
import theme from 'theme';

import { ITableCellProps } from './TableCell';

export const useStyles = makeStyles<
	typeof theme,
	{ textAlign: Required<ITableCellProps['textAlign']> }
>(() => ({
	cell: {
		backgroundColor: 'inherit',
		textAlign: ({ textAlign }) => textAlign,
		padding: 0,
		paddingLeft: 10
	}
}));
