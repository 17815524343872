import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		alignSelf: 'stretch',
		padding: 0
	},
	item: {
		display: 'flex',
		paddingTop: 0,
		paddingBottom: 0
	},
	button: {
		margin: '0px 30px',
		color: theme.palette.customBlack.main,
		padding: '10px 8px',
		textTransform: 'none',
		letterSpacing: 0,
		width: '100%',
		fontWeight: theme.typography.h1.fontWeight,
		display: 'flex',
		justifyContent: 'center',
		position: 'relative',
		height: '100%',
		fontSize: 16,
		'&::after': {
			content: '" "',
			display: 'inline-block',
			position: 'absolute',
			top: 0,
			left: 0,
			height: 4,
			width: '100%',
			background: theme.palette.blue.main,
			zIndex: 999,
			transform: 'scaleX(0)',
			transformOrigin: '0% 50%',
			transition: 'all 0.3s ease-in-out'
		},
		'&:hover': {
			backgroundColor: theme.palette.gray.light
		}
	},
	active: {
		color: theme.palette.blue.main,
		fontWeight: theme.typography.h1.fontWeight,
		'&::after': {
			transform: 'scaleX(1)'
		}
	},
	badge: {
		'& .MuiBadge-badge': {
			top: -1,
			right: -5,
			fontSize: 10,
			minWidth: 15,
			height: 16
		}
	}
}));
