import { Box, Typography } from '@material-ui/core';
import { AioCarePanelLogo, Alert, ButtonBack } from 'components/elements';
import { APP_PATHS } from 'components/routes/paths';
import { useTranslations } from 'components/utilities';
import { useResetPasswordEmailRequest } from 'queries';
import React from 'react';
import { Form } from 'react-final-form';

import ActionButtons from './ActionButtons';
import AlertSuccess from './AlertSuccess';
import Fields from './Fields';
import { useStyles } from './styles';
// Import validation
import validation from './validation';

const { SIGN_IN } = APP_PATHS;

const ResetPasswordRequestForm = () => {
	const classes = useStyles();

	const { mutateAsync, isSuccess, isError, apiError } =
		useResetPasswordEmailRequest();

	const { t } = useTranslations();

	return (
		<Form
			onSubmit={mutateAsync}
			validate={validation}
			render={({ handleSubmit, submitting }) => (
				<form
					className={classes.form}
					onSubmit={handleSubmit}
					noValidate
				>
					<Box className={classes.formTop}>
						<AioCarePanelLogo />
						<Box className={classes.titleWrapper}>
							<ButtonBack to={SIGN_IN} />
							<Typography className={classes.title} variant="h2">
								{t('reset_password.title')}
							</Typography>
						</Box>
					</Box>
					{isError && <Alert message={apiError} />}
					{isSuccess ? (
						<AlertSuccess />
					) : (
						<Fields submitting={submitting} />
					)}
					<ActionButtons
						submitting={submitting}
						isSuccess={isSuccess}
					/>
				</form>
			)}
		/>
	);
};

export default ResetPasswordRequestForm;
