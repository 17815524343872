import { CalibrationCheckReportModel } from 'models/Report/Reports';
import { LocaleDate } from 'utilities';

export const SUBHEADER_LABELS: {
	[K in keyof Required<CalibrationCheckReportModel>]: {
		fieldName: K;
		label: string;
		valueConverter: (
			value: CalibrationCheckReportModel[K] | undefined
		) => TranslationKey;
	};
	//eslint-disable-next-line
}[keyof CalibrationCheckReportModel][] = [
	{
		fieldName: 'examinationCreateDateTime',
		label: 'report.subheader.verification_date',
		valueConverter: (v) =>
			v ? new LocaleDate(v).getDateWithTimeLabel().dateFirst(' |') : v
	}
];
