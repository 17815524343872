import { useParams as useRTDParams } from 'react-router-dom';

type IParams = {
	token: TokenParam;
	patientId: PatientIdParam;
	id: IDParam;
	examinationType: ExaminationTypeParam;
	clinicId: ClinicIdParam;
};
const useParams = () => {
	return useRTDParams<IParams>();
};

export default useParams;
