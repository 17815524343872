import { QUERY_PATIENT_DATA_AND_PERSONAL_BESTS } from 'helpers';
import { PersonalBestModel } from 'models/Examinations/PersonalBest';
import { INVITATION_STATUS, PatientModel } from 'models/Patient/PatientModel';
import { useQueryClient } from 'react-query';
import { useParams } from 'utilities';

export const useChat = () => {
	const { patientId } = useParams();
	const queryClient = useQueryClient();

	const patientData = queryClient.getQueryData<
		[{ best: PersonalBestModel }, PatientModel]
	>([QUERY_PATIENT_DATA_AND_PERSONAL_BESTS, patientId]);

	const isChatAvailable =
		patientData?.[1].patient.invitationStatus === INVITATION_STATUS.LINKED;

	return { isChatAvailable };
};
