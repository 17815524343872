import { COOKIE_EXPIRES, COOKIES } from 'helpers/variables';
import Cookie from 'js-cookie';
import * as types from 'store/types';

const { COOKIE_COUNTRY_CODE, COOKIE_CITY_NAME } = COOKIES;
export const setGeoLocation = (item) => async (dispatch) => {
	const countryCode = item?.country ?? null;
	const cityName = item?.name ?? null;
	Cookie.set(COOKIE_COUNTRY_CODE, countryCode, { expires: COOKIE_EXPIRES });
	Cookie.set(COOKIE_CITY_NAME, cityName, { expires: COOKIE_EXPIRES });

	dispatch({
		type: types.SET_GEO_LOCATION,
		payload: item
	});
};
