import { Grid, List } from '@material-ui/core';
import { isLastArrayIndex } from 'helpers';
import { array, arrayOf, node, number, oneOfType } from 'prop-types';
import React from 'react';

import NavItem from './NavItem';
import { useStyles } from './style';

const NavList = ({ links, lgItem = 7, children }) => {
	const classes = useStyles();

	return (
		<Grid container spacing={0}>
			<Grid item lg={lgItem} md={12} sm={12} xs={12}>
				<List className={classes.list}>
					<Grid container spacing={0} className={classes.root}>
						{links.map(({ label, ...rest }, index) => (
							<NavItem
								key={label.toLowerCase()}
								isLastItem={isLastArrayIndex(index, links)}
								label={label}
								{...rest}
							/>
						))}
						{children}
					</Grid>
				</List>
			</Grid>
		</Grid>
	);
};

NavList.propTypes = {
	children: oneOfType([arrayOf(node), node]),
	links: array,
	lgItem: number
};

export default NavList;
