import {
	ARCHIVE_DRUG_API_URL,
	DELETE_DRUG_API_URL,
	DRUG_RESOURCES_API_URL,
	EDIT_PRESCRIBED_DRUG_API_URL,
	PRESCRIBE_DRUG_TO_PATIENT_API_URL,
	UNARCHIVE_DRUG_API_URL
} from 'helpers';
import {
	replaceDrugIdQueryParam,
	replacePatientIdQueryParam
} from 'queries/common';
import services from 'services/services';

export const DrugsRepository = {
	get: (drugId) => {
		const apiUrl = replaceDrugIdQueryParam(DRUG_RESOURCES_API_URL, drugId);
		return services.get(apiUrl);
	},
	edit: (drugId, payload) => {
		const apiUrl = replaceDrugIdQueryParam(
			EDIT_PRESCRIBED_DRUG_API_URL,
			drugId
		);
		return services.put(apiUrl, payload);
	},
	create: (patientId, payload) => {
		const apiUrl = replacePatientIdQueryParam(
			PRESCRIBE_DRUG_TO_PATIENT_API_URL,
			patientId
		);
		return services.post(apiUrl, payload);
	},
	delete: (drugId) => {
		const apiUrl = replaceDrugIdQueryParam(DELETE_DRUG_API_URL, drugId);
		return services.delete(apiUrl);
	},
	archive: (drugId) => {
		const apiUrl = replaceDrugIdQueryParam(ARCHIVE_DRUG_API_URL, drugId);
		return services.post(apiUrl);
	},
	unarchive: (drugId) => {
		const apiUrl = replaceDrugIdQueryParam(UNARCHIVE_DRUG_API_URL, drugId);
		return services.post(apiUrl);
	}
};
