// Import routes
import { PATIENTS_DETAILS_PATHS } from 'components/routes/paths';
// Import api
import {
	ALLERGIES_API_URL,
	ALLERGY_REMOVE_API_URL,
	ALLERGY_TABLE_QUERY_KEY,
	CONDITION_REMOVE_API_URL,
	CONDITIONS_API_URL,
	CONDITIONS_TABLE_QUERY_KEY,
	SURGERIES_API_URL,
	SURGERIES_TABLE_QUERY_KEY,
	SURGERY_REMOVE_API_URL
} from 'helpers';

export const COLUMNS_ACCESSORS = {
	NAME: 'name',
	DESCRIPTION: 'description',
	ADDED_BY: 'added_by_name',
	DATE: 'date',
	SETTINGS: 'settings'
};

export const TABLE_NAME = {
	CONDITIONS: 'conditions',
	SURGERIES: 'surgeries',
	ALLERGIES: 'allergies'
};

const {
	CONDITIONS: {
		CREATE_CONDITION,
		EDIT_CONDITION,
		CREATE_SURGERY,
		EDIT_SURGERY,
		CREATE_ALLERGY,
		EDIT_ALLERGY
	}
} = PATIENTS_DETAILS_PATHS;

const { NAME, DESCRIPTION, ADDED_BY, DATE, SETTINGS } = COLUMNS_ACCESSORS;

const columns = [
	{
		Header: 'patients.conditions.table.name',
		accessor: NAME,
		disableSortBy: true
	},
	{
		Header: 'patients.conditions.table.description',
		accessor: DESCRIPTION,
		disableSortBy: true
	},
	{
		Header: 'patients.conditions.table.added_by',
		accessor: ADDED_BY,
		disableSortBy: true
	},
	{
		Header: 'patients.conditions.form.field.date',
		accessor: DATE,
		disableSortBy: true
	},
	{
		Header: '',
		accessor: SETTINGS,
		disableSortBy: true
	}
];

// conditions table
export const TABLE_CONSTRUCTORS = [
	{
		tableName: TABLE_NAME.CONDITIONS,
		title: 'patients.conditions.table.title',
		pathname: CREATE_CONDITION,
		editPathname: EDIT_CONDITION,
		url: CONDITIONS_API_URL,
		removeApiUrl: CONDITION_REMOVE_API_URL,
		queryKey: CONDITIONS_TABLE_QUERY_KEY,
		deleteModalDisableTitle: 'patients.conditions.form.deletion_title',
		deleteModalTextContent: (cell) => ({
			key: 'patients.conditions.form.deletion_text',
			replaceKeys: {
				['%1']: `<strong>${cell.row.original.name}</strong>`
			}
		}),
		columns
	},
	// surgeries table
	{
		tableName: TABLE_NAME.SURGERIES,
		title: 'patients.surgeries.table.title',
		pathname: CREATE_SURGERY,
		editPathname: EDIT_SURGERY,
		url: SURGERIES_API_URL,
		removeApiUrl: SURGERY_REMOVE_API_URL,
		queryKey: SURGERIES_TABLE_QUERY_KEY,
		columns: [...getSurgeriesColumns()],
		deleteModalDisableTitle: 'patients.surgeries.form.deletion_title',
		deleteModalTextContent: (cell) => ({
			key: 'patients.surgeries.form.deletion_text',
			replaceKeys: {
				['%1']: `<strong>${cell.row.original.name}</strong>`
			}
		})
	},
	// allergies table
	{
		tableName: TABLE_NAME.ALLERGIES,
		title: 'patients.allergies.table.title',
		pathname: CREATE_ALLERGY,
		editPathname: EDIT_ALLERGY,
		url: ALLERGIES_API_URL,
		removeApiUrl: ALLERGY_REMOVE_API_URL,
		queryKey: ALLERGY_TABLE_QUERY_KEY,
		columns,
		deleteModalDisableTitle: 'patients.allergies.form.deletion_title',
		deleteModalTextContent: (cell) => ({
			key: 'patients.allergies.form.deletion_text',
			replaceKeys: {
				['%1']: `<strong>${cell.row.original.name}</strong>`
			}
		})
	}
];

function getSurgeriesColumns() {
	const surgeriesColumns = [...columns];
	surgeriesColumns[3] = {
		Header: 'patients.conditions.form.field.surgery_date',
		accessor: DATE,
		disableSortBy: true
	};
	return surgeriesColumns;
}
