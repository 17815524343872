import { EXAMINATIONS_TYPES } from 'helpers/variables';
import { ExaminationsListConvertedItemModel } from 'models/Examinations/ExaminationsList';
import { LocaleDate } from 'utilities';
import { getCreationDateFromExaminationListItem } from 'utilities/Examinations/common/common';

export const DEFAULT_ACTIVE_EXAMINATION_STATE = {
	id: undefined,
	type: undefined,
	index: 0
};

export const SELECTED_EXAMINATION_TYPES_INITIAL_VALUE = [
	EXAMINATIONS_TYPES.SPIROMETRY.type,
	EXAMINATIONS_TYPES.SVC.type,
	EXAMINATIONS_TYPES.COPD_TEST.type,
	EXAMINATIONS_TYPES.PULSE_OXIMETRY.type,
	EXAMINATIONS_TYPES.PEAK_FLOW.type
];

export const createDateExtremesRange = (
	data: ExaminationsListConvertedItemModel[]
): TDateRange => ({
	endDate: new LocaleDate(
		getCreationDateFromExaminationListItem(data[0])
	).getDate(),
	startDate: new LocaleDate(
		getCreationDateFromExaminationListItem(data[data.length - 1])
	).getDate()
});
