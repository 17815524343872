import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		width: '100%',
		alignSelf: 'flex-start'
	},
	titleWrapper: {
		paddingBottom: 12.5,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	title: {
		marginRight: 79
	},
	questions: {
		'& > :last-child': {
			borderBottom: 'none',
			paddingBottom: 0
		}
	}
}));
