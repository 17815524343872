import { makeStyles } from '@material-ui/styles';

const MAX_WIDTH = 225;
export const useStyles = makeStyles(() => ({
	root: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end',
		justifyContent: 'center',
		gap: 5
	},
	label: {
		cursor: 'pointer',
		fontSize: '11px',
		width: MAX_WIDTH,
		'& span': {
			fontWeight: 500,
			display: 'inline-block'
		}
	},
	button: {
		width: MAX_WIDTH
	},
	alert: {
		marginBottom: 0
	}
}));
