import { MenuItem } from '@material-ui/core';
import { array, bool, string } from 'prop-types';
import React from 'react';
import { Field, useFormState } from 'react-final-form';

import { SelectFieldAdapter } from '../../../../../../Inputs';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
		}
	}
};

const SelectField = ({ options, label, name, required = true, ...rest }) => {
	const { submitting } = useFormState();

	return (
		<Field
			fullWidth
			component={SelectFieldAdapter}
			type="select"
			label={label}
			name={name}
			margin="normal"
			variant="outlined"
			autoComplete="false"
			disabled={submitting}
			required={required}
			SelectProps={{ MenuProps: MenuProps }}
			{...rest}
		>
			{options.map((item) => (
				<MenuItem key={item} value={item}>
					{item}
				</MenuItem>
			))}
		</Field>
	);
};

SelectField.propTypes = {
	options: array.isRequired,
	label: string.isRequired,
	name: string.isRequired,
	required: bool
};

export default SelectField;
