import {
	IModalDialogProps,
	ModalDialog
} from 'components/elements/organisms/ModalDialog/ModalDialog';
import React from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';

import { ModalDialogActionButtonsV2 } from '../../../organisms/ModalDialog';
import { FormRHF, IFormRHFProps } from '../FormRHF/FormRHF';

interface IActionButtons<TFieldValues extends FieldValues> {
	component?: ({
		form
	}: {
		form: UseFormReturn<TFieldValues>;
	}) => JSX.Element;
	submitLabel?: string;
	showCancelButton?: boolean;
}

interface IFormModalDialog<TFieldValues extends FieldValues>
	extends Omit<IFormRHFProps<TFieldValues>, 'children'> {
	open: IModalDialogProps<never>['open'];
	close: IModalDialogProps<never>['close'];
	onExited?: IModalDialogProps<never>['onExited'];
	title: string;
	children: React.ReactNode;
	maxWidth?: IModalDialogProps<never>['maxWidth'];
	isSuccess?: IModalDialogProps<never>['isSuccess'];
	successContentProps: IModalDialogProps<never>['successProps'];
	actionButtons?: IActionButtons<TFieldValues>;
	submitLabel?: string;
}

export const FormModalDialog = <TFieldValues extends FieldValues>({
	// ModalDialog props
	open,
	close,
	onExited,
	maxWidth = 'md',
	isSuccess = false,
	successContentProps = {} as IFormModalDialog<TFieldValues>['successContentProps'],
	title,
	// FormRHF props
	onSubmit,
	defaultValues,
	children,
	validationSchema,
	apiError,
	apiFieldErrorTranslationKeyPrefix,
	actionButtons = {
		submitLabel: 'btn.submit',
		showCancelButton: true
	}
}: IFormModalDialog<TFieldValues>) => {
	const renderActionButtons = (formMethods: UseFormReturn<TFieldValues>) => {
		if (actionButtons?.component) {
			return <actionButtons.component form={formMethods} />;
		}
		return (
			<ModalDialogActionButtonsV2
				confirm={{
					isLoading: formMethods.formState.isSubmitting,
					type: 'submit',
					label: actionButtons?.submitLabel
				}}
				cancel={{
					onClick: actionButtons?.showCancelButton ? close : undefined
				}}
			/>
		);
	};

	return (
		<ModalDialog
			open={open}
			close={close}
			maxWidth={maxWidth}
			disableBackdropClick={true}
			isSuccess={isSuccess}
			successProps={successContentProps}
			titleProps={{
				title
			}}
			onExited={onExited}
		>
			{() => (
				<FormRHF
					onSubmit={onSubmit}
					defaultValues={defaultValues}
					validationSchema={validationSchema}
					apiError={apiError}
					apiFieldErrorTranslationKeyPrefix={
						apiFieldErrorTranslationKeyPrefix
					}
				>
					{(formState) => (
						<>
							{children}
							{renderActionButtons(formState)}
						</>
					)}
				</FormRHF>
			)}
		</ModalDialog>
	);
};
