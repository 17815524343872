import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Slide,
	TableCell} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { DeleteIcon } from 'assets/icons/commonMUIIcons';
import { ButtonProgress } from 'components/elements';
import { useTranslations } from 'components/utilities';
import { func,object } from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
	cell: {
		display: 'flex',
		alignItems: 'center'
	},
	icon: {
		width: 15,
		height: 15,
		display: 'flex',
		alignItems: 'center',
		marginRight: theme.spacing(0.5)
	},
	wrapper: {
		margin: theme.spacing(1),
		position: 'relative'
	},
	progress: {
		color: theme.palette.primary.main,
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12
	}
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteTableCell = ({ cell, deleteAction = () => {} }) => {
	const classes = useStyles();
	const { t } = useTranslations();
	const [open, setOpen] = React.useState(false);
	const [isLoading, setLoading] = React.useState(false);

	const dispatch = useDispatch();

	const handleClickOpen = () => setOpen(true);

	const handleClose = () => setOpen(false);

	const handleReset = () => {
		setLoading(false);
		setOpen(false);
	};

	const handleDeleteItem = async () => {
		const id = cell.row.original.uuid;
		setLoading(true);
		await deleteAction({ id })(dispatch);
		handleReset();
	};

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<Button
				onClick={handleClickOpen}
				color="primary"
				className={classes.cell}
			>
				<DeleteIcon className={classes.icon} fontSize="small" />
				{t('table.modal.delete.btn_delete')}
			</Button>
			<Dialog
				disableBackdropClick={true}
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle id="alert-dialog-slide-title">
					{t('table.modal.delete.title')}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						{t('table.modal.delete.desc')}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						disabled={isLoading}
						onClick={handleClose}
						color="primary"
					>
						{t('table.modal.delete.btn_cancle')}
					</Button>

					<ButtonProgress
						onClick={handleDeleteItem}
						color="primary"
						className={classes.wrapper}
						isLoading={isLoading}
						disabled={isLoading}
					>
						<DeleteIcon className={classes.icon} fontSize="small" />
						{t('table.modal.delete.btn_delete')}
					</ButtonProgress>
				</DialogActions>
			</Dialog>
		</TableCell>
	);
};

DeleteTableCell.propTypes = {
	cell: object.isRequired,
	deleteAction: func
};

export default DeleteTableCell;
