import { TextField } from '@material-ui/core';
import { useTranslations } from 'components/utilities';
import { object } from 'prop-types';
import React from 'react';
import { useForm } from 'react-final-form';

const CountryAutocompleteAdapter = ({ input, meta, ...rest }) => {
	const { t } = useTranslations();
	const { change } = useForm();

	const {
		inputProps: { value }
	} = rest;

	const error =
		meta.touched && (meta.error || meta.submitError) ? true : false;

	const errorText =
		meta.touched && (meta.error || meta.submitError)
			? t(meta.error) || meta.submitError
			: '';

	React.useEffect(() => {
		// Change input value
		change(input.name, value || null);
		// eslint-disable-next-line
	}, [value]);

	return (
		<TextField {...input} {...rest} error={error} helperText={errorText} />
	);
};

CountryAutocompleteAdapter.propTypes = {
	input: object.isRequired,
	meta: object.isRequired
};

export default CountryAutocompleteAdapter;
