import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'flex-end',

		'& .MuiInputBase-root': {
			background: theme.palette.white,
			color: theme.palette.gray.medium
		},

		'& .MuiOutlinedInput-input': {
			paddingLeft: 35
		},

		'& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
			transform: 'translate(45px, 12px) scale(1)'
		},
		'& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
			transform: 'translate(14px, -6px) scale(0.75)'
		},

		'& .MuiInputLabel-formControl': {
			fontWeight: 500,
			fontSize: 12
		},
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: theme.palette.border.medium
		}
	},
	input: (isXsDeviceResolution) => ({
		position: 'relative',
		width: isXsDeviceResolution ? '100%' : 240
	}),
	buttonClose: {
		position: 'absolute',
		top: 7,
		right: 7,
		padding: 0
	},
	iconSearch: {
		position: 'absolute',
		top: 7,
		left: 9,
		padding: 0,
		zIndex: 99,
		color: theme.palette.text.gray
	}
}));
