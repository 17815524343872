import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { ZScoreContext, ZScoreProvider } from 'components/context';
import { object, string } from 'prop-types';
import React from 'react';

import { PictogramRow } from './PictogramRow';
import { ReferenceLines } from './ReferenceLines';
import { useStyles } from './styles';
import { XAxisLabels } from './XAxisLabels';

//eslint-disable-next-line
const ZScorePictogram = React.memo(
	({ spirometryData, classNameContainer, classNamePictogramWrapper }) => {
		const classes = useStyles();

		return (
			<ZScoreProvider data={spirometryData}>
				<ZScoreContext.Consumer>
					{({
						zScorePictograms,
						getZScoreValue,
						scaleLines,
						...rest
					}) => (
						<Box
							className={clsx([
								classes.container,
								classNameContainer
							])}
						>
							<Box
								className={clsx([
									classes.pictogramWrapper,
									classNamePictogramWrapper
								])}
							>
								<ReferenceLines {...rest} />
								{zScorePictograms.map(({ label, name }) => (
									<PictogramRow
										key={label}
										title={label}
										value={getZScoreValue({
											name,
											spirometryData
										})}
										scaleLines={scaleLines}
										{...rest}
									/>
								))}
							</Box>
							<XAxisLabels scaleLines={scaleLines} {...rest} />
						</Box>
					)}
				</ZScoreContext.Consumer>
			</ZScoreProvider>
		);
	},
	statusChartsPropsAreEqual
);

function statusChartsPropsAreEqual(prev, next) {
	return JSON.stringify(prev.spirometryData === next.spirometryData);
}

ZScorePictogram.propTypes = {
	spirometryData: object.isRequired,
	classNameContainer: string,
	classNamePictogramWrapper: string
};

export default ZScorePictogram;
