import { Box, Typography as MuiTypography } from '@material-ui/core';
import { useTranslations } from 'components/utilities';
import { DATE_FORMAT } from 'helpers';
import { CommonReportFieldsModel } from 'models/Report/Reports';
import React from 'react';
import { LocaleDate } from 'utilities';

import { LabeledValue } from '../LabeledValue';
import { Label } from '../Typography';
import { ExportReport } from './ExportReport';
import { useStyles } from './styles';

type Props = {
	title: string;
	refValue?: string | null;
	createdDateTime:
		| CommonReportFieldsModel['examinationCreateDateTime']
		| undefined;
};
export const Header = ({
	title,
	createdDateTime = '',
	refValue = ''
}: Props) => {
	const { t } = useTranslations();

	const classes = useStyles();
	const createdDateTimeLabel = React.useMemo(
		() =>
			new LocaleDate(createdDateTime)
				.getDateWithTimeLabel(DATE_FORMAT.FULL_DATE_WITH_WEEK_DAY)
				.dateFirst(' | '),
		[]
	);

	return (
		<Box className={classes.headerContainer}>
			<Box className={classes.titleContainer}>
				<MuiTypography variant="h3">{t(title)}</MuiTypography>
				<ExportReport />
			</Box>
			<Box className={classes.titleContainer}>
				<Label label={createdDateTimeLabel} />
				{refValue && (
					<LabeledValue
						labelProps={{
							label: 'report.subheader.ref_value'
						}}
						valueProps={{
							value: refValue
						}}
					/>
				)}
			</Box>
		</Box>
	);
};
