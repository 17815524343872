import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	datePickerField: {
		marginTop: 0,
		marginBottom: 0,
		flex: 1
	},
	inputBase: {
		cursor: 'pointer'
	}
}));
