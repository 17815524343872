import { LAYOUT_BOX_BACKGROUND } from 'helpers';

// Get heights of background layout box
const { HEIGHT } = LAYOUT_BOX_BACKGROUND;

export const getLayoutBgHeight = (path) => {
	const [, core] = path.split('/');

	switch (core) {
		case 'patient':
			return HEIGHT.PATIENTS;
		default:
			return HEIGHT.DEFAULT;
	}
};
