import { Box, Typography as MuiTypography } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslations } from 'components/utilities';
import { EMPTY_VALUE } from 'helpers';
import { bool, func, number, object, oneOfType, string } from 'prop-types';
import React from 'react';

import { useStyles } from './styles';
import useParameterValue from './useParameterValue';

const ParameterValue = (props) => {
	const {
		isLabelDisabled = true,
		isUnitOutside = true,
		isValueColoured = false,
		isTranslationKey = false,
		isScore = false,
		fullWidth = false,
		label = '',
		value = null,
		color = null,
		cellWidthMultiplier = 1,
		unit = null,
		component: Component = null,
		classNameRoot = ''
	} = props;

	const classes = useStyles({
		color,
		isScore,
		isValueColoured,
		cellWidthMultiplier,
		isLabel: !!label,
		fullWidth
	});
	const { t } = useTranslations();
	const { resultValue } = useParameterValue({ value });

	const renderLabel = (label, className = '', dataTestId) => (
		<MuiTypography
			data-testid={dataTestId}
			variant="subtitle1"
			className={clsx([className, classes.label])}
			component="p"
		>
			{label}
		</MuiTypography>
	);

	return (
		<Box
			className={clsx(classes.root, classNameRoot)}
			data-testid="parameterValue"
		>
			{label &&
				!isLabelDisabled &&
				renderLabel(label, classes.resultHeader, 'label')}
			{Component ? (
				<Component {...props} />
			) : (
				<Box className={classes.content} data-testid="content">
					<MuiTypography
						data-testid="result"
						variant="h6"
						className={classes.result}
						component="p"
					>
						{isTranslationKey ? t(value) : resultValue}
						{!isUnitOutside && resultValue !== EMPTY_VALUE && unit}
					</MuiTypography>
				</Box>
			)}
			{unit && isUnitOutside && renderLabel(unit, classes.unit, 'unit')}
		</Box>
	);
};

ParameterValue.propTypes = {
	isLabelDisabled: bool,
	isUnitOutside: bool,
	isValueColoured: bool,
	isScore: bool,
	isTranslationKey: bool,
	fullWidth: bool,
	value: oneOfType([number, string]),
	unit: string,
	label: string,
	color: string,
	cellWidthMultiplier: number,
	component: oneOfType([func, object]),
	classNameRoot: string
};

export default ParameterValue;
