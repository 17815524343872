import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	label: {
		fontWeight: 600,
		fontSize: '18px',
		lineHeight: '19.8px',
		letterSpacing: '0.15px',
		padding: '5px 0'
	}
}));
