import { IColumn } from 'components/elements/organisms/ExaminationsV2/ParametersTable/ParametersTable';
import { ReportParameterModel } from 'models/Report/common';
import { ColumnValues } from 'utilities/Examinations/ExaminationDetails/common';

type Accessor = Leaves<ColumnValues<ReportParameterModel>>;

export const columns: IColumn<Accessor>[] = [
	{ Header: '', accessor: 'parameterTypeLabel' },
	{ Header: 'report.best_results.value', accessor: 'value' },
	{ Header: 'report.best_results.lln', accessor: 'lln' },
	{ Header: 'report.best_results.predicted', accessor: 'predicted' },
	{ Header: 'report.best_results.z_score', accessor: 'zScore' },
	{
		Header: 'report.best_results.percentage_predicted',
		accessor: 'predictedPercent',
		isInteger: true
	},
	{
		Header: 'report.best_results.percentile',
		accessor: 'percentile',
		isInteger: true
	}
];
