import { Box, Card, List, TextField } from '@material-ui/core';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import { makeStyles } from '@material-ui/styles';
import { array, func, node, string } from 'prop-types';
import React from 'react';
import { useForm } from 'react-final-form';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		backgroundColor: theme.palette.background.paper,
		position: 'relative',
		overflow: 'auto',
		maxHeight: 350,
		padding: 20,
		paddingTop: 0
	},
	card: {
		boxShadow: '0px 3px 6px #00000029',
		position: 'absolute',
		width: '100%'
	}
}));

const Autocomplete = ({
	options,
	subheader = <li />,
	children,
	name,
	...rest
}) => {
	const classes = useStyles();

	const { change } = useForm();

	const {
		getRootProps,
		getInputLabelProps,
		getInputProps,
		getListboxProps,
		getOptionProps,
		groupedOptions
	} = useAutocomplete({
		options: options,
		getOptionLabel: (option) => option.name,
		noOptionsText: <Box>no data</Box>
	});

	const { value: inputValue } = getInputProps();

	React.useEffect(() => {
		inputValue && change(name, inputValue);
		//eslint-disable-next-line
	}, [inputValue]);

	return (
		<Box style={{ position: 'relative' }}>
			<Box {...getRootProps()}>
				<TextField
					name={name}
					{...rest}
					{...getInputLabelProps()}
					{...getInputProps()}
				/>
			</Box>
			{groupedOptions.length > 0 && (
				<Card className={classes.card}>
					<List className={classes.root} subheader={subheader}>
						{children({
							getListboxProps,
							getOptionProps,
							inputValue,
							groupedOptions
						})}
					</List>
				</Card>
			)}
		</Box>
	);
};

Autocomplete.propTypes = {
	options: array.isRequired,
	children: func.isRequired,
	subheader: node,
	name: string.isRequired
};

export default Autocomplete;
