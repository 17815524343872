import {
	emailRegex,
	lettersAndSpecialCharacters,
	numberRegex,
	passwordRegex
} from 'helpers';

export default ({
	first_name,
	last_name,
	email,
	password,
	password_confirmation,
	phone,
	birth_date,
	gender,
	phone_prefix
}) => {
	let errors = {};

	if (!gender) {
		errors.gender = 'sign_up.doctor.validation.gender_required';
	}

	if (!birth_date) {
		errors.birth_date = 'sign_up.doctor.validation.birth_date_required';
	}

	if (!email) {
		errors.email = 'sign_in.validation.email_required';
	} else if (!emailRegex.test(email)) {
		errors.email = 'sign_in.validation.email_invalid';
	}

	if (!phone_prefix) {
		errors.phone_prefix = 'sign_up.doctor.validation.phone_prefix_required';
	}

	if (!phone) {
		errors.phone = 'sign_up.doctor.validation.phone_required';
	} else if (!numberRegex.test(phone)) {
		errors.phone = 'sign_up.doctor.phone_invalid';
	}

	if (!first_name) {
		errors.first_name = 'patients.form.validation.first_name_required';
	} else if (!lettersAndSpecialCharacters.test(first_name)) {
		errors.first_name =
			'patients.form.validation.first_name_invalid_characters';
	}

	if (!last_name) {
		errors.last_name = 'patients.form.validation.last_name_required';
	} else if (!lettersAndSpecialCharacters.test(last_name)) {
		errors.last_name =
			'patients.form.validation.last_name_invalid_characters';
	}

	if (!password) {
		errors.password = 'global.validation.password_required';
	} else if (password && !passwordRegex.test(password)) {
		errors.password = ' ';
	}

	if (!password_confirmation) {
		errors.password_confirmation =
			'global.validation.password_confirmation_required';
	}

	if (
		password &&
		password_confirmation &&
		password !== password_confirmation
	) {
		errors.password =
			'settings.clinics.form.validation.admin.passwords_are_not_same';
		errors.password_confirmation =
			'settings.clinics.form.validation.admin.passwords_are_not_same';
	}

	return errors;
};
