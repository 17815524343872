import { Container } from 'components/elements/atoms/ExaminationsV2';
import { Correctness } from 'components/elements/organisms/ExaminationsV2';
import { CHART_MEASUREMENT_PARAMETER_OPTION_NAMES as PARAMETERS } from 'helpers/charts';
import React from 'react';

import { PatientCard } from '../components/organisms';
import { ReportTemplate } from '../components/templates';
import {
	AllMeasurementsTable,
	BestResults,
	BestVolumeTimeGraph
} from './components';
import { useStyles } from './styles';
import { useGetCachedSlowVitalCapacitySVCReportData } from './useGetCachedSlowVitalCapacitySVCReportData';

export const FirstPage = () => {
	const classes = useStyles();
	const { examination, patient } =
		useGetCachedSlowVitalCapacitySVCReportData();

	return (
		<ReportTemplate
			config={{
				title: 'report.type.svc',
				footer: { pageCount: 1, pages: 1 },
				lastPage: true,
				note: { height: 180 }
			}}
		>
			<PatientCard patientData={patient} />
			<Container className={classes.correctnessContainer}>
				<Correctness
					examination={examination}
					upperStatusFieldName={'vcStatus'}
					bottomStatusFieldName={'icStatus'}
					parametersLabels={[
						PARAMETERS.vc.label,
						PARAMETERS.ic.label
					]}
				/>
				<BestVolumeTimeGraph />
			</Container>
			<BestResults />
			<AllMeasurementsTable />
		</ReportTemplate>
	);
};
