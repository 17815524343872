import { PatientLinkWithDoctorRequestSchema } from 'models/Patient/PatientLink';

export const validateEmail = (email) => {
	try {
		PatientLinkWithDoctorRequestSchema.pick({
			email: true
		}).parse({ email });
		return true;
	} catch (arr) {
		return false;
	}
};
