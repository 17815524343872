import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	popper: {
		zIndex: 999
	},
	card: {
		padding: theme.spacing(2)
	}
}));
