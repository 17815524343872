import { Box } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import { LEGEND_ITEMS } from './helpers';
import { LegendItem } from './LegendItem';
import { useStyles } from './styles';

type Props = {
	className?: string;
	legendContainerClassName?: string;
};
export const Legend = ({
	className = '',
	legendContainerClassName = ''
}: Props) => {
	const classes = useStyles();

	return (
		<Box display={'flex'} flexWrap="wrap">
			<Box className={classes.spacer} />

			<Box className={clsx([legendContainerClassName, classes.legend])}>
				{LEGEND_ITEMS.map(({ label, ...rest }) => (
					<LegendItem
						key={label}
						label={label}
						{...rest}
						className={className}
					/>
				))}
			</Box>
		</Box>
	);
};
