import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		borderRadius: 10,
		background: theme.palette.gray.main,
		padding: 16,
		paddingBottom: 10
	},
	title: {
		textTransform: 'uppercase',
		fontSize: 12
	}
}));
