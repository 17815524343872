import { Box } from '@material-ui/core';
import { useExaminationsV2Context } from 'components/context/ExaminationsV2';
import { addDays, isSameDay, subDays } from 'date-fns';
import React from 'react';
import { Form } from 'react-final-form';

import { useStyles as useCommonStyles } from '../styles';
import { DatePicker } from './DatePicker';

export const DateRangeFilter = () => {
	const commonClasses = useCommonStyles();

	const { store: examinationsDateRange } = useExaminationsV2Context(
		(s) => s.examinationsDateRange
	);
	const { store: examinationDateExtremes } = useExaminationsV2Context(
		(s) => s.examinationDateExtremes
	);

	const theEarliestPossibleDate = examinationDateExtremes.startDate;
	const theLatestPossibleDate = examinationDateExtremes.endDate;

	const getStartMaxDate = React.useCallback(
		(endDate: Date): Date =>
			theEarliestPossibleDate &&
			isSameDay(endDate, theEarliestPossibleDate)
				? endDate
				: subDays(new Date(endDate), 1),
		[]
	);

	const getEndMaxDate = React.useCallback(
		(startDate: Date): Date =>
			theLatestPossibleDate && isSameDay(startDate, theLatestPossibleDate)
				? startDate
				: addDays(new Date(startDate), 1),
		[]
	);

	const isDisabled = React.useMemo(
		() =>
			theEarliestPossibleDate === null && theLatestPossibleDate === null,
		[theEarliestPossibleDate, theLatestPossibleDate]
	);

	return (
		<Form
			initialValues={examinationsDateRange}
			onSubmit={() => {}}
			render={({ values }) => (
				<Box className={commonClasses.filtersContainer}>
					<DatePicker
						name="startDate"
						label="patients.examinations_v2.examinations_from"
						minDate={
							theEarliestPossibleDate ??
							getStartMaxDate(values.endDate)
						}
						maxDate={getStartMaxDate(values.endDate)}
						newDateRangeCreator={(date) => ({
							startDate: date,
							endDate:
								examinationsDateRange.endDate as TDateRange['endDate']
						})}
						disabled={isDisabled}
					/>
					<DatePicker
						name="endDate"
						label="patients.examinations_v2.examinations_to"
						minDate={getEndMaxDate(values.startDate)}
						maxDate={
							theLatestPossibleDate ??
							getEndMaxDate(values.startDate)
						}
						newDateRangeCreator={(date) => ({
							startDate:
								examinationsDateRange.startDate as TDateRange['startDate'],
							endDate: date
						})}
						disabled={isDisabled}
					/>
				</Box>
			)}
		/>
	);
};
