import {
	CHART_MEASUREMENT_PARAMETER_OPTION_NAMES as OPTION_NAMES,
	EXAMINATIONS_TYPES,
	UNITS_TYPES,
	validateExaminationValue
} from 'helpers';
import {
	Bronchodilator,
	getBronchodilatorResults
} from 'helpers/examinations/bronchodilator';
import { getBronchodilatorExaminationParameterColor } from 'helpers/examinations/colors';

import BronchodilatorDataRow from '../../Examination/components/BronchodilatorDataRow';
import { formatCreatedDateTime } from './dateFormatter';

export const getBronchodilatorTest = ({ options, name }) => {
	const {
		post_tiffeneau,
		post_tiffeneauStatus,
		pre_tiffeneau,
		pre_tiffeneauStatus,
		examinationVersion
	} = options;

	const postValueFixed = post_tiffeneau?.toFixed(2);
	const preValueFixed = pre_tiffeneau?.toFixed(2);

	const results = getBronchodilatorResults({
		version: examinationVersion,
		valuesSetterCallbacks: Bronchodilator.sidebar,
		...options,
		...UNITS_TYPES.RESULT
	});

	return [
		{
			name,
			values: [
				{
					value: validateExaminationValue(preValueFixed),
					color: getBronchodilatorExaminationParameterColor(
						pre_tiffeneauStatus,
						preValueFixed
					),
					isLabelDisabled: false,
					...UNITS_TYPES.PRE
				},
				{
					value: validateExaminationValue(postValueFixed),
					color: getBronchodilatorExaminationParameterColor(
						post_tiffeneauStatus,
						postValueFixed
					),
					isLabelDisabled: false,
					...UNITS_TYPES.POST
				}
			]
		},
		{ ...results, customRow: BronchodilatorDataRow }
	];
};

export const getBronchodilatorTestProps = ({ options }) => {
	const name = OPTION_NAMES.tiffeneau.label;
	const label = OPTION_NAMES.tiffeneau.type;
	const title = OPTION_NAMES.tiffeneau.translationKey;
	const tab = EXAMINATIONS_TYPES.COPD_TEST.type;

	const parameters = getBronchodilatorTest({ options, name });

	return {
		id: options.id,
		type: options.type,
		createDateTime: formatCreatedDateTime(options.dateCreated),
		label,
		name,
		parameters,
		title,
		tab,
		openPanel: false,
		isContentFlexEnabled: true,
		pdfReport: options.pdfReport || null
	};
};
