import { Box } from '@material-ui/core';
import { EMPTY_VALUE } from 'helpers';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Cookies } from 'utilities';

import { ExaminationNote } from './ExaminationNote';
import { useStyles } from './styles';
import { TechnicalNote } from './TechnicalNote';

export type NotesProps = {
	height?: number;
	examinationId?: string;
	examinationNote?:
		| React.ReactElement<
				AnyType,
				string | React.JSXElementConstructor<AnyType>
				//eslint-disable-next-line
		  >[]
		| string;
	reportInterpretationNote?:
		| React.ReactElement<
				AnyType,
				string | React.JSXElementConstructor<AnyType>
				//eslint-disable-next-line
		  >[]
		| string;
};
export const Notes = React.forwardRef<HTMLDivElement, NotesProps>(
	(
		{
			reportInterpretationNote = EMPTY_VALUE,
			examinationNote,
			examinationId = '',
			height = 195
		},
		ref
	) => {
		const classes = useStyles({ height });

		const getReportInterpretation = () => {
			const reportNote = Cookies.report.getReportNote();
			if (reportNote) {
				return ReactHtmlParser(
					reportNote
						.replaceAll('<', '&#60;')
						.replaceAll(/\n/g, '<br/>')
				);
			}

			return reportInterpretationNote;
		};

		return (
			<Box className={classes.notes} {...{ ref }}>
				<TechnicalNote
					examinationNote={examinationNote}
					examinationId={examinationId}
				/>
				<ExaminationNote
					reportInterpretationNote={getReportInterpretation()}
				/>
			</Box>
		);
	}
);
