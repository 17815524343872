import { useQuery } from 'components/utilities';
import { UseQueryOptionsProps } from 'components/utilities/hooks/react-query/useQuery';
import { GET_SETTINGS_LIST_QUERY_KEY } from 'helpers';
import { UserSettingModel } from 'models/Settings/Settings';
import { useDispatch } from 'react-redux';
import { setSettingsData, setUserUnits } from 'store/actions';

import { SettingsRepository } from './repository';

const useFetchSettings = (
	options: UseQueryOptionsProps<UserSettingModel[]> = {}
) => {
	const dispatch = useDispatch();

	const handleSuccess = (data) => {
		setSettingsData(data)(dispatch);
		setUserUnits({ settings: data })(dispatch);
		options?.onSuccess?.(data);
	};
	const query = useQuery<UserSettingModel[]>(
		GET_SETTINGS_LIST_QUERY_KEY,
		({ cancelToken }) =>
			SettingsRepository.getSettingsList({ cancelToken }),
		{ ...options, onSuccess: handleSuccess }
	);

	return query;
};

export default useFetchSettings;
