import React from 'react';

const useRippleBox = ({ classes }) => {
	const rippleRef = React.useRef();
	const onRippleStart = (e, isHoverEnable, rippleRef) => {
		isHoverEnable && rippleRef.current.start(e);
	};

	const onRippleStop = (e, isHoverEnable, rippleRef) => {
		isHoverEnable && rippleRef.current.stop(e);
	};

	const setHoverEffect = (isHoverEnable) =>
		isHoverEnable && classes.hoverEffect;

	return { onRippleStart, onRippleStop, setHoverEffect, rippleRef };
};

export default useRippleBox;
