import { string } from 'prop-types';
import React from 'react';

import { SelectField } from '../SelectField';
import useMonthItems from './useMonthItems';

const MonthSelectFields = ({ yearFieldName, dayFieldName, ...rest }) => {
	const { months, isMonthDisabled, onMonthChange } = useMonthItems({
		yearFieldName,
		dayFieldName
	});

	return (
		<SelectField
			label="global.month_short"
			options={months}
			required={false}
			disabled={isMonthDisabled}
			onChange={onMonthChange}
			{...rest}
		/>
	);
};

MonthSelectFields.propTypes = {
	yearFieldName: string.isRequired,
	dayFieldName: string.isRequired
};

export default MonthSelectFields;
