import React from 'react';

import { ExaminationNote as ExaminationNoteCmp } from '../../components';
import { useSpirometryFVCData } from '../useSpirometryFVCData';

type Props = {
	className?: string;
};
export const ExaminationNote = ({ className }: Props) => {
	const examinationData = useSpirometryFVCData();

	return (
		<ExaminationNoteCmp
			className={className}
			fev1PredictedPercent={
				examinationData?.bestParameters.fev1.predictedPercent || 0
			}
			percentileFev1Fvc={
				examinationData?.bestParameters.fev1Fvc.percentile || 0
			}
		/>
	);
};
