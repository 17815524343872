import { Box } from '@material-ui/core';
import { FormCardLayoutRPC } from 'components/utilities';
import { object, string } from 'prop-types';
import React from 'react';
import { AutoSizer } from 'react-virtualized';

import Fields from './Fields/Fields';
import FormButton from './FormButton';
import { useStyles } from './styles';
import useScheduleForm from './useScheduleForm';
// Import validation
import { validation } from './validation';

const ScheduleForm = ({ submitBtnText, initialValues }) => {
	const classes = useStyles();

	const { onSubmitForm, isAppointmentReview, onSuccess } = useScheduleForm();

	return (
		<AutoSizer disableWidth>
			{({ height }) => (
				<FormCardLayoutRPC
					style={{ height: height - 48 }}
					submitFormAction={onSubmitForm}
					validation={validation}
					initialValues={initialValues}
					actionButtons={false}
					isCardLayout={false}
					isGoBackAction={false}
					queryOptions={{ onSuccess }}
					classNameRoot={classes.root}
				>
					{({ isSubmitLoading, isSubmitError }) => (
						<Box
							className={classes.wrapper}
							style={{
								height: isSubmitError
									? 'calc(100% - 103px)'
									: 'inherit'
							}}
						>
							<Fields />
							{!isAppointmentReview && (
								<FormButton
									submitBtnText={submitBtnText}
									isSubmitLoading={isSubmitLoading}
								/>
							)}
						</Box>
					)}
				</FormCardLayoutRPC>
			)}
		</AutoSizer>
	);
};

ScheduleForm.propTypes = {
	submitBtnText: string.isRequired,
	initialValues: object.isRequired
};

export default ScheduleForm;
