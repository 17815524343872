import { CHART_MEASUREMENT_PARAMETER_OPTION_NAMES } from 'helpers';
import { SvcPreviewModel } from 'models/Examinations/allExaminationsTypes';
import { SvcPreview } from 'utilities/Examinations/Previews';

import { formatCreatedDateTime } from './helpers';

export const getSvcPreview = (data: SvcPreviewModel): ExaminationPreview => {
	const svcPreview = new SvcPreview(data);
	const ic = svcPreview.getIcPreview();
	const vc = svcPreview.getVcPreview();

	return {
		id: data.id,
		type: data.type,
		createDateTime: formatCreatedDateTime(data.createDateTime),
		tab: data.type,
		title: CHART_MEASUREMENT_PARAMETER_OPTION_NAMES.ic.translationKey,
		parameters: [ic, vc],
		pdfReport: data.pdfReport || null
	};
};
