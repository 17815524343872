import { useQuery } from 'components/utilities';
import { QUERY_APPOINTMENTS_LIST } from 'helpers';

import { AppointmentsRepository } from './repository';
import { AppointmentsServices } from './services';

const useAppointmentsList = (
	options = {},
	{ appointmentsDateRange = {} } = {}
) => {
	const convertedDateRanges = AppointmentsServices.convertRangeDates(
		appointmentsDateRange
	);

	const query = useQuery(
		[QUERY_APPOINTMENTS_LIST, convertedDateRanges],
		async ({ cancelToken }) => {
			try {
				const { data } = await AppointmentsRepository.getAll({
					cancelToken,
					payload: convertedDateRanges
				});
				return AppointmentsServices.convertAppointmentsListData(data);
			} catch (error) {
				return error;
			}
		},
		options
	);

	return query;
};

export default useAppointmentsList;
