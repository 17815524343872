import { QUERY_PATIENT_DATA_AND_PERSONAL_BESTS } from 'helpers';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

const useBasicData = () => {
	const { patientId } = useParams();
	const cache = useQueryClient();
	const [, { patient }] = cache.getQueryData([
		QUERY_PATIENT_DATA_AND_PERSONAL_BESTS,
		patientId
	]);

	return {
		patient
	};
};

export default useBasicData;
