import { GET_EXAMINATION_REPORT_NOTE_QUERY_KEY } from 'helpers';
import { ExaminationNoteModel } from 'models/Examinations/ExaminationNote';
import { useGetCachedData } from 'queries/utilities/useGetCachedData';
import { useParams } from 'utilities';

import { AdditionalData } from '..';

export const useGetCachedExaminationNote = ({
	examinationId
}: Pick<AdditionalData, 'examinationId'> = {}) => {
	const { id: examinationIdParam } = useParams();
	const id = (examinationId as string) || examinationIdParam;

	const examinationData = useGetCachedData<ExaminationNoteModel>(
		GET_EXAMINATION_REPORT_NOTE_QUERY_KEY(id)
	);

	return examinationData;
};
