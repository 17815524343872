/**
 * Form validation
 * @param {string} formType Event form type. One of FORM_NAME from '../../../helpers/form'
 * @param {string} name event name
 * @param {string} description Description of entered event name
 * @param {number} year The onset year of given event
 * @returns {object} validation errors
 */

export default ({ formType }) =>
	({ name, description, year }) => {
		const errors = {};

		if (!name) {
			errors.name = `patients.conditions.form.validation.${formType}_required`;
		}

		if (description?.length > 244) {
			errors.description =
				'patients.conditions.form.validation.description_invalid';
		}

		if (!year) {
			errors.year = 'global.validation.year_required';
		}

		return errors;
	};
