import { func } from 'prop-types';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { Fields, Sidebar } from '../components';
// Import settings
import { FORM_SETTINGS } from '../helpers/form';
import { ALLERGY_FIELDS, ALLERGY_INITIAL_VALUES } from './helpers';

const AllergyForm = ({ setManualRefetch }) => {
	const { pathname } = useLocation();

	const isEdit = pathname.includes('edit');

	const title = isEdit
		? 'patients.allergies.form.title_edit'
		: 'patients.allergies.form.title';

	return (
		<Sidebar
			initialValues={ALLERGY_INITIAL_VALUES}
			title={title}
			formSettings={{
				...FORM_SETTINGS.ALLERGY,
				setManualRefetch: setManualRefetch
			}}
		>
			<Fields fieldsSet={ALLERGY_FIELDS} />
		</Sidebar>
	);
};

AllergyForm.propTypes = {
	setManualRefetch: func.isRequired
};

export default AllergyForm;
