import { Grid } from '@material-ui/core';
import { useTranslations } from 'components/utilities';
import _ from 'lodash';
import {
	array,
	arrayOf,
	bool,
	func,
	node,
	number,
	object,
	oneOfType,
	string
} from 'prop-types';
import React from 'react';
import { Field, useFormState } from 'react-final-form';

import { Alert } from '../../Alerts';
import { KeyboardDatePickerField } from '../../Inputs';
import { LoaderSpinner } from '../../Loader';
import { AutocompleteField, RadioFields } from './components';
import { getBasicFields, isFieldHidden } from './helpers';
import useBasicInformation from './useBasicInformation';

const BasicInformations = ({
	isAvailable = true,
	isIDDisabled = false,
	isEmailAvailable = true,
	isEmailEditable = false,
	isGendersFiltered = false,
	actionButton: ActionButtons,
	height = 670,
	fieldNames,
	fieldsSettings = [],
	children
}) => {
	const { t } = useTranslations();
	const { submitting } = useFormState();

	const basicFields = getBasicFields({
		fieldNames,
		isAvailable,
		isEmailAvailable,
		isEmailEditable,
		isIDDisabled,
		fieldsSettings
	});

	const {
		isDataLoading,
		isDataError,
		dataSets,
		errorMessage,
		locationInitialValues
	} = useBasicInformation({ fieldNames, basicFields, isGendersFiltered });

	const renderFields = (values) => {
		const { grid, type, name, mode } = values;

		const fieldData = { ...values, submitting, name };

		switch (type) {
			case 'select':
				const isPrefix = name === fieldNames.PREFIX;

				return (
					<Grid key={name} item {...grid} style={isFieldHidden(mode)}>
						<AutocompleteField
							dataset={dataSets[name]}
							locationInitialValues={locationInitialValues}
							isPrefix={isPrefix}
							{...fieldData}
						/>
					</Grid>
				);
			case 'date':
				return (
					<Grid key={name} item {...grid} style={isFieldHidden(mode)}>
						<KeyboardDatePickerField
							{..._.omit(fieldData, ['type'])}
						/>
					</Grid>
				);
			case 'radio':
				return (
					<Grid key={name} item {...grid} style={isFieldHidden(mode)}>
						<RadioFields
							{...fieldData}
							options={dataSets[name]?.data || []}
						/>
					</Grid>
				);
			default:
				const { label, disabled, ...rest } = values;
				return (
					<Grid key={name} item {...grid} style={isFieldHidden(mode)}>
						<Field
							fullWidth
							type={type}
							label={t(label)}
							name={name}
							variant="outlined"
							autoComplete="off"
							inputProps={type === 'number' ? { min: 1 } : {}}
							disabled={disabled || submitting}
							parse={(value) => value}
							{...rest}
						/>
					</Grid>
				);
		}
	};

	if (isDataLoading)
		return (
			<LoaderSpinner
				rootSize="medium"
				size={40}
				minHeight={height}
				width={536}
			/>
		);

	return (
		<>
			{isDataError && !isDataLoading && <Alert message={errorMessage} />}
			{!isDataError && (
				<>
					{basicFields.map(renderFields)}
					{children}
					{ActionButtons && <ActionButtons />}
				</>
			)}
		</>
	);
};

BasicInformations.propTypes = {
	isAvailable: bool,
	isIDDisabled: bool,
	isEmailAvailable: bool,
	isEmailEditable: bool,
	isGendersFiltered: bool,
	fieldsSettings: array,
	actionButton: func.isRequired,
	fieldNames: object.isRequired,
	height: oneOfType([number, string]),
	children: oneOfType([arrayOf(node), node])
};

export default BasicInformations;
