import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		height: 'inherit'
	},
	wrapper: {
		'& .Mui-disabled': {
			color: theme.palette.black
		},
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		overflowY: 'auto',
		overflowX: 'hidden',
		paddingRight: 10,
		height: 'inherit'
	},
	buttonContainer: {
		marginTop: '2rem',
		position: 'relative'
	}
}));
