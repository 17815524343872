import { isAfter, isBefore, isSameDay } from 'date-fns';
import { EXAMINATIONS_TYPES } from 'helpers';
import { ActiveExaminationBase } from 'models/Examinations/common';
import {
	ExaminationsListConvertedItemModel,
	ExaminationsListItemModel
} from 'models/Examinations/ExaminationsList';
import {
	PDFHistoryModel,
	PDFsModel,
	ReportParameterModel
} from 'models/Report/common';
import { getCreationDateFromExaminationListItem } from 'utilities/Examinations/common/common';

import { EXAMINATION_TYPES_FILTER_OPTIONS } from './helpers';

export const ExaminationsListServicesV2 = {
	isCopdPostExamination(examination: ExaminationsListConvertedItemModel) {
		return (
			examination.type === EXAMINATIONS_TYPES.SPIROMETRY.type &&
			examination.examinationType === EXAMINATIONS_TYPES.COPD_FINAL.type
		);
	},
	filterExaminationByDateRange(
		examinationsListData: ExaminationsListConvertedItemModel[],
		examinationsDateRange: TDateRange
	): ExaminationsListConvertedItemModel[] {
		const { startIndex, endIndex } = findClosestDateIndexInExaminationsList(
			examinationsListData,
			examinationsDateRange
		);

		if (startIndex === -1 || endIndex === -1) {
			return [];
		}

		const creationDateStartIndex = findCreationDateStartIndex(
			examinationsListData,
			startIndex,
			examinationsDateRange.startDate
		);

		return examinationsListData.slice(endIndex, creationDateStartIndex);
	},
	findAvailableExaminationTypesInExaminationsList(
		examinationsListData: ExaminationsListConvertedItemModel[]
	): ExaminationTypeParam[] {
		const availableExaminationTypes = new Set<ExaminationTypeParam>();
		examinationsListData.forEach((listItem) => {
			listItem.type !== EXAMINATIONS_TYPES.CREATION_DATE.type &&
				availableExaminationTypes.add(listItem.type);
			if (
				availableExaminationTypes.size ===
				EXAMINATION_TYPES_FILTER_OPTIONS.length
			) {
				return;
			}
		});
		return [...availableExaminationTypes];
	},
	filterExaminationsByExaminationTypes(
		examinationsListData: ExaminationsListConvertedItemModel[],
		selectedExaminationTypes: ExaminationTypeParam[]
	): ExaminationsListConvertedItemModel[] {
		const filteredExaminations = examinationsListData.filter(
			(listItem) =>
				listItem.type === EXAMINATIONS_TYPES.CREATION_DATE.type ||
				selectedExaminationTypes.includes(listItem.type)
		);

		return filteredExaminations;
	},
	deleteAllAdjacentCreationDateItems(
		examinationsList: ExaminationsListConvertedItemModel[]
	): ExaminationsListConvertedItemModel[] {
		const filteredExaminationsList = examinationsList.reduce(
			(newExaminationsList, currentListItem, index) => {
				if (
					currentListItem.type !==
					EXAMINATIONS_TYPES.CREATION_DATE.type
				) {
					newExaminationsList.push(currentListItem);
					return newExaminationsList;
				}

				const nextListItem = examinationsList?.[index + 1];

				const isNextListItemCreationDate =
					nextListItem &&
					nextListItem.type === EXAMINATIONS_TYPES.CREATION_DATE.type;

				const isCreationDateLastElementInList =
					!nextListItem &&
					currentListItem.type ===
						EXAMINATIONS_TYPES.CREATION_DATE.type;
				if (
					isNextListItemCreationDate ||
					isCreationDateLastElementInList
				) {
					return newExaminationsList;
				}

				newExaminationsList.push(currentListItem);
				return newExaminationsList;
			},
			[] as ExaminationsListConvertedItemModel[]
		);
		return filteredExaminationsList;
	},
	getExaminationIndexFromList({
		examinationsList,
		id,
		type
	}: {
		examinationsList: ExaminationsListConvertedItemModel[];
		id: ActiveExaminationBase['id'];
		type: ActiveExaminationBase['type'];
	}): number {
		const index = examinationsList.findIndex(
			(exam) =>
				exam.type === type && exam.id.toString() === id?.toString()
		);

		return index;
	},

	findLatestPdfReport({ reports }: { reports: PDFsModel | undefined }) {
		return reports?.history.reduce(
			(acc, next) => {
				if (acc.revision < next.revision) {
					return next;
				}
				return acc;
			},
			{ revision: -1 } as PDFHistoryModel
		);
	},

	setPdfReportKey({
		examination,
		pdfReportKey
	}: {
		examination: ExaminationsListItemModel;
		pdfReportKey: PDFHistoryModel['key'] | undefined;
	}): ExaminationsListItemModel {
		return { ...examination, pdfReport: pdfReportKey || '' };
	},

	convertPlainValuesToParameter<ObjectType>(data: Record<string, number>): {
		[K in keyof ObjectType]: ReportParameterModel;
	} {
		const convertedData = Object.entries(data).reduce(
			(acc, [key, value]) => {
				acc[key] = {
					value: value,
					lln: null,
					predicted: null,
					zScore: null,
					predictedPercent: null,
					percentile: null,
					personalBest: null
				};
				return acc;
			},
			{} as Record<string, ReportParameterModel>
		);

		return convertedData as {
			[K in keyof ObjectType]: ReportParameterModel;
		};
	}
};

function findClosestDateIndexInExaminationsList(
	examinationsListData: ExaminationsListConvertedItemModel[],
	examinationsDateRange: TDateRange
): { startIndex: number; endIndex: number } {
	const startIndex = examinationsListData.findIndex((listItem, i) => {
		const listItemStartDate =
			getCreationDateFromExaminationListItem(listItem);

		const isStartDateInRange =
			isAfter(examinationsDateRange.startDate, listItemStartDate) ||
			(isSameDay(examinationsDateRange.startDate, listItemStartDate) &&
				(!examinationsListData?.[i + 1] ||
					!isSameDay(
						getCreationDateFromExaminationListItem(
							examinationsListData?.[i + 1]
						),
						listItemStartDate
					)));
		return isStartDateInRange;
	});
	const endIndex = examinationsListData.findIndex((listItem) => {
		const listItemEndDate =
			getCreationDateFromExaminationListItem(listItem);
		const isEndDateInRange =
			isBefore(listItemEndDate, examinationsDateRange.endDate) ||
			isSameDay(examinationsDateRange.endDate, listItemEndDate);
		return isEndDateInRange;
	});
	return { startIndex, endIndex };
}

function findCreationDateStartIndex(
	examinationsListData: ExaminationsListConvertedItemModel[],
	index: number,
	startDate: TDateRange['startDate']
): number {
	let newIndex = index;

	let nextListItemCreationDate = getCreationDateFromExaminationListItem(
		examinationsListData[newIndex]
	);

	function nextIndex() {
		newIndex = newIndex + 1;
	}
	const isNextBeforeSelectedStartDate = () =>
		isBefore(nextListItemCreationDate, startDate) ||
		examinationsListData.length === newIndex;

	nextIndex();
	//eslint-disable-next-line
	while (true) {
		if (isNextBeforeSelectedStartDate()) break;

		nextListItemCreationDate = getCreationDateFromExaminationListItem(
			examinationsListData[newIndex]
		);

		nextIndex();
	}

	return newIndex;
}
