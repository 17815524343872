// Import actions
import { toggleArchivedDrugs,togglePrescribedDrugs } from './store/actions';

const useActions = ({ dispatch }) => {
	const handleTogglePrescribedDrugs = (payload) =>
		togglePrescribedDrugs(payload)(dispatch);

	const handleArchivedDrugs = (payload) =>
		toggleArchivedDrugs(payload)(dispatch);

	return {
		togglePrescribedDrugs: handleTogglePrescribedDrugs,
		toggleArchivedDrugs: handleArchivedDrugs
	};
};

export default useActions;
