export class ZScoreValuesValidator {
	static areValuesValid = (data) => {
		const { bestZScoreFev1, bestZScoreFvc, bestZScoreTiffeneau } = data;
		const isFev1Valid = this.#isValueValid(bestZScoreFev1);
		const isFvcValid = this.#isValueValid(bestZScoreFvc);
		const isTiffeneauValid = this.#isValueValid(bestZScoreTiffeneau);
		return isFev1Valid && isFvcValid && isTiffeneauValid;
	};

	static #isValueValid = (value) => {
		return value !== null && value !== undefined;
	};
}
