import clsx from 'clsx';
import { useExaminationsV2Context } from 'components/context/ExaminationsV2';
import {
	LoaderSpinner,
	SwitchComponentWithTransition
} from 'components/elements';
import { useExaminationNote } from 'queries/Examinations/ReportsV2';
import React from 'react';
import { TechnicalNote as TechnicalNoteCmp } from 'views/PatientsDetails/views/ExaminationsV2/Reports/components/organisms';

import { useStyles, useTechnicalNoteStyles } from './styles';

type Props = {
	className?: string;
};
export const TechnicalNote = ({ className = '' }: Props) => {
	const classes = useStyles();
	const { store: activeExamination } = useExaminationsV2Context(
		(s) => s.activeExamination
	);
	const examinationInfo = React.useMemo(
		() => ({
			examinationId: activeExamination.id,
			examinationType: activeExamination.type
		}),
		[]
	);
	const noteData = useExaminationNote({}, examinationInfo);

	return (
		<SwitchComponentWithTransition
			className={clsx([className, classes.root])}
			state={noteData.isLoading}
			initialComponent={() => <LoaderSpinner rootSize={'small'} />}
			newComponent={() => (
				<TechnicalNoteCmp
					useLocalTheme={useTechnicalNoteStyles}
					examinationId={activeExamination.id}
				/>
			)}
		/>
	);
};
