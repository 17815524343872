import { makeStyles } from '@material-ui/styles';
import {
	ALLERGY_ADD_API_URL,
	ALLERGY_EDIT_API_URL,
	ALLERGY_GET_API_URL,
	ALLERGY_RESOURCES_QUERY_KEY,
	CONDITION_ADD_API_URL,
	CONDITION_EDIT_API_URL,
	CONDITION_GET_API_URL,
	CONDITION_RESOURCES_QUERY_KEY,
	SURGERY_ADD_API_URL,
	SURGERY_EDIT_API_URL,
	SURGERY_GET_API_URL,
	SURGERY_RESOURCES_QUERY_KEY
} from 'helpers';
import { ConditionsServices } from 'queries';

export const FORM_NAME = {
	CONDITION: 'condition',
	SURGERY: 'surgery',
	ALLERGY: 'allergy'
};

const { CONDITION, SURGERY, ALLERGY } = FORM_NAME;

export const FORM_SETTINGS = {
	CONDITION: {
		getApiUrl: CONDITION_GET_API_URL,
		addApiUrl: CONDITION_ADD_API_URL,
		editApiUrl: CONDITION_EDIT_API_URL,
		queryKey: CONDITION_RESOURCES_QUERY_KEY,
		conditionType: CONDITION,
		createInitialValues: ConditionsServices.createFormInitialValues,
		convertFormValues: ConditionsServices.convertFormValues
	},
	SURGERY: {
		getApiUrl: SURGERY_GET_API_URL,
		addApiUrl: SURGERY_ADD_API_URL,
		editApiUrl: SURGERY_EDIT_API_URL,
		queryKey: SURGERY_RESOURCES_QUERY_KEY,
		conditionType: SURGERY,
		createInitialValues: ConditionsServices.createFormInitialValues,
		convertFormValues: ConditionsServices.convertFormValues
	},
	ALLERGY: {
		getApiUrl: ALLERGY_GET_API_URL,
		addApiUrl: ALLERGY_ADD_API_URL,
		editApiUrl: ALLERGY_EDIT_API_URL,
		queryKey: ALLERGY_RESOURCES_QUERY_KEY,
		conditionType: ALLERGY,
		createInitialValues: ConditionsServices.createFormInitialValues,
		convertFormValues: ConditionsServices.convertFormValues
	}
};

export const useGlobalStyles = makeStyles((theme) => ({
	wrapper: {
		marginTop: 10
	},
	fieldWrapper: {
		padding: '10px 0',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	label: {
		fontWeight: 400,
		fontSize: 13,
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		color: '#343434',
		'& span': {
			display: 'block',
			fontWeight: 600,
			textAlign: 'right'
		}
	},
	gridDivider: { padding: '2px 0 !important' }
}));
