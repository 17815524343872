export class LinkedSeriesFacade {
	#linkedSeries;
	constructor(linkedSeries) {
		this.#linkedSeries = linkedSeries;
	}

	remove = () => {
		this.#toEachLinkedSerie((linkedSerie) => linkedSerie.remove());
	};

	showSerie = () => {
		this.#toEachLinkedSerie((linkedSerie) =>
			linkedSerie.update(
				{
					zIndex: 999
				},
				false
			)
		);
	};

	setDefaultStyles = () => {
		this.#toEachLinkedSerie((linkedSerie) =>
			linkedSerie.update?.(
				{
					visible: false,
					zIndex: undefined
				},
				false
			)
		);
	};

	setVisibility = (isVisible) => {
		this.#toEachLinkedSerie((linkedSerie) =>
			linkedSerie.update(
				{
					visible: isVisible
				},
				false
			)
		);
	};

	#toEachLinkedSerie = (callback) => {
		for (let i in this.#linkedSeries) {
			callback(this.#linkedSeries[i]);
		}
	};
}
