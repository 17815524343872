import { convertValueUnit } from 'helpers';
import { numberRegex } from 'helpers/validation';

import { SelectFieldAdapter, TextFieldAdapter } from '../../../Inputs';

export const ADDITIONAL_FIELD_NAMES = {
	RACE: 'patientRace',
	WEIGHT_UNIT: 'patientWeightUnit',
	HEIGHT_UNIT: 'patientHeightUnit',
	WEIGHT: 'patientWeight',
	HEIGHT: 'patientHeight',
	BMI: 'patientBmi',
	PATIENT_SMOKER: 'patientSmoker',
	PATIENT_PASSIVE_SMOKER: 'patientPassiveSmoker'
};

export const ADDITIONAL_FIELDS = [
	{
		component: SelectFieldAdapter,
		type: 'select',
		label: 'patients.form.patient_race',
		name: ADDITIONAL_FIELD_NAMES.RACE,
		grid: { lg: 12, md: 12, sm: 12, xs: 12 },
		disabled: false,
		required: true
	},
	{
		component: TextFieldAdapter,
		type: 'text',
		regex: numberRegex,
		label: 'patients.form.patient_weight',
		name: ADDITIONAL_FIELD_NAMES.WEIGHT,
		grid: { lg: 9, md: 9, sm: 9, xs: 12 },
		disabled: false,
		required: true
	},
	{
		component: SelectFieldAdapter,
		type: 'select',
		label: 'patients.form.patient_weight_unit',
		name: ADDITIONAL_FIELD_NAMES.WEIGHT_UNIT,
		onMenuItemClick: ({ changeFormValue, values, newUnit }) => {
			const convertedWeight = convertValueUnit(
				values[ADDITIONAL_FIELD_NAMES.WEIGHT],
				values[ADDITIONAL_FIELD_NAMES.WEIGHT_UNIT],
				newUnit
			);
			changeFormValue(ADDITIONAL_FIELD_NAMES.WEIGHT, convertedWeight);
		},
		grid: { lg: 3, md: 3, sm: 3, xs: 12 },
		disabled: false,
		required: true
	},
	{
		component: TextFieldAdapter,
		type: 'text',
		regex: numberRegex,
		label: 'patients.form.patient_height',
		name: ADDITIONAL_FIELD_NAMES.HEIGHT,
		grid: { lg: 9, md: 9, sm: 9, xs: 12 },
		disabled: false,
		required: true
	},
	{
		component: SelectFieldAdapter,
		type: 'select',
		label: 'patients.form.patient_height_unit',
		name: ADDITIONAL_FIELD_NAMES.HEIGHT_UNIT,
		onMenuItemClick: ({ changeFormValue, values, newUnit }) => {
			const convertedHeight = convertValueUnit(
				values[ADDITIONAL_FIELD_NAMES.HEIGHT],
				values[ADDITIONAL_FIELD_NAMES.HEIGHT_UNIT],
				newUnit
			);
			changeFormValue(ADDITIONAL_FIELD_NAMES.HEIGHT, convertedHeight);
		},
		grid: { lg: 3, md: 3, sm: 3, xs: 12 },
		disabled: false,
		required: true
	},
	{
		component: TextFieldAdapter,
		type: 'number',
		label: 'patients.form.patient_bmi',
		name: ADDITIONAL_FIELD_NAMES.BMI,
		grid: { lg: 12, md: 12, sm: 12, xs: 12 },
		disabled: true,
		required: false
	}
];
