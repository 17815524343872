import { Box } from '@material-ui/core';
import { Typography } from 'components/elements/atoms/ExaminationsV2';
import { useTranslations } from 'components/utilities';
import { useGetCachedReportData } from 'queries/Examinations/ReportsV2/getter';
import React from 'react';
import { LocaleDate } from 'utilities';

import { createDoctorName } from '../../PatientCard/helpers';
import { useStyles } from './styles';

export const Signature = () => {
	const classes = useStyles();
	const { doctorUser } = useGetCachedReportData();
	const { t } = useTranslations();

	const dateNow = React.useMemo(
		() => new LocaleDate().getStringFormatDate(),
		[]
	);
	return (
		<Box className={classes.signature}>
			<Typography>
				{`${t('report.clinic.dr')} ${createDoctorName(
					doctorUser?.first_name,
					doctorUser?.last_name
				)}`}
			</Typography>
			<Typography>{dateNow}</Typography>
		</Box>
	);
};
