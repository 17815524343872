import { FormHelperText } from '@material-ui/core';
import { useTranslations } from 'components/utilities';
import React from 'react';

import { useStyles } from './styles';

type Props = { translatedError?: string; message?: string };

const InputErrorLabel = ({ translatedError, message }: Props) => {
	const { t } = useTranslations();
	const classes = useStyles();
	return (
		<FormHelperText className={classes.errorText}>
			{translatedError
				? t(translatedError)
					? t(translatedError)
					: translatedError
				: message}
		</FormHelperText>
	);
};

export default InputErrorLabel;
