import { Box, Typography as MuiTypography } from '@material-ui/core';
// Import icon
import { ReactComponent as IconError } from 'assets/icons/ic_alert_error.svg';
import { ReactComponent as IconSuccess } from 'assets/icons/ic_alert_success.svg';
import { ReactComponent as IconWarning } from 'assets/icons/ic_alert_warning.svg';
import clsx from 'clsx';
import { useTranslations } from 'components/utilities';
import { string } from 'prop-types';
import React from 'react';

import { ALERT_CUSTOM_TYPES } from './helpers';
import { useStyles } from './styles';

const CustomAlert = ({
	type = ALERT_CUSTOM_TYPES.WARNING,
	message = '',
	className = ''
}) => {
	const classes = useStyles({ type });
	const { t } = useTranslations();

	const renderIcon = () => {
		switch (type) {
			case ALERT_CUSTOM_TYPES.SUCCESS:
				return <IconSuccess className={classes.icon} />;
			case ALERT_CUSTOM_TYPES.WARNING:
				return <IconWarning className={classes.icon} />;
			case ALERT_CUSTOM_TYPES.ERROR:
				return <IconError className={classes.icon} />;

			default:
				break;
		}
	};

	return (
		<Box className={clsx(classes.root, className)}>
			<Box className={classes.content}>
				{renderIcon()}
				<MuiTypography
					className={classes.message}
					variant="body1"
					component="p"
				>
					{t(message)}
				</MuiTypography>
			</Box>
		</Box>
	);
};

CustomAlert.propTypes = {
	title: string,
	type: string,
	message: string.isRequired,
	className: string
};

export default CustomAlert;
