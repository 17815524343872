import { CHART_EXAMINATION_PARAMETER_TYPES as GROUPS_TYPES } from 'helpers';
import { arrayOf, node, oneOfType } from 'prop-types';
import React from 'react';

import { ParameterGroup } from './classes/ParameterGroup';
import useActions from './useActions';

export const ParametersContext = React.createContext(null);

const ParametersProvider = ({ children }) => {
	const [activeParameterGroup, setActiveParameterGroup] = React.useState(
		new ParameterGroup(GROUPS_TYPES.LUNG)
	);

	const actions = useActions({
		setActiveParameterGroup,
		activeParameterGroup
	});

	return (
		<ParametersContext.Provider
			value={{ activeParameterGroup, ...actions }}
		>
			{children}
		</ParametersContext.Provider>
	);
};

ParametersProvider.propTypes = {
	children: oneOfType([arrayOf(node), node]).isRequired
};

export default ParametersProvider;
