import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: { height: '100%' },
	type: {
		textTransform: 'uppercase',
		fontWeight: 600,
		color: theme.palette.customBlack.main,
		fontSize: 18,
		lineHeight: '19.8px'
	}
}));
