export const capitalizeString = (text: string): string =>  text.charAt(0).toUpperCase() + text.slice(1)

export const areBooleansTruthy = (method = 'some', ...args: boolean[]): boolean  => {
	if (args.length === 0) {
		throw new Error('there are any arguments');
	}
	if (!args?.[method]) {
		throw new Error(`Invalid method, give: ${method}`);
	}

	return args[method]((arg) => !!arg === true);
};

export const isLastArrayIndex = <T>(index: number, arr: T[]): boolean => {
	return arr.length - 1 === index;
};
