import { Box } from '@material-ui/core';
import { Divider } from 'components/elements';
import { SpirometryPreviewModel } from 'models/Examinations/allExaminationsTypes';
import React from 'react';

import {
	AdditionalParameters,
	BestResult,
	ExaminationTypeLabel,
	TopBar
} from './components';
import { ShowMoreButton } from './components/ShowMoreButton';
import { ITooltipData } from './ExaminationTooltipFactory';
import { useStyles } from './styles';

export type TooltipProps = ITooltipData & {
	chartName: ExaminationChartNames;
	examinationType: SpirometryPreviewModel['examinationType'];
	active: boolean;
};

const Tooltip = ({
	createDateTime,
	bestValue,
	restValues,
	chartName,
	active,
	examinationType
}: TooltipProps) => {
	const classes = useStyles();

	return (
		<Box style={{ position: 'relative' }}>
			<TopBar
				createDateTime={createDateTime}
				examinationType={examinationType}
			/>
			<ExaminationTypeLabel chartName={chartName} />
			<Box className={classes.additionalParameters}>
				<BestResult {...bestValue} />
				{restValues && (
					<>
						<Divider
							data-testid="Tooltip-divider"
							className={classes.divider}
							orientation="horizontal"
							flexItem
						/>
						<AdditionalParameters restValues={restValues} />
					</>
				)}
			</Box>
			<ShowMoreButton active={active} examinationType={examinationType} />
		</Box>
	);
};

export default Tooltip;
