import { useQuery } from 'components/utilities';
import { QUERY_PATIENT_DATA_AND_PERSONAL_BESTS } from 'helpers';
import { useParams } from 'react-router-dom';

import { PatientRepository } from './repository';
import { PatientServices } from './services';

/**
 * fetch patient's personal bests scores and his data
 * @param {object} options query options
 * @param {object} additionalData query additional data e.g services arguments
 * @param {number} additionalData.patientId patient ID to be fetched
 * @returns fetch patient's resources query
 */
const useFetchAll = (options = {}, { patientId } = {}) => {
	const { patientId: paramsPatientId } = useParams();

	const id = paramsPatientId || patientId;
	const query = useQuery(
		[QUERY_PATIENT_DATA_AND_PERSONAL_BESTS, id],
		getResources,
		options
	);

	function getResources(payload) {
		return Promise.all([
			PatientRepository.getPersonalBests({ ...payload, patientId: id }),
			PatientRepository.getData({ ...payload, patientId: id })
		]).then((response) => {
			const [{ data: personalBests }, { patient }] = response;
			const patientDataWithBmi =
				PatientServices.addBmiToPatientData(patient);
			return [personalBests, { patient: patientDataWithBmi }];
		});
	}

	return query;
};

export default useFetchAll;
