export const getCurrentMonthDays = ({
	currentYear,
	currentMonth,
	currentDate,
	monthValue,
	yearValue
}) => {
	const currentMonthDaysNumber = new Date(
		Number(yearValue),
		Number(monthValue),
		0
	).getDate();

	const days = new Array(currentMonthDaysNumber)
		.fill('')
		.map((_, index) => ('0' + (index + 1)).slice(-2));

	if (
		currentYear === Number(yearValue) &&
		Number(monthValue) >= currentMonth
	) {
		return days.slice(0, Number(currentDate));
	}
	return days;
};
