import { IDLE } from 'helpers/variables';
import React from 'react';
import { QueryStatus } from 'react-query';

type Props = {
	idle: QueryStatus;
	success: () => JSX.Element | null;
	loading: () => JSX.Element;
	error: () => JSX.Element;
};

const IdleRender = ({
	idle,
	success: Success,
	loading: Loading,
	error: Error
}: Props): JSX.Element => {
	switch (idle) {
		case IDLE.LOADING:
			return <Loading />;
		case IDLE.SUCCESS:
			return <Success />;
		case IDLE.ERROR:
			return <Error />;
		default:
			return <Loading />;
	}
};

export default IdleRender;
