import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	root: {
		minWidth: 160,
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center'
	},
	button: {
		padding: 0
	},
	calendar: {
		'& .MuiInput-input': {
			textAlign: 'center',
			cursor: 'pointer'
		}
	}
}));
