import { MenuItem } from '@material-ui/core';
import { SelectFieldAdapter } from 'components/elements';
import { useTranslations } from 'components/utilities';
import React from 'react';
import { Field, useFormState } from 'react-final-form';

import { DURATION_ARRAY } from '../helpers';
import { useStyles } from '../styles';
import useDurationField from './useDurationField';

const DurationField = () => {
	const classes = useStyles();
	const { t } = useTranslations();
	const { submitting } = useFormState();
	const { duration, endTimeHelperText, isAppointmentReview, getEndTime } =
		useDurationField();

	return (
		<Field
			fullWidth
			component={SelectFieldAdapter}
			name={'duration'}
			className={classes.buttonAdornment}
			disabled={submitting || isAppointmentReview}
			margin="dense"
			variant="outlined"
			label={t('schedule.form.duration')}
			initialValue={duration}
			inputOnChange={getEndTime}
			helperText={`${t('schedule.form.end_time')} ${
				endTimeHelperText || '-'
			}`}
			required
		>
			{DURATION_ARRAY.map((duration) => (
				<MenuItem key={duration} value={duration}>
					{`${duration} ${t('global.minutes')}`}
				</MenuItem>
			))}
		</Field>
	);
};

export default DurationField;
