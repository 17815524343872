import { Box } from '@material-ui/core';
import {
	ParameterLabel,
	ValueLabel
} from 'components/elements/atoms/ExaminationsV2';
import React from 'react';

import { useStyles } from './styles';

type Props = {
	value: number;
	unit: string;
};

const Value = ({ value, unit }: Props) => {
	const classes = useStyles();
	return (
		<Box className={classes.flex}>
			<ValueLabel value={value?.toString()} />
			<ParameterLabel
				className={classes.unit}
				disableTranslation
				label={` ${unit}`}
			/>
		</Box>
	);
};

export default Value;
