import { useQuery } from 'components/utilities';
import { useSettingsServices } from 'queries/Settings';
import { useDispatch } from 'react-redux';
import { FETCH_LANGUAGES_SUCCESS } from 'store/types';
import { Cookies } from 'utilities';

import { UseQueryOptionsProps } from '../../components/utilities/hooks/react-query/useQuery';
import { LanguagesRepository } from './repository';

export const useFetchLanguages = (
	options: UseQueryOptionsProps<{ data: { data: Record<string, string> } }>,
	{ translationGroup }: { translationGroup?: string } = {}
) => {
	const dispatch = useDispatch();

	const settingsService = useSettingsServices();

	const handleFetchResources = () => {
		const cookieLng = Cookies.report.getReportLanguage();
		const panelLanguage =
			settingsService.findByName('language')?.value?.[0];

		const language = cookieLng ?? panelLanguage ?? 'en';
		return LanguagesRepository.getTranslations(language, translationGroup);
	};

	return useQuery<{ data: { data: Record<string, string> } }>(
		'translations',
		handleFetchResources,
		{
			...options,
			onSuccess: (data) => {
				dispatch({
					type: FETCH_LANGUAGES_SUCCESS,
					payload: { translations: data?.data?.data, lng: 'en' }
				});
				options?.onSuccess?.(data);
			}
		}
	);
};
