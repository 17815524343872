import {
	ContainerWithBorder,
	Title
} from 'components/elements/atoms/ExaminationsV2';
import { ParametersTable } from 'components/elements/organisms/ExaminationsV2';
import { useTranslations } from 'components/utilities';
import { CheckTypeModel } from 'models/Report/CalibrationCheck';
import React from 'react';

import { columns } from './columns';
import { convertToColumnsData } from './helpers';
import { useStyles } from './styles';

type Props = {
	checkType: CheckTypeModel;
	title: string;
};
export const CheckTable = ({ checkType, title }: Props) => {
	const classes = useStyles();
	const { tNoParser } = useTranslations();

	return (
		<ContainerWithBorder>
			<Title title={title} />
			<ParametersTable
				rowClassName={classes.row}
				columns={columns}
				data={convertToColumnsData(checkType, tNoParser)}
			/>
		</ContainerWithBorder>
	);
};
