import { ReportParameterModel } from 'models/Report/common';
import { SlowVitalCapacitySVCMeasurementModel } from 'models/Report/SlowVitalCapacitySVC';
import { ColumnDataImpl } from 'utilities/Examinations/ExaminationDetails/common';
import { SVC_ALL_PARAMETERS } from 'utilities/Examinations/ExaminationDetails/helpers';

export const addBestValueToAllMeasurements = (
	measurements?: SlowVitalCapacitySVCMeasurementModel[]
): SlowVitalCapacitySVCMeasurementModel[] => {
	if (!measurements) {
		return [] as SlowVitalCapacitySVCMeasurementModel[];
	}
	const bestMeasurement = measurements.find((m) => m.isBest);
	const parametersKeys = Object.keys(SVC_ALL_PARAMETERS);

	return new Array(measurements.length).fill('').map((_, index) => {
		const element: SlowVitalCapacitySVCMeasurementModel =
			measurements[index] || {};
		parametersKeys.forEach((p) => {
			element[p] = {
				...element[p],
				best: bestMeasurement?.[p].value || null
			};
		});
		return element;
	});
};

export const convertBestResultsToColumnsData = (
	data?: SlowVitalCapacitySVCMeasurementModel
) => {
	if (!data) {
		return [];
	}

	const svcColumnData = new ColumnDataImpl<
		SlowVitalCapacitySVCMeasurementModel,
		ReportParameterModel
	>(SVC_ALL_PARAMETERS, data);
	svcColumnData.createColumnData();

	return svcColumnData.columnData;
};
