import {
	INVITATION_STATUS,
	InvitationStatus
} from 'models/Patient/PatientModel';

export const getLinkedDisplayValue = (status: InvitationStatus) =>
	({
		[INVITATION_STATUS.UNLINKED]: 'patients.table.account_link.unlinked',
		[INVITATION_STATUS.LINKED]: 'patients.table.account_link.linked',
		[INVITATION_STATUS.PENDING]: 'patients.table.account_link.pending'
	}[status]);
