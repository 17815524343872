import { determineIfCellIsInteger } from 'components/elements/organisms/ExaminationsV2/ParametersTable/helpers';
import { CHART_MEASUREMENT_PARAMETER_OPTION_NAMES } from 'helpers';
import { ExaminationsParametersType } from 'types/Examinations/parameters';

import {
	IParameterValuesData,
	ParameterValuesData
} from '../../ParameterValuesData';
import { IParameterBuilder } from './SpirometryParametersDirector';

const { pef } = CHART_MEASUREMENT_PARAMETER_OPTION_NAMES;
export class BestValueBuilder implements IParameterBuilder {
	private parameterData: IParameterValuesData;
	private parameterType: ExaminationsParametersType | undefined;
	constructor(parameterType?: ExaminationsParametersType) {
		this.parameterData = new ParameterValuesData();
		this.parameterType = parameterType;
	}

	reset = () => {
		this.parameterData = new ParameterValuesData();
	};

	setValidator = () => {
		this.parameterData.setValidator((v) => v >= 0);
	};
	setValue = (_value) => {
		const isValueDecimal = this.parameterType
			? determineIfCellIsInteger({
					isInteger: { rows: [pef.name] },
					parameterType: this.parameterType
			  })
			: false;
		const decimalsAmount = isValueDecimal ? 0 : 2;
		const value = _value?.toFixed(decimalsAmount);

		this.parameterData.setValue(value);
	};

	setUnit = (u) => {
		this.parameterData.setUnit(u);
	};

	setColor = (color) => {
		this.parameterData.setColor(color);
	};

	getValue = () => {
		return this.parameterData;
	};
}
