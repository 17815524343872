import { EMPTY_VALUE } from 'helpers';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';

export interface ICityProps {
	/**
	 * City name string
	 */
	city: string | null;
	/**
	 * Country code label
	 */
	countryCode?: string | null;
	/**
	 * Determine if display country code next to city label
	 */
	enableCountryCode?: boolean;
}

export const City = ({
	city,
	countryCode = '',
	enableCountryCode = false
}: ICityProps) => {
	const cityLabel = city ? `${city}` : EMPTY_VALUE;

	const label = enableCountryCode
		? countryCode
			? `${cityLabel} (${countryCode})`
			: `${cityLabel} (${EMPTY_VALUE})`
		: cityLabel;

	return <>{ReactHtmlParser(label)}</>;
};
