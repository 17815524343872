import { Box, Divider } from '@material-ui/core';
import React from 'react';

import Group from './Group';
import { PARAMETERS_GROUPS } from './helpers';
import { useStyles } from './styles';

const ParameterGroups = () => {
	const classes = useStyles();
	return (
		<Box className={classes.root}>
			<Box
				component="ul"
				className={classes.list}
				data-testid="ParameterGroups-unorderedList"
			>
				{PARAMETERS_GROUPS.map((parameterType) => (
					<Group key={parameterType.type} {...parameterType} />
				))}
			</Box>
			<Divider className={classes.divider} />
		</Box>
	);
};

export default ParameterGroups;
