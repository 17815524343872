import { MenuItem } from '@material-ui/core';
import { useTranslations } from 'components/utilities';
import { IDLE } from 'helpers/variables';
import React from 'react';

import { ISuccessContentProps } from '../../ModalDialog/components/ModalDialogContent/SuccessContent';
import {
	IRequestConfirmationModalDialogProps,
	RequestConfirmationModalDialog
} from '../../ModalDialog/RequestConfirmationModalDialog';
import {
	IUseRequestMenuItemProps,
	useRequestMenuItem
} from './useRequestMenuItem';

export type IRequestModalMenuItemProps<TResponseData> =
	IUseRequestMenuItemProps<TResponseData> &
		Omit<
			IRequestConfirmationModalDialogProps,
			| 'isLoading'
			| 'isSuccess'
			| 'open'
			| 'setClose'
			| 'requestConfirmation'
			| 'apiError'
		> & {
			menuItemLabel: TranslationKey;
		};

export const RequestModalMenuItem = <TResponseData,>({
	promiseFn,
	closeMenu,
	validate,
	onSuccess: onSuccessProp = () => {},
	actionButtonsProps = {},
	children,
	menuItemLabel,
	modalTextContent,
	disableModalTitle = false,
	modalTitle,
	successProps = { textContent: '' } as ISuccessContentProps,
	onExited = () => {},
	textAlign = 'center'
}: IRequestModalMenuItemProps<TResponseData>) => {
	const { t } = useTranslations();

	const {
		idle,
		open,
		apiError,
		setOpen,
		setClose,
		reset,
		requestConfirmation
	} = useRequestMenuItem<TResponseData>({
		promiseFn,
		closeMenu,
		onSuccess: onSuccessProp,
		validate,
		actionButtonsProps
	});

	const isLoading = idle === IDLE.LOADING;
	const isSuccess = idle === IDLE.SUCCESS;

	return (
		<>
			<MenuItem onClick={setOpen}>{t(menuItemLabel)}</MenuItem>
			<RequestConfirmationModalDialog
				open={open}
				setClose={setClose}
				apiError={apiError}
				isLoading={isLoading}
				isSuccess={isSuccess}
				requestConfirmation={requestConfirmation}
				actionButtonsProps={actionButtonsProps}
				successProps={successProps}
				disableModalTitle={disableModalTitle}
				modalTitle={modalTitle}
				onExited={() => {
					reset();
					onExited();
				}}
				modalTextContent={modalTextContent}
				textAlign={textAlign}
			>
				{children}
			</RequestConfirmationModalDialog>
		</>
	);
};
