import { SvcPreviewModel } from 'models/Examinations/allExaminationsTypes';

import { Svc } from '../common/Svc';
import { IParameterTooltip } from './common';

interface ISvcTooltip {
	getIcTooltip: () => IParameterTooltip;
	getVcTooltip: () => IParameterTooltip;
}

export class SvcTooltip extends Svc implements ISvcTooltip {
	constructor(data: SvcPreviewModel) {
		super(data);
	}

	public getIcTooltip = (): IParameterTooltip => ({
		bestValue: this.getIcValues()
	});
	public getVcTooltip = (): IParameterTooltip => ({
		bestValue: this.getVcValues()
	});
}
