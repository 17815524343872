import { ReactComponent as CircleIcon } from 'assets/icons/circle_black.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/cross_black.svg';
import { ReactComponent as TickIcon } from 'assets/icons/tick_black.svg';

import { LegendItemProps } from './LegendItem';

export const LEGEND_ITEMS: LegendItemProps[] = [
	{
		icon: TickIcon,
		label: 'report.correctness.acceptable'
	},
	{
		icon: CircleIcon,
		label: 'report.correctness.usable'
	},
	{
		icon: CrossIcon,
		label: 'report.correctness.unacceptable'
	}
];
