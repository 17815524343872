import { Box, Typography } from '@material-ui/core';
import {
	useExaminationsV2Context,
	useExaminationsV2RefsContext
} from 'components/context/ExaminationsV2';
import { useFadeTransitionStyles } from 'components/elements/atoms/Transitions/styles';
import { useTranslations } from 'components/utilities';
import { QUERY_PATIENT_DATA_AND_PERSONAL_BESTS, TRANSITIONS } from 'helpers';
import { PersonalBestModel } from 'models/Examinations/PersonalBest';
import React from 'react';
import { useQueryClient } from 'react-query';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { useParams } from 'utilities';

import { createExaminationId } from '../../../helpers';
import Examination from '../ExaminationListItem/Examination';
import { ExaminationListItemFactory } from '../ExaminationListItem/ExaminationListItemFactory/ExaminationListItemFactory';
import { useStyles } from './styles';

export const PinnedExaminationListItem = () => {
	const fadeClassNames = useFadeTransitionStyles();

	const { t } = useTranslations();
	const cacheQuery = useQueryClient();
	const { patientId } = useParams();

	const { store: pinnedExaminationContainerRef } =
		useExaminationsV2RefsContext((s) => s.pinnedExaminationContainerRef);

	const { store: activeExamination } = useExaminationsV2Context(
		(s) => s.activeExamination
	);

	const classes = useStyles({
		activeExaminationHeight: activeExamination.data?.height || 0
	});

	const personalBest = cacheQuery.getQueryData<
		[{ best: PersonalBestModel }] | []
	>([QUERY_PATIENT_DATA_AND_PERSONAL_BESTS, patientId]);

	const data = activeExamination.data
		? new ExaminationListItemFactory(
				activeExamination.data,
				personalBest?.[0]?.best
		  ).createItem()
		: {};

	if (!activeExamination?.data) {
		return null;
	}
	return (
		<Box
			className={classes.root}
			{...{ ref: pinnedExaminationContainerRef }}
		>
			<Typography className={classes.label}>
				{t('patients.examinations_v2.selected_examination')}
			</Typography>
			<Box className={classes.examinationWrapper}>
				<SwitchTransition>
					<CSSTransition
						classNames={fadeClassNames}
						key={createExaminationId(activeExamination)}
						timeout={TRANSITIONS.DURATIONS.enteringScreen}
					>
						<Examination
							data={data}
							classNameRoot={classes.examinationRoot}
						/>
					</CSSTransition>
				</SwitchTransition>
			</Box>
		</Box>
	);
};
