import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { ReactHMLParserType } from 'components/utilities/hooks/translations/types';
import { getGraphTriangleData } from 'helpers';
import HighchartsMore from 'highcharts/highcharts-more';
import Highcharts, { PointOptionsObject } from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';

import { Title } from '../Typography';
import {
	getGraphDimensionsWithMinMax,
	getTriangleMaxValues,
	validateParameterValue
} from './helpers';
import { getOptions } from './options';
import { useStyles } from './styles';

// Initialize chart
HighchartsMore(Highcharts);

// Load Highcharts modules
// eslint-disable-next-line
require('highcharts/modules/exporting')(Highcharts);

export type AxisValueRatio = `${number}:${number}`;
export interface IGraphData {
	data: PointOptionsObject[];
	isBest?: boolean;
	lineWidth?: number;
	color?: string;
	name?: string;
}
export interface IGraphProps {
	/**
	 * includes graph signals with X,Y data array
	 */
	data: IGraphData[];
	/**
	 * displayed y axis label
	 */
	yAxisLabel?: ReactHMLParserType | null;
	/**
	 * displayed y axis label
	 */
	xAxisLabel?: ReactHMLParserType | null;
	/**
	 * determine if background triangle should be displayed
	 */
	disableTriangle?: boolean;
	/**
	 * triangle XY points data
	 */
	triangleData?: PointOptionsObject[];
	/**
	 * pef
	 */
	pefPredicted?: number | null;
	/**
	 * fvc
	 */
	fvcPredicted?: number | null;
	/**
	 * describes ration between Y and X axis.
	 * YAxis tick interval : XAxis tick interval
	 */
	axisValuesRatio?: AxisValueRatio;
	/**
	 * fixed axis min/max values.
	 */
	axisBoundaries: {
		MIN_Y: number;
		MAX_Y: number;
		MIN_X: number;
		MAX_X: number;
	};
	/**
	 * Graph container max width and height.
	 * Based on that boundaries graph dimension are calculate to generate width/height ratio based on given axisValuesRatio
	 */
	containerBoundaries?: {
		height: number;
		width: number;
	};
	/**
	 * graph title
	 */
	title: {
		label: string;
		className?: string;
	};
	/**
	 * graph root box className
	 */
	className?: string;
}

export const Graph = React.forwardRef<HighchartsReact.RefObject, IGraphProps>(
	(
		{
			data,
			xAxisLabel,
			yAxisLabel,
			title,
			disableTriangle = false,
			fvcPredicted = undefined,
			pefPredicted = undefined,
			axisValuesRatio = '1:1',
			axisBoundaries,
			containerBoundaries = {
				height: 200,
				width: 341
			},
			className = ''
		}: IGraphProps,
		ref
	) => {
		const classes = useStyles();
		const triangleData = React.useMemo(
			() =>
				disableTriangle ||
				validateParameterValue(fvcPredicted) ||
				validateParameterValue(pefPredicted)
					? []
					: getGraphTriangleData(
							fvcPredicted as number,
							pefPredicted as number
							//eslint-disable-next-line
					  ),
			[fvcPredicted, pefPredicted, disableTriangle]
		);

		const triangleMaxAxisValues = React.useMemo(
			() =>
				disableTriangle
					? {}
					: getTriangleMaxValues(triangleData, axisValuesRatio),
			[fvcPredicted, pefPredicted, disableTriangle]
		);

		const dimensionsWithMinMax = getGraphDimensionsWithMinMax(
			data,
			axisValuesRatio as AxisValueRatio,
			axisBoundaries,
			containerBoundaries,
			triangleMaxAxisValues
		);

		const maxXAxis = React.useMemo(
			() =>
				triangleMaxAxisValues?.maxX
					? Math.max(
							dimensionsWithMinMax.x.max,
							triangleMaxAxisValues?.maxX
					  )
					: dimensionsWithMinMax.x.max,
			[]
		);

		const maxYAxis = React.useMemo(
			() =>
				triangleMaxAxisValues?.maxY
					? Math.max(
							dimensionsWithMinMax.y.max,
							triangleMaxAxisValues?.maxY
					  )
					: dimensionsWithMinMax.y.max,
			[]
		);

		const options = React.useMemo(() => {
			const opt = getOptions({
				data,
				triangleData,
				yAxis: {
					tickInterval: Number(axisValuesRatio.split(':')[0]),
					label: yAxisLabel || '',
					min: dimensionsWithMinMax.y.min,
					max: maxYAxis
				},
				xAxis: {
					tickInterval: Number(axisValuesRatio.split(':')[1]),
					label: xAxisLabel || '',
					min: dimensionsWithMinMax.x.min,
					max: maxXAxis
				}
			});
			return opt;
		}, []);

		return (
			<Box className={clsx([className, classes.graphContainer])}>
				<Title
					title={title.label}
					className={clsx([title.className, classes.title])}
				/>
				<HighchartsReact
					ref={ref}
					containerProps={{
						style: {
							height: dimensionsWithMinMax.height,
							width: dimensionsWithMinMax.width
						}
					}}
					highcharts={Highcharts}
					options={options}
				/>
			</Box>
		);
	}
);
