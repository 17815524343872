import { Chip as MuiChip, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import palette from 'theme/palette';

import useTranslations from '../../../utilities/hooks/translations/useTranslations';
import { CollapsedContent } from './CollapsedContent';
import { useStyles } from './styles';
import { IChipProps } from './types';

export const Chip = ({
	label,
	className,
	backgroundColor = palette.primary.main,
	collapsedLabel = '',
	collapsedButton = false,
	onClick = () => {},
	isLoading,
	size = 'medium'
}: IChipProps) => {
	const collapsedContentRef = React.useRef<HTMLDivElement>(null);
	const [minWidth, setMinWidth] = React.useState(0);

	const classes = useStyles({ backgroundColor, size, minWidth });
	const { t } = useTranslations();

	React.useLayoutEffect(() => {
		if (collapsedContentRef?.current) {
			const width = getComputedStyle(collapsedContentRef.current).width;
			setMinWidth(Number(width.substring(0, width.length - 2)));
		}
	}, []);

	const renderLabel = React.useCallback(
		() => (
			<Typography className={clsx([classes.font, classes.label])}>
				{t(label)}
			</Typography>
		),
		[label]
	);

	if (collapsedButton) {
		return (
			<MuiChip
				onClick={onClick}
				className={clsx([
					classes.chip,
					classes.relative,
					classes.chipHover,
					className
				])}
				disabled={isLoading}
				component="button"
				label={
					<>
						{renderLabel()}
						<CollapsedContent
							ref={collapsedContentRef}
							collapsedLabel={collapsedLabel}
							size={size}
							backgroundColor={backgroundColor}
						/>
					</>
				}
			/>
		);
	}
	return (
		<MuiChip
			className={clsx([className, classes.chip])}
			label={renderLabel()}
		/>
	);
};
