import { Box } from '@material-ui/core';
import { TextFieldAdapter } from 'components/elements';
import { useTranslations } from 'components/utilities';
import { bool } from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';

import { useStyles } from './styles';

const Fields = ({ submitting }) => {
	const { t } = useTranslations();
	const classes = useStyles();
	return (
		<>
			<Box className={classes.boxField}>
				<Field
					fullWidth
					component={TextFieldAdapter}
					type="text"
					label={t('reset_password.email')}
					name="email"
					variant="outlined"
					autoComplete="false"
					disabled={submitting}
					required
				/>
			</Box>
		</>
	);
};

Fields.propTypes = {
	submitting: bool.isRequired
};

export default Fields;
