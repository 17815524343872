import {
	SelectedParameters,
	useExaminationsTrendChartContext
} from 'components/context/ExaminationsV2';

const useSelectedParameters = () => {
	const { selectedParameters } = useExaminationsTrendChartContext();

	const selectedParametersLabels = new SelectedParameters(
		selectedParameters
	).getSelectedParametersLabels();

	return selectedParametersLabels;
};

export default useSelectedParameters;
