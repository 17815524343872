import { Divider, Grid, MenuItem } from '@material-ui/core';
import { useScheduleCalendarContext } from 'components/context';
import { SelectFieldAdapter } from 'components/elements';
import { useTranslations } from 'components/utilities';
import React from 'react';
import { Field, useFormState } from 'react-final-form';

import { alertOptions } from './helpers';

const AlertField = () => {
	const { t } = useTranslations();
	const { submitting } = useFormState();
	const { isAppointmentReview } = useScheduleCalendarContext();

	return (
		<>
			<Grid item xs={12}>
				<Field
					fullWidth
					component={SelectFieldAdapter}
					type="select"
					label={t('schedule.form.labels.alert')}
					name="alert_before"
					variant="outlined"
					disabled={submitting || isAppointmentReview}
					required={!isAppointmentReview}
					margin="dense"
				>
					{alertOptions.map(({ id, minutes }) => (
						<MenuItem key={id} value={minutes}>
							{minutes} {t('schedule.form.alert.minutes_before')}
						</MenuItem>
					))}
				</Field>
			</Grid>

			<Grid item xs={12}>
				<Divider />
			</Grid>
		</>
	);
};

export default AlertField;
