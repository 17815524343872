import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	graphContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column'
	},
	title: {}
}));
