import { useSnackbar } from 'notistack';
import React from 'react';

import { ActionNotification } from '../components/ActionNotification';
import { IActionNotificationProps } from '../components/ActionNotification/ActionNotification';

//need to use this one
export type ISystemNotificationsPermissionSnackbarProps =
	IActionNotificationProps;

export const SystemNotificationsPermissionSnackbar = React.forwardRef<
	HTMLDivElement,
	ISystemNotificationsPermissionSnackbarProps
>(({ onActionClick, onSecondaryActionClick, ...rest }, ref) => {
	const { closeSnackbar } = useSnackbar();
	return (
		<ActionNotification
			{...rest}
			title="global.notifications.system.notifications_permission.title"
			onActionClick={(id) => {
				closeSnackbar(id);
				onActionClick?.(id);
			}}
			onSecondaryActionClick={(id) => {
				closeSnackbar(id);
				onSecondaryActionClick?.(id);
			}}
			ref={ref}
		/>
	);
});
