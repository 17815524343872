import { Box, CircularProgress } from '@material-ui/core';
import { Alert } from 'components/elements';
import {
	arrayOf,
	func,
	node,
	object,
	oneOfType,
	shape,
	string
} from 'prop-types';
import React from 'react';
import { Form as FinalForm } from 'react-final-form';

import ActionButtons from './ActionButtons';
import { useStyles } from './styles';
import useForm from './useForm';
import validation from './validation';

const Form = ({ initialValues, formSettings, handleCancel, children }) => {
	const classes = useStyles();

	const {
		isResourcesError,
		isSubmitError,
		isSubmitLoading,
		isResourcesLoading,
		apiErrors,
		setInitialValues,
		submitItem
	} = useForm({ formSettings, initialValues });

	if (isResourcesLoading) {
		return (
			<Box className={classes.loader}>
				<CircularProgress size={70} color="primary" />
			</Box>
		);
	}

	if (
		!isResourcesLoading &&
		!isSubmitLoading &&
		(isSubmitError || isResourcesError) &&
		apiErrors
	) {
		return <Alert message={apiErrors} />;
	}

	return (
		!isResourcesLoading &&
		!isResourcesError && (
			<FinalForm
				initialValues={setInitialValues}
				validate={validation({ formType: formSettings.conditionType })}
				onSubmit={submitItem}
				render={({ handleSubmit }) => (
					<form
						className={classes.form}
						onSubmit={handleSubmit}
						noValidate
					>
						{children}
						<ActionButtons onCancel={handleCancel} />
					</form>
				)}
			/>
		)
	);
};

Form.propTypes = {
	handleCancel: func.isRequired,
	initialValues: object.isRequired,
	children: oneOfType([arrayOf(node), node]).isRequired,
	formSettings: shape({
		getApiUrl: string.isRequired,
		addApiUrl: string.isRequired,
		editApiUrl: string.isRequired,
		queryKey: string.isRequired,
		conditionType: string.isRequired,
		createInitialValues: func.isRequired,
		convertFormValues: func.isRequired,
		setManualRefetch: func.isRequired
	}).isRequired
};

export default Form;
