import { makeStyles } from '@material-ui/styles';
import theme from 'theme';

const GRADIENT = {
	COLORS: {
		RED: theme.palette.zScore.red,
		YELLOW: theme.palette.zScore.yellow,
		YELLOW_LIGHT: theme.palette.zScore.yellowLight,
		GREEN: theme.palette.zScore.green
	},
	VALUES: {
		END_RED: -4,
		END_YELLOW: -2.708,
		END_YELLOW_LIGHT: -1.6616,
		BEGIN_GREEN: -1.6536
	}
};

const { VALUES: GRADIENT_VALUES } = GRADIENT;

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		marginBottom: 7,
		alignItems: 'center'
	},
	title: {
		fontSize: '12px',
		lineHeight: '12px',
		fontWeight: 400,
		minWidth: 50,
		marginRight: 20
	},
	pictogram: {
		'-webkit-print-color-adjust': 'exact',
		position: 'relative',
		height: 16,
		width: '100%',
		display: 'flex',
		backgroundImage: ({ getValuePosition }) => {
			const GRADIENT_PERCENTAGES =
				getGradientPercentageValues(getValuePosition);

			return `url( "data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3ClinearGradient%20id%3D%22Gradient1%22%3E%3Cstop%20stop-color%3D%22%23F56666%22%20offset%3D%22${GRADIENT_PERCENTAGES.END_RED}%25%22%20%2F%3E%3Cstop%20stop-color%3D%22%23DECC35%22%20offset%3D%22${GRADIENT_PERCENTAGES.END_YELLOW}%25%22%20%2F%3E%3Cstop%20stop-color%3D%22%23E8DC72%22%20offset%3D%22${GRADIENT_PERCENTAGES.END_YELLOW_LIGHT}%25%22%20%2F%3E%3Cstop%20stop-color%3D%22%234CD63C%22%20offset%3D%22${GRADIENT_PERCENTAGES.BEGIN_GREEN}%25%22%20%2F%3E%3C%2FlinearGradient%3E%3Crect%20style%3D%22fill%3A%20url(%23Gradient1)%3B%22%20id%3D%22rect1%22%20width%3D%22100%25%22%20height%3D%22100%25%22%20%2F%3E%3C%2Fsvg%3E" )`;
		},
		'& > :last-child': {
			borderRight: 'none'
		}
	},
	scaleLine: {
		flex: 1,
		backgroundColor: 'transparent',
		borderRight: `1px solid ${theme.palette.gray.main}`
	}
}));

function getGradientPercentageValues(getValuePosition) {
	return Object.entries(GRADIENT_VALUES).reduce((acc, next) => {
		acc[next[0]] = `${100 - getValuePosition(next[1])}`;
		return acc;
	}, {});
}
