export const convertCountryNameToCode = (countryName, countries) => {
	validateArgs(countryName, countries);

	const countryCode = countries
		.find(({ name }) => name === countryName)
		?.countryCode?.toUpperCase();

	return countryCode ?? null;
};

export const convertCountryCodeToName = (code, countries) => {
	validateArgs(code, countries);

	const countryName = countries.find(
		({ countryCode }) => countryCode === code.toUpperCase()
	)?.name;
	return countryName ?? null;
};

export const validateArgs = (country, countriesList) => {
	if (!country || !countriesList) {
		throw Error(
			`Country ${country ?? null} or countries list: ${
				countriesList ?? null
			} is not defined!`
		);
	}

	if (!Array.isArray(countriesList)) {
		throw Error('Given countries list is not type of array');
	}
};
