import {
	getScaleLines,
	getValuePosition,
	getXAxisValuesDifference,
	getZScoreValue
} from './helpers';

const useActions = ({
	minXAxisValue,
	maxXAxisValue,
	scaleValuesQuantity,
	xAxisNonPositiveValues
}) => {
	function handleGetScaleLines() {
		return getScaleLines(scaleValuesQuantity);
	}

	function handleGetValuePosition(value) {
		return getValuePosition({
			value,
			scaleValuesQuantity,
			xAxisNonPositiveValues
		});
	}

	function handleGetXAxisValuesDifference() {
		return getXAxisValuesDifference(minXAxisValue, maxXAxisValue);
	}

	return {
		getScaleLines: handleGetScaleLines,
		getValuePosition: handleGetValuePosition,
		getXAxisValuesDifference: handleGetXAxisValuesDifference,
		getZScoreValue
	};
};

export default useActions;
