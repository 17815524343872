import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		textAlign: 'right',
		width: '100%',
		display: 'flex'
	},
	typography: ({ fontSize }) => ({
		fontWeight: 400,
		display: 'inline-block',
		lineHeight: '13.2px',
		textTransform: 'none',
		fontSize
	}),
	color: {
		color: theme.palette.text.gray
	},
	icon: ({ iconSize }) => ({
		width: iconSize,
		height: iconSize,
		marginRight: 7,
		verticalAlign: 'middle',
		display: 'inline-block'
	})
}));
