import {
	ContainerWithPrimaryBackground,
	Typography
} from 'components/elements/atoms/ExaminationsV2';
import { useTranslations } from 'components/utilities';
import { useGetCachedReportData } from 'queries/Examinations/ReportsV2/getter';
import React from 'react';

import { useStyles } from './styles';

type Props = {
	title: string;
};

export const Header = ({ title }: Props) => {
	const classes = useStyles();
	const { t } = useTranslations();
	const { clinic } = useGetCachedReportData();
	return (
		<header className={classes.root}>
			{clinic?.imageUrl && (
				<img
					src={clinic?.imageUrl}
					className={classes.clinicImage}
					alt="Clinic logo"
				/>
			)}
			<ContainerWithPrimaryBackground className={classes.container}>
				<Typography className={classes.examinationType}>
					{t(title)}
				</Typography>
			</ContainerWithPrimaryBackground>
		</header>
	);
};
