import {
	ColumnSort,
	getCoreRowModel,
	getFacetedMinMaxValues,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	getSortedRowModel,
	SortingState,
	TableOptions,
	Updater,
	useReactTable
} from '@tanstack/react-table';
import React from 'react';

import { defaultFilter } from './helpers';
import { ITanStackTableMiddleWare } from './types';

export interface IUseTanStackTable<TData> {
	/**
	 * Data state. Array of row objects. Usually `data` returned by useQuery hook.
	 */
	data: TableOptions<TData>['data'];
	/**
	 * Define how and which column data should be rendered by default. \n`{id: accessor, desc: boolean}`
	 */
	defaultSortBy?: ColumnSort;
	/**
	 * Array of columns definition
	 */
	columns: TableOptions<TData>['columns'];
	/**
	 * Table actions middlewares for: `globalFilterFn`
	 */
	middleware?: ITanStackTableMiddleWare<TData>;
}

export const useTanStackTable = <TData,>({
	data = [],

	columns,

	defaultSortBy,
	middleware = {
		globalFilterFn: undefined
	}
}: IUseTanStackTable<TData>) => {
	const [sorting, setSorting] = React.useState<SortingState>(
		defaultSortBy ? [defaultSortBy] : []
	);
	const [globalFilter, setGlobalFilter] = React.useState('');

	const handleSorting = React.useCallback(
		(sortingUpdater: Updater<SortingState>) => {
			setSorting((prev) => {
				let newSorting: SortingState;
				if (typeof sortingUpdater === 'function') {
					newSorting = sortingUpdater(prev);
				} else {
					newSorting = sortingUpdater;
				}

				if (newSorting.length === 0 && defaultSortBy) {
					newSorting = [defaultSortBy];
				}
				return newSorting;
			});
		},
		[]
	);

	const table = useReactTable({
		data,
		columns,
		state: {
			sorting,
			globalFilter
		},
		defaultColumn: {
			minSize: 0,
			size: 0
		},
		globalFilterFn: (row, columnId, filterValue, addMeta) => {
			if (middleware.globalFilterFn) {
				return middleware.globalFilterFn(
					row,
					columnId,
					filterValue,
					addMeta,
					() => defaultFilter<TData>(row, columnId, filterValue)
				);
			}
			return defaultFilter<TData>(row, columnId, filterValue);
		},
		onSortingChange: handleSorting,
		onGlobalFilterChange: setGlobalFilter,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
		getFacetedMinMaxValues: getFacetedMinMaxValues()
	});

	return { table, globalFilter, setGlobalFilter };
};
