import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	typography: {
		textTransform: 'none'
	},
	button: {
		marginLeft: theme.spacing(3),
		textTransform: 'none',
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		fontWeight: 600
	},
	spacer: { flex: 1 }
}));
