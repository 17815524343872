import clsx from 'clsx';
import React from 'react';

import { Chip as CmpChip } from '../../../../atoms';
import { IChipProps } from '../../../../atoms/Chip/types';
import { useStyles } from './styles';

export interface IChipTableCellContentProps extends Omit<IChipProps, 'label'> {
	/**
	 * translation key label
	 */
	label: TranslationKey;
}

export const Chip = ({
	label,
	className = '',
	backgroundColor,
	collapsedLabel = '',
	collapsedButton = false,
	onClick = () => {},
	...rest
}: IChipTableCellContentProps) => {
	const classes = useStyles();
	return (
		<CmpChip
			backgroundColor={backgroundColor}
			label={label}
			className={clsx([className, classes.chipTableCellContent])}
			onClick={onClick}
			collapsedButton={collapsedButton}
			collapsedLabel={collapsedLabel}
			{...rest}
		/>
	);
};
