import { useQuery } from 'components/utilities';
import { QUERY_DOCTOR_ACCOUNT_VERIFICATION } from 'helpers';
import { useLocation } from 'react-router-dom';

import { AuthRepository } from './repository';

/**
 * verify user account creation
 * @param {object} options useQuery options
 * @returns verification query data
 */
const useVerifyAccount = (options = {}) => {
	const { pathname } = useLocation();

	const { length, [length - 1]: token } = pathname.split('/');

	if (!token) {
		throw Error('pathname does not include token!');
	}

	const query = useQuery(
		QUERY_DOCTOR_ACCOUNT_VERIFICATION,
		() => AuthRepository.verifyAccount({ token }),
		options
	);

	return query;
};

export default useVerifyAccount;
