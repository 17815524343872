import { Box } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import { ContainerWithBorder, Title } from '../../../components';
import { useStyles } from './styles';

type Props = {
	children: CmpChildren;
	title?: string;
	isBorder?: boolean;
};
export const TypeColumnContainer = ({
	children,
	title = '',
	isBorder = false
}: Props) => {
	const classes = useStyles();
	return (
		<Box
			className={clsx([
				isBorder ? classes.borderBox : '',
				classes.wrapper
			])}
		>
			{title ? (
				<ContainerWithBorder className={classes.titleContainer}>
					<Title title={title} className={classes.title} />
				</ContainerWithBorder>
			) : null}

			{children}
		</Box>
	);
};
