import { useMutation, useQuery } from 'components/utilities';
import { QUERY_PATIENT_BASIC_DATA } from 'helpers';
import { PatientModel } from 'models/Patient/PatientModel';
import { CountriesRepository } from 'queries/Countries';
import { useSettingsServices } from 'queries/Settings';
import { useParams } from 'utilities';

import { SETTING_NAMES } from './helpers/common';
import { PatientRepository } from './repository';
import { PatientServices } from './services';

type AdditionalData = {
	patientId?: PatientIdParam;
};
const useFetchData = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	options: { enabled?: boolean; onError?: (message: any) => void } = {
		enabled: true,
		onError: () => {}
	},
	{ patientId }: AdditionalData = {}
) => {
	const { patientId: paramsPatientId } = useParams();
	const SettingServices = useSettingsServices();
	const id = paramsPatientId || (patientId as PatientIdParam);

	return useQuery<PatientModel>(
		[QUERY_PATIENT_BASIC_DATA, id],
		async (payload) => {
			const [data, countries] = await Promise.all([
				PatientRepository.getData({
					...payload,
					patientId: id
				}),
				CountriesRepository.getAll(payload)
			]);

			const patientDataWithBmi = PatientServices.addBmiToPatientData(
				data.patient
			);

			const initialValues = Object.entries(patientDataWithBmi).reduce(
				(acc, [fieldName, initValue]) => {
					const indexOfSettingName = SETTING_NAMES.findIndex(
						({ field }) => field === fieldName
					);
					if (indexOfSettingName !== -1) {
						const userSettingValue = SettingServices.findByName(
							SETTING_NAMES[indexOfSettingName].settingName
						).value[0];
						acc[fieldName] = userSettingValue;
					} else {
						acc[fieldName] = initValue;
					}
					return acc;
				},
				{}
			);

			const convertedAdditionalInformationData =
				PatientServices.convertAdditionalInformationsInitialValues(
					initialValues
				);

			const convertedPatientData =
				PatientServices.convertBasicInformationsInitialValues(
					convertedAdditionalInformationData,
					countries
				);

			return convertedPatientData;
		},
		options
	);
};

/**
 * fetch patient's data
 * @param {object} options mutation options
 * @param {object} additionalData mutation additional data e.g services arguments
 * @param {number} additionalData.patientId patient ID to be fetched
 * @returns fetch patient's data mutation
 */
const useFetchDataMutation = (
	options = {},
	{ patientId }: AdditionalData = {}
) => {
	const { patientId: paramsPatientId } = useParams();

	const id = paramsPatientId || (patientId as PatientIdParam);

	return useMutation<PatientModel, TMyError, { patientId?: PatientIdParam }>(
		(payload, cancelToken) =>
			PatientRepository.getData({
				...payload,
				patientId: id ?? payload?.patientId,
				cancelToken
			}),
		options
	);
};

export { useFetchData, useFetchDataMutation };
