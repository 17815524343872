import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	title: {
		fontSize: '20px'
	},
	emailRow: {
		flexWrap: 'nowrap'
	}
}));
