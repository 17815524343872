import React from 'react';

export interface UseToggleReturnObject {
	toggle: () => void;
	setToggleState: (state: boolean) => void;
	on: boolean;
}

const useToggle = (initialState = false): UseToggleReturnObject => {
	const [on, setOn] = React.useState<boolean>(initialState);

	const handleToggle = () => {
		setOn((prev) => !prev);
	};

	const handleSetToggleState = (state: boolean) => {
		setOn(state);
	};
	return { toggle: handleToggle, setToggleState: handleSetToggleState, on };
};

export default useToggle;
