import { Box } from '@material-ui/core';
import { ParameterValue } from 'components/elements/molecules';
import { useTranslations } from 'components/utilities';
import { shape, string } from 'prop-types';
import React from 'react';

import { useStyles } from './styles';

const Scores = ({ scores }) => {
	const classes = useStyles();
	const { tNoParser } = useTranslations();

	return (
		<Box className={classes.root} data-testid="scoresRoot">
			{Object.entries(scores).map(([name, value]) => (
				<ParameterValue
					key={`${value}-${name}`}
					classNameRoot={classes.score}
					isScore
					value={value}
					isUnitOutside
					unit={tNoParser(
						'patients.examinations.details.spirometry.score'
					)}
				/>
			))}
		</Box>
	);
};

Scores.propTypes = {
	scores: shape({ fev1: string, fvc: string }).isRequired
};

export default Scores;
