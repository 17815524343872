import { useMessengerContext } from 'components/context';
import React from 'react';

const INITIAL_STATE = '';
const useMessageTextField = () => {
	const { sendMessage, conversationId } = useMessengerContext();
	const [message, setMessage] = React.useState(INITIAL_STATE);

	const handleOnChangeText = React.useCallback(
		({ target: { value } }) => setMessage(value),
		//eslint-disable-next-line
		[]
	);

	const handleSendMessage = React.useCallback(() => {
		const option = {
			message,
			type: 'text',
			conversation_id: conversationId
		};
		sendMessage(option);
		setMessage(INITIAL_STATE);
		//eslint-disable-next-line
	}, [conversationId, message]);

	// handle press ENTER key to force send message
	const handleEnterKeyDown = React.useCallback(
		(e) => e.keyCode === 13 && handleSendMessage(),
		//eslint-disable-next-line
		[conversationId, message]
	);

	return {
		onTextChange: handleOnChangeText,
		onEnterPress: handleEnterKeyDown,
		onSendMessage: handleSendMessage,
		message
	};
};

export default useMessageTextField;
