import { Grid } from '@material-ui/core';
// Import components
import { SettingsRoutes } from 'components/routes';
import React from 'react';

import { Sidebar } from './Sidebar';

const Settings = () => {
	return (
		<Grid container spacing={3}>
			<Grid item lg={'auto'} md={3} sm={12} xs={12}>
				<Sidebar />
			</Grid>
			<Grid item lg={8} md={9} sm={9} xs={12}>
				<SettingsRoutes />
			</Grid>
		</Grid>
	);
};

export default Settings;
