import * as types from './types';

export const setPatient = (payload) => (dispatch) => {
	dispatch({ type: types.SET_PATIENT, payload });
};

export const setSelectedDate = (payload) => (dispatch) => {
	dispatch({ type: types.SET_SELECTED_DATE, payload });
};

export const setDuration = (payload) => (dispatch) => {
	dispatch({ type: types.SET_DURATION, payload });
};

export const setSelectedAppointment = (payload) => (dispatch) => {
	dispatch({ type: types.SET_SELECTED_APPOINTMENT, payload });
};
