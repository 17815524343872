import {
	areBooleansTruthy,
	isLastArrayIndex,
	QUERY_PATIENT_DATA_AND_PERSONAL_BESTS
} from 'helpers';
import { useChartsDataMutation } from 'queries';
import { useQueryClient } from 'react-query';
import { useParams } from 'utilities';

import { HighCharts } from '../../classes';
import { useTooltipContext } from '../TooltipProvider';

const useExaminationsTrendChartData = ({ trendChartRef }) => {
	const { patientId } = useParams();
	const cache = useQueryClient();
	const [patientPersonalBests] = cache.getQueryData([
		QUERY_PATIENT_DATA_AND_PERSONAL_BESTS,
		patientId
	]);

	const { deactivateTooltip } = useTooltipContext();

	const handleSuccess = (data) => {
		const highChart = new HighCharts(trendChartRef);
		const currentSelectedParameters = [];

		if (Array.isArray(data)) {
			data.forEach((chartData, index) => {
				currentSelectedParameters.push(chartData?.chartName);
				highChart.addSeries(chartData, isLastArrayIndex(index, data));
			});
		} else {
			currentSelectedParameters.push(data?.chartName);
			highChart.addSeries(data);
		}

		highChart.setYAxisSoftMinSoftMax(
			patientPersonalBests?.best,
			currentSelectedParameters
		);
		highChart.updateSeriesEvents(patientPersonalBests?.best, () => {
			deactivateTooltip();
		});
		highChart.setInitialYAxisVisibility(patientPersonalBests?.best);
		highChart.updateNavigatorXAxisExtremes(data);

		const { min: selectionStartDate, max: selectionEndDate } =
			highChart.getHighChart().chart.xAxis[0];
		highChart.updateNavigatorColouredZone(
			selectionStartDate,
			selectionEndDate
		);
	};

	const { singleChartParameterMutation, allChartParametersMutation } =
		useChartsDataMutation({
			onSuccess: handleSuccess
		});

	const isChartDataLoading = areBooleansTruthy(
		'some',
		singleChartParameterMutation.isLoading,
		allChartParametersMutation.isLoading
	);

	const isChartDataSuccess = areBooleansTruthy(
		'some',
		singleChartParameterMutation.isSuccess,
		allChartParametersMutation.isSuccess
	);
	return {
		isLoading: isChartDataLoading,
		isSuccess: isChartDataSuccess,
		singleChartParameterMutation,
		allChartParametersMutation
	};
};

export default useExaminationsTrendChartData;
