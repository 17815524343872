import { HelmetTags } from 'components/elements';
import { LoginTemplate } from 'components/templates';

import { AcceptanceStatus } from './AcceptanceStatus';

export default function PatientLinkInvitationAcceptance() {
	return (
		<>
			<LoginTemplate>
				<HelmetTags title="pages.patient_link_invitation_acceptance.title" />
				<AcceptanceStatus />
			</LoginTemplate>
		</>
	);
}
