import { makeStyles } from '@material-ui/styles';
import theme from 'theme';

export const useStyles = makeStyles<typeof theme, { isTitleDefined: boolean }>(
	() => ({
		contentContainer: {
			paddingTop: ({ isTitleDefined }) =>
				isTitleDefined ? '32px' : '50px !important'
		}
	})
);
