import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	alert: {
		marginBottom: theme.spacing(3),
		fontWeight: 500,
		display: 'flex',
		alignItems: 'center',
		fontSize: 14
	}
}));
