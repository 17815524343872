import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useTranslations } from 'components/utilities';
import { useLanguage } from 'components/utilities';
import { func, string } from 'prop-types';
import React from 'react';

const PickersUtilsProvider = ({ children, variant, ...rest }) => {
	const { localLng } = useLanguage();
	const { t } = useTranslations();

	const translationsProps =
		variant === 'dialog'
			? {
					cancelLabel: t('btn.cancel'),
					okLabel: t('btn.ok_confirm')
			  }
			: {};

	return (
		<MuiPickersUtilsProvider
			locale={localLng()}
			utils={DateFnsUtils}
			{...rest}
		>
			{children({ translationsProps })}
		</MuiPickersUtilsProvider>
	);
};

PickersUtilsProvider.propTypes = {
	children: func.isRequired,
	variant: string
};

export default PickersUtilsProvider;
