import { useSnackbar } from 'notistack';
import React from 'react';
import { Browser } from 'utilities';

import {
	callPermissionsRequest,
	notificationsDirector
} from '../notificationsDirector';

export const useSystemNotificationsPermissionSnackbar = () => {
	const { enqueueSnackbar } = useSnackbar();

	React.useEffect(() => {
		if (
			'Notification' in window &&
			Notification?.permission === 'default' &&
			Browser.isSafari
		) {
			notificationsDirector(
				enqueueSnackbar
			).notificationsPermissionRequest();
		} else {
			(async () => await callPermissionsRequest())();
		}
	}, []);
};
