import { Box } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/styles';
import clsx from 'clsx';
import {
	StackedParameters,
	Title
} from 'components/elements/atoms/ExaminationsV2';
import {
	ForcedSpirometryReportModel,
	SlowVitalCapacitySVCReportModel
} from 'models/Report/Reports';
import React from 'react';
import palette from 'theme/palette';
import { v4 as uuidv4 } from 'uuid';

import { Dots, NoPerformedMeas } from './Dots';
import { getMeasurementByNumber } from './helpers';
import { Legend } from './Legend';
import { useStyles } from './styles';

export type LocalTheme = () => void | ClassNameMap<
	| 'font'
	| 'stackedParametersContainer'
	| 'stackedParametersLabel'
	| 'dotsContainer'
	| 'dotClassName'
	| 'dotsWrapper'
	| 'legendContainer'
	| 'root'
>;
type Props = {
	examination:
		| SlowVitalCapacitySVCReportModel
		| ForcedSpirometryReportModel
		| undefined;
	upperStatusFieldName: string;
	bottomStatusFieldName: string;
	parametersLabels: string[];
	color?: string;
	useLocalTheme?: LocalTheme;
};
export const Correctness = ({
	examination,
	upperStatusFieldName,
	bottomStatusFieldName,
	parametersLabels,
	color = palette.report.green.main,
	useLocalTheme = () => {}
}: Props) => {
	const classes = useStyles();
	const localTheme = useLocalTheme();

	const renderMeasurementDot = () => ({
		renderDefault(_, index) {
			const meas = getMeasurementByNumber(
				index,
				examination?.measurements ?? []
			);

			const key = uuidv4();

			if (!meas) {
				return (
					<NoPerformedMeas
						key={key}
						className={localTheme?.dotsWrapper}
						upperDotClassName={localTheme?.dotClassName}
						bottomDotClassName={localTheme?.dotClassName}
					/>
				);
			}

			return (
				<Dots
					className={localTheme?.dotsWrapper}
					upperDotClassName={localTheme?.dotClassName}
					bottomDotClassName={localTheme?.dotClassName}
					key={key}
					color={color}
					isBest={meas.isBest}
					upperStatus={meas[upperStatusFieldName]}
					bottomStatus={meas[bottomStatusFieldName]}
				/>
			);
		}
	});
	return (
		<Box flex={1} className={localTheme?.root}>
			<Title
				variant="secondary"
				title="report.correctness.title"
				className={localTheme?.font}
			/>
			<Box display={'flex'} justifyContent="center">
				<StackedParameters
					parameters={parametersLabels}
					parameterLabelClassName={localTheme?.stackedParametersLabel}
					className={localTheme?.stackedParametersContainer}
				/>
				<Box
					className={clsx([
						localTheme?.dotsContainer,
						classes.dotsContainer
					])}
					flex={1}
				>
					{new Array(8)
						.fill('')
						.map(renderMeasurementDot().renderDefault)}
				</Box>
			</Box>
			<Legend legendContainerClassName={localTheme?.legendContainer} />
		</Box>
	);
};
