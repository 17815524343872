import { Grid } from '@material-ui/core';
import React from 'react';

import { FIELD_NAMES } from './helpers';
import SelectField from './SelectField';
import useExSmokerFields from './useExSmokerFields';

const ExSmokerFields = () => {
	const { yearsArray, monthsArray, onYearChange } = useExSmokerFields();

	return (
		<>
			<Grid item lg={3} md={3} sm={3} xs={4}>
				<SelectField
					options={yearsArray}
					label="yyyy"
					name={FIELD_NAMES.exSmokerYear}
					inputOnChange={onYearChange}
				/>
			</Grid>
			<Grid item lg={3} md={3} sm={3} xs={4}>
				<SelectField
					options={monthsArray}
					label="mm"
					name={FIELD_NAMES.exSmokerMonth}
					required={false}
				/>
			</Grid>
		</>
	);
};

export default ExSmokerFields;
