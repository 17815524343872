import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		textTransform: 'capitalize',
		fontSize: 14,
		fontWeight: 600
	},
	error: ({ variant }) => ({
		color: variant === 'text' ? '#E25050' : 'white',
		backgroundColor:
			variant === 'text' ? 'transparent' : theme.palette.error.darker,
		'&:hover': {
			backgroundColor:
				variant === 'text' ? 'rgba(245, 102, 102, 0.1)' : '#E25050'
		},
		'&:disabled': {
			backgroundColor: variant === 'text' ? 'transparent' : '#FBD2D2'
		}
	}),
	tiny: {
		height: 25,
		fontSize: '12px',
		lineHeight: '12px',
		padding: '0 10px',
		minWidth: 0
	}
}));
