import { makeStyles } from '@material-ui/styles';

import { FONT } from '../../components/styles';

export const TRANSITION_DURATION = 250;

export const useStyles = makeStyles(() => ({
	font: {
		...FONT
	},
	row: {
		'& > :first-child': {
			width: '110px'
		},
		'& > :nth-child(2)': {
			width: '200px'
		},
		'& > :nth-child(3)': {
			width: '110px'
		}
	},
	loader: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		top: 0,
		left: 0,
		backgroundColor: 'rgba(0,0,0,0.1)'
	},
	root: {
		position: 'relative'
	},
	transitionEnter: {
		maxHeight: 0,
		marginBottom: 0,
		opacity: 0
	},
	transitionEnterActive: {
		maxHeight: '1000px',
		marginBottom: 30,
		transition: `all ${TRANSITION_DURATION}ms ease`,

		opacity: 1
	},
	transitionExit: {
		maxHeight: '1000px',
		marginBottom: 30,
		transition: `all ${TRANSITION_DURATION}ms ease`,
		opacity: 1
	},
	transitionExitActive: {
		maxHeight: 0,
		marginBottom: 0,
		opacity: 0
	}
}));

export const useCellStyles = makeStyles(() => ({
	cell: {
		...FONT,
		fontSize: '12px',
		lineHeight: '12px',
		color: 'black'
	}
}));
