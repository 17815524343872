import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(1),
		width: 200,
		'& button': {
			color: theme.palette.primary.main
		}
	}
}));
