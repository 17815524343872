import { format } from 'date-fns';
import { EN } from 'helpers';

// Convert event time to locale
export const convertAppointmentTime = ({ date, lng }) => {
	const isEnglish = lng === EN;
	const timeFormat = isEnglish ? 'h:mm a' : 'HH:mm';
	const time = format(date, timeFormat);
	return time.replace(/\s/g, '').toLowerCase();
};
