import { Link,TableCell } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { EditIcon } from 'assets/icons/commonMUIIcons';
import { useTranslations } from 'components/utilities';
import { bool,object, string } from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	link: {
		display: 'flex',
		alignItems: 'center'
	},
	icon: {
		width: 15,
		height: 15,
		display: 'flex',
		alignItems: 'center',
		marginRight: theme.spacing(0.5)
	}
}));

const EditTableCell = ({
	cell,
	editLinkPath,
	icon: IconComponent = EditIcon,
	text = 'table.edit',
	isIcon = true,
	originalText = ''
}) => {
	const { t } = useTranslations();
	const classes = useStyles();

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<Link
				className={classes.link}
				component={RouterLink}
				to={editLinkPath}
				variant="h6"
			>
				{isIcon && (
					<IconComponent className={classes.icon} fontSize="small" />
				)}
				{originalText || t(text)}
			</Link>
		</TableCell>
	);
};

EditTableCell.propTypes = {
	cell: object.isRequired,
	editLinkPath: string.isRequired,
	text: string,
	originalText: string,
	icon: object,
	isIcon: bool
};

export default EditTableCell;
