import { IColumn } from 'components/elements/organisms/ExaminationsV2/ParametersTable/ParametersTable';
import { ReportParameterModel } from 'models/Report/common';
import { ColumnValues } from 'utilities/Examinations/ExaminationDetails/common';

type Accessor = Leaves<
	ColumnValues<ReportParameterModel & { changePercentage?: number | null }>
>;

const columns: IColumn<Accessor>[] = [
	{ Header: '', accessor: 'parameterTypeLabel' },
	{
		Header: 'report.best_results.value',
		accessor: 'value',
		isInteger: { rows: ['pef'] }
	},
	{ Header: 'report.best_results.lln', accessor: 'lln' },
	{ Header: 'report.best_results.predicted.short', accessor: 'predicted' },
	{ Header: 'report.best_results.z_score', accessor: 'zScore' },
	{
		Header: 'report.best_results.percentage_predicted.short',
		accessor: 'predictedPercent',
		isInteger: true
	},
	{
		Header: 'report.best_results.percentile.short',
		accessor: 'percentile',
		isInteger: true
	}
];

export const getColumns = (isChangePercentage: boolean) => {
	if (!isChangePercentage) {
		return columns;
	}
	return [
		...columns,
		{
			Header: 'report.best_results.percentage_change',
			accessor: 'changePercentage',
			isInteger: true
		}
	];
};
