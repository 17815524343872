import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: 25,
		paddingTop: 2,
		paddingBottom: 2
	},
	text: {
		fontSize: '9px',
		lineHeight: '9px',
		color: theme.palette.gray.dark,
		'& div': {
			height: 10,
			display: 'flex',
			alignItems: 'center'
		}
	},
	wrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '0 5px 0 5px',
		height: 15
	},
	dividerWrapper: {
		height: '100%',
		paddingTop: 8,
		paddingBottom: 3,
		'& hr': {
			backgroundColor: theme.palette.gray.dark
		}
	}
}));
