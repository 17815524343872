import { ReactComponent as AioCareLogoIcon } from 'assets/icons/AioCareLogoFooter.svg';
import { Typography } from 'components/elements/atoms/ExaminationsV2';
import { LabeledValue } from 'components/elements/molecules/ExaminationsV2';
import { DATE_FORMAT, TIME_FORMAT } from 'helpers/variables';
import React from 'react';
import { Cookies, LocaleDate } from 'utilities';

import { FooterProps } from '.';
import { useStyles } from './styles';

export const Footer = React.forwardRef<HTMLDivElement, FooterProps>(
	({ pageCount, pages }, ref) => {
		const classes = useStyles();

		const currentDate = React.useMemo(() => {
			const cookieDateTime = Cookies.report.getCreationDateTime();
			const now = cookieDateTime !== null ? cookieDateTime : new Date();
			return new LocaleDate(now)
				.getDateWithTimeLabel(DATE_FORMAT.DEFAULT, TIME_FORMAT.DEFAULT)
				.dateFirst(' |');
		}, []);

		return (
			<footer className={classes.footer} ref={ref}>
				<AioCareLogoIcon />
				<LabeledValue
					labelProps={{
						label: 'report.footer.all_rights_reserved'
					}}
					valueProps={{
						value: 'www.aiocare.com'
					}}
				/>
				<LabeledValue
					labelProps={{
						label: 'report.footer.report_generated'
					}}
					valueProps={{
						value: currentDate
					}}
				/>
				<Typography
					className={classes.pageCount}
				>{`${pageCount}/${pages}`}</Typography>
			</footer>
		);
	}
);
