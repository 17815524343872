import { makeStyles } from '@material-ui/styles';
import theme from 'theme';

import { ISnackbarMessageProps } from '../SnackbarMessage/SnackbarMessage';

export const useStyles = makeStyles<
	typeof theme,
	{ variant: ISnackbarMessageProps['variant'] }
>(() => ({
	messageContainer: {
		padding: '14px 16px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
		maxWidth: 400
	}
}));
