import { makeStyles } from '@material-ui/styles';
import theme from 'theme';

import { SnackbarProps } from './Snackbar';

export const useStyles = makeStyles<
	typeof theme,
	{ variant: SnackbarProps['variant'] }
>((theme) => ({
	messageContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	root: {
		borderRadius: 15,
		backgroundColor: ({ variant }) => {
			switch (variant) {
				case 'primary':
					return theme.palette.primary.main;
				case 'secondary':
					return 'white';
				case 'error':
					return '#ffcfcf';
				default:
					return theme.palette.primary.main;
			}
		},
		boxShadow: ({ variant }) => {
			switch (variant) {
				case 'error':
					return `0px 0px 10px 1px #ff7171`;
				default:
					return '0px 0px 10px 4px rgba(0,0,0,0.2)';
			}
		}
	},
	message: {
		borderRadius: 15,
		width: '100%'
	}
}));

export const useSnackBarStyles = makeStyles<
	typeof theme,
	{ variant: SnackbarProps['variant'] }
>(() => ({
	font: {
		color: ({ variant }) => {
			switch (variant) {
				case 'primary':
					return 'white';
				case 'secondary':
					return 'black';
				case 'error':
					return 'white';
				default:
					return 'white';
			}
		}
	}
}));

export const useSnackbarAnimationStyles = makeStyles(() => ({
	shake: {
		animation: `$shake 1.2s infinite`,
		'&:hover': {
			animation: 'none'
		}
	},
	'@keyframes shake': {
		'0%': {},
		'50%': { transform: ' rotate(0deg)' },
		'55%': { transform: ' rotate(1deg)' },
		'60%': { transform: ' rotate(-1deg)' },
		'65%': { transform: ' rotate(1deg)' },
		'70%': { transform: ' rotate(-1deg)' },
		'100%': {}
	}
}));
