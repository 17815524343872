import React from 'react';

import { useAttemptsContext } from '../context';

export const useGraphInitialState = () => {
	const { flowVolumeGraphRef, volumeTimeGraphRef, measurements } =
		useAttemptsContext();

	React.useEffect(() => {
		measurements?.forEach((meas, index) => {
			const flowVolumeSerie =
				flowVolumeGraphRef.current?.chart.series[index];
			const volumeTimeSerie =
				volumeTimeGraphRef.current?.chart.series[index];

			if (!meas.isBest && index > 0) {
				flowVolumeSerie?.hide();
				volumeTimeSerie?.hide();
			}
		});
	}, []);
};
