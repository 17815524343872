import { useControlledTableContextProvider } from 'components/context';
import { useMutation } from 'components/utilities';
import React from 'react';

const useDeleteMenuItem = ({ deletePromise, closeMenu, itemId }) => {
	const [open, setOpen] = React.useState(false);

	const { refetchTableDataResources } = useControlledTableContextProvider();

	const handleClose = (_, reason) => {
		if (reason === 'backdropClick') {
			return false;
		}
		setOpen(false);
		closeMenu();
	};

	const handleOpen = () => setOpen(true);

	const handleSuccess = async () => {
		refetchTableDataResources();
		handleClose();
	};

	const {
		mutate: deleteItem,
		status: idle,
		apiError
	} = useMutation(deletePromise);
	const handleDelete = () => deleteItem(itemId);

	return {
		deleteItem: handleDelete,
		setOpen: handleOpen,
		setClose: handleClose,
		onSuccess: handleSuccess,
		open,
		idle,
		error: apiError
	};
};

export default useDeleteMenuItem;
