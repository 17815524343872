import {
	CHART_MEASUREMENT_PARAMETER_OPTION_NAMES,
	Unit,
	USER_SETTINGS_UNITS
} from 'helpers';
import _ from 'lodash';
import Store from 'store/store';
import * as types from 'store/types';
import { Settings } from 'utilities';

export const setSettingsData = (data) => async (dispatch) =>
	dispatch({
		type: types.SET_USER_SETTINGS,
		payload: data
	});

export type UserUnit = {
	unit: string;
	settingName: string;
	isInteger: boolean;
};
export type UserUnits = {
	[K in keyof typeof CHART_MEASUREMENT_PARAMETER_OPTION_NAMES]: UserUnit;
};

export const setUserUnits =
	({ settings }) =>
	async (dispatch) => {
		const settingsObj = new Settings(settings);
		const allParametersCopy = Object.entries(
			CHART_MEASUREMENT_PARAMETER_OPTION_NAMES
		).reduce((acc, next) => {
			let userUnit: UserUnit = {
				unit: next[1].defaultUnit,
				settingName: '',
				isInteger: false
			};
			if (USER_SETTINGS_UNITS?.[next[0]]) {
				const unitSetting: Unit = USER_SETTINGS_UNITS[next[0]];
				const setting = settingsObj.findByName(unitSetting.settingName);
				const unit = setting.value[0] as string;
				userUnit = {
					settingName: unitSetting.settingName,
					unit,
					isInteger: unitSetting.integerUnits?.includes(unit) ?? false
				};
			}
			acc[next[0]] = userUnit;
			return acc;
		}, {} as UserUnits);

		dispatch({
			type: types.SET_USER_UNITS,
			payload: allParametersCopy
		});
	};

export const updateUserUnits =
	({ newUnit, settingName }) =>
	async (dispatch) => {
		const userUnits = Store.getState().settings.units as UserUnits;

		const updatedUnitEntry = Object.entries(userUnits).find(
			(userUnit) => userUnit[1]?.settingName === settingName
		);
		const updatedParameterUnitKey = updatedUnitEntry?.[0];
		const updatedUserUnits = _.cloneDeep(userUnits);
		if (
			updatedParameterUnitKey &&
			updatedUserUnits?.[updatedParameterUnitKey]
		) {
			const unitSetting: Unit =
				USER_SETTINGS_UNITS[updatedParameterUnitKey];

			updatedUserUnits[updatedParameterUnitKey] = {
				...updatedUserUnits[updatedParameterUnitKey],
				unit: newUnit,
				isInteger: unitSetting.integerUnits?.includes(newUnit) ?? false
			};
		}

		dispatch({
			type: types.SET_USER_UNITS,
			payload: updatedUserUnits
		});
	};

export const updateSettings = (data) => async (dispatch) => {
	dispatch({
		type: types.UPDATE_USER_SETTING,
		payload: data
	});
};
