import { ChevronRightIcon } from 'assets/icons/commonMUIIcons';
import clsx from 'clsx';
import React from 'react';

import { TableCell } from '../../../atoms/Table/TableCell';
import { ITableCellProps } from '../../../atoms/Table/TableCell/TableCell';
import { useStyles } from './styles';

export interface ITableHeaderCellProps extends ITableCellProps {
	className?: string;
	onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
	isSorted?: false | 'asc' | 'desc';
	canSort?: boolean;
	textAlign?:
		| undefined
		| 'start'
		| 'end'
		| 'left'
		| 'right'
		| 'center'
		| 'justify'
		| 'initial'
		| 'match-parent';
}
export const TableHeaderCell = ({
	className,
	children,
	onClick,
	isSorted,
	textAlign = 'right',
	canSort = true,
	...rest
}: ITableHeaderCellProps) => {
	const classes = useStyles({ textAlign });
	return (
		<TableCell {...rest} className={className}>
			<div
				{...{
					className: clsx([className, classes.cell]),
					onClick
				}}
			>
				{children}
				{canSort && (
					<ChevronRightIcon
						color="primary"
						className={clsx([
							classes.sortChevron,
							isSorted
								? {
										asc: classes.sortChevronAsc,
										desc: classes.sortChevronDesc
								  }[isSorted]
								: null
						])}
					/>
				)}
			</div>
		</TableCell>
	);
};
