import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setLayoutBgHeight } from 'store/actions';
import { useBaseLayoutStyles } from 'theme/styles';

import { getLayoutBgHeight } from './helpers';
import { useStyles } from './style';

const useMain = () => {
	const dispatch = useDispatch();
	const theme = useTheme();

	const { pathname } = useLocation();
	const isSignOutLoading = useSelector((state) => state.auth.signOut.loading);

	// Layout
	const layoutBgHeight = useSelector((state) => state.layout.height);
	const baseLayoutClasses = useBaseLayoutStyles();
	const classes = useStyles({ layoutBgHeight });

	// Set media queries for desktop
	const [openSidebar, setOpenSidebar] = useState(false);
	const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
		defaultMatches: true
	});
	// Check if it is a mobile or desktop view
	const shouldOpenSidebar = isDesktop ? true : openSidebar;

	// Mobile sidebar
	const handleSidebarOpen = () => setOpenSidebar(true);
	const handleSidebarClose = () => setOpenSidebar(false);

	const [, core] = pathname.split('/');
	React.useEffect(() => {
		const height = getLayoutBgHeight(pathname);
		dispatch(setLayoutBgHeight(height));
		// eslint-disable-next-line
	}, [core]);

	return {
		isSignOutLoading,
		baseLayoutClasses,
		classes,
		shouldOpenSidebar,
		handleSidebarOpen,
		handleSidebarClose,
		isDesktop
	};
};

export default useMain;
