import { Box } from '@material-ui/core';
import React from 'react';
import { generatePath, Link, LinkProps, useLocation } from 'react-router-dom';
import { useParams } from 'utilities';

export type LocationState = {
	prevLocation: ReturnType<typeof useLocation<LocationState>>;
};

type Props = LinkProps<LocationState> & { to: string; pathBase?: string };

//eslint-disable-next-line
const RouterLink = React.forwardRef<HTMLDivElement, Props>(
	({ pathBase, ...props }, ref) => {
		const location = useLocation();
		const params = useParams();

		return (
			<Box {...{ ref }}>
				<Link
					{...props}
					to={{
						pathname: props.to,
						state: {
							prevLocation: {
								...location,
								pathname: pathBase
									? generatePath(pathBase, params)
									: location.pathname
							}
						}
					}}
				/>
			</Box>
		);
	}
);

export default RouterLink;
